import { BrowserRouter as Router } from 'react-router-dom';
import { RouterBuilder } from './common/router.js';
import { MAIN_ROUTES } from '../config/routes/mainRoutes.js';
import { ActivityContainer } from './common/container/activity.js';
import { SocketContainer } from './common/container/socketContainer.js';
import { sessionSelector, sessionSuccess } from '../redux/slicers/sessionSlice.js';
import { useDispatch, useSelector } from 'react-redux';
import { getUserData } from '../helpers/projectHelper.js';
import { useEffect } from 'react';
import { useMemo } from 'react';

const AppContainer = () => {
  const { session = false } = useSelector(sessionSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    const userData = getUserData();
    if (userData.user_id) {
      dispatch(sessionSuccess(userData));
    }
  }, []);

  return (
    <Router>
      <ActivityContainer>
        <RouterBuilder data={MAIN_ROUTES} />
      </ActivityContainer>
    </Router>
  );
};

export default AppContainer;
