import { useEffect, useState } from 'react';
import { FullScreenLoader } from '../loader.js';
import { useHistory } from 'react-router-dom';
import { getLocal, getUserData } from '../../../helpers/projectHelper.js';
import { PREVENT_ROUTES, ROLE_NAV } from '../../../config/constant/projectConstant.js';
import { useSession } from '../../../helpers/hooks';
import { matchPath, useLocation } from 'react-router-dom/cjs/react-router-dom';
import { EMPLOYER_DASHBOARD } from '../../../config/constant/routePathConstant';
import { subscriptionSelector } from '../../../redux/slicers/sessionSlice';
import {  useSelector } from 'react-redux';
export const WebsiteSessionChecker = () => {
   const history = useHistory();
   const [loading, setLoading] = useState(true);
   
   useEffect(() => {
      const token = getLocal();
      const { user_type = '' } = getUserData() ?? {};
      const path = ROLE_NAV?.[user_type] ?? '';
      if (token && path) {
         history.push(path);
      }
      setLoading(false);
   }, []);
   
   return loading && <FullScreenLoader/>;
};

export const OtherSessionsChecker = ({ checkCondition = true,currentRoute,allowedRoutes=[],unverifiedPath='',sessionFailPath, onlyToken = false, children }) => {
   const history = useHistory();
   const location = useLocation();
   const { pathname } = location
   const [success, setSuccess] = useState(false);
   const [loading, setLoading] = useState(true);
   // const {
   //    is_subscription_active = null,
   //  } = useSelector(subscriptionSelector);

   useEffect(async() => {
      const token = getLocal();
      const { user_type = '' , plan_details = {} } = getUserData() ?? {};
      const { is_subscription_active } = plan_details ?? {};
      const path = ROLE_NAV?.[user_type] ?? '';
      const isPrevented = PREVENT_ROUTES.some(a=> matchPath(a,{path:pathname,strict:false,exact:true}));
      if (!onlyToken) {
         if (token && path && path !== currentRoute && !isPrevented) {
            history.push(path);
         }
         setSuccess(true);
      }
      if (!token) {
         history.push(sessionFailPath);
         setSuccess(false);
      }
      
      if(!checkCondition && user_type === 'job_seeker'){
         const checkIsAllowed = allowedRoutes.some(a=> matchPath(a,{path:pathname,strict:false,exact:true}));
         if(!checkIsAllowed){
            history.push(unverifiedPath)
         }
      }
    
      if (!is_subscription_active && user_type === 'employer'){
         
         const checkIsAllowed = allowedRoutes.some(a=> matchPath(pathname,{path:a,strict:true,exact:true}));
       
         if(!checkIsAllowed){
            history.push(`${EMPLOYER_DASHBOARD}?q=basic`)
         }
      }
      setLoading(false);
   }, [pathname]);
   
   return loading ? <FullScreenLoader/> : success && children;
};
