import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { renameList } from '../../actions/board';
import { TextField } from '@material-ui/core';

const ListTitle = ({ list }) => {
  const [editing, setEditing] = useState(false);
  const [title, setTitle] = useState(list.title);
  const dispatch = useDispatch();

  useEffect(() => {
    setTitle(list.title);
  }, [list.title]);

  const onSubmit = async (e) => {
    e.preventDefault();
    let data = {
      id: list._id,
      title: title,
    };
    dispatch(renameList(data));
    setEditing(false);
  };

  return !editing ? (
    <h3 className="list-title" onClick={() => setEditing(true)}>
      {list.title}
    </h3>
  ) : (
    <form onSubmit={(e) => onSubmit(e)}>
      <TextField
        required
        style={{ color: '#33444d' }}
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
    </form>
  );
};

ListTitle.propTypes = {
  list: PropTypes.object.isRequired,
};

export default ListTitle;
