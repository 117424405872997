import React from 'react';
import { emphasize, makeStyles, withStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Chip from '@material-ui/core/Chip';
import { useHistory, useParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& .MuiBreadcrumbs-ol': {
      color: '#ffffff',
    },
  },
}));

const StyledBreadcrumb = withStyles((theme) => ({
  root: {
    backgroundColor:"rgb(217 217 217)",
    height: "30px",
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.grey[300],
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12),
    },
    '& .MuiBreadcrumbs-ol': {
      color: '#ffffff',
    },
    "& .MuiChip-clickable":{
      backgroundColor:"rgb(217 217 217) !important",
      fontSize: '14px', 
      padding: '10px',
      [theme.breakpoints.down("md")]: {
        fontSize: '12px', 
        padding: '8x',
      }
    }
  },
}))(Chip);

export default function CustomizedBreadcrumbs() {
  const history = useHistory();
  const classes = useStyles();

  const location = window.location.pathname.split('/')
  function handleClick(event) {
    let paths = window.location.pathname.split('/');
    history.push(`/${paths[1]}/${paths[2]}/${paths[3]}/${event}`);
    history.push(`${event}`);
  }

  return (
    <Breadcrumbs aria-label="breadcrumb" className={classes.root}>
      <StyledBreadcrumb
        style={{ fontWeight: 500,pointerEvents: location[location.length - 1] == "home" ? "none" : "auto", backgroundColor: location[location.length - 1] == "home" ? "#b3b1ab" : "rgb(217 217 217)",color: location[location.length - 1] == "home" ? "#ffffff" : "black"}}
        href={`/home`}
        label="Home"
        onClick={() => handleClick('home')}
      />

      <StyledBreadcrumb
        style={{ fontWeight: 500,pointerEvents: location[location.length - 1] !== "home" ? "none": "auto" , backgroundColor: location[location.length - 1] !== "home" ? "#b3b1ab" : "rgb(217 217 217)" ,color: location[location.length - 1] == "weaklytable" ? "#ffffff" : "black" }}
        label="Weekly table"
        href="/weeklytable"
        onClick={() => handleClick('weaklytable')}
      />
    </Breadcrumbs>
  );
}
