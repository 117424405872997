import React, { useState,useRef, useEffect } from 'react'
// import Link from "next/link";
// import {
//   ENTERPRISE,
//   SIGN_UP_EMPLOYER,
// } from "../../config/constant/routeConstant";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link, useHistory } from 'react-router-dom';
import { EMPLOYER_DASHBOARD } from '../../config/constant/routePathConstant';
import { useDispatch } from 'react-redux';
import { empSubsribePlan, empUpdateSubsribePlan } from '../../api/employer/employer';
import { getUserData } from '../../helpers/projectHelper';
// import { staticMetaRead } from './../../backend/helper/fetch';


const Pricing2 = () => {
  const [flag, setFlag] = useState(false);
  const [plan, setPlan] = useState('yearly');
  const [alertShow, setAlertShow] = useState(false);
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const { user_id, plan_details = {} } = getUserData();
  const data = { employerId: user_id };
  const dispatch = useDispatch();
  const [alertMsg, setAlertMsg] = useState('');
  
  const [active, setActive] = useState(false);
  const [screenSize, setScreenSize] = useState(0)
  const sliderSettings = {
    dots: true,
    arrows:false
  };
  const ref = useRef(null);
  const iconPath = '/static/img/sub_pricing.png'

  const handleNextSlide = () => {
    ref.current.slickNext();
  };

  const handlePrevSlide = () => {
    ref.current.slickPrev();
  };
  const testimonialArr = [
    {
      q: 'I hired using Virtual Staff for my digital business ventures. The website earned my trust as they have been in the business for more than six years. They have cultivated a brand that is dependable, reliable, and trustworthy.',
      a: 'Ryan Smith, CEO ',
    },
    {
      q: "Great place to find quality staff in the Philippines. Used this platform for several years now,  Would recommend and they have a service where they handpick, manage the VA's for you.",
      a: 'Philip Matthews, Talent Acquisition Head  ',
    },
    {
      q: 'I’ve been using this site for the past 3 years and found it easy to use to find quality virtual assistants for my real estate financing business. It’s run professionally.',
      a: 'Michelle Gomez, Director',
    },
    {
      q: 'This company is one of the easiest and yet affordable way to outsource virtual staffs and assitants in the Philippines. You have the chance to interact, meet and be able to team up with different kind of people accross the globe. Also, the platform was good as a whole! Thumbs up!',
      a: 'Tiffany Cox, Human Resource Business Partner',
    },
    {
      q: 'Amazing place to hire,really good quality virtual staff very knowlegable and at affordable prices.',
      a: 'Jerry Ling, Hiring Manager',
    },
  ];
  const arrthree = ["Post jobs", "Job approval within 24 hours"];
  const arr = [
    {
      key: "Post jobs",
      value: "Unlimited",
      tooltip:
        "Looking to hire multiple workers? Create and post unlimited jobs and let workers apply.",
    },
    {
      key: "Message applicants",
      value: "Unlimited",
      tooltip:
        "Reach out to qualified jobseekers and discuss job requirements without any limitations.",
    },
    {
    key: "Hire staff",
    value: "Unlimited",
    tooltip:
        "Build an entirely remote team and hire as many workers as you wish.",
    },
    {
    key: "Customer support",
    value: "24/5",
    tooltip:
        "Talk to our live chat customer care agents, email us, or message us on messenger to get immediate support.",
    },
    {
      key: "Send job offers",
      value: "Unlimited",
      tooltip:
        "Reach out to jobseekers with tailored job offers without any limitations.",
    },
    
    {
      key: "Job approval time",
      value: "Instant",
      tooltip:
        " Post a job and have it approved instantly on the platform for applicants to see.",
    },
    {
      key: "Time tracking",
      value: "Free to Use!",
      tooltip:
        "Track the hours your workers work by using our time-tracking software absolutely for free.",
    },
    {
      key: "Payment processing fee",
      value: " 0%",
      tooltip:
        "Pay your workers securely within the platform without incurring any payment processing fee.",
    },
    {
      key: "Bookmark workers",
      value: "Unlimited",
      tooltip:
        "Save the profiles of the jobseekers that you like to refer to easily whenever you like.",
    },
    
  ];
  const arrtwo = [
    "Client Success Manager (CSM)",
    "Recruitment & HR support",
    "Payroll & compliance",
    "Legal protections",
    "Staff monitoring system",
    "Simple pricing",
  ];
  const faqPricing = [
    {
      q: 'Can you help me build a team in the Philippines?',
      a: [
        'We have extensive experience in building teams in the Philippines for some of the biggest companies. ',
        'Our Enterprise Solution offers compliance, legal protections, enforceable staff contracts, professional recruitment, and even your dedicated Client Success Manager.',
      ],
    },
    {
      q: 'What do I get with the free account?',
      a: [
        'With a free account, you can post jobs and view applicants. This lets you gauge the quality of the talent pool on our platform.'
      ],
    },
    {
      q: "Why can't I hire people with a free account?",
      a: [
        'We want to maintain a safe and legitimate platform for both employers and job seekers. ',
        'If employers could message workers without paying, it could lead to spam and scams, and ultimately hurt the experience for everyone.',
        'By offering a subscription-based site, we can ensure that only honest and serious businesses use our platform, providing a high-quality talent pool for our users.',
        'This also means that as an employer, you have access to a much wider talent pool.',
      ],
    },
    {
        q:"Why do I need to pay a subscription up-front before hiring someone?",
        a:['We offer an industry-leading money-back guarantee to eliminate any risk for our users.',
            "We want to prove to you that you've made the right decision in choosing our platform to build your team in the Philippines.",
            "With our bold guarantee, you can trust that we stand behind the quality and affordability of our talent pool."
        ]
    },
    {
        q:'Why do you only offer one price option? (what about a cheaper option?)',
        a:['We wanted to keep things simple and all-inclusive for our users. Our $99/month subscription includes unlimited features and no limitations.',
          "By offering a flat rate, we can provide the best value to our users and attract serious businesses looking to hire world-class staff in the Philippines.",
          "The yearly plan at $297/year (less than $25/month) offers even more savings. This price allows us to invest in platform development, improve the user experience, and provide safety and protection for our users."
      ]
    },
    {
      q:"Why choose a subscription platform instead of a free model like some other “freelance sites”?",
      a:[
        "At VirtualStaff.ph, we take pride in offering you the best value for your money. Here are some reasons why we have a subscription model:",
        "We exclusively offer you access to talent in the Philippines at unbeatable rates. Our subscription fee allows us to maintain our high-quality standard of talent and ensure that you get the best value for your money.",
        `Unlike other "free" sites, we don't charge you payment processing fees, which can add up over time.`,
        'We are designed for businesses that want to hire part-time or full-time staff on a longer-term basis. This means you can benefit from compounded savings over time as you build your team with us.',
        "We believe in transparent pricing. What you see is what you get. You'll only pay the rate agreed upon with your chosen talent, with no additional fees or charges.",
        'Our subscription fee also allows us to invest in our platform properly, make continuous improvements, attract top tech talent, and provide world-class customer support. ',
        'We are more than just a "marketplace" for hiring people. We want to be your outsourcing partner and help you scale up your team with confidence.'
      ]
    },
    {
      q:"Is the service truly unlimited?",
      a:[
        'Absolutely! With VirtualStaff.ph, you can hire as many staff as you need to scale your business.'
      ]
    },
    {
      q:"Can I switch from a monthly to a yearly subscription?",
      a:[
        'Yes, you can upgrade from a monthly to a yearly subscription at any time. Just go to your account settings and select the "Upgrade" option.'
      ]
    },
    {
      q:"What if I want to cancel my subscription?",
      a:[
        "You can cancel your subscription at any time from within your account."
      ]
    },
    {
      q:"DOES MY SUBSCRIPTION AUTO-RENEW?",
      a:[
        "Yes, all of our monthly and annual subscription plans will auto-renew unless canceled.",
        "We understand that some customers may prefer not to have their subscription renew automatically, so we recommend keeping track of your subscription renewal date and canceling before the renewal date if you do not wish to continue with the subscription.",
      ]
    }
  ];
  const unlimitedCardDetails = [
    {
      title: "Post jobs",
      description: "Unlimited",
    },
    {
      title: "Message applicants",
      description: "Unlimited",
    },
    {
      title: "Hire staff",
      description: "Unlimited",
    },
    {
      title: "Customer support",
      description: "24/5",
    },
    {
      title: "Send job offers",
      description: "Unlimited",
    },
    {
      title: "Job approval time",
      description: "Instant",
    },
    {
      title: "Time tracking",
      description: "Free to Use!",
    },
    {
      title: "Payment processing fee",
      description: "0%",
    },
    {
      title: "Bookmark workers",
      description: "Unlimited",
    },
  ];
  const enterpriseCardDetails = [
    {
      title: "Client Success Manager (CSM)",
      description: "An expert in your corner",
    },
    {
      title: "Recruitment",
      description: "We'll find you, world-class staff",
    },
    {
      title: "HR Support",
      description: "Easily build your team in the Philippines",
    },
    {
      title: "Compliance & Laws",
      description: "Stay on top of the Philippine local & national laws",
    },
    {
      title: "Legal Protections",
      description: "Enforceable contracts, NDAs, Non-solicitation",
    },
    {
      title: "Staff monitoring system",
      description: "We guarantee that your staff is working",
    },
    {
      title: "It's Our Liability, Never Yours!",
      description: "We assume all legal responsibilities in the Philippines",
    },
    {
      title: "All-inclusive pricing",
      description: "Everything is taken care of",
    },
  ];
  const subscribePlan = (a) => {
    if (plan_details?.is_subscription_active && plan_details?.subscription_plan == 'year') {
      setAlertMsg(a)
      setAlertShow(true);
      return;
    }

    if (plan_details?.is_subscription_active && plan_details?.subscription_plan == 'month' && a === 'monthly') {
      setAlertMsg(a)
      setAlertShow(true);
      return;
    }
    setLoading(true);
    let Obj = {
      product: a,
      coupon: true,
      ...data,
    };
    if(a === 'yearly'){
      if(plan_details?.is_subscription_active){
        dispatch(empUpdateSubsribePlan({'employerId': user_id},setLoading))
      }else{
        dispatch(empSubsribePlan(Obj, setLoading, history));
      }
      
      return
    }
    dispatch(empSubsribePlan(Obj, setLoading, history));
  };
useEffect(() => {
  setScreenSize(window.innerWidth)
}, [])

  return (
    // <WebsiteContainer meta={seo?.meta} icon={iconPath} title={seo?.title}>
    //   <style jsx global>
    //     {SubscriptionStyles}
    //   </style>
      <div className="emp-pricing-section" style={{backgroundColor:"white"}}>
        <div className="emp-pricing-header">
          <div className="sub-pricing container">
            <div className="heading headings mb-5">
              <h1>Build Your Dream Team with <br></br> Ease and Affordability </h1>
              <h3>
              Unlock Insane Savings on Employee Costs and Get Access to the Best Talent in the Philippines!
              </h3>
            </div>
            <div className='buisness_struggle row mb-5 align-items-center'>
                <div className='col-lg-6 col-md-12 col-sm-12 col-xs-12'>
                    <p>Are you ready to skyrocket your business growth without breaking the bank?</p>
                    <p>Our subscription plans offer you exclusive access to a pool of top-tier talent in the Philippines, enabling you to supercharge your operations like never before.</p>
                    <p>Choose from our monthly or annual subscription plans and enjoy the benefits of unmatched employee cost savings.</p>
                    <p>With hourly rates ranging between <b>$2.00 and $10.00/hr</b>, you'll be able to hire a full-time team of professionals for a fraction of the cost of hiring locally.</p>
                    <p>Join the thousands of businesses that have already experienced the power of Virtual Staff and take your business to the next level.</p>
                    <p>Unlock insane cost savings and access the best talent in the Philippines!</p>
                </div>
                <div className='col-lg-6 col-md-12 col-sm-12 col-xs-12'>
                    <img src="/images/Arrow_2.png" className='w-100'/>
                </div>
            </div>
            <div className='d-flex justify-content-center m-3'>
            <div className="emp-pricing-toggle-btn">
                <button className={active ?  "active" : "not-active"} onClick={() => setActive(true)}>Monthly Plan</button>
                <button className={!active ? "active"  : "not-active"} onClick={() => setActive(false)}>Annual Discount</button>
            </div>
            </div>
            <div className="price-cards row mb-5">
              <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 row-eq-height mt-5 padding-adjust">
                <div className="cards small-cards">
                  <div className="heading grey-bg">
                    <h4>Do It Yourself</h4>
                    <p>FREE USER</p>
                  </div>
                  <div className="price">
                    <h6 style={{ marginTop: "20px", color: "#717171" }}>$0</h6>
                  </div>
                  <p className="cards-para">
                    Have a glimpse of the Philippines Safest Outsourcing
                    Marketplace
                  </p>
                  <div className="features mt-5">
                    <div className="fea">
                      <img src="/images/Green-tic.png"></img>
                      <p>Post Jobs</p>
                    </div>
                    <div className="fea">
                      <img src="/images/Green-tic.png"></img>
                      <p>Job approval within 24 hours</p>
                    </div>
                  </div>
                  <div className="btn-div">
                  <h3 style={{ textDecoration: 'underline', color: '#565353', fontWeight: 'bold',width:"100%",marginBottom:"20px" }}>
                  <Link className='return' to={EMPLOYER_DASHBOARD}>Return to Dashboard</Link>
                </h3>
                  </div>
                  <div className="space-div"></div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 row-eq-height mt-5  padding-adjust">
                <div className="cards small-cards" style={{position:"relative"}}>
                {!active?<div className='card_toggle_banner'>
                    <p>Save 75%</p>
                  </div>:<></>}
                  <div className="heading blue-bg">
                    <h4>Do It Yourself</h4>
                    <p>UNLIMITED - {!active?"Yearly":"Monthly"}</p>
                  </div>
                  <div className="price">
                  {!active?<><p style={{ marginTop: "2px" }}>$99 x 12 = $1188</p>
                    <h6 style={{ marginTop: "-10px", color: "#004C70" }}>
                      $297<span>/ Year</span>
                    </h6></>:<h6 style={{ marginTop: "20px", color: "#004C70" }}>
                      $99<span>/ Month</span>
                    </h6>}
                  </div>
                  <div className="features">
                    {arr.map((e) => {
                      return (
                        <>
                          <div className="fea">
                            <img src="/images/Green-tic.png"></img>
                            <p>{e.key}</p>
                            <div
                              className="tooltips swing ml-1"
                              data-title={e.tooltip}
                            >
                              <img
                                className="i"
                                src="/images/i-icon.png"
                              ></img>
                            </div>
                          </div>
                          <div className="des">{e.value}</div>
                        </>
                      );
                    })}
                  </div>
                  <div className="btn-div d-block">
                  <button className="cards-btn blue-bg mr-0"  onClick={() => subscribePlan(active?'monthly':'yearly')}>Get Now</button>

                  </div>
                  <div className="space-div"></div>
                </div>
              </div>
              {/* <div
                className="col-lg-3 col-md-6 col-sm-12 col-xs-12 row-eq-height mt-5  padding-adjust"
                style={{ position: "relative" }}
              >
                <div className="cards-banner">
                  <img
                    src="/assets/images/bestoffer.png"
                  />
                </div>
                <div className="cards small-cards">
                  <div className="heading green-bg">
                    <h4>Unlimited - Yearly</h4>
                    <p>Everything is unlimited.<br></br>
Pay yearly!
</p>
                  </div>
                  <div className="price">
                    <p style={{ marginTop: "2px" }}>$99 x 12 = $1188</p>
                    <h6 style={{ marginTop: "-10px", color: "#008100" }}>
                      $297/<span>Year</span>
                    </h6>
                  </div>
                  <div className="features">
                    {arr.map((e) => {
                      return (
                        <>
                          <div className="fea">
                            <img src="/assets/images/Green-tic.png"></img>
                            <p>{e.key}</p>
                            <div
                              className="tooltips swing ml-1"
                              data-title={e.tooltip}
                            >
                              <img
                                className="i"
                                src="/assets/images/i-icon.png"
                              ></img>
                            </div>
                          </div>
                          <div className="des">{e.value}</div>
                        </>
                      );
                    })}
                  </div>
                  <div className="btn-div">
                  <Link
                     href={SIGN_UP_EMPLOYER}
                    >
                    <button className="cards-btn green-bg">Get Now</button>
                    </Link>
                  </div>
                  <div className="space-div"></div>
                </div>
              </div> */}
              <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 row-eq-height mt-5 padding-adjust">
                <div className="cards small-cards" style={{ paddingTop: "0px" }}>
                  <div className="enterprise-heading">
                    <h4>Enterprise</h4>
                    <p>
                      Hire staff in the Philippines with complete confidence
                    </p>
                  </div>
                  <div className="features">
                    {enterpriseCardDetails.map((e) => {
                      return (
                        <>
                          <div className="fea">
                            <img src="/images/Green-tic.png"></img>
                            <p>{e.title}</p>
                          </div>
                          <div className="des">{e.description}</div>
                        </>
                      );
                    })}
                  </div>
                  <div className="btn-div ">
                  <button className="cards-btn brown-bg"  onClick={() => {
                    window.open('https://virtualstaff.ph/enterprise');
                  }}>Learn More</button>
                  </div>
                  <div className="space-div"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="emp-pricing-footer">
            <img src="/images/moneyback.png" />
            <div className="inner-div inner-divs w-100 py-4">
              <h2 className="mt-3 bold-text">30 Day Money Back Guarantee</h2>
              <div
                style={{ width: "80%" }}
                className="mt-2 text-center mx-auto"
              >
                <p>
                We're confident that you'll love the staff you hire with VirtualStaff.ph. That's why we offer a 30-day quality staff guarantee.
                </p>
                <p>If, within your first 30 days, you're not satisfied with the quality of your staff, we'll work with you to find a replacement or provide you with a full refund. No questions asked!</p>
                <p>We take the risk, so you don't have to. </p>
                <p style={{ color: "black" }}>
                  Simply email{" "}
                  <a
                    className=" font-weight-bolder"
                    style={{ borderBottom: "1px solid black" }}
                    href="mailto:support@virtualstaff.ph"
                  >
                    support@virtualstaff.ph
                  </a>
                  , and we'll take care of the rest.
                </p>
               <p>With our quality staff guarantee, you can feel confident and secure in your decision to hire with VirtualStaff.ph</p>
              </div>
            </div>
          </div>
          <div className="review reviews my-5 container ">
            <h2 className="mb-5">Join thousands of satisfied customers who have saved time and money by hiring their own team of virtual staff in the Philippines.</h2>
            <div className="row">
            <Link to="https://www.trustpilot.com/review/www.virtualstaff.ph" passHref>
              <a  target="_blank">
              <div className="col-lg-4 testimonial" >
               {/* {screenSize > 450? */}
               <img src="/images/review.png" className="review-img"/>
              {/* :
              <img src="/images/trustpilot.png" className="review-img"/>
                } */}
              </div>
              </a>
              </Link>
              
              <div className="col-lg-8">
              <Slider {...sliderSettings} ref={ref}>
                  {testimonialArr.map((item, i) => (
                    <div key={i} className="review-card">
                      <p>
                       {item.q}
                      </p>
                      <h5>- {item.a}</h5>
                    </div>
                  ))}
                </Slider>
                <div className="slick-btn-div">
                  <div onClick={handlePrevSlide}>
                    <button>{`<`}</button>
                  </div>
                  <div onClick={handleNextSlide}>
                    <button>{`>`}</button>
                  </div>
                  </div>
              </div>
            </div>
          </div>faqPricing
         
          <div className="enterprise-faq mb-5">
            <div className="faq">
              <div className="container">
                <div className="faq-title">
                  <h2>FREQUENTLY ASKED QUESTIONS</h2>
                </div>
                <div className="accordion enterprise-accordian pricing-faq" id="faq">
                  <h2 style={{color:"#004C70"}}>Subscription & Services</h2>
                   {faqPricing?.map((ele,i) => (
                          <div className="card">
                          <div className="card-header" id="faqhead1">
                            <a
                              href="#"
                              className="btn btn-header-link"
                              data-toggle="collapse"
                              data-target={'#faq' + i}
                              aria-expanded="true"
                              aria-controls="faq1"
                             
                            >
                              {" "}
                               {ele.q}
                            </a>
                          </div>
      
                          <div
                            id={'faq' + i}
                            className="collapse"
                            aria-labelledby="faqhead1"
                            data-parent="#faq"
                          >
                            <div className="card-body">
                              {ele.a.map((e,idx) => (
                                       <p style={{textAlign:"left"}}>
                                       {e}
                                     </p>
                              ))}
                              
                            </div>
                          </div>
                        </div>

                   ))}
                  <div className="card">
                          <div className="card-header" id="faqhead1">
                            <a
                              href="#"
                              className="btn btn-header-link"
                              data-toggle="collapse"
                              data-target={'#faq' + 14}
                              aria-expanded="true"
                              aria-controls="faq1"
                             
                            >
                              {" "}
                              HOW CAN I CANCEL MY SUBSCRIPTION?
                            </a>
                          </div>
      
                          <div
                            id={'faq' + 14}
                            className="collapse"
                            aria-labelledby="faqhead1"
                            data-parent="#faq"
                          >
                            <div className="card-body">
                            <p style={{textAlign:"left"}}>
                            To cancel your subscription, simply log in to your <a href="https://app.virtualstaff.ph/">VirtualStaff.ph</a> account and follow the step-by-step guide provided in our support center.
                                     </p><p style={{textAlign:"left"}}>
                                     We recommend canceling at least 24 hours before your subscription renewal date to ensure that the cancellation takes effect before your next billing cycle.
                                     </p><p style={{textAlign:"left"}}>
                                     If you encounter any issues while canceling your subscription, our support team is available to assist you.
                                     </p>
                                     <p style={{textAlign:"left"}}>
                                     Please note that canceling your subscription will not result in a refund, unless you request it within the first 30 days of your initial subscription.
                                     </p>
                                       <p style={{textAlign:"left"}}>
                                       Here's a step-by-step guide on how to cancel your subscription. <a href="https://support.virtualstaff.ph/442170-How-to-Cancel-Your-Subscription">https://support.virtualstaff.ph/442170-How-to-Cancel-Your-Subscription</a>
                                     </p>
                              
                            </div>
                          </div>
                        </div>
              
                        <h2 style={{color:"#004C70"}} className="mt-3">Hiring & Managing Staff</h2>
                        {/* <div className="card">
                          <div className="card-header" id="faqhead1">
                            <a
                              href="#"
                              className="btn btn-header-link"
                              data-toggle="collapse"
                              data-target={'#faq' + 9}
                              aria-expanded="true"
                              aria-controls="faq1"
                       
                            >
                              {" "}
                              How do I hire talent?

                            </a>
                          </div>
       
                          <div
                            id={'faq' + 9}
                            className="collapse"
                            aria-labelledby="faqhead1"
                            data-parent="#faq"
                          >
                            <div className="card-body">
                               <p>
                               It’s incredibly easy. All you need to do is contact, interview, and send a job offer to the applicants you want to hire.
                               </p>
                              
                        <p>
                        This is done by clicking either the  “send offer” or “hire staff” button inside your VirtualStaff.ph account.

                        </p>
                       
                            </div>
                          </div>
                        </div>    */}
                        <div className="card">
                          <div className="card-header" id="faqhead1">
                            <a
                              href="#"
                              className="btn btn-header-link"
                              data-toggle="collapse"
                              data-target={'#faq' + 10}
                              aria-expanded="true"
                              aria-controls="faq1"
                            
                            >
                              {" "}
                              How do I hire staff?


                            </a>
                          </div>
       
                          <div
                            id={'faq' + 10}
                            className="collapse"
                            aria-labelledby="faqhead1"
                            data-parent="#faq"
                          >
                            <div className="card-body">
                               <p>
                               It’s incredibly easy. All you need to do is contact, interview, and send a job offer to the applicants you want to hire.

                               </p>
                               <p>This is done by clicking either the “send offer” or “hire staff” button inside your VirtualStaff.ph account.</p>
                              
                      
                       
                            </div>
                          </div>
                        </div> 
                        <div className="card">
                          <div className="card-header" id="faqhead1">
                            <a
                              href="#"
                              className="btn btn-header-link"
                              data-toggle="collapse"
                              data-target={'#faq' + 11}
                              aria-expanded="true"
                              aria-controls="faq1"
                           
                            >
                              {" "}
                              How do I process payments for my staff?


                            </a>
                          </div>
       
                          <div
                            id={'faq' + 11}
                            className="collapse"
                            aria-labelledby="faqhead1"
                            data-parent="#faq"
                          >
                            <div className="card-body">
                               <p>
                               After your staff accepts your job offer, you can easily process payments directly from within VirtualStaff.ph by clicking the "pay staff" button.


                               </p>
                               <p>Choose a payment schedule that works best for you and your staff, whether it's weekly, bi-weekly, or monthly.</p>
                              
                      
                       
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div className="card-header" id="faqhead1">
                            <a
                              href="#"
                              className="btn btn-header-link"
                              data-toggle="collapse"
                              data-target={'#faq' + 12}
                              aria-expanded="true"
                              aria-controls="faq1"
                         
                            >
                              {" "}
                              How do I terminate the contract of a staff member?


                            </a>
                          </div>
       
                          <div
                            id={'faq' + 12}
                            className="collapse"
                            aria-labelledby="faqhead1"
                            data-parent="#faq"
                          >
                            <div className="card-body">
                               <p>
                               If you need to terminate the contract of one of your staff members, simply click the "terminate" button or icon found on your employer dashboard. 


                               </p>
                               <p>
                               Keep in mind that if you owe the staff member any outstanding payments for hours worked, you'll need to settle that with them before terminating their contract.

                               </p>
                      
                       
                            </div>
                          </div>
                        </div> 
                        <div className="card">
                          <div className="card-header" id="faqhead1">
                            <a
                              href="#"
                              className="btn btn-header-link"
                              data-toggle="collapse"
                              data-target={'#faq' + 13}
                              aria-expanded="true"
                              aria-controls="faq1"
                            
                            >
                              {" "}
                              Can I communicate with staff outside the VirtualStaff.ph platform?

                            </a>
                          </div>
       
                          <div
                            id={'faq' + 13}
                            className="collapse"
                            aria-labelledby="faqhead1"
                            data-parent="#faq"
                          >
                            <div className="card-body">
                               <p>
                               Yes, once you've hired staff members, you can communicate with them inside or outside of the platform. 



                               </p>
                               <p>
                               However, to protect your business and ensure a transparent and accountable working relationship, we recommend communicating about salary and work hours within the platform.


                               </p>
                      
                       
                            </div>
                          </div>
                        </div> 
                </div>

                
              </div>
            </div>
          </div>

          {/* <div className="m-2">
            <h1
              className="p-2  font-weight-bold  text-black"
              style={{ color: "black" }}
            >
              Frequently Asked Questions
            </h1>
          </div> */}
          {/* <div className="p-5 d-flex justify-content-center emp-pricing-faq mt-1">
            <div id="faq">
              {faq.map((ele, idx) => (
                <div className="card">
                  <div className="card-header" id="faqhead1">
                    <a
                      href="#"
                      className="btn btn-header-link"
                      data-toggle="collapse"
                      data-target={"#faq" + idx}
                      aria-expanded="true"
                      aria-controls="faq1"
                    >
                      {" "}
                      {ele?.q}
                    </a>
                  </div>

                  <div
                    id={"faq" + idx}
                    className="collapse"
                    aria-labelledby="faqhead1"
                    data-parent="#faq"
                  >
                    <div className="card-body">
                      <p className="text-align-left">{ele?.a}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div> */}
        </div>
      </div>
    // </WebsiteContainer>
  );
};

export async function getStaticProps(ctx, o) {
  const post_url = 'subscription-pricing';
  // const data = await staticMetaRead(post_url)

  return {
    props: {  },
    revalidate: 10
  }
}

export default Pricing2;


