export const TRACKER_APP_DOWNLOAD_WINDOWS = 'https://s3.amazonaws.com/www.virtualstaff.ph/vsphworklog-v1.0.6-1.0.6sg.exe'
export const JITSI_SERVER_ROOT = 'meet.virtualstaff.ph'
export const SOCKET_API_ROOT = 'https://taskmngt.virtualstaff.ph';
export const WEB_SOCKET_API_ROOT = 'https://websocket.virtualstaff.ph';
export const LOGIN_API_ROOT = 'https://authapi.virtualstaff.ph/api/v1';
export const JOB_SEEKER_API_ROOT ='https://jobseekerapi.virtualstaff.ph/api/v1';
export const EMPLOYER_API_ROOT = 'https://employerapi.virtualstaff.ph/api/v1';
export const ADMIN_API_ROOT = 'https://authapi.virtualstaff.ph/api/v1';
export const LOCAL_HOST_BACKEND_JOBSEEKER = 'https://employer.virtualstaff.ph/api/v1';
export const LOCAL_HOST_BACKEND_EMPLOYER = 'https://jobseeker.virtualstaff.ph/api/v1';
export const TASK_MANAGEMENT_API = 'https://taskmngt.virtualstaff.ph/api/v1'
export const TASK_MANAGEMENT_ROOT_API = 'https://taskmngt.virtualstaff.ph'
export const TASK_MANAGEMENT_ROOT_API_BACKEND = 'https://taskmngt.virtualstaff.ph/api/v1/task-management'
export const WORK_LOG_API_ROOT = 'https://timetracker.virtualstaff.ph/api/v1';
export const ADMIN_DASHBOARD_CHAT_TOTAL = "/chat/chat-count";
export const ADMIN_DASHBOARD_GET_EMPLOYER_LOG = "/admin/employer-profile-log";
export const DOWNLOAD_API = 'https://98mmn8mjed.us-east-1.awsapprunner.com/api/v1/file/download';
export const LOGIN_API = '/auth/login';
export const LOGOUT_API = '/auth/logout';
export const REGISTER_API = '/auth/register';
export const FORGOT_PASSWORD_API = '/auth/forgot-password';
export const RESET_PASSWORD_API = '/auth/reset-password';
export const CHANGE_PASSWORD_API = '/auth/change-password';
export const ACCOUNT_ACTIVATION_API = '/auth/account-activation';
export const CHANGE_PASSWORD = '/auth/change-password';
export const NEW_PASSWORD_API = '/auth/new-password';
export const PASSWORD_ACTIVATION_API = '/auth/new-password/activation';
export const GET_FILE_API = LOGIN_API_ROOT + '/file/download/'
export const GOOGLE_REGISTER_API = '/auth/google-register';
export const GOOGLE_LOGIN_API = '/auth/google-login';
// ticketing system api - auth
export const AUTH_TICKET_CREATE_API = "/ticket/create"
export const AUTH_TICKET_EMPLOYER_GET_API = "/employer/ticket/filter"
export const AUTH_TICKET_JOB_SEEKER_GET_API = "/job-seeker/ticket/filter"
export const AUTH_TICKET_DELETE_API = "/ticket/delete"
export const AUTH_TICKET_UPDATE_API = "/ticket/update"
export const AUTH_TICKET_COMMENT_API = "/ticket/comment"
export const AUTH_GET_EVENT_DETAILS = "/auth/event-details"

export const ADMIN_LOGIN_API = "/admin/login"
//jobseeker api
//Deshboard IN Job Seeker
export const BOOKMARK_API = '/job-seeker/jobs/saved-jobs-list';
export const JOBSEEKER_PROFILE_CHECK = '/job-seeker/profile/required-check';

//Find jobs
export const JOB_LISTS_API = '/job-seeker/jobs/list';
export const SEARCH_JOB_API = '/job-seeker/jobs/search-jobs';
export const CATEGORY_LIST_API = '/job-seeker/skills-category/list';
export const INDIVIDUAL_JOB_API = '/job-seeker/jobs/individual-job-list';
export const SKILL_JOB_API = '/job-seeker/skills/list';
export const APPLY_JOB_API = '/job-seeker/jobs/apply';
export const APPLIED_JOBS_LIST = '/job-seeker/jobs/applied-jobs-list';
export const SAVE_JOB_API = '/job-seeker/jobs/save-jobs';
export const SAVED_JOB_LIST = '/job-seeker/jobs/saved-jobs-list';
export const ISAPPLIED_API = '/job-seeker/jobs/individual-applied-jobs-list';
export const ISSAVED_API = '/job-seeker/jobs/individual-saved-jobs-list';
export const UNSAVE_API = '/job-seeker/jobs/unsave-jobs';
export const JOBSEEKER_JOBALERT_API = '/job-seeker/job-alert/update';
export const JOBSEEKER_JOBALERT_GET = '/job-seeker/job-alert/list'


//Stripe Integration
export const STRIPE_ONBOARD_LINK ='/job-seeker/stripe-onboard'
export const STRIPE_ONBOARD_STATUS ='/job-seeker/stripe-account'
export const STRIPE_ACCOUNT_BALANCE ='/job-seeker/stripe-check-balance'
export const STRIPE_DASHBOARD_LINK ='/job-seeker/stripe-dashboard'
export const STRIPE_ONBOARD_LINK_EXISTING_USER ='/job-seeker/stripe-onboarding'




//Payment Integration
export const JOBSEEKER_RESIGNATION_API = '/job-seeker/resign-job'
export const JOBSEEKER_CREATE_VENDOR = '/job-seeker/vendor/create';
export const JOBSEEKER_ADD_BANK_DETAILS = '/job-seeker/bank-details/add';
export const JOBSEEKER_LIST_BANK_DETAILS = '/job-seeker/bank-details/get';
export const JOBSEEKER_DELETE_BANK_DETAIL = '/job-seeker/bank-details/delete';
export const JOBSEEKER_UPDATE_BANK_DETAIL = '/job-seeker/bank-details/update';
export const JOBSEEKER_PAYMENT_HISTORY = '/job-seeker/hired-jobs-list';
export const JOBSEEKER_PAYMENT_REQUEST = '/job-seeker/payment-request';
export const JOBSEEKER_PAYMENT_REQUEST_LIST = '/job-seeker/payment-request/list';
export const JOBSEEKER_INVOICE_LIST = '/job-seeker/payment-invoice/list';
export const JOBSEEKER_INVOICE_UPDATE_API = '/job-seeker/payment-invoice/update';
export const JOBSEEKER_INVOICE_AMEND_API = '/job-seeker/payment-invoice/amend';
export const JOBSEEKER_TOTAL_HOUR_WORK = '/employer/total-worked-hours';
export const JOBSEEKER_PAYMENT_DISPUTE_TICKET = '/job-seeker/payment-invoice/dispute';
export const JOBSEEKER_INDIVIDUAL_INVOICE = '/job-seeker/payment-invoice/get';

// --- profile page
export const JOBSEEKER_CATEGORY_LIST = '/job-seeker/skills-category/list';
export const JOBSEEKER_JOB_TITLE_LIST = '/job-seeker/job-title/list';
export const JOBSEEKER_SKILLS_LIST = '/job-seeker/skills/list';
export const JOBSEEKER_EDUCATION_LIST = '/job-seeker/education/list';
export const JOBSEEKER_ADD_EDUCATION = '/job-seeker/education/add';
export const JOBSEEKER_UPDATE_EDUCATION = '/job-seeker/education/update';
export const JOBSEEKER_DELETE_EDUCATION = '/job-seeker/education/delete';
export const JOBSEEKER_EMPLOYMENT_LIST = '/job-seeker/experience/list';
export const JOBSEEKER_ADD_EMPLOYMENT = '/job-seeker/experience/add';
export const JOBSEEKER_UPDATE_EMPLOYMENT = '/job-seeker/experience/update';
export const JOBSEEKER_DELETE_EMPLOYMENT = '/job-seeker/experience/delete';
export const JOBSEEKER_LIST_PROFILE = '/job-seeker/profile/list';
export const JOBSEEKER_UPDATE_PROFILE = '/job-seeker/profile/update';
export const JOBSEEKER_GET_FILE = '/file/download/';
export const JOBSEEKER_FILE_UPLOAD = '/file/upload';
export const JOBSEEKER_ADD_PROFILE = '/job-seeker/profile/add';
export const JOBSEEKER_VIEW_OFFERS = '/job-seeker/list-job-offer';
export const UNSUBSCRIBE_NOTIFICATION = '/job-seeker/job-alert/unsubscribe';
export const JOBSEEKER_ACCEPT_JOBOFFER = '/job-seeker/job-offer-action';
export const JOBSEEKER_JOBOFFER_STATUS = '/job-seeker/get-job-offer';
export const JOBSEEKER_NOTIFICATION_SETTING_LIST = '/job-seeker/notification/settings/list';
export const JOBSEEKER_NOTIFICATION_SETTING_UPDATE = '/job-seeker/notification/settings/update';
export const JOBSEEKER_NOTIFY_API = '/job-seeker/notification/count';
export const JOBSEEKER_NOTIFY_UPDATE_API = '/job-seeker/notification/job-offer/count/update';
export const JOBSEEKER_SKILL_SEARCH_API = '/job-seeker/skills/search';
export const JOBSEEKER_SCHEDULED_EVENTS = '/auth/event-list';
export const JOBSEEKER_UPDATE_SCHEDULED_EVENTS = '/auth/event-completed';
export const JOBSEEKER_HIRED_EMPLOYER_LIST = '/job-seeker/hired-employer/list';
export const JOBSEEKER_HIRED_JOB_DETAIL = '/job-seeker/get-hired-job'
export const JOBSEEKER_HIRED_JOB_LIST = '/job-seeker/hired-jobs-list'
export const JOBSEEKER_TRANSACTION_LIST = '/job-seeker/payment-history/filter';
export const JOBSEEKER_BONUS_TRANSACTION = '/job-seeker/bonus/list';
export const JOBSEEKER_PROFILE_REQUIRED_CHECK = '/job-seeker/profile/required-check';
export const JOBSEEKER_DELETE_DECLAINED_JOBS = '/job-seeker/delete-reject-job-offer';
export const JOBSEEKER_PAYMENT_INVOICE_APPROVE = '/job-seeker/payment-invoice/approve';
export const JOBSEEKER_STRIPE_ACCOUNT_DELETE = '/job-seeker/bank-details/delete';
export const JOBSEEKER_UPDATE_EMAIL  = '/job-seeker/profile/send-otp';
export const JOBSEEKER_VERIFY_OTP = '/job-seeker/profile/verify-otp';

//AI
export const JOBSEEKER_GET_TRANSFORM_TEXT ='/job-seeker/profile/get-transformed-text'

//protected
export const JOBSEEKER_SIGNIN_LIST = '/job-seeker/protected/list';
export const JOBSEEKER_CONTRACT_DETAILS = '/job-seeker/protected/details';
export const JOBSEEKER_CONTRACT_AGREE = '/job-seeker/protected/agree';


//employer
export const EMPLOYER_JOB_COUNT = '/employer/jobs/job-count';
export const EMPLOYER_SKILL_LIST = '/employer/jobs/skills-list';
export const EMPLOYER_CATEGORY_LIST = '/employer/jobs/category-list';
export const EMPLOYEE_LIST = '/employer/job-seekers-search';
export const EMPLOYEE_SAVE_CARD = '/employer/bookmark-job-seeker';
export const EMPLOYEE_UNSAVE_CARD = '/employer/unbook-job-seeker';
export const JOBSEEKER_INDIVIDUAL_LIST = '/employer/jobseeker-profile/individual-list';
export const GET_BOOKMARK_JOBSEEKER = '/employer/get-bookmark-job-seeker';
export const GET_BOOKMARK_JOBSEEKER_LIST = '/employer/bookmark-job-seeker-list';
export const EMPLOYER_POST_A_JOB_API = '/employer/jobs/add';
export const EMPLOYER_PAYMENT_DISPUTE_TICKET = '/employer/payment-invoice/dispute';
//chat
export const CHAT_CHECK_EXIST_API = '/chat/check-chat';
export const CHAT_LIST_API = '/chat/chat-list';
export const CHAT_MSG_LIST_API = '/chat/msg-list';
export const CHAT_CHAT_DETAILS = '/chat/details';
export const CHAT_HIRED_API = '/chat/employee';
export const CHAT_CLEAR_API = '/chat/clear';
export const CHAT_VIEW_API = '/chat/view';
export const CHAT_UNREAD_COUNT_API = '/chat/unread-count';
export const CHAT_DELETE_API = '/chat/delete';
export const CHAT_BLOCK_API = '/chat/block';
export const CHAT_MUTE_API = '/chat/mute';

export const EMPLOYER_ACTIVATE_JOB = '/employer/jobs/activate'
export const EMPLOYER_JOB_APPLICANTS_LIST = '/employer/jobs/job-applicants-list';
export const GET_POSTED_JOB_LIST = '/employer/jobs/list';
export const EMPLOYER_UPDATE_JOB = '/employer/jobs/update';
export const EMPLOYER_DELETE_JOB = '/employer/jobs/delete';
export const EMPLOYER_CLOSE_JOB = '/employer/jobs/close';
export const EMPLOYER_INDIVIDAL_JOB = '/employer/jobs/individual-list';
export const EMPLOYER_JOB_VIEWS_COUNT = '/employer/jobs/viewed-applicant-count';
export const EMPLOYER_JOB_FILTER = '/employer/jobs/filter';
export const EMPLOYER_TOTAL_APPLICANTS_LIST = '/employer/jobs/viewed-applicant-profile';
export const EMPLOYER_LIST_COUNT = '/employer/jobs/tot-applicant-count';
export const EMPLOYER_ALL_APPLICANTS_LIST = '/employer/jobs/tot-applicant-profile-list';
export const EMPLOYER_SEND_JOBOFFER = '/employer/send-job-offer';
export const EMPLOYER_ADD_PROFILE = '/employer/profile/add';
export const EMPLOYER_LIST_PROFILE = '/employer/profile/list';
export const EMPLOYER_EDIT_PROFILE = '/employer/profile/update';
export const EMPLOYER_OFFER_LIST = '/employer/list-job-offer';
export const EMPLOYER_NOTIFICATION_LIST = '/employer/notification/settings/list';
export const EMPLOYER_NOTIFICATION_UPDATE = '/employer/notification/settings/update';
export const EMPLOYER_NOTIFY_JOB_OFFER_COUNT = '/employer/notification/job-offer-reply/count/update';
export const EMPLOYER_NEW_APPLICANT_COUNT = '/employer/notification/new-applicant/count/update';
export const EMPLOYER_GET_ALL_NOTIFICATION_COUNT = '/employer/notification/count';
export const EMPLOYER_GET_JOB_OFFER = '/employer/get-job-offer';
export const EMPLOYER_HIRE_STAFF_API = '/employer/employee/hire';
export const EMPLOYER_HIRED_STAFF_LIST = '/employer/employee/list';
export const EMPLOYER_TRANSACTION_LIST = '/employer/payment-history/filter';
export const EMPLOYER_INVOICE_LIST = '/employer/payment-invoice/list';
export const EMPLOYER_APPROVED_INVOICE = '/employer/payment-request/approve';
export const EMPLOYER_JOBTITLE_LIST = '/employer/jobs/job-title-list';
export const EMPLOYER_INDIVIDUAL_OFFER_LIST = '/employer/get-job-offer';
export const EMPLOYER_OFFER_UPDATE = '/employer/update-job-offer';
export const EMPLOYER_HIRED_INDIVIDIUAL_LIST = '/employer/hired-jobs/list';
export const EMPLOYER_CARD_VERIFICATION = '/employer/card/verification';
export const EMPLOYER_GET_HIRED_JOB = '/employer/hired-jobs/get';
export const EMPLOYER_CONTRACT_UPDATE = '/employer/hired-job/update';
export const EMPLOYER_UPDATE_EMAIL  = '/employer/send-otp';
export const EMPLOYER_VERIFY_OTP = '/employer/verify-otp';
export const EMPLOYER_INVOICE_ACTION = '/employer/payment-invoice/payment-action';
export const EMPLOYER_INDIVIUAL_INVOICE = '/employer/payment-invoice/get';
export const EMPLOYER_INVOICE_UPDATE = '/employer/payment-invoice/amend';
export const EMPLOYER_EMPLOYEE_DETAILS = '/job-seeker/employee/individual-employee-profile';
export const EMPLOYER_EMPLOYEE_DETAILS_EMPLOYER = '/employer/employee/individual-employee-profile';
export const EMPLOYER_CONFIRM_JOBOFFER = '/employer/confirm-job-offer';
export const EMPLOYER_EMPLOYEE_TRACKED_TIMES = '/tracker/weakly-time-tracked';
export const EMPLOYER_EMPLOYEE_WEAKLY_TRACKED_TIMES = '/tracker/weakly-time-tracked';
export const EMPLOYER_EMPLOYEE_SAVE_TIMES = '/tracker/savetimer';
export const EMPLOYER_EMPLOYEE_SAVE_IMAGES = '/tracker/saveimages';
export const EMPLOYER_EMPLOYEE_WEAKLY_TABLE = '/tracker/alldates';
export const EMPLOYER_EMPLOYEE_FILTER_WEAKLY_TABLE = '/tracker/filterwithdates';
export const EMPLOYER_EMPLOYEE_FILTER_WEAKLY_IMAGES = '/tracker/alldata-images';
export const EMPLOYER_EMPLOYEE_FILTER_WEAKLY_IMAGES_DATE = '/tracker/filterwithImages';
export const EMPLOYER_EMPLOYEE_FILTER_WEAKLY_IMAGES_DATE_TIMES = '/tracker/filterwithImagestimes';
export const EMPLOYER_EMPLOYEE_DAY = '/tracker/alldata-timers';
export const EMPLOYER_EMPLOYEE_DAY_GROUP = '/tracker/all-data-with-timers-Group';
export const EMPLOYER_EMPLOYEE_TODAY_TRACKED_TIMES = '/tracker/today-time-tracked';
export const EMPLOYER_EMPLOYEE_MANUAL_TRACKED_TIMES = '/tracker/weakly-manual-timetracked';
export const EMPLOYER_RECEIPT = '/employer/receipt-generate';
export const EMPLOYER_SUBSCRIPTION_REFRESH = '/employer/subscription-refresh-data'
export const EMPLOYER_DELETE_REQUEST = '/employer/profile/delete'
export const EMPLOYER_EMPLOYEE_MANUAL_TODAY_TRACKED_TIMES = '/tracker/today-manual-timetracked';
export const EMPLOYER_EMPLOYEE_MANUAL_WEEKLY_TRACKED_TIMES = '/tracker/weakly-manual-timetracked';
export const EMPLOYER_EMPLOYEE_MANUAL_WEEKLY_REPORT = '/tracker/weakly-report';
export const EMPLOYER_ADD_BONUS = '/employer/bonus/add';
export const EMPLOYER_ADD_PAYMENT ='/employer/payment/add';
export const EMPLOYER_BONUS_TRANSACTION_LIST = '/employer/bonus/list';
export const EMPLOYER_EMPLOYEE_ADD_BOARD = '/board/add';
export const EMPLOYER_EMPLOYEE_BOARD_BY_ID = '/board/id';
export const EMPLOYER_EMPLOYEE_BOARD_LIST = '/board/users';
export const EMPLOYER_EMPLOYEE_BOARD_ARCHIEVE_LIST = '/list/archive';
export const EMPLOYER_EMPLOYEE_BOARD_CREATE_LIST = '/list/add';
export const EMPLOYER_EMPLOYEE_BOARD_LIST_BY_ID = '/list/id';
export const EMPLOYER_EMPLOYEE_BOARD_CARD_BY_ID = '/card/id';
export const EMPLOYER_EMPLOYEE_BOARD_LIST_RENAME = '/list/edit';
export const EMPLOYER_EMPLOYEE_BOARD_CARD_CREATE = '/card/add-card';
export const EMPLOYER_EMPLOYEE_BOARD_ADD_CARD_MEMBER = '/card/addMember';
export const EMPLOYER_RELEASE_DISPUTE = '/employer/payment-invoice/release-dispute';
export const EMPLOYER_TERMINATE_API = '/employer/employee/terminate';
export const EMPLOYER_ADD_CARD = '/employer/card-details/add';
export const EMPLOYER_GET_CARD_DETAILS = '/employer/card-details/list';
export const EMPLOYER_DELETE_cARD_DETAILS = '/employer/card-details/delete';
export const EMPLOYER_CHANGE_DEFAULT_CARD = '/employer/card-details/change-default'
export const EMPLOYER_HOW_IT_WORKS = '/employer/how-it-works';
export const EMPLOYER_UPDATE_JOB_APPLICANT = '/employer/jobs/update-applicant-profile';
export const EMPLOYER_DELETE_JOB_APPICANT = '/employer/jobs/delete-applicant-profile';
//subscription
export const EMPLOYER_SUBSCRIBE_PLAN = '/employer/add-subscription';
export const EMPLOYER_SUBSCRIPTION_UPDATE = '/employer/update-subscription';
export const EMPLOYER_ORDER_SUCCESS = '/employer/order-success';
export const EMPLOYER_UNLIMITED_MONTHLY = '/employer/plus_monthly/subscription-confirmation';
export const EMPLOYER_UNLIMITED_YEARLY = '/employer/plus_yearly/subscription-confirmation';

//protected
export const EMPLOYER_SIGNIN_REQUEST = '/employer/protected/sign-in-request'
export const EMPLOYER_PROTECTED_PAY = '/employer/protected/pay'
export const EMPLOYER_PROTECTED_CANCEL = '/employer/protected/cancel'
export const EMPLOYER_CONTRACT_DETAILS = '/employer/protected/details'
export const EMPLOYER_SIGNIN_LIST = '/employer/protected/list'

//AI
export const EMPLOYER_GET_TALENTS ='/employer/jobs/get-relavant-user'
export const EMPLOYER_GET_AITEXT ='/employer/jobs/get-ai-text'
export const EMPLOYER_DELETE_AITEXT = '/employer/jobs/delete'
export const EMPLOYER_GET_TRANSFORMTEXT = '/employer/jobs/get-transformed-text'

//agreement

export const EMPLOYER_UPDATE_AGREEMENT = '/employer/update-agreement-details'
export const EMPLOYER_USER_MODAL_ALERT = '/employer/update-alert-modal'
//admin

export const ADMIN_EMPLOYER_VERIFY_LIST = "/admin/employer-profile/verify/list";
export const ADMIN_EMPLOYER_VERIFY = "/admin/employer-profile/verify";
export const ADMIN_JOBSEEKER_VERIFY = "/admin/jobseeker-profile/verify";
export const ADMIN_DASHBOARD_LIST_API = "/admin/dashboard";
export const ADMIN_DASHBOARD_EMPLOER_LIST_API = "/admin/employer-profile/list";
export const ADMIN_DASHBOARD_GET_EMPLOYER_DETAIL = "/admin/employer-profile/get";
export const ADMIN_DASHBOARD_GET_DELETE_EMPLOYER_DETAIL = "/admin/employer-details/list";
export const ADMIN_DASHBOARD_GET_DELETE_JOB_SEEKER_DETAIL = "/admin/jobseeker-details/list";
export const ADMIN_EMPLOYER_BLOCK_API = "/admin/user/block"
export const ADMIN_EMPLOYER_UNBLOCK_API = "/admin/user/unblock"
export const ADMIN_EMPLOYER_PASSWORD_API = "/admin/employer-profile/change-password"
export const ADMIN_EMPLOYER_PASSWORD_API_LINK = "/auth/forgot-password"
export const ADMIN_JOBSEEKER_PASSWORD_API_LINK = "/auth/forgot-password"
export const ADMIN_JOB_LIST_API = "/admin/jobs/list"
export const ADMIN_DELETE_EMPLOYER_LIST_API = "/admin/employer-profile/delete/list"
export const ADMIN_DELETE_JOBSEEKER_LIST_API = "/admin/jobseeker-profile/delete/list"
export const ADMIN_UNVERIFIED_JOB_LIST_API = "/admin/un-verified-jobs/list"
export const ADMIN_JOB_DELETE_API = "/admin/jobs/delete"
export const ADMIN_JOB_SEEKER_LIST_API = "/admin/jobseeker-profile/list"
export const ADMIN_JOB_SEEKER_PASSWORD_API = "/admin/jobseeker-profile/change-password"
export const ADMIN_GET_JOB_SEEKER_DETAIL_API = "/admin/jobseeker-profile/get"
export const ADMIN_GET_JOB_INFO = "/admin/jobs/get"
export const ADMIN_UPDATE_JOB_INFO = '/admin/jobs/update'
export const ADMIN_UPDATE_JOBSEEKER_INFO = '/admin/jobseeker-profile/update'
export const ADMIN_UPDATE_EMPLOYER_INFO = '/admin/employer-profile/update'
export const ADMIN_DELETE_JOBSEEKER_INFO = '/admin/jobseeker-profile/delete'
export const ADMIN_DELETE_JOBSEEKER_STRIPE = '/admin/jobseeker-profile/stripe-delete'
export const ADMIN_DELETE_EMPLOYER_INFO = '/admin/employer-profile/delete'
export const ADMIN_VERIFY_JOB_API = '/admin/jobs/verify'

export const ADMIN_USER_AGREEMENT = '/admin/get-agreement'
export const ADMIN_USER_AGREEMENT_LIST = '/admin/user-agreement-list'
export const ADMIN_INDIVIDUAL_JOB_LIST = '/admin/jobs/applicant-list'
export const ADMIN_INDIVIDUAL_OFFER_LIST = '/admin/jobs/offer-list'
export const ADMIN_INDIVIDUAL_HIRED_LIST = '/admin/employee/list'
export const ADMIN_INDIVIDUAL_STAFF_OFFER_LIST = '/admin/list-job-offer'
export const ADMIN_INDIVIDUAL_STAFF_HIRED_LIST = '/admin/employee/list'
export const ADMIN_CATEGORY_API = "/admin/category/list"
export const ADMIN_DELETE_CATEGORY_API = "/admin/category/delete"
export const ADMIN_ADD_CATEGORY_API = "/admin/category/add"
export const ADMIN_UPDATE_CATEGORY_API = "/admin/category/update"
export const ADMIN_INDIVIDUAL_PAYMENT_LIST = '/admin/payments-list'
export const ADMIN_SKILL_API = "/admin/skill/list"
export const ADMIN_DELETE_SKILL_API = "/admin/skill/delete"
export const ADMIN_ADD_SKILL_API = "/admin/skill/add"
export const ADMIN_UPDATE_SKILL_API = "/admin/skill/update"

export const ADMIN_JOB_TITLE_API = "/admin/job-title/list"
export const ADMIN_DELETE_JOB_TITLE_API = "/admin/job-title/delete"
export const ADMIN_ADD_JOB_TITLE_API = "/admin/job-title/add"
export const ADMIN_UPDATE_JOB_TITLE_API = "/admin/job-title/update"


export const ADMIN_PAYMENT_HISTORY_LIST_API = "/admin/payment/payment-history/list"
export const ADMIN_PAYMENT_HISTORY_FILTER_LIST_API = "/admin/payment/payment-history/filter"
export const ADMIN_BONUS_FILTER_LIST_API = "/admin/payment/bonus/filter"

export const ADMIN_STAFF_LIST_API = "/admin/staff/list"
export const ADMIN_ADD_STAFF_API = "/admin/staff/add"
export const ADMIN_UPDATE_STAFF_API = "/admin/staff/update"
export const ADMIN_DELETE_STAFF_API = "/admin/staff/delete"

export const ADMIN_JOB_SEEKER_VERIFY_LIST_API = "/admin/jobseeker-profile/verify/list"
export const ADMIN_JOB_SEEKER_VERIFY_API = "/admin/jobseeker-profile/get"

export const ADMIN_STAFF_ACTIVATION_API = "/admin/staff/account-activation"
export const ADMIN_JOB_SEEKER_VERIFY = "/admin/jobseeker-profile/verify";
export const ADMIN_ACTIVITY_LOG_LIST_API = "/admin/audit-log/list";
export const ADMIN_NOTIFICATION_LIST_API = "/admin/notification/list";
export const ADMIN_NOTIFICATION_UPDATE_API = "/admin/notification/update";

//Notification
export const ADMIN_CHAT_LIST_BY_EMAIL = "/chat/user-by-email";
export const ADMIN_CHAT_FILTER = "/chat/admin-chat-filter";
export const ADMIN_EMPOLYER_ACCEPTED_LIST = "/admin/employer-profile/accepted/list";
export const ADMIN_EMPOLYER_REJECTED_LIST_API = "/admin/employer-profile/rejected/list";
export const ADMIN_JOB_SEEKER_ACCEPTED_LIST_API = "/admin/jobseeker-profile/accepted/list";
export const ADMIN_JOB_SEEKER_REJECTED_LIST_API = "/admin/jobseeker-profile/rejected/list";

// ----------------------------------------------------- ticketing system api - admin
export const ADMIN_TICKET_FILTER_API = "/admin/ticket/filter"
export const ADMIN_CUST_SUP_LIST_API = "/admin/customer-support/list"
export const ADMIN_TICKET_ASSIGN_API = "/admin/ticket/assign"

// -------------------------------------------------------------Customer Support---
export const ADMIN_RELEASE_DISPUTE = '/admin/payment/release-dispute';
export const CUSTOMER_SUPPORT_TICKET_LIST = "/admin/ticket/filter";
export const CUSTOMER_SUPPORT_TICKET_STATUS_UPDATE_API = "/admin/ticket/status/update"



export const APP_NOTIFICATION_LIST = '/notification/notification-list';
export const APP_UPDATE_NOTIFICATION = '/notification/read-nofication'

export const ADMIN_ADD_ANNOUNCEMENT = '/admin/emailbanner';
export const ADMIN_GET_ANNOUNCEMENT = '/admin/emailbannerList';
export const ADMIN_GET_REFERRAL = '/admin/get-referral-users';
export const ADMIN_GET_AFFILIATE = '/admin/get-affiliate-users';
export const ADMIN_UPDATE_ANNOUNCEMENT = '/admin/emailbannerupdate'
export const ADMIN_DELETE_ANNOUNCEMENT = '/admin/emailbannerDelete'
export const ADMIN_GET_ANNOUNCEMENT_INFO = '/admin/getemailbanner'

export const ADMIN_GET_ACCOUNT_DETAILS = '/admin/admin-profile/get';
export const ADMIN_UPDATE_ACCOUNT_DETAILS = '/admin/admin-profile/update';

export const ADMIN_GET_FREEUSERS_LIST = '/admin/employer-profile/free-users/list';
export const ADMIN_UPDATE_EXPIRYDATE = '/admin/employer-profile/updateexpirydate';
export const ADMIN_GET_SUBSCRIBERS_LIST = '/admin/employer-profile/get-customer-payment';
export const ADMIN_UPDATE_BASIC_PLAN = '/admin/employer-profile/update-basic-plan';
export const ADMIN_SUBSCRIPTION_CANCEL = '/employer/subscription/cancel';


export const ADMIN_PROTECTION_LIST = '/admin/get-protected-users';
export const ADMIN_INDIVIDUAL_PROTECTION_LIST = '/admin/get-individual-protected-users';
export const ADMIN_CONTRACT_DETAILS = '/admin/get-contract-details';
export const ADMIN_USERS_CONFIG_LIST = '/admin/get-users-config';
export const ADMIN_AGREED_USERS_LIST ='/admin/get-agreement-list'
export const ADMIN_DELETE_USERS_LIST ='/admin/get-delete-request-details'
export const ADMIN_DELETE_USER ='/admin/delete-employer'
//fb convertion
export const EMPLOYER_CONVERSION = '/employer/conversion';

export const ADMIN_GET_REPORTED_JOB = '/admin/get-job-reports'
export const ADMIN_UPDATE_REPORTED_JOB = '/admin/update-job-reports'