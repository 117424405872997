import React from 'react';

function Meet() {
    return (
        <div>
            Meet
        </div>
    )
}

export default Meet
