import { useTimer } from "react-timer-hook";
const  MyTimer = ({ expiryTimestamp , flag }) =>  {
  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart
  } = useTimer({
    expiryTimestamp,
    onExpire: () => console.warn("onExpire called")
  });
   const convertNumber = (a) => {
    let str = a.toString();
    if(str.length === 1){
        str = "0" + str;
    }
    return str
   }
  return (
    <div style={{ textAlign: "center" }}>
     {flag ? 
      <div className="timer-section">
        <div className="timer-div">
        <span>{days}</span>
         <p>days</p>
        </div>
        <div className="timer-div">
        <span>{hours}</span>
        <p>hours</p>
        </div>
        <div className="timer-div">
        <span>{minutes}</span>
        <p>minutes</p>
        </div>
        <div className="timer-div">
        <span>{seconds}</span>
        <p>seconds</p>
        </div>
        
      </div> :
      <div className="counter-section">
        <div className="counter d-flex justify-content-center">
          <div className="d-flex flex-column">
          <span id="one">{convertNumber(days)}</span>
          <span id="two">Days</span>
          </div><p id="one">:</p>
          <div className="d-flex flex-column">
          <span id="one">{convertNumber(hours)}</span>
          <span id="two">Hours</span>
          </div><p id="one">:</p>
          <div className="d-flex flex-column">
          <span id="one">{convertNumber(minutes)}</span>
          <span id="two">Minutes</span>
          </div><p id="one">:</p>
          <div className="d-flex flex-column">
          <span id="one">{convertNumber(seconds)}</span>
          <span id="two">Seconds</span>

          </div>
      {/* <span >{convertNumber(hours)}</span>:
      <span>{convertNumber(minutes)}</span>:
       <span>{convertNumber(minutes)}</span>: */}
      </div>
      {/* <div className="letter-div">
        <span>Days</span>
        <span>Hours</span>
        <span>Minutes</span>
        <span>Seconds</span>
      </div> */}
  
    </div> }
    
    </div>
  );
}
export default MyTimer