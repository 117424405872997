import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';
import { editCardtext, getCard } from '../../actions/board';
import getInitials from '../../utils/getInitials';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import CardMUI from '@material-ui/core/Card';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import SubjectIcon from '@material-ui/icons/Subject';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import { TextField, Avatar, CardContent, Button, Tooltip } from '@material-ui/core';
// import { Avatar, CardContent, TextField, Tooltip } from '@material-ui/core';
import CardModal from './CardModal';
import { getUserData } from '../../../../helpers/projectHelper';
import IconButton from '@material-ui/core/IconButton';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
// import Avatar from '@material-ui/core/Avatar';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
const Card = ({ cardId, list, index }) => {
  // const { full_name, user_type, user_id } = getUserData();
  const isAuthenticated = localStorage.getItem('token');
  const [editing, setEditing] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [mouseOver, setMouseOver] = useState(false);
  const [title, setTitle] = useState('');
  const [height, setHeight] = useState(0);
  const [completeItems, setCompleteItems] = useState(0);
  const cardRef = useRef(null);
  const card = useSelector((state) =>
    state.board.board.cardObjects.find((object) => object._id === cardId),
  );
  const cardsids = useSelector((state) => state.board.cardsids);
  const dispatch = useDispatch();

  useEffect(() => {
    if (cardId) {
      let data = {
        card_id: cardId,
      };
      dispatch(getCard(data));
    }
  }, [cardId, dispatch]);

  useEffect(() => {
    if (card) {
      setTitle(card.title);
      card.checklist &&
        setCompleteItems(
          card.checklist.reduce((completed, item) => (completed += item.complete ? 1 : 0), 0),
        );
    }
  }, [card]);

  useEffect(() => {
    cardRef && cardRef.current && setHeight(cardRef.current.clientHeight);
  }, [list, card, cardRef]);

  const onSubmitEdit = async (e) => {
    e.preventDefault();
    let formData = {
      title: title,
      card_id: cardId,
    };
    dispatch(editCardtext(formData));
    setEditing(false);
    setMouseOver(false);
  };

  return !card || (card && card.archived) ? (
    ''
  ) : (
    <Fragment>
      <CardModal cardId={cardId} open={openModal} setOpen={setOpenModal} card={card} list={list} />
      {!editing ? (
        <Draggable draggableId={cardId} index={index}>
          {(provided) => (
            <CardMUI
              className={`card ${mouseOver && !editing ? 'mouse-over' : ''}`}
              onMouseOver={() => setMouseOver(true)}
              onMouseLeave={() => setMouseOver(false)}
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              {mouseOver &&
              !editing &&
              getUserData().user_type &&
              getUserData().user_type !== 'job_seeker' ? (
                <IconButton
                  style={{
                    position: 'absolute',
                    bottom: height - 50,
                    left: '325px',
                    zIndex: 1,
                  }}
                  onClick={() => setEditing(true)}
                >
                  <EditIcon fontSize="small" />
                </IconButton>
              ) : (
                ' '
              )}
              <CardContent
                onClick={() => {
                  setOpenModal(true);
                  setMouseOver(false);
                }}
                ref={cardRef}
              >
                <p className="board-card-task-title" style={{ fontSize: '15px' }}>
                  {card.title}
                </p>

                <div style={{ display: 'flex' }}>
                  {' '}
                  {card.label && card.label !== 'none' && (
                    <div
                      className="card-label"
                      style={{
                        marginTop: '10px',
                        padding: '10px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: card.labelText,
                        fontSize: '14px',
                        height: '25px',
                        borderRadius: '10px',
                        color: '#ffffff',
                        width: 'max-content',
                      }}
                    >
                      {card.label}
                      {/* {card.label === "#db3e00" ? "Bug" : (card.label==="#b80000"?"Backend":(card.label=="#fccb00"?"UI":"s"))}  */}
                    </div>
                  )}
                  &nbsp;
                  {card.priority && card.priority !== 'none' && (
                    <div
                      className="card-label"
                      style={{
                        marginTop: '10px',
                        padding: '10px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: card.priority_color,
                        fontSize: '14px',
                        height: '25px',
                        borderRadius: '10px',
                        color: 'white',
                        width: 'max-content',
                      }}
                    >
                      {card.priority}
                      {/* {card.label === "#db3e00" ? "Bug" : (card.label==="#b80000"?"Backend":(card.label=="#fccb00"?"UI":"s"))}  */}
                    </div>
                  )}
                  &nbsp;
                  {card?.due_date ? (
                    <div
                      className="card-label"
                      style={{
                        marginTop: '10px',
                        padding: '13px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor:
                          new Date(card.due_date).toDateString() == new Date().toDateString()
                            ? '#2eb82e'
                            : '#e5e5e5',
                        fontSize: '14px',
                        height: '25px',
                        borderRadius: '10px',
                        color:
                          new Date(card.due_date).toDateString() == new Date().toDateString()
                            ? '#ffffff'
                            : '#33444d',
                        width: 'max-content',
                      }}
                    >
                      <AccessTimeIcon
                        size="small"
                        style={{
                          fontSize: '20px',
                          color:
                            new Date(card.due_date).toDateString() == new Date().toDateString()
                              ? '#ffffff'
                              : '#33444d',
                        }}
                      />
                      <p
                        style={{
                          color:
                            new Date(card.due_date).toDateString() == new Date().toDateString()
                              ? '#ffffff'
                              : '#33444d',
                          marginBottom: '0px',
                        }}
                      >
                        &nbsp;
                        {new Date(card.due_date).toDateString()}
                      </p>
                    </div>
                  ) : (
                    ' '
                  )}
                  {/* <div
                  style={{display:"flex", justifyContent:"center", alignItems:"center"}}
                  >{card?.files?<AttachFileIcon/>:" "}</div> */}
                </div>
                <div className="card-bottom">
                  <div className="card-bottom-left">
                    {card.description && (
                      <SubjectIcon className="description-indicator" fontSize="small" />
                    )}
                    {card.checklist && card.checklist.length > 0 && (
                      <div
                        className={`checklist-indicator ${
                          completeItems === card.checklist.length
                            ? 'completed-checklist-indicator'
                            : ''
                        }`}
                      >
                        <AssignmentTurnedInIcon
                          fontSize="small"
                          className="checklist-indicator-icon"
                        />
                        {completeItems}/{card.checklist.length}
                      </div>
                    )}
                    <div
                      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    >
                      {card?.files.length > 0 ? <AttachFileIcon /> : ' '}
                    </div>
                  </div>

                  <div className="card-member-avatars">
                    {card.members.map((member, index) => {
                      return (card?.members?.length > 3 && index + 1 <= 2) ||
                        card?.members?.length <= 3 ? (
                        // <AvatarGroup max={1}>
                        <Tooltip title={member.name} key={member.user}>
                          {member.profile_id ? (
                            <Avatar
                              style={{ height: '30px', width: '30px' }}
                              fontSize="small"
                              alt="Remy Sharp"
                              src={`https://authapi.virtualstaff.ph/api/v1/file/download/${member.profile_id}`}
                            />
                          ) : (
                            <Avatar fontSize="small" className="avatar">
                              {getInitials(member.name)}
                            </Avatar>
                          )}
                        </Tooltip>
                      ) : (
                        // </AvatarGroup>:
                        ' '
                      );
                    })}
                    {card?.members?.length > 3 ? (
                      <Avatar style={{ cursor: 'pointer', height: '30px', width: '30px' }}>
                        +{card?.members?.length - 2}
                      </Avatar>
                    ) : (
                      ' '
                    )}
                  </div>
                </div>
              </CardContent>
            </CardMUI>
          )}
        </Draggable>
      ) : (
        <form className="create-card-form" onSubmit={(e) => onSubmitEdit(e)}>
          <CardMUI>
            <CardContent className="card-edit-content">
              <TextField
                margin="normal"
                fullWidth
                multiline
                required
                label="Edit this card's title"
                autoFocus
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                style={{ fontSize: '15px !important' }}
                onKeyPress={(e) => e.key === 'Enter' && onSubmitEdit(e)}
              />
            </CardContent>
          </CardMUI>
          <div className="card-actions">
            <Button type="submit" variant="contained" color="primary">
              Save
            </Button>
            &nbsp;
            {/* <Button
              onClick={() => {
                setEditing(false);
                setMouseOver(false);
                setTitle(card.title);
              }}
            > */}
            <IconButton
              onClick={() => {
                setEditing(false);
                setMouseOver(false);
                setTitle(card.title);
              }}
            >
              <CloseIcon />
            </IconButton>
            {/* </Button> */}
          </div>
        </form>
      )}
    </Fragment>
  );
};

Card.propTypes = {
  cardId: PropTypes.string.isRequired,
  list: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};

export default Card;
