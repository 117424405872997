import React, { useState } from 'react';
import moment from 'moment';
import { LOGIN_API_ROOT } from '../../config/constant/apiConstant';
import { Image } from 'react-bootstrap';
import { FILE_IMG, LOADING_IMG } from '../../config/constant/imageConstant';
import { socketSelector } from '../../redux/slicers/socketSlicer';
import { useSelector } from 'react-redux';
import {Link} from "react-router-dom"
const windowConfig = `toolbar=no, location=no, status=no, menubar=no`

export const ChatLeftMsg = ({ msg_time, content, animation = false, msg_type = 'text', ...a }) => {
  const className = animation ? 'zoomIn' : '';
  const [loading, setLoading] = useState(msg_type === 'file' && a.file_type === 'image');
  const url = `${LOGIN_API_ROOT}/file/download/${a.file_id}`;
  const handleFileOpen = () => {
    window.open(url,'targetWindow',windowConfig);
  };
  const handleOnLoad = () => {
    setLoading(false);
  };
  return (
    <>
      {msg_type === 'file' && a.file_type && <div id={a?._id ?? Math.random()} className={`messagerow messageleftrow animated ${className}`}>
        <div className='messageleftrowtext'>
          <div className='messageleftrowdoctordetails'>
            <p><span>{moment(msg_time).format('hh:mm A')}</span></p>
          </div>
          <div className='messageleftrowtextdetails'>
            {msg_type === 'file' && <>
              {a.file_id && a.file_type === 'image' && <Image src={url} onLoad={handleOnLoad} className={'pointer'} onClick={handleFileOpen} style={{ objectFit: 'cover' }} width={200} height={100} />}
              {a.file_id && a.file_type === 'other' && <Image src={FILE_IMG} onLoad={handleOnLoad} className={'pointer'} onClick={handleFileOpen} style={{ objectFit: 'cover' }} width={200} height={100} />}
              {a.file_id && a.file_type === 'other' && <p className={"mt-2"}><i className={"fas fa-paperclip mr-2"}/>Document</p>}
            </>}
          </div>
        </div>
      </div>}
    {((msg_type === 'file' && content.trim()) || msg_type !== 'file') && <div  id={a?._id ?? Math.random()} className={`messagerow messageleftrow animated ${className} ${msg_type === 'file' ? 'mt-1':'' }`}>
      <div className='messageleftrowtext text-break'>
        {msg_type !== 'file' && <div className='messageleftrowdoctordetails'>
          <p><span>{moment(msg_time).format('hh:mm A')}</span></p>
        </div>}
        <div className='messageleftrowtextdetails'>
          {msg_type === 'link' &&
          <a className={'chat-link'} href={content} rel='noreferrer' target='_blank'>{content}</a>}
          {msg_type === 'text' && <p>{content}</p>}
          {msg_type === 'calling' && <p><i className={'fas fa-video mr-2 '} />Video Call</p>}
          {msg_type === 'file' && <>
            <p>{content}</p>
          </>}
          {msg_type === 'job_offer' &&  <><p>You have received a job offer. You can accept or decline the job offer here</p><a className={'chat-link'} href={content} rel='noreferrer' target='_blank'>{content}</a></>}
        </div>
      </div>
    </div>}
      </>
  );
};

export const ChatRightMsg = ({ msg_time, content, animation = false,onClose=null,msg_type = 'text', ...a }) => {
  const { attachLoading = {}, attachFileId = {} } = useSelector(socketSelector);
  const className = animation ? 'zoomIn' : '';
  const [loading, setLoading] = useState(msg_type === 'file' && a.file_type === 'image');
  const file_id = a.file_id ? a.file_id : attachFileId[a?._id];
  const url = `${LOGIN_API_ROOT}/file/download/${file_id}`;
  const handleFileOpen = () => {
    window.open(url,'targetWindow',windowConfig);
  };
  const handleOnLoad = () => {
    setLoading(false);
  };
  if(msg_type === 'job_offer') {
    return null
  }
   return (
    <>
      {msg_type === 'file' && a.file_type && <div  id={a?._id ?? Math.random()} className={`messagerow text-break messagerightrow animated ${className}`}>
        <div className='messagerightrowtext messageleftrowtext'>
          <div className='messageleftrowdoctordetails right-time'>
            <p><span>{moment(msg_time).format('hh:mm A')}</span></p>
          </div>
          <div className='messagerightrowtextdetails messageleftrowtextdetails'>
            {msg_type === 'file' && <>
              {(loading || attachLoading?.[a._id]) && <img src={LOADING_IMG} width={50} />}
              {file_id && a.file_type === 'image' && <Image src={url} onLoad={handleOnLoad} className={'pointer'} onClick={handleFileOpen} style={{ objectFit: 'cover' }} width={200} height={100} />}
              {file_id && a.file_type === 'other' && <Image src={FILE_IMG} onLoad={handleOnLoad} className={'pointer'} onClick={handleFileOpen} style={{ objectFit: 'cover' }} width={200} height={100} />}
              {file_id && a.file_type === 'other' && <p className={"mt-2"}><i className={"fas fa-paperclip mr-2"}/>Document</p>}
            </>}
          </div>
        </div>
      </div>}
      {((msg_type === 'file' && content.trim()) || msg_type !== 'file') && <div  id={a?._id ?? Math.random()} className={`messagerow text-break messagerightrow animated ${className} ${msg_type === 'file' ? 'mt-1':'' }`}>
        
        <div className='messagerightrowtext text-break messageleftrowtext'>
          {msg_type !== 'file' && <div className='messageleftrowdoctordetails right-time'>
            <p><span>{moment(msg_time).format('hh:mm A')}</span></p>
          </div>}
          <div className='messagerightrowtextdetails messageleftrowtextdetails'>
           {onClose && <i className={"fa fa-times-circle float-right text-white"} onClick={onClose}/>}
            {msg_type === 'link' &&
            <a className={'chat-link color-white'} href={content} rel='noreferrer' target='_blank'>{content}</a>}
            {msg_type === 'text' && <p>{content}</p>}
            {msg_type === 'html' && <p dangerouslySetInnerHTML={{__html: content}} />}
            {msg_type === 'calling' && <p><i className={'fas fa-video mr-2'} />Video Call</p>}
            {msg_type === 'file' && <>
              <p>{content}</p>
            </>}
          </div>
        </div>
      </div>}
    </>
  );
};

export const ChatEmployerInitialMsg = (props) => {
  return <ChatRightMsg {...props} msg_type="html" />;
};

export const ChatCenter = (a) => {
  const staticTime = moment(a.msg_time).format('DD-MM-YYYY')
  return (
    <div className="chat-center">
      {staticTime}
    </div>
  )
};