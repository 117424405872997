import React, { useEffect, useState } from 'react';

function usePagination(data, itemsPerPage) {
  const [currentPage, setCurrentPage] = useState(1);
  var maxPage = data !== undefined ? Math.ceil(data.length / itemsPerPage) : 0;
  useEffect(() => {
    maxPage = data !== undefined ? Math.ceil(data.length / itemsPerPage) : 0;
    currentData();
  }, [data]);

  function currentData() {
    const begin = (currentPage - 1) * itemsPerPage;
    const end = begin + itemsPerPage;
    return data !== undefined ? data.slice(begin, end) : [];
  }

  function next() {
    setCurrentPage((currentPage) => Math.min(currentPage + 1, maxPage));
  }

  function prev() {
    setCurrentPage((currentPage) => Math.max(currentPage - 1, 1));
  }

  function jump(page) {
    const pageNumber = Math.max(1, page);
    setCurrentPage((currentPage) => Math.min(pageNumber, maxPage));
  }

  return { next, prev, jump, currentData, currentPage, maxPage };
}

export default usePagination;
