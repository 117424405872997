import { TASK_MANAGEMENT_BOARD_LIST, TASK_MANAGEMENT_DASHBOARD } from '../constant/routePathConstant';
import Dashboard from '../../components/task/components/pages/Dashboard';
import Board from '../../components/task/components/pages/Board';

const TASK_MANAGEMENT_ROUTES = [
  // { component: RedirectToTaskmanagementDashboard, path: TASK_MANAGEMENT },
  { component: Dashboard, path: TASK_MANAGEMENT_DASHBOARD },
  { component: Board, path: TASK_MANAGEMENT_BOARD_LIST },
];

export default TASK_MANAGEMENT_ROUTES;
