import { ACCOUNT_VERIFY, ACCOUNT_VERIFY_LOGIN, ADMIN_LOGIN, CUSTOMER_SUPPORT, EMPLOYER, MARKETING, JOB_SEEKER, LOGIN, SUPER_ADMIN, WEBSITE_RELOGIN } from './routePathConstant';

const userData = localStorage.getItem('userData');
const userInfo = JSON.parse(userData);
const user_type = userInfo?.user_type;

export const DEPRECATED_NOTIFICATIONS = ['job_post_applicants_update','salary_status','weekly_work_log_final_rem','support_ticket_confirmation','auto_payment_alert','weekly_invoice_alert','weekly_invoice_rem_alert','weekly_newsletter','support_ticket_creation','support_ticket_update','auto_payment_alert','payment_invoice']

export const CAPTCHA_KEY = '6LcHwt0eAAAAAJ-DWmIRrMVe_9_0LgPD-kkxjzol';
export const AUTH_TOKEN = 'token';
export const TITLE_PREFIX = 'Vs';

//error
export const NETWORK_ERROR = 'Network Error';

//user record
export const DEFAULT_TOKEN = 'token';
export const USER_DATA = 'userData';

//roles
export const JOB_SEEKER_ROLE = 'job_seeker';
export const EMPLOYER_ROLE = 'employer';
export const SUPER_ADMIN_ROLE = 'super_admin';
export const CUSTOMER_SUPPORT_ROLE = 'customer_support';
export const ACCOUT_VERIFY_ROLE = "account_verifier"

export const EMPLOYER_INTRO_MSG = `<p style="word-wrap:break-word"><strong>Ready to Hire??</strong> <br/>To hire this talent, just click the <b>"Hire Staff"</b> button.</p>`

//role navigation
export const ROLE_NAV = {
   'job_seeker': JOB_SEEKER,
   'employer': EMPLOYER,
   'account_verifier': ACCOUNT_VERIFY,
   'customer_support': CUSTOMER_SUPPORT,
   'super_admin': SUPER_ADMIN,
   'marketing': MARKETING
};

export const USER_TYPE_LOGIN = {
   'job_seeker': LOGIN,
   'employer': LOGIN,
   'account_verifier': ACCOUNT_VERIFY_LOGIN,
   'customer_support': ADMIN_LOGIN
}

export const JOB_TYPE = [
   { value: 'full_time', text: 'Full Time' },
   { value: 'part_time', text: 'Part Time' },

];
export const VERIFICATION_TYPE = [
   { value: 'All', text: 'All' },
   { value: 'verified', text: 'Verified' },
   // { value: 'unverified', text: 'Unverified' },
  
]

export const WEEK = [

   { shortTitle: 'Mon', title: 'Monday' },
   { shortTitle: 'Tue', title: 'Tuesday' },
   { shortTitle: 'Wed', title: 'Wednesday' },
   { shortTitle: 'Thu', title: 'Thursday' },
   { shortTitle: 'Fri', title: 'Friday' },
   { shortTitle: 'Sat', title: 'Saturday' },
   { shortTitle: 'Sun', title: 'Sunday' },
];
export const YEARS_OF_EXPERIENCE = [
   { value: 1, text: '1 Years' },
   { value: 2, text: '2 Years' },
   { value: 3, text: '3 Years' },
   { value: 4, text: '4 Years' },
   { value: 5, text: '5 Years' },
   { value: 6, text: '6 Years' },
   { value: 7, text: '7 Years' },
   { value: 8, text: '8 Years' },
   { value: 9, text: '9 Years' },
   { value: 10, text: '10 Years' },
];

export const HIGHEST_QUALIFICATION = [
   { value: "Elementry/Primary School", text: "Elementry/Primary School" },
   { value: "Junior High School", text: "Junior High School" },
   { value: "Senior High School", text: "Senior High School" },
   { value: "Bachelors", text: "Bachelors" },
   { value: "Masters", text: "Masters" },
   { value: "Doctorate", text: "Doctorate" },
]
export const TYPING_TIMEOUT = 4000;
export const JOIN_MEET_TYPE = 'join_meet';
export const PRIORITY_LIST = [
   { value: "High", text: "High" },
   { value: "Medium", text: "Medium" },
   { value: "Low", text: "Low" },
];
export const FREE_TRAIL = 7
export const MODULE_LIST = [
   { value: "Invoice Module", text: "Invoice Module" },
   { value: "Chat Module", text: "Chat Module" },
]

export const USER_TYPE = [
   user_type === 'job_seeker'
      ? { value: 'job_seeker', text: 'Job Seeker' }
      : { value: 'employer', text: 'Employer' },
];
export const USER_MODULE = [
   { value: 'chat', text: 'Chat' },
   { value: 'invoice', text: 'Invoice' },
   { value: 'notification', text: 'Notification' },
   { value: 'timeTracking', text: 'Time Tracking' },
];
export const PERIOD_CHOICES = [
   { value: 'daily', text: 'Daily' },
   { value: 'weekly', text: 'Weekly' },
   { value: 'bi-weekly', text: 'Bi-Weekly' },
   { value: 'monthly', text: 'Monthly' },
];
export const OTHER_ADMIN_ROLE = [
   { value: 'account_verifier', text: 'Account Verifier' },
   { value: 'customer_support', text: 'Customer Support' },
   { value: 'marketing', text: 'Marketing' }
];
export const ISSUE_PRIORITY = [
   { value: 'low', text: 'Low' },
   { value: 'high', text: 'High' },
];
export const GENDER = [
   { value: 'male', text: 'Male' },
   { value: 'female', text: 'Female' },
];
export const TICKET_STATUS = [
   { value: 'open', text: 'Open' },
   { value: 'closed', text: 'Closed' },
   { value: 'assigned', text: 'Assigned' },
]


export const ID_TYPE = [
   { value: "national_id", text: "National ID" },
   { value: "driver_license", text: "Driver's License" },
   { value: "passport", text: 'Passport' }
]

export const BONUS_TYPE = [
   { value: "bonus", text: "Bonus" },
   { value: "reimbursement", text: "Reimbursement" }
]
export const PREVENT_ROUTES = [
   WEBSITE_RELOGIN
]
export const COUNTRY_NAME = [

   { value: "Afghanistan", text: "AF" },
   { value: "Åland Islands", text: "AX" },
   { value: "Albania", text: "AL" },
   { value: "Algeria", text: "DZ" },
   { value: "American Samoa", text: "AS" },
   { value: "Andorra", text: "AD" },
   { value: "Angola", text: "AO" },
   { value: "Anguilla", text: "AI" },
   { value: "Antarctica", text: "AQ" },
   { value: "Antigua and Barbuda", text: "AG" },
   { value: "Argentina", text: "AR" },
   { value: "Armenia", text: "AM" },
   { value: "Aruba", text: "AW" },
   { value: "Australia", text: "AU" },
   { value: "Austria", text: "AT" },
   { value: "Azerbaijan", text: "AZ" },
   { value: "Bahamas", text: "BS" },
   { value: "Bahrain", text: "BH" },
   { value: "Bangladesh", text: "BD" },
   { value: "Barbados", text: "BB" },
   { value: "Belarus", text: "BY" },
   { value: "Belgium", text: "BE" },
   { value: "Belize", text: "BZ" },
   { value: "Benin", text: "BJ" },
   { value: "Bermuda", text: "BM" },
   { value: "Bhutan", text: "BT" },
   { value: "Bolivia", text: "BO" },
   { value: "Bosnia and Herzegovina", text: "BA" },
   { value: "Botswana", text: "BW" },
   { value: "Bouvet Island", text: "BV" },
   { value: "Brazil", text: "BR" },
   { value: "British Indian Ocean Territory", text: "IO" },
   { value: "Brunei Darussalam", text: "BN" },
   { value: "Bulgaria", text: "BG" },
   { value: "Burkina Faso", text: "BF" },
   { value: "Burundi", text: "BI" },
   { value: "Cambodia", text: "KH" },
   { value: "Cameroon", text: "CM" },
   { value: "Canada", text: "CA" },
   { value: "Cape Verde", text: "CV" },
   { value: "Cayman Islands", text: "KY" },
   { value: "Central African Republic", text: "CF" },
   { value: "Chad", text: "TD" },
   { value: "Chile", text: "CL" },
   { value: "China", text: "CN" },
   { value: "Christmas Island", text: "CX" },
   { value: "Cocos (Keeling) Islands", text: "CC" },
   { value: "Colombia", text: "CO" },
   { value: "Comoros", text: "KM" },
   { value: "Congo", text: "CG" },
   { value: "Congo, The Democratic Republic of the", text: "CD" },
   { value: "Cook Islands", text: "CK" },
   { value: "Costa Rica", text: "CR" },
   { value: "Cote D'Ivoire", text: "CI" },
   { value: "Croatia", text: "HR" },
   { value: "Cuba", text: "CU" },
   { value: "Cyprus", text: "CY" },
   { value: "Czech Republic", text: "CZ" },
   { value: "Denmark", text: "DK" },
   { value: "Djibouti", text: "DJ" },
   { value: "Dominica", text: "DM" },
   { value: "Dominican Republic", text: "DO" },
   { value: "Ecuador", text: "EC" },
   { value: "Egypt", text: "EG" },
   { value: "El Salvador", text: "SV" },
   { value: "Equatorial Guinea", text: "GQ" },
   { value: "Eritrea", text: "ER" },
   { value: "Estonia", text: "EE" },
   { value: "Ethiopia", text: "ET" },
   { value: "Falkland Islands (Malvinas)", text: "FK" },
   { value: "Faroe Islands", text: "FO" },
   { value: "Fiji", text: "FJ" },
   { value: "Finland", text: "FI" },
   { value: "France", text: "FR" },
   { value: "French Guiana", text: "GF" },
   { value: "French Polynesia", text: "PF" },
   { value: "French Southern Territories", text: "TF" },
   { value: "Gabon", text: "GA" },
   { value: "Gambia", text: "GM" },
   { value: "Georgia", text: "GE" },
   { value: "Germany", text: "DE" },
   { value: "Ghana", text: "GH" },
   { value: "Gibraltar", text: "GI" },
   { value: "Greece", text: "GR" },
   { value: "Greenland", text: "GL" },
   { value: "Grenada", text: "GD" },
   { value: "Guadeloupe", text: "GP" },
   { value: "Guam", text: "GU" },
   { value: "Guatemala", text: "GT" },
   { value: "Guernsey", text: "GG" },
   { value: "Guinea", text: "GN" },
   { value: "Guinea-Bissau", text: "GW" },
   { value: "Guyana", text: "GY" },
   { value: "Haiti", text: "HT" },
   { value: "Heard Island and Mcdonald Islands", text: "HM" },
   { value: "Holy See (Vatican City State)", text: "VA" },
   { value: "Honduras", text: "HN" },
   { value: "Hong Kong", text: "HK" },
   { value: "Hungary", text: "HU" },
   { value: "Iceland", text: "IS" },
   { value: "India", text: "IN" },
   { value: "Indonesia", text: "ID" },
   { value: "Iran, Islamic Republic Of", text: "IR" },
   { value: "Iraq", text: "IQ" },
   { value: "Ireland", text: "IE" },
   { value: "Isle of Man", text: "IM" },
   { value: "Israel", text: "IL" },
   { value: "Italy", text: "IT" },
   { value: "Jamaica", text: "JM" },
   { value: "Japan", text: "JP" },
   { value: "Jersey", text: "JE" },
   { value: "Jordan", text: "JO" },
   { value: "Kazakhstan", text: "KZ" },
   { value: "Kenya", text: "KE" },
   { value: "Kiribati", text: "KI" },
   { value: "Korea, Democratic People'S Republic of", text: "KP" },
   { value: "Korea, Republic of", text: "KR" },
   { value: "Kuwait", text: "KW" },
   { value: "Kyrgyzstan", text: "KG" },
   { value: "Lao People'S Democratic Republic", text: "LA" },
   { value: "Latvia", text: "LV" },
   { value: "Lebanon", text: "LB" },
   { value: "Lesotho", text: "LS" },
   { value: "Liberia", text: "LR" },
   { value: "Libyan Arab Jamahiriya", text: "LY" },
   { value: "Liechtenstein", text: "LI" },
   { value: "Lithuania", text: "LT" },
   { value: "Luxembourg", text: "LU" },
   { value: "Macao", text: "MO" },
   { value: "Macedonia, The Former Yugoslav Republic of", text: "MK" },
   { value: "Madagascar", text: "MG" },
   { value: "Malawi", text: "MW" },
   { value: "Malaysia", text: "MY" },
   { value: "Maldives", text: "MV" },
   { value: "Mali", text: "ML" },
   { value: "Malta", text: "MT" },
   { value: "Marshall Islands", text: "MH" },
   { value: "Martinique", text: "MQ" },
   { value: "Mauritania", text: "MR" },
   { value: "Mauritius", text: "MU" },
   { value: "Mayotte", text: "YT" },
   { value: "Mexico", text: "MX" },
   { value: "Micronesia, Federated States of", text: "FM" },
   { value: "Moldova, Republic of", text: "MD" },
   { value: "Monaco", text: "MC" },
   { value: "Mongolia", text: "MN" },
   { value: "Montserrat", text: "MS" },
   { value: "Morocco", text: "MA" },
   { value: "Mozambique", text: "MZ" },
   { value: "Myanmar", text: "MM" },
   { value: "Namibia", text: "NA" },
   { value: "Nauru", text: "NR" },
   { value: "Nepal", text: "NP" },
   { value: "Netherlands", text: "NL" },
   { value: "Netherlands Antilles", text: "AN" },
   { value: "New Caledonia", text: "NC" },
   { value: "New Zealand", text: "NZ" },
   { value: "Nicaragua", text: "NI" },
   { value: "Niger", text: "NE" },
   { value: "Nigeria", text: "NG" },
   { value: "Niue", text: "NU" },
   { value: "Norfolk Island", text: "NF" },
   { value: "Northern Mariana Islands", text: "MP" },
   { value: "Norway", text: "NO" },
   { value: "Oman", text: "OM" },
   { value: "Pakistan", text: "PK" },
   { value: "Palau", text: "PW" },
   { value: "Palestinian Territory, Occupied", text: "PS" },
   { value: "Panama", text: "PA" },
   { value: "Papua New Guinea", text: "PG" },
   { value: "Paraguay", text: "PY" },
   { value: "Peru", text: "PE" },
   { value: "Philippines", text: "PH" },
   { value: "Pitcairn", text: "PN" },
   { value: "Poland", text: "PL" },
   { value: "Portugal", text: "PT" },
   { value: "Puerto Rico", text: "PR" },
   { value: "Qatar", text: "QA" },
   { value: "Reunion", text: "RE" },
   { value: "Romania", text: "RO" },
   { value: "Russian Federation", text: "RU" },
   { value: "RWANDA", text: "RW" },
   { value: "Saint Helena", text: "SH" },
   { value: "Saint Kitts and Nevis", text: "KN" },
   { value: "Saint Lucia", text: "LC" },
   { value: "Saint Pierre and Miquelon", text: "PM" },
   { value: "Saint Vincent and the Grenadines", text: "VC" },
   { value: "Samoa", text: "WS" },
   { value: "San Marino", text: "SM" },
   { value: "Sao Tome and Principe", text: "ST" },
   { value: "Saudi Arabia", text: "SA" },
   { value: "Senegal", text: "SN" },
   { value: "Serbia and Montenegro", text: "CS" },
   { value: "Seychelles", text: "SC" },
   { value: "Sierra Leone", text: "SL" },
   { value: "Singapore", text: "SG" },
   { value: "Slovakia", text: "SK" },
   { value: "Slovenia", text: "SI" },
   { value: "Solomon Islands", text: "SB" },
   { value: "Somalia", text: "SO" },
   { value: "South Africa", text: "ZA" },
   { value: "South Georgia and the South Sandwich Islands", text: "GS" },
   { value: "Spain", text: "ES" },
   { value: "Sri Lanka", text: "LK" },
   { value: "Sudan", text: "SD" },
   { value: "Suriname", text: "SR" },
   { value: "Svalbard and Jan Mayen", text: "SJ" },
   { value: "Swaziland", text: "SZ" },
   { value: "Sweden", text: "SE" },
   { value: "Switzerland", text: "CH" },
   { value: "Syrian Arab Republic", text: "SY" },
   { value: "Taiwan, Province of China", text: "TW" },
   { value: "Tajikistan", text: "TJ" },
   { value: "Tanzania, United Republic of", text: "TZ" },
   { value: "Thailand", text: "TH" },
   { value: "Timor-Leste", text: "TL" },
   { value: "Togo", text: "TG" },
   { value: "Tokelau", text: "TK" },
   { value: "Tonga", text: "TO" },
   { value: "Trinidad and Tobago", text: "TT" },
   { value: "Tunisia", text: "TN" },
   { value: "Turkey", text: "TR" },
   { value: "Turkmenistan", text: "TM" },
   { value: "Turks and Caicos Islands", text: "TC" },
   { value: "Tuvalu", text: "TV" },
   { value: "Uganda", text: "UG" },
   { value: "Ukraine", text: "UA" },
   { value: "United Arab Emirates", text: "AE" },
   { value: "United Kingdom", text: "GB" },
   { value: "United States", text: "US" },
   { value: "United States Minor Outlying Islands", text: "UM" },
   { value: "Uruguay", text: "UY" },
   { value: "Uzbekistan", text: "UZ" },
   { value: "Vanuatu", text: "VU" },
   { value: "Venezuela", text: "VE" },
   { value: "Viet Nam", text: "VN" },
   { value: "Virgin Islands, British", text: "VG" },
   { value: "Virgin Islands, U.S.", text: "VI" },
   { value: "Wallis and Futuna", text: "WF" },
   { value: "Western Sahara", text: "EH" },
   { value: "Yemen", text: "YE" },
   { value: "Zambia", text: "ZM" },
   { value: "Zimbabwe", text: "ZW" }
];

export const BANK_NAME = [
   { value: "AMA Bank", text: "AB" },
   { value: "ANZ Bank", text: "DF" },
   { value: "Allied Bank", text: "AS" },
   { value: "Allied Savings Bank", text: "ASB" },
   { value: "Asia United Bank", text: "AUB" },
   { value: "BDO (Banco De Oro) Unibank", text: "BDO" },
   { value: "BDO Network Bank (One Network Bank)", text: "BNB" },
   { value: "BPI Family Savings Bank", text: "BFSB" },
   { value: "Bangkok Bank", text: "BB" },
   { value: "Bank of China", text: "BC" },
   { value: "Bank One Savings and Trust Corp.", text: "BOS" },
   { value: "Bank of Commerce", text: "QBFDA" },
   { value: "Bank of Tokyo", text: "BT" },
   { value: "Bank of the Philippine Islands (BPI)", text: "PI" },
   { value: "CTBC", text: "CTBC" },
   { value: "China Bank Savings", text: "CBS" },
   { value: "China Banking Corporation", text: "CBC" },
   { value: "CitiBank Savings", text: "CBS" },
   { value: "Citibank", text: "CB" },
   { value: "City Estate Savings Bank", text: "CESB" },
   { value: "City Savings Bank", text: "CSB" },
   { value: "Deutsche Bank", text: "DB" },
   { value: "Development Bank of the Philippines (DBP)", text: "DBP" },
   { value: "East West Bank", text: "EWB" },
   { value: "Equicom Savings Bank", text: "ESB" },
   { value: "Far Eastern Bank", text: "FEB" },
   { value: "Filipino Savers Bank (A Rural Bank)", text: "FSB" },
   { value: "First Allied Bank", text: "FAB" },
   { value: "First Consolidated Bank", text: "FCB" },
   { value: "First Macro Bank", text: "FMB" },
   { value: "Guagua Savers Bank", text: "GSB" },
   { value: "HSBC Savings", text: "HS" },
   { value: "Hongkong and Shanghai Bank (HSBC)", text: "HSB" },
   { value: "International Exchange Bank", text: "IEB" },
   { value: "Islamic Bank (Al-Amanah Islamic Investment Bank)", text: "IB" },
   { value: "Land Bank of the Philippines", text: "LBP" },
   { value: "Luzon Development Bank", text: "LDB" },
   { value: "Malayan Bank", text: "MB" },
   { value: "MayBank Philippines", text: "MBP" },
   { value: "Metropolitan Bank and Trust Company (Metrobank)", text: "MBTC" },
   { value: "Pen Bank", text: "PB" },
   { value: "Philippine Bank of Communications (PBCOM)", text: "PBC" },
   { value: "Philippine Business Bank", text: "PBB" },
   { value: "Philippine National Bank (PNB)", text: "PNB" },
   { value: "Philippine Savings Bank (PSBank)", text: "PTC" },
   { value: "Philippine Trust Company", text: "PSB" },
   { value: "Philippine Veterans Bank", text: "PVB" },
   { value: "Planters Bank", text: "PBGHD" },
   { value: "Postal Bank", text: "HJTGSRF" },
   { value: "Premiere Bank", text: "DFXBDF" },
   { value: "RCBC Savings", text: "EWDGS" },
   { value: "Rizal Commercial Banking Corp. (RCBC)", text: "RFHBDF" },
   { value: "Robinsons Savings Bank", text: "AQWSEF" },
   { value: "Security Bank Corporation", text: "TYGH" },
   { value: "Security Bank Savings", text: "RHE5YE" },
   { value: "Standard Chartered Bank", text: "RHESW3" },
   { value: "Sterling Bank", text: "QFDGDA" },
   { value: "Tong Yang Savings Bank", text: "NWA" },
   { value: "UCPB Savings Bank", text: "BVNCZ" },
   { value: "Union Bank of the Philippines", text: "UKTGY" },
   { value: "United Coconut Planters Bank (UCPB)", text: "ETAQWE" },
   { value: "United Overseas Bank", text: "sc" },
   { value: "University Savings Bank", text: "65UJR" }
]
export const S3_BUCKET_URL = 'https://dhpt63wbl5csr.cloudfront.net/media/'
export const IMAGE_API = 'https://authapi.virtualstaff.ph/api/v1/file/download'
export const OLD_S3 = process.env.OLD_S3_BUCKET_URL

export const PH_STATES = [
   {
      text: "Abra",
      href: "luzon/car/abra.html",
      attr: {
         href: "luzon/car/abra.html"
      }
   },
   {
      text: "Agusan del Norte",
      href: "mindanao/caraga/agusan-del-norte.html",
      attr: {
         href: "mindanao/caraga/agusan-del-norte.html"
      }
   },
   {
      text: "Agusan del Sur",
      href: "mindanao/caraga/agusan-del-sur.html",
      attr: {
         href: "mindanao/caraga/agusan-del-sur.html"
      }
   },
   {
      text: "Aklan",
      href: "visayas/r06/aklan.html",
      attr: {
         href: "visayas/r06/aklan.html"
      }
   },
   {
      text: "Albay",
      href: "luzon/r05/albay.html",
      attr: {
         href: "luzon/r05/albay.html"
      }
   },
   {
      text: "Antique",
      href: "visayas/r06/antique.html",
      attr: {
         href: "visayas/r06/antique.html"
      }
   },
   {
      text: "Apayao",
      href: "luzon/car/apayao.html",
      attr: {
         href: "luzon/car/apayao.html"
      }
   },
   {
      text: "Aurora",
      href: "luzon/r03/aurora.html",
      attr: {
         href: "luzon/r03/aurora.html"
      }
   },
   {
      text: "Basilan",
      href: "mindanao/barmm/basilan.html",
      attr: {
         href: "mindanao/barmm/basilan.html"
      }
   },
   {
      text: "Bataan",
      href: "luzon/r03/bataan.html",
      attr: {
         href: "luzon/r03/bataan.html"
      }
   },
   {
      text: "Batanes",
      href: "luzon/r02/batanes.html",
      attr: {
         href: "luzon/r02/batanes.html"
      }
   },
   {
      text: "Batangas",
      href: "luzon/r04a/batangas.html",
      attr: {
         href: "luzon/r04a/batangas.html"
      }
   },
   {
      text: "Benguet",
      href: "luzon/car/benguet.html",
      attr: {
         href: "luzon/car/benguet.html"
      }
   },
   {
      text: "Biliran",
      href: "visayas/r08/biliran.html",
      attr: {
         href: "visayas/r08/biliran.html"
      }
   },
   {
      text: "Bohol",
      href: "visayas/r07/bohol.html",
      attr: {
         href: "visayas/r07/bohol.html"
      }
   },
   {
      text: "Bukidnon",
      href: "mindanao/r10/bukidnon.html",
      attr: {
         href: "mindanao/r10/bukidnon.html"
      }
   },
   {
      text: "Bulacan",
      href: "luzon/r03/bulacan.html",
      attr: {
         href: "luzon/r03/bulacan.html"
      }
   },
   {
      text: "Cagayan",
      href: "luzon/r02/cagayan.html",
      attr: {
         href: "luzon/r02/cagayan.html"
      }
   },
   {
      text: "Camarines Norte",
      href: "luzon/r05/camarines-norte.html",
      attr: {
         href: "luzon/r05/camarines-norte.html"
      }
   },
   {
      text: "Camarines Sur",
      href: "luzon/r05/camarines-sur.html",
      attr: {
         href: "luzon/r05/camarines-sur.html"
      }
   },
   {
      text: "Camiguin",
      href: "mindanao/r10/camiguin.html",
      attr: {
         href: "mindanao/r10/camiguin.html"
      }
   },
   {
      text: "Capiz",
      href: "visayas/r06/capiz.html",
      attr: {
         href: "visayas/r06/capiz.html"
      }
   },
   {
      text: "Catanduanes",
      href: "luzon/r05/catanduanes.html",
      attr: {
         href: "luzon/r05/catanduanes.html"
      }
   },
   {
      text: "Cavite",
      href: "luzon/r04a/cavite.html",
      attr: {
         href: "luzon/r04a/cavite.html"
      }
   },
   {
      text: "Cebu",
      href: "visayas/r07/cebu.html",
      attr: {
         href: "visayas/r07/cebu.html"
      }
   },
   {
      text: "Cotabato",
      href: "mindanao/r12/cotabato.html",
      attr: {
         href: "mindanao/r12/cotabato.html"
      }
   },
   {
      text: "Davao de Oro",
      href: "mindanao/r11/davao-de-oro.html",
      attr: {
         href: "mindanao/r11/davao-de-oro.html"
      }
   },
   {
      text: "Davao del Norte",
      href: "mindanao/r11/davao-del-norte.html",
      attr: {
         href: "mindanao/r11/davao-del-norte.html"
      }
   },
   {
      text: "Davao del Sur",
      href: "mindanao/r11/davao-del-sur.html",
      attr: {
         href: "mindanao/r11/davao-del-sur.html"
      }
   },
   {
      text: "Davao Occidental",
      href: "mindanao/r11/davao-occidental.html",
      attr: {
         href: "mindanao/r11/davao-occidental.html"
      }
   },
   {
      text: "Davao Oriental",
      href: "mindanao/r11/davao-oriental.html",
      attr: {
         href: "mindanao/r11/davao-oriental.html"
      }
   },
   {
      text: "Dinagat Islands",
      href: "mindanao/caraga/dinagat-islands.html",
      attr: {
         href: "mindanao/caraga/dinagat-islands.html"
      }
   },
   {
      text: "Eastern Samar",
      href: "visayas/r08/eastern-samar.html",
      attr: {
         href: "visayas/r08/eastern-samar.html"
      }
   },
   {
      text: "Guimaras",
      href: "visayas/r06/guimaras.html",
      attr: {
         href: "visayas/r06/guimaras.html"
      }
   },
   {
      text: "Ifugao",
      href: "luzon/car/ifugao.html",
      attr: {
         href: "luzon/car/ifugao.html"
      }
   },
   {
      text: "Ilocos Norte",
      href: "luzon/r01/ilocos-norte.html",
      attr: {
         href: "luzon/r01/ilocos-norte.html"
      }
   },
   {
      text: "Ilocos Sur",
      href: "luzon/r01/ilocos-sur.html",
      attr: {
         href: "luzon/r01/ilocos-sur.html"
      }
   },
   {
      text: "Iloilo",
      href: "visayas/r06/iloilo.html",
      attr: {
         href: "visayas/r06/iloilo.html"
      }
   },
   {
      text: "Isabela",
      href: "luzon/r02/isabela.html",
      attr: {
         href: "luzon/r02/isabela.html"
      }
   },
   {
      text: "Kalinga",
      href: "luzon/car/kalinga.html",
      attr: {
         href: "luzon/car/kalinga.html"
      }
   },
   {
      text: "La Union",
      href: "luzon/r01/la-union.html",
      attr: {
         href: "luzon/r01/la-union.html"
      }
   },
   {
      text: "Laguna",
      href: "luzon/r04a/laguna.html",
      attr: {
         href: "luzon/r04a/laguna.html"
      }
   },
   {
      text: "Lanao del Norte",
      href: "mindanao/r10/lanao-del-norte.html",
      attr: {
         href: "mindanao/r10/lanao-del-norte.html"
      }
   },
   {
      text: "Lanao del Sur",
      href: "mindanao/barmm/lanao-del-sur.html",
      attr: {
         href: "mindanao/barmm/lanao-del-sur.html"
      }
   },
   {
      text: "Leyte",
      href: "visayas/r08/leyte.html",
      attr: {
         href: "visayas/r08/leyte.html"
      }
   },
   {
      text: "Maguindanao",
      href: "mindanao/barmm/maguindanao.html",
      attr: {
         href: "mindanao/barmm/maguindanao.html"
      }
   },
   {
      text: "Marinduque",
      href: "luzon/mimaropa/marinduque.html",
      attr: {
         href: "luzon/mimaropa/marinduque.html"
      }
   },
   {
      text: "Masbate",
      href: "luzon/r05/masbate.html",
      attr: {
         href: "luzon/r05/masbate.html"
      }
   },
   {
      text: "Misamis Occidental",
      href: "mindanao/r10/misamis-occidental.html",
      attr: {
         href: "mindanao/r10/misamis-occidental.html"
      }
   },
   {
      text: "Misamis Oriental",
      href: "mindanao/r10/misamis-oriental.html",
      attr: {
         href: "mindanao/r10/misamis-oriental.html"
      }
   },
   {
      text: "Mountain Province",
      href: "luzon/car/mountain-province.html",
      attr: {
         href: "luzon/car/mountain-province.html"
      }
   },
   {
      text: "Negros Occidental",
      href: "visayas/r06/negros-occidental.html",
      attr: {
         href: "visayas/r06/negros-occidental.html"
      }
   },
   {
      text: "Negros Oriental",
      href: "visayas/r07/negros-oriental.html",
      attr: {
         href: "visayas/r07/negros-oriental.html"
      }
   },
   {
      text: "Northern Samar",
      href: "visayas/r08/northern-samar.html",
      attr: {
         href: "visayas/r08/northern-samar.html"
      }
   },
   {
      text: "Nueva Ecija",
      href: "luzon/r03/nueva-ecija.html",
      attr: {
         href: "luzon/r03/nueva-ecija.html"
      }
   },
   {
      text: "Nueva Vizcaya",
      href: "luzon/r02/nueva-vizcaya.html",
      attr: {
         href: "luzon/r02/nueva-vizcaya.html"
      }
   },
   {
      text: "Occidental Mindoro",
      href: "luzon/mimaropa/occidental-mindoro.html",
      attr: {
         href: "luzon/mimaropa/occidental-mindoro.html"
      }
   },
   {
      text: "Oriental Mindoro",
      href: "luzon/mimaropa/oriental-mindoro.html",
      attr: {
         href: "luzon/mimaropa/oriental-mindoro.html"
      }
   },
   {
      text: "Palawan",
      href: "luzon/mimaropa/palawan.html",
      attr: {
         href: "luzon/mimaropa/palawan.html"
      }
   },
   {
      text: "Pampanga",
      href: "luzon/r03/pampanga.html",
      attr: {
         href: "luzon/r03/pampanga.html"
      }
   },
   {
      text: "Pangasinan",
      href: "luzon/r01/pangasinan.html",
      attr: {
         href: "luzon/r01/pangasinan.html"
      }
   },
   {
      text: "Quezon",
      href: "luzon/r04a/quezon.html",
      attr: {
         href: "luzon/r04a/quezon.html"
      }
   },
   {
      text: "Quirino",
      href: "luzon/r02/quirino.html",
      attr: {
         href: "luzon/r02/quirino.html"
      }
   },
   {
      text: "Rizal",
      href: "luzon/r04a/rizal.html",
      attr: {
         href: "luzon/r04a/rizal.html"
      }
   },
   {
      text: "Romblon",
      href: "luzon/mimaropa/romblon.html",
      attr: {
         href: "luzon/mimaropa/romblon.html"
      }
   },
   {
      text: "Samar",
      href: "visayas/r08/samar.html",
      attr: {
         href: "visayas/r08/samar.html"
      }
   },
   {
      text: "Sarangani",
      href: "mindanao/r12/sarangani.html",
      attr: {
         href: "mindanao/r12/sarangani.html"
      }
   },
   {
      text: "Siquijor",
      href: "visayas/r07/siquijor.html",
      attr: {
         href: "visayas/r07/siquijor.html"
      }
   },
   {
      text: "Sorsogon",
      href: "luzon/r05/sorsogon.html",
      attr: {
         href: "luzon/r05/sorsogon.html"
      }
   },
   {
      text: "South Cotabato",
      href: "mindanao/r12/south-cotabato.html",
      attr: {
         href: "mindanao/r12/south-cotabato.html"
      }
   },
   {
      text: "Southern Leyte",
      href: "visayas/r08/southern-leyte.html",
      attr: {
         href: "visayas/r08/southern-leyte.html"
      }
   },
   {
      text: "Sultan Kudarat",
      href: "mindanao/r12/sultan-kudarat.html",
      attr: {
         href: "mindanao/r12/sultan-kudarat.html"
      }
   },
   {
      text: "Sulu",
      href: "mindanao/barmm/sulu.html",
      attr: {
         href: "mindanao/barmm/sulu.html"
      }
   },
   {
      text: "Surigao del Norte",
      href: "mindanao/caraga/surigao-del-norte.html",
      attr: {
         href: "mindanao/caraga/surigao-del-norte.html"
      }
   },
   {
      text: "Surigao del Sur",
      href: "mindanao/caraga/surigao-del-sur.html",
      attr: {
         href: "mindanao/caraga/surigao-del-sur.html"
      }
   },
   {
      text: "Tarlac",
      href: "luzon/r03/tarlac.html",
      attr: {
         href: "luzon/r03/tarlac.html"
      }
   },
   {
      text: "Tawi-Tawi",
      href: "mindanao/barmm/tawi-tawi.html",
      attr: {
         href: "mindanao/barmm/tawi-tawi.html"
      }
   },
   {
      text: "Zambales",
      href: "luzon/r03/zambales.html",
      attr: {
         href: "luzon/r03/zambales.html"
      }
   },
   {
      text: "Zamboanga del Norte",
      href: "mindanao/r09/zamboanga-del-norte.html",
      attr: {
         href: "mindanao/r09/zamboanga-del-norte.html"
      }
   },
   {
      text: "Zamboanga del Sur",
      href: "mindanao/r09/zamboanga-del-sur.html",
      attr: {
         href: "mindanao/r09/zamboanga-del-sur.html"
      }
   },
   {
      text: "Zamboanga Sibugay",
      href: "mindanao/r09/zamboanga-sibugay.html",
      attr: {
         href: "mindanao/r09/zamboanga-sibugay.html"
      }
   }
]
export const PROVINCES = PH_STATES.map((ele) => ({ text: ele.text, value: ele.text }))
export const STRIPE_KEY = 'pk_live_51IYlMjEs4YSpqgfJHYfPFAoabhOwwyfG71TQXgJKXzHzjG7QkIon0yPiitjYx2KxMfS4PDqe2uqQG57vAa7ToLBU005dga9zvC'


export const SKILLS_LIST =[
   "Admin Assistant", 
"Data Entry", 
"Email Management", 
"Human Resource Management", 
"Project Coordinator", 
"Travel Planning", 
"Research Assistant ", 
"Community Forum Moderation", 
"Content Moderation", 
"Customer Support", 
"Email Support", 
"Phone Support", 
"Social Media Moderation", 
"Tech Support", 
"Legal Services", 
"Other Project Management", 
"Personal Assistant", 
"Recruitment Assistant", 
"Strategic Planning", 
"Inventory Management", 
"Real Estate Assistant", 
"Order Placing Assistant ", 
"Operations Management ", 
"Shopify Assistant", 
"Shopee Assistant", 
"Amazon Assistant ", 
"Alibaba Assistant ", 
"Chat Support ", 
"Billing Assistant ", 
"Business Plans", 
"Writing Project Management", 
"Speaking", 
"Translation", 
"Tutoring", 
"Teaching", 
"Writing", 
"Blogging", 
"Copywriting", 
"Creative Writing", 
"Editing Proofreading", 
"Ghost Writing", 
"Technical Writing", 
"Web Content Writing", 
"Transcription", 
"Affiliate Marketing", 
"Classified Ads Marketing", 
"Direct Mail Marketing", 
"Email Marketing", 
"Lead Generation", 
"Mobile Marketing", 
"Sales Representative/Manager", 
"Business Development", 
"Pinterest Marketing", 
"Ecommerce Manager", 
"Pay Per Click", 
"SEM", 
"SEO", 
"Social Media Marketing", 
"Telemarketing", 
"Cold Calling", 
"Video Marketing", 
"Amazon Product Ads", 
"Bing Ads", 
"Facebook Ads", 
"Google AdWords", 
"Media Buys", 
"Content Management", 
"Ecommerce / Shopping Carts", 
"Marketing Project Management", 
"Appointment Setter", 
"Facebook Marketing", 
"LinkedIn Marketing", 
"Instagram Marketing", 
"Craigslist Marketing", 
"YouTube Marketing", 
"Twitter Marketing", 
"Voice over artist", 
"Event Planner", 
"Advertising  ", 
"Account Manager", 
"Digital Marketing ", 
"Business Automation ", 
"Data Analyst ", 
"Lazada Manager ", 
"Community Management", 
"Quality Assurance", 
"Drupal Development", 
"Javascript", 
"Joomla Development", 
"Mysql", 
"PHP", 
"Wordpress Development", 
"Cpanel", 
"CSS", 
"Drupal", 
"Google analytics", 
"HTML", 
"Joomla", 
"Managing Servers", 
"PSD to HTML", 
"Webmaster Tools", 
"ReactJS", 
"NodeJS", 
"Wordpress", 
"Android development", 
"Desktop Applications", 
"Game development", 
"iOS development", 
"Software QA Testing", 
"Software Development Project Management", 
"Web Development Project Management", 
"Python", 
"Ruby on Rails", 
"C#", 
"ASP.NET", 
"ClickFunnels", 
"Shopify", 
"Magento", 
"MacOS", 
"Linux", 
"HTML5", 
"JAVA", 
"Backend Developer ", 
"Frontend Developer ", 
"Laravel", 
"3D Modeling", 
"Graphics Editing", 
"Logo Design", 
"Recording Audio", 
"Shirt Design", 
"Multimedia artist", 
"User Interface Design", 
"Video Editing", 
"Web page Design", 
"Design Project Management", 
"Animation Specialist", 
"Podcast Editor (Audio Editor)", 
"Motion Graphics Specialist ", 
"Accounting", 
"Bookkeeping", 
"Business Plans", 
"Financial Analysis", 
"Financial Forecasting", 
"Financial Management", 
"Investment Researching", 
"Payroll", 
"Tax Preparation", 
"Xero", 
"Quickbooks", 
"Auditor", 

















































































































































































































































































































































































































































































































































































































































































































































































































































































































]
