import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { addMember, getBoardDatas } from '../../actions/board';
import getInitials from '../../utils/getInitials';
import { Button, TextField } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CloseIcon from '@material-ui/icons/Close';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { getUserData } from '../../../../helpers/projectHelper';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Chip from '@mui/material/Chip';
// import { useSession, useSocket } from '../../../../helpers/hooks';

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    height: '100%',
    '& .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-elevation24.MuiPaper-rounded':
      {
        width: '500px',
      },
    '& .MuiDialogActions-root': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '150px',
      flexWrap: 'wrap',
    },
  },
}));

const StyledChip = withStyles((theme) => ({
  root: {
    maxWidth: 250,
    backgroundColor: '#e5e5e5',
    // backgroundColor:`${'#'+Math.floor(Math.random()*16777215).toString(16)}`,
    height: '20px',
  },
  label: {
    // whiteSpace: 'nowrap',
    // overflow: 'hidden',
    // textOverflow: 'ellipsis',
    fontSize: '14px',
    padding: '10px',
  },
}))(Chip);

const Members = ({ match }) => {
  const classes = useStyles();
  const { board_id } = useParams();
  const { full_name, user_type, user_id } = getUserData();
  // const {sendNotification=null} = useSocket();
  const isAuthenticated = localStorage.getItem('token');
  // const userTypes = useSelector((state) => state.auth);

  // const userType = user_type;
  const { id } = useParams();
  const [inviting, setInviting] = useState(false);
  const [user, setUser] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [users, setUsers] = useState([]);
  const boardMembers = useSelector((state) => state.board.board.members);
  const searchOptions = users.filter((user) =>
    boardMembers.find((boardMember) => boardMember.user === user._id) ? false : true,
  );
  const [openDialog, setOpenDialog] = useState(false);

  const board = useSelector((state) => state.board.board);
  const [searchByMembers, setsearchByMembers] = useState([]);
  const [userid, setuserid] = useState();

  useEffect(()=>{
    handleInputValue()
  },[])

  const searchUserOptions = async (inputValue) => {
    setInputValue(inputValue);
    const re = new RegExp(_.escapeRegExp(inputValue), 'i');
    const results = boardMembers.filter((object) => {
      if (re.test(object.name)) {
        // console.log('object', object.user);
        setuserid(object.user);
        return true;
      }
    });
    // console.log('idss', results[0]?.user);

    await setsearchByMembers(results);
    return results;
  };

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const dispatch = useDispatch();

  const handleInputValue = async (newInputValue=null) => {

    if (newInputValue && newInputValue !== '') {
        setInputValue(newInputValue);
    }else if(newInputValue == '' || newInputValue == null){
      setInputValue("");
    }


      let payload = {
        input: newInputValue,
        user_id: user_id,
        employer_id:user_id
      };
      const configs = {
        method: 'post',
        url: `https://phoenix-task-management.colan.in/api/v1/task-management/board/filter`,
        data: payload,
        headers: {
          'Content-Type': 'application/json',
          Authorization: isAuthenticated,
        },
      };
      await axios(configs).then((data) => {
        let search = data?.data?.result?.slice(0, 8);
        setUsers(search && search?.length > 0 ? search : []);
      });
  };

  const onSubmit = async () => {
    let data = {
      userId: user._id,
      boardId: board_id,
    };
    dispatch(addMember(data));
    // sendNotification?.({type:'TaskBoard',title:"New Task Board",msg:`${full_name} assigned you to a new task board.`},user._id)
    setUser(null);
    setInputValue('');
    setInviting(false);
  };

  const onSubmits = async () => {
    // console.log('idssssssssssssssssssssssss', searchByMembers[0].user, id);
    let data = {
      id: searchByMembers[0].user,
      boardid: board_id,
      userid: userid,
    };
    dispatch(getBoardDatas(data));
    // dispatch(getBoardDatas(searchByMembers[0].user, id, userid));
    setUser(null);
    setInputValue('');
    setInviting(false);
  };

  return (
    <div className="board-members-wrapper">
      <div className="board-members">
        {boardMembers.map((member, index) => {
          return (boardMembers.length > 3 && index + 1 <= 3) || boardMembers.length <= 3 ? (
            <Tooltip title={member.name} key={member.user}>
              {member.profile_id ? (
                <Avatar
                  fontSize="small"
                  alt="Remy Sharp"
                  src={`https://authapi.virtualstaff.ph/api/v1/file/download/${member.profile_id}`}
                />
              ) : (
                <Avatar fontSize="small" className="avatar">
                  {getInitials(member.name)}
                </Avatar>
              )}
            </Tooltip>
          ) : (
            ' '
          );
        })}
        <Dialog className={classes.dialogPaper} open={openDialog} onClose={handleClose}>
          <DialogTitle>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <p style={{ marginBottom: '0px' }}>{'Board Members'}</p>
              <IconButton
                onClick={handleClose}
                color="primary"
                aria-label="upload picture"
                component="span"
              >
                <CloseIcon />
              </IconButton>
            </div>
          </DialogTitle>
          <DialogActions>
            {boardMembers.map((member, index) => {
              return boardMembers.length > 3 && index + 1 > 3 ? (
                <div style={{ display: 'flex' }}>
                  {member.profile_id ? (
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <StyledChip
                        size="small"
                        style={{ backgroundColor: '#e5e5e5' }}
                        key={`${member.name}`}
                        label={`${member.name}`}
                        className={classes.chip}
                        avatar={`https://authapi.virtualstaff.ph/api/v1/file/download/${member.profile_id}`}
                      />
                    </div>
                  ) : (
                    <div style={{ display: 'flex' }}>
                      <StyledChip
                        size="small"
                        style={{ backgroundColor: '#e5e5e5' }}
                        key={`${member.name}`}
                        label={`${member.name}`}
                        className={classes.chip}
                        avatar={<Avatar>{getInitials(member.name)}</Avatar>}
                      />
                    </div>
                  )}
                </div>
              ) : (
                ' '
              );
            })}
          </DialogActions>
        </Dialog>
        {boardMembers.length > 3 ? (
          <Avatar style={{ cursor: 'pointer' }} onClick={handleClickOpen}>
            +{boardMembers.length - 3}
          </Avatar>
        ) : (
          ' '
        )}
      </div>

      {user_type && user_type !== 'job_seeker' ? (
        <div>
          <div className="invite">
            <Autocomplete
              value={user}
              onChange={(e, newMember) => setUser(newMember)}
              inputValue={inputValue}
              onInputChange={(e, newInputValue) => handleInputValue(newInputValue)}
              options={searchOptions}
              getOptionLabel={(member) => member.user_email}
              className="search-member"
              renderInput={(params) => (
                <TextField {...params} helperText="Search for user by email" autoFocus />
              )}
            />
            <div className="add-member">
              <Button disabled={!user} variant="contained" color="primary" onClick={onSubmit}>
                ADD STAFF
              </Button>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Members;
