import React, { useEffect, useState, useRef } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { GithubPicker } from 'react-color';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import moment from 'moment';
// import ReactTimeAgo from 'react-time-ago'
import DateFnsUtils from '@date-io/date-fns';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import parse from 'html-react-parser';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import * as yup from 'yup';
import { Formik, Form } from 'formik';
import { Container, Box } from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import {
  archiveCard,
  deleteCardComment,
  editCard,
  editCardComment,
  editCards,
  editCardtext,
  getCard,
  getCardComment,
  postCardComment,
} from '../../actions/board';
import { Button, Modal, TextField } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DeleteCard from './DeleteCard';
import CardMembers from './CardMembers';
import Checklist from '../checklist/Checklist';
import useStyles from '../../utils/modalStyles';
// import Avatar from '@material-ui/core/Avatar';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';
import getInitials from '../../utils/getInitials';
import { getUserData } from '../../../../helpers/projectHelper';
import { useParams } from 'react-router-dom';
// import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
// import { useParams } from 'react-router-dom';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import plugins from 'suneditor/src/plugins';

import CodeMirror from 'codemirror';
import 'codemirror/mode/htmlmixed/htmlmixed';
import 'codemirror/lib/codemirror.css';
import { TASK_MANAGEMENT_ROOT_API_BACKEND } from '../../../../../src/config/constant/apiConstant';

const colors = [
  '#B80000',
  '#DB3E00',
  '#FCCB00',
  '#008B02',
  '#006B76',
  '#1273DE',
  '#004DCF',
  '#5300EB',
  '#EB9694',
  '#FAD0C3',
  '#FEF3BD',
  '#BEDADC',
];

var parser = new DOMParser();

const options = {
  replace: (domNode) => {
    if (domNode.attribs && domNode.attribs.class === 'remove') {
      return <></>;
    }
  },
};

var formSchema = {
  Start_dates: null,
  due_dates: null,
  end_time: null,
};

var validationSchema = yup.object().shape({
  Start_dates: yup.date().nullable().required(),
  due_dates: yup.date().nullable().required(),
  end_time: yup.date().nullable().required(),
});

const CardModal = ({ cardId, open, setOpen, card, list, commentss, loading }) => {
  const myRef = React.createRef();
  const { full_name, user_type, user_id } = getUserData();
  const isAuthenticated = localStorage.getItem('token');
  const { board_id } = useParams();
  const messagesEndRef = useRef(null);
  // const { user, isAuthenticated } = useSelector((state) => state.auth);
  const [formSchemas, setFormSchemas] = useState({
    Start_dates: card.start_date,
    due_dates: card.due_date,
    end_time: card.due_date,
  });

  const [dateEditing, setDateEditing] = useState(false);
  const classes = useStyles();
  const [title, setTitle] = useState(card.title);
  const [description, setDescription] = useState(card.description);
  const dispatch = useDispatch();
  const [label, setLabel] = useState('');
  const [labelText, setLabelText] = useState('');
  const [priority, setpriority] = useState('');
  const [priorityColor, setpriorityColor] = useState('');
  const [comment, setComment] = useState('');
  const [files, setFiles] = useState();
  // const [fileItem,setFileItem] = useState();
  const [editComments, setEditComment] = useState();
  const [showComment, setShowcomment] = useState(false);
  const [commentEditing, setCommentEditing] = useState(false);
  const [commentId, setCommentId] = useState();
  const [newcomment, setnewcomment] = useState({
    text: '',
    name: '',
    user_id: '',
  });
  const [displayImages, setDisplayImages] = useState(false);
  const cards = useSelector((state) =>
    state.board.board.cardObjects.find((object) => object._id === cardId),
  );
  const [startDateTask, setStartDateTask] = useState();
  const [dueDateTask, setdueDateTask] = useState();
  const [remainder, setRemainder] = useState();
  const [remainderTime, setRemainderTime] = useState(0.5);
  const comments = useSelector((state) => state.board.board);
  const [savedComments, setsavedComments] = useState([]);
  useEffect(() => {
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: 'smooth',
    });
    myRef?.current?.scrollTo(0, 0);
    setTitle(card.title);
    setDescription(card.description);
    setFiles(card.files);
  }, [card]);

  useEffect(() => {
    let data = {
      id: cardId,
      user_id: user_id,
    };
    dispatch(getCardComment(data));
  }, [cardId]);

  const fileChange = (e) => {
    const file = e.target.files[0];
    // setFileItem(e.target.files[0])
    const fileReader = new FileReader();
    if (e.target.files[0]) {
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        setFiles({ src: fileReader.result, files: file, type: 'image', name: file.name });
      };
    }
  };

  const onTitleDescriptionSubmit = async (e) => {
    e.preventDefault();

    if (!files) {
      const formData = new FormData();
      await formData.append('title', title);
      await formData.append('description', description);
      await formData.append('cardId', cardId);
      dispatch(editCardtext(formData));
    } else {
      let formDatasssss = {
        title: title,
        description: description,
        files: files,
        card_id: cardId,
      };
      dispatch(editCard(formDatasssss));
    }
  };

  const handleDaskDates = (e, callBack) => {
    const formData = {
      card_id: cardId,
      start_date: e.Start_dates,
      due_date: new Date(
        `${new Date(e.due_dates).toLocaleDateString()}/${new Date(
          `${e.end_time}`,
        ).toLocaleTimeString()}`,
      ),
      remainder_alert: new Date(
        new Date(`${e.due_dates}`).setMinutes(
          new Date(`${e.due_dates}`).getMinutes() - remainderTime * 60,
        ),
      ),
    };
    dispatch(editCards(formData));
    callBack();
  };

  const editDateInfo = () => {
    setDateEditing(true);
    setRemainderTime(
      (new Date(card.due_date) - new Date(card.remainder_alert)) / 3600 == 500
        ? 0.5
        : (new Date(card.due_date) - new Date(card.remainder_alert)) / 3600 === 1000
        ? 1
        : 3,
    );
    // setRemainderTime(
    //   array.includes(
    //     new Date(card.remainder_alert).getMinutes() - new Date(card.due_date).getMinutes() / 60,
    //   )
    //     ? (new Date(card.remainder_alert).getMinutes() - new Date(card.due_date).getMinutes()) / 60
    //     : Math.round(
    //         (new Date(card.remainder_alert).getMinutes() - new Date(card.due_date).getMinutes()) /
    //           60,
    //       ),
    // );
  };

  const submititle = async () => {
    let data = {
      card_id: cardId,
      title: title,
      description: description,
    };

    dispatch(editCardtext(data));
  };

  const onArchiveCard = async () => {
    let data = {
      cardId: cardId,
      archive: false,
      user_id: user_id,
      boardId: board_id,
    };
    dispatch(archiveCard(data));
    setOpen(false);
  };

  const handleChange = async (e) => {
    e.persist();
    const name = await e.target.name;
    setLabel(e.target.value);
  };

  const handleChangeColor = (color) => {
    setLabelText(color);
  };

  const handleChanges = (e) => {
    setpriority(e.text);
    setpriorityColor(e.color);
  };

  const editcardcomments = (e) => {
    let formData = {
      id: cardId,
      text: editComments,
      commentId: e,
    };
    dispatch(editCardComment(formData));
    setCommentEditing(false);
  };

  const editComment = (e, comment) => {
    console.log('editComments 1', comment);
    console.log(
      'editComments 11',
      parser.parseFromString('<!doctype html><body>' + `${comment}`, 'text/html').body.textContent,
    );
    setCommentEditing(true);
    setCommentId(e);
    setEditComment(
      `${
        parser.parseFromString('<!doctype html><body>' + `${comment}`, 'text/html').body.textContent
      }`,
    );
  };

  const submitLabel = async () => {
    const formData = {
      card_id: cardId,
      label: label,
      labelText: labelText,
      priority: priority,
      priority_color: priorityColor,
    };
    dispatch(editCards(formData));
    setLabel('');
    setLabelText('');
    setpriority('');
  };

  const getComments = async (comments) => {
    setComment(comments);
  };

  const getEditComments = async (c) => {
    setEditComment(c);
  };

  const deleteComment = async (id) => {
    let data = {
      cardId: id,
      id: cardId,
    };
    await dispatch(deleteCardComment(data));
    await dispatch(getCard({ card_id: cardId }));
  };

  const submitComments = async () => {
    let data = {
      id: cardId,
      text: {
        text: comment,
        name: full_name,
        user_id: user_id,
        created_time: new Date(),
      },
    };
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    await dispatch(postCardComment(data));
    setShowcomment(true);
    setComment('');
    dispatch(getCard({ card_id: cardId }));
  };
  const boardMembers = useSelector((state) => state.board.board.members);
  const members = card.members.map((member) => member.user);

  return (
    <div>
      <Modal open={open} onClose={() => setOpen(false)}>
        <div className={`${classes.paper} ${classes.cardModal}`}>
          <form onSubmit={(e) => onTitleDescriptionSubmit(e)}>
            <div className={classes.modalTop}>
              {user_type && user_type == 'job_seeker' ? (
                <div styles={{ display: 'flex', flexDirection: 'row' }}>
                  {' '}
                  <div styles={{ display: 'flex', flexDirection: 'row' }}>
                    {' '}
                    <b>Task Title</b>
                  </div>
                  <br /> <p style={{ fontSize: '25px', lineHeight: '40px' }}>{title}</p>
                </div>
              ) : (
                <TextField
                  ref={myRef}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  multiline
                  label="Task name"
                  style={{ color: '#33444d !important' }}
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  onKeyPress={(e) => e.key === 'Enter' && onTitleDescriptionSubmit(e)}
                  className={classes.cardTitle}
                />
              )}
              {/* {user_type && user_type == 'job_seeker' ? (
                ''
              ) : ( */}
              <div
                style={{
                  display: 'flex',
                  paddingLeft: '10px',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  // alignItems: 'center',
                }}
              >
                <IconButton onClick={() => setOpen(false)}>
                  <CloseIcon />
                </IconButton>
              </div>
              {/* )} */}
            </div>
            <br />
            {user_type && user_type == 'job_seeker' ? (
              <div styles={{ display: 'flex', flexDirection: 'row' }}>
                {' '}
                <b>Task Description:</b> <br /> <br />{' '}
                {description ? (
                  <p> {description}</p>
                ) : (
                  <p style={{ color: '#e5e5e5', fontWeight: 600 }}>No description found !</p>
                )}
              </div>
            ) : (
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                multiline
                label="Task description"
                style={{ color: '#33444d !important' }}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className={classes.cardTitle}
              />
            )}
            {/* <br /> */}
            {user_type && user_type == 'job_seeker' ? ' ' : <br />}
            {/* <br /> */}
            {user_type && user_type == 'job_seeker' ? ' ' : <br />}
            {user_type && user_type == 'job_seeker' ? (
              ''
            ) : (
              <Button
                color="primary"
                disabled={
                  (!title || card?.title == title) &&
                  (!description || card?.description == description)
                }
                variant="contained"
                onClick={submititle}
              >
                Save Title and Description
              </Button>
            )}
            {/* <br /> */}
            {user_type && user_type == 'job_seeker' ? ' ' : <br />}
            {/* <br /> */}
            {user_type && user_type == 'job_seeker' ? ' ' : <br />}

            <div styles={{ display: 'flex', flexDirection: 'row' }}>
              {' '}
              <b>Attachments:</b>
            </div>

            {/* {card.files.map((e, index) => (
              <div>
                <a
                  style={{ textDecoration: 'none' }}
                  href={
                    card.files.length
                      ? `http://localhost:9098/api/v1/task-management/file/download/${e}`
                      : '#'
                  }
                  target="_blank"
                >
                  description files {index}
                </a>
                <br />
                <br />
              </div>
            ))} */}
            <div className={classes.scrollmenu}>
              {card.files.length > 0 ? (
                card.files.map((e, index) => (
                  <a
                    className={classes.an}
                    style={{ textDecoration: 'none' }}
                    href={
                      card.files.length
                        ? `${TASK_MANAGEMENT_ROOT_API_BACKEND}/file/download/${e}`
                        : '#'
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={`${TASK_MANAGEMENT_ROOT_API_BACKEND}/file/download/${e}`}
                      style={{ maxHeight: '200px' }}
                    />
                  </a>
                ))
              ) : (
                <p
                  style={{
                    color: '#e5e5e5',
                    fontWeight: 600,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: '10px 0px',
                  }}
                >
                  No files found !
                </p>
              )}
            </div>

            {displayImages
              ? card.files.map((e, index) => (
                  <div>
                    <img
                      style={{ width: '700px' }}
                      src={`https://phoenix-task-management.colan.in/api/v1/task-management/file/download/${e}`}
                    />
                    <br />
                  </div>
                ))
              : ' '}

            {/* {displayImages ? (
              <img
                style={{ width: "700px" }}
                src={`http://localhost:9098/api/v1/task-management/file/download/${card.files[0]}`}
              />
            ) : (
              " "
            )} */}
            {user_type && user_type == 'job_seeker' ? (
              ''
            ) : (
              <div
                style={{
                  marginTop: '15px',
                  alignItems: 'center',
                  display: user_type && user_type == 'job_seeker' ? 'none' : 'flex',
                }}
              >
                <Button
                  variant="contained"
                  style={{ display: 'flex', width: 'max-content', borderRadius: '15px' }}
                  component="label"
                >
                  {/* <UploadFileIcon /> */}
                  <span>Upload file</span>
                  <input type="file" multiple name="file" onChange={fileChange} hidden />
                </Button>
                &nbsp; &nbsp;
                <p style={{ marginBottom: '0px', fontSize: '17px' }}>
                  {files?.name ? files?.name : ''}
                </p>
              </div>
            )}

            {user_type && user_type == 'job_seeker' ? ' ' : <br />}
            {user_type && user_type == 'job_seeker' ? (
              ''
            ) : (
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.button}
                disabled={!files?.name}
              >
                Add File
              </Button>
            )}
          </form>
          {}

          <br />
          <Checklist card={card} />
          <br />
          <b>Dates</b>
          <br />
          <div>
            {cards.start_date || cards.due_date ? (
              <div>
                <Chip
                  style={{ width: 'max-content' }}
                  label={`Start date : ${new Date(cards.start_date).toLocaleString()}`}
                />{' '}
                - <Chip label={`Due date : ${new Date(cards.due_date).toLocaleString()}`} />
                {user_type == 'employer' ? (
                  <IconButton style={{ cursor: 'pointer' }} onClick={() => editDateInfo()}>
                    <EditIcon />
                  </IconButton>
                ) : (
                  ''
                )}
              </div>
            ) : (
              <p style={{ color: '#e5e5e5', fontWeight: 700 }}>No dates choosen</p>
            )}
          </div>
          <br />
          {user_type == 'employer' && dateEditing ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              {console.log('formSchemas', formSchemas)}
              <Formik
                validationSchema={validationSchema}
                initialValues={formSchemas}
                onSubmit={(value, onSubmit) => {
                  handleDaskDates(value, () => {
                    onSubmit.resetForm();
                    setDateEditing(false);
                  });
                }}
                render={(props) => (
                  <Form style={{ display: 'flex' }}>
                    <Box style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
                      <div
                        className={classes.datefeild}
                        style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}
                      >
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            // className="date-picker"
                            className={classes.starts_date}
                            variant="inline"
                            id="date-picker-dialog"
                            label="Start date"
                            inputVariant="outlined"
                            format="MM/dd/yyyy"
                            value={props.values.Start_dates}
                            minDate={moment().startOf('week').toDate()}
                            onChange={(value) => props.setFieldValue('Start_dates', value)}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                            InputProps={{
                              style: {
                                width: '220px',
                                marginRight: '20px',
                                color: '#33444d',
                                padding: '0px !important',
                              },
                            }}
                          />

                          <KeyboardDatePicker
                            // className="date-picker"
                            className={classes.starts_date}
                            variant="inline"
                            id="date-picker-dialog"
                            label="Due date"
                            inputVariant="outlined"
                            format="MM/dd/yyyy"
                            value={props.values.due_dates}
                            minDate={moment(props.values.Start_dates).toDate()}
                            onChange={(value) => props.setFieldValue('due_dates', value)}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                            InputProps={{
                              style: {
                                width: '220px',
                                marginRight: '20px',
                                color: '#33444d',
                                padding: '0px !important',
                              },
                            }}
                          />

                          <KeyboardTimePicker
                            margin="normal"
                            id="time-picker"
                            label="Select due time"
                            inputVariant="outlined"
                            variant="inline"
                            value={props.values.end_time}
                            onChange={(value) => props.setFieldValue('end_time', value)}
                            KeyboardButtonProps={{
                              'aria-label': 'change time',
                            }}
                            InputProps={{
                              style: {
                                color: '#33444d',
                                width: '220px',
                                marignTop: '0px',
                                marginRight: '20px',
                              },
                            }}
                          />
                        </MuiPickersUtilsProvider>
                        <FormControl
                          fullWidth
                          style={{ maxWidth: '220px', marginTop: '10px', marginRight: '10px' }}
                        >
                          <InputLabel id="demo-simple-select-label">
                            Set due date remainder
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={remainderTime}
                            label="Age"
                            style={{ color: '#33444d' }}
                            onChange={(e) => {
                              setRemainderTime(e.target.value);
                            }}
                          >
                            <MenuItem value={0.5}>30 Minutes before</MenuItem>
                            <MenuItem value={1}>1 Hours before</MenuItem>
                            <MenuItem value={3}>3 Hours before</MenuItem>
                          </Select>
                        </FormControl>

                        <Button
                          type="submit"
                          style={{ padding: '17px', width: 'max-content' }}
                          id="stop"
                          className={classes.button}
                          disabled={
                            props.values.Start_dates == null ||
                            props.values.due_dates == null ||
                            props.values.end_time == null
                          }
                          variant="contained"
                          color="primary"
                        >
                          Save changes
                        </Button>
                        {console.log("props.values.due_dates",props.values)}
                        <Button
                          style={{ padding: '17px', marginLeft: '10px', width: 'max-content' }}
                          id="stop"
                          className={classes.button}
                          disabled={
                            props.values.Start_dates == null ||
                            props.values.due_dates == null ||
                            props.values.end_time == null
                          }
                          variant="contained"
                          color="primary"
                          onClick={() => setDateEditing(false)}
                        >
                          Cancel
                        </Button>
                      </div>
                    </Box>
                  </Form>
                )}
              />
            </div>
          ) : (
            ''
          )}
        

          {user_type == 'employer' && !card?.due_date && !card?.start_date ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Formik
                validationSchema={validationSchema}
                initialValues={formSchema}
                onSubmit={(value, onSubmit) => {
                  handleDaskDates(value, () => {
                    onSubmit.resetForm();
                  });
                }}
                render={(props) => (
                  <Form style={{ display: 'flex' }}>
                    <Box style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
                      <div
                        className={classes.datefeild}
                        style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}
                      >
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            // className="date-picker"
                            className={classes.starts_date}
                            variant="inline"
                            id="date-picker-dialog"
                            label="Start date"
                            inputVariant="outlined"
                            format="MM/dd/yyyy"
                            value={props.values.Start_dates}
                            minDate={moment().startOf('week').toDate()}
                            onChange={(value) => props.setFieldValue('Start_dates', value)}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                            InputProps={{
                              style: {
                                width: '220px',
                                marginRight: '20px',
                                color: '#33444d',
                                padding: '0px !important',
                              },
                            }}
                            cancelLabel={<span style={{ color: "#304257" }}>cancel</span>}
                          />

                          <KeyboardDatePicker
                            // className="date-picker"
                            className={classes.starts_date}
                            variant="inline"
                            id="date-picker-dialog"
                            label="Due date"
                            inputVariant="outlined"
                            format="MM/dd/yyyy"
                            value={props.values.due_dates}
                            minDate={moment(props.values.Start_dates?props.values.Start_dates:new Date()).toDate()}
                            onChange={(value) => props.setFieldValue('due_dates', value)}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                            InputProps={{
                              style: {
                                width: '220px',
                                marginRight: '20px',
                                color: '#33444d',
                                padding: '0px !important',
                              },
                            }}
                          />

                          <KeyboardTimePicker
                            margin="normal"
                            id="time-picker"
                            label="Select due time"
                            inputVariant="outlined"
                            variant="inline"
                            value={props.values.end_time}
                            onChange={(value) => props.setFieldValue('end_time', value)}
                            KeyboardButtonProps={{
                              'aria-label': 'change time',
                            }}
                            InputProps={{
                              style: {
                                color: '#33444d',
                                width: '220px',
                                marignTop: '0px',
                                marginRight: '20px',
                              },
                            }}
                          />
                        </MuiPickersUtilsProvider>
                        <FormControl
                          fullWidth
                          style={{ maxWidth: '220px', marginTop: '10px', marginRight: '10px' }}
                        >
                          <InputLabel id="demo-simple-select-label">
                            Set due date remainder
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={remainderTime}
                            label="Age"
                            style={{ color: '#33444d' }}
                            onChange={(e) => {
                              setRemainderTime(e.target.value);
                            }}
                          >
                            <MenuItem value={0.5}>30 Minutes before</MenuItem>
                            <MenuItem value={1}>1 Hours before</MenuItem>
                            <MenuItem value={3}>3 Hours before</MenuItem>
                          </Select>
                        </FormControl>

                        <Button
                          type="submit"
                          style={{ padding: '17px', width: 'max-content' }}
                          id="stop"
                          className={classes.button}
                          disabled={
                            props.values.Start_dates == null ||
                            props.values.due_dates == null ||
                            props.values.end_time == null
                          }
                          variant="contained"
                          color="primary"
                        >
                          Add dates
                        </Button>
                      </div>
                    </Box>
                  </Form>
                )}
              />
            </div>
          ) : (
            ''
          )}
          <div className={classes.modalSection}>
            {user_type && user_type == 'job_seeker' ? ' ' : <CardMembers card={card} />}
            <div>
              <h3 className={classes.labelTitle}>Label</h3>

              {cards.label && user_type == 'job_seeker'
                ? ((cards.label && cards.label !== 'none') || cards.priority) && (
                    <div style={{ display: 'flex' }}>
                      <div
                        className="card-label"
                        style={{
                          marginTop: '10px',
                          padding: '10px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          backgroundColor: cards.labelText,
                          fontSize: '14px',
                          height: '25px',
                          borderRadius: '10px',
                          color: '#ffffff',
                          width: 'max-content',
                        }}
                      >
                        {cards.label}
                      </div>
                    </div>
                  )
                : ' '}
              <br />

              {user_type && user_type == 'job_seeker' ? (
                (cards.label || cards.label !== 'none' || cards.priority) && (
                  <div>
                    <br />
                    <div>
                      <b>Priority</b>
                      <br />
                      <br />
                      <div
                        className="card-label"
                        style={{
                          padding: '10px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          backgroundColor: cards?.priority_color,
                          fontSize: '14px',
                          height: '25px',
                          borderRadius: '10px',
                          color: '#ffffff',
                          width: 'max-content',
                          marginRight: '10px',
                        }}
                      >
                        {cards?.priority}
                      </div>
                    </div>
                  </div>
                )
              ) : (
                <div className={classes.labelsPriority}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div
                      style={{
                        display: cards.label || cards.priority ? 'flex' : 'none',
                        marginBottom: '10px',
                      }}
                    >
                      {cards?.label !== 'none' && (
                        <div
                          className="card-label"
                          style={{
                            padding: '10px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: cards?.labelText,
                            fontSize: '14px',
                            height: '25px',
                            borderRadius: '10px',
                            color: '#ffffff',
                            width: 'max-content',
                            marginRight: '10px',
                          }}
                        >
                          {cards?.label}
                        </div>
                      )}

                      <div
                        className="card-label"
                        style={{
                          padding: '10px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          backgroundColor: cards?.priority_color,
                          fontSize: '14px',
                          height: '25px',
                          borderRadius: '10px',
                          color: '#ffffff',
                          width: 'max-content',
                          marginRight: '10px',
                        }}
                      >
                        {cards?.priority}
                      </div>
                    </div>
                    {cards?.priority || cards?.label ? (
                      ' '
                    ) : (
                      <p style={{ color: '#e5e5e5', fontWeight: 600 }}>No items found !</p>
                    )}
                    {console.log('cards', cards)}
                    <GithubPicker
                      className={classes.colorPicker}
                      name="label"
                      colors={colors}
                      onChange={(color) => handleChangeColor(color.hex)}
                    />
                    &nbsp;
                    <Button
                      className={classes.noLabel}
                      variant="outlined"
                      onClick={async () =>
                        dispatch(editCardtext({ card_id: cardId, label: 'none' }))
                      }
                    >
                      No Label
                    </Button>
                  </div>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    multiline
                    label="Label Name"
                    // value={label}
                    name="labelText"
                    onChange={(e) => handleChange(e)}
                  />

                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <p className={classes.priorityText}>Set the priority for this card.</p>
                    <div
                      onClick={() => handleChanges({ text: 'Highest', color: 'red' })}
                      className={classes.priorityBtns}
                    >
                      <p style={{ marginBottom: '0px' }}>1. Highest</p>
                      <p
                        style={{
                          display: priority == 'Highest' ? 'block' : 'none',
                          marginBottom: '0px',
                          marginLeft: '5px',
                        }}
                      >
                        <CheckCircleIcon size="small" />
                      </p>
                    </div>
                    <div
                      onClick={() => handleChanges({ text: 'Critical', color: '#eea237' })}
                      className={classes.priorityBtns}
                      style={{ backgroundColor: '#eea237' }}
                    >
                      <p style={{ marginBottom: '0px' }}>2. Critical</p>
                      <p
                        style={{
                          display: priority == 'Critical' ? 'block' : 'none',
                          marginBottom: '0px',
                          marginLeft: '5px',
                        }}
                      >
                        <CheckCircleIcon size="small" />
                      </p>
                    </div>
                    <div
                      onClick={() => handleChanges({ text: 'Alarming', color: '#eed000' })}
                      className={classes.priorityBtns}
                      style={{ backgroundColor: '#eed000' }}
                    >
                      <p style={{ marginBottom: '0px' }}>3. Alarming</p>
                      <p
                        style={{
                          display: priority == 'Alarming' ? 'block' : 'none',
                          marginBottom: '0px',
                          marginLeft: '5px',
                        }}
                      >
                        <CheckCircleIcon size="small" />
                      </p>
                    </div>
                    <div
                      onClick={() => handleChanges({ text: 'Act soon', color: '#56b540' })}
                      className={classes.priorityBtns}
                      style={{ backgroundColor: '#56b540' }}
                    >
                      <p style={{ marginBottom: '0px' }}>4. Act soon</p>
                      <p
                        style={{
                          display: priority == 'Act soon' ? 'block' : 'none',
                          marginBottom: '0px',
                          marginLeft: '5px',
                        }}
                      >
                        <CheckCircleIcon size="small" />
                      </p>
                    </div>
                    <div
                      onClick={() => handleChanges({ text: 'Lowest', color: '#366bb7' })}
                      className={classes.priorityBtns}
                      style={{ backgroundColor: '#366bb7' }}
                    >
                      <p style={{ marginBottom: '0px' }}>5. Lowest</p>
                      <p
                        style={{
                          display: priority == 'Lowest' ? 'block' : 'none',
                          marginBottom: '0px',
                          marginLeft: '5px',
                        }}
                      >
                        <CheckCircleIcon size="small" />
                      </p>
                    </div>
                  </div>
                  <div className={classes.saveLabel}>
                    <Button
                      style={{ width: '80%' }}
                      //  className={classes.saveLabel}
                      color="primary"
                      variant="contained"
                      onClick={submitLabel}
                      disabled={!label || !priority || !labelText}
                    >
                      Save label
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
          <br />

          {user_type == 'job_seeker' ? (
            <div>
              <p style={{ fontSize: '27px' }}>Assignees</p>
              {boardMembers.map((member) => (
                <div>
                  {members.indexOf(member.user) !== -1 ? (
                    <div>
                      <Chip
                        avatar={
                          member.profile_id ? (
                            <Avatar
                              fontSize="small"
                              alt="Remy Sharp"
                              src={`https://authapi.virtualstaff.ph/api/v1/file/download/${member.profile_id}`}
                            />
                          ) : (
                            <Avatar fontSize="small" className="avatar">
                              {getInitials(member.name)}
                            </Avatar>
                          )
                        }
                        label={member.name}
                        clickable
                        color="primary"
                        // onDelete={handleDelete}
                        // deleteIcon={<DoneIcon />}
                        variant="outlined"
                      />
                      <br />
                      <br />
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              ))}
            </div>
          ) : (
            ''
          )}
          <br />

          <br />

          <br />

          <br />
          <div>
            <b style={{ fontSize: '27px', fontWeight: 500 }}>All Comments</b>

            {cards.comments || loading !== true ? (
              commentss
                ?.find((object) => object._id === cardId)
                .comments?.map((e) =>
                  commentEditing && e._id == commentId ? (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <b style={{ fontSize: '27px', fontWeight: 500 }}>Comments</b>
                      <br />
                      {/* <textarea
                        style={{ padding: '15px', fontSize: '17px', height: 'max-content' }}
                        cols="80"
                        placeholder="Comments"
                        name="comment"
                        value={editComments}
                        onChange={(e) => getEditComments(e.target.value)}
                      /> */}
                      {console.log('editComments', editComments)}
                      <SunEditor
                        setOptions={{
                          plugins: plugins,
                          buttonList: [
                            ['undo', 'redo'],
                            ['font', 'fontSize', 'formatBlock', 'paragraphStyle', 'blockquote'],
                            ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                            ['fontColor', 'hiliteColor', 'textStyle'],
                            ['removeFormat'],
                            '/',
                            ['outdent', 'indent'],
                            ['align', 'horizontalRule', 'list', 'lineHeight'],
                            ['table', 'link'],
                            // ['image'],
                          ],
                        }}
                        placeholder="Please type here..."
                        onChange={(e) => getEditComments(e)}
                        width="100%"
                        height="200px"
                        setContents={editComments}
                      />
                      <br />
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => editcardcomments(e._id)}
                        disabled={editComments.length == 0 || loading}
                      >
                        {loading && getComments ? (
                          <CircularProgress className={classes.circularProgress} size={20} />
                        ) : (
                          'Save Comment'
                        )}
                      </Button>
                    </div>
                  ) : (
                    <div
                      style={{
                        backgroundColor: '#e5e5e5',
                        padding: '10px',
                        margin: '10px',
                        borderRadius: '20px',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Tooltip title={e.name} key={e.name}>
                            <Avatar
                              className="avatar"
                              size="small"
                              style={{
                                borderRadius: '80px',
                                height: '25px',
                                width: '25px',
                              }}
                            >
                              {getInitials(e.name)}
                            </Avatar>
                          </Tooltip>
                          <b style={{ color: '#0a4b73', fontSize: '15px' }}>
                            &nbsp; {e.name.toUpperCase()}
                          </b>
                          &nbsp;
                          <p style={{ fontSize: '15px', marginBottom: '0px' }}>
                            {' '}
                            &nbsp;{moment(`${new Date(e.created_time)}`).fromNow()}
                          </p>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          {e.user_id == user_id ? (
                            <div>
                              <IconButton
                                style={{ cursor: 'pointer' }}
                                onClick={() => editComment(e._id, e.text)}
                              >
                                <EditIcon />
                              </IconButton>
                              <IconButton
                                style={{ cursor: 'pointer' }}
                                onClick={() => deleteComment(e._id)}
                              >
                                <DeleteForeverIcon />
                              </IconButton>
                            </div>
                          ) : (
                            ' '
                          )}
                        </div>
                      </div>
                      <p
                        style={{
                          margin: '10px 0px',
                          display: 'flex',
                          flexWrap: 'wrap',
                          fontSize: '14px',
                          paddingLeft: '35px',
                        }}
                      >
                        {parse(
                          parser.parseFromString('<!doctype html><body>' + `${e.text}`, 'text/html')
                            .body.textContent,
                          options,
                        )}
                        {/* {e.text} */}
                      </p>
                    </div>
                  ),
                )
            ) : (
              <div>
                <CircularProgress color="inherit" />
              </div>
            )}
          </div>
          <br />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <b style={{ fontSize: '27px', fontWeight: 500 }}>Comments</b>
            <br />
            {/* <textarea
               style={{ padding: '15px', fontSize: '17px' }}
              rows="2"
              cols="80"
              placeholder="Comments"
              name="comment"
              value={comment}
              onChange={(e) => getComments(e.target.value)}
            /> */}
            <SunEditor
              setOptions={{
                plugins: plugins,
                codeMirror: CodeMirror,
                icons: {
                  bold: '<span class="se-icon-text">B</span>',
                  table: '<i class="xx xxx></i>',
                  insert_row_above: '<svg></svg>',
                },
                imageResizing: true,
                imageWidth: '500px',
                buttonList: [
                  ['undo', 'redo'],
                  ['font', 'fontSize', 'formatBlock', 'paragraphStyle', 'blockquote'],
                  ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                  ['fontColor', 'hiliteColor', 'textStyle'],
                  ['removeFormat'],
                  '/',
                  ['outdent', 'indent'],
                  ['align', 'horizontalRule', 'list', 'lineHeight'],
                  ['table', 'link'],
                  ['codeView', 'image'],
                ],
              }}
              placeholder="Please type here..."
              onChange={(e) => {
                getComments(e);
              }}
              width="100%"
              height="200px"
              // autoFocus={true}
              setContents={comment}
            />
            <br />
            <Button
              variant="contained"
              color="primary"
              onClick={submitComments}
              disabled={comment.length == 0 || loading || comment == '<p><br></p>'}
            >
              {loading && commentEditing == false && comment.length > 0 ? (
                <CircularProgress className={classes.circularProgress} size={20} />
              ) : (
                'Post Comment'
              )}
            </Button>
          </div>
          <br />
          {user_type && user_type == 'job_seeker' ? (
            ' '
          ) : (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <br />
              <DeleteCard cardId={cardId} setOpen={setOpen} list={list} />
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

CardModal.propTypes = {
  cardId: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  card: PropTypes.object.isRequired,
  list: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  // console.log('state.board.board.cardObjects', state.board.loading, state.board.board.cardObjects);
  return {
    commentss: state.board.board.cardObjects,
    loading: state.board.loading,
  };
};

export default connect(mapStateToProps)(CardModal);
