import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { addCardMember } from '../../actions/board';
import { Checkbox, FormControl, FormControlLabel, FormGroup } from '@material-ui/core';
import useStyles from '../../utils/modalStyles';
import { getUserData } from '../../../../helpers/projectHelper';
import { useParams } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import getInitials from '../../utils/getInitials';
import Chip from '@material-ui/core/Chip';
import { useSocket } from "../../../../helpers/hooks";

const CardMembers = ({ card }) => {
  // const { sendNotification = {}} = useSocket()
  const { board_id } = useParams();
  // const { sendNotification = null } = useSocket();

  const classes = useStyles();
  const boardMembers = useSelector((state) => state.board.board.members);
  const members = card.members.map((member) => member.user);
  const dispatch = useDispatch();
  // const { user, isAuthenticated } = useSelector((state) => state.auth);
  const { full_name, user_type, user_id } = getUserData();
  const isAuthenticated = localStorage.getItem('token');

  const assigneCheckbox = (e) => {
    dispatch(
      addCardMember({
        add: e.target.checked,
        cardId: card._id,

        user_id: e.target.name,
        boardId: board_id,
      }),
    );
    // sendNotification?.({type:'NewTask',title:"New Task Added",msg:`${full_name} have added a new task to your task board`},e.target.name)
  };

  return (
    <div>
      <br />
      <h3 className={classes.membersTitle}>Assignees</h3>
      <FormControl component="fieldset">
        <FormGroup>
          {boardMembers.map((member) => (
            <FormControlLabel
              key={member.user}
              control={
                <Checkbox
                  checked={members.indexOf(member.user) !== -1}
                  onChange={async (e) => assigneCheckbox(e)}
                  name={member.user}
                />
              }
              label={
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Chip
                    avatar={
                      member.profile_id ? (
                        <Avatar
                          fontSize="small"
                          alt="Remy Sharp"
                          src={`https://authapi.virtualstaff.ph/api/v1/file/download/${member.profile_id}`}
                        />
                      ) : (
                        <Avatar fontSize="small" className="avatar">
                          {getInitials(member.name)}
                        </Avatar>
                      )
                    }
                    label={member.name}
                    clickable
                    color="primary"
                    // onDelete={handleDelete}
                    // deleteIcon={<DoneIcon />}
                    variant="outlined"
                  />
                </div>
                // member.name
              }
            />
          ))}
        </FormGroup>
      </FormControl>
    </div>
  );
};

CardMembers.propTypes = {
  card: PropTypes.object.isRequired,
};

export default CardMembers;
