import React from 'react';
import dashboardIcon from '../../../../assets/images/admin/admin_image/dashboardIcon.png';
import no_user from '../../../../assets/images/admin/admin_image/no-user-avatar.png';
import employerVeriIcon from '../../../../assets/images/admin/icons/employer-verifications.png';
import jobSeekerVeriIcon from '../../../../assets/images/admin/icons/jobseeker-verifications.png';
import jobSeekerIcon from '../../../../assets/images/admin/icons/manage-jobseeker.png';
import employerIcon from '../../../../assets/images/admin/icons/manage-employer.png';
import ticketIcon from '../../../../assets/images/admin/icons/manage-tickets.png';
import categoryIcon from '../../../../assets/images/admin/icons/manage-category.png';
import chatIcon from '../../../../assets/images/admin/icons/manage-chat.png';
import jobIcon from '../../../../assets/images/admin/icons/manage-job.png';
import notificationIcon from '../../../../assets/images/admin/icons/manage-notification.png';
import paymentIcon from '../../../../assets/images/admin/icons/manage-payments.png';
import skillsIcon from '../../../../assets/images/admin/icons/manage-skills.png';
import pendingIcon from '../../../../assets/images/admin/icons/manage-pending.png';
import accepteIcon from '../../../../assets/images/admin/icons/manage-accepted.png';
import rejectedIcon from '../../../../assets/images/admin/icons/manage-rejected.png';
import activityIcon from '../../../../assets/images/admin/icons/manage-activity-log.png';
import branch from '../../../../assets/images/admin/admin_image/branch.png';
import {
    SUPER_ADMIN_CATEGORY,
    SUPER_ADMIN_DASHBOARD,
    SUPER_ADMIN_EMPLOYER_LIST,
    SUPER_ADMIN_JOB_SEEKER_LIST,
    SUPER_ADMIN_MANAGE_CHAT,
    SUPER_ADMIN_MANAGE_JOBS,
    SUPER_ADMIN_MANAGE_NOTIFICATION,
    SUPER_ADMIN_MANAGE_PAYMENT,
    SUPER_ADMIN_SKILL,
    SUPER_ADMIN_JOB_TITLE,
    SUPER_ADMIN_MANAGE_STAFF,
    SUPER_ADMIN_ADD_STAFF,
    SUPER_ADMIN_JOB_SEEKER_VERIFICATION,
    SUPER_ADMIN_JOB_SEEKER_VERIFICATION_ID,
    SUPER_ADMIN_ADD_JOB_TITLE,
    SUPER_ADMIN_ADD_CATEGORY,
    SUPER_ADMIN_ADD_SKILL,
    SUPER_ADMIN_VERIFY_EMPLOYER_ROUTE,
    SUPER_ADMIN_ACTIVITY_LOG_LIST_ROUTE,
    SUPER_ADMIN_MANAGE_TICKET,
    SUPER_ADMIN_EMPLOYER_ACCEPTED_LIST_ROUTE,
    SUPER_ADMIN_EMPLOYER_REJECTED_LIST_ROUTE,
    SUPER_ADMIN_JOB_SEEKER_REJECTED_LIST_ROUTE,
    SUPER_ADMIN_JOB_SEEKER_ACCEPTED_LIST_ROUTE,
    SUPER_ADMIN_EMPLOYER_ID,
    SUPER_ADMIN_JOB_SEEKER_ID,
    SUPER_ADMIN_MANAGE_COMMISSIONS,
    SUPER_ADMIN_MANAGE_BONUS,
    SUPER_ADMIN_JOB_ID,
    SUPER_ADMIN_UNVERIFIED_JOBS,
    SUPER_ADMIN_UNVERIFIED_JOB_ID,
    SUPER_ADMIN_DELETE_EMPLOYER_LIST,
    SUPER_ADMIN_DELETE_JOB_SEEKER_LIST,
    SUPER_ADMIN_DELETE_EMPLOYER_ID,
    SUPER_ADMIN_DELETE_JOB_SEEKER_ID,
    SUPER_ADMIN_ANNOUNCEMENT_AND_PROMOS,
    SUPER_ADMIN_MY_ACCOUNT
} from '../../../../config/constant/routePathConstant';
import SideBarTabs from './sideBarTabs';
const dashboard = '/admin/dashboard';

const SideBar = () => {

    const sideBarData = [
        {
            name: "Dashboard",
            link: SUPER_ADMIN_DASHBOARD,
            icon: dashboardIcon,
            alt: "dashboardIcon",
            active: SUPER_ADMIN_DASHBOARD,
            activeId: "",
            sub: { condition: false, start: 0, end: 0 },
            subMenu: {
                condition: false
            }
        },
        {
            name: "Manage Jobs",
            link: SUPER_ADMIN_MANAGE_JOBS,
            icon: jobIcon, alt: "job icon",
            active: SUPER_ADMIN_MANAGE_JOBS,
            activeId: SUPER_ADMIN_JOB_ID,
            sub: { condition: false, start: 0, end: 0 },
            subMenu: {
                condition: false
            }
        },
        {
            name: "Manage Unverified Jobs",
            link: SUPER_ADMIN_UNVERIFIED_JOBS,
            icon: jobIcon, alt: "job icon",
            active: SUPER_ADMIN_UNVERIFIED_JOBS,
            activeId: SUPER_ADMIN_UNVERIFIED_JOB_ID,
            sub: { condition: false, start: 0, end: 0 },
            subMenu: {
                condition: false
            }
        },
        {
            name: "Manage Employer",
            link: SUPER_ADMIN_EMPLOYER_LIST,
            icon: employerIcon, alt: "employer icon",
            active: SUPER_ADMIN_EMPLOYER_LIST,
            activeId: SUPER_ADMIN_EMPLOYER_ID,
            sub: { condition: true, start: 0, end: 28 },
            subMenu: {
                condition: false
            }
        },
        {
            name: "Delete Employer Request",
            link: SUPER_ADMIN_DELETE_EMPLOYER_LIST,
            icon: employerIcon, alt: "employer icon",
            active: SUPER_ADMIN_DELETE_EMPLOYER_LIST,
            activeId: SUPER_ADMIN_DELETE_EMPLOYER_ID,
            sub: { condition: true, start: 0, end: 28 },
            subMenu: {
                condition: false
            }
        },
        {
            name: "Manage Jobseeker",
            link: SUPER_ADMIN_JOB_SEEKER_LIST,
            icon: jobSeekerIcon, alt: "job seeker icon",
            active: SUPER_ADMIN_JOB_SEEKER_LIST,
            activeId: SUPER_ADMIN_JOB_SEEKER_ID,
            sub: { condition: true, start: 0, end: 29 },
            subMenu: {
                condition: false
            }
        },
        {
            name: "Delete Jobseeker Request",
            link: SUPER_ADMIN_DELETE_JOB_SEEKER_LIST,
            icon: jobSeekerIcon, alt: "job seeker icon",
            active: SUPER_ADMIN_DELETE_JOB_SEEKER_LIST,
            activeId: SUPER_ADMIN_DELETE_JOB_SEEKER_ID,
            sub: { condition: true, start: 0, end: 29 },
            subMenu: {
                condition: false
            }
        },
        {
            name: "Manage Category",
            link: SUPER_ADMIN_CATEGORY,
            icon: categoryIcon,
            alt: "category icon",
            active: SUPER_ADMIN_CATEGORY,
            activeId: SUPER_ADMIN_ADD_CATEGORY,
            sub: { condition: false, start: 0, end: 0 },
            subMenu: {
                condition: false
            }
        },
        {
            name: "Manage Job Title",
            link: SUPER_ADMIN_JOB_TITLE,
            icon: jobSeekerIcon,
            alt: "job title icon",
            active: SUPER_ADMIN_JOB_TITLE,
            activeId: SUPER_ADMIN_ADD_JOB_TITLE,
            sub: { condition: false, start: 0, end: 0 },
            subMenu: {
                condition: false
            }
        },
        {
            name: "Manage Skills",
            link: SUPER_ADMIN_SKILL,
            icon: skillsIcon,
            alt: "skills icon",
            active: SUPER_ADMIN_SKILL,
            activeId: SUPER_ADMIN_ADD_SKILL,
            sub: { condition: false, start: 0, end: 0 },
            subMenu: {
                condition: false
            }
        },
        {
            name: "Manage Staff",
            link: SUPER_ADMIN_MANAGE_STAFF,
            icon: branch,
            alt: "staff icon",
            active: SUPER_ADMIN_MANAGE_STAFF,
            activeId: SUPER_ADMIN_ADD_STAFF,
            sub: { condition: false, start: 0, end: 0 },
            subMenu: {
                condition: false
            }
        },
        {
            name: "Verify Pending Employers",
            link: SUPER_ADMIN_VERIFY_EMPLOYER_ROUTE,
            icon: pendingIcon,
            alt: "Pending icon",
            active: SUPER_ADMIN_VERIFY_EMPLOYER_ROUTE,
            activeId: "",
            sub: { condition: false, start: 0, end: 0 },
            subMenu: {
                condition: false
            }
        },
        {
            name: "Verify Pending Jobseekers",
            link: SUPER_ADMIN_JOB_SEEKER_VERIFICATION,
            icon: pendingIcon,
            alt: "Pending icon",
            active: SUPER_ADMIN_JOB_SEEKER_VERIFICATION,
            activeId: "",
            sub: { condition: false, start: 0, end: 0 },
            subMenu: {
                condition: false
            }
        },
        // {
        //     parentName: "Verify Job Seeker",
        //     parentIcon: jobSeekerVeriIcon,
        //     id: "job_seeker",

        //     subMenuData: [
        //         {
        //             name: "Pending",
        //             link: SUPER_ADMIN_JOB_SEEKER_VERIFICATION,
        //             icon: pendingIcon,
        //             alt: "Pending icon",
        //             active: SUPER_ADMIN_JOB_SEEKER_VERIFICATION,
        //             activeId: "",
        //         },
        //         {
        //             name: "Accept",
        //             link: SUPER_ADMIN_JOB_SEEKER_ACCEPTED_LIST_ROUTE,
        //             icon: accepteIcon,
        //             alt: "Accept icon",
        //             active: SUPER_ADMIN_JOB_SEEKER_ACCEPTED_LIST_ROUTE,
        //             activeId: "",
        //         },
        //         {
        //             name: "Reject",
        //             link: SUPER_ADMIN_JOB_SEEKER_REJECTED_LIST_ROUTE,
        //             icon: rejectedIcon,
        //             alt: "Reject icon",
        //             active: SUPER_ADMIN_JOB_SEEKER_REJECTED_LIST_ROUTE,
        //             activeId: "",
        //         },
        //     ],
        //     subMenu: {
        //         condition: true,
        //     },
        //     activeParentTab: [
        //         SUPER_ADMIN_JOB_SEEKER_VERIFICATION,
        //         SUPER_ADMIN_JOB_SEEKER_VERIFICATION_ID,
        //         SUPER_ADMIN_JOB_SEEKER_ACCEPTED_LIST_ROUTE,
        //         SUPER_ADMIN_JOB_SEEKER_REJECTED_LIST_ROUTE
        //     ],
        // },

        // {
        //     parentName: "Verify Employer",
        //     parentIcon: employerVeriIcon,
        //     id: "employer",
        //     subMenuData: [
        //         {
        //             name: "Pending",
        //             link: SUPER_ADMIN_VERIFY_EMPLOYER_ROUTE,
        //             icon: pendingIcon,
        //             alt: "Pending icon",
        //             active: SUPER_ADMIN_VERIFY_EMPLOYER_ROUTE,
        //             activeId: "",
        //         },
        //         {
        //             name: "Accept",
        //             link: SUPER_ADMIN_EMPLOYER_ACCEPTED_LIST_ROUTE,
        //             icon: accepteIcon,
        //             alt: "Accept icon",
        //             active: SUPER_ADMIN_EMPLOYER_ACCEPTED_LIST_ROUTE,
        //             activeId: "",
        //         },
        //         {
        //             name: "Reject",
        //             link: SUPER_ADMIN_EMPLOYER_REJECTED_LIST_ROUTE,
        //             icon: rejectedIcon,
        //             alt: "Reject icon",
        //             active: SUPER_ADMIN_EMPLOYER_REJECTED_LIST_ROUTE,
        //             activeId: "",
        //         },
        //     ],
        //     subMenu: {
        //         condition: true,
        //     },
        //     activeParentTab: [
        //         SUPER_ADMIN_VERIFY_EMPLOYER_ROUTE,
        //         SUPER_ADMIN_EMPLOYER_REJECTED_LIST_ROUTE,
        //         SUPER_ADMIN_EMPLOYER_ACCEPTED_LIST_ROUTE
        //     ],
        // },
        {
            name: " Manage Activity Log",
            link: SUPER_ADMIN_ACTIVITY_LOG_LIST_ROUTE,
            icon: activityIcon,
            alt: "activity icon",
            active: SUPER_ADMIN_ACTIVITY_LOG_LIST_ROUTE,
            activeId: "",
            sub: { condition: false, start: 0, end: 0 },
            subMenu: {
                condition: false
            }
        },
        {
            name: "Manage Chat",
            link: SUPER_ADMIN_MANAGE_CHAT,
            icon: chatIcon,
            alt: "Chat icon",
            active: SUPER_ADMIN_MANAGE_CHAT,
            activeId: "",
            sub: { condition: false, start: 0, end: 0 },
            subMenu: {
                condition: false
            }
        },
        {
            name: "Manage Notifications",
            link: SUPER_ADMIN_MANAGE_NOTIFICATION,
            icon: notificationIcon,
            alt: "Chat icon",
            active: SUPER_ADMIN_MANAGE_NOTIFICATION,
            activeId: "",
            sub: { condition: false, start: 0, end: 0 },
            subMenu: {
                condition: false
            }
        },
        // {
        //     name: "Manage Dispute",
        //     link: SUPER_ADMIN_MANAGE_TICKET,
        //     icon: ticketIcon,
        //     alt: "Chat icon",
        //     active: SUPER_ADMIN_MANAGE_TICKET,
        //     activeId: "",
        //     sub: { condition: false, start: 0, end: 0 },
        //     subMenu: {
        //         condition: false
        //     }
        // },
        {
            name: "Manage Payments",
            link: SUPER_ADMIN_MANAGE_PAYMENT,
            icon: paymentIcon,
            alt: "payment icon",
            active: SUPER_ADMIN_MANAGE_PAYMENT,
            activeId: "",
            sub: { condition: false, start: 0, end: 0 },
            subMenu: {
                condition: false,
            }
        },

        {
            name: "Manage Commissions",
            link: SUPER_ADMIN_MANAGE_COMMISSIONS,
            icon: paymentIcon,
            alt: "comissions icon",
            active: SUPER_ADMIN_MANAGE_COMMISSIONS,
            activeId: "",
            sub: { condition: false, start: 0, end: 0 },
            subMenu: {
                condition: false,
            }
        },

        {
            name: "Manage Bonus",
            link: SUPER_ADMIN_MANAGE_BONUS,
            icon: paymentIcon,
            alt: "bonus icon",
            active: SUPER_ADMIN_MANAGE_BONUS,
            activeId: "",
            sub: { condition: false, start: 0, end: 0 },
            subMenu: {
                condition: false,
            }
        },
        {
            name: "Announcement and promos",
            link: SUPER_ADMIN_ANNOUNCEMENT_AND_PROMOS,
            icon: paymentIcon,
            alt: "bonus icon",
            active: SUPER_ADMIN_ANNOUNCEMENT_AND_PROMOS,
            activeId: "",
            sub: { condition: false, start: 0, end: 0 },
            subMenu: {
                condition: false,
            }
        },
        {
            name: "My Account",
            link: SUPER_ADMIN_MY_ACCOUNT,
            icon: employerIcon,
            alt: "bonus icon",
            active: SUPER_ADMIN_MY_ACCOUNT,
            activeId: "",
            sub: { condition: false, start: 0, end: 0 },
            subMenu: {
                condition: false,
            }
        },
    ]
    const mapSideBar = sideBarData.map((item, i) => (
        <SideBarTabs key={i}
            name={item.name} link={item.link} icon={item.icon} alt={item.alt} active={item.active} sub={item.sub} activeId={item.activeId} sub_menu={item.subMenu}
            activeParentTab={item.activeParentTab} subMenuData={item.subMenuData} data={item}
        />
    ))
    return (
        <div className='mobcoll collapse navbar-collapse ' id="collapsibleNavbar">
             <button
                    className="navbar-toggler mobbutn"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapsibleNavbar"
                >
                    <span className="navbar-toggler-icon">
                        <svg
                            width="23"
                            height="20"
                            viewBox="0 0 23 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M1.23926 4.23096H21.5518C21.9833 4.23096 22.333 3.8812 22.333 3.44971V1.49658C22.333 1.06509 21.9833 0.715332 21.5518 0.715332H1.23926C0.807764 0.715332 0.458008 1.06509 0.458008 1.49658V3.44971C0.458008 3.8812 0.807764 4.23096 1.23926 4.23096ZM1.23926 12.0435H21.5518C21.9833 12.0435 22.333 11.6937 22.333 11.2622V9.30908C22.333 8.87759 21.9833 8.52783 21.5518 8.52783H1.23926C0.807764 8.52783 0.458008 8.87759 0.458008 9.30908V11.2622C0.458008 11.6937 0.807764 12.0435 1.23926 12.0435ZM1.23926 19.856H21.5518C21.9833 19.856 22.333 19.5062 22.333 19.0747V17.1216C22.333 16.6901 21.9833 16.3403 21.5518 16.3403H1.23926C0.807764 16.3403 0.458008 16.6901 0.458008 17.1216V19.0747C0.458008 19.5062 0.807764 19.856 1.23926 19.856Z"
                                fill="black"
                            />
                        </svg>
                    </span>
                </button>
        <>
       
            <aside className="app-sidebar" >
              
                    <div className="app-sidebar__user">
                        <div className="imgupload">
                            <img style={{ backgroundColor: "White" }}
                                className={`app-sidebar__user-avatar ${dashboard === window.location.pathname ? 'active' : ''
                                    }`}
                                src={no_user}
                                alt="Admin Image"
                            />

                        </div>
                        <div>
                            {/* <p className="app-sidebar__user-name">John Doe</p> */}
                            <span className="app-sidebar__user-designation">Admin</span>
                        </div>
                    </div>
                    <ul className="app-menu">
                        {mapSideBar}
                    </ul>
                
            </aside>
        </>
        </div>
    );
};


export default SideBar;
