import { lazy } from 'react';
import {  
  ACCOUNT_EMPLOYER_VERIFICATION,
  ACCOUNT_JOB_SEEKER_VERIFICATION,
  ACCOUNT_VERIFY_LOGIN,
  ACCOUNT_VERIFY,
  ACCOUNT_EMPLOYER_ACCEPTED,
  ACCOUNT_EMPLOYER_REJECTED,
  ACCOUNT_JOB_SEEKER_ACCEPTED,
  ACCOUNT_JOB_SEEKER_REJECTED,
} from '../constant/routePathConstant';
import { RedirectToAdDashboard } from '../../components/ad/common/redirect';

const AccountVerifyLogin = lazy(() => import('../../components/ad/login'));
const EmployerVerificationPending = lazy(() => import('../../components/ad/verifyEmployerList'));
const EmployerVerificationAccept = lazy(() => import('../../components/ad/verifyEmployerAcceptedList'));
const EmployerVerificationReject = lazy(() => import('../../components/ad/verifyEmployerRejected'));
const JobSeekerVerification = lazy(() => import('../../components/ad/verifyJobSeekerList'));
const JobSeekerVerificationAccept = lazy(() => import('../../components/ad/verifyJobSeekerAcceptList'));
const JobSeekerVerificationReject = lazy(() => import('../../components/ad/verfiryJobSeekerRejectList'));

export const ACCOUNT_VERIFY_ROUTE = [
  { component: AccountVerifyLogin , path: ACCOUNT_VERIFY_LOGIN },
  { component: RedirectToAdDashboard, path: ACCOUNT_VERIFY },
  { component: EmployerVerificationPending, path: ACCOUNT_EMPLOYER_VERIFICATION },
  { component: EmployerVerificationAccept, path: ACCOUNT_EMPLOYER_ACCEPTED },
  { component: EmployerVerificationReject, path: ACCOUNT_EMPLOYER_REJECTED },
  { component: JobSeekerVerification, path: ACCOUNT_JOB_SEEKER_VERIFICATION },
  { component: JobSeekerVerificationAccept, path: ACCOUNT_JOB_SEEKER_ACCEPTED },
  { component: JobSeekerVerificationReject, path: ACCOUNT_JOB_SEEKER_REJECTED }
  
]
