import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { addBoard } from '../../actions/board';
import { Button, Modal, TextField } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import useStyles from '../../utils/modalStyles';
import { getUserData } from '../../../../helpers/projectHelper';
import CircularProgress from '@material-ui/core/CircularProgress';

const CreateBoard = ({ history }) => {
  const { full_name, user_type, user_id } = getUserData();
  const isAuthenticated = localStorage.getItem('token');
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('');
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.board.loading);

  const onSubmit = async (e) => {
    e.preventDefault();
    let data = {
      title: title,
      user_id: user_id,
    };
    dispatch(addBoard(data, history));
    setTitle('');
    openToggle();
  };

  const openToggle = () => {
    setOpen(false);
    setTitle('');
  };

  const body = (
    <div className={`${classes.paper} ${classes.createBoardModal}`}>
      <div className={classes.modalTop}>
        <h3>Create new board</h3>
        <Button onClick={() => openToggle()}>
          <CloseIcon />
        </Button>
      </div>
      {console.log('loading', loading)}
      <form onSubmit={(e) => onSubmit(e)}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label="Add board title"
          autoFocus
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <Button type="submit" disabled={!title} fullWidth variant="contained" color="primary">
          Create Board
        </Button>
      </form>
    </div>
  );

  return (
    <div>
      <button
        className="board-card create-board-card"
        disabled={loading}
        onClick={() => setOpen(true)}
      >
        {loading ? <CircularProgress /> : 'Create New Board'}
      </button>
      <Modal open={open} onClose={() => setOpen(false)}>
        {body}
      </Modal>
    </div>
  );
};

export default withRouter(CreateBoard);
