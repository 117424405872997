import logo_img from '../../assets/images/logo.png';
import dummy_logo from '../../assets/images/jobseeker/dummy-logo.png';
import male_user_img from '../../assets/images/message/maledescrightpic.png';
import male_chat_img from '../../assets/images/message/leftpropicmale.png';
import female_user_img from '../../assets/images/message/leftpropicfemale.png';
import user_img from '../../assets/images/user_img.png';
import block_user from '../../assets/images/block-user.jpg';
import file_img from '../../assets/images/file.png';
import loading_img from '../../assets/images/loading.gif';

//admin
export const LOGO_IMG = logo_img;
export const DUMMY_IMG = dummy_logo;
export const MALE_USER_IMG = male_user_img;
export const MALE_CHAT_IMG = male_chat_img;
export const FEMALE_CHAT_IMG = female_user_img;
export const USER_IMG = user_img;
export const BLOCK_USER = block_user;
export const FILE_IMG = file_img;
export const LOADING_IMG = loading_img;

