import React from 'react'
import { Link } from 'react-router-dom';
import { JOBSEEKER_HOW_IT_WORKS } from '../../config/constant/routePathConstant';
import { useHistory } from 'react-router-dom'

function JSAlert() {
    const history = useHistory()
    return (
        <div className='p-3' style={{ backgroundColor: "green" }}>
            <div className='d-flex justify-content-between learn-how-it-works-alert' style={{ width: "96%" }}>
                <div>
                    <p className='m-0' style={{ color: "White", width: "58%", fontSize: "13px" }}>When you get hired by an employer on VirtualStaff.ph, you must remember to record your attendance on Work Log to get paid.</p>
                </div>
                <div className='d-flex align-items-center'>
                    <a href='https://support.virtualstaff.ph/357540-Where-Can-I-View-My-Work-Log'target='_blank'>
                        <button style={{
                            border: "3px white solid",
                            outline: "none",
                            color: "black",
                            fontSize: "14px",
                            borderRadius: "6px",
                            backgroundColor: "white",
                            fontWeight: "bold"
                        }}>Learn how it works!</button>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default JSAlert