import { createSlice } from '@reduxjs/toolkit';

const hireEmployeeSlice = createSlice({
    name: 'hireEmployee',
    initialState: {
      employeeList:[],
      individualEmpList:[],
      isBookmarked:"",
      bookmarkedEmpList:[],
      jobApplicantsList:[],
      postedJobsList:[],
      individualJobList:[],
      jobApplicationCount:"",
      viewedApplicantList:[],
      totalApplicantsCount:"",
      allApplicantsList:[],
      empProfileData:[],
      jobOfferList:[],
      postJobCount:"",
      bookmarkedEmpCount:0,
      staffCount:0,
      offerListCount:0,
      projectDetails:[],
      hiredStaffList:[],
      transactionList:[],
      empInvoiceList:[],
      individualOfferList:[],
      hiredEmpIndividualDetailData:{},
      indivdualProjectData:[],
      individualInvoiceData:[],
      govFile:'',
      selfieFile:'',
      bonusTransaction:[],
      paymentLoader:false,
      loaderMessage:"",
      sendOfferData:{},
      cardData:{},
      paymentError:[],
    },
    reducers: {
       employeeListData: (state, { payload }) => {
          state.employeeList = payload;
       },
       individualEmpData: (state, { payload }) => {
        state.individualEmpList = payload;
     },
     bookmarkEmployeeCheck:(state,{payload}) => {
        state.isBookmarked = payload
     },
     bookmarkedEmpList:(state,{payload}) => {
        state.bookmarkedEmpList = payload
     },
     jobApplicantsData:(state,{payload}) => {
         state.jobApplicantsList = payload
     },
     postedJobsData:(state,{payload}) => {
         state.postedJobsList = payload
     },
     individualJobsData:(state,{payload}) => {
      state.individualJobList = payload
  },
   jobViewData:(state,{payload}) => {
      state.jobApplicationCount = payload
   },
   viewedApplicantData:(state,{payload}) => {
      state.viewedApplicantData = payload
   },
   getTotalAppCount:(state,{payload}) => {
      state.getTotalAppCount =  payload
   },
   allApplicantsData:(state,{payload}) => {
      state.allApplicantsList =  payload
   },
   getEmpProfileData:(state,{payload}) => {
      state.empProfileData = payload
   },
   getJobOfferData:(state,{payload}) => {
      state.jobOfferList = payload
   },
   getPostedJobsCount:(state,{payload}) => {
      state.postJobCount = payload
   },
   getBookmarkedEmpCount:(state,{payload}) => {
      state.bookmarkedEmpCount = payload
   },
   getStaffCount:(state,{payload}) => {
      state.staffCount = payload
       
   },
   getOfferCount:(state,{payload}) =>{
      state.offerListCount = payload
   },
   getProjectDetails:(state,{payload}) => {
      state.projectDetails = payload
   },
   getHiredStaff:(state,{payload}) => {
      state.hiredStaffList = payload
   },
   getTransactionList:(state,{payload}) => {
      state.transactionList = payload
   },
   getEmpInvoiceList:(state,{payload}) => {
      state.empInvoiceList = payload
   },
   getEmpIndividualOfferList:(state,{payload}) => {
      state.individualOfferList = payload
   },
   hiredEpmIndividualDetail:(state,{payload}) => {
      state.hiredEmpIndividualDetailData= payload
   },
   getIndividualProjectList:(state,{payload}) => {
      state.indivdualProjectData = payload
   },
   getIndividualInvoiceList:(state,{payload}) => {
      state.individualInvoiceData = payload
   },
   govFileAct: (state, { payload }) => {
      state.govId = payload;
    },
    selfieFileAct: (state, { payload }) => {
      state.selfieId = payload;
    },
    getBonusTransactionList:(state,{payload}) => {
       state.bonusTransaction = payload;
    },
    setLoader:(state,{payload}) => {
       state.paymentLoader = payload
    },
    setLoaderMessage:(state,{payload}) => {
       state.loaderMessage = payload
    },
    getSendOfferData:(state,{payload}) => {
       state.sendOfferData = payload
    },
    getCardData:(state,{payload}) => {
       state.cardData = payload;
    },
    setPaymentError:(state,{payload}) => {
       state.paymentError = payload;
    }
},
 });
 
 export const {setPaymentError,getCardData,setLoaderMessage,getSendOfferData,setLoader,getBonusTransactionList,selfieFileAct,govFileAct,getIndividualInvoiceList,getIndividualProjectList,getEmpIndividualOfferList, getEmpInvoiceList, getTransactionList,getHiredStaff,getProjectDetails,getOfferCount,getStaffCount, getBookmarkedEmpCount, allApplicantsData,employeeListData,individualEmpData,bookmarkEmployeeCheck,bookmarkedEmpList,jobApplicantsData,postedJobsData,individualJobsData,jobViewData,viewedApplicantData,getTotalAppCount,getEmpProfileData,getJobOfferData,getPostedJobsCount,hiredEpmIndividualDetail} = hireEmployeeSlice.actions;
 export const hireEmployeeSelector = state => state.hireEmployee;
 const hireEmployeeReducer = hireEmployeeSlice.reducer;
 export default hireEmployeeReducer;
 