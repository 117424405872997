import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { getList } from '../../actions/board';
import ListTitle from './ListTitle';
import ListMenu from './ListMenu';
import Card from '../card/Card';
import CreateCardForm from './CreateCardForm';
import Button from '@material-ui/core/Button';
import { getUserData } from '../../../../helpers/projectHelper';

const List = ({ listId, index }) => {
  const { full_name, user_type, user_id } = getUserData();
  const isAuthenticated = localStorage.getItem('token');

  // const { user, isAuthenticated } = useSelector((state) => state.auth);
  const board = useSelector((state) => state.board.board);
  const [addingCard, setAddingCard] = useState(false);
  const list = useSelector((state) =>
    state.board.board.listObjects.find((object) => object._id === listId),
  );
  const cardsids = useSelector((state) => state.board.cardsids);
  const dispatch = useDispatch();

  const getID = (cardId) => {
    if (cardsids.length) {
      cardsids.map((e) => {
        if (e._id == cardId) return cardId;
      });
    }
  };

  useEffect(() => {
    console.log('listId', listId);
    let data = {
      id: listId,
    };
    dispatch(getList(data));
  }, [dispatch, listId]);

  const createCardFormRef = useRef(null);
  useEffect(() => {
    addingCard && createCardFormRef.current.scrollIntoView();
  }, [addingCard]);

  return !list || (list && list.archived) ? (
    ''
  ) : (
    <Draggable draggableId={listId} index={index}>
      {(provided) => (
        <div
          className="list-wrapper"
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <div className="list-top">
            <ListTitle list={list} />
            <ListMenu listId={listId} />
          </div>
          <Droppable droppableId={listId} type="card">
            {(provided) => (
              <div
                className={`list ${addingCard ? 'adding-card' : 'not-adding-card'}`}
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                <div className="cards">
                  {console.log('userids', cardsids)}
                  {list.cards.map((cardId, index) => (
                    <div>
                      {console.log(
                        'useridsdsdsds',
                        cardId,
                        cardsids?.filter((e) => e._id === cardId)[0]?._id,
                      )}
                      <Card
                        key={
                          cardsids?.length > 0
                            ? cardsids?.filter((e) => e._id === cardId)[0]?._id
                            : cardId
                        }
                        cardId={
                          cardsids?.length > 0
                            ? cardsids?.filter((e) => e._id === cardId)[0]?._id
                            : cardId
                        }
                        // key={cardId}
                        // cardId={cardId}
                        list={list}
                        index={index}
                      />
                    </div>
                  ))}
                </div>
                {provided.placeholder}
                {addingCard && (
                  <div ref={createCardFormRef}>
                    <CreateCardForm listId={listId} setAdding={setAddingCard} />
                  </div>
                )}
              </div>
            )}
          </Droppable>

          {/* {user && user.type == "Job-seeker" ? ( */}
          {!addingCard && user_type && user_type !== 'job_seeker' ? (
            <div className="create-card-button">
              <Button variant="contained" onClick={() => setAddingCard(true)}>
                + Add a card
              </Button>
            </div>
          ) : (
            ' '
          )}
        </div>
      )}
    </Draggable>
  );
};

List.propTypes = {
  listId: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
};

export default List;
