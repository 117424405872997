import React from 'react';
import logo from '../../../assets/images/admin/admin_image/logo.png';
import notification from '../../../assets/images/admin/admin_image/notification.png';
import transporteViewGreen from '../../../assets/images/admin/admin_image/transporte-view-green.png';
import transporteViewRed from '../../../assets/images/admin/admin_image/transporte-view-red.png';
import transporteViewYellow from '../../../assets/images/admin/admin_image/transporte-view-yellow.png';
import logout from '../../../assets/images/admin/admin_image/logout.png';
import { sessionDestroy } from '../../../helpers/projectHelper';
import { MARKETING_LOGIN } from '../../../config/constant/routePathConstant';

const Header = () => {
  return (
    <>
      <header class="docuchain-app-header">
        <a class="docuchain-header-logo" href=''>
          <img src={logo} height="75" />
        </a>
        {/* <a
          class="docuchain-sidebar-toggle"
          href="#"
          data-toggle="sidebar"
          aria-label="Hide Sidebar"
        ></a> */}

        {/* <!-- Navbar Right Menu--> */}
        <ul class="docuchain-app-nav">
          <li>
            <a
              class="app-nav__item"
              href="#"
              data-toggle="modal"
              data-target="#logout"
              data-backdrop="static"
              aria-label="Show notifications"
            >
              <img src={logout}  alt="logout" onClick={() => sessionDestroy(MARKETING_LOGIN)} />
            </a>
          </li>
        </ul>
      </header>
    </>
  );
};

export default Header;
