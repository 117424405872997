//main routes
export const WEBSITE_ROOT = 'https://virtualstaff.ph';

export const WEBSITE = '/';
export const EMPLOYER = '/employer';
export const JOB_SEEKER = '/jobseeker';
export const SUPER_ADMIN = '/super_admin';
export const CHAT = '/chat';
export const TIME_SHEET = '/tracker';
export const TASK_MANAGEMENT = '/task-management';
export const CUSTOMER_SUPPORT = '/customer-support';
export const MARKETING = '/marketing';
export const TICKETING_SYSTEM = '/ticketing-system';
export const ADMIN = '/admin';
export const ACCOUNT_VERIFY = '/account-verify';
export const TRACKER = `${JOB_SEEKER}/mytracker`;
export const TRACKEREMPLOYER = `${EMPLOYER}/mytracker`;
export const TASKEMPLOYER = `/task-management${EMPLOYER}/dashboard`;
export const TASKJOBSEEKER = `/task-management/job_seeker/dashboard`;
export const INSTALL_TRACKER = `${JOB_SEEKER}/install/tracker`
//REDIRECT
export const REDIRECT_QUERY_ROUTE = '/redirect-query';
//website
export const HOMEPAGE = '/';
export const ENTERPRISE = '/enterprise';
export const ABOUT_US = '/aboutus';
export const CONTACT_US = '/contactus';
export const PRIVACY = '/privacypolicy';
export const TERMS_AND_USE = '/termsanduse';

export const HOW_IT_WORKS_EMPLOYERS = '/howitworksemployer';
export const HOW_IT_WORKS_JOBSEEKERS = '/howitworksjobseeker';
export const ACCOUNT_ACTIVATION_FOR_JOBSEEKER = '/jobseeker-sign-up/thank-you/:user_id/:link_id';
export const ACCOUNT_ACTIVATION_FOR_EMPLOYER = '/employer-sign-up/thank-you/:user_id/:link_id';
export const SEE_ALL_SPECIALIZATION = '/see-all-specialization';
export const ACTIVATE_PASSWORD = '/activate-password/:user_id/:link_id'

//auth
export const LOGIN = '/login';
export const SIGNUP = '/signup';
export const SIGN_UP_EMPLOYER = '/signupemployer';
export const SIGN_UP_B = '/signup-b';
export const SIGN_UP_JOBSEEKER = '/signupjobseeker';
export const FORGET_PASSWORD = '/forgotpassword';
export const RESET_PASSWORD = '/resetpassword/:user_id/:link_id';
export const THANKYOU_JOBSEEKER = '/jobseeker-thank-you-1'
export const THANKYOU_EMPLOYER = '/employers-thank-you-2'
//employer
export const EMPLOYER_ENTERPRICE = `${EMPLOYER}/enterprice`;
export const EMPLOYER_DASHBOARD = `${EMPLOYER}/dashboard`;
export const EMPLOYER_APPLICANT_PROFILE = `${EMPLOYER}/applicantprofile/:id`;
export const EMPLOYER_MANAGENOTIFICATIONS = `${EMPLOYER}/manageNofications`;
export const EMPLOYER_POST_A_JOB = `${EMPLOYER}/postAJob`;
export const EMPLOYER_EDIT_A_JOB = `${EMPLOYER}/edit-job/:job_id`;
export const EMPLOYER_PROFILE = `${EMPLOYER}/profile`;
export const EMPLOYER_REVIEW_JOBS = `${EMPLOYER}/reviewjobs/:job_id`;
export const EMPLOYER_TOOLS = `${EMPLOYER}/tools`;
export const EMPLOYER_TRANSACTIONS = `${EMPLOYER}/transactions`;
export const EMPLOYER_TRANSACTIONS_RECEIPT = `${EMPLOYER}/transactions/receipt/:id`;
export const EMPLOYER_TRANSACTION_HISTORY = `${EMPLOYER}/transaction_history`;
export const EMPLOYER_SUBSCRIPTION_PRICING = `${EMPLOYER}/pricing-subscription`;
export const EMPLOYER_PRICING_PAGE = `${EMPLOYER}/pricing`;
export const EMPLOYER_PROTECTION = `${EMPLOYER}/employer-protection`;
export const EMPLOYER_STAFF_PROTECTION = `${EMPLOYER}/staff-protection`;


export const EMPLOYER_VIEW_APPLICANTS = `${EMPLOYER}/viewapplicants`;
export const EMPLOYER_VIRTUAL_STAFF_ACADEMY = `${EMPLOYER}/staffacademy`;
export const EMPLOYER_PRICING = `${EMPLOYER}/pricing`;
export const EMPLOYER_FIND_A_STAFF = `${EMPLOYER}/findastaff`;
export const EMPLOYER_JOB_LISTS = `${EMPLOYER}/joblists`;
export const EMPLOYER_BOOKMARKED = `${EMPLOYER}/bookmarked`;
export const EMPLOYER_MANAGESTAFF = `${EMPLOYER}/managestaff`;
export const EMPLOYER_MANAGE_JOBS = `${EMPLOYER}/managejobs`;
export const EMPLOYER_POSTED_JOB_LIST = `${EMPLOYER}/viewpostedjobs`;
export const EMPLOYER_APPLICANT_VIEW = `${EMPLOYER}/view-applicants-profile/:id`;
export const EMPLOYER_VIEW_ALL_APPLICANTS = `${EMPLOYER}/viewallapplicants`;
export const EMPLOYER_VIEW_PROFILE = `${EMPLOYER}/employerprofile`;
export const EMPLOYER_VIEW_JOBOFFER = `${EMPLOYER}/joboffer`;
export const EMPLOYER_SEND_JOB_OFFER = `${EMPLOYER}/send-job-offer/:id`;
export const EMPLOYER_HIRE_STAFF = `${EMPLOYER}/hire-staff/:id`;
export const EMPLOYER_PAYMENT_GATEWAY = `${EMPLOYER}/payment-gateway/:id`;
export const EMPLOYER_VIEW_INVOICE = `${EMPLOYER}/view-invoice`;
export const EMPLOYER_EDIT_JOBOFFER = `${EMPLOYER}/edit-job-offer/:id/:offer_id`;
export const EMPLOYER_HIRED_EMPLOYER_LIST = `${EMPLOYER}/my-virtual-staff-list`;
export const EMPLOYER_HIRED_STAFF = `${EMPLOYER}/hired-staff/:employer_id/:userId`;
export const EMPLOYER_TWO_STEP_VERFICATION = `${EMPLOYER}/two-step-verification`;
export const EMPLOYER_UNVERIFIED = `${EMPLOYER}/un-verified`;
export const EMPLOYER_HIRED_VIEW = `${EMPLOYER}/hired-employer-view/:project_id`;
export const EMPLOYER_INVOICE_ACTION = `${EMPLOYER_VIEW_INVOICE}/invoice-action/:id`
export const EMPLOYER_PAYMENT_METHOD = `${EMPLOYER}/change-payment-options`;
export const EMPLOYER_SUBSCRIPTION_PRICING_PAGE =  `${EMPLOYER}/subscription-pricing`;
export const SIGNED_CONTRACT =  `${EMPLOYER}/signed-contract`;
export const CONTRACT_AGREEMENT =  `${EMPLOYER}/contract-agreement/:id`;
export const PROTECTION_THANKYOU_PAGE = `${EMPLOYER}/staff-protect-active`;
export const EMPLOYER_SUBSCRIPTION_PRICING_PAGE_NEW =  `${EMPLOYER}/subscription-pricing-new`;
export const EMPLOYER_SUBSCRIPTION_PRICING__B_PAGE =  `${EMPLOYER}/subscription-pricing-b-page`;
//Link Constants
export const EMPLOYER_VIEW_HIRED_STAFF = `${EMPLOYER}/hired-staff`;
export const EMPLOYER_ACADEMY = `${EMPLOYER}/virtual-staff-academy`;
export const EMPLOYER_TRACKING_DETAILS = `${EMPLOYER}${TIME_SHEET}/home`;
export const EMPLOYER_TRACKING_WEEKLY_TABLE = `${EMPLOYER}${TIME_SHEET}/weaklytable`;
export const EMPLOYER_TRACKING_GALLERY = `${EMPLOYER}${TIME_SHEET}/gallery`;
export const EMPLOYER_TRACKING_WITH_DAY = `${EMPLOYER}${TIME_SHEET}/:dayid`;
export const EMPLOYER_SUBSCRIPTION_CONFIRMATION_MONTHLY = `${EMPLOYER}/monthly/subscription-confirmation`;
export const EMPLOYER_SUBSCRIPTION_CONFIRMATION_YEARLY = `${EMPLOYER}/yearly/subscription-confirmation`;
export const EMPLOYER_SUBSCRIPTION_CONFIRMATION_QUARTERLY = `${EMPLOYER}/quarterly/subscription-confirmation`;
export const EMPLOYER_SUBSCRIPTION_CONFIRMATION_PROMO = `${EMPLOYER}/promo/thank-you`;
export const EMPLOYER_PROMO =`${EMPLOYER}/promo`;
export const EMPLOYER_CONTRACT_DOWNLOAD = `${EMPLOYER}/nda/download/:id`
export const EMPLOYER_NEW_SUCCESS_PAGE = `${EMPLOYER}/subscription-confirmation`
export const EMPLOYER_BUSINESS_PROTECTION_PAGE = `${EMPLOYER}/business-protection-plan`
//jobseeker
export const JOBSEEKER_UNVERIFIED = `${JOB_SEEKER}/profile-incomplete`;
export const JOBSEEKER_DASHBOARD = `${JOB_SEEKER}/dashboard`;
export const JOBSEEKER_FINDJOBS = `${JOB_SEEKER}/findjobs`;
export const JOBSEEKER_ACCOUNTSECTION = `${JOB_SEEKER}/account`;
export const JOBSEEKER_TRANSACTION = `${JOB_SEEKER}/transations`;
export const JOBSEEKER_JOB_DESCRIPTION = `${JOB_SEEKER}/job-description/:_id`;
export const JOBSEEKER_USER_PROFILE_PREVIEW = `${JOB_SEEKER}/user_profile/preview`;
export const JOBSEEKER_SEE_PUBLIC_VIEW = `${JOB_SEEKER}/user_profile/see_public_view`;
export const JOBSEEKER_APPLIEDJOBS = `${JOB_SEEKER}/appliedjobs`;
export const JOBSEEKER_SAVEDJOBS = `${JOB_SEEKER}/savedjobs`;
export const JOBSEEKER_NOTIFICATION_SETTING = `${JOB_SEEKER}/settings-notification`;
export const JOBSEEKER_INVOICE = `${JOB_SEEKER}/invoice`;
export const STRIPE_ONBOARD_EXISTING_USER = `${JOB_SEEKER}/stripe-onboarding`;
export const JOBSEEKER_MESSAGE = `${JOB_SEEKER}/message`;
export const JOBSEEKER_JOBOFFER_LIST = `${JOB_SEEKER}/viewjoboffer`;
export const JOBSEEKER_VIEW_JOB_DESCRIPTION = `${JOB_SEEKER}/view-job/:_id/:_jobid`;
export const JOBSEEKER_PROFILE_LANDING = `${JOB_SEEKER}/account/profile_landing`;
export const JOBSEEKER_VIEW_ALL_SCHEDULED_EVENTS = `${JOB_SEEKER}/view/all_schedule_events`;
export const JOBSEEKER_UNSUBSCRIBE_NOTIFICATION = `${JOB_SEEKER}/unsubscribe/notification/:text/:id`;
export const JOBSEEKER_VIEW_SCHEDULED_EVENT = `${JOB_SEEKER}/view/scheduled-events`;
export const JOBSEEKER_VIEW_SCHEDULED_CLICK = `${JOB_SEEKER}/view/schedule_event/`;
export const JOBSEEKER_MY_EMPLOYER = `${JOB_SEEKER}/myemployer`;
export const JOBSEEKER_SCHEDULE_INTERVIEW = `${JOB_SEEKER}/interview-confirmation/:id`;
export const JOBSEEKER_HIRED_EMPLOYER_JOB_DETAILS_ROUTE = `${JOB_SEEKER}/employer-details/:id/`;
export const JOBSEEKER_HIRED_EMPLOYER_JOB_DETAILS = `${JOB_SEEKER}/employer-details/`;
export const JOBSEEKER_INVOICE_ACTION = `${JOB_SEEKER}/invoice-action/:id`
export const JOBSEEKER_ALL_JOBS_APPLICATIONS = `${JOB_SEEKER}/job-description`
export const JOBSEEKER_INTERVIEW_CONFRIMATION = `${JOB_SEEKER}/interview-confirmation`
export const JOBSEEKER_EARNINGS_SUMMARY = `${JOB_SEEKER}/earnings-summary`
export const JOBSEEKER_HOW_IT_WORKS = `${JOB_SEEKER}/how-it-works`
export const JOBSEEKER_BANK_VERIFY = `${JOB_SEEKER}/bank-verify`
export const NDA_PAGE = `${JOB_SEEKER}/nda`
export const NDA_AGREEMENT = `${JOB_SEEKER}/contract-agreement`
export const NDA_AGREEMENT_DETAILS = `${NDA_AGREEMENT}/:id`
export const JOBSEEKER_CONTRACT_DOWNLOAD = `${JOB_SEEKER}/nda/download/:id`


//account-section
export const ACCOUNT_GETSTARTED = `${JOBSEEKER_ACCOUNTSECTION}/getstarted`;
export const ACCOUNT_CATEGORY = `${JOBSEEKER_ACCOUNTSECTION}/category`;
export const ACCCOUNT_EXPERTISE = `${JOBSEEKER_ACCOUNTSECTION}/expertise`;
export const ACCOUNT_EXPERTISELEVEL = `${JOBSEEKER_ACCOUNTSECTION}/expertiselevel`;
export const ACCOUNT_EDUCATION = `${JOBSEEKER_ACCOUNTSECTION}/education`;
export const ACCOUNT_EMPLOYMENT = `${JOBSEEKER_ACCOUNTSECTION}/employment`;
export const ACCOUNT_LANGUAGE = `${JOBSEEKER_ACCOUNTSECTION}/language`;
export const ACCOUNT_RATE = `${JOBSEEKER_ACCOUNTSECTION}/rate`;
export const ACCOUNT_ABOUT_ME = `${JOBSEEKER_ACCOUNTSECTION}/about_me`;
export const ACCOUNT_PHOTO = `${JOBSEEKER_ACCOUNTSECTION}/profilephoto`;
export const ACCOUNT_LOCATION = `${JOBSEEKER_ACCOUNTSECTION}/location`;
export const ACCOUNT_STRIPE_ONBOARD = `${JOBSEEKER_ACCOUNTSECTION}/stripe-onboard`;
export const ACCOUNT_VERFICATION = `${JOBSEEKER_ACCOUNTSECTION}/verfication`;
export const ACCOUNT_PROFILEVISIBILITY = `${JOBSEEKER_ACCOUNTSECTION}/profilevisibility`;
export const ACCOUNT_PREVIEW = `${JOBSEEKER_ACCOUNTSECTION}/preview`;

//chat
export const CHAT_INDIVIDUAL = `${CHAT}/:chat_id`;
export const CHAT_INIT = `${CHAT}/:from_id/:to_id`;
export const CHAT_VIDEO_CALL = `${CHAT}/video/:chat_id/:from_id/:to_id`;
export const CHAT_MEET_VIDEO_CALL = `${CHAT}/meet/:from_id/:to_id`;

//super admin-sections
export const ADMIN_LOGIN = `${SUPER_ADMIN}/login`
export const SUPER_ADMIN_DASHBOARD = `${SUPER_ADMIN}/dashboard`;
export const SUPER_ADMIN_MANAGE_JOBS = `${SUPER_ADMIN}/managejobs`;
export const SUPER_ADMIN_UNVERIFIED_JOBS = `${SUPER_ADMIN}/unverifiedjobs`;
export const SUPER_ADMIN_JOB_ID = `${SUPER_ADMIN}/manage-job`
export const SUPER_ADMIN_UNVERIFIED_JOB_ID = `${SUPER_ADMIN}/unverified-job`
export const SUPER_ADMIN_MANAGE_JOB = `${SUPER_ADMIN}/manage-job/:id`;
export const SUPER_ADMIN_UNVERIFIED_JOB = `${SUPER_ADMIN}/unverified-job/:id`;
export const SUPER_ADMIN_MANAGE_JOB_SEEKER = `${SUPER_ADMIN}/manage-jobseeker/:id`;
export const SUPER_ADMIN_MANAGE_EMPLOYER = `${SUPER_ADMIN}/manage_employer/:id`;
export const SUPER_ADMIN_DELETE_JOB_SEEKER = `${SUPER_ADMIN}/delete-jobseeker/:id`;
export const SUPER_ADMIN_DELETE_EMPLOYER = `${SUPER_ADMIN}/delete_employer/:id`;
export const SUPER_ADMIN_MANAGE_CHAT = `${SUPER_ADMIN}/manage_chat`;
export const SUPER_ADMIN_MANAGE_NOTIFICATION = `${SUPER_ADMIN}/manage_notification`;
export const SUPER_ADMIN_MANAGE_REFUNDS = `${SUPER_ADMIN}/manage_refunds`;
export const SUPER_ADMIN_MANAGE_PAYMENT = `${SUPER_ADMIN}/manage_payment`;
export const SUPER_ADMIN_MANAGE_COMMISSIONS = `${SUPER_ADMIN}/manage_comissions`;
export const SUPER_ADMIN_VENDOR_PAYOUT_JOBSEEKER = `${SUPER_ADMIN}/manage_vendor_payout_jobseeker`;
export const SUPER_ADMIN_VENDOR_VERIFICATION = `${SUPER_ADMIN}/manage_vendor_verification`;
export const SUPER_ADMIN_EMPLOYER_LIST = `${SUPER_ADMIN}/employer/list`
export const SUPER_ADMIN_DELETE_EMPLOYER_LIST = `${SUPER_ADMIN}/delete-Employer/list`
export const SUPER_ADMIN_EMPLOYER_DEPOSIT = `${SUPER_ADMIN_MANAGE_EMPLOYER}/deposit`
export const SUPER_ADMIN_EMPLOYER_ID = `${SUPER_ADMIN}/manage_employer`
export const SUPER_ADMIN_DELETE_EMPLOYER_ID = `${SUPER_ADMIN}/delete_employer`
export const SUPER_ADMIN_JOB_SEEKER_LIST = `${SUPER_ADMIN}/job-seeker/list`
export const SUPER_ADMIN_DELETE_JOB_SEEKER_LIST = `${SUPER_ADMIN}/delete-jobseeker/list`
export const SUPER_ADMIN_JOB_SEEKER_ID = `${SUPER_ADMIN}/manage-jobseeker`
export const SUPER_ADMIN_DELETE_JOB_SEEKER_ID = `${SUPER_ADMIN}/delete-jobseeker`
export const SUPER_ADMIN_CATEGORY = `${SUPER_ADMIN}/manage-category`
export const SUPER_ADMIN_ADD_CATEGORY = `${SUPER_ADMIN}/manage-category/add-category`
export const SUPER_ADMIN_SKILL = `${SUPER_ADMIN}/manage-skill`
export const SUPER_ADMIN_ADD_SKILL = `${SUPER_ADMIN}/manage-skill/add-skill`
export const SUPER_ADMIN_JOB_TITLE = `${SUPER_ADMIN}/manage-job_title`
export const SUPER_ADMIN_ADD_JOB_TITLE = `${SUPER_ADMIN}/manage-skill/add-job_title`
export const SUPER_ADMIN_MANAGE_TICKET = `${SUPER_ADMIN}/manage-ticket`
export const SUPER_ADMIN_MANAGE_BONUS = `${SUPER_ADMIN}/manage-bonus`
export const EMPLOYER_HOW_IT_WORKS = '/employer/how-it-works';
export const SUPER_ADMIN_MANAGE_STAFF = `${SUPER_ADMIN}/manage-staff`
export const SUPER_ADMIN_ADD_STAFF = `${SUPER_ADMIN}/add-staff`
export const SUPER_ADMIN_JOB_SEEKER_VERIFICATION = `${SUPER_ADMIN}/jobseeker/verification`
export const SUPER_ADMIN_EMPLOYER_VERIFICATION = `${SUPER_ADMIN}/employer/verification`
export const SUPER_ADMIN_JOB_SEEKER_VERIFICATION_ID = `${SUPER_ADMIN}/jobseeker/verification/:id`;
export const SUPER_ADMIN_VERIFY_EMPLOYER_ROUTE = `${SUPER_ADMIN}/employer/verification`
export const SUPER_ADMIN_ACTIVITY_LOG_LIST_ROUTE = `${SUPER_ADMIN_DASHBOARD}/manage-activity`
export const SUPER_ADMIN_EMPLOYER_ACCEPTED_LIST_ROUTE = `${SUPER_ADMIN_DASHBOARD}/employer-accepted`
export const SUPER_ADMIN_EMPLOYER_REJECTED_LIST_ROUTE = `${SUPER_ADMIN_DASHBOARD}/employer-rejected`
export const SUPER_ADMIN_JOB_SEEKER_ACCEPTED_LIST_ROUTE = `${SUPER_ADMIN_DASHBOARD}/job_seeker-accepted`
export const SUPER_ADMIN_JOB_SEEKER_REJECTED_LIST_ROUTE = `${SUPER_ADMIN_DASHBOARD}/job_seeker-rejected`
export const SUPER_ADMIN_ANNOUNCEMENT_AND_PROMOS = `${SUPER_ADMIN}/announcement-and-promos`
export const SUPER_ADMIN_MY_ACCOUNT = `${SUPER_ADMIN}/my-profile`
export const SUPER_ADMIN_CREATE_EVENT = `${SUPER_ADMIN}/create_event`
export const SUPER_ADMIN_UPDATE_EVENT = `${SUPER_ADMIN}/update_event`
export const SUPER_ADMIN_UPDATE_EVENT_ID = `${SUPER_ADMIN}/update_event/:id`

//website
export const WEBSITE_HOMEPAGE = `${WEBSITE_ROOT}/`;
export const WEBSITE_FIND_JOBS = `${WEBSITE_ROOT}/find-jobs`;
export const WEBSITE_FIND_STAFF = `${WEBSITE_ROOT}/find-staff`;
export const WEBSITE_ENTERPRISE = `${WEBSITE_ROOT}/enterprise`;
export const WEBSITE_ABOUT_US = `${WEBSITE_ROOT}/about-us`;
export const WEBSITE_BLOG = `${WEBSITE_ROOT}/blog`;
export const WEBSITE_CONTACT_US = `${WEBSITE_ROOT}/contact-us`;
export const WEBSITE_PRIVACY = `${WEBSITE_ROOT}/privacy-policy`;
export const WEBSITE_TERMS_AND_USE = `${WEBSITE_ROOT}/terms-and-use`;
export const WEBSITE_HOW_IT_WORKS_EMPLOYERS = `${WEBSITE_ROOT}/how-it-works-employer`;
export const WEBSITE_HOW_IT_WORKS_JOB_SEEKERS = `${WEBSITE_ROOT}/how-it-works-jobseeker`;
export const WEBSITE_ACCOUNT_ACTIVATION = `${WEBSITE_ROOT}/account-activation/:user_id/:link_id`;
export const WEBSITE_SEE_ALL_SPECIALIZATION = `${WEBSITE_ROOT}/see-all-specialization`;
export const WEBSITE_VIRTUAL_STAFF_FINDER = `${WEBSITE_ROOT}/virtualStaff-finder`;
export const WEBSITE_VERIFIED_WORKERS = `${WEBSITE_ROOT}/verified-workers`;
export const WEBSITE_RELOGIN = `/re-login`;
export const WEBSITE_SUBSCRIPTION_PRICING_PAGE =  `${WEBSITE_ROOT}/subscription-pricing`;
export const WEBSITE_THANKYOU_JOBSEEKER = `${WEBSITE_ROOT}/jobseeker-thank-you-1`
export const WEBSITE_THANKYOU_EMPLOYER = `${WEBSITE_ROOT}/employers-thank-you-2`
export const WEBSITE_HELPFUL_RESOURCES = `${WEBSITE_ROOT}/helpful-resources`
//timesheet
export const TIME_SHEET_DASHBOARD = `${TIME_SHEET}/dashboard/:payment_id`;
export const TIME_SHEET_HOME = `${TIME_SHEET}/:userRole/:payment_id/home`;
export const TIME_SHEET_WEEKLY_TABLE = `${TIME_SHEET}/:userRole/:payment_id/weaklytable`;
export const TIME_SHEET_GALLERY_TABLE = `${TIME_SHEET}/:userRole/:payment_id/gallery`;
export const TIME_SHEET_WITH_DAY = `${TIME_SHEET}/:userRole/:payment_id/:dayid`;
export const TIME_SHEET_WITH_DAY_IMAGE = `${TIME_SHEET}/:userRole/:payment_id/:fromDate/:toDate`;

//task-management
export const TASK_MANAGEMENT_DASHBOARD = `${TASK_MANAGEMENT}/:userRole/dashboard`;
export const TASK_MANAGEMENT_BOARD_LIST = `${TASK_MANAGEMENT}/:userRole/board/:board_id`;


//customer_support-sections
export const CUSTOMER_SUPPORT_LOGIN = `${CUSTOMER_SUPPORT}/login`;
export const CUSTOMER_SUPPORT_DASHBOARD = `${CUSTOMER_SUPPORT}/dashboard`;
export const CUSTOMER_SUPPORT_MANAGE_JOBS = `${CUSTOMER_SUPPORT}/manage-jobs`;
export const CUSTOMER_SUPPORT_PENDING_JOB = `${CUSTOMER_SUPPORT}/pending-job`;
export const CUSTOMER_SUPPORT_MANAGE_JOBS_INFO = `${CUSTOMER_SUPPORT}/manage-jobs/:id`;
export const CUSTOMER_SUPPORT_MANAGE_EMPLOYER = `${CUSTOMER_SUPPORT}/manage_employer/:id`;
export const CUSTOMER_SUPPORT_PENDING_EMPLOYER = `${CUSTOMER_SUPPORT}/pending-employer`;
export const CUSTOMER_SUPPORT_EMPLOYER_ID = `${CUSTOMER_SUPPORT}/manage_employer`
export const CUSTOMER_SUPPORT_EMPLOYER_LIST = `${CUSTOMER_SUPPORT}/employer/list`
export const CUSTOMER_SUPPORT_MANAGE_JOB_SEEKER = `${CUSTOMER_SUPPORT}/manage_job-seeker/:id`;
export const CUSTOMER_SUPPORT_PENDING_JOB_SEEKER = `${CUSTOMER_SUPPORT}/pending-jobseeker`;
export const CUSTOMER_SUPPORT_JOB_SEEKER_ID = `${CUSTOMER_SUPPORT}/manage_job-seeker`
export const CUSTOMER_SUPPORT_JOB_SEEKER_LIST = `${CUSTOMER_SUPPORT}/job-seeker/list`
export const CUSTOMER_SUPPORT_MANAGE_CHAT = `${CUSTOMER_SUPPORT}/manage_chat`;
export const CUSTOMER_SUPPORT_MANAGE_TICKETING = `${CUSTOMER_SUPPORT}/manage_ticket`;
export const CUSTOMER_SUPPORT_FREETRAIL_USERS = `${CUSTOMER_SUPPORT}/manage_free_users`;
export const CUSTOMER_SUPPORT_SUBSCRIBED_USERS = `${CUSTOMER_SUPPORT}/subscribed-users`;
export const CUSTOMER_SUPPORT_PROTECTED_LIST = `${CUSTOMER_SUPPORT}/protected-list`;
export const CUSTOMER_SUPPORT_INDIVIDUAL_PROTECTED_LIST = `${CUSTOMER_SUPPORT}/individual-protected-list/:id`;
export const CUSTOMER_SUPPORT_CONTRACT_AGREEMENT = `${CUSTOMER_SUPPORT}/contract-agreement/:id`;
export const CUSTOMER_SUPPORT_MANAGE_USERS = `${CUSTOMER_SUPPORT}/manage-users`
export const CUSTOMER_SUPPORT_AGREEMENT_USERS = `${CUSTOMER_SUPPORT}/agreement-users`
export const CUSTOMER_SUPPORT_DELETE_USERS = `${CUSTOMER_SUPPORT}/delete-request`
export const CUSTOMER_SUPPORT_REPORTED_JOBS = `${CUSTOMER_SUPPORT}/reported-jobs`
export const CUSTOMER_SUPPORT_PENDING_REPORTED_JOBS = `${CUSTOMER_SUPPORT}/pending-reported-jobs`
export const CUSTOMER_SUPPORT_INDIVIDUAL_JOB_LIST = `${CUSTOMER_SUPPORT}/job-list/:id`
export const CUSTOMER_SUPPORT_INDIVIDUAL_JOB_APPLICANTS = `${CUSTOMER_SUPPORT}/job-applicants/:id/:job_id`
export const CUSTOMER_SUPPORT_INDIVIDUAL_OFFER_LIST = `${CUSTOMER_SUPPORT}/offer-list/:id`
export const CUSTOMER_SUPPORT_INDIVIDUAL_HIRED_LIST = `${CUSTOMER_SUPPORT}/hired-list/:id`
export const CUSTOMER_SUPPORT_STAFF_OFFER_LIST = `${CUSTOMER_SUPPORT}/staff-offer-list/:id`
export const CUSTOMER_SUPPORT_STAFF_HIRED_LIST = `${CUSTOMER_SUPPORT}/staff-hired-list/:id`
export const CUSTOMER_SUPPORT_PAYMENT_LIST = `${CUSTOMER_SUPPORT}/payment-history/:id`
export const CUSTOMER_SUPPORT_TRIAL_CHARGE_LIST = `${CUSTOMER_SUPPORT}/user-agreement-list`
// Account verifier
export const ACCOUNT_VERIFIER_LOGIN = `${ACCOUNT_VERIFY}/login`;
export const ACCOUNT_VERIFY_DASHBOARD = `${ACCOUNT_VERIFY}/dashboard`;
export const ACCOUNT_VERIFY_MANAGE_JOBS = `${ACCOUNT_VERIFY}/manage-jobs`;
export const ACCOUNT_VERIFY_EMPLOYER_LIST = `${ACCOUNT_VERIFY}/employer/list`
export const ACCOUNT_VERIFY_MANAGE_EMPLOYER_LIST = `${ACCOUNT_VERIFY}/manage_employer`;
export const ACCOUNT_VERIFY_MANAGE_EMPLOYER = `${ACCOUNT_VERIFY}/manage_employer/:id`;
export const ACCOUNT_VERIFY_MANAGE_JOBS_INFO = `${ACCOUNT_VERIFY}/manage-jobs/:id`;
export const ACCOUNT_VERIFY_JOB_SEEKER_LIST = `${ACCOUNT_VERIFY}/job-seeker/list`
export const ACCOUNT_VERIFY_MANAGE_JOB_SEEKER = `${ACCOUNT_VERIFY}/manage_job-seeker/:id`;
export const ACCOUNT_VERIFY_MANAGE_JOB_SEEKER_LIST = `${ACCOUNT_VERIFY}/manage_job-seeker`;
export const ACCOUNT_VERIFY_MANAGE_CHAT = `${ACCOUNT_VERIFY}/manage_chat`;
export const ACCOUNT_VERIFY_MANAGE_TICKETING = `${ACCOUNT_VERIFY}/manage_ticket`;
export const ACCOUNT_VERIFY_EMPLOYER_ID = `${ACCOUNT_VERIFY}/manage_employer`
export const ACCOUNT_VERIFY_JOB_SEEKER_ID = `${ACCOUNT_VERIFY}/manage_job-seeker`
export const ACCOUNT_VERIFY_MANAGE_EMPLOYER_ID = `${ACCOUNT_VERIFY}/manage_employer/`;
export const ACCOUNT_VERIFY_MANAGE_JOB_SEEKER_ID = `${ACCOUNT_VERIFY}/manage_job-seeker/`;

// Account verifier - 2
export const ACCOUNT_VERIFY_LOGIN = `${ACCOUNT_VERIFY}/login`
export const ACCOUNT_EMPLOYER_VERIFICATION = `${ACCOUNT_VERIFY}/employer-verification`
export const ACCOUNT_EMPLOYER_ACCEPTED = `${ACCOUNT_VERIFY}/employer-verification/accepted`
export const ACCOUNT_EMPLOYER_REJECTED = `${ACCOUNT_VERIFY}/employer-verification/rejected`
export const ACCOUNT_JOB_SEEKER_VERIFICATION = `${ACCOUNT_VERIFY}/job_seeker-verification`
export const ACCOUNT_JOB_SEEKER_REJECTED = `${ACCOUNT_VERIFY}/job_seeker-verification/rejected`
export const ACCOUNT_JOB_SEEKER_ACCEPTED = `${ACCOUNT_VERIFY}/job_seeker-verification/accepted`
export const ACCOUNT_PENDING_JOBS = `${ACCOUNT_VERIFY}/manage-jobs`
export const ACCOUNT_PENDING_JOB_SEEKER = `${ACCOUNT_VERIFY}/job-seeker/list`
export const ACCOUNT_PENDING_EMPLOYER = `${ACCOUNT_VERIFY}/employer/list`


// marketing 
export const MARKETING_LOGIN = `${MARKETING}/login`;
export const MARKETING_MANAGE_NOTIFICATIONS = `${MARKETING}/manage-notification`
export const MARKETING_ANNOUCEMENT = `${MARKETING}/manage-announcement`
export const MARKETING_AFFILIATE = `${MARKETING}/manage-affiliate`
export const MARKETING_AFFILIATE_SIGNUP = `${MARKETING}/manage-affiliate-referral-signups/:id`

export const MARKETING_CREATE_EVENT = `${MARKETING}/create_event`
export const MARKETING_UPDATE_EVENT = `${MARKETING}/update_event`
export const MARKETING_UPDATE_EVENT_ID = `${MARKETING}/update_event/:id`

//***************************************Link***************************

export const HELP_SUPPORT = 'https://support.virtualstaff.ph/'
export const ENTERPRISE_PAGE = 'https://www.virtualstaff.ph/enterprise'