import React,{useState,useEffect} from 'react';
import { Modal, Button } from 'react-bootstrap';
import { getLocal, getUserData } from '../../helpers/projectHelper';
import axios from 'axios';
import { empAddBonus ,addCardApi} from '../../api/employer/employer';
import { useDispatch,useSelector } from 'react-redux';
import { useSocket } from '../../helpers/hooks';
import { hireEmployeeSelector, setLoader } from '../../redux/slicers/employer/hireEmployeeSlice';
import {stripe} from "../../helpers/componentHelper";
import { FullScreenLoader } from '../common/loader';
import { Spinner } from 'react-bootstrap';
const PaymentBonus = ({ show, close,data = {},id = null,type,call,height }) => {
    const {amount= '',amount_type  = '',description = ''} = data;
  const dispatch = useDispatch();
  const token = getLocal();
  const {user_id = '',full_name = ''} = getUserData();
 
  const [loading,setLoading] = useState(false);
  const {sendNotification=null} = useSocket();
  const {paymentError = []} = useSelector(hireEmployeeSelector);
  const [cardError,setCardError] = useState(false);
  const [ele,setEle] = useState();
 
  useEffect(() => {
  
    const elements = stripe.elements();
    const creditCard =  elements.create("card", {
      style: {
        base: {
          fontSize: "18px",
        },
      },
  
    });
    
    creditCard.on("change", (event) => {
      if (event.error) {
        setCardError(event.error.message);
      } else {
        setCardError('');
      }
    });
  
     creditCard.mount(".credit-card");
     setEle(creditCard)
     
  },[])
  const handleSubmit = () => {
    setLoading(true);
    stripe.createToken(ele).then(({ error, token }) => {
      if (error) {
        setCardError(error.message);
        setLoading(false);
      } else {
        console.log(token.id);
         let data = {
           "user_obj":{
          "user_id":user_id
       },
       "payment_obj":{
          "card":token.id
       }}
       dispatch(addCardApi(data,setLoading,close,call));
       
      }
    });
  }
  const handleAddBonus = () => {
    const formObj = {
      "user_obj":{
   
         "user_id":id,
         "employer_id":user_id,
         "amount_type":data?.amount_type,
         "description":data?.description,
         "billing_date":new Date(),
         "amount":103,
         "initial_amount":100
      }
   }
  }
  
  
   
  return (
  <div>
      <Modal
        show={show}
        onHide={close}
        aria-labelledby="example-modal-sizes-title-lg"
       
     
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">{type === 'Bonus' ? "Payment Gateway" : "Add Card Details"}</Modal.Title>
        </Modal.Header>
      
        <Modal.Body style={{ fontSize: '20px' }}>
        <div className="credit-card" />  
        {cardError && <p className="card-error">{cardError}</p>}
        <button
          onClick={() => handleSubmit()}
          className="btn btn-primary btn-lg mt-4"
        >
          {loading ? <i class="fa fa-spinner fa-spin" aria-hidden="true"></i> : "Add Card" }
        </button> 
       
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default PaymentBonus;
