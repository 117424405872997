
import {
  CHAT,
  EMPLOYER,
  EMPLOYER_DASHBOARD,
  EMPLOYER_FIND_A_STAFF,
  EMPLOYER_HIRED_EMPLOYER_LIST,
  EMPLOYER_JOB_LISTS,
  EMPLOYER_MANAGENOTIFICATIONS,
  EMPLOYER_POST_A_JOB,
  EMPLOYER_TWO_STEP_VERFICATION,
  EMPLOYER_UNVERIFIED,
  EMPLOYER_VIEW_APPLICANTS,
  EMPLOYER_VIEW_JOBOFFER,
  EMPLOYER_VIEW_PROFILE,
  JOBSEEKER_APPLIEDJOBS,
  JOBSEEKER_FINDJOBS,
  JOBSEEKER_INVOICE,
  JOBSEEKER_JOBOFFER_LIST,
  JOBSEEKER_MY_EMPLOYER,
  JOBSEEKER_NOTIFICATION_SETTING,
  JOBSEEKER_SAVEDJOBS,
  JOBSEEKER_USER_PROFILE_PREVIEW,
  EMPLOYER_TRANSACTIONS,
  JOBSEEKER_ACCOUNTSECTION,
  EMPLOYER_ACADEMY,
  EMPLOYER_ENTERPRICE,
  EMPLOYER_HOW_IT_WORKS,
  JOBSEEKER_UNVERIFIED,
  JOBSEEKER_PROFILE_LANDING,
  ACCOUNT_GETSTARTED,
  ACCOUNT_CATEGORY,
  ACCCOUNT_EXPERTISE,
  ACCOUNT_EXPERTISELEVEL,
  ACCOUNT_EDUCATION,
  ACCOUNT_EMPLOYMENT,
  ACCOUNT_LANGUAGE,
  ACCOUNT_RATE,
  ACCOUNT_ABOUT_ME,
  ACCOUNT_PHOTO,
  ACCOUNT_LOCATION,
  ACCOUNT_VERFICATION,
  ACCOUNT_PREVIEW,
  EMPLOYER_APPLICANT_PROFILE,
  ACCOUNT_PROFILEVISIBILITY,
  EMPLOYER_PAYMENT_METHOD,
  EMPLOYER_REVIEW_JOBS,
  HELP_SUPPORT,
  LOGIN,
  ACCOUNT_STRIPE_ONBOARD,
  STRIPE_ONBOARD_EXISTING_USER,
  INSTALL_TRACKER,
  EMPLOYER_SUBSCRIPTION_PRICING_PAGE,
  EMPLOYER_SUBSCRIPTION_CONFIRMATION,
  EMPLOYER_VIEW_ALL_APPLICANTS,
  EMPLOYER_SUBSCRIPTION_CONFIRMATION_MONTHLY,
  EMPLOYER_SUBSCRIPTION_CONFIRMATION_YEARLY,
  EMPLOYER_SUBSCRIPTION_CONFIRMATION_PROMO,
  EMPLOYER_SUBSCRIPTION_CONFIRMATION_QUARTERLY,
  EMPLOYER_PROMO,
  EMPLOYER_SUBSCRIPTION_PRICING,
  EMPLOYER_PRICING_PAGE,
  NDA_PAGE,
  SIGNED_CONTRACT,
  EMPLOYER_SUBSCRIPTION_PRICING_PAGE_NEW,
  EMPLOYER_POSTED_JOB_LIST,
  EMPLOYER_SUBSCRIPTION_PRICING__B_PAGE,
  EMPLOYER_NEW_SUCCESS_PAGE,
  EMPLOYER_BUSINESS_PROTECTION_PAGE
} from './routePathConstant';


const stripe = localStorage.getItem('isStripeOnboarded');

export const JOBSEEKER_MENU = [
  {
    name: 'Inbox',
    path: CHAT,
    icon: 'fas fa-envelope',
  },
  {
    name: 'Search Jobs',
    icon: 'fas fa-search',
    subMenu: [
      { name: 'Find Jobs', path: JOBSEEKER_FINDJOBS },
      { name: 'Job Offers', path: JOBSEEKER_JOBOFFER_LIST },
      { name: 'Bookmarked Jobs', path: JOBSEEKER_SAVEDJOBS },
      { name: 'Job Applications', path: JOBSEEKER_APPLIEDJOBS },
    ],
  },
  {
    name: 'My Jobs & Employers',
    icon: 'fas fa-briefcase',
    subMenu: [
      { name: 'My Employers', path: JOBSEEKER_MY_EMPLOYER },
      // { name: 'My Tasks', path: TASKJOBSEEKER },
      // { name: 'Work Log', path: TRACKER, text: "(To get paid you must track the hours that you work inside Work Log.)" },
      { name: 'Salary & Payments', path: JOBSEEKER_INVOICE },
      { name: 'Contracts', path: NDA_PAGE },
      { name: 'Stripe Onboarding', path: STRIPE_ONBOARD_EXISTING_USER }

      // { name: 'My Earnings', path: JOBSEEKER_EARNINGS_SUMMARY }
    ]
    // .concat(stripe == 0 ? [{ name: 'Stripe Onboarding', path: STRIPE_ONBOARD_EXISTING_USER }]
    //     : [{ name: 'Salary & Payments', path: JOBSEEKER_INVOICE },])
  },
  {
    name: 'Secure Your Payments',
    icon: 'fas fa-briefcase',
    path: ''
  },
  {

    icon: 'fas fa-cogs',
    name: 'Settings & Others',
    subMenu: [
      { name: 'Edit Profile', path: JOBSEEKER_ACCOUNTSECTION },
      { name : 'Time Tracker', path: INSTALL_TRACKER },
      { name: 'Manage Notifications', path: JOBSEEKER_NOTIFICATION_SETTING },
      { name: 'Help & Support', path: HELP_SUPPORT },
    ],
  },
  // {
  //   icon: 'fas fa-wallet',
  //   name: 'Get Paid to Share VirtualStaff.ph'
  // },
];

export const EMPLOYER_MENU = [
  {
    name: 'FIND STAFF',
    icon: 'fas fa-users',
    subMenu: [
      { name: 'Post a Job', path: EMPLOYER_POST_A_JOB },
      { name: 'Search Jobseekers', path: EMPLOYER_FIND_A_STAFF },
      { name: 'My Job Posts', path: EMPLOYER_JOB_LISTS },
      { name: 'Bookmarked Workers', path: EMPLOYER_VIEW_APPLICANTS },
      { name: 'View Job Offers', path: EMPLOYER_VIEW_JOBOFFER },
    ],
  },
  {
    name: 'MANAGE STAFF',
    icon: 'fas fa-users-cog ',
    subMenu: [
      {
        name: 'My Staff (Hired)', path: EMPLOYER_HIRED_EMPLOYER_LIST,
        text: 'Click here so you can modify a lot of things for your staff such as:',
        list: [
          'Pay Staff', 
          'Give Bonus', 
          // 'Work Log (track time)', 
          'Salary Rate',
          // 'Task Management', 
          'Terminate  Staff'
        ],
        // endtext: "... and more"
      },
      {
        name : 'Signed Contracts' , path : SIGNED_CONTRACT
      }

    ]
  },
  {
    icon: "fas fa-file-invoice",
    name: 'PAYMENT STUFF',
    subMenu: [
      // { name: 'Salary Invoices', path: EMPLOYER_VIEW_INVOICE },
      { name: 'Transaction History', path: EMPLOYER_TRANSACTIONS },
      { name: 'Payment Method', path: EMPLOYER_PAYMENT_METHOD },

    ],
  },
  {
    icon: 'fas fa-user-circle',
    name: 'MY ACCOUNT',
    subMenu: [
      { name: 'Edit Account', path: EMPLOYER_VIEW_PROFILE },
    
      { name: 'Manage Notifications', path: EMPLOYER_MANAGENOTIFICATIONS },
      

    ],
  },
  {
    icon: 'fas fa-clipboard-list ',
    name: 'OTHER STUFF',
    subMenu: [
      { name: 'How It Works', path: EMPLOYER_HOW_IT_WORKS },
      { name: 'Enterprise Solution', path: EMPLOYER_ENTERPRICE },
      { name: 'VirtualStaff Academy', path: EMPLOYER_ACADEMY },
      { name: 'Help & Support', path: HELP_SUPPORT },
      { name: 'Helpful Resources', path: HELP_SUPPORT },
    ],
  },
  {
    icon: 'fas fa-wallet',
    name: 'Get Paid to Share VirtualStaff.ph'

  },
  {
    icon: 'fas fa-sign-out-alt ',
    name: 'LOGOUT', path: LOGIN

  }
];

export const EMPLOYER_ALLOWED_ROUTES = [
  EMPLOYER,
  EMPLOYER_POST_A_JOB,
  EMPLOYER_DASHBOARD,
  EMPLOYER_UNVERIFIED,
  EMPLOYER_VIEW_PROFILE,
  EMPLOYER_TWO_STEP_VERFICATION,
  EMPLOYER_FIND_A_STAFF,
  EMPLOYER_APPLICANT_PROFILE,
  EMPLOYER_JOB_LISTS,
  EMPLOYER_REVIEW_JOBS,
  EMPLOYER_HOW_IT_WORKS,
  EMPLOYER_ENTERPRICE,
  EMPLOYER_ACADEMY,
  HELP_SUPPORT,
  EMPLOYER_SUBSCRIPTION_PRICING_PAGE,
  EMPLOYER_SUBSCRIPTION_CONFIRMATION_MONTHLY,
  EMPLOYER_SUBSCRIPTION_CONFIRMATION_YEARLY,
  EMPLOYER_SUBSCRIPTION_CONFIRMATION_PROMO,
  EMPLOYER_VIEW_ALL_APPLICANTS,
  EMPLOYER_SUBSCRIPTION_CONFIRMATION_QUARTERLY,
  EMPLOYER_PROMO,
  EMPLOYER_SUBSCRIPTION_PRICING,
  EMPLOYER_PRICING_PAGE,
  EMPLOYER_MANAGENOTIFICATIONS,
  EMPLOYER_SUBSCRIPTION_PRICING_PAGE_NEW,
  EMPLOYER_POSTED_JOB_LIST,
  EMPLOYER_SUBSCRIPTION_PRICING__B_PAGE,
  EMPLOYER_NEW_SUCCESS_PAGE,
  EMPLOYER_VIEW_APPLICANTS,
  EMPLOYER_BUSINESS_PROTECTION_PAGE

];

export const JOBSEEKER_ALLOWED_ROUTES = [
  JOBSEEKER_USER_PROFILE_PREVIEW,
  JOBSEEKER_ACCOUNTSECTION,
  JOBSEEKER_UNVERIFIED,
  JOBSEEKER_PROFILE_LANDING,
  ACCOUNT_GETSTARTED,
  ACCOUNT_CATEGORY,
  ACCOUNT_STRIPE_ONBOARD,
  ACCCOUNT_EXPERTISE,
  ACCOUNT_EXPERTISELEVEL,
  ACCOUNT_EDUCATION,
  ACCOUNT_EMPLOYMENT,
  ACCOUNT_LANGUAGE,
  ACCOUNT_RATE,
  ACCOUNT_ABOUT_ME,
  ACCOUNT_PHOTO,
  ACCOUNT_LOCATION,
  ACCOUNT_VERFICATION,
  ACCOUNT_PREVIEW,
  ACCOUNT_PROFILEVISIBILITY,

];
