import React,{useEffect} from 'react';
import Header from './common/header';
import Footer from './common/footer';
import { RouterBuilder } from '../common/router.js';
import { EMPLOYEE_ROUTES } from '../../config/routes/employerRoutes';
import { LazyLoader, PaymentScreenLoader } from '../common/loader.js';
import { EMPLOYER, EMPLOYER_UNVERIFIED, LOGIN } from '../../config/constant/routePathConstant.js';
import { OtherSessionsChecker } from '../common/container/sessionChecker.js';
import { EMPLOYER_ALLOWED_ROUTES } from '../../config/constant/menuConstant';
import { sessionDestroy } from '../../helpers/projectHelper';
import {hireEmployeeSelector} from '../../redux/slicers/employer/hireEmployeeSlice';
import {useSelector} from "react-redux"; 
const EmployerContainer = (a) => {
       
    const {paymentLoader = false} = useSelector(hireEmployeeSelector);
   return (
     
     <OtherSessionsChecker allowedRoutes={EMPLOYER_ALLOWED_ROUTES} unverifiedPath={EMPLOYER_UNVERIFIED} sessionFailPath={LOGIN} currentRoute={EMPLOYER}>
        <Header/>
       {paymentLoader && <PaymentScreenLoader/> }
        <LazyLoader>
           <RouterBuilder data={EMPLOYEE_ROUTES}/>
        </LazyLoader>
        <Footer/>
     </OtherSessionsChecker>
   );
};

export default EmployerContainer;
