import api from '../../helpers/axiosHelper.js';
import {
  CHAT_BLOCK_API,
  CHAT_CHAT_DETAILS,
  CHAT_CHECK_EXIST_API,
  CHAT_CLEAR_API,
  CHAT_DELETE_API,
  CHAT_LIST_API,
  CHAT_MSG_LIST_API,
  CHAT_MUTE_API,
  CHAT_UNREAD_COUNT_API,
  CHAT_VIEW_API,
  JOBSEEKER_FILE_UPLOAD,
  LOGIN_API_ROOT,
  SOCKET_API_ROOT,
} from '../../config/constant/apiConstant.js';
import {
  chatListUpdate,
  chatMessageInitialLoad,
  chatMessageLoad,
  removeChat,
  removeChatMsg,
  removeCount,
  setActivityLoading,
  setChatList,
  setChatListAfter,
  setChatListInitial,
  setCurrentChat,
  setSidebarLoading,
  setUnreadCount,
} from '../../redux/slicers/socketSlicer.js';
import { devConsoleLog, errorToast, navigate, successToast } from '../../helpers/projectHelper.js';
import { CHAT } from '../../config/constant/routePathConstant.js';

export const checkChatApi = (to_id) => (dispatch) => {

  api().root(SOCKET_API_ROOT).post(CHAT_CHECK_EXIST_API).data({ to_id }).success(({ result }) => {
    const { _id: chat_id } = result;
    if (result?._id) {
      navigate({
        pathname: `${CHAT}/${result._id}`,
        state: { ...result, chat_id, man: 'sss' },
      });
    } else {
      dispatch(setCurrentChat({ ...result, chat_id, man: 'sss2' }));
    }
  }).send();
};
export const checkChatExistApi = (formData,setChatId) => (dispatch) => {
  api().root(SOCKET_API_ROOT).post(CHAT_CHECK_EXIST_API).data(formData).success(({ result }) => {
      setChatId(result?.temp_chat_id)
  }).send( (result) => {
    return result
  });
};

export const getChatListApi = (formData,replace=false,callBack) => (dispatch) => {
  dispatch(setSidebarLoading(true))
  api().root(SOCKET_API_ROOT).post(CHAT_LIST_API).data(formData).success(({ result }) => {
    if(replace){
      dispatch(setChatListInitial(result))
      return
    }
    dispatch(setChatListAfter(result));
  }).error(({message}) => {
    errorToast({title:'Refresh and try',msg:message})
  }).send((_,__,res)=>{
    dispatch(setSidebarLoading(false))
    callBack?.(res)
  });
};

export const getChatMessageListApi = (chat_id, replace=false,limit=30,skip=0) => (dispatch) => {
  api().root(SOCKET_API_ROOT).post(CHAT_MSG_LIST_API).data({ chat_id, limit: limit, skip: skip }).success(({ result }) => {
    const msgData = { chat_id, data: result };
    if (replace) {
      dispatch(chatMessageInitialLoad(msgData));
      return;
    }
    dispatch(chatMessageLoad(msgData));
  }).send();
};

export const getChatDetailsApi = (chat_id=null, callback = null) => (dispatch) => {
  if(!chat_id){
    return
  }
  api().root(SOCKET_API_ROOT).post(CHAT_CHAT_DETAILS).data({ chat_id }).success(({ result }) => {
    if (result) {
      const { _id: chat_id = null } = result;
      if (callback) {
        callback?.({ chat_id: chat_id.toString(), data: result });
        return;
      }
      dispatch(setCurrentChat({ ...result, chat_id }));
    }
  }).send();
};
//
//
export const clearMsgApi = (chat_id) => (dispatch) => {
  api().root(SOCKET_API_ROOT).post(CHAT_CLEAR_API).data({ chat_id }).success(({ result }) => {
    if (result) {
      dispatch(removeChatMsg(chat_id));
      successToast({ title: 'Success', msg: result });
    }
  }).send();
};

export const chatViewApi = (chat_id) => (dispatch) => {
  if(!chat_id){
    return
  }
  api().root(SOCKET_API_ROOT).post(CHAT_VIEW_API).data({ chat_id }).success(({ result }) => {
    if (result) {
      dispatch(removeCount(chat_id));
    }
  }).send();
};
export const getUnreadCount = () => (dispatch) => {
  api().root(SOCKET_API_ROOT).get(CHAT_UNREAD_COUNT_API).success(({ result }) => {
    if (result) {
      dispatch(setUnreadCount(result?.count));
    }
  }).send();
};

export const deleteChatApi = (chat_id, callback = null) => (dispatch) => {
  dispatch(setActivityLoading(true));
  api().root(SOCKET_API_ROOT).post(CHAT_DELETE_API).data({ chat_id }).success(({ result }) => {
    if (result) {
      successToast({ title: 'Success', msg: result });
      dispatch(removeChat(chat_id));
      callback?.();
    }
  }).error(({ result }) => {
    errorToast({ title: 'Error', msg: 'Request failure, Try again later' });
  }).send(() => {
    dispatch(setActivityLoading(false));
  });
};


export const muteChatApi = (chat_id, mute, callback = null) => (dispatch) => {
  dispatch(setActivityLoading(true));
  api().root(SOCKET_API_ROOT).post(CHAT_MUTE_API).data({ chat_id, mute }).success(({ result }) => {
    if (result) {
      successToast({ title: 'Success', msg: result });
      dispatch(getChatDetailsApi(chat_id, (a) => {
        dispatch(chatListUpdate(a));
        dispatch(setCurrentChat({ ...a.data, chat_id: a.chat_id }));
      }));
      callback?.();
    }
  }).error(({ result }) => {
    errorToast({ title: 'Error', msg: 'Request failure, Try again later' });
  }).send(() => {
    dispatch(setActivityLoading(false));
  });
};

export const blockChatApi = (chat_id, block, callback = null) => (dispatch) => {
  dispatch(setActivityLoading(true));
  api().root(SOCKET_API_ROOT).post(CHAT_BLOCK_API).data({ chat_id, block }).success(({ result }) => {
    if (result) {
      successToast({ title: 'Success', msg: result });
      dispatch(getChatDetailsApi(chat_id, (a) => {
        dispatch(chatListUpdate(a));
        dispatch(setCurrentChat({ ...a.data, chat_id: a.chat_id }));
      }));
      callback?.();
    }
  }).error(({ result }) => {
    errorToast({ title: 'Error', msg: 'Request failure, Try again later' });
  }).send(() => {
    dispatch(setActivityLoading(false));
  });
};

export const fileUploadApi = (formData, callback) => (dispatch) => {
  api().root(LOGIN_API_ROOT).post(JOBSEEKER_FILE_UPLOAD).data(formData).success(res => {
    callback?.(res);
  }).error((e) => {
    const { message = '' } = e ?? {};
    devConsoleLog(e);
    errorToast({ title: 'Failed', msg: message });
  }).upload(() => {
  });
};