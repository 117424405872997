import { lazy } from 'react';

import {
  EMPLOYER,
  EMPLOYER_APPLICANT_PROFILE,
  EMPLOYER_APPLICANT_VIEW,
  EMPLOYER_BOOKMARKED,
  EMPLOYER_DASHBOARD,
  EMPLOYER_EDIT_A_JOB,
  EMPLOYER_EDIT_JOBOFFER,
  EMPLOYER_FIND_A_STAFF,
  EMPLOYER_HIRE_STAFF,
  EMPLOYER_HIRED_EMPLOYER_LIST,
  EMPLOYER_HIRED_STAFF,
  EMPLOYER_HIRED_VIEW,
  EMPLOYER_JOB_LISTS,
  EMPLOYER_MANAGE_JOBS,
  EMPLOYER_MANAGENOTIFICATIONS,
  EMPLOYER_MANAGESTAFF,
  EMPLOYER_PAYMENT_GATEWAY,
  EMPLOYER_POST_A_JOB,
  EMPLOYER_POSTED_JOB_LIST,
  EMPLOYER_PRICING,
  EMPLOYER_PROFILE,
  EMPLOYER_REVIEW_JOBS,
  EMPLOYER_SEND_JOB_OFFER,
  EMPLOYER_TOOLS,
  EMPLOYER_TRACKING_DETAILS,
  EMPLOYER_TRACKING_GALLERY,
  EMPLOYER_TRACKING_WEEKLY_TABLE,
  EMPLOYER_TRACKING_WITH_DAY,
  EMPLOYER_TRANSACTION_HISTORY,
  EMPLOYER_TRANSACTIONS,
  EMPLOYER_TWO_STEP_VERFICATION,
  EMPLOYER_UNVERIFIED,
  EMPLOYER_VIEW_ALL_APPLICANTS,
  EMPLOYER_VIEW_APPLICANTS,
  EMPLOYER_VIEW_INVOICE,
  EMPLOYER_VIEW_JOBOFFER,
  EMPLOYER_VIEW_PROFILE,
  EMPLOYER_INVOICE_ACTION,
  EMPLOYER_ACADEMY,
  EMPLOYER_ENTERPRICE,
  TRACKEREMPLOYER,
  EMPLOYER_PAYMENT_METHOD,
  EMPLOYER_TRANSACTIONS_RECEIPT,
 EMPLOYER_SUBSCRIPTION_PRICING_PAGE,
 EMPLOYER_SUBSCRIPTION_CONFIRMATION_MONTHLY,
 EMPLOYER_SUBSCRIPTION_CONFIRMATION_YEARLY,
 EMPLOYER_PROMO,
 EMPLOYER_SUBSCRIPTION_CONFIRMATION_PROMO,
 EMPLOYER_SUBSCRIPTION_CONFIRMATION_QUARTERLY,
 EMPLOYER_SUBSCRIPTION_PRICING,
 EMPLOYER_PRICING_PAGE,
 EMPLOYER_PROTECTION,
 SIGNED_CONTRACT,
 CONTRACT_AGREEMENT,
 PROTECTION_THANKYOU_PAGE,
 EMPLOYER_CONTRACT_DOWNLOAD,
 EMPLOYER_STAFF_PROTECTION,
 EMPLOYER_SUBSCRIPTION_PRICING_PAGE_NEW,
 EMPLOYER_SUBSCRIPTION_PRICING__B_PAGE,
 EMPLOYER_NEW_SUCCESS_PAGE,
 EMPLOYER_BUSINESS_PROTECTION_PAGE
} from '../constant/routePathConstant.js';

import { RedirectToEmployerDashboard } from '../../components/employer/common/redirect.js';
import { EMPLOYER_HOW_IT_WORKS, EMPLOYER_UNLIMITED_MONTHLY, EMPLOYER_UNLIMITED_YEARLY } from '../constant/apiConstant.js';
import Pricing2 from '../../components/pricing/pricing-subscription.js';
import Pricing1 from '../../components/pricing/pricing.js';
import SignedContract from '../../components/employer/signedContract.js';

const EmployerDashboard = lazy(() => import('../../components/employer/dashboard.js'));
const UnVerified = lazy(() => import('../../components/employer/unVerified.js'));
const PostAJob = lazy(() => import('../../components/employer/postAJob/postAJob.js'));
const HiredEmployerView = lazy(() => import('../../components/employer/hiredEmployerView.js'));
const Profile = lazy(() => import('../../components/employer/profile.js'));
const Bookmarked = lazy(() => import('../../components/employer/bookmarked.js'));
const JobLists = lazy(() => import('../../components/employer/jobList.js'));
const FindAStaff = lazy(() => import('../../components/employer/findAStaff.js'));
const ReviewJobs = lazy(() => import('../../components/employer/reviewJobs.js'));
const Tools = lazy(() => import('../../components/employer/tools.js'));
const Transaction = lazy(() => import('../../components/employer/transactions.js'));

const ApplicantProfile = lazy(() => import('../../components/employer/applicantProfile.js'));
const ManageNotifications = lazy(() => import('../../components/employer/manageNotifications.js'));
const EmployerPricing = lazy(() => import('../../components/employer/employerPricing.js'));
const ViewApplicant = lazy(() => import('../../components/employer/viewApplicants.js'));
const ManageStaff = lazy(() => import('../../components/employer/manageStaff.js'));
const ManageJobs = lazy(() => import('../../components/employer/manageJobs.js'));
const ViewPostedJobList = lazy(() => import('../../components/employer/jobList'));
const ViewApplicantList = lazy(() => import('../../components/employer/viewAppliedApplicants'));
const ViewAllApplicants = lazy(() => import('../../components/employer/viewAllApplicants'));
const EmployerProfile = lazy(() => import('../../components/employer/empProfile'));
const ViewJobOffer = lazy(() => import('../../components/employer/jobOfferList'));
const SendJobOffer = lazy(() => import('../../components/employer/sendJobOffer'));
const HireStaff = lazy(() => import('../../components/employer/hireStaff'));
const PaymentGateway = lazy(() => import('../../components/employer/paymentGateway'));
const HiredStaffPage = lazy(() => import('../../components/employer/hiredStaff'));
const ViewInvoice = lazy(() => import('../../components/employer/viewInvoice'));
const InvoiceAction = lazy(() => import('../../components/employer/invoiceAction'));
const TimeSheetHome = lazy(() => import('../../components/timeSheet/trackerHome'));
const TimeSheetWeaklyTable = lazy(() => import('../../components/timeSheet/WeaklyTable'));
const Gallery = lazy(() => import('../../components/timeSheet/Gallery'));
const TimeSheetWithDay = lazy(() => import('../../components/timeSheet/TImetrackerwithday'));
const EditJobOffer = lazy(() => import('../../components/employer/editJobOffer'));
const HiredEmployerList = lazy(() => import('../../components/employer/hiredEmployerList'));
const TwoStepVerification = lazy(() => import('../../components/employer/twoStepVerification'));
const VirtualStaffAcademy = lazy(() => import('../../components/employer/virtualStaffAcademy'));
const Enterprice = lazy(() => import('../../components/employer/enterprice'));
const MyTracker = lazy(() => import('../../components/employer/tracker/tracker'));
const PaymentOptions = lazy(() => import('../../components/employer/paymentOptions'));
const HowItWorks = lazy(() => import('../../components/employer/howitworks'));
const InvoiceReciept = lazy(() => import('./../../components/employer/transaction-receipt'));
const SubscriptionPricing = lazy(() => import('../../components/employer/subscricption-pricing'));
const SubscriptionConfirmation = lazy(() => import('../../components/employer/subscriptionSuccess'));
const HaloweenPromo = lazy(() => import('../../components/employer/promoPage'));
const VirtualStaffProtection=lazy(() => import('../../components/employer/VirtualStaffProtection.js'))
const VirtualStaffProtectionLatest=lazy(() => import('../../components/employer/virtualstaffProtectionLatest.js'))
const VirtualProtection=lazy(() => import('../../components/employer/VirtualProtection.js'))
const ContractAgreement = lazy(() => import('../../components/employer/ContractAgreement'));
const ProtectionSuccess = lazy(() => import('../../components/employer/protectionThankYou'))
const ContractDownload = lazy(() => import('../../components/employer/contractDownload.js'))
const PricingNew  = lazy(() => import('../../components/employer/pricing-new'))
const PricingNewB  = lazy(() => import('../../components/employer/pricing-b'))
const  ThankYou = lazy(() => import('../../components/employer/new-success-page.js'))
const BusinessProtectionPage = lazy(() => import('../../components/employer/business-protection-plan.js'))
export const EMPLOYEE_ROUTES = [
  { component: RedirectToEmployerDashboard, path: EMPLOYER },
  { component: EmployerDashboard, path: EMPLOYER_DASHBOARD },
  { component: ApplicantProfile, path: EMPLOYER_APPLICANT_PROFILE },
  { component: ManageNotifications, path: EMPLOYER_MANAGENOTIFICATIONS },
  { component: PostAJob, path: EMPLOYER_POST_A_JOB },
  { component: PostAJob, path: EMPLOYER_EDIT_A_JOB },
  { component: Profile, path: EMPLOYER_PROFILE },
  { component: ReviewJobs, path: EMPLOYER_REVIEW_JOBS },
  { component: Tools, path: EMPLOYER_TOOLS },
  { component: Transaction, path: EMPLOYER_TRANSACTIONS },

  { component: InvoiceReciept, path: EMPLOYER_TRANSACTIONS_RECEIPT },
  { component: ViewApplicant, path: EMPLOYER_VIEW_APPLICANTS },
  // { component: EmployerPricing, path: EMPLOYER_PRICING },
  { component: FindAStaff, path: EMPLOYER_FIND_A_STAFF },
  { component: JobLists, path: EMPLOYER_JOB_LISTS },
  { component: Bookmarked, path: EMPLOYER_BOOKMARKED },
  { component: ManageStaff, path: EMPLOYER_MANAGESTAFF },
  { component: ManageJobs, path: EMPLOYER_MANAGE_JOBS },
  { component: ViewPostedJobList, path: EMPLOYER_POSTED_JOB_LIST },
  { component: ViewApplicantList, path: EMPLOYER_APPLICANT_VIEW },
  { component: ViewAllApplicants, path: EMPLOYER_VIEW_ALL_APPLICANTS },
  { component: EmployerProfile, path: EMPLOYER_VIEW_PROFILE },
  { component: ViewJobOffer, path: EMPLOYER_VIEW_JOBOFFER },
  { component: SendJobOffer, path: EMPLOYER_SEND_JOB_OFFER },
  { component: HireStaff, path: EMPLOYER_HIRE_STAFF },
  { component: PaymentGateway, path: EMPLOYER_PAYMENT_GATEWAY },
  { component: HiredStaffPage, path: EMPLOYER_HIRED_STAFF },
  { component: ViewInvoice, path: EMPLOYER_VIEW_INVOICE },
  { component: SendJobOffer, path: EMPLOYER_SEND_JOB_OFFER },
  { component: TimeSheetHome, path: EMPLOYER_TRACKING_DETAILS },
  { component: TimeSheetWeaklyTable, path: EMPLOYER_TRACKING_WEEKLY_TABLE },
  { component: Gallery, path: EMPLOYER_TRACKING_GALLERY },
  { component: TimeSheetWithDay, path: EMPLOYER_TRACKING_WITH_DAY },
  { component: EditJobOffer, path: EMPLOYER_EDIT_JOBOFFER },
  { component: HiredEmployerList, path: EMPLOYER_HIRED_EMPLOYER_LIST },
  { component: TwoStepVerification, path: EMPLOYER_TWO_STEP_VERFICATION },
  { component: UnVerified, path: EMPLOYER_UNVERIFIED },
  { component: HiredEmployerView, path: EMPLOYER_HIRED_VIEW },
  { component: InvoiceAction, path: EMPLOYER_INVOICE_ACTION },
  { component: VirtualStaffAcademy, path: EMPLOYER_ACADEMY },
  { component: Enterprice, path: EMPLOYER_ENTERPRICE },
  { component: MyTracker, path: TRACKEREMPLOYER },
  { component: PaymentOptions, path: EMPLOYER_PAYMENT_METHOD },
  { component: HowItWorks, path: EMPLOYER_HOW_IT_WORKS },
  {component:PricingNewB,path:EMPLOYER_SUBSCRIPTION_PRICING_PAGE},
  {component:SubscriptionConfirmation, path:EMPLOYER_SUBSCRIPTION_CONFIRMATION_YEARLY},
  {component:SubscriptionConfirmation, path:EMPLOYER_SUBSCRIPTION_CONFIRMATION_MONTHLY},
  {component:SubscriptionConfirmation, path:EMPLOYER_UNLIMITED_MONTHLY},
  {component:SubscriptionConfirmation, path:EMPLOYER_UNLIMITED_YEARLY},
  {component:Pricing2,path:EMPLOYER_SUBSCRIPTION_PRICING},
  {component:Pricing1,path:EMPLOYER_PRICING_PAGE},
  {component:VirtualStaffProtectionLatest,path:EMPLOYER_PROTECTION},
  {component:VirtualStaffProtectionLatest,path:EMPLOYER_STAFF_PROTECTION},
  {component:SignedContract,path:SIGNED_CONTRACT},
  {component:ContractAgreement,path:CONTRACT_AGREEMENT},
  {component:ProtectionSuccess,path:PROTECTION_THANKYOU_PAGE},
  {component:ContractDownload,path:EMPLOYER_CONTRACT_DOWNLOAD},
  {component:PricingNew,path:EMPLOYER_SUBSCRIPTION_PRICING_PAGE_NEW},
  {component:SubscriptionPricing,path:EMPLOYER_SUBSCRIPTION_PRICING__B_PAGE},
  {component:ThankYou,path:EMPLOYER_NEW_SUCCESS_PAGE},
  {component:BusinessProtectionPage,path: EMPLOYER_BUSINESS_PROTECTION_PAGE}

  // {component:HaloweenPromo, path:EMPLOYER_PROMO},
];
