import React from 'react';
import paymentIcon from '../../../assets/images/admin/icons/manage-payments.png';
import notificationIcon from '../../../assets/images/admin/icons/manage-notification.png';
import no_user from '../../../assets/images/admin/admin_image/no-user-avatar.png';
import { Link } from 'react-router-dom';
import {
  MARKETING_AFFILIATE,
  MARKETING_AFFILIATE_SIGNUP,
  MARKETING_ANNOUCEMENT, MARKETING_MANAGE_NOTIFICATIONS
} from '../../../config/constant/routePathConstant';
import { getUserData } from '../../../helpers/projectHelper';
const dashboard = '/admin/dashboard';

const SideBar = () => {
  const { full_name = null } = getUserData()
  return (
    <>
      <aside className="app-sidebar">
        <div className="app-sidebar__user">
          <div className="imgupload">
            <img style={{ backgroundColor: "White" }}
              className={`app-sidebar__user-avatar ${dashboard === window.location.pathname ? 'active' : ''
                }`}
              src={no_user}
              alt="Customer Support Image"
            />
          </div>
          <div>
            <p className="app-sidebar__user-name text-capitalize">{full_name}</p>
            <span className="app-sidebar__user-designation">Marketing</span>
          </div>
        </div>
        <ul className="app-menu">
          <li>
            <Link
              className={`app-menu__item  ${MARKETING_MANAGE_NOTIFICATIONS === window.location.pathname ? 'active' : ''
                }`}
              to={MARKETING_MANAGE_NOTIFICATIONS}
              aria-expanded="false"
              data-toggle="collapse"
            >
              <div className="imgclrs clrff8830">
                <img src={notificationIcon} alt="dashboardIcon" />
              </div>

              <span className="app-menu__label">Manage Notifications</span>
            </Link>
          </li>
          {/* <li>
            <Link
              className={`app-menu__item  ${MARKETING_ANNOUCEMENT === window.location.pathname ? 'active' : ''
                }`}
              to={MARKETING_ANNOUCEMENT}
              aria-expanded="false"
              data-toggle="collapse"
            >
              <div className="imgclrs clrff8830">
                <img src={paymentIcon} alt="dashboardIcon" />
              </div>

              <span className="app-menu__label">Announcement and Promos</span>
            </Link>
          </li> */}
          <li>
            <Link
              className={`app-menu__item  ${MARKETING_AFFILIATE === window.location.pathname ? 'active' : ''
                }`}
              to={MARKETING_AFFILIATE}
              aria-expanded="false"
              data-toggle="collapse"
            >
              <div className="imgclrs clrff8830">
                <img src={paymentIcon} alt="dashboardIcon" />
              </div>

              <span className="app-menu__label">Affiliate Users</span>
            </Link>
          </li>
          {/* <li>
            <Link
              className={`app-menu__item  ${MARKETING_AFFILIATE_SIGNUP === window.location.pathname ? 'active' : ''
                }`}
              to={MARKETING_AFFILIATE_SIGNUP}
              aria-expanded="false"
              data-toggle="collapse"
            >
              <div className="imgclrs clrff8830">
                <img src={paymentIcon} alt="dashboardIcon" />
              </div>

              <span className="app-menu__label">Affiliate referral signups</span>
            </Link>
          </li> */}
        </ul>
      </aside>
    </>
  );
};

export default SideBar;
