import React, {forwardRef, useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import Pagination from "react-js-pagination";
// import GoogleLogin from 'react-google-login';
import { GoogleLogin } from '@react-oauth/google';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {Button, Modal} from 'react-bootstrap';
import jwt_decode from "jwt-decode";
export const LinkIcon = ({ icon, to = '', text = null, className, ...props }) => (
  <Link to={to} className={className} {...props}>
    <i className={`fas ${icon}`} />
    <span>{text}</span>
  </Link>
);

export const WPagination = ({activePage,total,limit,onChange = null,className=null}) => {
  let check = Math.ceil(total/limit);
  return (
      total ? <Pagination
          prevPageText={<i className="fas fa-arrow-circle-left" style={{color:activePage === 1 && 'grey'}}/>}
          nextPageText={<i className="fas fa-arrow-circle-right" style={{color:activePage === check && 'grey'}}/>}
          firstPageText={<i className="fas fa-step-backward" style={{color:activePage === 1 && 'grey'}}/>}
          lastPageText={<i className="fas fa-step-forward" style={{color:activePage === check && 'grey'}}/>}
          innerClass={`paination_link ${className}`}
          linkClass="pagination_link_a tw-w-auto"
          activeClass="paination_active_link"
          activePage={activePage}
          itemsCountPerPage={limit}
          totalItemsCount={total}
          pageRangeDisplayed={3}
          onChange={onChange}
      /> : ''
  )
}

export const HPagination = ({activePage=0,total,limit,onChange = null,className=null}) => {
  let check = Math.ceil(total/limit);
  return (
      total ? <Pagination
          prevPageText={<i className="fas fa-arrow-circle-left" style={{color:activePage === 1 && 'grey'}}/>}
          nextPageText={<i className="fas fa-arrow-circle-right" style={{color:activePage === check && 'grey'}}/>}
          firstPageText={<i className="fas fa-step-backward" style={{color:activePage === 1 && 'grey'}}/>}
          lastPageText={<i className="fas fa-step-forward" style={{color:activePage === check && 'grey'}}/>}
          innerClass={`paination_link ${className}`}
          linkClass="pagination_link_a_a  tw-w-auto"
          activeClass="pagination_active_link"
          activePage={activePage}
          itemsCountPerPage={limit}
          totalItemsCount={total}
          pageRangeDisplayed={3}
          onChange={onChange}
      /> : ''
  )
}

export const disableLiveChat = ()=>{
  let finder = setInterval(()=>{
    if(document.querySelector('[title="Live chat button"]')){
       clearInterval(finder)
      document.querySelector('[title="Live chat button"]').classList.add('hide-element')
     }
  },10)
}
export const enableLiveChat = ()=>{
  if(document.querySelector('[title="Live chat button"]')){
    document.querySelector('[title="Live chat button"]').classList.remove('hide-element')
    }
}


// export const GoogleAuthentication = ({response , buttonText , error}) => {
//   const gapi = window.gapi
//   const clientId = "520595860570-8j9dtp7344hgtdsi7jd9oufev1gef6nr.apps.googleusercontent.com";

//   function start(){
//     gapi.client.init({
//       clientId : clientId,
//       scope : ""
//     })
//   }

//   useEffect(() => {
//     gapi.load('client:auth2',start)
//   },[gapi.load])
  
//   return (
//     <>
//      <div className="d-flex flex-column align-items-center mt-2">
    
//      <GoogleLogin
//     clientId="520595860570-8j9dtp7344hgtdsi7jd9oufev1gef6nr.apps.googleusercontent.com"
//     buttonText="Google"
//     onSuccess={response}
//     onFailure={error}
//     cookiePolicy={'single_host_origin'}
//     render={(renderProps) => (
//       // <div className="google-login-button" onClick={renderProps.onClick} disabled={renderProps.disabled}>
//       //    <div style={{background:"white",borderRadius:"50%", float:'left',padding: '8px'}}><img  src="images/login/google.png" style={{width:"23px",height:"23px"}}/></div>
        
//       //    <span >
//       //    Continue with Google
//       //   </span>
//       // </div>
//       <div className="" onClick={renderProps.onClick}>
//                  <img src="./images/login/image 1.png" alt="#" />
//                </div>
      
//     )}
//   />
//    <div className="d-flex flex-row align-items-center mb-4"><span className='left-line' style={{background:"#c4bcbc94",width:"8rem",height:"1px"}}></span>
//    <span className='logined-text'>{buttonText}</span>
//    <span className='right-line' style={{background:"#c4bcbc94",width:"8rem",height:"1px"}}></span></div>
//        </div>
//     </>
//   )
// }
export const GoogleAuthentication = ({responses , buttonText , error}) => {
    return (
      <div className="d-flex flex-column align-items-center mt-2">
      <GoogleLogin
    onSuccess={response => {
      const {credential = ""} = response;
      var decoded = jwt_decode(credential);
      responses(decoded)
    }}
  onError={error}
  render={(renderProps) => (
         
          <div className="" onClick={renderProps.onClick}>
                     <img src="./images/login/image 1.png" alt="#" />
                   </div>
          
        )}
      />
       <div className="d-flex flex-row align-items-center mb-4"><span className='left-line' style={{background:"#c4bcbc94",width:"8rem",height:"1px"}}></span>
       <span className='logined-text'>{buttonText}</span>
      <span className='right-line' style={{background:"#c4bcbc94",width:"8rem",height:"1px"}}></span></div>
       </div>
    )
}

export const  CircularProgressWithLabel = (props) => {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

export const ModalDialog = ({title="",body="",show='',button=false,buttonText="",handleSuccess=null,handleClose=null,handleShow=null,backdrop=false,keyboard=true,successText="Ok",closeText="close"})=>{
  return (
    <>
      {button && <Button variant="primary" onClick={handleShow}>
       {buttonText}
      </Button>}

      <Modal
        show={show}
        onHide={handleClose}
        backdrop={backdrop}
        keyboard={keyboard}
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {body}
        </Modal.Body>
        <Modal.Footer>
          {closeText && <Button variant="secondary" onClick={handleClose}>
            {closeText}
          </Button>}
          <Button variant="primary" onClick={handleSuccess}>{successText}</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export const CInput = forwardRef(({maskPlaceholder = null, mask = null, required = false, className = null, limit = 1000, label = null, type = 'text', errorData = [], multiline = false, name = null, defaultData = {}, onChange = null, ...props}, ref,) => {
  const [value, setValue] = useState('');
  const [errorText, setErrorText] = useState('');

  className = className === false ? '' : `form-control ${className}`;
  useEffect(() => {
      if (maskPlaceholder) {
          setValue(maskPlaceholder);
      }
  }, [maskPlaceholder]);
  useEffect(() => {
      if (defaultData?.[name]) {
          setValue(defaultData[name]);
      }
  }, [defaultData]);

  useEffect(() => {
      if (errorData?.[name]) {
          setErrorText(errorData[name]);
      } else {
          setErrorText('');
      }
  }, [errorData]);
  function currencyFormat(num) {
      return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }
  const handleChange = ({ target }) => {
      const { value: val = '' } = target;
      if (val.length <= limit) {
          setValue(type === 'number' ? parseFloat(val) : val);
          onChange?.({ name, value: type === 'number' ? val : val });
      }
  };

  return (
      <>
          {label !== false && <label>{label}</label>}
          {multiline ? (
              <textarea required={required} className={` ${className} ${errorText && 'is-invalid'}`} name={name} value={value} onChange={handleChange}{...props}/>
          ) : (
              <>
                  <input required={required} type={type} ref={ref} className={`${className} ${errorText && 'is-invalid'}`} name={name} value={value} onChange={handleChange}{...props}/>
                  {errorText && <div className="invalid-feedback">{errorText}</div>}
              </>
          )}
      </>
  );
},
);

export const RadioCheckbox = ({successValue=true,label='',name=null,className='',nameDefault='',defaultData={},onChange=null})=>{
  const [checked, setChecked] = useState(false)


  const handleChange = (e)  => {
     onChange?.({ name, value: e.target.checked });
  };

  return (
    <>
       <input id={name} className={`styled-checkbox ${className}`} type="radio" onChange={handleChange} name={nameDefault} value={successValue}/>
       <label for={name}>{label}</label>
    </>
  );
};
export const HtmlParser = (input = '') => {
  const htmlDecode = (input) => {
    var e = document.createElement('div');
    e.innerHTML = input;
    return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue;
  }
  
  const parser = new DOMParser();
  const htmlDoc = parser.parseFromString(htmlDecode(input), 'text/html');

  return htmlDoc.body.textContent.slice(0,150) 
}