import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import logo from '../../../assets/images/admin/admin_image/logo.png';
import { Link } from 'react-router-dom';
import { JOBSEEKER_DASHBOARD, EMPLOYER_DASHBOARD } from '../../../config/constant/routePathConstant';
import { useSession, useSocket } from '../../../helpers/hooks.js';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    margin: '0px auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '0px',

    '& > *': {
      margin: theme.spacing(0),
    },
    '& .MuiAppBar-colorPrimary': {
      // backgroundColor: "#004c70"
      backgroundColor: '#ffffff',
    },
    '& .MuiToolbar-regular': {
      minHeight: '80px',
      [theme.breakpoints.down("md")]: {
        minHeight: '60px',
      }
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    //   flexGrow: 1,
    fontFamily: 'Lato-bold',
    // marginottom: 15px;
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '20px',
    lineHeight: '24px',
    color: '#f4d00c',
  },
  time: {
    flexGrow: 1,
    fontWeight: 500,
    fontSize: '30px',
  },
  imgClass:{
    width:"max-content !important",
    height:"48px !important",
    [theme.breakpoints.down("md")]: {
      width:"max-content",
      height:"35px !important",
    }
  },
  headerText:{
    color: '#0093d1 !important',
    fontSize: '25px !important',
    margin: '0px 5px 0px 5px',
    fontWeight: 1000,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down("md")]: {
      fontSize: '18px !important',
    }
  }

}));

const Header = () => {
  const classes = useStyles();
  const { user_id = '',user_type='' } = useSession();

  return (
    <div className={classes.root}>
      <AppBar
     
        position="static"
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection:'row'
        }}
      >
        <div style={{marginLeft:'10px',fontSize:'10px'}}>
        <Link className="navbar-brand" to={user_type == 'job_seeker'?JOBSEEKER_DASHBOARD:EMPLOYER_DASHBOARD}> {`< Dashboard`} </Link></div>
        <Toolbar
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}
        >
          <Link className="navbar-brand" to={user_type == 'job_seeker'?JOBSEEKER_DASHBOARD:EMPLOYER_DASHBOARD}>
            <div style={{ display: 'flex', justifyContent: 'center',flexWrap:'wrap' }}>
              <img src={logo} className={classes.imgClass} />
              <p
               className={classes.headerText}>
                -
              </p>
              <p
              className={classes.headerText}>
                Work Log
              </p>
            </div>
          </Link>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Header;
