import React from 'react';
import dashboardIcon from '../../../assets/images/admin/admin_image/dashboardIcon.png';
import no_user from '../../../assets/images/admin/admin_image/no-user-avatar.png';
import branch from '../../../assets/images/admin/admin_image/branch.png';
import jobSeekerIcon from '../../../assets/images/admin/icons/manage-jobseeker.png';
import employerIcon from '../../../assets/images/admin/icons/manage-employer.png';
import jobIcon from '../../../assets/images/admin/icons/manage-job.png';
import { Link } from 'react-router-dom';
import { 
  ACCOUNT_VERIFY_MANAGE_JOBS,
  ACCOUNT_VERIFY_EMPLOYER_LIST,
  ACCOUNT_VERIFY_JOB_SEEKER_LIST,
  ACCOUNT_VERIFY_DASHBOARD,
  ACCOUNT_VERIFY_MANAGE_EMPLOYER_ID,
  ACCOUNT_VERIFY_MANAGE_JOB_SEEKER_ID,
} from '../../../config/constant/routePathConstant';
import { getUserData } from '../../../helpers/projectHelper';
const dashboard = '/admin/dashboard';

const SideBar = () => {
  const { full_name = null} = getUserData();
  return (
    <>
      <aside className="app-sidebar">
        <div className="app-sidebar__user">
          <div className="imgupload">
          <img style={{backgroundColor: "White"}}
              className={`app-sidebar__user-avatar ${
                dashboard === window.location.pathname ? 'active' : ''
              }`}
              src={no_user}
              alt="Customer Support Image"
            />
          </div>
          <div>
            <p className="app-sidebar__user-name text-capitalize">{full_name}</p>
            <span className="app-sidebar__user-designation">Account Verifier</span>
          </div>
        </div>
        <ul className="app-menu">
          <li>
            <Link
              className={`app-menu__item  ${
                ACCOUNT_VERIFY_DASHBOARD === window.location.pathname ? 'active' : ''
              }`}
              to={ACCOUNT_VERIFY_DASHBOARD}
            >
              <div className="imgclrs clr972323">
                <img src={dashboardIcon} alt="dashboardIcon" />
              </div>
              <span className="app-menu__label">Dashboard</span>
            </Link>
          </li>

          <li>
            <Link
              className={`app-menu__item  ${
                ACCOUNT_VERIFY_MANAGE_JOBS === window.location.pathname ? 'active' : ''
              }`}
              to={ACCOUNT_VERIFY_MANAGE_JOBS}
              aria-expanded="false"
              data-toggle="collapse"
            >
              <div className="imgclrs clrff8830">
                <img src={jobIcon} alt="dashboardIcon" />
              </div>

              <span className="app-menu__label">Pending Jobs</span>
            </Link>
            <ul className="appmenu collapse" id="mastersubmenu">
              <li>
                <a className="app-menu__item" href="branch-master.html">
                  <div className="imgclrs clrff8830">
                    <img src={branch} alt="branchIcon" />
                  </div>

                  <span className="app-menu__label">Delete job post</span>
                </a>
              </li>
            </ul>
          </li>
          <li>
            <Link
              className={`app-menu__item  ${
                ACCOUNT_VERIFY_EMPLOYER_LIST === window.location.pathname ||
                ACCOUNT_VERIFY_MANAGE_EMPLOYER_ID === window.location.pathname.substring(0, 34)? 'active' : ''
              }`}
              to={ACCOUNT_VERIFY_EMPLOYER_LIST}
              aria-expanded="false"
              data-toggle="collapse"
            >
              <div className="imgclrs clrff8830">
                <img src={employerIcon} alt="dashboardIcon" />
              </div>

              <span className="app-menu__label">Verify Pending Employers</span>
            </Link>
            <ul className="appmenu collapse" id="transubmenu">
              <li>
                <a className="app-menu__item " href="cir-report.html">
                  <div className="imgclrs clrff8830">
                    <img src={branch} alt="branchIcon" />
                  </div>

                  <span className="app-menu__label">Ban user</span>
                </a>
              </li>
              <li>
                <a className="app-menu__item " href="ecy-report.html">
                  <div className="imgclrs clrff8830">
                    <img src={branch} alt="branchIcon" />
                  </div>

                  <span className="app-menu__label">Change email</span>
                </a>
              </li>
              <li>
                <a className="app-menu__item " href="ecy-report.html">
                  <div className="imgclrs clrff8830">
                    <img src={branch} alt="brbranchIcon" />
                  </div>

                  <span className="app-menu__label">Change password</span>
                </a>
              </li>
            </ul>
          </li>

          <li>
            <Link
              className={`app-menu__item  ${
                ACCOUNT_VERIFY_JOB_SEEKER_LIST === window.location.pathname ||
                ACCOUNT_VERIFY_MANAGE_JOB_SEEKER_ID === window.location.pathname.substring(0,36) 
                ? 'active' : ''
              }`}
              to={ACCOUNT_VERIFY_JOB_SEEKER_LIST}
            >
              <div className="imgclrs clr972323">
                <img src={jobSeekerIcon} alt="dashboardIcon" />
              </div>
              <span className="app-menu__label">Verify Pending Jobseeker</span>
            </Link>
          </li>

        
        </ul>
      </aside>
    </>
  );
};

export default SideBar;
