import { createSlice } from '@reduxjs/toolkit';

const stripeOnboardSlice = createSlice({
  name: 'stripe_onboard',
  initialState: {
    stripeOnboardLink: {},
    stripeOnboardStatus: {},
    stripeBalance: {},
    stripeDashboardLink : {},
    stripeOnboardLinkExistingUser: {},
    stripePageToggle: {}
  },
  reducers: {
    getStripeOnboardLink: (state, { payload }) => {
      state.stripeOnboardLink = payload;
    },
    getStripeOnboardStatus: (state, { payload }) => {
      state.stripeOnboardStatus = payload;
    },
    getStripeBalance: (state, { payload }) => {
      state.stripeBalance = payload
    },
    getStripeDashboardLink: (state, { payload }) =>{
      state.stripeDashboardLink = payload
    },
    getStripeOnboardLinkExistingUser: (state, {payload}) =>{
      state.stripeOnboardLinkExistingUser = payload;
    },

    getStripePageToggle:(state, {payload}) =>{
      state.stripePageToggle = payload
    }


  }
});

export const {
  getStripeOnboardLink,
  getStripeOnboardStatus,
  getStripeBalance,
  getStripeDashboardLink,
  getStripeOnboardLinkExistingUser,
  getStripePageToggle
} = stripeOnboardSlice.actions;

export const stripeOnboardSelector = (state) => state.stripeOnboardReducer;
const stripeOnboardReducer = stripeOnboardSlice.reducer;
export default stripeOnboardReducer;
