import React, { Suspense } from 'react';
import { LOGO_IMG } from '../../config/constant/imageConstant.js';
import ReactLoading from 'react-loading';
import { THEME_DARK_BLUE } from '../../config/constant/assetConstant.js';
import {  useSelector } from 'react-redux';
import { hireEmployeeSelector } from '../../redux/slicers/employer/hireEmployeeSlice';
import { jobSeekerSelector } from '../../redux/slicers/jobSeekerSlice.js';
export const LazyLoader = ({ children }) => {
   return (
     <Suspense fallback={<FullScreenLoader/>}>{children}</Suspense>
   );
};

export const FullScreenLoader = ({ children }) => {
   return (
     <div className="centerElem"style={{zIndex:"200"}} >
        <div className="centerElemOverlay" style={{zIndex:"500"}}/>
        <div className="visibleBox"style={{zIndex:"1000"}}>
           <img src={LOGO_IMG} alt="logo"/>
           <ReactLoading type={'spinningBubbles'} color={THEME_DARK_BLUE} height={'20%'} width={'20%'} />
        </div>
     </div>
   )
}
export const PaymentScreenLoader = ({children}) => {
   const {loaderMessage = ''} = useSelector(hireEmployeeSelector)
   return (
      <div className="centerElem" style={{zIndex:"1000"}}>
      <div className="centerElemOverlay" style={{zIndex:"1000"}}/>
      <div className="visibleBox" style={{zIndex:"1000"}}>
         <img src={LOGO_IMG} alt="logo"/>
         <h1>{loaderMessage}</h1>
         <ReactLoading type={'spinningBubbles'} color={THEME_DARK_BLUE} height={'20%'} width={'20%'} />
      </div>
   </div>
   )
}
export const ProcessScreenLoader = ({children}) => {
   const {paymentMessage = ''} = useSelector(jobSeekerSelector)
   return (
      <div className="centerElem" style={{zIndex:"1000"}}>
      <div className="centerElemOverlay" style={{zIndex:"1000"}}/>
      <div className="visibleBox" style={{zIndex:"1000"}}>
         <img src={LOGO_IMG} alt="logo"/>
         <h1>{paymentMessage}</h1>
         <ReactLoading type={'spinningBubbles'} color={THEME_DARK_BLUE} height={'20%'} width={'20%'} />
      </div>
   </div>
   )
}

export const FullSectionLoader = ({ children }) => {
   return (
     <div className="flex flex-column justify-content-center align-items-center flex-grow-1">
        <div className="flex flex-1 bg-light"/>
        <ReactLoading type={'bars'}  color={THEME_DARK_BLUE} height={'7%'} width={'7%'} />
     </div>
   )
}

