import React from 'react';
import CUSTOMER_SUPPORT_ROUTES from '../../config/routes/customerSupport';
import { RouterBuilder } from '../common/router';
import { LazyLoader } from '../common/loader.js';
import Header from './common/header';
import SideBar from './common/CsSideBar';
import '../../assets/css/admin.scss';
import '../../assets/css/login.scss';
import { useRouteMatch } from 'react-router-dom';
import {  CUSTOMER_SUPPORT, ADMIN_LOGIN } from '../../config/constant/routePathConstant';
import { OtherSessionsChecker } from '../common/container/sessionChecker';

const AdminContainer = () => {
  const isLogin = useRouteMatch(ADMIN_LOGIN);
  return (
    <div className="v-staff-admin-container">
      <OtherSessionsChecker sessionFailPath={ADMIN_LOGIN} currentRoute={CUSTOMER_SUPPORT}>
        {''}{' '}
      </OtherSessionsChecker>
      {!isLogin && (
        <>
          <Header />
          <SideBar />
        </>
      )}
      <LazyLoader>
        <RouterBuilder data={CUSTOMER_SUPPORT_ROUTES} />
      </LazyLoader>
    </div>
  );
};

export default AdminContainer;
