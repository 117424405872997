import { Input } from '../common/input.js';
import { BLOCK_USER, MALE_CHAT_IMG } from '../../config/constant/imageConstant.js';
import React, { useEffect, useRef, useState } from 'react';
import { checkChatApi, getChatListApi } from '../../api/chat/chatApi.js';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useChatMessageList, useSession } from '../../helpers/hooks.js';
import { setCurrentChat, socketSelector, setHiredStaff, setChatListInitial } from '../../redux/slicers/socketSlicer.js';
import { CHAT } from '../../config/constant/routePathConstant.js';
import { EMPLOYER_ROLE } from '../../config/constant/projectConstant.js';
import { objectFind, strReduce } from '../../helpers/jsHelper.js';
import { ImageLoader } from '../../helpers/projectHelper.js';
import { CircularProgress } from '@material-ui/core';


const ChatSidebar = () => {
   const { chat_id = null, from_id = null, to_id = null } = useParams()
   const [limit, setLimit] = useState()
   const [index, setIndex] = useState(0);
   const history = useHistory()
   const { user_id = '', user_type = '' } = useSession();
   let { chatList = [], currentChat = {}, sidebarLoading } = useSelector(socketSelector);
   chatList = chatList.filter((a, i) => i == chatList.findIndex(k => k._id.toString() == a._id.toString()))
   const [loading, setLoading] = useState({ hiredLoading: false, inboxLoading: false })
   const isEmployer = user_type === EMPLOYER_ROLE
   const hiredChatList = chatList.filter(a => a?.hired);
   const inboxChatList = chatList.filter(a => !a?.hired);
   const dispatch = useDispatch();
   const ref = useRef()
   const chatSideBarRef = useRef()
   const [inboxSkip, setInboxSkip] = useState(0)
   const [search, setSearch] = useState('')
   const [hiredSkip, setHiredSkip] = useState(0)
   const timer = useRef(null)
   let requested = false
   useEffect(() => {
      if (chatSideBarRef.current) {
         const chatListWindow = chatSideBarRef.current.offsetHeight - 150;
         const limits = Math.ceil(chatListWindow / 70) + 5;
         dispatch(setChatListInitial([]))
         setLimit(limits)
         getChatList({ limit: limits })
         getChatList({ limit: limits, hired: true })
      }
      return () => {
         dispatch(setChatListInitial([]))
      }
   }, [])

   useEffect(() => {
      if (currentChat?.hired) {
         setIndex(1)
      }
   }, [currentChat])

   const getChatList = ({ limit, skip = 0, hired = false, search,forceHire=null }, replace = false) => {
      if (index == 1) {
         setHiredSkip(skip)
      } else {
         setInboxSkip(skip)
      }
      const hiredData = forceHire != null ? forceHire : (hired ? hired : index === 1)
      const formData = {
         user_id,
         skip,
         limit,
         hired: hiredData,
         search
      }
      if (limit) {
         setLoading(pre => {
            return hiredData ? { ...pre, hiredLoading: true } : { ...pre, inboxLoading: true }
         })
         dispatch(getChatListApi(formData, replace, () => {
            setLoading(pre => {
               return hiredData ? { ...pre, hiredLoading: false } : { ...pre, inboxLoading: false }
            })
         }));
      }
   }

   useEffect(() => {
      if (chat_id) {
         const chatData = objectFind(chatList, '_id', chat_id);
         if (chatData) {
            setIndex(chatData?.hired ? 1 : 0)
         }
      }
   }, [chat_id])

   useEffect(() => {
      if (from_id && to_id) {
         const currentChat = chatList.find(({ members }) => members.indexOf(from_id) > -1 && members.indexOf(to_id) > -1);
         if (currentChat) {
            const { _id } = currentChat;
            dispatch(setCurrentChat(currentChat))
            history.push(`${CHAT}/${_id}`);
            return
         }
         dispatch(checkChatApi(to_id))
      }
   }, [from_id, to_id]);

   const handleTab = (ind) => () => {
      setIndex(ind)
      if (ind === 1) {
         dispatch(setHiredStaff(true))
      } else {
         dispatch(setHiredStaff(false))
      }
   }

   function handleFilter({ target: { value } }) {
      if (timer.current) {
         clearTimeout(timer.current)
      }
      timer.current = setTimeout(() => {
         dispatch(setChatListInitial([]))
         getChatList({
            limit,
            search: value,
            forceHire:true
         },true)
         getChatList({
            limit,
            search: value,
            forceHire:false
         })
      }, 1000)
   }


   const handleScroll = () => {
      if ((loading.hiredLoading && index == 1) ||
         (loading.inboxLoading && index == 0) ||
         (index == 1 && hiredChatList.length < limit) ||
         (index == 0 && inboxChatList.length < limit)) {
         return
      }
      const { clientHeight, scrollHeight, scrollTop } = ref.current
      if (clientHeight + scrollTop >= scrollHeight) {
         const skipCurrent = index == 1 ? hiredSkip : inboxSkip
         const skipValue = skipCurrent + limit
         getChatList({ limit, skip: skipValue })
      }
   }


   return (
      <div ref={chatSideBarRef} className='chat-sidebar flex-grow-2'>
         <div className="messagecard1 patient-details-left-card">
            <div className="messagesearch-section flex justify-content-between">
               <div>
                  <span className="flex text-white bg-info iconButton p-3 fas fa-2x fa-arrow-left" onClick={() => history.push('/')} />
               </div>
               <div className="w-100 ml-3">
                  <Input label={false} className={false} onKeyUp={handleFilter} name="search" placeholder="Search" />
               </div>
            </div>
            {sidebarLoading && chatList.length === 0 ? <div className="flex h-75 justify-content-center align-items-center flex-column">
               <CircularProgress />
            </div> : <><div className="flex flex-row chat-tabs justify-content-around align-items-center">
               <div onClick={handleTab(0)} className={`w-50 tab pointer flex flex-row justify-content-center align-items-center p-2 py-3 ${index === 0 ? 'active' : ''}`}>
                  <i className="fa fa-inbox" /> &nbsp;&nbsp;Applications
               </div>
               <div onClick={handleTab(1)} className={`w-50 tab pointer flex flex-row justify-content-center align-items-center p-2 py-3 ${index === 1 ? 'active' : ''}`}>
                  <i className="fa fa-user" /> &nbsp;&nbsp;{isEmployer ? 'My Staff' : 'My Employers'}
               </div>
            </div>
               <div className="chat-list" ref={ref} onScroll={handleScroll}>
                  {index === 1 && <>
                     {hiredChatList && hiredChatList.map(a => <ChatListCard active={chat_id === a._id} key={a._id} chat_id={a._id} to_details={a.to_details} subtitle={a?.recent_msg} hired={true} {...a.to_details} all={a} />)}
                     {loading.hiredLoading && <div className="center-loading-chat-list">
                        <CircularProgress className="chat-list-load" size={40} color="primary" />
                     </div>}
                  </>}
                  {index === 0 && <>
                     {inboxChatList && inboxChatList.map(a => <ChatListCard active={chat_id === a._id} key={a._id} chat_id={a._id} to_details={a.to_details} subtitle={a?.recent_msg} hired={false} {...a.to_details} all={a} />)}</>}
                  {loading.inboxLoading && <div className="center-loading-chat-list">
                     <CircularProgress className="chat-list-load" size={40} color="primary" />
                  </div>}
               </div></>}
         </div>
      </div>
   )
}

const ChatListCard = ({ img = MALE_CHAT_IMG, onClick = null, to_details = {}, chat_id = null, full_name: title = '', subtitle = "", active = false, hired, all = {}, ...props }) => {
   const { data = [] } = useChatMessageList(chat_id)
   const { user_id } = useSession()
   const history = useHistory();
   const handleClick = () => {
      onClick?.(chat_id)
      history.push({
         pathname: `${CHAT}/${chat_id}`,
         state: {
            chat_id,
            to_details,
            hired
         }
      })
   }
   const count = all?.msg_count ?? 0
   const isBlocked = props.blocked && props.blocked.length > 0;
   const isMuted = props.muted && props.muted.indexOf(user_id) > -1;

   if (data.length > 0) {
      subtitle = strReduce(subtitle, 50);
   }

   return chat_id && <div onClick={handleClick} className={`messagecard1-leftrowdetails flex flex-row justify-content-between align-items-center ${count !== 0 ? 'new-message' : ''} ${active ? 'messagecard1-leftrow-active' : ''} ${isBlocked ? "blocked" : ""}`}>
      <div className={"flex"}>
         <div className="messagecard1-leftrowdetails-img">
            <img alt="user img rounded-circle" src={isBlocked ? BLOCK_USER : all?.to_details?.user_img_id ? ImageLoader(all.to_details.user_img_id) : img} />
            {isMuted && !isBlocked && <i className={'fa fa-bell-slash mute-bell fa-sm'} />}
         </div>
         <div className="messagecard1-leftrowdetails-text align-items-start justify-content-around  flex flex-column flex-grow">
            <h4 className="title">{title}</h4>
            <p>{subtitle}</p>
         </div>
      </div>
      {count !== 0 && <div className="badge msg-count-badge">{count}</div>}
   </div>
}

export default ChatSidebar
