import React from 'react';
import { RouterBuilder } from '../common/router.js';
import TASK_MANAGEMENT_ROUTES from '../../config/routes/taskmanagementRoutes';
import { LazyLoader } from '../common/loader.js';
import Header from './common/Header';
import Footer from './common/footer';
import '../../assets/css/task-index.scss';
import { Provider } from 'react-redux';
import store from './store';
import FooterEmployer from './common/footer-employer';
import { getUserData } from '../../helpers/projectHelper';

const TaskManagementContainer = () => {
  const { user_type } = getUserData();
  return (
    <Provider store={store}>
      <div className={'task-container-sass'}>
        {/* <OtherSessionsChecker
          onlyToken
          sessionFailPath={LOGIN}
          // currentRoute={TASK_MANAGEMENT_ROUTES}
        > */}
        <Header />
        <LazyLoader>
          <RouterBuilder data={TASK_MANAGEMENT_ROUTES} />
        </LazyLoader>
        {user_type == 'job_seeker' ? <Footer /> : <FooterEmployer />}
        {/* </OtherSessionsChecker> */}
      </div>
    </Provider>
  );
};

export default TaskManagementContainer;
