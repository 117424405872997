import Header from './common/header';
import Footer from './common/footer';
import { RouterBuilder } from '../common/router.js';
import { JOBSEEKER_ROUTES } from '../../config/routes/jobSeekerRoutes.js';
import { FullScreenLoader, LazyLoader, ProcessScreenLoader } from '../common/loader.js';
import React, { useEffect, useState } from 'react';
import '../../assets/css/jobseeker.scss';
import { OtherSessionsChecker } from '../common/container/sessionChecker.js';
import { CHAT, JOBSEEKER_UNVERIFIED, JOB_SEEKER, LOGIN } from '../../config/constant/routePathConstant.js';
import { dummyQueryUser, infoToast } from '../../helpers/projectHelper';
import { useSocket } from '../../helpers/hooks';
import { setSocketNotification } from '../../redux/slicers/socketSlicer';
import { useDispatch, useSelector } from 'react-redux';
import { getSettingsData, jobSeekerSelector } from '../../redux/slicers/jobSeekerSlice';
import { JOBSEEKER_ALLOWED_ROUTES } from '../../config/constant/menuConstant';
import { profileListapi } from '../../api/jobSeeker/jobSeeker';
import 'react-loading-skeleton/dist/skeleton.css'
import ContractBanner from './common/contractBanner';
const JobSeekerContainer = (a) => {
   const { notificationData, profileList = {}, processLoader = false,protectedPendingSign=[] } = useSelector(jobSeekerSelector);
   const { jobOfferCount = 0 } = notificationData;
   const { notification = {} } = useSocket();
   const state = profileList && profileList?.is_profile_complete;
   const [success, setSuccess] = useState(false);
   const dispatch = useDispatch();
   const [data, setData] = useState();
   useEffect(() => {
      setSuccess(true)
      dispatch(profileListapi(dummyQueryUser(), setData, setSuccess));
   }, [])

   useEffect(() => {
      if (notification.type === 'notify') {
         dispatch(getSettingsData({ ...notificationData, jobOfferCount: jobOfferCount + notification.count }))
         if (notification?.msg) {
            infoToast({ title: 'notification', msg: notification.msg })
         }
         dispatch(setSocketNotification({}))
      }
   }, [notification])


   useEffect(() => {
      document.querySelector('html').classList.add('jobseeker');
      return () => {
         document.querySelector('html').classList.remove('jobseeker');
      };
   }, []);


   return (
      !success ? <OtherSessionsChecker checkCondition={data} allowedRoutes={JOBSEEKER_ALLOWED_ROUTES} unverifiedPath={JOBSEEKER_UNVERIFIED} sessionFailPath={LOGIN} currentRoute={JOB_SEEKER}>
         {protectedPendingSign?.length > 0&&<ContractBanner protectedPendingSign={protectedPendingSign}/>}
         <Header />
         {processLoader && <ProcessScreenLoader />}
         <LazyLoader>
            <RouterBuilder data={JOBSEEKER_ROUTES} />
         </LazyLoader>
         <Footer />
      </OtherSessionsChecker> : <FullScreenLoader />
   );
};

export default JobSeekerContainer;
