import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { deleteCard } from '../../actions/board';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import { getUserData } from '../../../../helpers/projectHelper';
import { useParams } from 'react-router-dom';

const DeleteCard = ({ cardId, setOpen, list }) => {
  const { board_id } = useParams();
  const [openDialog, setOpenDialog] = useState(false);
  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const onDeleteCard = async () => {
    let data = {
      id: cardId,
      listId: list._id,
      user_id: getUserData().user_id,
      boardId: board_id,
    };
    dispatch(deleteCard(data));
    setOpenDialog(false);
    setOpen(false);
  };

  return (
    <div>
      <Button variant="contained" color="secondary" onClick={handleClickOpen}>
      Close this ticket
      </Button>
      <Dialog open={openDialog} onClose={handleClose}>
        <DialogTitle style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <span>{'Are you sure to Delete card?'}</span>
          {/* <Button style={{ borderRadius: "10px", pading: "0px" }} onClick={handleClose}>
            <CloseIcon />
          </Button> */}
        </DialogTitle>

        <DialogActions style={{ display: 'flex', flexDirection: 'row', justifyContent: "center", alignItems: 'center' }}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "center", alignItems: 'center' }}>
            <Button style={{ margin: "0px 10px" }} onClick={handleClose}>
              Cancel
            </Button>
            <Button onClick={onDeleteCard} variant="contained" color="secondary" autoFocus>
              Delete
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

DeleteCard.propTypes = {
  cardId: PropTypes.string.isRequired,
  setOpen: PropTypes.func.isRequired,
  list: PropTypes.object.isRequired,
};

export default DeleteCard;
