import { combineReducers, configureStore } from '@reduxjs/toolkit';
import sessionReducer from './slicers/sessionSlice.js';
import activityReducer from './slicers/activitySlice.js';
import jobSeekerReducer from './slicers/jobSeekerSlice.js';
import employerDashboardReducer from './slicers/employer/empDashboardSlice.js';
import postJobReducer from './slicers/employer/postJobSlice.js';
import socketReducer from './slicers/socketSlicer.js';
import hireEmployeeReducer from './slicers/employer/hireEmployeeSlice';
import adminDashboardReducer from './slicers/adminSlice/adminDashboardSlice';
import customerSupportReducer from './slicers/customerSupportSlice/customerSupportSlice';
import ticketingSystemReducer from './slicers/ticketingSystemSlice.js';
import stripeOnboardReducer from './slicers/stripeOnboardSlice.js';

const rootReducer = combineReducers({
  activity: activityReducer,
  session: sessionReducer,
  jobSeeker: jobSeekerReducer,
  employerDashboard: employerDashboardReducer,
  postJob: postJobReducer,
  socket: socketReducer,
  hireEmployee: hireEmployeeReducer,
  adminDashboard: adminDashboardReducer,
  customerSupport: customerSupportReducer,
  ticketingSystem: ticketingSystemReducer,
  stripeOnboardReducer: stripeOnboardReducer
});

const store = configureStore({ reducer: rootReducer });
export default store;
