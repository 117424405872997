import { useLocation, useParams, useRouteMatch } from 'react-router-dom';
import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../../assets/css/chat.css';
import ChatSidebar from './chatSidebar.js';
import ChatProfile from './chatProfile.js';
import ChatContent from './chatContent/chatContent.js';
import {
  chatListAdd,
  chatListUpdate,
  chatMessageStore,
  setCurrentChat,
  setSocketNotification,
  socketSelector,
} from '../../redux/slicers/socketSlicer.js';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import { FullSectionLoader } from '../common/loader.js';
import { CHAT_INDIVIDUAL, CHAT_INIT } from '../../config/constant/routePathConstant.js';
import { getChatDetailsApi, getChatMessageListApi } from '../../api/chat/chatApi.js';
import { useSocket } from '../../helpers/hooks';
import { objectFindCheck, strReduce } from '../../helpers/jsHelper';
import { disableLiveChat, enableLiveChat } from '../common/basic';
import { CircularProgress } from '@material-ui/core';

const ChatBox = () => {
  const chatSideBarRef = useRef();
  const { notification = {}, receivedMsg = {} } = useSocket();
  const {
    chatProfile = false,
    currentChat = {},
    chatList = {},
    preventApiRenders = false,
    connection = true,
  } = useSelector(socketSelector);
  const [internetConn, setInterConn] = useState(true);
  const [connectionAlert, setConnectionAlert] = useState(false);
  const matchNew = useRouteMatch(CHAT_INIT);
  const directChat = useRouteMatch(CHAT_INDIVIDUAL);
  const { state = {} } = useLocation();
  const dispatch = useDispatch();
  const { chat_id } = useParams();
  const [skipLimits, setSkipLimits] = useState(0);

  useEffect(() => {
    let timer = setInterval(() => {
      if (navigator.onLine && !internetConn) {
        setInterConn(true);
      } else if (!navigator.onLine && internetConn) {
        setInterConn(false);
      }
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [navigator.onLine]);

  let timeOut = null;
  useEffect(() => {
    if (!connection) {
      timeOut = setTimeout(() => {
        setConnectionAlert(true);
      }, 10000);
    }
    if (connection) {
      if (timeOut) {
        clearTimeout(timeOut);
      }
      setConnectionAlert(false);
    }
    return () => {
      if (timeOut) {
        clearTimeout(timeOut);
        setConnectionAlert(false);
      }
    };
  }, [connection]);

  const chatStatusUpdate = (notification_chat_id) => (a) => {
    dispatch(setSocketNotification({}));
    if (chat_id === notification_chat_id) {
      dispatch(setCurrentChat({ ...a.data, chat_id: a.chat_id }));
    }
    dispatch(chatListUpdate(a));
  };

  useEffect(() => {
    if (notification.type === 'block') {
      dispatch(getChatDetailsApi(notification.chat_id, chatStatusUpdate(notification.chat_id)));
    }
  }, [notification]);

  useEffect(() => {
    disableLiveChat();
    window.history.replaceState({}, document.title);
    return () => {
      window.history.replaceState({}, document.title);
      enableLiveChat();
    };
  }, []);

  const updateChatLimit = (replace = false, skipInitial = null) => {
    if (chat_id && directChat) {
      let limit = 30;
      let skip = skipInitial !== null ? skipInitial : skipLimits;
      dispatch(getChatMessageListApi(chat_id, replace, limit, skip));
      setSkipLimits(skipLimits + 30);
    }
  };

  useEffect(() => {
    window.onpopstate = (e) => {
      dispatch(setCurrentChat({}));
    };
  }, []);
  useEffect(() => {
    if(chat_id === 'welcome'){
      return
    }
    if (state?.to_details) {
      dispatch(setCurrentChat(state));
    } else {
      dispatch(getChatDetailsApi(chat_id));
    }
    window.history.replaceState({}, document.title);
    updateChatLimit(true, 0);
  }, [chat_id]);

  useEffect(() => {
    if (receivedMsg) {
      if (receivedMsg.chat_id) {
        if (!objectFindCheck(chatList, '_id', receivedMsg.chat_id.toString())) {
          dispatch(
            getChatDetailsApi(receivedMsg.chat_id, (a) => {
              dispatch(
                chatListAdd({
                  ...a.data,
                  last_msg_time: receivedMsg?.msg_time,
                  recent_msg: strReduce(receivedMsg.content),
                }),
              );
            }),
          );
        }
      }
      dispatch(chatMessageStore(receivedMsg));
    }
  }, [receivedMsg, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(setCurrentChat({}));
    };
  }, []);

  return (
    <div className="messagewholecard">
      <div className="dashboard-parent">
        {(!connection || !internetConn) && (
          <div className="chat-alert-box">
            {!connectionAlert ? (
              <>
                <CircularProgress size={15} thickness={5} color="primary" />
                &nbsp; Connecting...
              </>
            ) : (
              <>
                <WifiOffIcon color="warning" />{' '}
                <span>
                  &nbsp; &nbsp; Unable to load messages. Please check your internet connection
                </span>
              </>
            )}
          </div>
        )}
        <div className={`chat-container ${!connection || !internetConn ? 'top-50' : ''}`}>
          {ChatSidebar()}
          <div className="chat-content animated fadeIn">
            {chat_id === 'welcome' ? (
              <NoChatFound />
            ) : (
              <>
                {!currentChat?.to_details && (matchNew || directChat) ? (
                  <FullSectionLoader />
                ) : (
                  !currentChat?.to_details && !chat_id && <NoChatFound />
                )}
                {currentChat?.to_details && (
                  <ChatContent
                    updateChatLimit={updateChatLimit}
                    preventApiRenders={preventApiRenders}
                  />
                )}
              </>
            )}
          </div>
          {/* {chatProfile && (
            <div className="chat-profile">
              <ChatProfile />
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
};

const NoChatFound = () => {
  return (
    <div className={'container flex flex-column justify-content-center align-items-center'}>
      <i className="fa fa-laptop fa-3x p-4 rounded-lg bg-info text-white m-3" />
      <h1 className="font-weight-lighter">Hi, Welcome</h1>
    </div>
  );
};

export default ChatBox;
