import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { activitySelector, navigatePath } from '../../../redux/slicers/activitySlice.js';
import { useDispatch, useSelector } from 'react-redux';
import { sessionSuccess } from '../../../redux/slicers/sessionSlice.js';
import { getUserData } from '../../../helpers/projectHelper.js';

export const ActivityContainer = ({ children }) => {
   const { navPath } = useSelector(activitySelector);
   const dispatch = useDispatch();
   const history = useHistory();
   
   useEffect(() => {
      if (navPath) {
         history.push(navPath);
         dispatch(navigatePath(null));
      }
   }, [navPath]);
 
   return children;
};
