import { Link } from "react-router-dom"

const SideBarTabs = ({name, link, icon, alt, active, activeId, sub,sub_menu, activeParentTab, subMenuData, data}) => {
    
    
    return (
        <li>
        {sub_menu.condition === false ?<Link
          className={`app-menu__item  ${
           sub.condition === true ? (active === window.location.pathname || activeId === window.location.pathname.substring(sub.start,sub.end)) ? 'active' : '' : active === window.location.pathname || activeId === window.location.pathname ? 'active' : ''
          }`}
          to={link}
        >
          <div className="imgclrs clr972323">
            <img src={icon} alt={alt} />
          </div>
          <span className="app-menu__label">{name}</span>
        </Link>:
        <>
          <a
            className={`app-menu__item   ${
             
                activeParentTab?.some(item => item === window.location.pathname )  ? 'active'
                : ''
            } `}
            href={`#${data.id}`}
            aria-expanded="false"
            data-toggle="collapse"
          >
            <div className="imgclrs clrff8830">
              <img src={data.parentIcon} alt={data.alt} />
            </div>

            <span className="app-menu__label">{data.parentName}</span>
            <span className={`fa ${activeParentTab?.some(item => item === window.location.pathname ) ?   'fa-caret-down': 'fa-caret-right'} right custom-arrow-btn`}></span>
          </a>
          <ul className="appmenu collapse" id={`${data.id}`}>
           { subMenuData?.map((item, i) => (
               <li key={i}>
               <Link
                  className={`app-sub_menu__item  ${
                      item.link === window.location.pathname
                      ? 
                        'active'
                      : ''
                  }`}
                  to={item.link}
                  aria-expanded="false"
                  data-toggle="collapse"
                >
                  <div className="imgclrs clrff8830">
                    <img src={item.icon} alt="dashboardIcon" />
                  </div>
  
                  <span className="app-menu__label">{item.name} </span>
                </Link>
              </li>  

           ))  }        
          </ul>

        </>
          
          }
      </li>
    )
}

export default SideBarTabs