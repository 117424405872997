import React, { useEffect } from 'react';
import './assets/css/app.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Provider } from 'react-redux';
import store from './redux/store';
import AppContainer from './components';
import Toastr from './components/common/toastr.js';
import './assets/css/font.css';
import { GoogleOAuthProvider } from '@react-oauth/google';
const App = () => {
  return (
    <GoogleOAuthProvider clientId="520595860570-8j9dtp7344hgtdsi7jd9oufev1gef6nr.apps.googleusercontent.com">
    <Provider store={store}>
      <AppContainer />
      <Toastr />
    </Provider>
    </GoogleOAuthProvider>
  );
};

export default App;
