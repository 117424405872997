import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { profileListapi } from '../../../api/jobSeeker/jobSeeker';
import {
  countFixer,
  dummyQueryUser,
  getUserData,
  infoToast,
  sessionDestroy,
} from '../../../helpers/projectHelper';
import { useDispatch, useSelector } from 'react-redux';
import {
  CHAT,
  HOW_IT_WORKS_JOBSEEKERS,
  JOBSEEKER_DASHBOARD,
  JOBSEEKER_FINDJOBS,
  JOBSEEKER_INVOICE,
  JOBSEEKER_JOBOFFER_LIST,
  JOBSEEKER_MY_EMPLOYER,
  JOBSEEKER_VIEW_SCHEDULED_EVENT,
  JOB_SEEKER,
  TASKJOBSEEKER,
  JOBSEEKER_HOW_IT_WORKS,
  WEBSITE_HOW_IT_WORKS_JOB_SEEKERS,
} from '../../../config/constant/routePathConstant';
import { LOGO_IMG } from '../../../config/constant/imageConstant.js';
import { getSettingsData, jobSeekerSelector } from '../../../redux/slicers/jobSeekerSlice';
import { jobseekerNotifyApi } from '../../../api/jobSeeker/deshboard';
import {
  getNotificationData,
  setSocketNotification,
  socketSelector,
  unreadCountSelector,
} from '../../../redux/slicers/socketSlicer';
import { getUnreadCount } from '../../../api/chat/chatApi';
import { useSocket } from '../../../helpers/hooks';
import { getNotificationApi, updateNotificationApi } from '../../../api/notification';
import moment from 'moment';
import WarningAlert from '../../common/warningAlert';
import JSAlert from '../../common/jsAlert';
import Sidebar from './sidebar';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
const Header = () => {
  const dispatch = useDispatch();
  const [drawer, setDrawer] = useState(false);
  const location = useLocation();
  const unreadCount = useSelector(unreadCountSelector);
  const { profileList } = useSelector(jobSeekerSelector);
  const { appNotification = {} } = useSelector(socketSelector);
  const { disconnect = null } = useSocket();
  const { skills = [] } = profileList ?? {};
  const [flag, setFlag] = useState(false);
  const dropdownref = useRef(null);
  const { user_id = '', tracker_installed = false } = getUserData();
  const { count = '', json = [] } = appNotification;

  useEffect(() => {
    const data = {
      userid: user_id,
    };
    dispatch(getUnreadCount());
    dispatch(profileListapi(dummyQueryUser()));
    dispatch(getNotificationApi(data));
  }, []);

  useEffect(() => {
    const pageClickEvent = (e) => {
      // If the active element exists and is clicked outside of
      if (dropdownref.current !== null && !dropdownref.current.contains(e.target)) {
        setFlag(!flag);
      }
    };
    // If the item is active (ie open) then listen for clicks
    if (flag) {
      window.addEventListener('click', pageClickEvent);
    }
    return () => {
      window.removeEventListener('click', pageClickEvent);
    };
  }, [flag, dropdownref]);

  const getLink = (val) => {
    switch (val?.type) {
      case 'Amend':
        return JOBSEEKER_INVOICE;
      case 'Payment':
        return JOBSEEKER_INVOICE;
      case 'Invoice':
        return JOBSEEKER_INVOICE;
      case 'JobOffer':
        return JOBSEEKER_JOBOFFER_LIST;
      case 'Hired':
        return JOBSEEKER_MY_EMPLOYER;
      case 'Bookmarked':
        return JOBSEEKER_FINDJOBS;
      case 'JobOfferConfirmation':
        return JOBSEEKER_JOBOFFER_LIST;
      case 'JobInvitation':
        return `${JOB_SEEKER}/job-description/${val?.id}`;
      case 'InterviewScheduled':
        return JOBSEEKER_VIEW_SCHEDULED_EVENT;
      case 'TaskBoard':
        return TASKJOBSEEKER;
      case 'NewTask':
        return TASKJOBSEEKER;
      case 'payment_auto_debit_alert':
        return JOBSEEKER_INVOICE;
    }
  };
  const handleUnread = (val) => {
    dispatch(updateNotificationApi(val));
  };

  const handleLogout = () => {
    sessionDestroy();
    disconnect?.();
  };
  const toggleDrawer = (a) => {
    setDrawer(a);
  };

  return (
    <header className="jobseeker_header">
      <div className="container-fluid ">
        <nav className="navbar navbar-expand-md">
          <Link className="navbar-brand" to={JOBSEEKER_DASHBOARD}>
            <img className="img-fluid" src={LOGO_IMG} alt="logo" />
          </Link>
          <button className="navbar-toggler" type="button">
            <span className="navbar-toggler-icon" onClick={() => setDrawer(true)}>
              <svg
                width="23"
                height="20"
                viewBox="0 0 23 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.23926 4.23096H21.5518C21.9833 4.23096 22.333 3.8812 22.333 3.44971V1.49658C22.333 1.06509 21.9833 0.715332 21.5518 0.715332H1.23926C0.807764 0.715332 0.458008 1.06509 0.458008 1.49658V3.44971C0.458008 3.8812 0.807764 4.23096 1.23926 4.23096ZM1.23926 12.0435H21.5518C21.9833 12.0435 22.333 11.6937 22.333 11.2622V9.30908C22.333 8.87759 21.9833 8.52783 21.5518 8.52783H1.23926C0.807764 8.52783 0.458008 8.87759 0.458008 9.30908V11.2622C0.458008 11.6937 0.807764 12.0435 1.23926 12.0435ZM1.23926 19.856H21.5518C21.9833 19.856 22.333 19.5062 22.333 19.0747V17.1216C22.333 16.6901 21.9833 16.3403 21.5518 16.3403H1.23926C0.807764 16.3403 0.458008 16.6901 0.458008 17.1216V19.0747C0.458008 19.5062 0.807764 19.856 1.23926 19.856Z"
                  fill="black"
                />
              </svg>
            </span>
          </button>
          <SwipeableDrawer
            anchor="left"
            open={drawer}
            onClose={() => toggleDrawer(false)}
            onOpen={() => toggleDrawer(true)}
          >
            <Sidebar />
          </SwipeableDrawer>
          <div className="collapse navbar-collapse" id="collapsibleNavbar">
            <ul className="flexdirection employer-nav nav navbar-nav navbar-right">
              <a
                href={WEBSITE_HOW_IT_WORKS_JOB_SEEKERS}
                target="_blank"
                className={' font-weight-bold p-2 px-3 mr-2 bg-theme'}
                style={{ color: '#004C70', background: 'none', fontSize: '17px' }} rel="noreferrer"
              >
                How It Works
              </a>

              <li className="nav-item">
                <a href={JOBSEEKER_DASHBOARD}>
                  <i class="iconButton text-white bg-theme bg-theme-hover p-3 fas fa-home pr-1"></i>
                </a>
              </li>

              <li className="nav-item">
                <Link to={CHAT}>
                  <i className="iconButton text-white bg-theme bg-theme-hover p-3 px-4 fas fa-comments" />
                  {unreadCount ? (
                    <div className="notification-header-num">{countFixer(unreadCount)}</div>
                  ) : (
                    ''
                  )}
                </Link>
              </li>
              <li className="nav-item nav-notifications" ref={dropdownref}>
                <a
                  onClick={() => {
                    setFlag(true);
                  }}
                >
                  <i className="iconButton text-white bg-theme bg-theme-hover p-3 fas fa-bell" />
                  {count ? <div className="notification-header-num">{countFixer(count)}</div> : ''}
                </a>
                {flag && (
                  <div className="nav-notification-options notifi-res">
                    {count > 0 ? (
                      <>
                        {json?.result
                          .filter((e) => {
                            if (e.notificationObj.type != 'calling') {
                              return e;
                            }
                          })
                          ?.map((ele, i) => {
                            return (
                              <Link
                                key={'notification' + i}
                                to={getLink(ele?.notificationObj)}
                                className="convers dropdownoptions d-flex justify-content-between align-items-center"
                                style={{ borderBottom: '1px solid grey' }}
                              >
                                <div
                                  className="dropdownoptions"
                                  onClick={() => {
                                    handleUnread(ele?._id);
                                    setTimeout(() => {
                                      setFlag(false);
                                    }, 100);
                                  }}
                                >
                                  <div className="w-100 pb-3 d-flex flex-column justify-content-start align-items-start">
                                    <li
                                      className="w-100 pb-0 mb-0 d-flex justify-content-between align-items-center"
                                      style={{ fontSize: '16px' }}
                                    >
                                      <strong>{ele?.notificationObj?.title}</strong>
                                      <small style={{ color: 'grey' }} className="txt-sm">
                                        {moment(ele?.created_time).format('hh:mm A')}
                                      </small>
                                    </li>
                                    <small style={{ fontSize: '14px' }} className="txt-sm">
                                      {ele?.notificationObj?.msg}
                                    </small>
                                  </div>
                                </div>
                              </Link>
                            );
                          })
                          .reverse()}
                      </>
                    ) : (
                      <div style={{ textAlign: 'center', padding: '3%', fontSize: '20px' }}>
                        No Notifications
                      </div>
                    )}
                  </div>
                )}
              </li>
              <li className="nav-item" onClick={handleLogout}>
                <a>
                  <i className="iconButton p-3 fas bg-theme-hover text-white bg-theme fa-sign-out-alt" />
                </a>
              </li>
              {/* <div className='sidebarmobile'> <Sidebar /></div> */}
            </ul>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
