import React, { useEffect, useState } from 'react';
import { getUserData } from '../../helpers/projectHelper';
import { useDispatch, useSelector } from 'react-redux';
import { getEmployeeDetails,getEmpEmployeeDetails } from '../../api/employer/employer';
import { employerDashboardSelector } from '../../redux/slicers/employer/empDashboardSlice';
import { useParams } from 'react-router-dom';

const TrackerDetails = () => {
  const [userName, setUserName] = useState();
  const dispatch = useDispatch();
  let { payment_id } = useParams();
  const { user_type = 'job_seeker' } = getUserData();
  const { employeeData } = useSelector(employerDashboardSelector);

  useEffect(async () => {
    const { full_name, user_id} = await getUserData();
    setUserName(full_name);
    if(user_type==='job_seeker'){
    var datas = {
      _id: payment_id,
    };
    await dispatch(getEmployeeDetails(datas));
  }else{
    var datas = {
      _id: payment_id,
    };
    await dispatch(getEmpEmployeeDetails(datas));
  }
  }, []);

  function currencyFormat(num) {
    return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }

  return (
    <div className="tracker-details track-card-res">
      <div className="inner-details-card">
        <div className="section">
          <p>Staff Name</p>
          <p className="seperate-comma">:</p>
          <p style={{ overflowWrap: 'break-word' }}>{employeeData?.user_id?.user_full_nam} </p>
        </div>
        <div className="section">
          <p>Job Position </p>
          <p className="seperate-comma">:</p>
          <p style={{ overflowWrap: 'break-word' }}>
            {employeeData?.job_id?.job_title} (
            {employeeData?.job_id?.job_type == 'full_time'
              ? 'full-time'
              : employeeData?.job_id?.job_type == 'part_time'
              ? 'part-time'
              : employeeData?.job_id?.job_type}
            )
          </p>
        </div>
        <div className="section">
          <p>Weekly Hours Set</p>
          <p className="seperate-comma">:</p>
          <p style={{ overflowWrap: 'break-word' }}>
            {employeeData?.work_hours} {employeeData?.job_id?.salary_period}/
            {employeeData?.work_hours_period}
          </p>
        </div>
        <div className="section" style={{ marginBottom: '5px' }}>
          <p>Salary Rate</p>
          <p className="seperate-comma">:</p>
          <p style={{ overflowWrap: 'break-word' }}>
            {employeeData?.salary_amount ? currencyFormat(employeeData?.salary_amount) : 0}/
            {employeeData?.salary_period}
          </p>
        </div>
        {user_type == 'job_seeker' ? (
          <div className="section" style={{ color: '#33444d', fontWeight: 600 }}>
            <div style={{ maxWidth: '392px', textAlign: 'justify' }}>
                <li>The weekly Cut-off is Sunday 11:59pm UTC. All authorized time tracked will appear on
                    your salary invoice, which you'll receive on Monday.</li>
                <li>Your employer may allow manual time or may only allow automatic tracked time. Please
                    confirm with your employer!</li>            </div>
          </div>
        ) : (
          ' '
        )}
      </div>
    </div>
  );
};

export default TrackerDetails;
