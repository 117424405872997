import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import { blue } from '@material-ui/core/colors';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import 'date-fns';
import CustomizedBreadcrumbs from './common/BreadCrumbs';
import '../../assets/css/Gallery.scss';
// import CircularProgress from '@material-ui/core/CircularProgress';
// import Card from '@mui/material/Card';
// import CardHeader from '@mui/material/CardHeader';
import Skeleton from '@mui/material/Skeleton';
import { useParams  } from 'react-router-dom';
import { DateRangePicker } from 'materialui-daterange-picker';
// import { makeStyles } from "@material-ui/core/styles";
// import DownloadingIcon from '@material-ui/icons/Downloading';
import GetAppIcon from '@material-ui/icons/GetApp';
import { getUserData } from '../../helpers/projectHelper';
import { useDispatch, useSelector } from 'react-redux';
import { employeeWithFilteredTimesImages ,employeeWithDay ,employeeWithDayGroup} from '../../api/employer/employer';
import { employerDashboardSelector } from '../../redux/slicers/employer/empDashboardSlice';
import { useEffect } from 'react';

const useStyles = makeStyles((theme) => ({
  rootss: {
    // maxWidth: 345
    margin: '10px',
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
    '& .MuiDialog-paperWidthSm': {
      maxWidth: '1000px !important',
    },
  },
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  wholeRoot: {
    width: '100%',
    display: 'flex',
    // flexDirection: "column",
    flexWrap: 'wrap',
    justifyContent: 'center',
    '& .MuiDialog-paperWidthSm': {
      maxWidth: '1200px !important',
    },
  },
  textField: {
    color: '#ffffff',
  },
  button: {
    color: '#ffffff',
    backgroundColor: '#0093d1',
    '&:hover': {
      backgroundColor: '#0093d1',
      cursor: 'pointer',
    },
    '&:disabled': {
      backgroundColor: '#d9d9d9',
      color: '#33444d',
    },
  },
  media: {
    height: 0,
    width: '100%',
    paddingTop: '56.25%', // 16:9
  },
}));

export default function GalleryWithDay() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { employeeTrackedImageTable = [], trackingLoader ,employeeTrackedDay  = []} = useSelector(employerDashboardSelector);
  let { dayid, userRole, payment_id, fromDate, toDate } = useParams();
  const url = new URL(window.location.href);
  const params = url.searchParams;
  const [expanded, setExpanded] = React.useState(false);
  const [base64Images, setbase64Images] = React.useState([]);
  const [toggleImage, settoggleImage] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState();
  const [showImage, setshowImage] = React.useState();
  const [showTitle, setshowTitle] = React.useState();

  const [search, setSearch] = React.useState();
  const [opens, setOpens] = React.useState(false);
  const [dateRange, setDateRange] = React.useState({});
  const [toggle, settoggle] = React.useState(false);

  const [selectedDates, setSelectedDates] = React.useState();
  const [selectedDatess, setSelectedDatess] = React.useState();
  const [hours, setHours] = React.useState([]);

  React.useEffect(() => {
    let payloads = {
      user_id: getUserData().user_id,
      employee_id: '61fd30ad5572947b113a4e8a',
      //   startDateOfs: new Date(new Date(fromDate).getTime() + 6),
      //   endDateOfs: new Date(new Date(toDate).getTime() + 6),
      startDateOfs:fromDate,
      endDateOfs:toDate,
      payment_id: payment_id,
    };
    dispatch(employeeWithFilteredTimesImages(payloads));
  }, []);
  // useEffect(() => {
  //    let data = params.get('dayid');
  //    let payload = {
  //     payment_id: payment_id,
  //     created_date: data,
  //   };

  //   dispatch(employeeWithDay(payload));
  //   dispatch(employeeWithDayGroup(payload));
  // },[])
  React.useEffect(async () => {
    let d =
      new Date(toDate).getHours() - new Date(fromDate).getHours() > 1
        ? new Date(toDate).getHours() - new Date(fromDate).getHours()
        : new Date(toDate).getHours() == new Date(fromDate).getHours()
          ? 1
          : 2;
    let arr = [];
    console.log('hours', d);
    await Array(d)
      .fill(0)
      .map((n, i) => {
        console.log('hours', d, i);
        arr.push(new Date(fromDate).getHours() + i);
      });
    setHours(arr);
  }, []);

  const toggles = () => {
    settoggle(!toggle);
    setOpens(true);
    setOpens(!opens);
  };

  const handleCloses = (value) => {
    if (value === 'clickaway') {
      return;
    }
    setOpens(false);
  };

  const handleClickOpen = (i) => {
    console.log('handle', i);
    setOpen(true);
    setshowImage(i.image);
    setshowTitle(i);
  };

  const handleClose = (value) => {
    if (value === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <div className={'timesheet-container-sass'}>
      <br />
      <CustomizedBreadcrumbs />
      <br />
      <div className={classes.wholeRoot}>
        <br />
        <p style={{ fontSize: '18px', color: '#33444d' }}>
          {/* Tracked Images from {fromDate} to {toDate} */}
          Tracked Images from {new Date(`${fromDate}`).toLocaleString()} to {new Date(`${toDate}`).toLocaleString()}
        </p>

        <div>
          {showImage ? (
            <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
              <DialogTitle
                disableTypography
                id="alert-dialog-title"
                style={{
                  backgroundColor: '#0093d1',
                  color: 'white',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <div>
                  {' '}
                  <h6>{new Date(showTitle.created_At).toLocaleString('en-US')}</h6>
                </div>
                <div>
                  <a style={{ textDecoration: 'none', color: '#ffffff' }} href={showImage} download>
                    <GetAppIcon />
                  </a>
                </div>
              </DialogTitle>

              <img src={`https://dhpt63wbl5csr.cloudfront.net/${showImage}`} style={{ width: '100%' }} />
            </Dialog>
          ) : (
            ' '
          )}

          {toggle ? (
            <Dialog onClose={handleCloses} aria-labelledby="simple-dialog-title" open={opens}>
              <DateRangePicker
                open={opens}
                toggle={toggles}
                onChange={(range) => setDateRange(range)}
              />
            </Dialog>
          ) : (
            ' '
          )}
        </div>
      </div>
      {/* <br /> */}
      {employeeTrackedImageTable == null ? (
        <p
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '18px',
            color: '#ffffff',
            minHeight:"200px"
          }}
        >
          <CircularProgress />
        </p>
      ) : employeeTrackedImageTable?.length > 0 ? (
        hours?.map((j) => (
          <div class="wrapper">
            <div class="title">
              <p style={{ marginBottom: '0px' }}>
                Image Reports on {j > 12 ? j - 12 : j == 0 ? 12 : j} {j >= 12 ? 'pm' : 'Am'}
              </p>
            </div>

            <div class="wrappers">
              <div class="starts">
                <div class="start">
                  <p style={{ marginBottom: '0px', fontSize: '22px' }}>{j > 12 ? j - 12 : j == 0 ? 12 : j}</p>{' '}
                  <p>{j >= 12 ? 'pm' : 'Am'}</p>
                </div>
              </div>

              <div class="scrolls">
                <div class="scroll-body">
                  {employeeTrackedImageTable == null || employeeTrackedImageTable?.length == 0 ? (
                    <div style={{ width: '100%', display: 'flex' }}>
                      {Array(employeeTrackedImageTable?.length)
                        .fill(0)
                        .map((a) => (
                          <Card style={{ width: '250px' }}>
                            <CardHeader
                              title={
                                <Skeleton
                                  animation="wave"
                                  height={5}
                                  width="50%"
                                  style={{ marginBottom: 6 }}
                                />
                              }
                              subheader={<Skeleton animation="wave" height={5} width="40%" />}
                            />

                            <Skeleton sx={{ height: 150 }} animation="wave" variant="rectangular" />
                          </Card>
                        ))}
                    </div>
                  ) : (
                    employeeTrackedImageTable?.map((i) =>
                      // j == new Date(i.created_At).getHours() ? (
                        (
                        <div
                          style={{ marginRight: '10px', background: '#e5e5e5' }}
                          onClick={() => handleClickOpen(i)}
                        >
                          <p style={{ display: "flex", padding: "0px 10px", justifyContent: "space-between", alignItems: "center", flexDirection: "row", fontSize: '15px', marginBottom: '0px', padding: '5px 5px' }}>
                            {new Date(i.created_At).toLocaleTimeString()}
                            <p style={{ height: "15px", marginBottom: "0px", width: "50px", display: "flex", jusitfyContent: "center", alignItems: "center", borderRadius: "10px", backgroundColor: "rgb(172, 219, 138)" }}></p>
                          </p>
                          <img src={`https://dhpt63wbl5csr.cloudfront.net/${i.image}`} style={{ height: '200px', width: '270px' }} />
                        </div>
                      ) 
                    )
                       ) }
                </div> 
              </div>
            </div>
          </div>
        ))
      ) : (
        <p
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '18px',
            color: '#bbbbbb',
            minHeight:"155px"
          }}
        >
          No Image Found
        </p>
      )}
    </div>
  );
}
