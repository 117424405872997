import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import { blue } from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import 'date-fns';
import '../../assets/css/Gallery.scss';
// import Card from '@mui/material/Card';
// import CardHeader from '@mui/material/CardHeader';
import Skeleton from '@mui/material/Skeleton';
import { useParams } from 'react-router-dom';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { DateRangePicker } from 'materialui-daterange-picker';
// import { makeStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
// import DownloadingIcon from '@material-ui/icons/Downloading';
import GetAppIcon from '@material-ui/icons/GetApp';
import { getUserData } from '../../helpers/projectHelper';
import { useDispatch, useSelector } from 'react-redux';
import {
  employeeWithFilteredImages,
  employeeWithFilteredTimesImages,
  employeeWithImages,
} from '../../api/employer/employer';
import { employerDashboardSelector } from '../../redux/slicers/employer/empDashboardSlice';

const useStyles = makeStyles((theme) => ({
  rootss: {
    // maxWidth: 345
    margin: "10px",
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "1000px !important",
    }
  },
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  wholeRoot: {
    width: "100%",
    display: "flex",
    // flexDirection: "column",
    flexWrap: "wrap",
    justifyContent: "center",
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "1200px !important",
    },
   
  },
  textField:{
      color:"#ffffff"
  },
  button:{
    color:"#ffffff",
    backgroundColor:'#0093d1',
      "&:hover":{
        backgroundColor:'#0093d1',
        cursor:"pointer"
      },
      "&:disabled":{
        backgroundColor:'#d9d9d9',
        color:"#33444d"
      }
  },
  media: {
    height: 0,
    width: "100%",
    paddingTop: "56.25%", // 16:9
  },
}));

export default function Gallery() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { employeeTrackedImageTable,trackingLoader } = useSelector(employerDashboardSelector)
  let { dayid,userRole,payment_id } = useParams();

  const [expanded, setExpanded] = React.useState(false);
  const [base64Images, setbase64Images] = React.useState([]);
  const [toggleImage, settoggleImage] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState();
  const [showImage, setshowImage] = React.useState();
  const [showTitle, setshowTitle] = React.useState();

  const [search, setSearch] = React.useState();
  const [opens, setOpens] = React.useState(false);
  const [dateRange, setDateRange] = React.useState({});
  const [toggle, settoggle] = React.useState(false);

  const [selectedDates, setSelectedDates] = React.useState();
  const [selectedDatess, setSelectedDatess] = React.useState();
  const [hours,setHours] = React.useState([]);

  const handleChange = async (e) => {
    console.log("e", e.target.value);
    setSelectedDates(e.target.value);
  };

  const handleChangess = async (e) => {
    setSelectedDatess(e.target.value);
  };

  const handleChanges = async () => {
    // console.log(e.target.value, new Date(e.target.value));
    // settoggle(false);
    let payloads = {
      user_id: getUserData().user_id,
      employee_id:"615d8c752aea22532b24775d",
      startDateOfs: new Date((new Date(selectedDates).getTime()+6)),
      endDateOfs:new Date((new Date(selectedDatess).getTime()+6)),
      payment_id:payment_id
    };
   console.log("moment",moment(selectedDates))
   dispatch(employeeWithFilteredTimesImages(payloads))
  };

  React.useEffect(async () => {
    let d = (new Date()).getHours()-5
    let arr = [] 
    console.log("hours",d)
    await Array(5).fill(0).map((n,i)=>{
      console.log("hours",d)
      arr.push(d+i+1)
    })
    setHours(arr)
  },[])

  React.useEffect(() => {
    
    let start = new Date(new Date().getTime() - 1 * 60 * 60 * 24 * 1000);

    var startDate = moment(start).startOf("day").toDate();

    let payload = {
      // user_id: getUserData().user_id,
      payment_id:payment_id,
      employee_id:"615d8c752aea22532b24775d",
      startDate:startDate
    };
    dispatch(employeeWithImages(payload))
  }, []);

  const toggles = () => {
    settoggle(!toggle);
    setOpens(true);
  
    setOpens(!opens);
  };

  const filterTable = async () => {
   
    let payloads = {
      startDateOfs: dateRange.startDate,
      endDateOfs: dateRange.endDate,
      user_id: getUserData().user_id,
      employee_id:"615d8c752aea22532b24775d",
      payment_id:payment_id
    };

    dispatch(employeeWithFilteredImages(payloads))
    
  };

  const handleCloses = (value) => {
    if (value === "clickaway") {
      return;
    }
    setOpens(false);
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };



  const fetchData = async () => {

    let start = new Date(new Date().getTime() - 1 * 60 * 60 * 24 * 1000);

    var startDate = moment(start).startOf("day").toDate();

    let payload = {
      user_id: getUserData().user_id,
      employee_id:"615d8c752aea22532b24775d",
      startDate:startDate
    };

    dispatch(employeeWithImages(payload))
  
  };

  const handleClickOpen = (i) => {
      console.log("handle",i)
    setOpen(true);
    setshowImage(i.image);
    setshowTitle(i)

  };

  const handleClose = (value) => {
    if (value === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
      <div className={"timesheet-container-sass"}>
        {console.log("gallery",employeeTrackedImageTable,trackingLoader)}
           <br/>
    <div className={classes.wholeRoot}>
      <br/>
      <p style={{color:"#ffffff",fontSize:"17px"}}>Lastly Captured Ten Images is showing Here, use Filter to see all images</p>

      <br/>

      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {console.log("hours",hours)}
          <div
            style={{
              fontSize: "15px",
              backgroundColor: "#e5e5e5",
              padding: "0px 10px",
              borderRadius: "25px",
              height: "25px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p
              style={{
                fontSize: "15px",
                marginBottom: "0px",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {dateRange.startDate
                ? moment(dateRange?.startDate).format("DD-MM-YYYY")
                : "From Date"}
            </p>
            <p style={{ marginBottom: "0px" }}>&nbsp; - &nbsp;</p>
            <p
              style={{
                fontSize: "15px",
                height: "100%",
                marginBottom: "0px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {dateRange?.endDate
                ? moment(dateRange?.endDate).format("DD-MM-YYYY")
                : "End Date"}
            </p>
          </div>
          &nbsp; &nbsp;
          <DateRangeIcon onClick={toggles} style={{ float: "left",color:"#ffffff" }} />
          &nbsp; &nbsp;
          <Button onClick={filterTable}disabled={dateRange.endDate && dateRange.startDate?false:true} className={classes.button} variant="contained">
            Filter with Date
          </Button>
        </div>
        &nbsp; &nbsp; &nbsp; &nbsp;
        <br />
        <div style={{ display: "flex" }}>
          <form
            style={{ display: "flex", color:"#ffffff" }}
            className={classes.container}
            noValidate
          >
            <TextField
              id="datetime-local"
              label="Start Date with time filter"
              type="datetime-local"
            //   defaultValue="2021-09-16T03:35"
              className={classes.textField}
            style={{color:"#ffffff"}}
              value={selectedDates}
              onChange={handleChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
           
          </form>
          &nbsp; &nbsp;   &nbsp; &nbsp;
          <form
            style={{ display: "flex" }}
            className={classes.container}
            noValidate
          >
            <TextField
              id="datetime-local"
              label="End Date with time filter"
              type="datetime-local"
            //   defaultValue="2021-09-16T03:35"
              className={classes.textField}
              value={selectedDatess}
              onChange={handleChangess}
              InputLabelProps={{
                shrink: true,
              }}
            />
            &nbsp; &nbsp;
            <Button onClick={handleChanges} disabled={selectedDatess && selectedDates?false:true} className={classes.button} variant="contained">
              Filter With Time
            </Button>
          </form>
        </div>
      </div>
      {/* <br /> */}

      <br />
      <br />
      <br />
      <br /> 
      {employeeTrackedImageTable && !trackingLoader ?(
  
          <div style={{display:"flex", flexWrap:"wrap",width:"100%",justifyContent:"center",alignItems:"center"}}>
          {employeeTrackedImageTable?.map((i)=> (
          <Card
            className={classes.rootss}
            onClick={() => handleClickOpen(i)}
          >
            <CardHeader
              style={{fontWeight:600 }}
              subheader={new Date(i.created_At).toLocaleString("en-US")}
            />

            <CardMedia
              className={classes.media}
              style={{ width: "350px" }}
              image={i.image}
              title={new Date(i.created_At).toLocaleString("en-US")}
            />
          </Card>
        ))}
        </div>
      ) : (
        <div>
          {" "}
          <br />
          <CircularProgress color="secondary" />
        </div>
      )}
      <div>

        <br />
        {showImage ? (
          <Dialog
            onClose={handleClose}
            aria-labelledby="simple-dialog-title"
            open={open}
          >
              <DialogTitle
                disableTypography
                 id="alert-dialog-title"
                 style={{ backgroundColor: '#0093d1', color: 'white',display:"flex", flexDirection:"row",justifyContent: 'space-between' }}
                >
 <div>   <h6>{new Date(showTitle.created_At).toLocaleString("en-US")}</h6></div>
    <div>
        <a style={{textDecoration:"none", color:"#ffffff"}} href={showImage} download>
        <GetAppIcon/>
        </a>
        </div>
</DialogTitle>
           
            <img src={showImage} style={{ width: "100%" }} />
          </Dialog>
        ) : (
          " "
        )}

        {toggle ? (
          <Dialog
            onClose={handleCloses}
            aria-labelledby="simple-dialog-title"
            open={opens}
          >
            <DateRangePicker
              open={opens}
              toggle={toggles}
              onChange={(range) => setDateRange(range)}
            />
          </Dialog>
        ) : (
          " "
        )}
      </div>
    </div>
    <br/>
    {hours?.map(j=>(
<div class="wrapper">
  <div class="title">
  <p style={{marginBottom: "0px"}}>
  Weakly Reports
  </p>
  </div>
  
<div class="wrappers">
  <div class="starts">
     <div class="start"><p style={{marginBottom: "0px",fontSize:"22px"}}>{j>12?j-12:j}</p> <p>{j>=12?"pm":"Am"}</p></div>
  </div>
  
  <div class="scrolls">
   <div class="scroll-body">
     {employeeTrackedImageTable == null || employeeTrackedImageTable.length<=0?  
     <div style={{width: '100%',display:"flex"}}>
       {Array(7).fill(0).map(a=>(
        <Card style={{width:"250px"}}>
          <CardHeader
        title={
            <Skeleton
              animation="wave"
              height={5}
              width="50%"
              style={{ marginBottom: 6 }}
            />
        }
        subheader={
            <Skeleton animation="wave" height={5} width="40%" />
        }
      />
      
        <Skeleton sx={{ height: 150 }} animation="wave" variant="rectangular" />
      
    </Card>
       )) }
    </div>:
   employeeTrackedImageTable?.map((i)=> (
     <div style={{marginRight:"10px", background:"#e5e5e5"}} onClick={() => handleClickOpen(i)}>
     <p style={{fontSize:"15px",marginBottom:"0px", padding:"5px 0px"}}>{(new Date(i.created_At)).toLocaleTimeString()}</p>
     <img src={i.image} style={{height:"200px",width:"270px"}}/>
     </div>
   ))}
 
       {/* <div style={{height:"200px",width:"300px", backgroundColor:"grey"}}></div> */}
       {/* &nbsp;
       <img src="http://placehold.it/200x200" />
        &nbsp;
       <img src="http://placehold.it/200x200" />
        &nbsp;
       <img src="http://placehold.it/200x200" />
        &nbsp;
       <img src="http://placehold.it/200x200" />
        &nbsp;
       <img src="http://placehold.it/200x200" />
       &nbsp;
       <img src="http://placehold.it/200x200" />
        &nbsp;
       <img src="http://placehold.it/200x200" />
       &nbsp;
       <img src="http://placehold.it/200x200" />
       &nbsp;
       <img src="http://placehold.it/200x200" />
        &nbsp;
       <img src="http://placehold.it/200x200" /> */}
   </div>
  </div>
 </div>
 
</div>
))}

    </div>
  );
}
