import {
  CHAT,
  CHAT_INDIVIDUAL,
  CHAT_INIT,
  CHAT_MEET_VIDEO_CALL,
  CHAT_VIDEO_CALL,
} from '../constant/routePathConstant.js';
import ChatBox from '../../components/chat/chatBox.js';
import VideoCall from '../../components/chat/videoCall';
import Meet from '../../components/chat/meet.js';
import { RedirectToChatWelcome } from './../../components/chat/redirect';

const CHAT_ROUTES = [
   { component: RedirectToChatWelcome, path: CHAT },
   { component: ChatBox, path: CHAT_INIT },
   { component: ChatBox, path: CHAT_INDIVIDUAL },
   { component: VideoCall, path: CHAT_VIDEO_CALL },
   {component:  Meet ,path:CHAT_MEET_VIDEO_CALL}
];

export default CHAT_ROUTES;
