import React, { useEffect } from 'react';
import 'date-fns';
import CustomizedBreadcrumbs from './common/BreadCrumbs';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { Link, useParams } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useDispatch, useSelector } from 'react-redux';
import {
  employeeWithDay,
  employeeWithDayGroup,
  employeeWithWeaklyReport,
} from '../../api/employer/employer';
import { employerDashboardSelector } from '../../redux/slicers/employer/empDashboardSlice';
import '../../assets/css/stopWatch.scss';
import Tooltip from '@mui/material/Tooltip';
import CancelIcon from '@mui/icons-material/Cancel';

const columns = [
  { id: 'startTime', label: 'Start time', minWidth: 100 },
  {
    id: 'endTime',
    label: 'End Time',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
  
  {
    id: 'TotalHours',
    label: 'Total hours',
    minWidth: 170,
    align: 'center',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'See_Images',
    label: 'See images',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toFixed(2),
  },
 
  {
    id: 'startDate',
    label: 'Start date',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toFixed(2),
  },
  {
    id: 'endDate',
    label: 'End date',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toFixed(2),
  },
];

function createData(
  // name,
  startTime,
  endTime,
  time_type,
  TotalHours,
  See_Images,
  remarks,
  startDate,
  endDate,
) {
  return {
    startTime,
    endTime,
    time_type,
    TotalHours,
    See_Images,
    remarks,
    startDate,
    endDate,
  };
}

var rows = [];

const useStyles = makeStyles({
  roots: {
    width: '100%',
    '& p.MuiTypography-root.MuiTablePagination-caption.MuiTypography-body2.MuiTypography-colorInherit':
      {
        display: 'none',
      },
    '& .MuiInputBase-root.MuiTablePagination-input.MuiTablePagination-selectRoot': {
      display: 'none',
    },
    '& .MuiTableCell-alignRight': {
      textAlign: 'center',
      fontFamily:'Lato-Regular !important',
    },
    '& .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-elevation24.MuiPaper-rounded':
      {
        width: '360px !important',
      },
    '& .MuiDialog-paperWidthSm': {
      maxWidth: '850px !important',
    },
    '& .MuiTableCell-body': {
      minWidth: '150px !important',
    },
    '& th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-stickyHeader':{
      fontFamily: 'Lato-Regular !important'
    }
  },
  container: {
    maxHeight: 440,
  },
});

export default function TimerTrackerWithDay() {
  let { dayid, userRole, payment_id } = useParams();
  const dispatch = useDispatch();
  const {
    employeeTrackedTime,
    trackingLoader,
    employeeTrackedDay = [],
    totalTImeCount,
    employeeTrackedDaysGroup = [],
    weaklyReport = [],
  } = useSelector(employerDashboardSelector);

  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(6);
  const [tableData, settableData] = React.useState();
  const [toggle, settoggle] = React.useState(false);
  const [search, setSearch] = React.useState();
  const [sumCounts, setsumCounts] = React.useState();
  const [TrackedDaysGroup, setTrackedDaysGroup] = React.useState([]);
  const [TimeHours, setTimeHours] = React.useState([
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
  ]);
  const [opens, setOpens] = React.useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleCloses = (value) => {
    if (value === 'clickaway') {
      return;
    }
    setOpens(false);
  };

  useEffect(() => {
    setTrackedDaysGroup(employeeTrackedDaysGroup);
  }, [employeeTrackedDaysGroup]);

  useEffect(async () => {
    rows = [];
    if (employeeTrackedDay?.length > 0) {
      await employeeTrackedDay?.map((e) =>
        rows.push(
          createData(
            e.startTime,
            e.endTime,
            e.time_type,
            parseInt(e.endTime) - parseInt(e.startTime),
            'See Images',
            e.remarks,
            e.startDate,
            e.endDate,
          ),
        ),
      );

      settoggle(true);
    }
  }, [employeeTrackedDay]);

  useEffect(async () => {
    if (employeeTrackedDay?.length > 0) {
      await setsumCounts(parseInt(totalTImeCount));
    }
  }, [employeeTrackedDay, totalTImeCount]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [open, setOpen] = React.useState(false);
  const [dateRange, setDateRange] = React.useState({});
  const [toggleRemark, setToggleRemark] = React.useState(false);
  const toggles = () => setOpen(!open);
  const [remarkText, setRemarkText] = React.useState();

  const [selectedDate, setSelectedDate] = React.useState(new Date());

  React.useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    let payload = {
      payment_id: payment_id,
      created_date: dayid,
    };

    dispatch(employeeWithDay(payload));
    dispatch(employeeWithDayGroup(payload));
    dispatch(employeeWithWeaklyReport({ payment_id: payment_id }));
  };

  const formatTime = (timer) => {
    const getSeconds = `0${timer % 60}`.slice(-2);
    const minutes = `${Math.floor(timer / 60)}`;
    const getMinutes = `0${minutes % 60}`.slice(-2);
    const getHours = `0${Math.floor(timer / 3600)}`.slice(-2);

    return `${getHours} : ${getMinutes}`;
  };

  const viewRemark = (e) => {
    setOpens(true);
    setRemarkText(e);
    setToggleRemark(true);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
      }}
    >
      {/* <CustomizedBreadcrumbs /> */}

      {/* {toggleRemark ? (
        <Dialog onClose={handleCloses} aria-labelledby="simple-dialog-title" open={opens}>
          <DialogTitle>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                height: '15px',
              }}
            >
              <p
                style={{
                  display: 'flex',
                  marginBottom: '0px',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                Remarks
              </p>
              <CancelIcon style={{ cursor: 'pointer' }} onClick={handleCloses} />
            </div>
          </DialogTitle>
          <hr />
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: 'max-content',
              minWidth: '600px',
              maxWidth: '627px',
              padding: '21px 23px',
            }}
          >
            {remarkText ? remarkText : <p style={{ color: '#e5e5e5' }}>No remarks Found</p>}
          </div>
        </Dialog>
      ) : (
        ' '
      )} */}

      <br />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          width: '90%',
        }}
      >
        <p style={{ fontWeight: 600, color: '#33444d', marginBottom:"0px" }}>Logged times on {dayid}</p>
        <br />
        {/* <div className='flexwrp' style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',paddingRight:'8px', paddingLeft:'8px' }}>
            <p style={{ height: '13px', width: '40px', background: '#acdb8a' }}></p>
            &nbsp; &nbsp;
            <p style={{ fontSize: '15px', color: '#33444d' }}>Automatic Logged</p>
          </div>

          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingRight:'8px', paddingLeft:'8px' }}>
            <p style={{ height: '13px', width: '40px', background: '#fcd303' }}></p>
            &nbsp; &nbsp;
            <p style={{ fontSize: '15px', color: '#33444d' }}>Manually Logged</p>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',paddingRight:'8px', paddingLeft:'8px' }}>
            <p style={{ height: '13px', width: '40px', background: 'red' }}></p>
            &nbsp; &nbsp;
            <p style={{ fontSize: '15px', color: '#33444d' }}>Exceeded worked Time</p>
          </div>
        </div> */}
        {/* <br />
        <p style={{ fontSize: '15px', color: '#33444d',marginBottom: '10px' }}>Weekly Logged Report</p>
        <br /> */}
        {/* <div
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '90%',
            height: '40px',
            zIndex: 1,
            backgroundColor: 'white',
            border: '1px solid #e5e5e5',
          }}
        >
          <div style={{ display: 'flex', width: '100%' }}>
            {weaklyReport &&
              weaklyReport?.map((e) =>
                e.exeedPercentage ? (
                  <div style={{ display: 'flex', width: '100%' }}>
                    <Tooltip
                      title={`${e._id} - ${formatTime(
                        (e.hiredStaffsDetails / 100) * e.exeedPercentage * 3600,
                      )}`}
                      placement="top"
                    >
                      <div
                        style={{
                          display: 'flex',
                          height: '40px',
                          width: `${parseInt(e.exeedPercentage)}%`,
                          zIndex: 100,
                          backgroundColor: 'red',
                        }}
                      ></div>
                    </Tooltip>
                    <Tooltip
                      title={`${e._id} - ${formatTime(
                        e.workerHours - (e.hiredStaffsDetails / 100) * e.exeedPercentage * 3600,
                      )}`}
                      placement="top"
                    >
                      <div
                        style={{
                          display: 'flex',
                          height: '40px',
                          width: `${parseInt(e.percentage)}%`,
                          zIndex: 100,
                          backgroundColor:
                            e._id == 'manual'
                              ? '#fcba03'
                              : e._id == 'automatic'
                              ? 'rgb(172, 219, 138)'
                              : 'red',
                        }}
                      ></div>
                    </Tooltip>
                  </div>
                ) : (
                  <Tooltip title={`${e._id} - ${formatTime(e.workerHours)}`} placement="top">
                    <div
                      style={{
                        display: 'flex',
                        height: '40px',
                        width: `${parseInt(e.percentage)}%`,
                        zIndex: 100,
                        backgroundColor:
                          e._id == 'manual'
                            ? '#fcba03'
                            : e._id == 'automatic'
                            ? 'rgb(172, 219, 138)'
                            : 'red',
                      }}
                    ></div>
                  </Tooltip>
                ),
              )}
          </div>
        </div>
        <br />
        <br /> */}
        
        

        {/* <div
          style={{
            backgroundColor: '#e5e5e5',
            display: 'flex',
            flexDirection: 'row',
            width: '90%',
          }}
        >
          <div
            style={{
              backgroundColor: '#e5e5e5',
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
            }}
          >
            {TimeHours.map((i) => (
              <div
                style={{
                  backgroundColor: 'white',
                  display: 'flex',
                  height: '24px',
                  width: '4.3%',
                  borderRight: '1px solid #000',
                  flexDirection: 'row',
                }}
              >
                {TrackedDaysGroup?.map((e) =>
                  e.percentage !== 0 && e.startHour == i ? (
                    <Tooltip title={`${e.startTime}-${e.endTime}`} placement="top">
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          zIndex: 200,
                          justifyContent: `${
                            e.startMinute >= 30
                              ? 'flex-end'
                              : e.startMinute <= 30 && e.endHour == i
                              ? 'flex-start'
                              : e.startMinute <= 30 && e.endHour > i
                              ? 'flex-end'
                              : 'center'
                          }`,
                        }}
                      >
                        <div
                          style={{
                            backgroundColor:
                              e.time_type == 'manual'
                                ? '#fcba03'
                                : e.time_type == 'automatic'
                                ? '#acdb8a'
                                : e.time_type == 'exeed'
                                ? 'red'
                                : '#e5e5e5',
                            height: '24px',
                            width: `${
                              e.diff <= 60
                                ? e.startHour == i && e.endHour == i
                                  ? e.times
                                  : (e.startHour == i || e.endHour == i) &&
                                    (e.startHour == i || e.endHour >= i)
                                  ? ((e.startMinute
                                      ? e.startMinute >= 30
                                        ? 60 - e.startMinute
                                        : 60 - e.startMinute
                                      : 60) /
                                      60) *
                                    100
                                  : 100
                                : e.diff >= 60 && e.startHour == i && e.endHour >= i
                                ? ((e.startMinute
                                    ? e.startMinute >= 30
                                      ? 60- e.startMinute
                                      : 60 - e.startMinute
                                    : 60) /
                                    60) *
                                  100
                                : 100
                            }%`,
                            display: 'flex',
                            position: 'relative',
                          }}
                        ></div>
                      </div>
                    </Tooltip>
                  ) : e.percentage !== 0 && e.endHour >= i && e.startHour < i ? (
                    <Tooltip title={`${e.startTime}-${e.endTime}`} placement="top">
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          zIndex: 200,
                          justifyContent: `flex-start`,
                        }}
                      >
                        <div
                          style={{
                            backgroundColor:
                              e.time_type == 'manual'
                                ? '#fcba03'
                                : e.time_type == 'automatic'
                                ? '#acdb8a'
                                : e.time_type == 'exeed'
                                ? 'red'
                                : '#e5e5e5',
                            height: '24px',
                            width: `${
                              e.diff <= 60
                                ? e.startHour == i && e.endHour == i
                                  ? e.times
                                  : (e.startHour == i || e.endHour == i) &&
                                    (e.startHour <= i || e.endHour == i)
                                  ? (e.endMinute / 60) * 100
                                  : 100
                                : e.diff >= 60 && e.startHour < i && e.endHour == i
                                ? (e.endMinute / 60) * 100
                                : 100
                            }%`,
                            display: 'flex',
                          }}
                        ></div>
                      </div>
                    </Tooltip>
                  ) : (
                    ' '
                  ),
                )}
              </div>
            ))}
          </div>
        </div>
        <div
          style={{
            backgroundColor: '#e5e5e5',
            display: 'flex',
            flexDirection: 'row',
            width: '90%',
          }}
        >
          <div
            style={{
              backgroundColor: '#e5e5e5',
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
            }}
          >
            {TimeHours.map((i) => (
              <div
                style={{
                  backgroundColor: 'white',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '24px',
                  width: '4.3%',
                  borderRight: '1px solid #000',
                }}
              >
                <p
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '14px',
                    height: '100%',
                    marginBottom: '0px',
                    zIndex: 100,
                  }}
                >
                  {i > 12 ? i - 12 : i == 0 ? 12 : i} {i >= 12 ? 'PM' : i == 0 ? 'AM' : 'AM'}
                </p>
              </div>
            ))}
          </div>
        </div> */}
        {/* <br /> */}
        {/* <div className='mobres'
          style={{
            backgroundColor: '#e5e5e5',
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
          }}
        >
          <div
            style={{
              backgroundColor: '#e5e5e5',
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
            }}
          >
            {TimeHours.map((i) => (
              <div
                style={{
                  backgroundColor: 'white',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '24px',
                  width: '4.3%',
                  borderRight: '1px solid #000',
                }}
              >
                <p
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '14px',
                    height: '100%',
                    marginBottom: '0px',
                    zIndex: 100,
                  }}
                >
                  {i > 12 ? i - 12 : i == 0 ? 12 : i} {i >= 12 ? 'PM' : i == 0 ? 'AM' : 'AM'}
                </p>
              </div>
            ))}
          </div>
        </div> */}
       
       
        <br/>
        
        {toggle ? (
          <Paper className={classes.roots}>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, fontWeight: 600 }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                {toggle ? (
                  <TableBody>
                    {console.log('tableData', tableData, rows)}
                    {rows
                      ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row) => {
                        return (
                          <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                            {columns.map((column) => {
                              const value = row[column.id];
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  {column.id == 'startTime' ||
                                  column.id == 'endTime' ||
                                  column.id == 'TotalHours' ? (
                                    formatTime(value)
                                  ) : column.id == 'See_Images' ? (
                                    <Link
                                      to={`/tracker/${userRole}/${payment_id}/${row['startDate']}/${row['endDate']}`}
                                      // to={`/tracker/${userRole}/${payment_id}/dates?fromDate=${row['startDate']}&toDate=${row['endDate']}`}
                                    >
                                      View Screenshot
                                    </Link>
                                  ) : column.id == 'endDate' || column.id == 'startDate' ? (
                                    // new Date(parseInt(value)).toLocaleString()
                                    new Date(value).toLocaleString('en-US')
                                  ) : ''
                            }
                                 
                                </TableCell>
                              );
                            })}
                            {/* new Date(parseInt(value)).toLocaleString("en-US",{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}) */}
                          </TableRow>
                        );
                      })}
                    {/* <TableRow
                      hover
                      role="checkbox"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '596%',
                      }}
                      tabIndex={-1}
                      tabIndex={-1}
                    >
                      <TableCell
                        style={{
                          fontWeight: 600,
                          width: '100%',
                          justifyContent: 'center',
                          alignItems: 'center',
                          display: 'flex',
                        }}
                      >
                        Effective worked hours(hours: minutes: seconds):{' '}
                        {sumCounts ? formatTime(sumCounts) : ''}
                        hours
                      </TableCell>
                      {/* <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell style={{ fontWeight: 600 }}>
                        Effective worked hours
                      </TableCell>
                      <TableCell>(hours:minutes:seconds)</TableCell>
                      <TableCell style={{ fontWeight: 600 }}>
                        {sumCounts ? formatTime(sumCounts) : ''}
                      </TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </TableRow> */}
                  </TableBody>
                ) : (
                  ' '
                )}
              </Table>
            </TableContainer>
            <div style={{ display: 'flex', justifyContent: 'center',padding:"10px 0px",alignItems: 'center', fontWeight: 600, }}>  Effective worked hours(hours: minutes):{' '}
                        {sumCounts ? formatTime(sumCounts) : ''}
                       {` `} hours</div>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              // onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        ) : (
          <CircularProgress color="secondary" />
        )}
      </div>
      <br />
    </div>
  );
}
