import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { profileListapi } from '../../../api/jobSeeker/jobSeeker.js';
import { JOBSEEKER_ACCOUNTSECTION, JOBSEEKER_DASHBOARD, JOBSEEKER_PROFILE_LANDING } from '../../../config/constant/routePathConstant.js';
import { getUserData } from '../../../helpers/projectHelper.js';
import { jobSeekerSelector } from '../../../redux/slicers/jobSeekerSlice.js';


export const RedirectToDashboard = () => {
   const { profileList = {} } = useSelector(jobSeekerSelector);
   return <Redirect to={JOBSEEKER_DASHBOARD} />




   // return  profileList?.is_profile_complete === false ? <Redirect to={JOBSEEKER_ACCOUNTSECTION} /> : <Redirect to={JOBSEEKER_DASHBOARD} />




};

export const RedirectToGetStarted = () => {
   return <Redirect to={JOBSEEKER_PROFILE_LANDING} />;
};
