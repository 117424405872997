import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { CAPTCHA_KEY } from '../../config/constant/projectConstant.js';
import ReCAPTCHA from 'react-google-recaptcha';
import Autocomplete from 'react-autocomplete';

import InputMask from 'react-input-mask';
import { ref } from 'yup';

export const Input = forwardRef(
  (
    {
      maskPlaceholder = null,
      mask = null,
      required = false,
      className = null,
      limit = 1000,
      label = null,
      type = 'text',
      errorData = [],
      multiline = false,
      name = null,
      defaultData = {},
      onChange = null,
      ...props
    },
    ref,
  ) => {
    const [value, setValue] = useState('');
    const [errorText, setErrorText] = useState('');

    className = className === false ? '' : `form-control ${className}`;
    useEffect(() => {
      if (maskPlaceholder) {
        setValue(maskPlaceholder);
      }
    }, [maskPlaceholder]);
    useEffect(() => {
      if (defaultData?.[name]) {
        setValue(defaultData[name]);
      }
    }, [defaultData]);

    useEffect(() => {
      if (errorData?.[name]) {
        setErrorText(errorData[name]);
      } else {
        setErrorText('');
      }
    }, [errorData]);
    function currencyFormat(num) {
      return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }
    const handleChange = ({ target }) => {
      const { value: val = '' } = target;
      if (val.length <= limit) {
        setValue(type === 'number' ? parseFloat(val) : val);
        onChange?.({ name, value: type === 'number' ? val : val });
      }
    };

    return (
      <>
        {label !== false && <label>{label}</label>}
        {multiline ? (
          <textarea
            required={required}
            className={` ${className} ${errorText && 'is-invalid'}`}
            name={name}
            value={value}
            onChange={handleChange}
            {...props}
          />
        ) : (
          <>
            {mask ? (
              <InputMask
                mask={mask}
                name={name}
                value={value}
                onChange={handleChange}
                ref={ref}
                required={required}
              >
                {(inputProps) => (
                  <input className={`${className} ${errorText && 'is-invalid'}`} {...inputProps} />
                )}
              </InputMask>
            ) : (
              <>
                {' '}
                <input
                  required={required}
                  type={type}
                  ref={ref}
                  className={`${className} ${errorText && 'is-invalid'}`}
                  name={name}
                  value={value}
                  onChange={handleChange}
                  {...props}
                />
                {errorText && <div className="invalid-feedback">{errorText}</div>}
              </>
            )}
          </>
        )}
      </>
    );
  },
);


export const DInput = ({ placeholder = "00.00",onFocus=null,size='5',dataSlots='0',dataExp='0', mask = null, required = false, className = null, limit = 1000, label = null, type = 'text', errorData = [], multiline = false, name = null, defaultData = {}, onChange = null, ...props }) => {

  const ref = useRef();
  const [back,setBack] = useState(false)
  const [value, setValue] = useState(placeholder);
  const [errorText, setErrorText] = useState('');

  className = className === false ? '' : `form-control ${className}`;

  useEffect(() => {
    if (defaultData?.[name]) {
      setValue(defaultData[name]);
      if(ref.current){
        ref.current.value = defaultData[name];
        handleInput(ref.current)
      }
    }
  }, [defaultData,ref]);

  useEffect(() => {
    if (errorData?.[name]) {
      setErrorText(errorData[name]);
    } else {
      setErrorText('');
    }
  }, [errorData]);

  function currencyFormat(num) {
    return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }

  const handleChange = ({ target }) => {
    const { value: val = '' } = target;
    if (val.length <= limit) {
      setValue(type === 'number' ? parseFloat(val) : val);
      onChange?.({ name, value: type === 'number' ? val : val });
    }
  }

  const handleBlur = () => {
    const el = ref.current;
    return el.value === 'placeholder' && (el.value = "")
  };
  const handleKeydown = (e) => {
     setBack(e.key === 'Backspace')
  };
  const clean = (input, exp) => {
    const el = ref.current;
    const pattern = placeholder;
    const slots = new Set(el.dataset.slots || '0')
    const accept = new RegExp(el.dataset.accept || '\\d', 'g')
    input = input.match(accept) || [];
    return Array.from(pattern, c =>
      (input[0] === c || slots.has(c)) ? input.shift() || c : c,
    );
  }
  const handleFocus=(e)=>{
    handleInput(e)
    onFocus?.(e)
  }
  const handleInput = (e) => {
    const exp = e.data;
    const el = ref.current;
    const pattern = placeholder, slots = new Set(el.dataset.slots || '0'), expThis = '0', prev = (j => Array.from(pattern, (c, i) => slots.has(c) ? j = i + 1 : j))(0), first = [...pattern].findIndex(c => slots.has(c)), accept = new RegExp(el.dataset.accept || '\\d', 'g');
    let [i, j] = [el.selectionStart, el.selectionEnd].map(i => {
      i = clean(el.value.slice(0, i)).findIndex(c => slots.has(c));
      return i < 0 && exp != expThis ? prev[prev.length - 1] : back ? prev[i - 1] || first : i;
    });
    el.value= clean(el.value, exp).join``
    i = exp == expThis ? ++i : i;
    j = exp == expThis ? ++j : j;
    el.setSelectionRange(i, j);
    setBack(false);
  };

  return (
    <>
      {label !== false && <label>{label}</label>}
      <input ref={ref} placeholder={placeholder} onChange={handleChange} onKeyDown={handleKeydown} onFocus={handleFocus}
             onBlur={handleBlur}  onInput={handleInput} name={name} data-exp={dataExp} data-slots={dataSlots} data-accept='\d'
             size={size} />
    </>
  );
};
export const TextArea = (props) => <Input multiline {...props} />;

export const ReCaptcha = forwardRef(({ name = null, onChange }, ref) => {
  const handleChange = (val) => {
    onChange?.({ name, value: val });
  };
  return <ReCAPTCHA ref={ref} sitekey={CAPTCHA_KEY} onChange={handleChange} />;
});

export const Select = forwardRef(
  (
    {
      className = '',
      label = null,
      emptyText = '-select-',
      fullWidth = false,
      name = null,
      defaultData = {},
      onChange = null,
      optionClassName = '',
      options = [],
      showSelect = true,
      ...props
    },
    ref,
  ) => {
    const [value, setValue] = useState('');

    className = fullWidth ? className + ' ' + 'full-width' : className;
    useEffect(() => {
     
      if (defaultData?.[name]) {
        
        setValue(defaultData[name]);
      }
    }, [defaultData]);

    const handleChange = ({ target }) => {
      const { value: val = null } = target;
      setValue(val);
      onChange?.({ name, value: val });
    };
    return (
      <>
        <label>{label}</label>
        <select
          ref={ref}
          className={`form-control ${className}`}
          name={name}
          value={value}
          onChange={handleChange}
          {...props}
        >
        {showSelect && <option value={null}>{emptyText} </option>}  
          {options.length > 0 &&
            options.map(({ value, text }) => (
              <option className={optionClassName} key={text} value={value}>
                {text}
              </option>
            ))}
        </select>
      </>
    );
  },
);

export const AutoCompleteInput = ({
  valid = false,
  defaultData = {},
  className = null,
  clearSelect = false,
  fullWidth = false,
  wrapperProps = {},
  items = [],
  min = 1,
  name = null,
  onChange = null,
  onSelect = null,
  ...props
}) => {
  const [options, setOptions] = useState(items);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState();
  wrapperProps['className'] =
    wrapperProps['className'] ?? ' ' + ' ' + (fullWidth ? 'full-width' : '');
  useEffect(() => {
   
    if (defaultData?.[name]) {
      setValue(defaultData[name]);
    }
  }, [defaultData]);
  const handleChange = ({ target }) => {
    const { value = '' } = target;
    let opt = items.filter(({ label }) => label.toLowerCase().indexOf(value.toLowerCase()) > -1);
    setOptions(opt);
    setValue(value);
    if (value.trim() && value.length > min && opt.length) {
      setOpen(true);
    } else {
      if (open) {
        setOpen(false);
      }
    }
    if (!valid) {
      onChange?.({ name, value: value });
    }
  };
  const handleSelect = (val) => {
    setOpen(false);
    if (!val) {
      val = value;
    }
    onChange?.({ name, value: val });
    onSelect?.({ name, value: val });
    if (clearSelect) {
      val = '';
    }
    setValue(val);
  };
  let menuStyle = {zIndex:100}
  return (
    <Autocomplete
      getItemValue={(item) => item.label}
      items={options}
      open={open}
      menuStyle={{zIndex:100}}
      placeholder={props.placeholder}
      wrapperProps={wrapperProps}
      inputProps={{
        placeholder: props.placeholder,
        className :`control ${className}`,
        onBlur: () => setOpen(false),
      
      }}
      wrapperStyle={{zIndex:1000}}
      renderItem={(item, isHighlighted) => (
        <div
          className={'font-fixer sizer'}
          key={item.label}
          style={{ background: isHighlighted ? 'lightgray' : 'white'}}
        >
          {item.label}
        </div>
      )}
      value={value}
      onChange={handleChange}
      onSelect={handleSelect}
    />
  );
};

export const RadioButton = ({
  label = null,
  successValue = '',
  checked = null,
  name = null,
  onChange = null,
  customLabel = null,
  defaultData = {},
  ...props
}) => {
  const ref = useRef();

  const handleChange = ({ target: { checked } }) => {
    onChange?.({ name, value: checked ? successValue : false });
  };
  useEffect(() => {
    if (defaultData?.[name]) {
      let check = defaultData[name] === successValue;
      if (check) {
        ref.current.checked = check;
        handleChange({ target: { checked: check } });
      }
    }
  }, [defaultData]);

  useEffect(() => {
    if (checked !== null) {
      ref.current.checked = checked;
      handleChange({ target: { checked } });
    }
  }, [checked]);

  return (
    <label className="selected-label" style={{width : customLabel && '100%'}}>
      <input ref={ref} type="radio" onChange={handleChange} name={name} />
      {label}
      {props.children}
    </label>
  );
};

export const Checkbox = ({
  successValue = true,
  label = '',
  name = null,
  className = '',
  defaultData = {},
  onChange = null,
}) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
  
    if (defaultData['value'] === false) {
      setChecked(false);
   
    }else{
      if (defaultData?.[name]) {
        setChecked(defaultData[name]);
     
      }
    }
    
  }, [defaultData]);

  const handleChange = () => {
    let check = !checked;
    console.log(check);
    setChecked(check);
    onChange?.({ name, value: check && successValue });
  };

  return (
    <>
      <input
        className={`tickadjust styled-checkbox w-auto ${className}`}
        type="checkbox"
        onChange={handleChange}
        checked={checked}
        name={name}
        value={successValue}
      />
      <label onClick={handleChange}>{label}</label>
    </>
  );
};

export const Pagination = ({
  count = 0,
  setCurrentPage,
  currentPage,
  maxPageNumLimit,
  minPageNumLimit,
  setMaxPageNumLimit,
  setMinPageNumLimit,
}) => {
  const [pageNumberLimt] = useState(3);
  const [itemsPerPage] = useState(10);

  const [pageCount, setPageCount] = useState();

  let pageArr = [];
  for (let i = 1; i <= Math.ceil(count / itemsPerPage); i++) {
    pageArr.push(i);
  }
  useEffect(() => {
    console.log(Math.ceil(1 / 2));
    console.log(pageArr);
  }, [pageArr]);
  const handlePage = (val) => {
    setCurrentPage(val);
  };
  let btn = null;
  const handleNext = () => {
    if (currentPage === pageArr[pageArr.length - 1]) {
      return;
    }
    setCurrentPage(pageCount === currentPage ? currentPage : currentPage + 1);
    if (currentPage + 1 > maxPageNumLimit) {
      setMaxPageNumLimit(maxPageNumLimit + pageNumberLimt);
      setMinPageNumLimit(minPageNumLimit + pageNumberLimt);
    }
  };
  const handlePrev = () => {
    if (currentPage === pageArr[0]) {
      return;
    }
    setCurrentPage(currentPage === 1 ? 1 : currentPage - 1);
    if ((currentPage - 1) % pageNumberLimt === 0) {
      setMaxPageNumLimit(maxPageNumLimit - pageNumberLimt);
      setMinPageNumLimit(minPageNumLimit - pageNumberLimt);
    }
  };

  return (
    <>
      <p class="page-prev-next-icon" title="Previous Page" onClick={() => handlePrev()}>
        <i class="fas fa-arrow-circle-left"></i>
      </p>
      {pageArr &&
        pageArr.map((num, i) => {
          if (num < maxPageNumLimit + 1 && num > minPageNumLimit) {
            return (
              <p
                key={num}
                class={currentPage === num ? 'active' : ''}
                onClick={() => {
                  handlePage(num);
                }}
              >
                {num}
              </p>
            );
          } else {
            return null;
          }
        })}

      <p class="page-prev-next-icon" title="Next Page" onClick={() => handleNext()}>
        <i class="fas fa-arrow-circle-right"></i>
      </p>
    </>
  );
};


export const DecimalInput  =   forwardRef(({
 
  required = false,
  className = null,
  limit = 1000,
  label = null,
  type = 'text',
  errorData = [],

  name = null,
  defaultData = {},
  onChange = null,
  ...props
},ref) => {
  const [value, setValue] = useState();
    const [errorText, setErrorText] = useState('');
    // const [selection,setSelection]  = useState(0);
    className = className === false ? '' : `form-control ${className}`;
    useEffect(() => {
      if (defaultData?.[name]) {
        setValue(defaultData[name]); 
      }
    }, [defaultData]);

    useEffect(() => {
      if (errorData?.[name]) {
        setErrorText(errorData[name]);
      } else {
        setErrorText('');
      }
    }, [errorData]);
  const handleChange = ({target}) => {
    // let selection = 0;
    // selection = e.target.selectionStart
    // let val = e.target.value;
    // val = val.replace(/([^0-9.]+)/, "");
    // val = val.replace(/^(0|\.)/, "");
    // const match = /(\d{0,7})[^.]*((?:\.\d{0,2})?)/g.exec(val);
    // const value = match[1] + match[2];
    // e.target.value = value;
    const {value : val = ''} =target;
    // setValue(value)
    // if (value.length > 0) {
    //   e.target.value = Number(value).toFixed(2);
    //  // e.target.setSelectionRange(selection, selection);
    //   setValue(Number(value).toFixed(2))
    //   onChange?.({ name, value:Number(value).toFixed(2)  });
    // }
    if (val.length <= limit) {
      setValue(type === 'number' ? parseFloat(val) : val);
      onChange?.({ name, value: type === 'number' ? val : val });
    }
  };
  const handleBlur = ({target}) => {
    const {value : val = ''} =target;
    setValue(Number(val).toFixed(2))
    // onChange?.({ name, value: type === 'number' ? val : val });
  }
  return (
    <>
    {label !== false && <label>{label}</label>}
    <input
                  required={required}
                  type={type}
                  ref={ref}
                  className={`${className} ${errorText && 'is-invalid'}`}
                  name={name}
                  value={value}
                  min={1}
                  onChange={handleChange}
                  {...props}
                  onBlur={handleBlur}
                />
                {errorText && <div className="invalid-feedback">{errorText}</div>}
    </>


  )
}
)