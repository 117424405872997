import { MALE_USER_IMG } from '../../config/constant/imageConstant.js';
import { useDispatch, useSelector } from 'react-redux';
import { chatProfileToggle, socketSelector } from '../../redux/slicers/socketSlicer.js';
import React from 'react';
import { ImageLoader } from '../../helpers/projectHelper.js';

const ChatProfile = () => {
  const { currentChat: { to_details = {} } } = useSelector(socketSelector);
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(chatProfileToggle())
  }
  return (
    <div className='messagecard3 patient-details-left-card'>
      <div className='rightcardpropic'>
        <img src={ImageLoader(to_details?.user_img_id)} alt='' />
        <div className='propicclosesection' onClick={handleClick}>
              <span>
                 <svg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <circle cx='20' cy='20' r='20' fill='white' />
                    <path fillRule='evenodd' clipRule='evenodd'
                          d='M18.4531 20L10.4766 27.9765L12.0236 29.5235L20.0001 21.547L27.9766 29.5235L29.5236 27.9765L21.5471 20L29.5236 12.0235L27.9766 10.4765L20.0001 18.453L12.0236 10.4765L10.4766 12.0235L18.4531 20Z'
                          fill='#0E7D94' />
                 </svg>
              </span>
        </div>
      </div>
      <div className='rightcard-basic-details'>
        <div className='rightcard-basic-details-heading'>
          <h2>Contact Info</h2>
          <p />
        </div>
        <div className='rightcard-basic-details-row-fixed'>
          <h6>Email</h6>
          <p className='text-lowercase'>{to_details?.user_email}</p>
        </div>
        {/*<div className="rightcard-basic-details-row-fixed">*/}
        {/*   <h6>STATE</h6>*/}
        {/*   <p className="text-lowercase"></p>*/}
        {/*</div>*/}
        {/*<div className="rightcard-basic-details-row-fixed">*/}
        {/*   <h6>City</h6>*/}
        {/*   <p>Dallas</p>*/}
        {/*</div>*/}
        {/*<div className="rightcard-basic-details-row-fixed">*/}
        {/*   <h6>Phone</h6>*/}
        {/*   <p>+1 555 55555 555 </p>*/}
        {/*</div>*/}
        {/*<div>*/}
        {/*  <div className="list-group list-group-flush">*/}
        {/*    <span className="list-group-item list-group-item-action" ><i className={"fas fa-trash fa-sm mr-2 text-dark"}/>Delete Chat</span>*/}
        {/*    <span className="list-group-item list-group-item-action" ><i className={"fas fa-ban fa-sm mr-2 text-dark"}/>Block</span>*/}
        {/*    <span className="list-group-item list-group-item-action" ><i className={"fa fa-volume-off fa-sm mr-2 text-dark"}/>Mute Conversation</span>*/}
        {/*    <span className="list-group-item list-group-item-action" ><i className={"fas fa-info-circle fa-sm mr-2 text-dark"}/>Something's wrong</span>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
    </div>
  );
};

export default ChatProfile;
