import { lazy } from 'react';
import {

  CUSTOMER_SUPPORT,
  CUSTOMER_SUPPORT_DASHBOARD,
  CUSTOMER_SUPPORT_EMPLOYER_LIST,
  CUSTOMER_SUPPORT_JOB_SEEKER_LIST,
  CUSTOMER_SUPPORT_LOGIN,
  CUSTOMER_SUPPORT_MANAGE_CHAT,
  CUSTOMER_SUPPORT_MANAGE_EMPLOYER,
  CUSTOMER_SUPPORT_MANAGE_JOBS,
  CUSTOMER_SUPPORT_MANAGE_JOB_SEEKER,
  CUSTOMER_SUPPORT_MANAGE_TICKETING,
  CUSTOMER_SUPPORT_MANAGE_JOBS_INFO,
  CUSTOMER_SUPPORT_PENDING_EMPLOYER,
  CUSTOMER_SUPPORT_PENDING_JOB_SEEKER,
  CUSTOMER_SUPPORT_PENDING_JOB,
  CUSTOMER_SUPPORT_FREETRAIL_USERS,
  CUSTOMER_SUPPORT_SUBSCRIBED_USERS,
  CUSTOMER_SUPPORT_PROTECTED_LIST,
  CUSTOMER_SUPPORT_INDIVIDUAL_PROTECTED_LIST,
  CUSTOMER_SUPPORT_CONTRACT_AGREEMENT,
  CUSTOMER_SUPPORT_MANAGE_USERS,
  CUSTOMER_SUPPORT_AGREEMENT_USERS,
  CUSTOMER_SUPPORT_DELETE_USERS,
  CUSTOMER_SUPPORT_REPORTED_JOBS, CUSTOMER_SUPPORT_PENDING_REPORTED_JOBS,
  CUSTOMER_SUPPORT_INDIVIDUAL_JOB_LIST,
  CUSTOMER_SUPPORT_INDIVIDUAL_JOB_APPLICANTS,
  CUSTOMER_SUPPORT_INDIVIDUAL_OFFER_LIST,
  CUSTOMER_SUPPORT_INDIVIDUAL_HIRED_LIST,
  CUSTOMER_SUPPORT_STAFF_OFFER_LIST,
  CUSTOMER_SUPPORT_STAFF_HIRED_LIST,
  CUSTOMER_SUPPORT_PAYMENT_LIST,
  CUSTOMER_SUPPORT_TRIAL_CHARGE_LIST,
} from '../constant/routePathConstant';
import { RedirectToCustomerSupportDashboard } from '../../components/customerSupport/common/redirect';

const Dashboard = lazy(() => import('../../components/customerSupport/userDashboard'));
const CustomerSupportLogin = lazy(() => import('../../components/customerSupport/login'));
const ManageJobs = lazy(() => import('../../components/customerSupport/manageJobs'));
const ManageEmployer = lazy(() => import('../../components/admin/manageEmployer'));
const ManageEmployerList = lazy(() =>
  import('../../components/customerSupport/manageEmployerList'),
);
const ManageChat = lazy(() => import('../../components/customerSupport/manageChat'));
const ManageJobSeekerList = lazy(() =>
  import('../../components/customerSupport/manageJobSeekerList'),
);

const ManageJobSeeker = lazy(() => import('../../components/customerSupport/manageJobSeeker'));
const ManageTicker = lazy(() => import('../../components/customerSupport/manageTicket'));
const ManageJobInfo = lazy(() => import('../../components/customerSupport/manageJobInfo'));
const PendingJobs = lazy(() => import('../../components/customerSupport/PendingJobs'));
const PendingEmployers = lazy(() => import('../../components/customerSupport/pendingEmp'));
const PendingJobseekers = lazy(() => import('../../components/customerSupport/pendingJobseeker'));
const FreeTialEmp = lazy(() => import('../../components/customerSupport/freeTrialEmployer'));
const SubscribedUsers = lazy(() => import('../../components/customerSupport/subscribedUsers'));
const ProtectedList = lazy(() => import('../../components/customerSupport/protectedUsersList'))
const IndividualProtectedList = lazy(() => import('../../components/customerSupport/individualProtectedList'));
const ContractAgreement = lazy(() => import('../../components/customerSupport/contractAgreement'))
const ManageUsersConfig = lazy(() => import('../../components/customerSupport/manageUsersConfig'))
const ManageAgreedUsersConfig = lazy(() => import('../../components/customerSupport/agreementList'))
const ManageDeleteUsersConfig = lazy(() => import('../../components/customerSupport/deleteRequest'))
const ReportedJobsComponent = lazy(() => import('../../components/customerSupport/reportedJobs'))
const IndividualJobList = lazy(() => import('../../components/customerSupport/_components/jobsList'))
const IndividualJobApplicantsList = lazy(() => import('../../components/customerSupport/_components/applicantsList'))
const OfferList = lazy(() => import('../../components/customerSupport/_components/offerList'))
const HiredList = lazy(() => import('../../components/customerSupport/_components/hiredList'))
const StaffOfferList = lazy(() => import('../../components/customerSupport/_components/staffOfferList'))
const StaffHiredList = lazy(() => import('../../components/customerSupport/_components/staffHiredList'))
const EmpPaymentList = lazy(() => import('../../components/customerSupport/_components/paymentsList'))
const UserAgreementList = lazy(() => import('../../components/customerSupport/_components/userAgreementList'))
const CUSTOMER_SUPPORT_ROUTES = [
  { component: CustomerSupportLogin, path: CUSTOMER_SUPPORT_LOGIN },
  { component: RedirectToCustomerSupportDashboard, path: CUSTOMER_SUPPORT },
  { component: Dashboard, path: CUSTOMER_SUPPORT_DASHBOARD },
  { component: ManageJobs, path: CUSTOMER_SUPPORT_MANAGE_JOBS },
  { component: ManageEmployer, path: CUSTOMER_SUPPORT_MANAGE_EMPLOYER },
  { component: ManageEmployerList, path: CUSTOMER_SUPPORT_EMPLOYER_LIST },
  { component: ManageChat, path: CUSTOMER_SUPPORT_MANAGE_CHAT },
  { component: ManageJobSeekerList, path: CUSTOMER_SUPPORT_JOB_SEEKER_LIST },
  { component: ManageJobSeeker, path: CUSTOMER_SUPPORT_MANAGE_JOB_SEEKER },
  { component: ManageTicker, path: CUSTOMER_SUPPORT_MANAGE_TICKETING },
  { component: ManageJobInfo, path: CUSTOMER_SUPPORT_MANAGE_JOBS_INFO },
  { component: PendingJobs, path: CUSTOMER_SUPPORT_PENDING_JOB },
  { component: PendingEmployers, path: CUSTOMER_SUPPORT_PENDING_EMPLOYER },
  { component: PendingJobseekers, path: CUSTOMER_SUPPORT_PENDING_JOB_SEEKER },
  { component: FreeTialEmp, path: CUSTOMER_SUPPORT_FREETRAIL_USERS },
  { component: SubscribedUsers, path: CUSTOMER_SUPPORT_SUBSCRIBED_USERS },
  {component: ProtectedList , path : CUSTOMER_SUPPORT_PROTECTED_LIST},
  {component: IndividualProtectedList, path : CUSTOMER_SUPPORT_INDIVIDUAL_PROTECTED_LIST},
  {component :ContractAgreement , path : CUSTOMER_SUPPORT_CONTRACT_AGREEMENT},
  {component :ManageUsersConfig , path : CUSTOMER_SUPPORT_MANAGE_USERS},
  {component :ManageAgreedUsersConfig , path : CUSTOMER_SUPPORT_AGREEMENT_USERS},
  {component :ManageDeleteUsersConfig , path : CUSTOMER_SUPPORT_DELETE_USERS},
  {component :ReportedJobsComponent , path : CUSTOMER_SUPPORT_REPORTED_JOBS},
  {component :ReportedJobsComponent , path : CUSTOMER_SUPPORT_PENDING_REPORTED_JOBS},
  {component :IndividualJobList , path : CUSTOMER_SUPPORT_INDIVIDUAL_JOB_LIST},
  {component :IndividualJobApplicantsList , path : CUSTOMER_SUPPORT_INDIVIDUAL_JOB_APPLICANTS},
  {component :OfferList , path : CUSTOMER_SUPPORT_INDIVIDUAL_OFFER_LIST},
  {component :HiredList , path : CUSTOMER_SUPPORT_INDIVIDUAL_HIRED_LIST},
  {component :StaffOfferList , path : CUSTOMER_SUPPORT_STAFF_OFFER_LIST},
  {component :StaffHiredList , path : CUSTOMER_SUPPORT_STAFF_HIRED_LIST},
  {component :EmpPaymentList , path : CUSTOMER_SUPPORT_PAYMENT_LIST},
  {component : UserAgreementList ,path : CUSTOMER_SUPPORT_TRIAL_CHARGE_LIST}
];

export default CUSTOMER_SUPPORT_ROUTES;
