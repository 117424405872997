import {
  TIME_SHEET_GALLERY_TABLE,
  TIME_SHEET_HOME,
  TIME_SHEET_WEEKLY_TABLE,
  TIME_SHEET_WITH_DAY,
  TIME_SHEET_WITH_DAY_IMAGE,
} from '../constant/routePathConstant';
import TrackerHome from '../../components/timeSheet/trackerHome';
import WeaklyTable from '../../components/timeSheet/WeaklyTable';
import Gallery from '../../components/timeSheet/Gallery';
import TimerTrackerWithDay from '../../components/timeSheet/TImetrackerwithday';
import GalleryWithDay from '../../components/timeSheet/Gallerywithday';

const TIME_SHEET_ROUTES = [
  // { component: RedirectToTimeSheetDashboard, path: TIME_SHEET },
  { component: TrackerHome, path: TIME_SHEET_HOME },
  { component: WeaklyTable, path: TIME_SHEET_WEEKLY_TABLE },
  { component: Gallery, path: TIME_SHEET_GALLERY_TABLE },
  { component: TimerTrackerWithDay, path: TIME_SHEET_WITH_DAY },
  { component: GalleryWithDay, path: TIME_SHEET_WITH_DAY_IMAGE },
];

export default TIME_SHEET_ROUTES;
