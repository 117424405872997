import React from 'react';
import TSAuth from './auth';
import TSAdmin from './admin';
import { getUserData } from '../../helpers/projectHelper';

function TicketingSystem() {
  const {user_type = null} = getUserData()

  return (
    <>
      {user_type === 'job_seeker' || 'employer' ? <TSAuth /> : <TSAdmin/>}
    </>
  );
}

export default TicketingSystem;
