import { lazy } from 'react';
import {
    MARKETING,
    MARKETING_ANNOUCEMENT,
    MARKETING_MANAGE_NOTIFICATIONS,
    MARKETING_CREATE_EVENT,
    MARKETING_UPDATE_EVENT_ID,
    MARKETING_AFFILIATE,
    MARKETING_AFFILIATE_SIGNUP
} from '../constant/routePathConstant';
import { RedirectToMarketingDashboard } from '../../components/Marketing/common/redirect';

const Announcement = lazy(() => import('../../components/Marketing/Announcement'));
const ManageNofication = lazy(() => import('../../components/Marketing/ManageNotification'));
const CreateEvent = lazy(() => import('../../components/Marketing/createEvent'));
const Affiliate = lazy(()=>import('../../components/Marketing/Affiliate'));
const AffiliateSignup = lazy(()=>import('../../components/Marketing/AffiliateSignup'));


const MARKETING_ROUTES = [
    { component: RedirectToMarketingDashboard, path: MARKETING },
    { component: Announcement, path: MARKETING_ANNOUCEMENT },
    { component: Affiliate, path: MARKETING_AFFILIATE },
    { component: AffiliateSignup, path: MARKETING_AFFILIATE_SIGNUP},
    { component: ManageNofication, path: MARKETING_MANAGE_NOTIFICATIONS },
    { component: CreateEvent, path: MARKETING_CREATE_EVENT },
    { component: CreateEvent, path: MARKETING_UPDATE_EVENT_ID },
];

export default MARKETING_ROUTES;
