import { lazy } from 'react';
import PasswordActivation from '../../components/website/login/passwordActivate';
import {
  ACCOUNT_ACTIVATION,
  FORGET_PASSWORD,
  HOMEPAGE,
  LOGIN,
  RESET_PASSWORD,
  SEE_ALL_SPECIALIZATION,
  SIGN_UP_EMPLOYER,
  SIGN_UP_JOBSEEKER,
  SIGNUP,
  WEBSITE_RELOGIN,
  ACTIVATE_PASSWORD,
  REDIRECT_QUERY_ROUTE,
  ACCOUNT_ACTIVATION_FOR_JOBSEEKER,
  ACCOUNT_ACTIVATION_FOR_EMPLOYER,
  THANKYOU_JOBSEEKER,
  THANKYOU_EMPLOYER,
  WEBSITE_SUBSCRIPTION_PRICING_PAGE,
  TERMS_AND_USE,
  PRIVACY,
  SIGN_UP_B,
} from '../constant/routePathConstant.js';
import { RedirectToSuperAdminDashboard } from '../../components/common/redirectSuperAdmin';

const Login = lazy(() => import('../../components/website/login/login.js'));
const SignUp = lazy(() => import('../../components/website/signUp/signup.js'));
const JobSeekerSignUp = lazy(() => import('../../components/website/signUp/signupJobSeeker.js'));
const EmployerSignUp = lazy(() => import('../../components/website/signUp/signupEmployer.js'));
const ForgotPassword = lazy(() => import('../../components/website/login/forgetPassword.js'));
const ResetPassword = lazy(() => import('../../components/website/login/resetPassword.js'));
const AccountActivation = lazy(() => import('../../components/website/login/activation.js'));
const SeeAllSpecialization = lazy(() => import('../../components/website/seeAllSpecialization.js'));
const ReLogin = lazy(() => import('../../components/website/relogin.js'));
const RedirectQuery = lazy(() => import('../../components/website/redirectPage'));
const SubscriptionPricing = lazy(() => import('../../components/employer/subscricption-pricing'));
const ThankYouPage = lazy(() => import('../../components/website/signUp/thankyouPage'))
const ThankYouPageJobseeker = lazy(() => import('../../components/website/signUp/thankYouJobseeker'))
const TermsPage = lazy(() => import('../../components/website/termAndUse'))
const PrivacyPolicy = lazy(() => import('../../components/website/privacy'))
const SignUpB = lazy(() => import('../../components/website/signUp/signup-b.js'));
const WEBSITE_ROUTES = [
  { component: RedirectToSuperAdminDashboard, path: HOMEPAGE },
  { component: RedirectToSuperAdminDashboard, path: LOGIN },
  { component: SignUp, path: SIGNUP },
  { component: SignUpB, path: SIGN_UP_B },
  { component: JobSeekerSignUp, path: SIGN_UP_JOBSEEKER },
  { component: EmployerSignUp, path: SIGN_UP_EMPLOYER },
  { component: ForgotPassword, path: FORGET_PASSWORD },
  { component: ResetPassword, path: RESET_PASSWORD },
  { component: AccountActivation, path: ACCOUNT_ACTIVATION_FOR_JOBSEEKER },
  { component: AccountActivation, path: ACCOUNT_ACTIVATION_FOR_EMPLOYER },
  { component: SeeAllSpecialization, path: SEE_ALL_SPECIALIZATION },
  { component: ReLogin, path: WEBSITE_RELOGIN },
  { component: PasswordActivation, path: ACTIVATE_PASSWORD },
  { component: RedirectQuery, path: REDIRECT_QUERY_ROUTE },
  { component: SubscriptionPricing, path: WEBSITE_SUBSCRIPTION_PRICING_PAGE },,
  { component:ThankYouPage, path :THANKYOU_EMPLOYER},
  { component:ThankYouPageJobseeker, path :THANKYOU_JOBSEEKER},
  {component:TermsPage, path :TERMS_AND_USE},
  {component:PrivacyPolicy, path :PRIVACY}
];

export default WEBSITE_ROUTES;
