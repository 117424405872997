import React from 'react';
import logo from '../../../assets/images/admin/admin_image/logo.png';
import logout from '../../../assets/images/admin/admin_image/logout.png';
import { sessionDestroy } from '../../../helpers/projectHelper';
import { ADMIN_LOGIN } from '../../../config/constant/routePathConstant';

const Header = () => {
  return (
    <>
    
      <header class="docuchain-app-header">
      
          <button
                    className="navbar-toggler mobbutn"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapsibleNavbar"
                >
                    <span className="navbar-toggler-icon">
                        <svg
                            width="23"
                            height="20"
                            viewBox="0 0 23 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M1.23926 4.23096H21.5518C21.9833 4.23096 22.333 3.8812 22.333 3.44971V1.49658C22.333 1.06509 21.9833 0.715332 21.5518 0.715332H1.23926C0.807764 0.715332 0.458008 1.06509 0.458008 1.49658V3.44971C0.458008 3.8812 0.807764 4.23096 1.23926 4.23096ZM1.23926 12.0435H21.5518C21.9833 12.0435 22.333 11.6937 22.333 11.2622V9.30908C22.333 8.87759 21.9833 8.52783 21.5518 8.52783H1.23926C0.807764 8.52783 0.458008 8.87759 0.458008 9.30908V11.2622C0.458008 11.6937 0.807764 12.0435 1.23926 12.0435ZM1.23926 19.856H21.5518C21.9833 19.856 22.333 19.5062 22.333 19.0747V17.1216C22.333 16.6901 21.9833 16.3403 21.5518 16.3403H1.23926C0.807764 16.3403 0.458008 16.6901 0.458008 17.1216V19.0747C0.458008 19.5062 0.807764 19.856 1.23926 19.856Z"
                                fill="black"
                            />
                        </svg>
                    </span>
                </button>
          
        <a class="docuchain-header-logo" href=''>
          <img src={logo} height="75" />
        </a>
        {/* <a
          class="docuchain-sidebar-toggle"
          href="#"
          data-toggle="sidebar"
          aria-label="Hide Sidebar"
        ></a> */}

        {/* <!-- Navbar Right Menu--> */}
        <ul class="docuchain-app-nav">
          {/* <li class="dropdown">
            <a
              class="app-nav__item animated infinite swing"
              href="#"
              data-toggle="dropdown"
              aria-label="Show notifications"
            >
              <img src={notification} alt="notification" />
              <span>3</span>
            </a>
            <ul
              class="app-notification dropdown-menu dropdown-menu-right animated hiding"
              data-animation="bounceInDown"
            >
              <li class="app-notification__title">Notifications</li>
              <div class="app-notification__content">
                <li>
                  <a class="app-notification__item" href="javascript:void(0);">
                    <span class="app-notification__icon">
                      <img src={transporteViewGreen} />
                    </span>
                    <div>
                      <p class="app-notification__message">User</p>
                      <p class="app-notification__meta">Just Now</p>
                    </div>
                  </a>
                </li>
                <li>
                  <a class="app-notification__item" href="javascript:void(0);">
                    <span class="app-notification__icon">
                      <img src={transporteViewRed} />
                    </span>
                    <div>
                      <p class="app-notification__message">User</p>
                      <p class="app-notification__meta">5 min ago</p>
                    </div>
                  </a>
                </li>
                <li>
                  <a class="app-notification__item" href="javascript:void(0);">
                    <span class="app-notification__icon">
                      <img src={transporteViewYellow }/>
                    </span>
                    <div>
                      <p class="app-notification__message">User</p>
                      <p class="app-notification__meta">2 days ago</p>
                    </div>
                  </a>
                </li>
                <div class="app-notification__content">
                  <li>
                    <a class="app-notification__item" href="javascript:void(0);">
                      <span class="app-notification__icon">
                        <img src={transporteViewYellow} />
                      </span>
                      <div>
                        <p class="app-notification__message">User</p>
                        <p class="app-notification__meta">3 days ago</p>
                      </div>
                    </a>
                  </li>
                </div>
              </div>
              <li class="app-notification__footer">
                <a href="notification.html">See all Notifications.</a>
              </li>
            </ul>
          </li> */}
        
          <li>
            <a
              class="app-nav__item"
              href="#"
              data-toggle="modal"
              data-target="#logout"
              data-backdrop="static"
              aria-label="Show notifications"
            >
              <img src={logout}  alt="logout" onClick={() => sessionDestroy(ADMIN_LOGIN)} />
            </a>
          </li>
        </ul>
      </header>
    </>
  );
};

export default Header;
