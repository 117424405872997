import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  authTicketCreateApi,
  authTicketJobSeekerGetApi,
  authTicketEmployerGetApi,
  authTicketDeleteApi,
  authTicketUpdateApi,
  authTicketCommentApi,
} from '../../api/ticketingSystem/ticketingSystemApi';
import { FullSectionLoader } from '../common/loader.js';
import { jobSeekerSelector } from '../../redux/slicers/jobSeekerSlice';
import { ticketingSystemSelector } from '../../redux/slicers/ticketingSystemSlice';
import { fileUploadApi } from '../../api/jobSeeker/jobSeeker';
import { warningToast } from '../../helpers/projectHelper';
import moment from 'moment';
import { Input, TextArea, Select } from '../common/input';
import { USER_MODULE, ISSUE_PRIORITY } from '../../config/constant/projectConstant';
import { Link } from 'react-router-dom';

import Pagination from 'react-js-pagination';
import AddIcon from '@material-ui/icons/Add';
import Header from '../jobSeeker/common/header';

function TSAuth() {
  const dispatch = useDispatch();
  const [status, setStatus] = useState('open');
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState('');
  const userData = localStorage.getItem('userData');
  const userInfo = JSON.parse(userData);
  const user_type = userInfo?.user_type;
  const {
    authTicketCreate,
    authTicketFilterGet,
    authTicketDelete,
    authTicketUpdate,
    authTicketComment,
  } = useSelector(ticketingSystemSelector);
  const { filedata } = useSelector(jobSeekerSelector);
  const [loading, setLoading] = useState(true);
  const ticketList = authTicketFilterGet?.data?.data ?? [];
  const [cardInfo, setCardInfo] = useState();
  const [updateBtn, setUpdateBtn] = useState(false);
  const [state, setState] = useState(false);
  const [data, setData] = useState();
  const limit = 6;
  const [page, setPage] = useState(1);
  const [commentArr, setCommentArr] = useState([]);
  const [btn, setBtn] = useState('');
  const [ticketDays, setTicketDays] = useState('');
  const [ticketHours, setTicketHours] = useState('');
  const [ticketMin, setTicketMin] = useState('');

  const getTicketList = () => {
    const data = {
      user_id: userInfo?.user_id,
      limit: limit,
      skip: 0,
      issue_status: status,
    };
    if (user_type === 'employer') {
      dispatch(authTicketEmployerGetApi(data, setLoading));
    } else {
      dispatch(authTicketJobSeekerGetApi(data, setLoading));
    }
  };

  useEffect(() => {
    getTicketList();
  }, [status]);

  useEffect(() => {
    setCardInfo(ticketList[0] ?? []);
  }, [authTicketFilterGet]);

  useEffect(() => {
    const data = { comments: commentArr, _id: cardInfo?._id };
    dispatch(authTicketCommentApi(data, setLoading, getTicketList));
  }, [btn]);

  useEffect(() => {
    var date1 = moment(cardInfo?.created_time);
    var date2 = moment();
    setTicketDays(date2.diff(date1, 'days'));
    setTicketHours(date2.diff(date1, 'hours'));
    setTicketMin(date2.diff(date1, 'minutes'));
  }, [cardInfo]);

  useEffect(() => {
    let data = {
      issue_status: status,
      limit: limit,
      skip: page * limit - limit,
    };
    if (user_type === 'employer') {
      dispatch(authTicketEmployerGetApi(data, setLoading));
    } else {
      dispatch(authTicketJobSeekerGetApi(data, setLoading));
    }
  }, [page]);

  const handlePagination = (pageNumber) => {
    setPage(pageNumber);
  };

  const formData = { ...data };

  const handleChange = (e) => {
    const { name, value } = e;
    formData[name] = value;
    setData(formData);
  };

  const handleDoc = (e) => {
    // const files = e.target.files[0];
    // setFileName(files && files.name);
    // const fileReader = new FileReader();
    // fileReader.readAsDataURL(files);
    // fileReader.onload = () => {
    //   setFile(fileReader.result);
    // };
    // const formData = new FormData();
    // formData.append('file', files);
    // dispatch(fileUploadApi(formData));
  };

  const submitHandler = (value) => {
    setUpdateBtn(false);
    if (value === 'submit') {
      const datas = {
        user_full_nam: userInfo?.full_name,
        user_email: userInfo?.user_email,
        user_type: user_type === 'job_seeker' ? 'job_seeker' : 'employer',
        issue: formData.issue,
        issue_priority: formData.issue_priority,
        module: formData.module,
        tag: formData.tag,
        issue_img_id: filedata && filedata[0],
        user_id: userInfo.user_id,
      };
      if (datas.issue === '' || datas.user_id === '') {
        warningToast({ title: 'Invalid', msg: 'Please fill the required fields' });
      } else {
        dispatch(authTicketCreateApi(datas, setLoading, getTicketList));
      }
    } else {
      const commentData = {
        user_id: userInfo?.user_id,
        comment: formData.comment,
        attachment: filedata && filedata[0],
      };
      setCommentArr((prevData) => [...prevData, commentData]);
      setBtn(value);
    }
  };

  const handleModal = (value) => {
    if (value === 'followup') {
      if (state === false) {
        warningToast({ title: 'Invalid', msg: 'Please choose a ticket' });
      }
    } else if (value === 'edit') {
      setUpdateBtn(true);
    } else {
      if (state === false) {
        warningToast({ title: 'Invalid', msg: 'Please choose a ticket' });
      }
    }
  };

  const cardDetail = (e) => {
    setCardInfo(e);
    setState(true);
    setCommentArr(e?.comments);
  };

  const deleteHandler = () => {
    const data = {
      _id: cardInfo?._id,
    };
    dispatch(authTicketDeleteApi(data, setLoading, getTicketList));
  };

  const statusHandler = (value) => {
    if (value === 'open') {
      setStatus('open');
    } else if (value === 'closed') {
      setStatus('closed');
    } else {
      setStatus('inprogress');
    }
  };

  const updateHandler = (e) => {
    setUpdateBtn(false);
    e.preventDefault();
    const datas = {
      user_full_nam: formData.user_full_nam,
      user_email: formData.user_email,
      issue: formData.issue,
      issue_priority: formData.issue_priority,
      module: formData.module,
      tag: formData.tag,
      issue_img_id: filedata && filedata[0],
      user_id: userInfo.user_id,
      _id: cardInfo?._id,
    };
    dispatch(authTicketUpdateApi(datas, setLoading, getTicketList));
  };
  const convertDate = (ele) => {
    let date = '';
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    date += new Date(ele).toLocaleString('en-us', options);
    return date;
  };
  return (
    <div>
      <Header/>
      <div className="dashboard-parent">
        <div className="py-5 px-5" style={{ width: '100%' }}>
          <h1 className="TS-heading">Ticketing System :</h1>
          {loading === true ? (
            <FullSectionLoader />
          ) : (
            <div className="extension1 mt-0">
              <div className="tomCardBody">
                <div className="helpdesk-tab-content tab-menu-content">
                  <div className="tab-menu-title">
                    <div className="left-nav-menu">
                      <ul>
                        <li
                          id="open"
                          className={status === 'open' ? 'menu1 active' : 'menu1'}
                          onClick={() => statusHandler('open')}
                        >
                          <a>
                            <p>open</p>
                          </a>
                        </li>
                        <li
                          id="inprogress"
                          className={status === 'inprogress' ? 'menu3 active' : 'menu3'}
                          onClick={() => statusHandler('inprogress')}
                        >
                          <a>
                            <p>In progress</p>
                          </a>
                        </li>
                        <li
                          id="closed"
                          className={status === 'closed' ? 'menu2 active' : 'menu2'}
                          onClick={() => statusHandler('closed')}
                        >
                          <a>
                            <p>closed</p>
                          </a>
                        </li>
                      </ul>
                    </div>
                    {/* <div className="right-nav-menu">
                      <ul>
                        <li>
                          {status === 'closed' || status === 'inprogress' ? null : (
                            <button
                              type="button"
                              class="commonBtn"
                              data-toggle="modal"
                              data-target="#exampleModal"
                              onClick={() => setUpdateBtn(false)}
                              disabled
                            >
                              Create Ticket
                            </button>
                          )}
                        </li>
                      </ul>
                    </div> */}
                  </div>

                  <div className="tab-menu-content-section">
                    <div id="pcontent-1">
                      {ticketList?.length !== 0 ? (
                        <div className="row">
                          <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12">
                            <div className="d-flex justify-content-between flex-wrap">
                              {ticketList?.map((e, i) => {
                                return (
                                  <div className="helpdesk-leftcard" onClick={() => cardDetail(e)}>
                                    <div className="helpdesk-leftcard-row1">
                                      <div className="helpdesk-leftcard-row1-left">
                                        <label>Ticket # : </label>
                                        <span>{e.ticket_id}</span>
                                      </div>
                                      <div className="helpdesk-leftcard-row1-left">
                                        <label>date : </label>
                                        <span>{moment(e.created_time).format('DD:MM:yyyy')}</span>
                                      </div>
                                    </div>
                                    <div className="helpdesk-leftcard-row1">
                                      <div className="helpdesk-leftcard-row1-left">
                                        <label>Priority : </label>
                                        <span>{e.issue_priority}</span>
                                      </div>
                                      <div className="helpdesk-leftcard-row1-left">
                                        <label>status : </label>
                                        <span
                                          className={
                                            status === 'open'
                                              ? 'opened-bg'
                                              : status === 'closed'
                                              ? 'closed-bg'
                                              : 'inprogress-bg'
                                          }
                                        >
                                          {e.issue_status}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="helpdesk-leftcard-row2">
                                      <label>Feedback/ Complaint : </label>
                                      <p>{e.issue}</p>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>

                          <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12">
                            <div className="helpdesk-rightcard">
                              <div className="helpdesk-rightcard-heading">
                                <h5 style={{ margin: '0', color: 'white', fontSize: '16px' }}>
                                  ticket activity system
                                </h5>
                              </div>
                              <div className="helpdesk-rightcard-details">
                                <div className="helpdesk-rightcard-details-row">
                                  <label>status :</label>
                                  <span
                                    className={
                                      status === 'open'
                                        ? 'opened-bg'
                                        : status === 'closed'
                                        ? 'closed-bg'
                                        : 'inprogress-bg'
                                    }
                                  >
                                    {cardInfo?.issue_status}
                                  </span>
                                </div>
                                <div className="helpdesk-rightcard-details-row">
                                    <label>Dispute Raised By :</label>
                                    <span>{cardInfo?.employer_id?.full_name}</span>
                                  </div>
                                  <div className="helpdesk-rightcard-details-row">
                                    <label>Staff Name :</label>
                                    <span>{cardInfo?.user_id?.full_name}</span>
                                  </div>
                                  <div className="helpdesk-rightcard-details-row">
                                    <label>Job Position :</label>
                                    <span>{cardInfo?.job_id?.job_title}</span>
                                  </div>
                                  <div className="helpdesk-rightcard-details-row">
                                    <label>Salary Amount:</label>
                                    <span>{cardInfo?.invoice_id?.salary_amount} /hr</span>
                                  </div>
                                  <div className="helpdesk-rightcard-details-row">
                                    <label>Work Hours :</label>
                                    <span>{cardInfo?.invoice_id?.work_hours} hours</span>
                                  </div>
                                  <div className="helpdesk-rightcard-details-row">
                                    <label>Billing From :</label>
                                    <span>{convertDate(cardInfo?.invoice_id?.billing_from)}</span>
                                  </div>
                                  <div className="helpdesk-rightcard-details-row">
                                    <label>Billing To :</label>
                                    <span>{convertDate(cardInfo?.invoice_id?.billing_to)}</span>
                                  </div>
                                  <div className="helpdesk-rightcard-details-row">
                                    <label>Amended Working Hours by employer :</label>
                                    <span>{cardInfo?.invoice_id?.work_hours_changed_by_employer}{' '}hours</span>
                                  </div>
                                  <div className="helpdesk-rightcard-details-row">
                                    <label>Amended Working Hours by employer :</label>
                                    <span>{cardInfo?.invoice_id?.work_hours_changed_by_employee}{' '}hours</span>
                                  </div>
                                  <div className="helpdesk-rightcard-details-row">
                                    <label>Created on :</label>
                                    <span>
                                      {moment(cardInfo?.created_time).format('DD:MM:yyyy')}
                                    </span>
                                  </div>
                                <div className="helpdesk-rightcard-details-row">
                                  <label>Ticket Duration :</label>
                                  {ticketHours >= 24 ? (
                                    <span>{ticketDays} Days ago</span>
                                  ) : ticketHours <= 1 ? (
                                    <span>{ticketMin} Minutes ago</span>
                                  ) : (
                                    <span>{ticketHours} Hours ago</span>
                                  )}
                                </div>
                                {cardInfo?.issue_img_id ? (
                                  <div className="attach">
                                    <Link
                                      onClick={(event) => {
                                        event.preventDefault();
                                        window.open(`https://authapi.virtualstaff.ph/api/v1/file/download/${cardInfo?.issue_img_id}`);
                                      }}
                                      target="blank"
                                    >
                                      <button
                                        type="button"
                                        className="commonBtn2 float-right mb-2"
                                        id="upBtn"
                                      >
                                        {' '}
                                        <label style={{ margin: '0' }} for="file1">
                                          <AddIcon />
                                          View Attachment
                                        </label>
                                      </button>
                                    </Link>
                                  </div>
                                ) : null}
                                <div className="helpdesk-rightcard-details-row">
                                  <label>Comments Box :</label>
                                </div>
                                {cardInfo?.comments?.length !== 0 ? (
                                  <div className="ticket-comment">
                                    {cardInfo?.comments?.map((e) => {
                                      return (
                                        <div className="comment-object">
                                          <div className="helpdesk-rightcard-details-row">
                                            <label>Name :</label>
                                            <span>{e.user_id.full_name}</span>
                                          </div>
                                          <div className="helpdesk-rightcard-details-row">
                                            <label>Comment :</label>
                                            <span>{e.comment}</span>
                                          </div>
                                          {e.attachment ? (
                                            <div>
                                              <Link
                                                onClick={(event) => {
                                                  event.preventDefault();
                                                  window.open(`https://authapi.virtualstaff.ph/api/v1/file/download/${e.attachment}`);
                                                }}
                                                target="blank"
                                              >
                                                <button className="commonBtn2 mb-2">
                                                  <label style={{ margin: '0' }} for="file1">
                                                    <AddIcon />
                                                    View Attachment
                                                  </label>
                                                </button>
                                              </Link>
                                            </div>
                                          ) : null}
                                        </div>
                                      );
                                    })}
                                  </div>
                                ) : (
                                  <div className="ticket-comment-two">
                                    <p>No Comments Found</p>
                                  </div>
                                )}
                                {status === 'closed' ? null : status === 'inprogress' ? (
                                  <>
                                    <hr />
                                    <div className="helpdesk-rightcard-details-row-btn">
                                      <button
                                        data-toggle="modal"
                                        data-target="#followup"
                                        type="button"
                                        className="commonBtn"
                                        onClick={() => handleModal('folowup')}
                                      >
                                        Reply / Follow up
                                      </button>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <hr />
                                    <div className="helpdesk-rightcard-details-row-btn">
                                      <button
                                        data-toggle="modal"
                                        data-target="#followup"
                                        type="button"
                                        className="commonBtn"
                                        onClick={() => handleModal('folowup')}
                                      >
                                        Reply / Follow up
                                      </button>
                                      {/* <button
                                        data-toggle="modal"
                                        data-target="#exampleModal"
                                        type="button"
                                        className="commonBtn"
                                        onClick={() => handleModal('edit')}
                                      >
                                        Edit
                                      </button>
                                      <button
                                        data-toggle="modal"
                                        data-target="#delete"
                                        type="button"
                                        className="commonBtn"
                                        onClick={() => handleModal('delete')}
                                      >
                                        Delete
                                      </button> */}
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="no-ticket">
                          <p>No ticket found</p>
                        </div>
                      )}
                      {ticketList?.length !== 0 ? (
                        <div style={{ width: '25%', marginLeft: '12rem' }}>
                          <Pagination
                            prevPageText={
                              <i class="fas fa-arrow-circle-left" aria-hidden="true"></i>
                            }
                            nextPageText={
                              <i class="fas fa-arrow-circle-right" aria-hidden="true"></i>
                            }
                            firstPageText={<i class="fas fa-step-backward"></i>}
                            lastPageText={<i class="fas fa-step-forward"></i>}
                            innerClass="paination_link"
                            linkClass="pagination_link_a"
                            activeClass="paination_active_link"
                            activePage={page}
                            itemsCountPerPage={limit}
                            totalItemsCount={ticketList?.length}
                            pageRangeDisplayed={3}
                            onChange={handlePagination}
                          />
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {/*  modal open div  */}
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" style={{ maxWidth: '63%' }} role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5
                class="modal-title"
                style={{ margin: '0px', color: 'red' }}
                id="exampleModalLabel"
              >
                Feedback / Complaint
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => setUpdateBtn(false)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="form_row">
                <div className="label_input">
                  <Input
                    name={'full_name'}
                    label={'Full Name'}
                    onChange={handleChange}
                    defaultData={userInfo}
                    disabled={true}
                  />
                </div>
                <div className="label_input">
                  <Input
                    name={'user_email'}
                    label={'Email'}
                    onChange={handleChange}
                    defaultData={userInfo}
                    disabled={true}
                  />
                </div>
              </div>
              <div class="form_row">
                <div className="label_textArea">
                  <TextArea
                    onChange={handleChange}
                    name={'issue'}
                    label={'Feedback'}
                    defaultData={cardInfo}
                  />
                </div>
              </div>
              <div class="form_row">
                <div className="label_input_module">
                  <Select
                    onChange={handleChange}
                    name={'module'}
                    label={'Module'}
                    style={{ marginLeft: '20px', width: '70%' }}
                    options={USER_MODULE}
                    defaultData={cardInfo}
                  />
                </div>
                <div className="label_input">
                  <Select
                    onChange={handleChange}
                    name={'issue_priority'}
                    label={'Priority'}
                    options={ISSUE_PRIORITY}
                    style={{ marginLeft: '20px', width: '70%' }}
                    defaultData={cardInfo}
                  />
                </div>
              </div>
              <div class="form_row">
                <div className="label_input">
                  <Input
                    name={'tag'}
                    label={'Tag'}
                    onChange={handleChange}
                    defaultData={cardInfo}
                  />
                </div>
                <div className="upload_div">
                  <div class="upload-name">
                    <p>{fileName}</p>
                  </div>
                  <div className="upload">
                    <button type="button" class="commonBtn2" id="upBtn" style={{ outline: 'none' }}>
                      {' '}
                      <label style={{ margin: '0' }} for="file1">
                        <i class="fa fa-plus"></i> Upload File
                      </label>
                    </button>
                    <input
                      type="file"
                      id="file1"
                      name="document"
                      onChange={handleDoc}
                      style={{ display: 'none' }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer" style={{ display: 'flex', justifyContent: 'center' }}>
              {updateBtn ? (
                <button
                  onClick={updateHandler}
                  type="button"
                  class="btn btn-danger"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  Update
                </button>
              ) : (
                <button
                  onClick={() => submitHandler('submit')}
                  type="button"
                  class="btn btn-danger"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  Submit
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      {state === true ? (
        <div
          class="modal fade"
          id="followup"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" style={{ maxWidth: '40%' }} role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5
                  class="modal-title"
                  style={{ margin: '0px', color: 'red' }}
                  id="exampleModalLabel"
                >
                  Reply / Follow up
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div>
                  <div>
                    <div>
                      <TextArea
                        name={'comment'}
                        label={'Feedback / Complaint:'}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="helpdesk-leftcard-row1-left" style={{ marginTop: '10px' }}>
                      <label>status : </label>
                      <span
                        className={
                          status === 'open'
                            ? 'opened-bg'
                            : status === 'closed'
                            ? 'closed-bg'
                            : 'inprogress-bg'
                        }
                      >
                        {cardInfo?.issue_status}
                      </span>
                    </div>
                    <div className="d-flex justify-content-between" style={{ width: '100%' }}>
                      <div class="upload-name">
                        <p>{fileName}</p>
                      </div>
                      <div className="upload">
                        <button
                          type="button"
                          class="commonBtn2"
                          id="upBtn"
                          style={{ outline: 'none' }}
                        >
                          {' '}
                          <label style={{ margin: '0' }} for="file1">
                            <i class="fa fa-plus"></i> Upload File
                          </label>
                        </button>
                        <input
                          type="file"
                          id="file1"
                          name="document"
                          onChange={handleDoc}
                          style={{ display: 'none' }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer d-flex justify-content-center">
                <button
                  type="button"
                  class="btn btn-danger"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => submitHandler('followUp')}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {state === true ? (
        <div
          class="modal fade"
          id="delete"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" style={{ maxWidth: '40%' }} role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5
                  class="modal-title"
                  style={{ margin: '0px', color: 'red' }}
                  id="exampleModalLabel"
                >
                  Delete Ticket
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <p style={{ textAlign: 'center' }}>
                  Are you sure you want to delete the ticket?
                </p>
              </div>
              <div class="modal-footer d-flex justify-content-center">
                <button
                  type="button"
                  class="btn btn-danger"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  No
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={deleteHandler}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {/* modal close div */}
    </div>
  );
}

export default TSAuth;
