import React, { useEffect } from 'react';
import { sessionDestroy } from '../../helpers/projectHelper';

const ReLogin = () => {

    useEffect(() => {
         sessionDestroy()
    },[])
    return (
        <div>
            
        </div>
    )
}

export default ReLogin
