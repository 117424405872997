/*global chrome*/
// import fs from 'fs';
import InfoIcon from '@mui/icons-material/Info';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import React, { useEffect, useRef, useState } from 'react';
import { useScreenshot } from 'use-screenshot-hook';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TrackerDetails from './trackerDetails';
import { getUserData } from '../../helpers/projectHelper';
import CustomizedBreadcrumbs from './common/BreadCrumbs';
import moment from 'moment';
import { Formik, Form } from 'formik';
import { Container, Box } from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
import DateFnsUtils from '@date-io/date-fns';
import * as yup from 'yup';
import {
  employeeWithDates,
  getEmployeeManualTodayTrackedTimes,
  getEmployeeManualTrackedTimes,
  getEmployeeManualWeeklyTrackedTimes,
  getEmployeeTrackedTimes,
  getEmployeeTrackedTodayTimes,
  getEmployeeTrackedWeaklyTimes,
  saveEmployeeTimes,
  saveEmployeeTrackedImages,
  employeeWithFilteredDates
} from '../../api/employer/employer';
import { useDispatch, useSelector } from 'react-redux';
import { employerDashboardSelector ,trackingLoaders} from '../../redux/slicers/employer/empDashboardSlice';
import { Link, useParams } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { Detector } from 'react-detect-offline';
import IconButton from '@mui/material/IconButton';
import DateRangeIcon from '@material-ui/icons/DateRange';
import {
  Input,
  Select
 } from '../common/input.js';
 import { DateRangePicker } from 'materialui-daterange-picker';
 import Dialog from '@material-ui/core/Dialog';
 import { empHiredStaffApi,empTerminationApi } from '../../api/employer/employer';
 import { hireEmployeeSelector ,  setLoader, setLoaderMessage } from '../../redux/slicers/employer/hireEmployeeSlice.js';
import { optionArray } from '../../helpers/jsHelper';
import { Spinner } from 'react-bootstrap';
import { TIME_SHEET } from '../../config/constant/routePathConstant';
import '../employer/employer.scss'
var base64ToImage = require('base64-to-image');

var fs = require('browserify-fs');

var formSchema = {
  date: null,
  start_time: null,
  end_time: null,
};
var validationSchema = yup.object().shape({
  date: yup.date().nullable().required(),
  start_time: yup.date().nullable().required(),
  end_time: yup.date().nullable().required(),
});

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    margin: '0px auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '0px',
    '& .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root':
    {
      border: '1px solid #ffffff !important',
    },
    '& label#time-label': {
      background: 'white',
      color: 'black !important',
      padding: '0px 10px',
    },
    '& label#date-label': {
      background: 'white',
      color: 'black !important',
      padding: '0px 10px',
    },
    '& input.MuiInputBase-input.MuiOutlinedInput-input': {
      color: 'black !important',
    },

    '& > *': {
      margin: theme.spacing(0),
    },
    '& .MuiBreadcrumbs-ol': {
      color: '#ffffff',
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  time: {
    flexGrow: 1,
    fontWeight: 500,
    fontSize: '30px',
    color: '#ffffff',
  },
  button: {
    backgroundColor: '#0093d1',
    '&:disabled': {
      backgroundColor: theme.palette.primary || 'red',
    },
    '&:hover': {
      backgroundColor: '#0093d1',
    },
  },
  trackerStatus: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    flexWrap: 'wrap',
  },
  trackerInlet: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    flexDirection: 'row !important',
    [theme.breakpoints.between('xs', 'md')]: {
      flexDirection: 'column',
    },
  },
}));

const TrackerWatch = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  let { dayid, userRole, payment_id } = useParams();
  const [open, setOpen] = React.useState(false);
  const ref = useRef(null);
  const imageRef = useRef(null);
  const [dateRange, setDateRange] = React.useState({});
  const { image, takeScreenshot, isLoading, clear } = useScreenshot({
    ref: imageRef,
  });
  const  { user_id = ''} =getUserData();
  const video = useRef(null);
  var canvas = useRef(null);
  var takeScreenShot = useRef(null);
  const { getTotalAppCount, hiredStaffList = [] } = useSelector(hireEmployeeSelector);
  const [digitalClock, setDigitalClock] = useState(new Date());

  const [images, setImages] = useState([]);
  const [start, setStart] = useState(false);
  const [timerStart, setTimerStart] = useState(false);
  const [sdate, ssetDate] = useState(
    `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`,
  );
  const [remarks, setRemarks] = useState();
  const [dates, setDates] = React.useState({ startDate: null, endDate: null });
  const [datevalidation, setdatevaloidation] = useState(true);
  const [imagethere, setImagesthere] = useState(false);
  const [latesttime, setLatestTime] = useState(0);
  const [hitAPI, setHitAPI] = useState(false);
  const {
    employeeTrackedTime = [],
    employeeWeaklyTrackedTIme,
    employeeTodayTrackedTIme,
    employeeManualTrackedTIme,
    employeeManualWeeklyTrackedTIme,
    employeeManualTodayTrackedTIme,
    employeeData,
    employeeTrackedDay, trackingLoader,
  } = useSelector(employerDashboardSelector);
  const [weaklyTrackedTImes, setWeaklyTrackedTImes] = useState(0);
  const [todayTrackedTImes, setTodayTrackedTImes] = useState(0);
  const [manualTrackedTime, setManualTrackedTime] = useState(0);
  const [manualWeeklyTrackedTime, setManualWeeklyTrackedTime] = useState(0);
  const [manualTodayTrackedTime, setManualTodayTrackedTime] = useState(0);
  const [timer, setTimer] = useState(0);
  const [offline, setOffLine] = useState(true);
  const [trackerOn, startTrackerOn] = useState(false);
  const [toggle, settoggle] = React.useState(false);
  const staffname = hiredStaffList?.data ?? '';
  const options  =  hiredStaffList?.data?.map(({user_id}) => ({ value: user_id['user_full_nam'], text: user_id['user_full_nam'], ...user_id })  )
  const [employee,setEmployee] = useState()
  const [newDate,setNewDate] = useState({
    start : moment().format('YYYY-MM-DD'),
    end :  moment().format('YYYY-MM-DD')
  }) 

  useEffect(() => {
    const value = {
      employer_id: user_id,
     
      limit:10,
      skip:0,
      employee_status: "active"
    }  
    dispatch(empHiredStaffApi(value));
  },[])
  const toggles = () => {
    setOpen(true);
    setOpen(!open);
    settoggle(true);
  };
  window.onbeforeunload = function () {
    console.log('here it is');
    if (parseInt(latesttime) < timer) {
      console.log('here it');
      setIsActive(false);
      setStart(false);
      setTimerStart(false);
      clearInterval(takeScreenShot.current);
      clearInterval(countRef.current);
      var datas = {
        startTime: latesttime,
        endTime: timer,
        endDate: new Date(),
        startDate: new Date(localStorage.getItem('startDate')),
        payment_id: payment_id,
        created_At: new Date(),
        created_Date: new Date().toDateString(),
        totalTimes: timer - (latesttime ? latesttime : 0),
        time_type: 'automatic',
      };
      dispatch(saveEmployeeTimes(datas));
      clearInterval(takeScreenShot.current);
      clearInterval(countRef.current);
      setWeaklyTrackedTImes(weaklyTrackedTImes + (parseInt(timer) - parseInt(latesttime) - 1));
      setTodayTrackedTImes(todayTrackedTImes + (parseInt(timer) - parseInt(latesttime) - 1));
    }
  };

  const trackerCallBack = (e) => {
    setLatestTime(timer);
    setWeaklyTrackedTImes(weaklyTrackedTImes + (parseInt(timer) - parseInt(latesttime) - 1) + 1);
    setTodayTrackedTImes(todayTrackedTImes + (parseInt(timer) - parseInt(latesttime) - 1) + 1);
  };

  useEffect(async () => {
    var datas = {
      payment_id: payment_id,
    };

    await dispatch(getEmployeeTrackedTimes(datas));
    await dispatch(getEmployeeTrackedWeaklyTimes(datas));
    await dispatch(getEmployeeTrackedTodayTimes(datas));
    await dispatch(getEmployeeManualTrackedTimes(datas));
    await dispatch(getEmployeeManualWeeklyTrackedTimes(datas));
    await dispatch(getEmployeeManualTodayTrackedTimes(datas));
  }, []);

   useEffect(() => {
    console.log(employeeTrackedTime.length)
   },[employeeTrackedTime])
//new API_DATA
const handleFormChange = (a) => {
   
   const  { name ,  value} = a;
   if(value === '-select-'){
     return setEmployee(false)
   }
   console.log(value)
   let findObj =  hiredStaffList?.data?.filter(({user_id}) => user_id.user_full_nam === value );
  setEmployee(findObj)
   if(findObj){
    var startDate =(moment().startOf('week')).toDate();
    var endDate = (moment().endOf('week')).toDate();
  
    let payload = {
      startDateOfs:newDate.start,
      endDateOfs:newDate.end,
      payment_id: findObj[0]?.hired_job_id?._id,
    };
  
    dispatch(employeeWithFilteredDates(payload));
   }
   
}
const handleChangeDate = (a) => {
  const  { name ,  value} = a;
  console.log(name, value);
  setNewDate({ 
    start : value
  })
  let payload = {
    startDateOfs:value,
    endDateOfs:value,
    payment_id: employee[0]?.hired_job_id?._id,
  };

  dispatch(employeeWithFilteredDates(payload));
 
}

  useEffect(() => {
    setTimer(parseInt(employeeWeaklyTrackedTIme));
    setLatestTime(parseInt(employeeWeaklyTrackedTIme));
    setWeaklyTrackedTImes(parseInt(employeeWeaklyTrackedTIme));
  }, [employeeWeaklyTrackedTIme]);

  useEffect(() => {
    setTodayTrackedTImes(parseInt(employeeTodayTrackedTIme));
  }, [employeeTodayTrackedTIme]);

  useEffect(() => {
    setManualTrackedTime(parseInt(employeeManualTrackedTIme));
  }, [employeeManualTrackedTIme]);

  useEffect(() => {
    setManualWeeklyTrackedTime(parseInt(employeeManualWeeklyTrackedTIme));
  }, [employeeManualWeeklyTrackedTIme]);

  useEffect(() => {
    setManualTodayTrackedTime(parseInt(employeeManualTodayTrackedTIme));
  }, [employeeManualTodayTrackedTIme]);

  function snap() {
    var context = canvas.current?.getContext('2d');
    ratio = video.current.videoWidth / video.current.videoHeight;
    w = video.current.videoWidth - 100;
    h = parseInt(w / ratio, 10);
    canvas.current.width = w;
    canvas.current.height = h;
    var w, h, ratio;
    context.fillRect(0, 0, w, h);
    context.drawImage(video.current, 0, 0, w, h);
  }

  // useEffect(() => {
  //   video.current.muted = true;
  //   window?.chrome?.desktopCapture?.chooseDesktopMedia(['screen']);
  // }, []);

  useEffect(async () => {
    setImages([...images, image]);

    if (isActive && image) {
      var datas = {
        image: image,
        created_At: new Date(),
        savedDate: Date.now(),
        payment_id: payment_id,
        created_Date: new Date().toDateString(),
      };

      dispatch(saveEmployeeTrackedImages(datas));
    }
  }, [image]);

  var displayMediaOptions = {
    video: {
      cursor: 'always',
    },
    audio: false,
  };

  var displayMediaOptionss = {
    video: true,
    audio: false,
    displaySurface: ['monitor'],
    // audio: false,
  };

  async function startCapture() {
    try {
      video.current.muted = false;
      // handleStart();
      setImagesthere(true);

      video.current.srcObject = await navigator.mediaDevices.getDisplayMedia(displayMediaOptionss);

      dumpOptionsInfo();
      takeScreenShot.current = setInterval(async () => {
        var context = canvas.current.getContext('2d');
        ratio = video.current.videoWidth / video.current.videoHeight;
        w = video.current.videoWidth - 100;
        h = parseInt(w / ratio, 10);
        canvas.current.width = w;
        canvas.current.height = h;
        var w, h, ratio;
        context.fillRect(0, 0, w, h);
        context.drawImage(video.current, 0, 0, w, h);
        await takeScreenshot();
      }, 5000);
      // }, 360000);
      startTrackerOn(true);
      handleStart();
    } catch (err) {
      setStart(false);
    }
  }

  function stopCapture(evt) {
    if (images.length > 0) {
      let tracks = video.current.srcObject.getTracks();

      tracks.forEach((track) => track.stop());
      video.current.srcObject = null;
      handlePause();
    }
  }

  async function pauseCaptures() {
    if (parseInt(latesttime) < timer) {
      setIsActive(false);
      setStart(false);
      setTimerStart(false);
      clearInterval(takeScreenShot.current);
      clearInterval(countRef.current);

      var datas = {
        startTime: latesttime,
        endTime: timer,
        endDate: new Date(),
        startDate: new Date(localStorage.getItem('startDate')),
        payment_id: payment_id,
        created_At: new Date(),
        created_Date: new Date().toDateString(),
        totalTimes: timer - (latesttime ? latesttime : 0),
        time_type: 'automatic',
      };
      await dispatch(saveEmployeeTimes(datas));
      await clearInterval(takeScreenShot.current);
      await clearInterval(countRef.current);
      await setWeaklyTrackedTImes(
        weaklyTrackedTImes + (parseInt(timer) - parseInt(latesttime) - 1),
      );
      await setTodayTrackedTImes(todayTrackedTImes + (parseInt(timer) - parseInt(latesttime) - 1));
      await setLatestTime(parseInt(timer));
    }
  }

  async function pauseCapture() {
    video.current.muted = true;
    setIsActive(false);
    setStart(false);
    setTimerStart(false);
    clearInterval(takeScreenShot.current);
    clearInterval(countRef.current);
    startTrackerOn(false);
    if (timer - (latesttime ? latesttime : 0) > 60) {
      var datas = {
        startTime: latesttime,
        endTime: timer,
        endDate: new Date(),
        startDate: new Date(localStorage.getItem('startDate')),
        payment_id: payment_id,
        created_At: new Date(),
        created_Date: new Date().toDateString(),
        totalTimes: timer - (latesttime ? latesttime : 0),
        time_type: 'automatic',
      };
      dispatch(saveEmployeeTimes(datas));
      clearInterval(takeScreenShot.current);
      clearInterval(countRef.current);
      setLatestTime(timer);
      setWeaklyTrackedTImes(weaklyTrackedTImes + (parseInt(timer) - parseInt(latesttime) - 1));
      setTodayTrackedTImes(todayTrackedTImes + (parseInt(timer) - parseInt(latesttime) - 1));
    } else {
      clearInterval(takeScreenShot.current);
      clearInterval(countRef.current);
      setTimer(latesttime);
    }
  }

  function dumpOptionsInfo() {
    const videoTrack = video.current.srcObject.getVideoTracks()[0];
  }

  const gdmOptions = {
    video: true,
    audio: false,
  };

  // useEffect(() => {
  //   video?.current?.srcObject?.getVideoTracks()[0].addEventListener('ended', pauseCapture);
  //   return () => {
  //     video?.current?.srcObject?.getVideoTracks()[0].removeEventListener('ended',pauseCapture);
  //   };
  // }, []);

  // video?.current?.srcObject?.getVideoTracks()[0].addEventListener('ended', () => {
  //   pauseCapture()
  // })

  const [isActive, setIsActive] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const countRef = useRef(null);

  const handleStart = async () => {
    localStorage.setItem('startDate', new Date());
    setIsActive(true);
    setIsPaused(true);
    setTimerStart(true);
    if (parseInt(latesttime) > 0) {
      let savedTime = await parseInt(latesttime);
      await setTimer(savedTime + 1);
      countRef.current = setInterval(() => {
        setTimer((timer) => timer + 1);
      }, 1000);
    } else if (parseInt(latesttime) > 0 && parseInt(latesttime) < timer) {
      countRef.current = setInterval(() => {
        setTimer((timer) => timer + 1);
      }, 1000);
    } else {
      countRef.current = setInterval(() => {
        setTimer((timer) => timer + 1);
      }, 1000);
    }
  };

  const handlePause = async () => {
    clearInterval(countRef.current);
    setIsPaused(false);
  };

  const handleResume = () => {
    setIsPaused(true);
    countRef.current = setInterval(() => {
      setTimer((timer) => timer + 1);
    }, 1000);
  };

  const handleReset = () => {
    // Reset button logic here
    clearInterval(countRef.current);
    setIsActive(false);
    setIsPaused(false);
    setTimer(0);
  };

  const formatTime = (timer) => {
    const getSeconds = `0${timer % 60}`.slice(-2);
    const minutes = `${Math.floor(timer / 60)}`;
    const getMinutes = `0${minutes % 60}`.slice(-2);
    const getHours = `0${Math.floor(timer / 3600)}`.slice(-2);

    return `${getHours} : ${getMinutes}`;
  };


  const formatedTime = (time) => {
    const getSeconds = `0${time % 60}`.slice(-2);
    const minutes = `${Math.floor(time / 60)}`;
    const getMinutes = `0${minutes % 60}`.slice(-2);
    const getHours = `0${Math.floor(time / 3600)}`.slice(-2);
    // return `${getHours} Hr : ${getMinutes} Min : ${getSeconds} Sec`;
    return `${getHours} Hr : ${getMinutes} Min : ${getSeconds} Sec`;
  };

  const handleDateChange = (e) => {
    let date = new Date(`${sdate}/:${e.target.value}`);

    let name = e.target.name;
    if (name == 'startDate') {
      let dateValidations = new Date(dates.endDate) > date || dates.endDate == null;
      if (dateValidations) {
        dates.endDate == null ? setdatevaloidation(true) : setdatevaloidation(false);
        setDates({ ...dates, [name]: date });
      } else {
        dates.endDate == null ? setdatevaloidation(true) : setdatevaloidation(false);
      }
    } else {
      let dateValidation = new Date(dates.startDate) < date;
      if (dateValidation) {
        setdatevaloidation(false);
        setDates({ ...dates, [name]: date });
      } else {
        setdatevaloidation(true);
        // setDates({...dates,[name]:date})
      }
    }
  };

  const sendManualDates = () => {
    var sd = new Date(`${dates.startDate}`);
    var ed = new Date(`${dates.endDate}`);

    var s = ed.getMinutes() - sd.getMinutes();
    var r = ed.getHours() - sd.getHours();
    var all = r * 60 * 60 + s * 60;

    var sds = sd.getMinutes() * 60 + sd.getHours() * 60 * 60;
    var eds = ed.getMinutes() * 60 + ed.getHours() * 60 * 60;

    var datass = {
      startTime: sds,
      endTime: eds,
      endDate: new Date(dates.endDate),
      startDate: new Date(dates.startDate),
      // user_id: getUserData().user_id,
      payment_id: payment_id,
      created_At: new Date(dates.startDate),
      created_Date: new Date(dates.startDate).toDateString(),
      totalTimes: all,
      time_type: 'manual',
      remarks: remarks,
    };
    dispatch(saveEmployeeTimes(datass));
    // setTimer(parseInt(all) + timer);
    let sdate = moment().startOf('week');
    let edate = moment().endOf('week');
    if (
      moment(dates.startDate).startOf('day') >= sdate &&
      moment(dates.endDate).endOf('day') <= edate
    ) {
      setManualTrackedTime(manualTrackedTime + all);
      setManualWeeklyTrackedTime(manualWeeklyTrackedTime + all);
      setManualTodayTrackedTime(manualTodayTrackedTime + all);
      setRemarks('');
      setDates({ startDate: null, endDate: null });
    } else {
      setManualTrackedTime(manualTrackedTime + all);
    }
  };

  const sendManualDatesAnother = (value, callbackforrest) => {
    // const d = new Date(`${value.date}`);
    // let sd = new Date(d.setTime(new Date(`${value.start_time}`).getTime()));
    // let ed = new Date(d.setTime(new Date(`${value.end_time}`).getTime()));

    const d = new Date(`${value.date}`);
    let sd = new Date(
      `${new Date(d).toLocaleDateString()}/${new Date(`${value.start_time}`).toLocaleTimeString()}`,
    );
    let ed = new Date(
      `${new Date(d).toLocaleDateString()}/${new Date(`${value.end_time}`).toLocaleTimeString()}`,
    );

    let s = ed.getMinutes() - sd.getMinutes();
    let r = ed.getHours() - sd.getHours();
    let all = r * 60 * 60 + s * 60;

    console.log('values', sd, ed, s, r, all);

    let sds = sd.getMinutes() * 60 + sd.getHours() * 60 * 60;
    let eds = ed.getMinutes() * 60 + ed.getHours() * 60 * 60;
    let datass = {
      startTime: sds,
      endTime: eds,
      endDate: new Date(
        `${new Date(d).toLocaleDateString()}/${new Date(`${value.end_time}`).toLocaleTimeString()}`,
      ),
      startDate: new Date(
        `${new Date(d).toLocaleDateString()}/${new Date(
          `${value.start_time}`,
        ).toLocaleTimeString()}`,
      ),
      payment_id: payment_id,
      created_At: new Date(
        `${new Date(d).toLocaleDateString()}/${new Date(
          `${value.start_time}`,
        ).toLocaleTimeString()}`,
      ),
      created_Date: new Date(
        `${new Date(d).toLocaleDateString()}/${new Date(
          `${value.start_time}`,
        ).toLocaleTimeString()}`,
      ).toDateString(),
      totalTimes: all,
      time_type: 'manual',
      remarks: remarks,
    };
    dispatch(saveEmployeeTimes(datass));
    callbackforrest();
    let sdate = moment().startOf('week');
    let edate = moment().endOf('week');
    if (
      moment(
        new Date(
          `${new Date(d).toLocaleDateString()}/${new Date(
            `${value.start_time}`,
          ).toLocaleTimeString()}`,
        ),
      ).startOf('day') >= sdate &&
      moment(
        new Date(
          `${new Date(d).toLocaleDateString()}/${new Date(
            `${value.start_time}`,
          ).toLocaleTimeString()}`,
        ),
      ).endOf('day') <= edate
    ) {
      setManualTrackedTime(manualTrackedTime + all);
      setManualWeeklyTrackedTime(manualWeeklyTrackedTime + all);
      console.log('dsdsddsdsdadsdsadsa', isToday(d));
      setManualTodayTrackedTime(isToday(d) ? manualTodayTrackedTime + all : manualTodayTrackedTime);
      setRemarks('');
      setDates({ startDate: null, endDate: null });
    } else {
      setManualTrackedTime(manualTrackedTime + all);
    }
    return;
  };

  const isToday = (date) => {
    const today = new Date();
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  };

  const apiStatus = () => {
    if (localStorage.getItem('offlineTImer') == null && latesttime !== timer) {
      var datas = {
        startTime: latesttime,
        endTime: timer,
        endDate: new Date(),
        startDate: new Date(localStorage.getItem('startDate')),
        payment_id: payment_id,
        created_At: new Date(),
        created_Date: new Date().toDateString(),
        totalTimes: timer - (latesttime ? latesttime : 0),
        time_type: 'automatic',
      };
      setTimeout(() => {
        localStorage.setItem('offlineTImer', JSON.stringify(datas));
      }, 3000);
      setIsActive(false);
      setStart(false);
      setTimerStart(false);
      clearInterval(takeScreenShot.current);
      clearInterval(countRef.current);
      setOffLine(false);
    }
  };

  const onLineStatus = async () => {
    if (localStorage.getItem('offlineTImer') && !offline) {
      let datasss = await localStorage.getItem('offlineTImer');
      localStorage.removeItem('offlineTImer');
      await setLatestTime(parseInt(JSON.parse(datasss).endTime));
      await setTimer(parseInt(JSON.parse(datasss).endTime));
      await dispatch(saveEmployeeTimes(JSON.parse(datasss), trackerCallBack));
      setOffLine(true);
    }
  };
  const handleClose = (value) => {
    if (value === 'clickaway') {
      return;
    }
    setOpen(false);
  };
 const handleCalender = (name) => {
  dispatch(trackingLoaders(true))
    let start;
    let end ;
    if(employee){
      if(name === 'Last Week'){
        let lastweekStart =  moment(newDate.start).subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD');
        let lastweekend  =  moment(newDate.end).subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD');
        start = lastweekStart;
        end = lastweekend;
        setNewDate({
          start: start,
          end: end
        })
       
       }else if(name === 'Next Week'){
             const startNextWeek = moment(newDate.start).add(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD')
              const endNextWeek = moment(newDate.end).add(1, 'weeks').endOf('isoWeek').format('YYYY-MM-DD')
              start = startNextWeek;
              end = endNextWeek;
              setNewDate({
                start: start,
                end: end
              })

       }else if(name === 'Next Day'){
          const next = moment(newDate.start).add(1, 'days').format('YYYY-MM-DD');
            start = next;
            end = next
            setNewDate({
              start: start,
              end: end
            })
       }else if(name === 'Yesterday'){
        const next = moment(newDate.start).subtract(1, 'days').startOf('day').format('YYYY-MM-DD') ;
        start = next;
        end = next
        setNewDate({
          start: start,
          end: end
        }) 
       }
       let payload = {
         startDateOfs:start,
         endDateOfs:end,
         payment_id: employee[0]?.hired_job_id?._id,
       };
        
    dispatch(employeeWithFilteredDates(payload));
    }
   
 
 }
 
  return (
  
      <div style={{padding:"1% 8%",alignItems: 'center',background:"white"}}>
            <div className="row timesheet_watch">
               <div className="col-md-3">
                   <Select  label={'Staff Name'} defaultData={staffname} options={options} onChange={handleFormChange}  name={'user_full_nam'}/>
               </div>
               <div className="col-md-3">
                   <Input type="date" label={'Date'} name={"start"} defaultData={newDate} onChange={handleChangeDate}  disabled={employee ? false : true}/>
               </div>
               {/* <div className="col-md-3">
                <label>Date</label>
               <div className="currency-input" style={{height:'2.4rem'}}>
              
                
                  <Input />
                
                <div className="input-group-append" style={{height:'2.4rem'}}>
                  <span className="input-group-text bg-transparent">  <IconButton>
                 <DateRangeIcon onClick={toggles} style={{ float: 'left' }} />
               </IconButton></span>
                </div>
              </div>
         
              
               </div> */}
               <div className="d-flex mt-4" style={{width:'50%',justifyContent: 'space-around'}}>
                   <button  disabled={employee ? false : true}  onClick={() => handleCalender('Last Week')}>{'<<'}Last Week</button>
                   <button  disabled={employee ? false : true}  onClick={() => handleCalender('Yesterday')}>{'<'}Yesterday</button>
                   <button  disabled={employee ? false : true}  onClick={() => handleCalender('Next Day')}>Next Day{'>'}</button>
                   <button   disabled={employee ? false : true} onClick={() => handleCalender('Next Week')}>Next Week{'>>'}</button>
               </div>
            </div>  
            <div className="text-center p-1 mt-5">
                <h2 style={{color: "#004c70",fontWeight:"700"}} className="heading_time">Work sessions for {moment(newDate?.start).format('DD.MM.YYYY')} {newDate?.end && <span>- {moment(newDate?.end).format('DD.MM.YYYY')}</span>}</h2>
                <div className="tracker-table d-flex justify-content-center ">
                      <table>
                          <thead>
                             <tr>
                                 <th>Start Time</th>
                                 <th>Duration</th>
                             </tr>
                           
                          </thead>
                        
                          <tbody>
                          {trackingLoader && <td colSpan="4" className="pl-5"><Spinner animation="border" /></td> || 
                          <>
                          {employeeTrackedTime?.length >  0  && !trackingLoader ? 
                          <>
                          {employeeTrackedTime?.map((ele) => {
                            return (
                               <tr>
                                 <td>{ele._id}</td>
                                 <td>{formatTime(ele.count)}</td>
                               <Link
                                to={`${TIME_SHEET}/employer/${employee && employee[0]?.hired_job_id?._id}/${ele._id}`}
                               >
                                 <td>View More</td>
                               </Link>
                               </tr>
                            )
                          })}
                             </>   :  <td colSpan="4" className="pl-5 mt-4">
                                        No Records
                              </td> 
                              } </> }
                          </tbody> 
                      </table>
 
                </div>
            </div>
            {toggle ? (
        <Dialog
          className="tracker-dialog"
          onClose={handleClose}
          aria-labelledby="simple-dialog-title"
          open={open}
        >
          <DateRangePicker
            open={open}
            toggle={toggles}
            onChange={(range) => setDateRange(range)}
            className={classes.dateRanges}
            wrapperClassName={'tracker-dialog'}
          />
        </Dialog>
      ) : (
        ' '
      )}
      </div>
//       <div className={classes.root}>
//         <CustomizedBreadcrumbs />
//         <br />
//         <Detector
//           render={({ online }) => (
//             <div
//               className={online ? 'normal' : 'warning'}
//               onClick={online ? onLineStatus() : apiStatus()}
//             ></div>
//           )}
//         />

//         <div className={classes.trackerStatus}>
//           <div className={classes.trackerInlet}>
//             <TrackerDetails />
//             <div className="apps res-2-card">
//               <div>
//                 <div
//                   style={{
//                     display: 'flex',
//                     justifyContent: 'center',
//                     alignItems: 'center',
//                     color: 'white',
//                   }}
//                 >
//                   <h4
//                     style={{
//                       marginRight: '10px',
//                       marginBottom: '0px',
//                       display: 'flex',
//                       justifyContent: 'center',
//                       alignItems: 'center',
//                     }}
//                   >
//                     <AlarmOnIcon style={{ fontSize: '40px' }} size="large" />
//                   </h4>
//                   <h4 style={{ marginBottom: '0px', marginRight: '3px' }}>Automatically Logged</h4>
//                   <h4 style={{ marginBottom: '0px' }}>
//                     <BootstrapTooltip
//                       title="This is the time tracked by our screen capture and auto time tracker. You can enable or disable
// this on your staff management dashboard. (The page you were on before this)."
//                     >
//                       <InfoIcon />
//                     </BootstrapTooltip>
//                   </h4>
//                 </div>
//                 <div className="stopwatch-card">
//                   <p
//                     style={{
//                       borderBottom: '1px solid #e5e5e5',
//                       fontSize: '20px',
//                       borderRadius: '5px',
//                     }}
//                   >
//                     Today logged times
//                   </p>
//                   <p>{formatedTime(todayTrackedTImes)}</p>
//                   <br />
//                   <p
//                     style={{
//                       borderBottom: '1px solid #e5e5e5',
//                       fontSize: '20px',
//                       borderRadius: '5px',
//                     }}
//                   >
//                     Weekly logged times
//                   </p>
//                   {console.log('weaklyTrackedTImes', weaklyTrackedTImes)}
//                   <p>{formatTime(timer)}</p>
//                   {/* <br /> */}
//                 </div>
//               </div>
//             </div>
//             <div className="apps res-2-card">
//               <div
//                 style={{
//                   display: 'flex',
//                   justifyContent: 'center',
//                   alignItems: 'center',
//                   color: 'white',
//                 }}
//               >
//                 <h4
//                   style={{
//                     marginRight: '10px',
//                     marginBottom: '0px',
//                     display: 'flex',
//                     justifyContent: 'center',
//                     alignItems: 'center',
//                   }}
//                 >
//                   <AlarmOnIcon style={{ fontSize: '40px' }} size="large" />
//                 </h4>
//                 <h4 style={{ marginBottom: '0px', marginRight: '3px' }}>Manually Logged</h4>
//                 <h4 style={{ marginBottom: '0px' }}>
//                   <BootstrapTooltip
//                     title="This is time that your staff has logged inside their Work Log. You can discuss this with your staff
// if you have any questions or clarifications regarding the time they logged."
//                   >
//                     <InfoIcon />
//                   </BootstrapTooltip>
//                 </h4>
//               </div>
//               <div className="stopwatch-card">
//                 <p
//                   style={{ borderBottom: '1px solid #e5e5e5', fontSize: '20px', borderRadius: '5px' }}
//                 >
//                   Today logged times
//                 </p>
//                 <p>{formatedTime(manualTodayTrackedTime)}</p>
//                 <br />
//                 <p
//                   style={{ borderBottom: '1px solid #e5e5e5', fontSize: '20px', borderRadius: '5px' }}
//                 >
//                   Weekly logged times
//                 </p>
//                 <p>{formatedTime(manualWeeklyTrackedTime)}</p>
//               </div>
//             </div>
//           </div>
//         </div>
//         <br />
//         <br />
//         {/* <div className="trackerIntstallation">
//         <div>
//           <p>Install Virtual Staff Phoenix Work Log to start logging time</p>
//         </div>
//         <div className="threecards">
//           <div className="downloadcards">
//             <div className="imagediv">
//               <img
//                 src="https://www.freeiconspng.com/uploads/system-windows-icon-png-4.png"
//                 alt="window-icon"
//               />
//             </div>
//             <div>
//               <span className="titles">Virtual Staff Phoenix - Work log for windows</span>
//             </div>
//           </div>
         
//           <div className="downloadcards">
//             <div className="imagediv">
//               <img
//                 src="https://www.freeiconspng.com/uploads/system-windows-icon-png-4.png"
//                 alt="window-icon"
//               />
//             </div>
//             <div>
//               <span className="titles">Virtual Staff Phoenix - Work log for Mac OS</span>
//             </div>
//           </div>
//           <div className="downloadcards">
//             <div className="imagediv">
//               <img
//                 src="https://w7.pngwing.com/pngs/100/373/png-transparent-finder-computer-icons-apple-macos-mac-angle-preview-fruit-nut.png"
//                 alt="window-icon"
//               />
//             </div>
//             <div>
//               <span className="titles">Virtual Staff Phoenix - Work log for windows</span>
//             </div>
//           </div>
//         </div>
//       </div> */}

//         {/* {userRole == 'job_seeker' && getUserData().user_type == 'job_seeker' ? (
//         <p style={{ display: 'flex' }}> */}
//         {/* <Button
//             style={{
//               padding: '10px',
//               width: 'max-content',
//               display: latesttime ? 'none' : 'block',
//             }}
//             variant="contained"
//             id="start"
//             onClick={startCapture}
//             color="primary"
//             disabled={timerStart ? true : false}
//             className={classes.button}
//           >
//             Start capture
//           </Button> */}
//         {/* &nbsp; &nbsp; */}
//         {/* <Button
//             style={{ padding: '10px', width: 'max-content' }}
//             variant="contained"
//             id="start"
//             onClick={pauseCapture}
//             color="primary"
//             disabled={timerStart ? false : true}
//             className={classes.button}
//           >
//             Pause capture
//           </Button> */}
//         {/* &nbsp; &nbsp; */}
//         {/* <Button
//             style={{ padding: '10px', width: 'max-content', display: latesttime ? 'flex' : 'none' }}
//             variant="contained"
//             id="start"
//             onClick={startCapture}
//             color="primary"
//             disabled={timerStart ? true : false}
//             className={classes.button}
//           >
//             Resume work
//           </Button> */}
//         {/* </p>
//       ) : (
//         ' '
//       )}
//       <br />
//       <br /> */}
//         <p className="titles">Add Manual Time</p>
//         <br />

//         {userRole == 'job_seeker' &&
//           getUserData().user_type == 'job_seeker' &&
//           trackerOn == false ? (
//           <div style={{ display: 'flex', justifyContent: 'center' }}>
//             <Formik
//               validationSchema={validationSchema}
//               initialValues={formSchema}
//               onSubmit={(value, onSubmit) => {
//                 sendManualDatesAnother(value, () => {
//                   onSubmit.resetForm();
//                 });
//                 console.log(
//                   'values',
//                   new Date(
//                     `${new Date(value.date).toLocaleDateString()}/${new Date(
//                       `${value.start_time}`,
//                     ).toLocaleTimeString()}`,
//                   ),
//                   new Date(`${value.date}`).setTime(-new Date(`${value.start_time}`).getTime()),
//                 );
//               }}
//               render={(props) => (
//                 <Form style={{ display: 'flex' }}>
//                   <Box style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
//                     <div className=' time-title'>
//                       <MuiPickersUtilsProvider utils={DateFnsUtils}>
//                         <KeyboardDatePicker
//                           className="date-picker"
//                           id="date-picker-dialog"
//                           label="Select date"
//                           inputVariant="outlined"
//                           format="MM/dd/yyyy"
//                           value={props.values.date}
//                           minDate={moment().startOf('week').toDate()}
//                           maxDate={moment().endOf('week').toDate()}
//                           onChange={(value) => props.setFieldValue('date', value)}
//                           KeyboardButtonProps={{
//                             'aria-label': 'change date',
//                           }}
//                           InputProps={{
//                             style: {
//                               color: '#ffffff',
//                               width: '220px',
//                               marginRight: '20px',
//                             },
//                           }}
//                         />
//                         {/* &nbsp; &nbsp; &nbsp; */}
//                         <KeyboardTimePicker
//                           margin="normal"
//                           id="time-picker"
//                           label="Select Start time"
//                           inputVariant="outlined"
//                           value={props.values.start_time}
//                           onChange={(value) => props.setFieldValue('start_time', value)}
//                           KeyboardButtonProps={{
//                             'aria-label': 'change time',
//                           }}
//                           InputProps={{
//                             style: {
//                               color: '#ffffff',
//                               width: '220px',
//                               marignTop: '0px',
//                               marginRight: '20px',
//                             },
//                           }}
//                         />
//                         {/* &nbsp; &nbsp; &nbsp; */}
//                         <KeyboardTimePicker
//                           margin="normal"
//                           id="time-picker"
//                           label="Select End time"
//                           inputVariant="outlined"
//                           value={props.values.end_time}
//                           onChange={(value) => props.setFieldValue('end_time', value)}
//                           KeyboardButtonProps={{
//                             'aria-label': 'change time',
//                           }}
//                           InputProps={{
//                             style: {
//                               color: '#ffffff',
//                               width: '220px',
//                               marignTop: '0px',
//                               marginRight: '20px',
//                             },
//                           }}
//                         />
//                       </MuiPickersUtilsProvider>
//                     </div>

//                     <TextareaAutosize
//                       aria-label="minimum height"
//                       minRows={2}
//                       value={remarks}
//                       placeholder="Remarks"
//                       maxRows={2}
//                       onChange={(e) => setRemarks(e.target.value)}
//                       className="text-box-res"
//                       style={{
//                         width: '400px',
//                         fontSize: '14px',
//                         padding: '8px',
//                         marginRight: '20px',
//                       }}
//                     />

//                     <Button
//                       type="submit"
//                       style={{ padding: '17px', width: 'max-content' }}
//                       id="stop"
//                       className={classes.button}
//                       disabled={
//                         props.values.date == null ||
//                         props.values.start_time == null ||
//                         props.values.end_time == null ||
//                         props.errors.date ||
//                         props.errors.start_time ||
//                         props.errors.end_time ||
//                         new Date(`${props.values.start_time}`) >= new Date(`${props.values.end_time}`)
//                       }
//                       variant="contained"
//                       color="primary"
//                     >
//                       Add your manual timings
//                     </Button>
//                   </Box>
//                 </Form>
//               )}
//             />
//             <br />
//             <br />
//           </div>
//         ) : (
//           ' '
//         )}

//         {userRole == 'job_seeker' ? (
//           <div className="trackerIntstallation">
//             <br />
//             <div>
//               <p>Install Work Log to track time and get paid!</p>
//             </div>
//             <br />
//             <div className="threecards">
//               <div className="downloadcards">
//                 <p className='text-center' style={{ fontSize: "18px", color: "#33444d", fontWeight: "bold" }}>
//                   Windows
//                 </p>
//                 <div className="imagediv">
//                   <img
//                     src="https://www.freeiconspng.com/uploads/system-windows-icon-png-4.png"
//                     alt="window-icon"
//                   />
//                 </div>
//                 <div>
//                   <span className="titles">
//                     To install work log app for Windows
//                   </span>
//                 </div>
//                 <div>
//                   <a
//                     className="btnsClick"
//                     href={`https://s3.amazonaws.com/www.virtualstaff.ph/vsphworklog-v1.0.3.exe`}
//                     download
//                   >
//                     Click here
//                   </a>
//                 </div>
//               </div>

//               <div className="downloadcards">
//                 <p className='text-center' style={{ fontSize: "18px", color: "#33444d", fontWeight: "bold" }}>
//                   Mac OS
//                 </p>
//                 <div className="imagediv">
//                   <img
//                     src="https://w7.pngwing.com/pngs/100/373/png-transparent-finder-computer-icons-apple-macos-mac-angle-preview-fruit-nut.png"
//                     alt="window-icon"
//                   />
//                 </div>
//                 <div>
//                   <span className="titles">
//                     To install work log app for Mac OS
//                   </span>
//                 </div>
//                 <div>
//                   <a
//                     className="btnsClick"
//                     href={`https://s3.amazonaws.com/www.virtualstaff.ph/vsphworklog-v1.0.3.zip`}
//                     download
//                   >
//                     Click here
//                   </a>
//                 </div>
//               </div>
//               <div className="downloadcards">
//                 <p className='text-center' style={{ fontSize: "18px", color: "#33444d", fontWeight: "bold" }}>
//                   Linux
//                 </p>
//                 <div className="imagediv">
//                   <img
//                     src="https://image.similarpng.com/very-thumbnail/2021/09/Linux-icon-design-on-transparent-background-PNG.png"
//                     alt="window-icon"
//                   />
//                 </div>
//                 <div>
//                   <span className="titles">
//                     To install work log app for Linux
//                   </span>
//                 </div>
//                 <div>
//                   <a
//                     className="btnsClick"
//                     href={`https://s3.us-east-1.amazonaws.com/www.virtualstaff.ph/VirtualStaff-1.0.0.deb`}
//                     download
//                   >
//                     Click here
//                   </a>
//                 </div>
//               </div>
//             </div>
//           </div>
//         ) : (
//           ''
//         )}
//         <br />
//         <div style={{ display: 'none' }}>
//           <video
//             autoPlay={true}
//             ref={video}
//             style={{ height: '400px', width: '50%' }}
//             id="myvideo"
//             class="video-js vjs-fluid vjs-tech"
//             controls="controls"
//             width="400"
//             height="150"
//             data-setup="{}"
//             poster="http://www.allotoi.com/wp-content/uploads/2014/07/rickyPepe.jpg"
//           >
//             <source src="https://media.geeksforgeeks.org/wp-content/uploads/20190616234019/Canvas.move_.mp4" />
//           </video>
//         </div>

//         {/* {userRole == 'job_seeker' ? (
//         !imagethere ? (
//           <p style={{ fontSize: '18px', color: '#bbbbbb' }}>
//             You haven't share your screen to capture. please click the start capture button
//           </p>
//         ) : (
//           <div
//             ref={imageRef}
//             style={{ zIndex: -1, top: '0px', position: 'absolute', width: '100%', left: '0px' }}
//           >
//             <canvas ref={canvas} id="canvas" width="640" height="480"></canvas>
//           </div>
//         )
//       ) : (
//         ' '
//       )} */}

//         <br />
//       </div>
   
  );
};

export default TrackerWatch;
