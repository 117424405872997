import React from 'react';
import logo from '../../../assets/images/admin/admin_image/logo.png';
import notification from '../../../assets/images/admin/admin_image/notification.png';
import transporteViewGreen from '../../../assets/images/admin/admin_image/transporte-view-green.png';
import transporteViewRed from '../../../assets/images/admin/admin_image/transporte-view-red.png';
import transporteViewYellow from '../../../assets/images/admin/admin_image/transporte-view-yellow.png';
import logout from '../../../assets/images/admin/admin_image/logout.png';
import { sessionDestroy } from '../../../helpers/projectHelper';
import { ACCOUNT_VERIFY_LOGIN } from '../../../config/constant/routePathConstant';

const Header = () => {
  return (
    <>
      <header class="docuchain-app-header">
        <a class="docuchain-header-logo" href="adminDashboard.html">
          <img src={logo} height="125" />
        </a>
        {/* <a
          class="docuchain-sidebar-toggle"
          href="#"
          data-toggle="sidebar"
          aria-label="Hide Sidebar"
        ></a> */}

        {/* <!-- Navbar Right Menu--> */}
        <ul class="docuchain-app-nav">
          {/* <li class="dropdown">
            <a
              class="app-nav__item animated infinite swing"
              href="#"
              data-toggle="dropdown"
              aria-label="Show notifications"
            >
              <img src={notification} alt="notification" />
              <span>3</span>
            </a>
            <ul
              class="app-notification dropdown-menu dropdown-menu-right animated hiding"
              data-animation="bounceInDown"
            >
              <li class="app-notification__title">Notifications</li>
              <div class="app-notification__content">
                <li>
                  <a class="app-notification__item" href="javascript:void(0);">
                    <span class="app-notification__icon">
                      <img src={transporteViewGreen} />
                    </span>
                    <div>
                      <p class="app-notification__message">User</p>
                      <p class="app-notification__meta">Just Now</p>
                    </div>
                  </a>
                </li>
                <li>
                  <a class="app-notification__item" href="javascript:void(0);">
                    <span class="app-notification__icon">
                      <img src={transporteViewRed} />
                    </span>
                    <div>
                      <p class="app-notification__message">User</p>
                      <p class="app-notification__meta">5 min ago</p>
                    </div>
                  </a>
                </li>
                <li>
                  <a class="app-notification__item" href="javascript:void(0);">
                    <span class="app-notification__icon">
                      <img src={transporteViewYellow }/>
                    </span>
                    <div>
                      <p class="app-notification__message">User</p>
                      <p class="app-notification__meta">2 days ago</p>
                    </div>
                  </a>
                </li>
                <div class="app-notification__content">
                  <li>
                    <a class="app-notification__item" href="javascript:void(0);">
                      <span class="app-notification__icon">
                        <img src={transporteViewYellow} />
                      </span>
                      <div>
                        <p class="app-notification__message">User</p>
                        <p class="app-notification__meta">3 days ago</p>
                      </div>
                    </a>
                  </li>
                </div>
              </div>
              <li class="app-notification__footer">
                <a href="notification.html">See all Notifications.</a>
              </li>
            </ul>
          </li> */}
          <li>
            <a
              class="app-nav__item"
              href="#"
              data-toggle="modal"
              data-target="#logout"
              data-backdrop="static"
              aria-label="Show notifications"
            >
              <img src={logout}  alt="logout" onClick={() => sessionDestroy(ACCOUNT_VERIFY_LOGIN)} />
            </a>
          </li>
        </ul>
      </header>
    </>
  );
};

export default Header;
