import React, { useEffect } from 'react';
import 'date-fns';
// import React from "react";
import { format } from 'date-fns';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { convertToLocalTime } from 'date-fns-timezone';
import { TIME_SHEET } from '../../config/constant/routePathConstant.js';
import { Link, useHistory, useParams } from 'react-router-dom';
import '../../assets/css/stopWatch.scss';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import { DateRangePicker } from 'materialui-daterange-picker';
import { employeeWithDates, employeeWithFilteredDates } from '../../api/employer/employer';
import { employerDashboardSelector } from '../../redux/slicers/employer/empDashboardSlice';
import IconButton from '@mui/material/IconButton';
// import { useParams } from 'react-router-dom';
import CustomizedBreadcrumbs from './common/BreadCrumbs';

export const DEFAULT_DATE_FORMAT = 'yyyy-MM-dd';
// EMPLOYER_EMPLOYEE_FILTER_WEAKLY_TABLE
const columns = [
  // { id: "name", label: "Name", minWidth: 170 },
  { id: '_id', label: 'Date', align: 'center' },
  {
    id: 'count',
    label: 'Total working hours',
    align: 'center',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'more',
    label: 'View',
    align: 'center',
    format: (value) => value.toLocaleString('en-US'),
  },
];

function createData(_id, count, view) {
  //   const density = endtime / size;
  return {
    _id,
    count,
    view,
  };
}

var rows = [];

const useStyles = makeStyles({
  roots: {
    width: '60%',
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center',
    '& .MuiDialog-paperWidthSm': {
      maxWidth: 'max-content',
    },
    '& .MuiBreadcrumbs-ol': {
      color: '#ffffff',
    },
    '& .MuiPaper-root': {
      background: 'transparent',
    },
    '& .MuiTableCell-alignRight': {
      textAlign: 'left',
    },
    '& p.MuiTypography-root.MuiTablePagination-caption.MuiTypography-body2.MuiTypography-colorInherit':
    {
      display: 'none',
    },
    '& .MuiInputBase-root.MuiTablePagination-input.MuiTablePagination-selectRoot': {
      display: 'none',
    },
    '& .MuiSelect-select.MuiSelect-select': {
      color: '#33444d !important',
    },
  },
  dateRanges: {
    color: '#33444d !important',
    '& .materialui-daterange-picker-MuiGrid-root-7': {
      display: 'none',
    },
    '& .MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiInputBase-input.materialui-daterange-picker-MuiInput-input-132':
    {
      color: '#33444d !important',
    },
  },
  container: {
    maxHeight: 'max-content',
  },

  button: {
    color: '#ffffff',
    borderRadius: '25px',
    backgroundColor: '#0093d1',
    '&:hover': {
      backgroundColor: '#0093d1',
    },
  },
  dates: {
    '& .MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiInputBase-input.materialui-daterange-picker-MuiInput-input-132':
    {
      color: '#33444d !important',
    },
  },
});

export default function WeaklyTable() {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  let { dayid, userRole, payment_id } = useParams();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [tableData, settableData] = React.useState();
  const [toggle, settoggle] = React.useState(false);
  const [search, setSearch] = React.useState();
  const [sumCounts, setsumCounts] = React.useState();

  const { employeeTrackedTime, trackingLoader, employeeTrackedDay, totalTImeCount } =
    useSelector(employerDashboardSelector);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [open, setOpen] = React.useState(false);
  const [dateRange, setDateRange] = React.useState({});

  const toggles = () => {
    setOpen(true);
    setOpen(!open);
    settoggle(true);
  };

  const formatDatesss = (date) => {
    if (!date) return new Date().toLocaleString();

    // Get the timezone from browser using native methods
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const dateTmp = Date.parse(date.toLocaleString());

    const localDate = convertToLocalTime(dateTmp, {
      timeZone: timezone,
    });

    return format(localDate, DEFAULT_DATE_FORMAT);
  };
  const changeDatesss = async (e) => {

  };

  const [selectedDate, setSelectedDate] = React.useState(new Date());

  const filterTable = async () => {
    settoggle(false);
    let payloads = {
      payment_id: payment_id,
      startDateOfs: dateRange.startDate,
      endDateOfs: dateRange.endDate,
    };

    dispatch(employeeWithFilteredDates(payloads));


  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  function handleClick(e) {
    history.push(`/${e}/time`);
  }

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const fetchData = async () => {
    var startDate =(moment().startOf('week')).toDate();
    var endDate = (moment().endOf('week')).toDate();

    let payload = {
      startDate: startDate,
      endDate: endDate,
      payment_id: payment_id,
    };

    dispatch(employeeWithDates(payload));
  };

  useEffect(async () => {
    if (employeeTrackedTime?.length > 0) {
      settableData(employeeTrackedTime);
      settoggle(true);
      rows = [];
      await employeeTrackedTime?.map((e) => rows.push(createData(e._id, e.count, e._id)));
    }
    await setsumCounts(parseInt(totalTImeCount));
  }, [employeeTrackedTime, totalTImeCount]);

  const formatTime = (timer) => {
    const getSeconds = `0${timer % 60}`.slice(-2);
    const minutes = `${Math.floor(timer / 60)}`;
    const getMinutes = `0${minutes % 60}`.slice(-2);
    const getHours = `0${Math.floor(timer / 3600)}`.slice(-2);

    // return `${getHours} : ${getMinutes} : ${getSeconds}`;

    return `${getHours} : ${getMinutes}`;
  };

  const handleClose = (value) => {
    if (value === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <div
      // className={'tracker-sass'}
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      {/* <CustomizedBreadcrumbs /> */}

      <br />
      <p style={{ color: 'black', fontSize: '17px' }}>
        {dateRange.startDate && dateRange.endDate
          ? `Logged times from ${moment(dateRange?.startDate).format('DD-MM-YYYY')} to ${moment(
            dateRange?.endDate,
          ).format('DD-MM-YYYY')}, Use filter to see all logged times`
          : `Logged times from ${moment().startOf('week').format('DD-MM-YYYY')}  to 
        ${moment().endOf('week').format('DD-MM-YYYY')}, Use filter to see all logged times`}
      </p>
      {/* <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              fontSize: '15px',
              backgroundColor: 'rgba(217,217,217,1)',
              padding: '0px 10px',
              borderRadius: '25px',
              height: '25px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <p
              style={{
                fontSize: '15px',
                marginBottom: '0px',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {dateRange.startDate
                ? moment(dateRange?.startDate).format('DD-MM-YYYY')
                : 'From date'}
            </p>
            <p style={{ marginBottom: '0px' }}>&nbsp; - &nbsp;</p>
            <p
              style={{
                fontSize: '15px',
                height: '100%',
                marginBottom: '0px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {dateRange.endDate ? moment(dateRange?.endDate).format('DD-MM-YYYY') : 'To date'}
            </p>
          </div>
          &nbsp; &nbsp;
          <IconButton>
            <DateRangeIcon onClick={toggles} style={{ float: 'left' }} />
          </IconButton>
          &nbsp; &nbsp;
          <Button
            onClick={filterTable}
            disabled={(dateRange.endDate && dateRange.startDate) ? false : true}
            color="primary"
            className={classes.button}
            variant="contained"
          >
            Filter
          </Button>
        </div>
        <br />
      </div> */}
      {toggle && trackingLoader == false && rows?.length > 0 ? (
        <Paper className={classes.roots}>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth, fontSize: '15px', fontWeight: 600 }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {toggle ? (
                <TableBody>
                  {rows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.id == 'count' ? (
                                formatTime(value)
                              ) : column.id == 'more' ? (
                                <Button
                                  variant="contained"
                                  className={classes.button}
                                // onClick={() => handleClick(row._id)}
                                >
                                  <Link
                                    style={{ color: '#ffffff', textDecoration: 'none' }}
                                    to={`${TIME_SHEET}/${userRole == 'job_seeker'
                                      ? 'job_seeker'
                                      : userRole == 'employer'
                                        ? 'employer'
                                        : ' application'
                                      }/${payment_id}/${row._id}`}
                                  >
                                    View More
                                  </Link>
                                </Button>
                              ) : (
                                value
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                  {/* <TableRow
                    hover
                    role="checkbox"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '170%',
                    }}
                    tabIndex={-1}
                  >
                    <TableCell
                      style={{
                        fontWeight: 600,
                        width: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                      }}
                    >
                      Effective worked hours(hours:minutes:seconds) :{' '}
                      {sumCounts ? formatTime(sumCounts) : ''}
                      hours
                    </TableCell>
                  </TableRow> */}
                </TableBody>
              ) : (
                ' '
              )}
            </Table>
          </TableContainer>
          <div style={{ display: 'flex', justifyContent: 'center',padding:"10px 0px",alignItems: 'center', fontWeight: 600, }}>   Effective worked hours(hours:minutes) :{' '}
                      {sumCounts ? formatTime(sumCounts) : ''}
                     {` `} hours</div>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            labelRowsPerPage=" "
            onPageChange={handleChangePage}
            showFirstButton={true}
          />
        </Paper>
      ) : trackingLoader ? (
        <CircularProgress color="secondary" />
      ) : (
        <p style={{ display: 'flex', color: 'rgb(145 145 145)',minHeight:"100px" }}>No Data Found</p>
      )}
      <br />
      {toggle ? (
        <Dialog
          className="tracker-dialog"
          onClose={handleClose}
          aria-labelledby="simple-dialog-title"
          open={open}
        >
          <DateRangePicker
            open={open}
            toggle={toggles}
            onChange={(range) => setDateRange(range)}
            className={classes.dateRanges}
            wrapperClassName={'tracker-dialog'}
          />
        </Dialog>
      ) : (
        ' '
      )}
    </div>
  );
}
