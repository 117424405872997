import React, {useState,useMemo,useEffect} from 'react'
import PaymentBonus from './paymentBonus';
import { Button, ListGroup, Modal } from 'react-bootstrap';
import { Input, TextArea ,DecimalInput ,Select} from '../common/input';
import { changeDefaultApi, empAddPayment, getCardApi } from '../../api/employer/employer';
import { getUserData } from '../../helpers/projectHelper';
import { useDispatch, useSelector } from 'react-redux';
import { Link , MemoryRouter , Switch , Route } from 'react-router-dom';
import { hireEmployeeSelector } from '../../redux/slicers/employer/hireEmployeeSlice';
import { ImageLoader, errorValidator , warningToast } from '../../helpers/projectHelper';
import { useSocket } from '../../helpers/hooks';
export default function     ChatPayment({id,name}) {
    const [showPaymentModal,setOpenPaymentModal] = useState(false);
    const { user_id = '',full_name = '',} = getUserData();
    const [addCardModal, setAddCardModal] = useState(false);
    const [flag,setFlag] = useState(false);
    const [loading,setLoading] = useState(false);
    const [formObj,setFormObj] = useState();
    const dispatch = useDispatch();
    const { sendNotification = null, sendMsg = "" } = useSocket();
  
    const PAYMENT_TYPE = [
        {text:'Salary',value:'Salary'},
        {text:'Bonus',value:'Bonus'},
      ]
 
  const addZero = (val) => {
    let data  = val
    return ('0' + data).slice(-2)  
}
const openPaymentModal = () => {
    setOpenPaymentModal(true);

  }
       
    const handleCloseModal = () => {
        setAddCardModal(false)
    }
    const handleClose = () => {
      setOpenPaymentModal(false);
      localStorage.removeItem('payments')
    };
   
      const handleCall = () => {
        const data ={
          employer_id: user_id
      }
      dispatch(getCardApi(data,setFlag))
    
     
    }
    const handleDefault = (ele) => {
        if(window.confirm('Are you sure want to change this card to default?')){
        const obj = {
                "employer_id": user_id,
                "card_id":ele?.id,
                "customer_id":ele?.customer
        }
        dispatch(changeDefaultApi(obj,handleCall))
    }else{}
    }
    
    
      const FlowDivone = () => {
        const [formObj,setFormObj] = useState();
        let formData = { ...formObj };
        let keyArr= [ 'amount' , 'amount_type']
        const check = keyArr.every((i) => formData[i]);
      
        useEffect(() => {
          let data  = localStorage.getItem('payments');
          setFormObj(JSON.parse(data));
        },[])
        const handleFormChange = ({ name, value }) => {
          if (name) {
            formData[name] = value;
            if(name === 'amount'){
              if(value < 1)  return warningToast({ title: 'Invaild',msg:"Please enter valid amount"})
            }
             setFormObj(formData);
        
             localStorage.setItem('payments', JSON.stringify(formData));
          }
          console.log(formData);
        };
        
        return (
          <>
           <Modal.Header closeButton style={{backgroundColor:"#004C70"}}>
        <Modal.Title id="example-modal-sizes-title-lg" className="bonus-modal">
          <div className="bonus-modal d-flex"><Link to="/"><div className='new-back-button blue-color text-center'>{'<'}</div></Link><div style={{color:"white"}} className="ml-2">
            
             <h1>Pay Staff</h1>
          <h6>Pay staff conveniently with 0% processing fee</h6>
            </div></div>
         
         
          </Modal.Title>
      </Modal.Header>
          <Modal.Body style={{ fontSize: '20px', }}>
    
    
          <div className="row">
            <div className="col-md-6">
              <label style={{ fontWeight: 'bold' , color :"#004C70" }}>Enter Amount</label>
              <div className="currency-input">
                <div>$</div>
                  <DecimalInput name="amount" type="number" className="currency-usd" onChange={handleFormChange}  defaultData={formObj}/>
              
                <span>USD</span>
              </div>
            </div>
            <div className="col-md-6">
              <label style={{ fontWeight: 'bold' , color :"#004C70" }}>Reason</label>
              <Select onChange={handleFormChange}  name={'amount_type'} options={PAYMENT_TYPE} defaultData={formObj}/>
            </div>
          </div>
       
        
        </Modal.Body>
       
     
           <Modal.Footer>
    
           {' '}
     
          <button className='cancel-btn mr-2' onClick={() => handleClose()}>
             Cancel
           </button>
           <Link to='/choose-payment' >
           <button  disabled={!check} className="hire-staff-button mr-2" >
              Next
           </button>
           </Link>
     
         </Modal.Footer>
         </>
        )
      }
      const FlowDivtwo = () => {
        const [amount,setAmount] = useState();
        const [form,setForm] = useState();
        const { cardData  = []} = useSelector(hireEmployeeSelector);
        const [loading,setLoading] = useState(false);
        useEffect(() => {
          const data ={
            employer_id: user_id
        }
        dispatch(getCardApi(data,setFlag));

        let amount = localStorage.getItem('payments');
        if(amount) setAmount(JSON.parse(amount));
        if(amount) setForm(JSON.parse(amount));
        },[]);
        const handleSendNotification = () => {
          sendNotification?.({ type: 'Payment', title: "Payment Successful", msg: `Your payment is processed by ${full_name}` },id);
        }
        const handleAddPayment = () => {
          setLoading(true)
          const { amount = '', amount_type = '', description = '' } = form;
          if (!amount || !amount_type ) {
            return warningToast({ title: "Invalid", msg: "Please enter all the details" })
          }
        
          const bonusObj = {
            user_obj: {
        
              "user_id":id,
              "employer_id": user_id,
              "amount_type": amount_type,
              "billing_date":  new Date(),
              "amount": Number(amount)
          
            }
          }
          console.log(bonusObj);
          console.log(formObj)
          dispatch(empAddPayment(bonusObj, setLoading ,handleSendNotification))
        
        }
        return (
          <>
           <Modal.Header closeButton style={{backgroundColor:"#004C70"}}>
        <Modal.Title id="example-modal-sizes-title-lg" className="bonus-modal">
          <div className="bonus-modal d-flex"><Link to="/"><div className='new-back-button blue-color text-center'>{'<'}</div></Link><div style={{color:"white"}} className="ml-2">
            
             <h1>Pay Staff</h1>
          <h6>Pay staff conveniently with 0% processing fee</h6>
            </div></div>
         
         
          </Modal.Title>
      </Modal.Header>
          <Modal.Body >
           <div className="d-flex align-items-center">
               {/* <Link to="/">
            <span className="flex text-white bg-info iconButton p-3 fas fa-2x fa-arrow-left"  /></Link>
             <h6 className="ml-1 mb-0">Go Back</h6> */}
           </div>
           {cardData?.length > 0 ?   
          <div className="row p-2">
                  <h6 className="mb-0" style={{fontWeight: 'bold'}}>Select Card</h6>
                  {cardData?.map((ele,i) => {
                          return (
    
                      
                      <div className="list-card mb-1" key={i} style={{fontSize:'10px',width:"100%"}}>
                          <div className="card-div flexwrap">
                           
                              <input type="radio" className={ele?.default ? "card-default mb-0" : ""} checked={ele?.default} onClick={() => handleDefault(ele)}/>
                          
                           
                            
                              <span className="ml-5">{ele?.brand}</span>
                              <span>**** **** **** {ele?.last4}</span>
                              <span>{ele?.cardType}</span>
                              <span>Exp Month - {addZero(ele?.exp_month)}</span>
                              <span>Exp Year  - {ele?.exp_year}</span>
                            
                          </div>
                         
                      </div>
                          )
                        })} 
                    </div> : <div className="py-5 font-weight-bolder text-center text-gray">No Payment Options</div> 
           
                      }
          <div className="d-flex align-items-center"><span style={{fontSize:'30px'}}>+</span>{' '}<button type="button" className='ml-2 common-blue-button' onClick={() => setAddCardModal(true)}>Add card</button></div>
          <div className="bonus-footer">
             <h3 className="blue-color" style={{fontSize:'20px'}}>Total Amount:</h3>
             <span>$   {amount ?  Number(amount?.amount).toFixed(2) : '0'}</span>
           </div>
          
        </Modal.Body>
        <Modal.Footer>
    
           {' '}
           <button className="cancel-btn" onClick={() => handleClose()}>
             Cancel
           </button>
          
           
           <button className="hire-staff-button mr-2" style={{width:"8rem"}} onClick={() => handleAddPayment()} disabled={loading}>   {loading ? <i class="fa fa-spinner fa-spin" aria-hidden="true"></i> : "Make Payment"}</button>
        
     
           
         </Modal.Footer>
        </>
        )
      }
     const Modalmemo = useMemo(() => {
    return (
      <Modal
      show={showPaymentModal}
      onHide={handleClose}
      aria-labelledby="example-modal-sizes-title-lg"
      size="lg"

    >
      
        <MemoryRouter>
      
            <Switch>
           
                <Route path="/" exact component={FlowDivone}/>
                <Route path="/choose-payment" exact component={FlowDivtwo} />
            </Switch>
        </MemoryRouter>
        

   
    </Modal>
    )
  },[showPaymentModal])
  return (
     <>
      <button className='payment-button' onClick={() => openPaymentModal()}>{name}</button>
       {Modalmemo}
    {addCardModal &&  <PaymentBonus show={addCardModal}  close={handleCloseModal} call={handleCall}  />}
     </>
  )
}
