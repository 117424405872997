import React, { useEffect } from 'react';
import Header from './common/header.js';
import { LazyLoader } from '../common/loader.js';
import { RouterBuilder } from '../common/router.js';
import WEBSITE_ROUTES from '../../config/routes/websiteRoutes.js';
import Footer from './common/footer.js';
import { WebsiteSessionChecker } from '../common/container/sessionChecker.js';

//css
import '../../assets/css/style.css';
import '../../assets/css/responsive.css';
import '../../assets/css/reset.css';
import '../../assets/css/bootstrap.css';
import { useLocation } from 'react-router';
import { navigate, redirectRoute } from '../../helpers/projectHelper.js';
import { REDIRECT_QUERY_ROUTE } from '../../config/constant/routePathConstant.js';
import { EMPLOYER_JOB_APPLICANTS_LIST } from '../../config/constant/apiConstant.js';

const WebsiteContainer = () => {
   const location = useLocation();
   useEffect(() => {
      if(location.search){
            redirectRoute(location.search)
      }
   },[])
   return (
     <>
        <WebsiteSessionChecker/>
        <Header/>
        <LazyLoader>
           <RouterBuilder data={WEBSITE_ROUTES}/>
        </LazyLoader>
        <Footer/>
     </>
   );
};

export default WebsiteContainer;
