import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { archiveList } from '../../actions/board';
import { Button, Menu, MenuItem } from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import MoveList from './MoveList';
import { getUserData } from '../../../../helpers/projectHelper';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams
} from "react-router-dom";
import {useSession} from "../../../../helpers/hooks";
// import { useParams } from 'react-router-dom';

const ListMenu = ({ listId }) => {
  const {user_type} = useSession()
  const { board_id } = useParams();
  const { full_name,user_id } = getUserData();


  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const archive = async () => {
    let data ={
      id:listId,
      user_id:user_id,
      boardId:board_id,
      archive:true
    }
    dispatch(archiveList(data));
  };

  return (
    <div>
      {user_type !== "job_seeker"?
      <Button onClick={handleClick}>
        <MoreHorizIcon />
      </Button>:" "}
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}>
          <MoreHorizIcon />
        </MenuItem>
        <MenuItem
          onClick={() => {
            archive();
            handleClose();
          }}
        >
          Archive This List
        </MenuItem>
        <MenuItem>
          <MoveList listId={listId} closeMenu={handleClose} />
        </MenuItem>
      </Menu>
    </div>
  );
};

ListMenu.propTypes = {
  listId: PropTypes.string.isRequired,
};

export default ListMenu;
