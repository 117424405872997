import axios from 'axios';
import { errorToast, successToast } from '../../../helpers/projectHelper.js';
import api from '../../../helpers/axiosHelper.js';
import { setAlert } from './alert';
import {
  ADD_BOARD,
  ADD_CARD,
  ADD_CARD_MEMBER,
  ADD_CHECKLIST_ITEM,
  ADD_LIST,
  ADD_MEMBER,
  ARCHIVE_CARD,
  ARCHIVE_LIST,
  BOARD_ERROR,
  BOARD_STATUS,
  BOARD_STATUS_ERROR,
  CARD_ID,
  CLEAR_BOARD,
  COMPLETE_CHECKLIST_ITEM,
  DELETE_CARD,
  DELETE_CHECKLIST_ITEM,
  EDIT_CARD,
  EDIT_CHECKLIST_ITEM,
  GET_ACTIVITY,
  GET_BOARD,
  GET_BOARDS,
  GET_CARD,
  GET_LIST,
  HIDE_LOADER,
  MOVE_CARD,
  MOVE_LIST,
  POST_COMMENT,
  RENAME_BOARD,
  RENAME_LIST,
  SHOW_LOADER,
  USER_ID,
} from './types';
import { TASK_MANAGEMENT_ROOT_API_BACKEND } from '../../../config/constant/apiConstant';
const config = {
  headers: {
    'Content-Type': 'application/json',
  },
};

export const showLoading = () => async (dispatch) => {
  dispatch({ type: SHOW_LOADER });
};

export const hideLoading = () => async (dispatch) => {
  dispatch({ type: HIDE_LOADER });
};

// Get boards
export const getBoards = (formData) => async (dispatch) => {
  dispatch({ type: CLEAR_BOARD });
  api()
    .root(TASK_MANAGEMENT_ROOT_API_BACKEND)
    .post('/board/id')
    .data(formData)
    .success((a) => {
      dispatch({
        type: GET_BOARDS,
        payload: a.result,
      });
      successToast({ title: 'Success', msg: a.message });
    })
    .error((err) => {
      dispatch(hideLoading());
      errorToast({ title: 'Failure', msg: 'Error in Fetching the board details' });
    })
    .send();
};

//Delete Board
export const delBoards = (formData) => async (dispatch) => {
  dispatch({ type: CLEAR_BOARD });
  api()
    .root(TASK_MANAGEMENT_ROOT_API_BACKEND)
    .post('/board/deleteBoard')
    .data(formData)
    .success((a) => {
      dispatch({
        type: GET_BOARDS,
        payload: a.result,
      });
      // cb()
      successToast({ title: 'Success', msg: a.message });
    })
    .error((err) => {
      dispatch(hideLoading());
      errorToast({ title: 'Failure', msg: 'Error in Fetching the board details' });
    })
    .send();
};

//Delete Board
export const delBoardsMembers = (formData) => async (dispatch) => {
  // dispatch({ type: CLEAR_BOARD });
  api()
    .root(TASK_MANAGEMENT_ROOT_API_BACKEND)
    .post('/board/deleteBoardMembers')
    .data(formData)
    .success((a) => {
      dispatch({
        type: GET_BOARD,
        payload: { ...a.result },
      });
      // cb()
      successToast({ title: 'Success', msg: a.message });
    })
    .error((err) => {
      dispatch(hideLoading());
      errorToast({ title: 'Failure', msg: 'Error in Fetching the board details' });
    })
    .send();
};

// Get board
export const getBoard = (formData) => async (dispatch) => {
  api()
    .root(TASK_MANAGEMENT_ROOT_API_BACKEND)
    .post('/board/users')
    .data(formData)
    .success((a) => {
      dispatch({
        type: GET_BOARD,
        payload: { ...a.result, listObjects: [], cardObjects: [] },
      });
    })
    .error((err) => {
      errorToast({ title: 'Failure', msg: 'Error in created the board' });
    })
    .send();
};

// Get board
export const getBoardDatas = (formData) => async (dispatch) => {
  api()
    .root(TASK_MANAGEMENT_ROOT_API_BACKEND)
    .post('/card/user-name')
    .data(formData)
    .success((a) => {
      dispatch({
        type: GET_BOARD,
        payload: { ...a.result.board },
      });
      dispatch({
        type: USER_ID,
        payload: formData.userid,
      });
      dispatch({
        type: CARD_ID,
        payload: a.result.card,
      });
      successToast({ title: 'Success', msg: a.message });
    })
    .error((err) => {
      errorToast({ title: 'Failure', msg: 'Error in fetching the data' });
    })
    .send();
};
// Add board
export const addBoard = (formData, history) => async (dispatch) => {
  dispatch(showLoading());
  api()
    .root(TASK_MANAGEMENT_ROOT_API_BACKEND)
    .post('/board/add')
    .data(formData)
    .success((a) => {
      dispatch({
        type: ADD_BOARD,
        payload: a.result,
      });
      dispatch(hideLoading());
      dispatch(setAlert('Board Created', 'success'));
      successToast({ title: 'Success', msg: 'Board created Successfully' });
    })
    .error((err) => {
      errorToast({ title: 'Failure', msg: 'Error in fetching the data' });
    })
    .send();
};

// Rename board
export const renameBoard = (formData) => async (dispatch) => {
  api()
    .root(TASK_MANAGEMENT_ROOT_API_BACKEND)
    .post('/board/rename')
    .data(formData)
    .success((a) => {
      dispatch({
        type: RENAME_BOARD,
        payload: a.result,
      });
      successToast({ title: 'Success', msg: a.message });
    })
    .send();
};

// Get list
export const getList = (formData) => async (dispatch) => {
  api()
    .root(TASK_MANAGEMENT_ROOT_API_BACKEND)
    .post('/list/id')
    .data(formData)
    .success((a) => {
      dispatch({
        type: GET_LIST,
        payload: a.result,
      });
    })
    .error((err) => {})
    .send();
};

// Get list by id
export const getListofDatas = async (boardId, memberName) => async (dispatch) => {
  try {
    const data = {
      id: boardId,
      name: memberName,
    };

    await axios.post('http://localhost:5000/api/boards/members', data).then((response) => {
      if (response.data) {
        const res = axios.get(`http://localhost:5000/api/cards/user-name/${response.data.name}`);
        dispatch({
          type: GET_LIST,
          payload: res.data,
        });
      }
    });
  } catch (err) {
    dispatch({
      type: BOARD_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Add list

export const addList = (formData) => async (dispatch) => {
  api()
    .root(TASK_MANAGEMENT_ROOT_API_BACKEND)
    .post('/list/add')
    .data(formData)
    .success((a) => {
      dispatch({
        type: ADD_LIST,
        payload: a.result,
      });
    })
    .error((err) => {
      errorToast({ title: 'Failure', msg: 'Error in fetching the data' });
    })
    .send();
};

// Rename list
export const renameList = (formData) => async (dispatch) => {
  api()
    .root(TASK_MANAGEMENT_ROOT_API_BACKEND)
    .post('/list/edit')
    .data(formData)
    .success((a) => {
      dispatch({
        type: RENAME_LIST,
        payload: a.result,
      });
      successToast({ title: 'Success', msg: a.message });
    })
    .error((err) => {
      errorToast({ title: 'Failure', msg: 'Error in fetching the data' });
    })
    .send();
};

// Archive/Unarchive list
export const archiveList = (formData) => async (dispatch) => {
  api()
    .root(TASK_MANAGEMENT_ROOT_API_BACKEND)
    .post('/list/archive')
    .data(formData)
    .success((a) => {
      dispatch({
        type: ARCHIVE_LIST,
        payload: a.result,
      });
      successToast({ title: 'Success', msg: a.message });
    })
    .error((err) => {
      errorToast({ title: 'Failure', msg: 'Error in fetching the data' });
    })
    .send();
};

// Get card
export const getCard = (formData) => async (dispatch) => {
  // dispatch(showLoading());
  // https://phoenix-task-management.colan.in
  api()
    .root(TASK_MANAGEMENT_ROOT_API_BACKEND)
    .post('/card/id')
    .data(formData)
    .success((a) => {
      dispatch({
        type: GET_CARD,
        payload: a.result,
      });
    })
    .error((err) => {})
    .send();
};

// Add card
export const addCard = (formData) => async (dispatch) => {
  api()
    .root(TASK_MANAGEMENT_ROOT_API_BACKEND)
    .post('/card/add-card')
    .data(formData)
    .success((a) => {
      dispatch({
        type: ADD_CARD,
        payload: a.result,
      });
      dispatch(getActivity());
      successToast({ title: 'Success', msg: a.message });
    })
    .error((err) => {
      errorToast({ title: 'Failure', msg: 'Error in fetching the data' });
    })
    .send();
};

export const editCards = (formData) => async (dispatch) => {
  api()
    .root(TASK_MANAGEMENT_ROOT_API_BACKEND)
    .post('/card/edit')
    .data(formData)
    .success((a) => {
      dispatch({
        type: EDIT_CARD,
        payload: a.result,
      });
      dispatch(getActivity());
      successToast({ title: 'Success', msg: a.message });
    })
    .error((err) => {
      errorToast({ title: 'Failure', msg: 'Error in fetching the data' });
    })
    .send();
};

// Edit card
export const editCard = (formData) => async (dispatch) => {
  const formDatas = new FormData();
  await formDatas.append('file', formData.files.files);

  api()
    .root(TASK_MANAGEMENT_ROOT_API_BACKEND)
    .post('/file/upload')
    .data(formDatas)
    .success((a) => {
      const formDatas = {
        title: formData.title,
        description: formData.description,
        files: a.file_id,
        card_id: formData.card_id,
      };
      api()
        .root(TASK_MANAGEMENT_ROOT_API_BACKEND)
        .post('/card/file-update')
        .data(formDatas)
        .success((a) => {
          dispatch({
            type: EDIT_CARD,
            payload: a.result,
          });
        })
        .error((err) => {
          errorToast({ title: 'Failure', msg: 'Error in fetching the data' });
        })
        .send();
    })
    .error((err) => {
      errorToast({ title: 'Failure', msg: 'Error in fetching the data' });
    })
    .send();
};

// Edit card
export const editCardtext = (formData) => async (dispatch) => {
  api()
    .root(TASK_MANAGEMENT_ROOT_API_BACKEND)
    .post('/card/edit')
    .data(formData)
    .success((a) => {
      dispatch({
        type: EDIT_CARD,
        payload: a.result,
      });
      successToast({ title: 'Success', msg: a.message });
    })
    .error((err) => {
      errorToast({ title: 'Failure', msg: 'Error in fetching the data' });
    })
    .send();
};

//post comment

export const postCardComment = (formData) => async (dispatch) => {
  dispatch(showLoading());
  api()
    .root(TASK_MANAGEMENT_ROOT_API_BACKEND)
    .post('/card/addcomment')
    .data(formData)
    .success((a) => {
      dispatch(hideLoading());
      dispatch({
        type: EDIT_CARD,
        payload: a.result,
      });
      successToast({ title: 'Success', msg: a.message });
    })
    .error((err) => {
      dispatch(hideLoading());
      errorToast({ title: 'Failure', msg: 'Error in fetching the data' });
    })
    .send();
};

//editCardcomment
export const editCardComment = (formData) => async (dispatch) => {
  dispatch(showLoading());
  api()
    .root(TASK_MANAGEMENT_ROOT_API_BACKEND)
    .post('/card/editCommentById')
    .data(formData)
    .success((a) => {
      dispatch(hideLoading());
      dispatch({
        type: EDIT_CARD,
        payload: a.result,
      });
      successToast({ title: 'Success', msg: a.message });
    })
    .error((err) => {
      dispatch(hideLoading());
      errorToast({ title: 'Failure', msg: 'Error in fetching the data' });
    })
    .send();
};

//all comments based on card ids

export const getCardComment = (formData) => async (dispatch) => {
  api()
    .root(TASK_MANAGEMENT_ROOT_API_BACKEND)
    .post('/card/allcomments')
    .data(formData)
    .success((a) => {
      dispatch({
        type: POST_COMMENT,
        payload: a.result,
      });
    })
    .error((err) => {})
    .send();
};

// Move card
export const moveCards = (formData) => async (dispatch) => {
  api()
    .root(TASK_MANAGEMENT_ROOT_API_BACKEND)
    .post('/card/move')
    .data(formData)
    .success((a) => {
      dispatch({
        type: MOVE_CARD,
        payload: a.result,
      });
      successToast({ title: 'Success', msg: a.message });
    })
    .error((err) => {
      errorToast({ title: 'Failure', msg: 'Error in fetching the data' });
    })
    .send();
};

// Archive/Unarchive card
export const archiveCard = (formData) => async (dispatch) => {
  api()
    .root(TASK_MANAGEMENT_ROOT_API_BACKEND)
    .post('/card/archive')
    .data(formData)
    .success((a) => {
      dispatch({
        type: ARCHIVE_CARD,
        payload: a.result,
      });

      dispatch(getActivity());
      successToast({ title: 'Success', msg: a.message });
    })
    .error((err) => {
      errorToast({ title: 'Failure', msg: 'Error in fetching the data' });
    })
    .send();
};

// Delete card
export const deleteCard = (formData) => async (dispatch) => {
  api()
    .root(TASK_MANAGEMENT_ROOT_API_BACKEND)
    .post('/card/delete-card')
    .data(formData)
    .success((a) => {
      dispatch({
        type: DELETE_CARD,
        payload: a.result,
      });

      // dispatch(getActivitys(formData.boardId));
      // successToast({ title: 'Success', msg: a.message });
    })
    .error((err) => {
      // errorToast({ title: 'Failure', msg: 'Error in fetching the data' });
    })
    .send();
};

// Get activity
export const getActivity = () => async (dispatch) => {
  try {
    const boardId = axios.defaults.headers.common['boardId'];

    const res = await axios.get(`http://localhost:5000/api/boards/activity/${boardId}`);

    dispatch({
      type: GET_ACTIVITY,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: BOARD_ERROR,
      payload: { msg: err, status: err },
    });
  }
};

export const getActivitys = (boardId) => async (dispatch) => {
  let formDatas = {
    boardId: boardId,
  };
  api()
    .root(TASK_MANAGEMENT_ROOT_API_BACKEND)
    .post('/boards/activity')
    .data(formDatas)
    .success((a) => {
      dispatch({
        type: GET_ACTIVITY,
        payload: a.result,
      });
      successToast({ title: 'Success', msg: a.message });
    })
    .error((err) => {
      errorToast({ title: 'Failure', msg: 'Error in fetching the data' });
    })
    .send();
};

// Add member
export const addMember = (formData) => async (dispatch) => {
  api()
    .root(TASK_MANAGEMENT_ROOT_API_BACKEND)
    .post('/board/addMember')
    .data(formData)
    .success((a) => {
      dispatch({
        type: ADD_MEMBER,
        payload: a.result,
      });

      dispatch(getActivity());
      successToast({ title: 'Success', msg: a.message });
    })
    .error((err) => {
      errorToast({ title: 'Failure', msg: 'Error in fetching the data' });
    })
    .send();
};

// Move list
export const moveList = (formData) => async (dispatch) => {
  api()
    .root(TASK_MANAGEMENT_ROOT_API_BACKEND)
    .post('/list/move')
    .data(formData)
    .success((a) => {
      dispatch({
        type: MOVE_LIST,
        payload: a.result,
      });

      dispatch(getActivity());
      successToast({ title: 'Success', msg: a.message });
    })
    .error((err) => {
      errorToast({ title: 'Failure', msg: 'Error in fetching the data' });
    })
    .send();
};

// Add card member
export const addCardMember = (formData) => async (dispatch) => {
  api()
    .root(TASK_MANAGEMENT_ROOT_API_BACKEND)
    .post('/card/addMember')
    .data(formData)
    .success((a) => {
      dispatch({
        type: ADD_CARD_MEMBER,
        payload: a.result,
      });

      dispatch(getActivity());
      successToast({ title: 'Success', msg: a.message });
    })
    .error((err) => {
      errorToast({ title: 'Failure', msg: 'Error in fetching the data' });
    })
    .send();
};

// Add checklist item
export const addChecklistItem = (formData) => async (dispatch) => {
  api()
    .root(TASK_MANAGEMENT_ROOT_API_BACKEND)
    .post('/card/check-list/add')
    .data(formData)
    .success((a) => {
      dispatch({
        type: ADD_CHECKLIST_ITEM,
        payload: a.result,
      });
      successToast({ title: 'Success', msg: a.message });
    })
    .error((err) => {
      errorToast({ title: 'Failure', msg: 'Error in fetching the data' });
    })
    .send();
};

// Edit checklist item
export const editChecklistItem = (formData) => async (dispatch) => {
  api()
    .root(TASK_MANAGEMENT_ROOT_API_BACKEND)
    .post('/card/check-list/edit')
    .data(formData)
    .success((a) => {
      dispatch(hideLoading());
      dispatch({
        type: EDIT_CHECKLIST_ITEM,
        payload: a.result,
      });
      successToast({ title: 'Success', msg: a.message });
    })
    .error((err) => {
      errorToast({ title: 'Failure', msg: 'Error in fetching the data' });
    })
    .send();
};

// Complete/Uncomplete checklist item
export const completeChecklistItem = (formData) => async (dispatch) => {
  api()
    .root(TASK_MANAGEMENT_ROOT_API_BACKEND)
    .post('/card/check-list/complete-status')
    .data(formData)
    .success((a) => {
      dispatch({
        type: COMPLETE_CHECKLIST_ITEM,
        payload: a.result,
      });
      successToast({ title: 'Success', msg: a.message });
    })
    .error((err) => {
      errorToast({ title: 'Failure', msg: 'Error in fetching the data' });
    })
    .send();
};

// Delete checklist item
export const deleteChecklistItem = (formData) => async (dispatch) => {
  api()
    .root(TASK_MANAGEMENT_ROOT_API_BACKEND)
    .post('/card/check-list/delete')
    .data(formData)
    .success((a) => {
      dispatch(hideLoading());
      dispatch({
        type: DELETE_CHECKLIST_ITEM,
        payload: a.result,
      });
      successToast({ title: 'Success', msg: a.message });
    })
    .error((err) => {
      errorToast({ title: 'Failure', msg: 'Error in fetching the data' });
    })
    .send();
};

export const boardStatus = (boardId) => async (dispatch) => {
  try {
    const res = await axios.post(`http://localhost:5000/api/checklists/${boardId}`);

    dispatch({
      type: BOARD_STATUS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: BOARD_STATUS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

export const deleteCardComment = (formData) => async (dispatch) => {
  api()
    .root(TASK_MANAGEMENT_ROOT_API_BACKEND)
    .post('/card/deleteComments')
    .data(formData)
    .success((a) => {
      dispatch({ type: EDIT_CARD, payload: a.result });
      successToast({ title: 'Success', msg: a.message });
    })
    .error((err) => {
      errorToast({ title: 'Failure', msg: 'Error in fetching the data' });
    })
    .send();
};
