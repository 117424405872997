import React from 'react';
import dashboardIcon from '../../../assets/images/admin/admin_image/dashboardIcon.png';
import jobSeekerIcon from '../../../assets/images/admin/icons/manage-jobseeker.png';
import employerIcon from '../../../assets/images/admin/icons/manage-employer.png';
import jobIcon from '../../../assets/images/admin/icons/manage-job.png';
import chatIcon from '../../../assets/images/admin/icons/manage-chat.png';
import pendingIcon from '../../../assets/images/admin/icons/manage-pending.png';
import accepteIcon from '../../../assets/images/admin/icons/manage-accepted.png';
import no_user from '../../../assets/images/admin/admin_image/no-user-avatar.png';
import {
    CUSTOMER_SUPPORT_DASHBOARD,
    CUSTOMER_SUPPORT_MANAGE_JOBS,
    CUSTOMER_SUPPORT_PENDING_JOB,
    CUSTOMER_SUPPORT_EMPLOYER_LIST,
    CUSTOMER_SUPPORT_PENDING_EMPLOYER,
    CUSTOMER_SUPPORT_JOB_SEEKER_LIST,
    CUSTOMER_SUPPORT_PENDING_JOB_SEEKER,
    CUSTOMER_SUPPORT_MANAGE_CHAT,
    CUSTOMER_SUPPORT_FREETRAIL_USERS,
    CUSTOMER_SUPPORT_SUBSCRIBED_USERS,
    CUSTOMER_SUPPORT_PROTECTED_LIST,
    CUSTOMER_SUPPORT_MANAGE_USERS,
    CUSTOMER_SUPPORT_AGREEMENT_USERS,
    CUSTOMER_SUPPORT_DELETE_USERS,
    CUSTOMER_SUPPORT_REPORTED_JOBS, CUSTOMER_SUPPORT_PENDING_REPORTED_JOBS,
    CUSTOMER_SUPPORT_TRIAL_CHARGE_LIST,
} from '../../../config/constant/routePathConstant';
import SideBarTabs from './sideBarTabs';
const dashboard = '/admin/dashboard';

const SideBar = () => {
    const sideBarData = [
        // DASHBOARD
        {
            name: "Dashboard",
            link: CUSTOMER_SUPPORT_DASHBOARD,
            icon: dashboardIcon,
            alt: "dashboardIcon",
            active: CUSTOMER_SUPPORT_DASHBOARD,
            activeId: "",
            sub: { condition: false, start: 0, end: 0 },
            subMenu: {
                condition: false
            }
        },
        // MANAGE JOB
        {
            parentName: "Manage Jobs",
            parentIcon: jobIcon,
            id: "job",

            subMenuData: [
                {
                    name: "List of Jobs",
                    link: CUSTOMER_SUPPORT_MANAGE_JOBS,
                    icon: pendingIcon,
                    alt: "Pending icon",
                    active: CUSTOMER_SUPPORT_MANAGE_JOBS,
                    activeId: "",
                },
                {
                    name: "Verify Pending Jobs",
                    link: CUSTOMER_SUPPORT_PENDING_JOB,
                    icon: accepteIcon,
                    alt: "Accept icon",
                    active: CUSTOMER_SUPPORT_PENDING_JOB,
                    activeId: "",
                }
            ],
            subMenu: {
                condition: true,
            },
            activeParentTab: [
                CUSTOMER_SUPPORT_MANAGE_JOBS,
                CUSTOMER_SUPPORT_PENDING_JOB
            ],
        },
        // MANAGE EMLPOYER
        {
            parentName: "Manage Employer",
            parentIcon: employerIcon,
            id: "employer",

            subMenuData: [
                {
                    name: "List of Employers",
                    link: CUSTOMER_SUPPORT_EMPLOYER_LIST,
                    icon: pendingIcon,
                    alt: "Pending icon",
                    active: CUSTOMER_SUPPORT_EMPLOYER_LIST,
                    activeId: "",
                },
                {
                    name: "Verify Pending Employers",
                    link: CUSTOMER_SUPPORT_PENDING_EMPLOYER,
                    icon: accepteIcon,
                    alt: "Accept icon",
                    active: CUSTOMER_SUPPORT_PENDING_EMPLOYER,
                    activeId: "",
                },
                {
                    name: "Manage Free Trial Users",
                    link: CUSTOMER_SUPPORT_FREETRAIL_USERS,
                    icon: accepteIcon,
                    alt: "Accept icon",
                    active: CUSTOMER_SUPPORT_FREETRAIL_USERS,
                    activeId: "",
                }
            ],
            subMenu: {
                condition: true,
            },
            activeParentTab: [
                CUSTOMER_SUPPORT_EMPLOYER_LIST,
                CUSTOMER_SUPPORT_PENDING_EMPLOYER
            ],
        },
        // MANAGE JOBSEEKER
        {
            parentName: "Manage Jobseeker",
            parentIcon: jobSeekerIcon,
            id: "jobseeker",

            subMenuData: [
                {
                    name: "List of Jobseekers",
                    link: CUSTOMER_SUPPORT_JOB_SEEKER_LIST,
                    icon: pendingIcon,
                    alt: "Pending icon",
                    active: CUSTOMER_SUPPORT_JOB_SEEKER_LIST,
                    activeId: "",
                },
                {
                    name: "Verify Pending Jobseekers",
                    link: CUSTOMER_SUPPORT_PENDING_JOB_SEEKER,
                    icon: accepteIcon,
                    alt: "Accept icon",
                    active: CUSTOMER_SUPPORT_PENDING_JOB_SEEKER,
                    activeId: "",
                }
            ],
            subMenu: {
                condition: true,
            },
            activeParentTab: [
                CUSTOMER_SUPPORT_PENDING_JOB_SEEKER,
                CUSTOMER_SUPPORT_JOB_SEEKER_LIST
            ],
        },
        // MANAGE CHAT
        {
            name: "Manage Chat",
            link: CUSTOMER_SUPPORT_MANAGE_CHAT,
            icon: chatIcon,
            alt: "Chat icon",
            active: CUSTOMER_SUPPORT_MANAGE_CHAT,
            activeId: "",
            sub: { condition: false, start: 0, end: 0 },
            subMenu: {
                condition: false
            }
        },
        {
            name: "Subscribed users",
            link: CUSTOMER_SUPPORT_SUBSCRIBED_USERS,
            icon: chatIcon,
            alt: "Chat icon",
            active: CUSTOMER_SUPPORT_SUBSCRIBED_USERS,
            activeId: "",
            sub: { condition: false, start: 0, end: 0 },
            subMenu: {
                condition: false
            }
        },
        {
            name: "Protection users",
            link: CUSTOMER_SUPPORT_PROTECTED_LIST,
            icon: chatIcon,
            alt: "Chat icon",
            active: CUSTOMER_SUPPORT_PROTECTED_LIST,
            activeId: "",
            sub: { condition: false, start: 0, end: 0 },
            subMenu: {
                condition: false
            }
        },
        {
            name: "Login Records",
            link: CUSTOMER_SUPPORT_MANAGE_USERS,
            icon: chatIcon,
            alt: "Chat icon",
            active: CUSTOMER_SUPPORT_MANAGE_USERS,
            activeId: "",
            sub: { condition: false, start: 0, end: 0 },
            subMenu: {
                condition: false
            }
        },
        {
            name: "Compliant Agreement",
            link: CUSTOMER_SUPPORT_AGREEMENT_USERS,
            icon: chatIcon,
            alt: "Chat icon",
            active: CUSTOMER_SUPPORT_AGREEMENT_USERS,
            activeId: "",
            sub: { condition: false, start: 0, end: 0 },
            subMenu: {
                condition: false
            }
        },
        {
            name: "Delete Users List",
            link: CUSTOMER_SUPPORT_DELETE_USERS,
            icon: chatIcon,
            alt: "Chat icon",
            active: CUSTOMER_SUPPORT_DELETE_USERS,
            activeId: "",
            sub: { condition: false, start: 0, end: 0 },
            subMenu: {
                condition: false
            }
        },
        {
            parentName: "Reported Jobs",
            parentIcon: chatIcon,
            id: "reportjobs",

            // active:  {
            //     name: "Reported Jobs",
            //     link: CUSTOMER_SUPPORT_REPORTED_JOBS,
            //     icon: chatIcon,
            //     alt: "Chat icon",
            //     active: CUSTOMER_SUPPORT_REPORTED_JOBS,
            //     activeId: "",
            //     sub: { condition: false, start: 0, end: 0 },
            //     subMenu: {
            //         condition: false
            //     }
            // },
            // activeId: "",
            // sub: { condition: false, start: 0, end: 0 },
            // subMenu: {
            //     condition: false
            // }
            subMenuData: [
                {
                    name: "Reviewed Jobs",
                    link: CUSTOMER_SUPPORT_REPORTED_JOBS,
                    icon: chatIcon,
                    alt: "Chat icon",
                    active: CUSTOMER_SUPPORT_REPORTED_JOBS,
                    activeId: "",
                },
                {
                    name: "Pending report Jobs",
                    link: CUSTOMER_SUPPORT_PENDING_REPORTED_JOBS,
                    icon: accepteIcon,
                    alt: "Accept icon",
                    active: CUSTOMER_SUPPORT_PENDING_REPORTED_JOBS,
                    activeId: "",
                }
            ],
            subMenu: {
                condition: true,
            },
            activeParentTab: [
                CUSTOMER_SUPPORT_REPORTED_JOBS,
                CUSTOMER_SUPPORT_PENDING_REPORTED_JOBS
            ],
        },
        {
            name: "User Agreement List",
            link: CUSTOMER_SUPPORT_TRIAL_CHARGE_LIST,
            icon: chatIcon,
            alt: "Chat icon",
            active: CUSTOMER_SUPPORT_TRIAL_CHARGE_LIST,
            activeId: "",
            sub: { condition: false, start: 0, end: 0 },
            subMenu: {
                condition: false
            }
        },
    ]

    const mapSideBar = sideBarData.map((item, i) => (
        <SideBarTabs key={i}
            name={item.name} link={item.link} icon={item.icon} alt={item.alt} active={item.active} sub={item.sub} activeId={item.activeId} sub_menu={item.subMenu}
            activeParentTab={item.activeParentTab} subMenuData={item.subMenuData} data={item}
        />
    ))

    return (
        <>
            <aside className="app-sidebar">
                <div className="app-sidebar__user">
                    <div className="imgupload">
                        <img style={{ backgroundColor: "White" }}
                            className={`app-sidebar__user-avatar ${dashboard === window.location.pathname ? 'active' : ''
                                }`}
                            src={no_user}
                            alt="Admin Image"
                        />
                    </div>
                    <div>
                        {/* <p className="app-sidebar__user-name">John Doe</p> */}
                        <span className="app-sidebar__user-designation">Customer Support</span>
                    </div>
                </div>
                <ul className="app-menu">
                    {mapSideBar}
                </ul>
            </aside>
        </>
    );
}

export default SideBar