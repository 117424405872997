import React from 'react'
import { Link } from 'react-router-dom'
import { NDA_PAGE } from '../../../config/constant/routePathConstant'

function ContractBanner({protectedPendingSign}) {
    let {contract_details:{client_name,position},_id} = protectedPendingSign[0]
  return (
    <div className='contract_banner'><div><p>Your employer <strong>{client_name}</strong> who hired you as a <strong>{position}</strong> has requested you to sign a <strong>contractor's&nbsp;agreement</strong> to make your work binding.</p></div><div><Link 
    to={NDA_PAGE}><button className='px-4'>Sign Now</button></Link></div></div>
  )
}

export default ContractBanner