import React from 'react';
import no_user from '../../../assets/images/admin/admin_image/no-user-avatar.png';
import vesselDocumentIcon from '../../../assets/images/admin/admin_image/vesselDocumentIcon.png';
import dashboardIcon from '../../../assets/images/admin/admin_image/dashboardIcon.png';
import employerVeriIcon from '../../../assets/images/admin/icons/employer-verifications.png';
import jobSeekerVeriIcon from '../../../assets/images/admin/icons/jobseeker-verifications.png';
import jobSeekerIcon from '../../../assets/images/admin/icons/manage-jobseeker.png';
import employerIcon from '../../../assets/images/admin/icons/manage-employer.png';
import ticketIcon from '../../../assets/images/admin/icons/manage-tickets.png';
import categoryIcon from '../../../assets/images/admin/icons/manage-category.png';
import chatIcon from '../../../assets/images/admin/icons/manage-chat.png';
import jobIcon from '../../../assets/images/admin/icons/manage-job.png';
import notificationIcon from '../../../assets/images/admin/icons/manage-notification.png';
import paymentIcon from '../../../assets/images/admin/icons/manage-payments.png';
import skillsIcon from '../../../assets/images/admin/icons/manage-skills.png';
import pendingIcon from '../../../assets/images/admin/icons/manage-pending.png';
import accepteIcon from '../../../assets/images/admin/icons/manage-accepted.png';
import rejectedIcon from '../../../assets/images/admin/icons/manage-rejected.png';
import activityIcon from '../../../assets/images/admin/icons/manage-activity-log.png';
import { getUserData } from '../../../helpers/projectHelper';

import { Link } from 'react-router-dom';
import {
  ACCOUNT_JOB_SEEKER_VERIFICATION,
  ACCOUNT_EMPLOYER_VERIFICATION,
  ACCOUNT_EMPLOYER_ACCEPTED,
  ACCOUNT_EMPLOYER_REJECTED,
  ACCOUNT_JOB_SEEKER_ACCEPTED,
  ACCOUNT_JOB_SEEKER_REJECTED,
} from '../../../config/constant/routePathConstant';

const dashboard = '/admin/dashboard';

const SideBar = () => {
  const { full_name = null } = getUserData();
  return (
    <>
      <aside className="app-sidebar">
        <div className="app-sidebar__user">
          <div className="imgupload">
          <img style={{backgroundColor: "White"}}
              className={`app-sidebar__user-avatar ${
                dashboard === window.location.pathname ? 'active' : ''
              }`}
              src={no_user}
              alt="Account Verify Image"
            />
          </div>
          <div>
            <p className="app-sidebar__user-name">{full_name}</p>
            <span className="app-sidebar__user-designation">Account Verifier</span>
          </div>
        </div>
        <ul className="app-menu">
          <li>
            <a
              className={`app-menu__item dropdown-toggle ${
                ACCOUNT_EMPLOYER_VERIFICATION === window.location.pathname ||
                ACCOUNT_EMPLOYER_REJECTED === window.location.pathname ||
                ACCOUNT_EMPLOYER_ACCEPTED === window.location.pathname
                  ? 'active'
                  : ''
              } `}
              href="#employerVerification"
              aria-expanded="false"
              data-toggle="collapse"
            >
              <div className="imgclrs clrff8830">
                <img src={employerVeriIcon} alt="dashboardIcon" />
              </div>

              <span className="app-menu__label">Employer Verifications</span>
            </a>
            <ul className="appmenu collapse" id="employerVerification">
              <li>
                <Link
                  className={`app-sub_menu__item   ${
                    ACCOUNT_EMPLOYER_VERIFICATION === window.location.pathname ? 'active' : ''
                  }`}
                  to={ACCOUNT_EMPLOYER_VERIFICATION}
                  aria-expanded="false"
                  data-toggle="collapse"
                >
                  <div className="imgclrs clrff8830">
                    <img src={pendingIcon} alt="dashboardIcon" />
                  </div>

                  <span className="app-menu__label">Pending</span>
                </Link>
              </li>
              <li>
                <Link
                  className={`app-sub_menu__item  ${
                    ACCOUNT_EMPLOYER_REJECTED === window.location.pathname ? 'active' : ''
                  }`}
                  to={ACCOUNT_EMPLOYER_REJECTED}
                  aria-expanded="false"
                  data-toggle="collapse"
                >
                  <div className="imgclrs clrff8830">
                    <img src={jobSeekerVeriIcon} alt="dashboardIcon" />
                  </div>

                  <span className="app-menu__label">Rejected</span>
                </Link>
              </li>
              <li>
                <Link
                  className={`app-sub_menu__item   ${
                    ACCOUNT_EMPLOYER_ACCEPTED === window.location.pathname ? 'active' : ''
                  }`}
                  to={ACCOUNT_EMPLOYER_ACCEPTED}
                  aria-expanded="false"
                  data-toggle="collapse"
                >
                  <div className="imgclrs clrff8830">
                    <img src={vesselDocumentIcon} alt="dashboardIcon" />
                  </div>

                  <span className="app-menu__label">Verified</span>
                </Link>
              </li>
            </ul>
          </li>

          <li>
            <a
              className={`app-menu__item dropdown-toggle ${
                ACCOUNT_JOB_SEEKER_VERIFICATION === window.location.pathname ||
                ACCOUNT_JOB_SEEKER_REJECTED === window.location.pathname ||
                ACCOUNT_JOB_SEEKER_ACCEPTED === window.location.pathname
                  ? 'active'
                  : ''
              } `}
              href="#jobSeekerVerification"
              aria-expanded="false"
              data-toggle="collapse"
            >
              <div className="imgclrs clrff8830">
                <img src={jobSeekerVeriIcon} alt="dashboardIcon" />
              </div>

              <span className="app-menu__label">Jobseeker Verifications</span>
            </a>
            <ul className="appmenu collapse" id="jobSeekerVerification">
              <li>
                <Link
                  className={`app-sub_menu__item   ${
                    ACCOUNT_JOB_SEEKER_VERIFICATION === window.location.pathname ? 'active' : ''
                  }`}
                  to={ACCOUNT_JOB_SEEKER_VERIFICATION}
                  aria-expanded="false"
                  data-toggle="collapse"
                >
                  <div className="imgclrs clrff8830">
                    <img src={vesselDocumentIcon} alt="dashboardIcon" />
                  </div>

                  <span className="app-menu__label">Pending</span>
                </Link>
              </li>
              <li>
                <Link
                  className={`app-sub_menu__item   ${
                    ACCOUNT_JOB_SEEKER_REJECTED === window.location.pathname ? 'active' : ''
                  }`}
                  to={ACCOUNT_JOB_SEEKER_REJECTED}
                  aria-expanded="false"
                  data-toggle="collapse"
                >
                  <div className="imgclrs clrff8830">
                    <img src={vesselDocumentIcon} alt="dashboardIcon" />
                  </div>

                  <span className="app-menu__label">Rejected</span>
                </Link>
              </li>
              <li>
                <Link
                  className={`app-sub_menu__item   ${
                    ACCOUNT_JOB_SEEKER_ACCEPTED === window.location.pathname ? 'active' : ''
                  }`}
                  to={ACCOUNT_JOB_SEEKER_ACCEPTED}
                  aria-expanded="false"
                  data-toggle="collapse"
                >
                  <div className="imgclrs clrff8830">
                    <img src={vesselDocumentIcon} alt="dashboardIcon" />
                  </div>

                  <span className="app-menu__label">Verified</span>
                </Link>
              </li>
            </ul>
          </li>
   
        </ul>
      </aside>
    </>
  );
};

export default SideBar;
