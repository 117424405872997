import { useSession, useSocket } from '../../../helpers/hooks.js';
import { useDispatch, useSelector } from 'react-redux';
import {
  chatMessageStore,
  setAttachFileId,
  setAttachLoading,
  socketSelector,
} from '../../../redux/slicers/socketSlicer.js';
import React, { useRef, useState } from 'react';
import { retrieveImageFromClipboardAsBlob, timeRandomName } from '../../../helpers/jsHelper.js';
import { checkChatApi, fileUploadApi } from '../../../api/chat/chatApi.js';
import { FILE_IMG } from '../../../config/constant/imageConstant';

const ChatFooter = ({ setScrollBottom = null }) => {
  const { user_id: from_id } = useSession();
  const dispatch = useDispatch();
  const [attach, setAttach] = useState();
  const { currentChat = {}, connection = false } = useSelector(socketSelector);
  const {
    to_details: { _id: to_id },
    chat_id = null,
    temp_chat_id,
  } = currentChat;
  const final_chat_id = chat_id || temp_chat_id;
  const { sendMsg, sendTyping } = useSocket();
  const input = useRef();
  const file = useRef();
  let timer = null;
  let typingCall = true;

  const handleTrigger = (e) => {
    const key = e.keyCode || e.which;
    if (key === 13) {
      e.preventDefault();
      if (connection) {
        handleSubmit();
      }
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    retrieveImageFromClipboardAsBlob(e, (a) => {
      if (a) {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(a);
        fileReader.onload = () => {
          setAttach({ src: fileReader.result, files: a, type: 'image', name: a.name });
        };
      }
    });
    const text = (e.originalEvent || e).clipboardData.getData('text/plain');
    document.execCommand('insertHTML', false, text);
  };

  const handleSubmit = () => {
    let { textContent: content = '' } = input.current;
    if (content.trim() || attach) {
      content = content.trim();
      if (timer) {
        clearTimeout(timer);
        sendTyping({ chat_id, typing: false }, to_id);
      }
      const rand_id = timeRandomName('messge');
      let msgData = {
        _id: rand_id,
        content,
        chat_id: final_chat_id,
        msg_time: Date.now(),
        from_id,
      };
      const attachType = attach?.type;
      if (attach?.src) {
        msgData['file_id'] = null;
        msgData['file_type'] = attachType;
        msgData['msg_type'] = 'file';
        msgData['src'] = attach?.src;
      }
      dispatch(chatMessageStore(msgData));

      if (attach?.src) {
        handleClose();
        const formData = new FormData();
        formData.append('file', attach.files);
        dispatch(setAttachLoading({ _id: rand_id, status: true }));
        setTimeout(() => {
          dispatch(
            fileUploadApi(formData, (res) => {
              dispatch(setAttachLoading({ _id: rand_id, status: false }));
              dispatch(setAttachFileId({ _id: rand_id, fileId: res.file_id }));
              sendMsgToServer(content, {
                file_id: res?.file_id,
                msg_type: 'file',
                file_type: attachType,
              });
              setScrollBottom(true);
            }),
          );
        }, 10);
      } else {
        sendMsgToServer(content);
        setScrollBottom(true);
      }
    }
  };

  const sendMsgToServer = (content, extra = {}) => {
    sendMsg({ content, chat_id: final_chat_id, temp_chat_id, ...extra }, to_id);
    if (!chat_id) {
      setTimeout(() => {
        dispatch(checkChatApi(to_id));
      }, 2000);
    }
    input.current.innerHTML = '';
  };

  let sendTypingTimer;
  const handleTyping = (e) => {
    const { textContent: content = '' } = e.target;
    if (content.trim()) {
      if (typingCall) {
        sendTyping({ chat_id, typing: true }, to_id);
        typingCall = false;
        timer = setTimeout(() => {
          typingCall = true;
        }, 3000);
      }
      if (sendTypingTimer) {
        clearTimeout(sendTypingTimer);
      }
      sendTypingTimer = setTimeout(() => {
        sendTyping({ chat_id, typing: false }, to_id);
        typingCall = true;
      }, [2000]);
    }
  };
  const handleAttach = (e) => {
    const files = e.target.files[0];

    if (!files) {
      return;
    }
    const file_type = files.type.indexOf('image') > -1 ? 'image' : 'other';
    const fileReader = new FileReader();
    fileReader.readAsDataURL(files);
    fileReader.onload = () => {
      setAttach({ src: fileReader.result, files, type: file_type, name: files.name });
    };
  };
  const handleClose = () => {
    setAttach(null);
    file.current.value = '';
  };

  return (
    <div className="type-message-section">
      {attach?.src && (
        <div className={'msg-attach-view'}>
          {attach.type === 'image' && <img src={attach.src} className={'image'} alt={'attach'} />}
          {attach.type === 'other' && (
            <>
              <img src={FILE_IMG} className={'other'} alt={'attach'} width={100} height={100} />
              <b className={'other position-absolute text'}>{attach?.name}</b>
            </>
          )}
          <i
            onClick={handleClose}
            className={'fa fa-times-circle fa-2x iconButton position-absolute pointer'}
            style={{ fontSize: 30 }}
          />
        </div>
      )}
      <label className="px-2 align-self-center mb-0">
        <i className="iconButton fa fa-paper-plane  chat-send-button" onClick={handleSubmit} />
      </label>
      <div className="type-message-sub-section">
        {!connection && <small>Unable to Connect. Refresh and try again.</small>}
        <div
          ref={input}
          className={'chat-text-box text-break'}
          onKeyDown={handleTrigger}
          onKeyPress={handleTyping}
          onPaste={handlePaste}
          contentEditable="true"
          placeholder="Type Message here..."
          autoFocus
        />
      </div>
      <label htmlFor={'file-input'} className="px-2 align-self-center mb-0">
        <input type="file" id={'file-input'} hidden ref={file} onChange={handleAttach} />
        <i className="iconButton fas fa-paperclip" />
      </label>
    </div>
  );
};

export default ChatFooter;
