import api from '../../helpers/axiosHelper';
import {
  APPLIED_JOBS_LIST,
  BOOKMARK_API,
  JOB_SEEKER_API_ROOT,
  JOBSEEKER_NOTIFICATION_SETTING_LIST,
  JOBSEEKER_NOTIFICATION_SETTING_UPDATE,
  JOBSEEKER_NOTIFY_API,
  JOBSEEKER_NOTIFY_UPDATE_API,
  JOBSEEKER_SCHEDULED_EVENTS,
  LOGIN_API_ROOT,
  JOBSEEKER_PROFILE_CHECK,
} from '../../config/constant/apiConstant';

// import {
//   devConsoleLog,
//   errorToast,
//   sessionDestroy,
//   storeLocal,
//   successToast,
// } from '../helpers/projectHelper.js';
import {
  appliedJobListArray,
  bookmarkedSuccess,
  getNotiftyData,
  getProfileCheck,
  getScheduledListData,
  getSettingsData,
} from '../../redux/slicers/jobSeekerSlice';
import { getUserData } from '../../helpers/projectHelper';
// import { ROLE_NAV, USER_DATA } from '../config/constant/projectConstant.js';
// import { LOGIN } from '../config/constant/routePathConstant.js';

export const bookmarkedApi = (formData) => (dispatch) => {
  console.log(formData, 'formData');
  //   dispatch(bookmarkedSuccess(true));
  api()
    .root(JOB_SEEKER_API_ROOT)
    .post(BOOKMARK_API)
    .data(formData)
    .success((a) => {
      const { result = {} } = a;

      dispatch(bookmarkedSuccess(result));
    })
    .error((e) => {
      console.log('error', e);
    })
    .send(() => {
      api()
    .root(JOB_SEEKER_API_ROOT)
    .post(APPLIED_JOBS_LIST)
    .data(formData)
    .success((a) => {
      const { result = {} } = a;

      dispatch(appliedJobListArray(result));
    }).send()
    });
};

export const getAppliedJobs = (formData) => (dispatch)  => {
  api()
  .root(JOB_SEEKER_API_ROOT)
  .post(APPLIED_JOBS_LIST)
  .data(formData)
  .success((a) => {
    const { result = {} } = a;

    dispatch(appliedJobListArray(result));
  }).send()
  
}
export const userNotificationApi = (formData) => (dispatch) => {
  const data = {
     user_id : formData
  }
  api().root(JOB_SEEKER_API_ROOT).post(JOBSEEKER_NOTIFICATION_SETTING_LIST).data(data).success(a => {
         dispatch(getNotiftyData(a?.result?.notification_settings));
     }).send();
};

export const userUpdateNotificationApi = (formData) => (dispatch) => {
 
  api().root(JOB_SEEKER_API_ROOT).post(JOBSEEKER_NOTIFICATION_SETTING_UPDATE).data(formData).success(a => {
         dispatch(getNotiftyData(a?.result?.notification_settings));
         const {user_id =''} = getUserData();
        //  const data = {
        //    employer_id : user_id
        // }
         dispatch(userNotificationApi(user_id));
        console.log(a);
     }).send();
};
export const jobseekerNotifyApi = (formData) => (dispatch) => {
  api().root(JOB_SEEKER_API_ROOT).post(JOBSEEKER_NOTIFY_API).data(formData).success(a => {
    dispatch(getSettingsData(a?.result));
}).send();
}
export const userJobOfferCountApi = (formData) => (dispatch) => {
  
  api().root(JOB_SEEKER_API_ROOT).post(JOBSEEKER_NOTIFY_UPDATE_API).data(formData).success(a => {
        // dispatch(empOfferCountData(a.result))
       
     }).send();
};

export const jobSeekerEventScheduled = (formData) => (dispatch) => {
  api().root(LOGIN_API_ROOT).post(JOBSEEKER_SCHEDULED_EVENTS).data(formData).success(a => {
    dispatch(getScheduledListData(a?.result));
   
   console.log(a);
}).send();

}
export const getUserDetailsApi = (formData) => (dispatch) => {
  api().root(JOB_SEEKER_API_ROOT).post(JOBSEEKER_PROFILE_CHECK).data(formData).success(a => {
    dispatch(getProfileCheck(a?.result));
}).send();

}