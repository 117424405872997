import { createSlice } from '@reduxjs/toolkit';

const postJobSlice = createSlice({
   name: 'postJob',
   initialState: {
      skillsData: [],
      categoryData: [],
      formData: null,
      loading:false,
      disabledIndex:10,
      jobTitleData: []
   },
   reducers: {
      postJobFormData: (state, { payload }) => {
         state.formData = {...state.formData,...payload};
      },
      postJobSkillsData: (state, { payload }) => {
         state.skillsData = payload;
      },
      resetPostFormData: (state, { payload }) => {
         state.formData = {};
      },
      postJobCategoryData: (state, { payload }) => {
         state.categoryData = payload;
      },
      postJobLoading: (state, { payload })=>{
         state.loading = payload;
      },
      setDisabledIndex: (state, { payload })=>{
         state.disabledIndex = payload;
      },
      postJobTitleData: (state, {payload}) => {
         state.jobTitleData = payload;
      }
   },
});

export const {postJobTitleData,resetPostFormData, setDisabledIndex, postJobFormData,postJobLoading,postJobSkillsData,postJobCategoryData } = postJobSlice.actions;
export const postJobSelector = state => state.postJob;
const postJobReducer = postJobSlice.reducer;
export default postJobReducer;
