import React from 'react';
import '../../assets/css/stopWatch.scss';
import { RouterBuilder } from '../common/router.js';
import TIME_SHEET_ROUTES from '../../config/routes/timeSheetRoutes';
import { LazyLoader } from '../common/loader.js';
import Header from './common/Header';
import Footer from './common/footer';
import { getUserData } from '../../helpers/projectHelper';
import FooterEmployer from './common/footer-employer';
const TimeSheetContainer = () => {
  const { user_type } = getUserData();
  return (
    <div className="tracker timesheet-container-sass timesheet_container_sass">
      {/* <OtherSessionsChecker onlyToken sessionFailPath={LOGIN} currentRoute={TIME_SHEET}> */}
      <Header />
      <LazyLoader>
        <RouterBuilder data={TIME_SHEET_ROUTES} />
      </LazyLoader>
      {user_type == 'job_seeker' ? <Footer /> : <FooterEmployer />}
      {/* </OtherSessionsChecker> */}
    </div>
  );
};

export default TimeSheetContainer;
