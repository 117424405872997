import { APP_NOTIFICATION_LIST, APP_UPDATE_NOTIFICATION, LOGIN_API_ROOT } from "../config/constant/apiConstant";
import {getNotificationData} from "../redux/slicers/socketSlicer";
import api from '../helpers/axiosHelper';
import { getUserData } from "../helpers/projectHelper";
export const getNotificationApi = (formData) => (dispatch) => {
   api()
  .root(LOGIN_API_ROOT)
  .post(APP_NOTIFICATION_LIST)
  .data(formData)
  .success((result) => {
        dispatch(getNotificationData(result));
  })
  .error((e) => {
    console.log('error', e);
  
  })
  .send()
}
export const updateNotificationApi = (formData) => (dispatch) => {
  const {user_id = ''} = getUserData()
  const data = {
     notification_id:formData,
      query : {
       is_read:true
    },
    user_id: user_id
  }
  
   
   
    api()
   .root(LOGIN_API_ROOT)
   .post(APP_UPDATE_NOTIFICATION)
   .data(data)
   .success((result) => {
    dispatch(getNotificationData(result))
   })
   .error((e) => {
     console.log('error', e);
   
   })
   .send()
 }