import React from 'react';
import { Link } from 'react-router-dom';
import {
  LOGIN,
  SIGNUP,
  WEBSITE_ENTERPRISE,
  WEBSITE_FIND_JOBS,
  WEBSITE_FIND_STAFF,
  WEBSITE_HOMEPAGE,
  WEBSITE_HOW_IT_WORKS_EMPLOYERS,
  WEBSITE_HOW_IT_WORKS_JOB_SEEKERS,
} from '../../../config/constant/routePathConstant.js';
import { List } from '../../common/list.js';
import { LOGO_IMG } from '../../../config/constant/imageConstant.js';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// const dummyOptions = [{ label: 'Link1' }, { label: 'Link2' }, { label: 'Link3' }];
const Header = () => {
  return (
    <div>
      <header className="header">
        <div className="container">
          <nav className="navbar navbar-expand-md">
            <Link target="_top" to={{ pathname: WEBSITE_HOMEPAGE }} >
              <a className="navbar-brand"><img className="img-fluid" src={LOGO_IMG} alt="logo" /></a>
            </Link>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar"><span className="navbar-toggler-icon"><svg width="23" height="20" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.23926 4.23096H21.5518C21.9833 4.23096 22.333 3.8812 22.333 3.44971V1.49658C22.333 1.06509 21.9833 0.715332 21.5518 0.715332H1.23926C0.807764 0.715332 0.458008 1.06509 0.458008 1.49658V3.44971C0.458008 3.8812 0.807764 4.23096 1.23926 4.23096ZM1.23926 12.0435H21.5518C21.9833 12.0435 22.333 11.6937 22.333 11.2622V9.30908C22.333 8.87759 21.9833 8.52783 21.5518 8.52783H1.23926C0.807764 8.52783 0.458008 8.87759 0.458008 9.30908V11.2622C0.458008 11.6937 0.807764 12.0435 1.23926 12.0435ZM1.23926 19.856H21.5518C21.9833 19.856 22.333 19.5062 22.333 19.0747V17.1216C22.333 16.6901 21.9833 16.3403 21.5518 16.3403H1.23926C0.807764 16.3403 0.458008 16.6901 0.458008 17.1216V19.0747C0.458008 19.5062 0.807764 19.856 1.23926 19.856Z" fill="black" /></svg></span></button>
            <div className="collapse navbar-collapse" id="collapsibleNavbar">
              <ul className="nav navbar-nav navbar-right">
                <li className="nav-item">
                  <Link target="_top" to={{ pathname: WEBSITE_FIND_JOBS }} ><a className="nav-link">Find Jobs</a></Link>
                </li>
                <li className="nav-item">
                  <Link target="_top" to={{ pathname: WEBSITE_FIND_STAFF }} ><a className="nav-link">Find staff</a></Link>
                </li>
                <li>
                  <div className="dropdown btnwidth">
                    <Link
                      className="dropdown-toggle howitwoksbtn" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                      style={{
                        color: "#004c70",
                        fontWeight: "bold",
                        fontStyle: "normal",
                        fontSize: "16px",
                        fontFamily: "Lato-bold"
                      }}
                    >
                      HOW IT WORKS
                    </Link>

                    <div className="dropdown-menu py-1 px-3" aria-labelledby="dropdownMenuButton">
                      <div className="d-flex align-items-center">
                        <i style={{ fontSize: "7px", marginRight: "10px" }} className="fas fa-circle"></i>
                        <a className="dropdown-item p-1" href={WEBSITE_HOW_IT_WORKS_JOB_SEEKERS}>For Jobseeker</a>
                      </div>
                      <div className="d-flex align-items-center">
                        <i style={{ fontSize: "7px", marginRight: "10px" }} className="fas fa-circle"></i>
                        <a className="dropdown-item p-1" href={WEBSITE_HOW_IT_WORKS_EMPLOYERS}>For Employer</a>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="nav-item">
                  <Link target="_top" to={{ pathname: WEBSITE_ENTERPRISE }} ><a className="nav-link">Enterprise</a></Link>
                </li>
                <li className="nav-item login-li">
                  <Link to={{ pathname: LOGIN }} >
                    <a className="login-text">
                      Log In
                    </a>
                  </Link>
                </li>
                <li className="nav-item signup-li">
                  <Link to={{ pathname: SIGNUP }} >
                    <a className="signup-text" >
                      Sign Up
                    </a>
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>
    </div>
  );
};

export default Header;
