import { createSlice } from '@reduxjs/toolkit';
import { menuIdUpdate } from '../../../helpers/projectHelper';
import { EMPLOYER_MENU } from '../../../config/constant/menuConstant';

const empDashboardSlice = createSlice({
  name: 'employerDashboard',
  initialState: {
    cardData: {
      job_count: null,
    },
    hiredStaffData: {},
    timeLogData: {},
    settings: {},
    jobOfferCount: null,
    jobApplicantCount: {},
    employerMenu: menuIdUpdate?.(EMPLOYER_MENU),
    employeeData: null,
    employeeTrackedTime: [],
    employeeTrackedTimeTable: null,
    employeeTrackedImageTable: null,
    employeeFilteredTrackedImageTable: null,
    employeeFilteredTrackedImageTableTIme: null,
    trackingLoader: false,
    employeeTrackedDay: null,
    totalTImeCount: 0,
    imageCount: 0,
    employeeWeaklyTrackedTIme: 0,
    employeeTodayTrackedTIme: 0,
    employeeManualTrackedTIme: 0,
    employeeManualWeeklyTrackedTIme: 0,
    employeeManualTodayTrackedTIme: 0,
    employeeTrackedDaysGroup: null,
    employerTaskManagementBoard: null,
    employerTaskManagementBoardcard: null,
    employerTaskManagementBoardList: null,
    employerTaskManagementBoardListObjects: [],
    employerTaskManagementBoardCardList: [],
  
    profileFormData: {},
    addressProof: null,
    dashboardLoading: true,
    weaklyReport:[],
    trialLoader:false,
  },
  reducers: {
    setTrialLoader: (state, { payload }) => {
      state.trialLoader = payload;
    },
    employerDashCardData: (state, { payload }) => {
      state.cardData = { ...state.cardData, ...payload };
    },
    employerDashHiredCardData: (state, { payload }) => {
      state.hiredStaffData = payload;
    },
    employerDashTimeCardData: (state, { payload }) => {
      state.timeLogData = payload;
    },
    employerSettingsData: (state, { payload }) => {
      state.settings = payload;
    },
    empOfferCountData: (state, { payload }) => {
      state.jobOfferCount = payload;
    },
    empApplicantCountData: (state, { payload }) => {
      state.jobApplicantCount = payload;
    },
    resetEmpSeekerMenu: (state, { payload }) => {
      state.employerMenu = menuIdUpdate?.(EMPLOYER_MENU);
    },
    setEmpSeekerMenu: (state, { payload }) => {
      const { name, subName = '' } = payload;
      state.employerMenu.forEach((a) => {
        a.active = a.active && a.id === name ? false : a.id === name
        if (a.subMenu) {
          a.subMenu.forEach((i) => {
            i.active = i.id === subName;
          });
        }
      });
    },
    employeeDatas: (state, { payload }) => {
      state.employeeData = payload;
    },
    employeeTrackedTimes: (state, { payload }) => {
      state.employeeTrackedTime = payload;
    },
    employeeTrackedImageTables: (state, { payload }) => {
      state.employeeTrackedImageTable = payload;
    },
    employeeFilteredTrackedImageTables: (state, { payload }) => {
      state.employeeFilteredTrackedImageTable = payload;
    },
    employeeFilteredTrackedImageTableTImes: (state, { payload }) => {
      state.employeeFilteredTrackedImageTableTIme = payload;
    },
    trackingLoaders: (state, { payload }) => {
      state.trackingLoader = payload;
    },
    employeeTrackedDays: (state, { payload }) => {
      state.employeeTrackedDay = payload;
    },
    employeeTrackedDaysGroups: (state, { payload }) => {
      state.employeeTrackedDaysGroup = payload;
    },
    totalTImeCounts: (state, { payload }) => {
      state.totalTImeCount = payload;
    },
    imageCounts: (state, { payload }) => {
      state.imageCount = payload;
    },
    employeeWeaklyTrackedTImes: (state, { payload }) => {
      state.employeeWeaklyTrackedTIme = payload;
    },
    employeeTodayTrackedTImes: (state, { payload }) => {
      state.employeeTodayTrackedTIme = payload;
    },
    employeeManualTrackedTImes: (state, { payload }) => {
      state.employeeManualTrackedTIme = payload;
    },
    employeeManualWeeklyTrackedTImes: (state, { payload }) => {
      state.employeeManualWeeklyTrackedTIme = payload;
    },
    employeeManualTodayTrackedTImes: (state, { payload }) => {
      state.employeeManualTodayTrackedTIme = payload;
    },
    weaklyReports:(state, { payload })=>{
      state.weaklyReport = payload;
    },
    employerTaskManagementBoards: (state, { payload }) => {
      state.employerTaskManagementBoard = payload;
    },
    employerTaskManagementBoardLists: (state, { payload }) => {
      state.employerTaskManagementBoardList = {
        BoardList: payload,
      };
    },
    employerTaskManagementBoardgetlist: (state, { payload }) => {
      console.log('payload', payload);
      state.employerTaskManagementBoardListObjects = [
        ...state.employerTaskManagementBoardListObjects,
        payload,
      ];
    },
    employerTaskManagementBoardlistcreates: (state, { payload }) => {
      state.employerTaskManagementBoardListObjects = [
        ...state.employerTaskManagementBoardListObjects,
        payload,
      ];
    },
    employerTaskManagementBoardCardLists: (state, { payload }) => {
      state.employerTaskManagementBoardCardList = [
        ...state.employerTaskManagementBoardCardList,
        payload,
      ];
    },
    employerTaskManagementBoardCreateCardLists: (state, { payload }) => {
      let listObjects = state.employerTaskManagementBoardListObjects.map((list) =>
        list._id === payload.listId ? { ...list, cards: [...list.cards, payload.cardId] } : list,
      );
      state.employerTaskManagementBoardListObjects = listObjects;
    },
    employerTaskManagementBoardListcard: (state, { payload }) => {
      state.employerTaskManagementBoardList = { ...state.employerTaskManagementBoardList, payload };
    },
    employerTaskManagementBoardcards: (state, { payload }) => {
      state.employerTaskManagementBoardcard = { ...state.employerTaskManagementBoardList, payload };
    },
    employerTaskManagementBoardRenameList: (state, { payload }) => {
      state.employerTaskManagementBoardList = {
        ...state.employerTaskManagementBoardList,
        listObjects: state.employerTaskManagementBoardList.listObjects.map((list) =>
          list._id === payload._id ? payload : list,
        ),
      };
    },
  },
  employerProfileFormData: (state, { payload }) => {
    state.profileFormData = payload;
  },
 
  addressProofAct: (state, { payload }) => {
    state.addressProof = payload;
  },
});

export const {
  employerDashCardData,
  employerDashHiredCardData,
  employerDashTimeCardData,
  employerSettingsData,
  empOfferCountData,
  empApplicantCountData,
  resetEmpSeekerMenu,
  setEmpSeekerMenu,
  employerProfileFormData,
  addressProofAct,
  employeeDatas,
  employeeTrackedTimes,
  employeeTrackedTimeTables,
  employeeTrackedImageTables,
  employeeFilteredTrackedImageTableTImes,
  employeeFilteredTrackedImageTables,
  trackingLoaders,
  employeeTrackedDays,
  totalTImeCounts,
  imageCounts,
  employeeWeaklyTrackedTImes,
  employeeTodayTrackedTImes,
  employeeManualTrackedTImes,
  employeeManualTodayTrackedTImes,
  employeeManualWeeklyTrackedTImes,
  weaklyReports,
  employeeTrackedDaysGroups,
  employerTaskManagementBoards,
  employerTaskManagementBoardLists,
  employerTaskManagementBoardRenameList,
  employerTaskManagementBoardgetlist,
  employerTaskManagementBoardcard,
  employerTaskManagementBoardlistcreates,
  employerTaskManagementBoardCardLists,
  employerTaskManagementBoardCreateCardLists,
  setTrialLoader
} = empDashboardSlice.actions;

export const employerMenu = (state) => state.employerDashboard.employerMenu;
export const employerDashboardSelector = (state) => state.employerDashboard;
const employerDashboardReducer = empDashboardSlice.reducer;
export default employerDashboardReducer;
