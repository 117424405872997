import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { EMPLOYER_DASHBOARD, EMPLOYER_SUBSCRIPTION_PRICING_PAGE, HELP_SUPPORT,ENTERPRISE_PAGE,WEBSITE_HOW_IT_WORKS_EMPLOYERS,WEBSITE_HELPFUL_RESOURCES} from '../../../config/constant/routePathConstant.js';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom';
import { menuReverseDetect, navigate, sessionDestroy } from '../../../helpers/projectHelper';
import { getFullDayFormat, spaceReduce } from '../../../helpers/jsHelper';
import { employerMenu, resetEmpSeekerMenu, setEmpSeekerMenu ,setTrialLoader } from '../../../redux/slicers/employer/empDashboardSlice';
import { getUserData, ImageLoader, successToast, warningToast } from '../../../helpers/projectHelper';
import { subscriptionSelector } from '../../../redux/slicers/sessionSlice';
import { hireEmployeeSelector } from '../../../redux/slicers/employer/hireEmployeeSlice';

import {empUpdateSubsribePlan} from '../../../api/employer/employer';
const EmployerSideBar = () => {
  const menu = useSelector(employerMenu);
  const { pathname } = useLocation()
  const isMatch = useRouteMatch(EMPLOYER_DASHBOARD)
  const dispatch = useDispatch();
  const [boolean, setBoolean] = useState(false)
  const [loading,setLoading] = useState(false)
  const {
    subscription_plan = null,
    plan_expiry = null,
    plan_days_left = null,
    is_trail=false,
    plan_id,
    protected_plan
  } = useSelector(subscriptionSelector);
  const { empProfileData: defaultData = {} } = useSelector(hireEmployeeSelector);

  const { user_id = null } = getUserData();
  useEffect(() => {
    if (isMatch) {
      dispatch(resetEmpSeekerMenu());
    }
    const res = menuReverseDetect(menu, pathname)
    if (res?.name) {
      dispatch(setEmpSeekerMenu({ name: spaceReduce(res?.name), subName: spaceReduce(res?.subName) }));
    }
  }, [])

  const handleNav = (name, subName = null) => () => {
    if (name === "LOGOUT") {
      sessionDestroy()
    } 
    if(name === 'Get Paid to Share VirtualStaff.ph') {
      window.open('https://affiliates.virtualstaff.ph/#/how-it-works-affiliate', '_blank');
    }
    dispatch(setEmpSeekerMenu({ name: spaceReduce(name), subName: spaceReduce(subName) }));
  };
  const handleRoute = (a) => {
    if (a?.name === 'Help & Support') {
      window.location.href = HELP_SUPPORT;
    } else if(a?.name === 'Enterprise Solution') {
      window.open(ENTERPRISE_PAGE, '_blank');
   
    }
    // else if(a?.name === 'How It Works') {
    //   window.open(WEBSITE_HOW_IT_WORKS_EMPLOYERS, '_blank');
   
    // }
    else if(a?.name === 'Helpful Resources'){
      window.open(WEBSITE_HELPFUL_RESOURCES, '_blank');
    }else {
      navigate(a?.path)
    }
  }
   
  const expiryString = plan_days_left  == '0' ? 'Today' : (plan_days_left  == '1' ? 'Tomorrow':plan_days_left)
  const handleUpdatePlan =() => {
    setLoading(true)
    dispatch(empUpdateSubsribePlan({'employerId': user_id},setLoading))
  }
  
  const getText = () => {
    if(!subscription_plan || subscription_plan == 'basic'){
      return  <div className="d-flex flex-column text-center text-capitalize text-under sidebar-text">
       
      <span className="mt-2">Free Plan</span>
      {plan_id && <p className="m-0">Expired Last {getFullDayFormat(plan_expiry)}</p>}
       <a className="text-decoration-underline" href={EMPLOYER_SUBSCRIPTION_PRICING_PAGE}><p className="text-decoration-underline">Click to upgrade</p></a>
      </div>
    }else if(subscription_plan === 'month' || subscription_plan == 'year'){
      return <div className="d-flex flex-column text-center text-capitalize text-under sidebar-text">
      <span className="font-weight-bold" style={{fontSize:"30px",color:"white"}}>{protected_plan ? "Business" : "Standard"}</span>
      {subscription_plan === 'month' && <p style={{color:"white"}}>(Monthly)</p> ||  <p style={{color:"white"}}>(Yearly)</p> }
      {/* <span style={{color:"#DBDF1E",fontSize: "20px",lineHeight: "24px"}}>Click to Upgrade</span> */}
    
        {/* {subscription_plan === 'month' && <Link className="text-decoration-underline" onClick={() => handleUpdatePlan()}>{loading ? <i class="fas fa-spinner fa-spin"></i> : 'Get Yearly! Save 40%'}</Link>} */}
      </div>
    }else if(subscription_plan === 'free'){
      return <div className="d-flex flex-column text-center text-capitalize text-under sidebar-text">
     {plan_days_left < 0 &&   <p className="m-0" style={{color:"red", textDecoration:"none"}}>Free Trial Ended</p> } 
     {plan_days_left === 0  &&   <p className="m-0" style={{color:"red", textDecoration:"none"}}>Expires today</p> }
     {plan_days_left > 1  &&   <p className="m-0" style={{color:"red", textDecoration:"none"}}>{plan_days_left} Days Left</p> }
     {plan_days_left === 1  &&   <p className="m-0" style={{color:"red", textDecoration:"none"}}>Expires tomorrow</p> }
      <span style={{textTransform:"uppercase" ,lineHeight:"6px"}} className="mt-2">Free Trial</span>
        <a  href={EMPLOYER_SUBSCRIPTION_PRICING_PAGE}><p className="text-decoration-underline">Click to upgrade</p></a>
      </div>
    }
  }
  return (
    <div className='side-nav employer-sidebar'>
      <div className='d-flex justify-content-center flex-column align-items-center mt-5'>
          {getText()}
       
        </div>
     <ul>
        {menu && menu.map(({ subMenu = null, icon = 'fas fa-circle', name = '', active = false, path = '' },i) => {
          if (subMenu) {
            return (
              <li key={'main-menu'+i} className={`sub-menu ${active ? 'employer-active' : ''} reduce-pad`}>
                <a onClick={handleNav(name)}><i
                  className={`${icon} mr-2`} /><span>{name}</span>
                  <div className={`fa ${active ? 'fa-caret-right' : 'fa-caret-down'} right custom-arrow-btn`} />
                </a>
                {active && subMenu && <ul className={'fadeIn'}>{subMenu.map((a,b) => (

                  <li key={'sub-menu'+b} onClick={handleNav(name, a?.name)} className={`hover-gray ${a?.active ? 'employer-active' : ''}`}>
                    <div onClick={() => handleRoute(a)} className='cursor-pointer'>
                    <a><span className={'text-black'}>{a?.name}</span></a>
                     {a?.text && <p className="sidebar-para">{a?.text}</p>} 
                     {a?.list && ( <div className="sidebar-list">{a?.list.map((ele,k) => <span key={'list'+k}><i class="fas fa-circle pr-2"></i>{ele}</span>)}</div>)}
                     {a?.endtext && <p className="sidebar-para">{a?.endtext}</p>} 
                     </div>
                    </li>
                   
                ))}</ul>}
              </li>
            );
          } else {
            return (
              <li onClick={handleNav(name)} className={`${active ? 'employer-active' : ''} hover-gray reduce-pad`}>
                <Link to={path}><i className={`${icon} mr-2`} /> <span>{name}</span></Link>
               
              </li>
            );
          }
        })}
      </ul>
    </div>
  );
};

export default EmployerSideBar;
