import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logoutApi } from '../../../api/session';
import { spaceReduce } from '../../../helpers/jsHelper';
import { Link } from 'react-router-dom';
import {
  jobSeekerMenu,
  jobSeekerSelector,
  resetJobSeekerMenu,
  setBadgeCount,
  setJobSeekerMenu,
} from '../../../redux/slicers/jobSeekerSlice';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { JOBSEEKER_DASHBOARD, JOBSEEKER_USER_PROFILE_PREVIEW, HELP_SUPPORT, STRIPE_ONBOARD_EXISTING_USER } from '../../../config/constant/routePathConstant';
import { getLocal, menuReverseDetect, navigate } from '../../../helpers/projectHelper';
import { unreadCountSelector } from '../../../redux/slicers/socketSlicer';
import WarningIcon from '@material-ui/icons/Warning';
import CancelIcon from '@material-ui/icons/Cancel';
import { getStripePageToggle, stripeOnboardSelector } from '../../../redux/slicers/stripeOnboardSlice';
import { JOBSEEKER_INVOICE } from './../../../config/constant/routePathConstant';
import Modal from 'react-bootstrap/Modal';

const Sidebar = () => {
  const width = window.innerWidth;
  const menu = useSelector(jobSeekerMenu);
  const { profileList = [] } = useSelector(jobSeekerSelector);
  const { stripePageToggle, stripeOnboardStatus } = useSelector(stripeOnboardSelector)
  const unreadCount = useSelector(unreadCountSelector)
  const { pathname } = useLocation()
  const isMatch = useRouteMatch(JOBSEEKER_DASHBOARD)
  const [close, setClose] = useState(getLocal("alertModal"))
  const dispatch = useDispatch();
  const history = useHistory();
  const [openModal, setOpenModal] = useState(false)
  const [respWidth,setRespWidth] = useState(false)
  const handleLogout = () => {
    dispatch(logoutApi());
  };

  useEffect(() => {
    {
      width < 500  ? setRespWidth(true) : setRespWidth(false);
    }
  }, []);
  useEffect(() => {
    if (profileList?.is_stripe_onboarded && profileList.is_stripe_onboarded  === 1 ) {
      dispatch(getStripePageToggle(true))
    }
    else {
      dispatch(getStripePageToggle(false))
    }
  }, [profileList])


  useEffect(() => {
   if (stripeOnboardStatus?.result?.charges_enabled) {
    dispatch(getStripePageToggle(true))
  }
  }, [stripeOnboardStatus?.result?.charges_enabled])



  useEffect(() => {
    dispatch(setBadgeCount({ name: 'Inbox', badge: unreadCount }))
  }, [unreadCount])

  useEffect(() => {
    if (isMatch) {
      dispatch(resetJobSeekerMenu());
    }
    const res = menuReverseDetect(menu, pathname)
    if (res?.name) {
      dispatch(setJobSeekerMenu({ name: spaceReduce(res?.name), subName: spaceReduce(res?.subName) }));
    }
  }, [])

  const handleNav = (name, subName = null) => () => {
    console.log(name);
    if(name === 'Secure Your Payments'){
      setOpenModal(true)
      return
    }else if(name === 'Get Paid to Share VirtualStaff.ph'){
      window.open('https://affiliates.virtualstaff.ph/#/how-it-works-affiliate', '_blank');
    }else{
      dispatch(setJobSeekerMenu({ name: spaceReduce(name), subName: spaceReduce(subName) }));
    }
   
   
  };
  const handleRoute = (a) => {
    if (a?.name === 'Help & Support') {
      window.location.href = HELP_SUPPORT;
    } else {
      navigate(a?.path)
    }
  }

  const handleSetupBank = ()=>{
    const is_stripe_onboarded = getLocal('isStripeOnboarded')
    history.push(is_stripe_onboarded  == '0' ||  !is_stripe_onboarded ? STRIPE_ONBOARD_EXISTING_USER : JOBSEEKER_INVOICE )
  }
  return (

    <div className='jobseeker-side-nav side-nav sidebar1 side-re-size' >

      <ul>
        {menu && menu.map(({ subMenu = null, icon = 'fas fa-circle', name = '', badge = 0, active = false, path = '' },i) => {
          if (subMenu) {
            return (
              <li key={'menu'+i} className={`sub-menu ${active ? 'employer-active' : ''}`}>
                <a onClick={handleNav(name)} href='javascript:void(0)'><i
                  className={`${icon} mr-4`} /><span>{name}</span>
                  <div className={`fa ${active ? 'fa-caret-right' : 'fa-caret-down'} right custom-arrow-btn`} />
                </a>
                {active && subMenu && <ul className={'fadeIn'}>{subMenu
                  .map((a,b)=> {
                    if (a.name === 'Edit Profile' && profileList?.skills?.length > 0) {
                      return (
                        <li  key={'sub-menu'+b} onClick={handleNav(name, a?.name)} className={`hover-gray ${a?.active ? 'employer-active' : ''}`}>
                          <Link to={JOBSEEKER_USER_PROFILE_PREVIEW}><span className={'text-black'}>{a?.name}</span></Link></li>
                      )
                    } else {
                      if (stripePageToggle) {
                        if (a.name !== "Stripe Onboarding") {
                          return (
                            <li  key={'sub-menu'+b} onClick={handleNav(name, a?.name)} className={`hover-gray ${a?.active ? 'employer-active' : ''}`}>
                              <Link onClick={() => handleRoute(a)} ><span className={'text-black'}>{a?.name}</span></Link><p className="sidebar-para">{a?.text}</p></li>
                          )
                        }
                      }
                      else {
                        if (a.name !== "Salary & Payments") {
                          return (
                            <li  key={'sub-menu'+b} onClick={handleNav(name, a?.name)} className={`hover-gray ${a?.active ? 'employer-active' : ''}`}>
                              <a onClick={() => handleRoute(a)} ><span className={'text-black'}>{a?.name}</span></a><p className="sidebar-para">{a?.text}</p></li>
                          )
                        }
                      }
                    }
                  }
                  )}
                </ul>}
              </li>
            );
          } else {
            return (
              <li key={'menu'+i} onClick={handleNav(name)} className={`${active ? 'employer-active' : ''} hover-gray`}>
               {name === 'Secure Your Payments' ? <Link><i className={`${icon} mr-4`} /> <span>{name}</span>
                  {badge ? <div className='notification-header-num-sidebar'>{badge}</div> : ""}
                </Link> : <Link to={path}><i className={`${icon} mr-4`} /> <span>{name}</span>
                  {badge ? <div className='notification-header-num-sidebar'>{badge}</div> : ""}
                </Link>} 
              </li>
            );
          }
        })}
        <li onClick={handleLogout} className={`hover-gray`}>
          <a href="javascript:void(0)"><i className={`fas fa-sign-out-alt mr-4`} /> <span>Logout</span></a>
        </li>
        {
          close &&
          <div className='mt-4 hired_alert'>
            <div className='d-flex justify-content-end' onClick={() => setClose(false)}>
              <CancelIcon style={{ fontSize: "20px", cursor: "pointer" }} />
            </div>
            <div className='text-center'>
              {/* <WarningIcon style={{ fontSize: "40px", color: "yellow" }} /> */}
              <img src="/images/Suit.png" style={{width:"100px"}}/>
              <p className='m-0' style={{ fontSize: "24px", fontWeight: "700",color:"#004C70" }}>
                Already Hired?
              </p>
              
            </div>
            <div className='mt-4 text-center'>
              <div style={{ width: "100%" }}>
                <p className='m-0' style={{ fontSize: "11px",color:"black" }}>
                  Setup your
                  <span style={{ fontSize: "11px", fontWeight: "700", paddingLeft: "2px" }}
                  >BANK DETAILS
                  </span>,
                  <span style={{ fontSize: "12px" }}>
                  to receive your salary:
                </span>
                </p>
                
                <h6 className='m-0' style={{ color: "black", fontSize: "10px" }}>
                (Do these, if you haven't yet)
              </h6>
              </div>
              <div style={{ width: "100%" }}>
                  <button
                    style={{
                      background: "green",
                      border: "none",
                      padding: "3px 5px",
                      borderRadius: "7px",
                      color: "white",
                      fontSize: "11px"
                    }}
                    onClick={handleSetupBank}
                  >
                    Setup Bank Details
                  </button>
              </div>
            </div>
            {/* <div className='mt-4 d-flex justify-content-between'>
              <div style={{ width: "59.5%" }}>
                <p className='m-0' style={{ fontSize: "11px" }}>
                  Install
                  <span style={{ fontSize: "11px", fontWeight: "700", paddingLeft: "2px" }}>
                    WORK LOG
                  </span>
                  , to record your work hours for salary:</p>
              </div>
              <div style={{ width: "40.5%" }}>
                <Link className='p-0' to='/jobseeker/install/tracker'>
                  <button
                    style={{
                      background: "green",
                      border: "none",
                      padding: "7px 10px",
                      borderRadius: "7px",
                      color: "white",
                      fontSize: "11px"
                    }}
                  >
                    Install Work Log
                  </button>
                </Link>
              </div>
            </div> */}
            <div className='text-center mt-4' style={{ width: "95%" }}>
              <p style={{ color: "black",fontSize: "10px" }}>Setting these up <b  style={{ color: "#004C70" }}>IS A MUST</b> as it will guarantee you that you will receive your salary properly in VirtualStaff.ph!</p>
            </div>
          </div>
        }
        <header />
      </ul>
      <Modal
        size="lg"
        show={openModal}
        onHide={() => setOpenModal(false)}
        aria-labelledby="example-modal-sizes-title-lg"
        className='protection-modal'
      >

        <Modal.Body>
          <div   onClick={() => setOpenModal(false)} className="payment-modal">
          <h1>Secure Your Earnings with VirtualStaff.ph</h1>
          {respWidth && <img  src="/images/mobilepayments.png" /> || <img  src="/images/payments.png" />} 
      
          <button>I UNDERSTAND</button>
          </div>
         
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Sidebar;
