import React, { useEffect, useState } from 'react';
// import { useDispatch } from "react-redux";
import PropTypes from 'prop-types';
import { renameBoard } from '../../actions/board';
import { TextField } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { getUserData } from '../../../../helpers/projectHelper';
import Tooltip from '@material-ui/core/Tooltip';

import { withStyles, makeStyles } from '@material-ui/core/styles';
import Chip from '@mui/material/Chip';

const useStyles = makeStyles(theme => ({
  dialogPaper: {

    height: '100%',
    "& .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-elevation24.MuiPaper-rounded": {
      width: "500px"
    },
    "& .MuiDialogActions-root": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "150px"
    }
  },
}));


const StyledChip = withStyles((theme) => ({
  root: {
    maxWidth: 450,
    height: "40px"
  },
  label: {
    fontSize: "25px",
    color: "snow",
  },
}))(Chip);


const BoardTitle = ({ board }) => {
  const classes = useStyles();
  const { full_name, user_type, user_id } = getUserData();
  const isAuthenticated = localStorage.getItem('token');
  const [editing, setEditing] = useState(false);
  const [title, setTitle] = useState(board.title);
  const dispatch = useDispatch();

  useEffect(() => {
    setTitle(board.title);
  }, [board.title]);

  const onSubmit = async (e) => {
    e.preventDefault();
    let formData = {
      id: board._id,
      user_id: user_id,
      title: title
    }
    dispatch(renameBoard(formData));
    setEditing(false);
  };

  return !editing ? (
    <h2 className="board-title" onClick={() => setEditing(true)}>
      <Tooltip title={board.title} key={board.title}>
        <StyledChip
          size="large"
          style={{}}
          key={`${board.title}`}
          label={`${board.title}`}
          className={board.title}
        />
      </Tooltip>
    </h2>
  ) : user_type && user_type == "job_seeker" ? (
    <h2 className="board-title" onClick={() => setEditing(true)}>
      <Tooltip title={board.title} key={board.title}>
        <StyledChip
          size="large"
          style={{}}
          key={`${board.title}`}
          label={`${board.title}`}
          className={board.title}
        />
      </Tooltip>
    </h2>
  ) : (
    <form className="board-title-form" onSubmit={(e) => onSubmit(e)}>
      <TextField
        variant="outlined"
        required
        value={title}
        size="small"
        onChange={(e) => setTitle(e.target.value)}
      />
    </form>
  );
};

BoardTitle.propTypes = {
  board: PropTypes.object.isRequired,
};

export default BoardTitle;
