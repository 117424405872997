import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  adminTicketFilterApi,
  adminCusSupListApi,
  adminTicketAssignApi,
  authTicketCommentApi,

} from '../../api/ticketingSystem/ticketingSystemApi';
import { FullSectionLoader } from '../common/loader.js';
import { jobSeekerSelector } from '../../redux/slicers/jobSeekerSlice';
import { fileUploadApi } from '../../api/jobSeeker/jobSeeker';
import { ticketingSystemSelector } from '../../redux/slicers/ticketingSystemSlice';
import { warningToast } from '../../helpers/projectHelper';
import moment from 'moment';

import { Link } from 'react-router-dom';
import Pagination from 'react-js-pagination';
import { Input, TextArea, Select } from '../common/input';
import AddIcon from '@material-ui/icons/Add';
import { Modal, Button } from 'react-bootstrap';
import { toFixedConvert } from '../../helpers/jsHelper';
import NumberFormat from 'react-number-format';
import { releaseDisputeApi } from '../../api/adminApi/adminApi'
const limit = 10;

function TSAdmin() {
  const [status, setStatus] = useState('open');
  const dispatch = useDispatch();
  const userData = localStorage.getItem('userData');
  const userInfo = JSON.parse(userData);
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState('');
  const { adminTicketFilter, adminCusSupList, adminTicketAssign, authTicketComment } =
    useSelector(ticketingSystemSelector);
  const { filedata } = useSelector(jobSeekerSelector);
  const [loading, setLoading] = useState(true);
  const ticketList = adminTicketFilter?.data?.data ?? [];
  const [cardInfo, setCardInfo] = useState();
  const [state, setState] = useState(false);
  const [data, setData] = useState('');
  const [customerObj, setCustomerObj] = useState();
  const [commentArr, setCommentArr] = useState([]);
  const [page, setPage] = useState(1);
  const [btn, setBtn] = useState('');
  const [ticketDays, setTicketDays] = useState('');
  const [ticketHours, setTicketHours] = useState('');
  const [ticketMin, setTicketMin] = useState('');
  const [flagTwo, setFlagTwo] = useState(false);
  const [workingHours, setWorkingHours] = useState('');
  const [totalAmount, setTotalAmount] = useState();
  useEffect(() => {
    getTicketList();
  }, [status]);
  const handleClose = () => {
    setFlagTwo(false)
  }
  const getTicketList = () => {
    const data = {
      issue_status: status,
      limit: limit,
      skip: 0,
    };
    dispatch(adminTicketFilterApi(data, setLoading));
  };

  useEffect(() => {
    var date1 = moment(cardInfo?.created_time);
    var date2 = moment();
    console.log(date1, date2);
    setTicketDays(date2.diff(date1, 'days'));
    setTicketHours(date2.diff(date1, 'hours'));
  }, [cardInfo]);

  useEffect(() => {
    setCardInfo(ticketList[0] ?? []);
  }, [adminTicketFilter]);

  useEffect(() => {
    let data = {
      issue_status: status,
      limit: limit,
      skip: page * limit - limit,
    };
    dispatch(adminTicketFilterApi(data, setLoading));
  }, [page]);

  useEffect(() => {
    const data = { comments: commentArr, _id: cardInfo?._id };
    dispatch(authTicketCommentApi(data, setLoading));
  }, [btn]);

  const handlePagination = (pageNumber) => {
    setPage(pageNumber);
  };
  const handleFormChange = () => {

 }
 const calculateFinalAmount = (val) => {
  let tax = Number(val)  * 0.03;
  let num = tax + Number(val)
  return Number(num).toFixed(2)
}
 const handleRelease = () => {
   const data  = {
    "invoice_id":cardInfo?.invoice_id?._id,
    "total_hours_changed_by_admin":workingHours,
    "total_amount": totalAmount,
    amount:calculateFinalAmount(totalAmount),
    initial_amount:totalAmount
}  
 console.log(data)
dispatch(releaseDisputeApi(data,setFlagTwo))
 }
  const cardDetail = (e) => {
    setCardInfo(e);
    setState(true);
    console.log(e);
    setCommentArr(e?.comments);
  };

  const formData = { ...data };

  const handleChange = (e) => {
    const { name, value } = e;
    formData[name] = value;
    console.log(formData);
    setData(formData);
  };

  const statusHandler = (value) => {
    if (value === 'open') {
      setStatus('open');
    } else if (value === 'closed') {
      setStatus('closed');
    } else {
      setStatus('inprogress');
    }
  };
  const handleValue = (a) => {
    setWorkingHours(a.formattedValue);

    let min = 60;
    let salary = Number(cardInfo?.salary_amount) / 60;
    const hour = a.formattedValue.slice(0, 2);
    const minute = a.formattedValue.slice(3, 5);
    let totalMinute = Number(hour) * min + Number(minute);

    let totalAmount = totalMinute * salary;
    let convertAmount = toFixedConvert(totalAmount)
    setTotalAmount(convertAmount);
  };
  const handleDoc = (e) => {
    const files = e.target.files[0];
    setFileName(files && files.name);
    const fileReader = new FileReader();
    if (e.target.files[0]) {
      fileReader.readAsDataURL(files);
      fileReader.onload = () => {
        setFile(fileReader.result);
      };
    }
    const formData = new FormData();
    formData.append('file', files);
    dispatch(fileUploadApi(formData));
  };

  const handleModal = (value) => {
    if (value === 'assign') {
      if (state === false) {
        warningToast({ title: 'Invalid', msg: 'Please choose a ticket' });
      } else {
        const data = {
          limit: 10,
          skip: 0,
        };
        dispatch(adminCusSupListApi(data, setLoading));
      }
    } else {
      if (state === false) {
        warningToast({ title: 'Invalid', msg: 'Please choose a ticket' });
      }
    }
  };

  const chooseCustomer = (value) => {
    setCustomerObj(JSON.parse(value));
  };

  const submitHandler = (value) => {
    if (value === 'assign') {
      const data = {
        ticket_id: cardInfo?._id,
        technical_support_id: customerObj?._id,
        technical_support_name: customerObj?.full_name,
        technical_support_email: customerObj?.user_email,
      };
      dispatch(adminTicketAssignApi(data, setLoading, getTicketList));
    } else {
      const commentData = {
        user_id: userInfo?.user_id,
        comment: formData.comment,
        attachment: filedata && filedata[0],
      };
      setCommentArr((prevData) => [...prevData, commentData]);
      setBtn(value);
    }
  };

  // console.log(adminTicketFilter);
  // console.log(adminCusSupList);
  // console.log(adminTicketAssign);
  // console.log(authTicketComment);
  // console.log(commentArr);
  const convertDate = (ele) => {
    let date = '';
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    date += new Date(ele).toLocaleString('en-us', options);
    return date;
  };
  return (
    <div>
      <div className="app-content">
        <div className="docuchainContent p-2">
          <div className="userUserExtension mt-5 p-0">
            <h1>Manage Tickets</h1>
            {loading === true ? (
              <FullSectionLoader />
            ) : (
              <div className="extension1 mt-0" style={{padding:"12px 0px"}}>
                <div className="tomCardBody">
                  <div className="helpdesk-tab-content tab-menu-content">
                    <div className="tab-menu-title">
                      <div className="left-nav-menu">
                        <ul>
                          <li
                            id="open"
                            className={status === 'open' ? 'menu1 active' : 'menu1'}
                            onClick={() => statusHandler('open')}
                          >
                            <a>
                              <p>open</p>
                            </a>
                          </li>
                          <li
                            id="inprogress"
                            className={status === 'inprogress' ? 'menu3 active' : 'menu3'}
                            onClick={() => statusHandler('inprogress')}
                          >
                            <a>
                              <p>In progress</p>
                            </a>
                          </li>
                          <li
                            id="closed"
                            className={status === 'closed' ? 'menu2 active' : 'menu2'}
                            onClick={() => statusHandler('closed')}
                          >
                            <a>
                              <p>closed</p>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="tab-menu-content-section" style={{padding:"15px 0px"}}>
                      <div id="pcontent-1">
                        {ticketList?.length !== 0 ? (
                          <div className="row">
                            <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12">
                              <div className="d-flex justify-content-between flex-wrap p-2" style={{height:"114vh", overflowY:"scroll",boxShadow: "0px 0px 5px 1px rgb(0 0 0 / 28%)"}}>
                                {ticketList?.map((e, i) => {
                                  return (
                                    <div
                                      className="helpdesk-leftcard"
                                      onClick={() => cardDetail(e)}
                                    >
                                      <div className="helpdesk-leftcard-row1">
                                        <div className="helpdesk-leftcard-row1-left">
                                          <label>Ticket # : </label>
                                          <span>{e.ticket_id}</span>
                                        </div>
                                        <div className="helpdesk-leftcard-row1-left">
                                          <label>date : </label>
                                          <span>{moment(e.created_time).format('DD:MM:yyyy')}</span>
                                        </div>
                                      </div>
                                      <div className="helpdesk-leftcard-row1">
                                        <div className="helpdesk-leftcard-row1-left">
                                          <label>Priority : </label>
                                          <span>{e.issue_priority}</span>
                                        </div>
                                        <div className="helpdesk-leftcard-row1-left">
                                          <label>status : </label>
                                          <span
                                            className={
                                              status === 'open'
                                                ? 'opened-bg'
                                                : status === 'closed'
                                                  ? 'closed-bg'
                                                  : 'inprogress-bg'
                                            }
                                          >
                                            {e.issue_status}
                                          </span>
                                        </div>
                                      </div>
                                      <div style={{ display: 'flex' }}>
                                        <label>Module : </label>
                                        <p>{e.module}</p>
                                      </div>
                                      <div style={{ display: 'flex' }}>
                                        <label>Issue Type : </label>
                                        <p>{e.issue_type}</p>
                                      </div>
                                      <div className="helpdesk-leftcard-row2">
                                        <label>Feedback/ Complaint : </label>
                                        <p>{e.issue}</p>
                                      </div>
                                      {/* <div style={{ display: 'flex' }}>
                                        <label>Dispute between : </label>
                                        <p>Employer Name - {e.user_full_nam}</p>
                                        <p>Staff Name - {e.user_id.full_name}</p>
                                      </div> */}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>

                            <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12">
                              <div className="helpdesk-rightcard">
                                <div className="helpdesk-rightcard-heading">
                                  <h5 style={{ margin: '0', color: 'white', fontSize: '16px' }}>
                                    ticket activity system
                                  </h5>
                                </div>
                                <div className="helpdesk-rightcard-details">
                                  <div className="helpdesk-rightcard-details-row">
                                    <span
                                      className={
                                        status === 'open'
                                          ? 'opened-bg'
                                          : status === 'closed'
                                            ? 'closed-bg'
                                            : 'inprogress-bg'
                                      }
                                      style={{fontSize:"12px"}}
                                    >
                                      {cardInfo?.issue_status}
                                    </span>
                                  </div>
                                  <div className="helpdesk-rightcard-details-row">
                                    <label>Dispute Raised By :</label>
                                    <span>{cardInfo?.employer_id?.full_name}</span>
                                  </div>
                                  <div className="helpdesk-rightcard-details-row">
                                    <label>Staff Name :</label>
                                    <span>{cardInfo?.user_id?.full_name}</span>
                                  </div>
                                  <div className="helpdesk-rightcard-details-row">
                                    <label>Job Position :</label>
                                    <span>{cardInfo?.job_id?.job_title}</span>
                                  </div>
                                  <div className="helpdesk-rightcard-details-row">
                                    <label>Salary Amount:</label>
                                    <span>{cardInfo?.invoice_id?.salary_amount} /hr</span>
                                  </div>
                                  <div className="helpdesk-rightcard-details-row">
                                    <label>Work Hours :</label>
                                    <span>{cardInfo?.invoice_id?.work_hours} hours</span>
                                  </div>
                                  <div className="helpdesk-rightcard-details-row">
                                    <label>Billing From :</label>
                                    <span>{convertDate(cardInfo?.invoice_id?.billing_from)}</span>
                                  </div>
                                  <div className="helpdesk-rightcard-details-row">
                                    <label>Billing To :</label>
                                    <span>{convertDate(cardInfo?.invoice_id?.billing_to)}</span>
                                  </div>
                                  <div className="helpdesk-rightcard-details-row">
                                    <label>Working Hours by employer :</label>
                                    <span>{cardInfo?.invoice_id?.work_hours_changed_by_employer}{' '}hours</span>
                                  </div>
                                  <div className="helpdesk-rightcard-details-row">
                                    <label>Working Hours by employee :</label>
                                    <span>{cardInfo?.invoice_id?.work_hours_changed_by_employee}{' '}hours</span>
                                  </div>
                                  <div className="helpdesk-rightcard-details-row">
                                    <label>Created on :</label>
                                    <span>
                                      {moment(cardInfo?.created_time).format('DD:MM:yyyy')}
                                    </span>
                                  </div>



                                  <div className="helpdesk-rightcard-details-row">
                                    <label>Ticket Duration :</label>
                                    {ticketHours >= 24 ? (
                                      <span>{ticketDays} days ago</span>
                                    ) : ticketHours <= 1 ? (
                                      <span>{ticketMin} minutes ago</span>
                                    ) : (
                                      <span>{ticketHours} hours ago</span>
                                    )}
                                  </div>
                                  {/* <div className="attach">
                                    <Link
                                      onClick={(event) => {
                                        event.preventDefault();
                                        window.open(`${DOWNLOAD_API}/${cardInfo?.issue_img_id}`);
                                      }}
                                      target="blank"
                                    >
                                      <button
                                        type="button"
                                        className="commonBtn2 float-right mb-2"
                                        id="upBtn"
                                      >
                                        {' '}
                                        <label style={{ margin: '0' }} for="file1">
                                          <AddIcon />
                                          View Attachment
                                        </label>
                                      </button>
                                    </Link>
                                  </div> */}
                                  {/* <div className="helpdesk-rightcard-details-row">
                                    <label>Comments Box :</label>
                                  </div> */}
                                  {/* {cardInfo?.comments?.length !== 0 ? (
                                    <div className="ticket-comment">
                                      {cardInfo?.comments?.map((e) => {
                                        return (
                                          <div className="comment-object">
                                            <div className="helpdesk-rightcard-details-row">
                                              <label>Name :</label>
                                              <span>{e.user_id.full_name}</span>
                                            </div>
                                            <div className="helpdesk-rightcard-details-row">
                                              <label>Comment :</label>
                                              <span>{e.comment}</span>
                                            </div>
                                            <div className=" d-flex justify-content-center ">
                                              <Link
                                                onClick={(event) => {
                                                  event.preventDefault();
                                                  window.open(`https://authapi.virtualstaff.ph/api/v1/file/download/${e.attachment}`);
                                                }}
                                                target="blank"
                                              >
                                                <button className="commonBtn2 mb-2">
                                                  <label style={{ margin: '0' }} for="file1">
                                                    <AddIcon />
                                                    View Attachment
                                                  </label>
                                                </button>
                                              </Link>
                                            </div>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  ) : (
                                    <div className="ticket-comment-two">
                                      <p>No comments found</p>
                                    </div>
                                  )} */}
                                  <hr />
                                  {/* <div className="helpdesk-rightcard-details-row-btn">
                                    <button
                                      data-toggle="modal"
                                      data-target="#followup"
                                      type="button"
                                      className="commonBtn"
                                      onClick={() => handleModal('followUp')}
                                    >
                                      Reply / Follow up
                                    </button>
                                    {status === 'open' ? (
                                      <button
                                        data-toggle="modal"
                                        data-target="#exampleModal"
                                        type="button"
                                        className="commonBtn"
                                        onClick={() => handleModal('assign')}
                                      >
                                        Assign
                                      </button>
                                    ) : null}
                                  </div> */}
                                  <div className="helpdesk-rightcard-details-row-btn">
                                    {status === 'open' ? (
                                      <button

                                        type="button"
                                        className="commonBtn"
                                        onClick={() => setFlagTwo(true)}
                                      >
                                        Release Dispute
                                      </button>) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="no-ticket">
                            <p>No ticket found</p>
                          </div>
                        )}
                        {ticketList?.length !== 0 ? (
                          <div style={{ width: '80%', marginLeft: '9rem' }} className='mt-4'>
                            <Pagination
                              prevPageText={
                                <i class="fas fa-arrow-circle-left" aria-hidden="true"></i>
                              }
                              nextPageText={
                                <i class="fas fa-arrow-circle-right" aria-hidden="true"></i>
                              }
                              firstPageText={<i class="fas fa-step-backward"></i>}
                              lastPageText={<i class="fas fa-step-forward"></i>}
                              innerClass="paination_link"
                              linkClass="pagination_link_a"
                              activeClass="paination_active_link"
                              activePage={page}
                              itemsCountPerPage={limit}
                              totalItemsCount={adminTicketFilter?.data?.recordsTotal}
                              pageRangeDisplayed={3}
                              onChange={handlePagination}
                            />
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* modal open div */}
        {state === true ? (
          <div
            class="modal fade"
            id="exampleModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog" style={{ maxWidth: '40%' }} role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5
                    class="modal-title"
                    style={{ margin: '0px', color: 'red' }}
                    id="exampleModalLabel"
                  >
                    Assign Ticket
                  </h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div>
                    <div>
                      <label>Customer Support: </label>
                      <div>
                        <select
                          onChange={(e) => chooseCustomer(e.target.value)}
                          className="form-control"
                        >
                          <option>select</option>
                          {adminCusSupList?.data?.data?.map((e, i) => {
                            return (
                              <option key={i} value={JSON.stringify(e)}>
                                {e.full_name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-footer" style={{ display: 'flex', justifyContent: 'center' }}>
                  <button
                    //   onClick={sumbitHandler}
                    type="button"
                    class="btn btn-danger"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => submitHandler('assign')}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {state === true ? (
          <div
            class="modal fade"
            id="followup"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog" style={{ maxWidth: '40%' }} role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5
                    class="modal-title"
                    style={{ margin: '0px', color: 'red' }}
                    id="exampleModalLabel"
                  >
                    Reply / Follow up
                  </h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div>
                    <div>
                      <div>
                        <TextArea
                          name={'comment'}
                          label={'Feedback / Complaint:'}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="helpdesk-leftcard-row1-left" style={{ marginTop: '10px' }}>
                        <label>status : </label>
                        <span
                          className={
                            status === 'open'
                              ? 'opened-bg'
                              : status === 'closed'
                                ? 'closed-bg'
                                : 'inprogress-bg'
                          }
                        >
                          {cardInfo?.issue_status}
                        </span>
                      </div>
                      <div className="d-flex justify-content-between" style={{ width: '100%' }}>
                        <div class="upload-name">
                          <p>{fileName}</p>
                        </div>
                        <div className="upload">
                          <button
                            type="button"
                            class="commonBtn2"
                            id="upBtn"
                            style={{ outline: 'none' }}
                          >
                            {' '}
                            <label style={{ margin: '0' }} for="file1">
                              <i class="fa fa-plus"></i> Upload File
                            </label>
                          </button>
                          <input
                            type="file"
                            id="file1"
                            name="document"
                            onChange={handleDoc}
                            style={{ display: 'none' }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-footer d-flex justify-content-center">
                  <button
                    type="button"
                    class="btn btn-danger"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => submitHandler('followUp')}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {/* modal close div */}
        <Modal
          show={flagTwo}
          onHide={handleClose}
          aria-labelledby="example-modal-sizes-title-lg"
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">Amend Invoice</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ fontSize: '20px' }}>
            <form>
              <div className="row">
                <div className="col-md-6">
                  <label style={{ color: '#004c70' }}>Total working hours for the week</label>
                  <NumberFormat
                    format="##:##"
                    placeholder="HH:MM"
                    className="form-control"
                    onValueChange={(v) => handleValue(v)}
                  />
                </div>
                <div className="col-md-6" style={{ display: 'flex', flexDirection: 'column' }}>
                  <label style={{ color: '#004c70' }}>Total amount for the working hours</label>
                  <span>${' '}{toFixedConvert(totalAmount)}</span>
                </div>
              </div>

            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleRelease}>
              Release Dispute
            </Button>

            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

export default TSAdmin;
