import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { addList } from '../../actions/board';
import { Button, TextField } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { getUserData } from '../../../../helpers/projectHelper';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams
} from "react-router-dom";
import { useSession } from "../../../../helpers/hooks";
import IconButton from '@mui/material/IconButton';

const CreateList = () => {
  const { user_type } = useSession()
  const { board_id } = useParams();
  const { full_name, user_id } = getUserData();
  const isAuthenticated = localStorage.getItem('token');

  const [adding, setAdding] = useState(false);
  const [title, setTitle] = useState('');
  const dispatch = useDispatch();

  const formRef = useRef(null);
  useEffect(() => {
    formRef && formRef.current && formRef.current.scrollIntoView();
  }, [title]);

  const onSubmit = async (e) => {
    e.preventDefault();
    let data = {
      title: title,
      boardId: board_id,
      user_id: user_id
    }
    dispatch(addList(data));
    setTitle('');
  };

  return !adding ? (
    <div className='create-list-button'>
      {user_type !== 'job_seeker' ?
        <Button variant='contained' onClick={() => setAdding(true)}>
          + Add a list
        </Button> : " "}
    </div>
  ) : (
    <div ref={formRef} className='create-list-form'>
      <form onSubmit={(e) => onSubmit(e)}>
        <TextField
          variant='outlined'
          fullWidth
          margin='normal'
          required
          label='Enter list title'
          autoFocus
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <div style={{ display: 'flex' }}>
          <Button type='submit' variant='contained' color='primary'>
            Add List
          </Button>
          &nbsp; &nbsp;
          <IconButton onClick={() => {
            setAdding(false);
            setTitle('');
          }} color="primary" aria-label="upload picture" component="span">
            <CloseIcon />
          </IconButton>
        </div>
      </form>
    </div>
  );
};

export default CreateList;
