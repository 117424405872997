import { OtherSessionsChecker } from '../common/container/sessionChecker.js';
import { CHAT, EMPLOYER_UNVERIFIED, LOGIN } from '../../config/constant/routePathConstant.js';
import React from 'react';
import { RouterBuilder } from '../common/router.js';
import CHAT_ROUTES from '../../config/routes/chatRoutes.js';
import { EMPLOYER_ALLOWED_ROUTES } from '../../config/constant/menuConstant';

const ChatContainer = (a) => {
  return (
    <>
      <OtherSessionsChecker allowedRoutes={EMPLOYER_ALLOWED_ROUTES} unverifiedPath={EMPLOYER_UNVERIFIED} sessionFailPath={LOGIN} onlyToken currentRoute={CHAT} />
      <RouterBuilder data={CHAT_ROUTES} />
    </>
  );
};

export default ChatContainer;


