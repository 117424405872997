import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { sessionSelector } from '../../../redux/slicers/sessionSlice.js';
import { activationApi, passwordActivationApi } from '../../../api/session.js';
import ReactLoading from 'react-loading';
import { WHITE } from '../../../config/constant/assetConstant.js';

const PasswordActivation = () => {
   const { activationLoading } = useSelector(sessionSelector);
   const history = useHistory();
   const {user_id  = '' ,link_id = '' } = useParams();
   const dispatch = useDispatch();
   
   const handleClick = () => {
      const formData = {
         user_id : user_id,
         link_id  : link_id
      }
      dispatch(passwordActivationApi(formData,history))
   };
   return (
     <div style={{ padding: '5%', textAlign: 'center' }}>
        <div>
           <h1>Password Confirmation</h1>
           <h2>Click on the following link to confirm your password</h2>
        </div>
        <div className="activationbtn" onClick={handleClick}>
           {activationLoading ? <ReactLoading type={"spinningBubbles"} width={'15%'} color={WHITE} className="loading-button"/> : "Activate your account here"}
        </div>
     </div>
   );
};

export default PasswordActivation;
