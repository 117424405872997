import store from '../redux/store.js';
import moment from 'moment';
import { DEFAULT_TOKEN, USER_DATA ,S3_BUCKET_URL,IMAGE_API} from '../config/constant/projectConstant.js';
import { sessionFail } from '../redux/slicers/sessionSlice.js';
import { CHAT, EMPLOYER,EMPLOYER_MANAGENOTIFICATIONS, EMPLOYER_DASHBOARD, EMPLOYER_TRANSACTIONS, EMPLOYER_TWO_STEP_VERFICATION, JOBSEEKER_DASHBOARD, JOBSEEKER_TRANSACTION, JOBSEEKER_USER_PROFILE_PREVIEW, JOB_SEEKER, LOGIN ,JOBSEEKER_NOTIFICATION_SETTING} from '../config/constant/routePathConstant.js';
import { navigatePath } from '../redux/slicers/activitySlice.js';
import { convertToHTML, parseHTML, spaceReduce } from './jsHelper';
import { USER_IMG } from '../config/constant/imageConstant.js';
import { toast } from 'react-toastify';
export const devConsoleLog = (...a) => {
  if (a) {
    if (isDev()) {
      console.log(...a);
    }
  }
};
export const dummyQueryUser = () => {
  const { user_id = null } = getUserData();
  const formQuery = {
    query: {
      user_id,
    },
  };
  return formQuery;
};

export const isDev = () => {
  return process.env.NODE_ENV === 'development';
};

export const dispatcher = (a) => {
  store.dispatch(a);
};
export const menuIdUpdate = (arr) => {
  return arr.map(a => ({ ...a, id: spaceReduce(a?.name), subMenu: a?.subMenu ? menuIdUpdate(a.subMenu) : null }))
}
export const menuReverseDetect = (menu, pathname) => {
  return menu.reduce((acc, a) => {
    if (a.path == pathname) {
      acc.name = a.name;
    }
    if (a.subMenu) {
      const list = a.subMenu.find(i => i.path == pathname);
      if (list?.name) {
        acc.subName = list.name;
        acc.name = a.name;
      }
    }
    return acc
  }, {})
}
export const storeLocal = (data = '', tokenName = DEFAULT_TOKEN) => {
  if (typeof data === 'object') {
    data = JSON.stringify(data);
  }
  window.localStorage.setItem(tokenName, data);
};
export const getLocal = (tokenName = DEFAULT_TOKEN) => {
  const localData = window.localStorage.getItem(tokenName);
  let res;
  try {
    res = JSON.parse(localData);
  } catch (err) {
    res = localData;
  }
  return res;
};
export const getUserData = (userData = USER_DATA) => {
  const localData = window.localStorage.getItem(userData);
  let res = {};
  try {
    res = JSON.parse(localData) || {}
  } catch (err) {
    res = localData || {};
  }
  return res;
};
export const removeLocal = (tokenName = DEFAULT_TOKEN) => {
  window.localStorage.removeItem(tokenName);
  return navigate(LOGIN)
};

export const sessionDestroy = (path = LOGIN) => {
  removeLocal();
  removeLocal(USER_DATA);
  removeLocal('alertModal')
  removeLocal('isStripeOnboarded')
  removeLocal('popup_flag')
  dispatcher(sessionFail());
  navigate(path);
};

export const uploadPathBuilder = (root, a) => {
  return root + a;
};

export const navigate = (path) => {
  dispatcher(navigatePath(path));
};

const toastContent = ({title= '',msg = ''})=>{
   
  if(typeof msg === 'object') {
     msg  =JSON.stringify(msg)
  }
  return (
    <ul className="toastr-box">
      <li><b>{title.toString()}</b></li>
      <li><p>{msg.toString()}</p></li>
    </ul>
  )
}

export const ageCalc = (d) => {
  return moment(new Date()).diff(moment(d), 'years');
};
export const successToast = ({ title, msg,toastrOptions = {} }) => {
  toast(toastContent({title, msg}),{
    type:'success',
    ...toastrOptions
  })
};

export const errorToast = ({ title, msg,toastrOptions = {} }) => {
  toast(toastContent({title, msg}),{
    type:'error',
    ...toastrOptions
  })
};

export const infoToast = ({ title, msg,toastrOptions = {} }) => {
  toast(toastContent({title, msg}),{
    type:'info',
    ...toastrOptions
  })
};

export const warningToast = ({ title, msg,toastrOptions = {} }) => {
  toast(toastContent({title, msg}),{
    type:'warning',
    ...toastrOptions
  })
};

export const validate = (fieldName, value) => {
  let valid;
  let symbol;
  let error;
  let errorTwo;
  let validData = {};
  switch (fieldName) {
    case 'amount' :
      valid = value > 1;
      error = valid ? '' : 'Please enter a valid amount';
      validData = {
        valid,
        error,
      };
    case 'full_name':
      valid = value.length >= 5;
      error = valid ? '' : 'Minimum 5 characters length required';
      validData = {
        valid,
        error,
      };
      return validData;
    case 'first_name':
    valid = value.length >= 1;
    error = valid ? '' : 'Please enter first name';
    validData = {
      valid,
      error,
    };
    return validData;
    case 'last_name':
    valid = value.length >= 1;
    error = valid ? '' : 'Please enter last name';
    validData = {
      valid,
      error,
    };
    return validData;
    case 'user_email':
      valid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/);
      symbol = value.match(/[!$%^&*()+|~=`{}\[\]:";'<>?,\/]/);
    
      error =  valid ? '' : 'Please enter valid email address.';
      error =  !symbol ? '' : 'Please dont add any symbols.';
      validData = {
        valid,
        error,
      };
      return validData;
    case 'password':
    case 'new_password':
      valid =
        value.length >= 8 &&
        value.match(/(?=.*[_!@#$%^&*-])(?=.*\d)(?!.*[.\n])(?=.*[a-z])(?=.*[A-Z])^.{8,}$/);
      error = valid ? (
        ''
      ) : (
        <div>
          {/[A-Z]/.test(value) === false && <li>must contain at least one uppercase letter</li>}
          {/[a-z]/.test(value) === false && <li>one lowercase letter</li>}
          {/[0-9]/.test(value) === false && <li>minimum one number</li>}
          {value.length < 8 && <li>one special character and a minimum of 8 characters</li>}
          {/[_!@#$%^&*-]/.test(value) === false && <li>minimum one special character </li>}
        </div>
      );

      validData = {
        valid,
        error,
        errorTwo,
      };
      return validData;
    default:
      return validData;
  }
};

export const errorValidator = (a, setState) => {
  const { name, value = '' } = a;
  if (value.trim()) {
    const { error = null } = validate(name, value);
    setState((err) => {
      return { ...err, [name]: error };
    });
  } else {
    setState((err) => {
      return { ...err, [name]: null };
    });
  }
};

export const createShortString = (a = null) => {
  const str = a ? parseHTML(a) : '';
  return str?.substring(0, 100)
}
export const createLongString = (a = null) => {
  const str = a ? parseHTML(convertToHTML(a)) : '';
  return str
}
export const currencyConvert = (num = null) => {
  return Number(num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'))
}
export const checkJson = (str) => {

  try {
    var o = JSON.parse(str);
    console.log(o, '0')

    if (o && typeof o === "object") {

      return o;
    }
  }
  catch (e) { }

  return false;
};

export const redirectRoute = (query = null) => {
  console.log(query.type)
  const type = new URLSearchParams(query).get('type')
  switch (type) {
    case 'join_meet':
      const from_id = new URLSearchParams(query).get('from_id');
      const to_id = new URLSearchParams(query).get('to_id');
      const chat_id = new URLSearchParams(query).get('chat_id')
      return navigate(`${CHAT}/video/${chat_id}/${from_id}/${to_id}?type=join_meet`)
    case 'two-step-verification':
      return navigate(EMPLOYER_TWO_STEP_VERFICATION)
    case 'job-seeker-profile':
      return navigate(JOBSEEKER_USER_PROFILE_PREVIEW)
    case 'job-offer-action-chat':
      const chatid = new URLSearchParams(query).get('chat_id')
      return navigate(`${CHAT}/${chatid}`)
    case 'hired-staff':
      const hired_id = new URLSearchParams(query).get('id')
      return navigate(`${EMPLOYER}/hired-employer-view/${hired_id}`)
    case 'new-applicant':
      const applicant_id = new URLSearchParams(query).get('id')
      const applicant_record_id = new URLSearchParams(query).get('applicant-record-id')
      return navigate(`${EMPLOYER}/applicantprofile/${applicant_id}?appliedprofile=true&uniqueid=${applicant_record_id}`)
    case 'view-invoice-job-seeker':
      const invoice_id = new URLSearchParams(query).get('id')
      return navigate(`${JOB_SEEKER}/invoice-action/${invoice_id}`)
    case 'jobseeker':
      return navigate(JOBSEEKER_DASHBOARD)
    case 'view-invoice-employer':
      const invoiceid = new URLSearchParams(query).get('id')
      return navigate(`${EMPLOYER}/view-invoice/invoice-action/${invoiceid}`)
    case 'dashboard':
      return navigate(EMPLOYER_DASHBOARD)
    case 'job-offer':
      const offerid = new URLSearchParams(query).get('id');
      const jobid = new URLSearchParams(query).get('job-id');

      return navigate(`${JOB_SEEKER}/view-job/${offerid}/${jobid}`)
    case 'new-job-post':
      const job_id = new URLSearchParams(query).get('id');
      return navigate(`${JOB_SEEKER}/job-description/${job_id}`)
    case 'bonus-employer':
      return navigate(EMPLOYER_TRANSACTIONS)
    case 'bonus-job-seeker':
      return navigate(JOBSEEKER_TRANSACTION)
    // case "new-job-post-alert":
    //   const job_arr = new URLSearchParams(query).get('id')
    //   return navigate(`${JOBSEEKER_FINDJOBS}?q=${job_arr}`)
    case "individual-job-alert":
      const jobID = new URLSearchParams(query).get('id')
      return navigate(`${JOB_SEEKER}/job-description/${jobID}`)
    case "all-job-post-alert":
      const skillName = new URLSearchParams(query).get('id')
      return navigate(`${JOB_SEEKER}/findjobs?skillname=${skillName}`)
    case "new-job-post-alert":
      const user_id = new URLSearchParams(query).get('userId')
      const text = new URLSearchParams(query).get('id')
      return navigate(`${JOB_SEEKER}/unsubscribe/notification/${text}/${user_id}`)
    case "job-seeker-notification":
       return navigate(JOBSEEKER_NOTIFICATION_SETTING)
    case 'employer-notification':
        return navigate(EMPLOYER_MANAGENOTIFICATIONS)
    default:
      return null
  }
} 


export const ImageLoader = (val = null) => {
  const unless = ["jobseeker"];
  if (!val) {
      return USER_IMG
  } else {
      const result = unless.some((a) => {
          return new RegExp(`${a}`, "gi").test(val);
      });
      if (result) {
          return `${S3_BUCKET_URL}${val}`
      } else {
          return `${IMAGE_API}/${val}`
      }
  }
}

export const countFixer =(num=0)=>{
 return num > 99 ? '99+': num
} 