import { lazy } from 'react';
import {
  SUPER_ADMIN,
  SUPER_ADMIN_CATEGORY,
  SUPER_ADMIN_DASHBOARD,
  SUPER_ADMIN_EMPLOYER_DEPOSIT,
  SUPER_ADMIN_EMPLOYER_LIST,
  SUPER_ADMIN_DELETE_EMPLOYER_LIST,
  SUPER_ADMIN_DELETE_JOB_SEEKER_LIST,
  SUPER_ADMIN_JOB_SEEKER_LIST,
  SUPER_ADMIN_MANAGE_CHAT,
  SUPER_ADMIN_MANAGE_EMPLOYER,
  SUPER_ADMIN_MANAGE_JOB_SEEKER,
  SUPER_ADMIN_MANAGE_JOBS,
  SUPER_ADMIN_MANAGE_NOTIFICATION,
  SUPER_ADMIN_MANAGE_PAYMENT,
  SUPER_ADMIN_MANAGE_REFUNDS,
  SUPER_ADMIN_VENDOR_PAYOUT_JOBSEEKER,
  SUPER_ADMIN_VENDOR_VERIFICATION,
  SUPER_ADMIN_VERIFY_EMPLOYER_ROUTE,
  SUPER_ADMIN_ADD_CATEGORY,
  SUPER_ADMIN_SKILL,
  SUPER_ADMIN_ADD_SKILL,
  SUPER_ADMIN_JOB_TITLE,
  SUPER_ADMIN_ADD_JOB_TITLE,
  SUPER_ADMIN_MANAGE_STAFF,
  SUPER_ADMIN_ADD_STAFF,
  ADMIN_LOGIN,
  SUPER_ADMIN_JOB_SEEKER_VERIFICATION,
  SUPER_ADMIN_EMPLOYER_VERIFICATION,
  SUPER_ADMIN_JOB_SEEKER_VERIFICATION_ID,
  SUPER_ADMIN_ACTIVITY_LOG_LIST_ROUTE,
  SUPER_ADMIN_EMPLOYER_ACCEPTED_LIST_ROUTE,
  SUPER_ADMIN_EMPLOYER_REJECTED_LIST_ROUTE,
  SUPER_ADMIN_JOB_SEEKER_ACCEPTED_LIST_ROUTE,
  SUPER_ADMIN_JOB_SEEKER_REJECTED_LIST_ROUTE,
  SUPER_ADMIN_MANAGE_TICKET,
  SUPER_ADMIN_MANAGE_COMMISSIONS,
  SUPER_ADMIN_MANAGE_BONUS,
  SUPER_ADMIN_MANAGE_JOB,
  SUPER_ADMIN_UNVERIFIED_JOBS,
  SUPER_ADMIN_UNVERIFIED_JOB,
  SUPER_ADMIN_DELETE_EMPLOYER,
  SUPER_ADMIN_DELETE_JOB_SEEKER,
  SUPER_ADMIN_ANNOUNCEMENT_AND_PROMOS,
  SUPER_ADMIN_CREATE_EVENT,
  SUPER_ADMIN_UPDATE_EVENT_ID,
  SUPER_ADMIN_MY_ACCOUNT
} from '../constant/routePathConstant';
import { RedirectToAdminDashboard } from '../../components/admin/common/redirect';

const Dashboard = lazy(() => import('../../components/admin/userDashboard'));
const AdminLogin = lazy(() => import('../../components/admin/login'));
const ManageJobs = lazy(() => import('../../components/admin/manageJobs'));
const ManageEmployer = lazy(() => import('../../components/admin/manageEmployer'));
const ManageEmployerList = lazy(() => import('../../components/admin/manageEmployerList'));
const ManageChat = lazy(() => import('../../components/admin/manageChat'));
const ManageNotifications = lazy(() => import('../../components/admin/manageNotifications'));
const ManageRefunds = lazy(() => import('../../components/admin/manageRefunds'));
const ManagePayment = lazy(() => import('../../components/admin/managePayment'));
const ManageVendorPayoutJobseeker = lazy(() =>
  import('../../components/admin/vendorPayoutJobSeeker'),
);
const VerifyEmployerList = lazy(() => import('../../components/admin/verifyEmployerList'));
const ManageVendorVerification = lazy(() => import('../../components/admin/manageVerification'));
const ManageJobSeekerList = lazy(() => import('../../components/admin/manageJobSeekerList'));
const ManageJobSeeker = lazy(() => import('../../components/admin/manageJobSeeker'));
const ManageJobInfo = lazy(() => import('../../components/admin/manageJobInfo'));
const ManageCategory = lazy(() => import('../../components/admin/category'));
const AddCategory = lazy(() => import('../../components/admin/addCategory'));
const ManageSkill = lazy(() => import('../../components/admin/skill'));
const AddSkill = lazy(() => import('../../components/admin/addSkill'));
const ManageJobTitle = lazy(() => import('../../components/admin/jobTitle'));
const AddJobTitle = lazy(() => import('../../components/admin/addJobTitle'));
const ManageStaff = lazy(() => import('../../components/admin/manageStaff'));
const AddStaff = lazy(() => import('../../components/admin/addStaff'));
const AdminEmployerAcceptedList = lazy(() => import('../../components/admin/verifyEmployerAcceptedList'));
const AdminEmployerRejectedList = lazy(() => import('../../components/admin/verifyEmployerRejected'));
const AdminJobSeekerAcceptedList = lazy(() => import('../../components/admin/verifyJobSeekerAcceptList'));
const AdminJobSeekerRejectedList = lazy(() => import('../../components/admin/verfiryJobSeekerRejectList'));
const MyAccount = lazy(() => import('../../components/admin/myAccount'));
const AdminJobSeekerVerification = lazy(() =>
  import('../../components/admin/verifyJobSeekerList'),
);
const AdminEmployerVerification = lazy(() =>
  import('../../components/admin/manageEmployerVerification'),
);
const AdminJobSeekerIdVerfication = lazy(() =>
  import('../../components/admin/jobSeekerIdVerfication'),
);
const ManageAcitivity = lazy(() =>
  import('../../components/admin/manageActivityList'),
);
const ManageTicket = lazy(() =>
  import('../../components/ticketingsystem/admin'),
);
const ManageCommissions = lazy(() =>
  import('../../components/admin/manageCommissions'),
);
const ManageBonus = lazy(() =>
  import('../../components/admin/manageBonus'),
);
const UnverifiedJobs = lazy(() =>
  import('../../components/admin/unVerifiedJobs'),
);
const ManageUnverifiedJobInfo = lazy(() =>
  import('../../components/admin/manageUnverifiedJobInfo'),
);
const DeleteJobSeeker = lazy(() =>
  import('../../components/admin/deleteJobseeker'),
);
const DeleteEmployer = lazy(() =>
  import('../../components/admin/deleteEmployer'),
);
const DeleteEmpInfo = lazy(() =>
  import('../../components/admin/deleteEmpInfo'),
);
const DeleteJobseekerInfo = lazy(() =>
  import('../../components/admin/deleteJobseekerInfo'),
);
const Announcements_or_Promos = lazy(() => import('../../components/admin/announcement'))
const CreateEvent = lazy(() => import('../../components/admin/createEvent'))

const SUPER_ADMIN_ROUTES = [
  { component: AdminLogin, path: ADMIN_LOGIN },
  { component: RedirectToAdminDashboard, path: SUPER_ADMIN },
  { component: Dashboard, path: SUPER_ADMIN_DASHBOARD },
  { component: ManageJobs, path: SUPER_ADMIN_MANAGE_JOBS },
  { component: ManageEmployer, path: SUPER_ADMIN_MANAGE_EMPLOYER },
  { component: ManageEmployerList, path: SUPER_ADMIN_EMPLOYER_LIST },
  { component: ManageChat, path: SUPER_ADMIN_MANAGE_CHAT },
  { component: ManageNotifications, path: SUPER_ADMIN_MANAGE_NOTIFICATION },
  { component: ManageRefunds, path: SUPER_ADMIN_MANAGE_REFUNDS },
  { component: ManagePayment, path: SUPER_ADMIN_MANAGE_PAYMENT },
  { component: ManageVendorPayoutJobseeker, path: SUPER_ADMIN_VENDOR_PAYOUT_JOBSEEKER },
  { component: ManageVendorVerification, path: SUPER_ADMIN_VENDOR_VERIFICATION },
  { component: VerifyEmployerList, path: SUPER_ADMIN_VERIFY_EMPLOYER_ROUTE },
  { component: ManageJobSeekerList, path: SUPER_ADMIN_JOB_SEEKER_LIST },
  { component: ManageJobSeeker, path: SUPER_ADMIN_MANAGE_JOB_SEEKER },
  { component: ManageJobInfo, path: SUPER_ADMIN_MANAGE_JOB },
  { component: ManageUnverifiedJobInfo, path: SUPER_ADMIN_UNVERIFIED_JOB },
  { component: ManageCategory, path: SUPER_ADMIN_CATEGORY },
  { component: AddCategory, path: SUPER_ADMIN_ADD_CATEGORY },
  { component: ManageSkill, path: SUPER_ADMIN_SKILL },
  { component: AddSkill, path: SUPER_ADMIN_ADD_SKILL },
  { component: ManageJobTitle, path: SUPER_ADMIN_JOB_TITLE },
  { component: AddJobTitle, path: SUPER_ADMIN_ADD_JOB_TITLE },
  { component: ManageStaff, path: SUPER_ADMIN_MANAGE_STAFF },
  { component: AddStaff, path: SUPER_ADMIN_ADD_STAFF },
  { component: AdminJobSeekerVerification, path: SUPER_ADMIN_JOB_SEEKER_VERIFICATION },
  { component: AdminEmployerVerification, path: SUPER_ADMIN_EMPLOYER_VERIFICATION },
  { component: AdminJobSeekerIdVerfication, path: SUPER_ADMIN_JOB_SEEKER_VERIFICATION_ID },
  { component: ManageAcitivity, path: SUPER_ADMIN_ACTIVITY_LOG_LIST_ROUTE },
  { component: AdminEmployerAcceptedList, path: SUPER_ADMIN_EMPLOYER_ACCEPTED_LIST_ROUTE },
  { component: AdminEmployerRejectedList, path: SUPER_ADMIN_EMPLOYER_REJECTED_LIST_ROUTE },
  { component: AdminJobSeekerAcceptedList, path: SUPER_ADMIN_JOB_SEEKER_ACCEPTED_LIST_ROUTE },
  { component: AdminJobSeekerRejectedList, path: SUPER_ADMIN_JOB_SEEKER_REJECTED_LIST_ROUTE },
  { component: ManageTicket, path: SUPER_ADMIN_MANAGE_TICKET },
  { component: ManageCommissions, path: SUPER_ADMIN_MANAGE_COMMISSIONS },
  { component: ManageBonus, path: SUPER_ADMIN_MANAGE_BONUS },
  { component: UnverifiedJobs, path: SUPER_ADMIN_UNVERIFIED_JOBS },
  { component: DeleteEmployer, path: SUPER_ADMIN_DELETE_EMPLOYER_LIST },
  { component: DeleteJobSeeker, path: SUPER_ADMIN_DELETE_JOB_SEEKER_LIST },
  { component: DeleteEmpInfo, path: SUPER_ADMIN_DELETE_EMPLOYER },
  { component: DeleteJobseekerInfo, path: SUPER_ADMIN_DELETE_JOB_SEEKER },
  { component: Announcements_or_Promos, path: SUPER_ADMIN_ANNOUNCEMENT_AND_PROMOS },
  { component: CreateEvent, path: SUPER_ADMIN_CREATE_EVENT },
  { component: CreateEvent, path: SUPER_ADMIN_UPDATE_EVENT_ID },
  { component: MyAccount, path: SUPER_ADMIN_MY_ACCOUNT }
];

export default SUPER_ADMIN_ROUTES;
