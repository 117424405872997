import api from '../../helpers/axiosHelper.js';
import {
  JOB_SEEKER_API_ROOT,
  JOBSEEKER_ACCEPT_JOBOFFER,
  JOBSEEKER_ADD_EDUCATION,
  JOBSEEKER_ADD_EMPLOYMENT,
  JOBSEEKER_ADD_PROFILE,
  JOBSEEKER_CATEGORY_LIST,
  JOBSEEKER_DELETE_EDUCATION,
  JOBSEEKER_DELETE_EMPLOYMENT,
  JOBSEEKER_EDUCATION_LIST,
  JOBSEEKER_EMPLOYMENT_LIST,
  JOBSEEKER_FILE_UPLOAD,
  JOBSEEKER_GET_FILE,
  JOBSEEKER_HIRED_EMPLOYER_LIST,
  JOBSEEKER_HIRED_JOB_DETAIL,
  JOBSEEKER_HIRED_JOB_LIST,
  JOBSEEKER_JOB_TITLE_LIST,
  JOBSEEKER_JOBOFFER_STATUS,
  JOBSEEKER_LIST_PROFILE,
  JOBSEEKER_SKILLS_LIST,
  JOBSEEKER_UPDATE_EDUCATION,
  JOBSEEKER_UPDATE_EMPLOYMENT,
  JOBSEEKER_UPDATE_PROFILE,
  JOBSEEKER_UPDATE_SCHEDULED_EVENTS,
  JOBSEEKER_VIEW_OFFERS,
  LOGIN_API_ROOT,
  JOBSEEKER_PROFILE_REQUIRED_CHECK,
  JOBSEEKER_DELETE_DECLAINED_JOBS,
  UNSUBSCRIBE_NOTIFICATION,
  AUTH_GET_EVENT_DETAILS,
  JOBSEEKER_UPDATE_EMAIL,
  ADMIN_API_ROOT,
  ADMIN_ADD_SKILL_API,
  JOBSEEKER_VERIFY_OTP,
  JOBSEEKER_SIGNIN_LIST,
  JOBSEEKER_CONTRACT_DETAILS,
  JOBSEEKER_CONTRACT_AGREE,
  JOBSEEKER_GET_TRANSFORM_TEXT
} from '../../config/constant/apiConstant.js';
import {
  devConsoleLog,
  dummyQueryUser,
  errorToast,
  getUserData,
  navigate,
  sessionDestroy,
  successToast,
} from '../../helpers/projectHelper.js';
import {
  getEventDetail,
  categoryData,
  educationList,
  experienceList,
  fileData,
  getHiredEmployerDetailsAct,
  gethiredEmployerList,
  getJobOfferList,
  getJobOfferStatus,
  getProfilePic,
  getRequiredFieldsList,
  govFileData,
  govIdAct,
  profileData,
  profileListSuccess,
  profilepicSuccess,
  selfieIdAct,
  setSkillSearchList,
  skillsData,
  updateSkillSearchList,
  getProtectedWaitingForSign,
} from '../../redux/slicers/jobSeekerSlice.js';
import { JOBSEEKER_DASHBOARD, JOBSEEKER_USER_PROFILE_PREVIEW } from '../../config/constant/routePathConstant.js';
import { Redirect } from 'react-router-dom';

export const getCategoryApi = (formData) => (dispatch) => {
  api()
    .root(JOB_SEEKER_API_ROOT)
    .post(JOBSEEKER_CATEGORY_LIST)
    .data(formData)
    .success((res) => {
      devConsoleLog(res);
      dispatch(categoryData(res.result.data.data));
      // successToast({ title: 'Success', msg: res.message });
    })
    .error((e) => {
      const { message = '' } = e ?? {};
      devConsoleLog(e);
      errorToast({ title: 'Failed', msg: message });
    })
    .send(() => { });
};
export const getSkillsApi = (formData, callback = null) => (dispatch) => {
  api()
    .root(JOB_SEEKER_API_ROOT)
    .post(JOBSEEKER_SKILLS_LIST)
    .data(formData)
    .success((res) => {
      devConsoleLog(res);
      dispatch(skillsData(res.result.data.data));
      callback?.()
      //  successToast({ title: 'Success', msg: res.message });
    })
    .error((e) => {
      const { message = '' } = e ?? {};
      devConsoleLog(e);
    })
    .send(() => { });
};
export const getEducationList = (formData) => (dispatch) => {
  api()
    .root(JOB_SEEKER_API_ROOT)
    .post(JOBSEEKER_EDUCATION_LIST)
    .data(formData)
    .success((res) => {
      devConsoleLog(res);
      dispatch(educationList(res.result.data.data));
      //  successToast({ title: 'Success', msg: res.message });
    })
    .error((e) => {
      const { message = '' } = e ?? {};
      devConsoleLog(e);
      errorToast({ title: 'Failed', msg: message });
    })
    .send(() => { });
};
export const addEducation = (formData, callback) => (dispatch) => {
  api()
    .root(JOB_SEEKER_API_ROOT)
    .post(JOBSEEKER_ADD_EDUCATION)
    .data(formData)
    .success((res) => {
      callback()
    })
    .error((e) => {
      const { message = '' } = e ?? {};
      errorToast({ title: 'Failed', msg: message });
    })
    .send(() => { });
};
export const editEducation = (formData,callback) => (dispatch) => {
  api()
    .root(JOB_SEEKER_API_ROOT)
    .post(JOBSEEKER_UPDATE_EDUCATION)
    .data(formData)
    .success((res) => {
       callback()
     
       successToast({ title: 'Success', msg: "Updated" });
    })
    .error((e) => {
      const { message = '' } = e ?? {};
      devConsoleLog(e);
      errorToast({ title: 'Failed', msg: message });
    })
    .send(() => { });
};
export const deleteEducation = (formData,callback) => (dispatch) => {
  api()
    .root(JOB_SEEKER_API_ROOT)
    .post(JOBSEEKER_DELETE_EDUCATION)
    .data(formData)
    .success((res) => {
       callback()
    })
    .error((e) => {
      const { message = '' } = e ?? {};
      devConsoleLog(e);
      errorToast({ title: 'Failed', msg: message });
    })
    .send(() => { });
};
export const getExperienceList = (formData) => (dispatch) => {
  api()
    .root(JOB_SEEKER_API_ROOT)
    .post(JOBSEEKER_EMPLOYMENT_LIST)
    .data(formData)
    .success((res) => {
      devConsoleLog(res);
      dispatch(experienceList(res.result.data.data));
      
    })
    .error((e) => {
      const { message = '' } = e ?? {};
      devConsoleLog(e);
      errorToast({ title: 'Failed', msg: message });
    })
    .send(() => { });
};
export const addExperience = (formData,callback) =>(dispatch) => {
  api()
    .root(JOB_SEEKER_API_ROOT)
    .post(JOBSEEKER_ADD_EMPLOYMENT)
    .data(formData)
    .success((res) => {
       callback()
    })
    .error((e) => {
      const { message = '' } = e ?? {};
      devConsoleLog(e);
      errorToast({ title: 'Failed', msg: message });
    })
    .send(() => { });
};
export const editExperience = (formData,callback) => (dispatch) => {
  api()
    .root(JOB_SEEKER_API_ROOT)
    .post(JOBSEEKER_UPDATE_EMPLOYMENT)
    .data(formData)
    .success((res) => {
       callback()
    })
    .error((e) => {
      const { message = '' } = e ?? {};
      devConsoleLog(e);
      errorToast({ title: 'Failed', msg: message });
    })
    .send(() => { });
};
export const deleteExperience = (formData,callback) => (dispatch) => {
  api()
    .root(JOB_SEEKER_API_ROOT)
    .post(JOBSEEKER_DELETE_EMPLOYMENT)
    .data(formData)
    .success((res) => {
       callback()
    })
    .error((e) => {
      const { message = '' } = e ?? {};
      devConsoleLog(e);
      errorToast({ title: 'Failed', msg: message });
    })
    .send(() => { });
};
export const fileUploadApi = (formData,progress) => (dispatch) => {
  api().root(LOGIN_API_ROOT).post(JOBSEEKER_FILE_UPLOAD).data(formData).onUploadProgress(progress).success(res => {
    dispatch(fileData(res.file_id));
    successToast({ title: 'Success', msg: res.message });
  })
    .error((e) => {
      const { message = '' } = e ?? {};
      devConsoleLog(e);
      errorToast({ title: 'Failed', msg: message });
    })
    .upload();
};

export const govUploadApi = (formData,progress) => (dispatch) => {
  api().root(LOGIN_API_ROOT).post(JOBSEEKER_FILE_UPLOAD).data(formData).onUploadProgress(progress).success(res => {
    dispatch(govFileData(res.file_id));
    successToast({ title: 'Success', msg: res.message });
  })
    .error((e) => {
      const { message = '' } = e ?? {};
      devConsoleLog(e);
      errorToast({ title: 'Failed', msg: message });
    })
    .upload(() => { });
};
export const gIdUploadApi = (formData) => (dispatch) => {
  api().root(LOGIN_API_ROOT).post(JOBSEEKER_FILE_UPLOAD).data(formData).success(res => {
    dispatch(govIdAct(res.file_id));
    //  successToast({ title: 'Success', msg: "Succes" });
  })
    .error((e) => {
      const { message = '' } = e ?? {};
      devConsoleLog(e);
      errorToast({ title: 'Failed', msg: message });
    })
    .upload(() => { });
};

export const selfieUploadApi = (formData) => (dispatch) => {
  console.log("&&&&&&&&&&&&", formData)
  api().root(LOGIN_API_ROOT).post(JOBSEEKER_FILE_UPLOAD).data(formData).success(res => {
    dispatch(selfieIdAct(res.file_id));
    //  successToast({ title: 'Success', msg: "This is selfie" });
  })
    .error((e) => {
      const { message = '' } = e ?? {};
      devConsoleLog(e);
      errorToast({ title: 'Failed', msg: message });
    })
    .upload(() => { });
};




// export const governmentIdUploadApi = (formData) => (dispatch) => {
//   console.log("*****************",formData)
//   api().root(LOGIN_API_ROOT).post(JOBSEEKER_FILE_UPLOAD).data(formData).success(res => {
//     dispatch(govIdAct(res.file_id));
//      successToast({ title: 'Success', msg: "This is government" });
//   })
//     .error((e) => {
//       const { message = '' } = e ?? {};
//       devConsoleLog(e);
//       errorToast({ title: 'Failed', msg: message });
//     })
//     .upload(() => {});
// };
export const profilePicUploadApi = (formData, setLoading=null,callback=null) => (dispatch) => {
  api()
    .root(LOGIN_API_ROOT)
    .post(JOBSEEKER_FILE_UPLOAD)
    .data(formData)
    .success((res) => {
      devConsoleLog('this is fileUpdload',);
      dispatch(fileData(res.file_id));
      dispatch(profilepicSuccess(res.file_id));
      setLoading?.(true)
      callback?.()
      //  successToast({ title: 'Success', msg: res.message });
    })
    .error((e) => {

    })
    .upload(() => {
       
     });
};

export const addProfile = (formData) => (dispatch) => {
  api()
    .root(JOB_SEEKER_API_ROOT)
    .post(JOBSEEKER_ADD_PROFILE)
    .data(formData)
    .success((res) => {
      devConsoleLog(res);
      successToast({ title: 'Success', msg: res.message });
      navigate(JOBSEEKER_USER_PROFILE_PREVIEW)
    })

    .error((e) => {
      const { message = '' } = e ?? {};
      devConsoleLog(e);
      errorToast({ title: 'Failed', msg: message });
    })
    .send(() => { });
};
export const viewJobOfferApi = (formData,getJobOffer) => (dispatch) => {
  api().root(JOB_SEEKER_API_ROOT).post(JOBSEEKER_VIEW_OFFERS).data(formData).success(res => {
    // successToast({ title: 'Success', msg: res.message });
    dispatch(getJobOfferList(res?.result?.data))
    getJobOffer()
  }).error(e => {
    const { message = '' } = e ?? {};
    devConsoleLog(e);
    // errorToast({ title: 'Failed', msg: message });
  }).send();
}
export const unSubscribeNotificationApi = (formData) => (dispatch) => {
  api().root(JOB_SEEKER_API_ROOT).post(UNSUBSCRIBE_NOTIFICATION).data(formData)
    .success((res) => {
      successToast({ title: 'Success', msg: res.message });
    })
    .error(e => {
      const { message = '' } = e ?? {};
      errorToast({ title: 'Failed', msg: message });
    }).send();
}
export const acceptJobOfferApi = (formData, _id, setAcceptLoader, setAcceptStatus) => (dispatch) => {
  api().root(JOB_SEEKER_API_ROOT).post(JOBSEEKER_ACCEPT_JOBOFFER).data(formData).success(res => {
    console.log(res);
    successToast({ title: 'Success', msg: res.message });
    const value = {
      query: {
        job_id: _id
      }
    }
    dispatch(getOfferStatusApi(value))
    setAcceptLoader(false)
  }).error(e => {
    const { message = '' } = e ?? {};
    devConsoleLog("this dasdga", message);
    errorToast({ title: 'Failed', msg: message });
    if (message === "Please add bank details before accepting the job offer") {
      setAcceptStatus(true)
    }

    setAcceptLoader(false)
  }).send(() => {

  });

}
export const updateProfileapi = (formData, cancelModalHandler = null, val) => (dispatch) => {
  // console.log('this is formData', val);
  api()
    .root(JOB_SEEKER_API_ROOT)
    .post(JOBSEEKER_UPDATE_PROFILE)
    .data(formData)
    .success((res) => {
      devConsoleLog('this is resposen', res);
      successToast({ title: 'Success', msg: val === "true" ? "Account delete request has been sent to admin" : res.message })
      dispatch(profileListapi(dummyQueryUser()));
      cancelModalHandler();
    })
    .error((e) => {
    })
    .send();
};

export const updateProfileEpertiseapi = (formData, setCloseModal) => (dispatch) => {
  console.log('this is formData', formData);
  api()
    .root(JOB_SEEKER_API_ROOT)
    .post(JOBSEEKER_UPDATE_PROFILE)
    .data(formData)
    .success((res) => {
      devConsoleLog('this is resposen', res);
      successToast({ title: 'Success', msg: res.message });
      setCloseModal(true)
      dispatch(profileListapi(dummyQueryUser()));

    })
    .error((e) => {
      const { message = '' } = e ?? {};
      devConsoleLog(e);
      errorToast({ title: 'Failed', msg: message });
    })
    .send();
};

export const updateProfilePicapi = (formData,callback=null) => (dispatch) => {
  api()
    .root(JOB_SEEKER_API_ROOT)
    .post(JOBSEEKER_UPDATE_PROFILE)
    .data(formData)
    .success((res) => {
      dispatch(profilepicSuccess(res.file_id));
      devConsoleLog('this is resposen', res);
      successToast({ title: 'Success', msg: res.message });
    })
    .error((e) => {

    })
    .send(() => {
      const { user_id = '' } = getUserData();
      const formQuery = {
        query: {
          user_id: user_id,
        }
      }
      dispatch(profileListapi(formQuery))
      callback?.()
    });
};

export const profileListapi = (formData, setData, setSuccess) => (dispatch) => {
  api()
    .root(JOB_SEEKER_API_ROOT)
    .post(JOBSEEKER_LIST_PROFILE)
    .data(formData)
    .success((res) => {
      dispatch(profileListSuccess(res.result));
      setSuccess(false)
      setData(res?.result?.is_profile_complete)
      //  successToast({ title: 'Success', msg: res.message });
    })
    .error((e) => {
      // const { message = '' } = e ?? {};
      // devConsoleLog(e);
      // errorToast({ title: 'Failed', msg: message });
    })
    .send(() => { });
};

export const fetchUserProfileapi = (formData, setLoading) => (dispatch) => {
  const { profileListData, educationListData, employementListData } = formData;
  api()
    .root(JOB_SEEKER_API_ROOT)
    .post(JOBSEEKER_LIST_PROFILE)
    .data(profileListData)
    .success((a) => {
      // const { result = {} } = a;
      dispatch(profileListSuccess(a.result));
      setLoading(false);
    })
    .error((e) => { })
    .send((a, b, c) => {
      // const img_id = c.data.result.user_img_id;
      console.log("this is respose", c)
      api()
        .root(JOB_SEEKER_API_ROOT)
        .post(JOBSEEKER_EDUCATION_LIST)
        .data(educationListData)
        .success((a) => {
          const { result = {} } = a;
          dispatch(educationList(result.data.data));
          setLoading(false);
        })
        .error((e) => { })
        .send(() => {
          api()
            .root(JOB_SEEKER_API_ROOT)
            .post(JOBSEEKER_EMPLOYMENT_LIST)
            .data(employementListData)
            .success((a) => {
              const { result = {} } = a;
              dispatch(experienceList(result.data.data));
              setLoading(false);
            })
            .error((e) => {
              devConsoleLog('error', e);
            })
            .send(() => {
              api()
                .root(LOGIN_API_ROOT)
                // .get(JOBSEEKER_GET_FILE + img_id)
                .get(JOBSEEKER_GET_FILE)
                .success((a) => {
                  const { result = {} } = a;
                  console.log('this is pic', result);
                  dispatch(getProfilePic(a));
                })
                .error((e) => {
                  console.log('this is error', e);
                }).notFound404(() => { })
                .send();
            });
        });
    });
};

export const fetchEmployementapi = (formData) => (dispatch) => {
  api()
    .root(JOB_SEEKER_API_ROOT)
    .post(JOBSEEKER_EMPLOYMENT_LIST)
    .data(formData)
    .success((a) => {
      const { result = {} } = a;
      dispatch(experienceList(result.data.data));
    })
    .error((e) => {
      devConsoleLog('error', e);
    })
    .send(() => {

    });
};

export const acceptJobApi = (formData) => (dispatch) => {
  api()
    .root(JOB_SEEKER_API_ROOT)
    .post(JOBSEEKER_ACCEPT_JOBOFFER)
    .data(formData)
    .success((a) => {
      const { result = {} } = a;
      //   dispatch(experienceList(result.data.data));
    })
    .error((e) => {
      devConsoleLog('error', e);
    })
    .send(() => {

    });


}
export const getOfferStatusApi = (formData) => (dispatch) => {
  console.log("dataaaaaa", formData)
  api()
    .root(JOB_SEEKER_API_ROOT)
    .post(JOBSEEKER_JOBOFFER_STATUS)
    .data(formData)
    .success((a) => {
      const { result = {} } = a;
      dispatch(getJobOfferStatus(result));
    })
    .error((e) => {
      devConsoleLog('error', e);
    })
    .send(() => {

    });

}
export const resumeUploadapi = (formData) => (dispatch) => {
  api().root(LOGIN_API_ROOT).post(JOBSEEKER_FILE_UPLOAD).data(formData).success(res => {
    dispatch(profileData({ resume_id: res.file_id }));
    //  successToast({ title: 'Success', msg: res.message });
  })
    .error((e) => {
      const { message = '' } = e ?? {};
      devConsoleLog(e);
      errorToast({ title: 'Failed', msg: message });
    })
    .upload(() => { });
};

export const audioUploadApi = (formData) => (dispatch) => {
  api().root(LOGIN_API_ROOT).post(JOBSEEKER_FILE_UPLOAD).data(formData).success(res => {
    dispatch(profileData({ user_audio_id: res.file_id }));
    //  successToast({ title: 'Success', msg: res.message });
  })
    .error((e) => {

    })
    .upload(() => { });
};

export const getJobTitleApi = (formData) => (dispatch) => {
  api()
    .root(JOB_SEEKER_API_ROOT)
    .post(JOBSEEKER_JOB_TITLE_LIST)
    .data(formData)
    .success((res) => {
      devConsoleLog("thi si sjoblist", res);
      dispatch(profileData({ jobTitle: res.result.data.data }));
      //  successToast({ title: 'Success', msg: res.message });
    })
    .error((e) => {
      const { message = '' } = e ?? {};
      devConsoleLog(e);
    })
    .send(() => { });
};

export const getAllSearchSkillList = (formData) => (dispatch) => {
  api()
    .root(JOB_SEEKER_API_ROOT)
    .post(JOBSEEKER_SKILLS_LIST)
    .data(formData)
    .success((res) => {
      devConsoleLog(res);
      dispatch(setSkillSearchList(res.result.data.data));
      //  successToast({ title: 'Success', msg: res.message });
    })
    .error((e) => {
      const { message = '' } = e ?? {};
      devConsoleLog(e);
    })
    .send(() => { });
};
 
export const addNewSkillApi = (formData,handleUpdate) => (dispatch) => {
  api().root(ADMIN_API_ROOT).post(ADMIN_ADD_SKILL_API).data(formData).success((a) => {
    // handleUpdateSkill(a.result)
    //  handleCall()
    let data = { skill_name : a.result.skill_name};
    handleUpdate(data)
 

  })
     .error(e => {
        errorToast({ title: "Failed", msg: e.message });
     

     })
     .send(() => {
     
     });
};
export const hireEmployerListApi = (formData, setLoading) => (dispatch) => {
  api()
    .root(JOB_SEEKER_API_ROOT)
    .post(JOBSEEKER_HIRED_EMPLOYER_LIST)
    .data(formData)
    .success((res) => {
      dispatch(gethiredEmployerList(res.result?.data));
      setLoading(false)
    })
    .error((e) => {
      const { message = '' } = e ?? {};
      devConsoleLog(e);
    })
    .send(() => { });
};
//event-compted
export const eventCompletedApi = (formData) => (dispatch) => {
  api()
    .root(LOGIN_API_ROOT)
    .post(JOBSEEKER_UPDATE_SCHEDULED_EVENTS)
    .data(formData)
    .success((res) => {
      devConsoleLog(res);
      successToast({ title: 'Success', msg: res.message });
      <Redirect to={JOBSEEKER_DASHBOARD} />
    })
    .error((e) => {
      const { message = '' } = e ?? {};
      devConsoleLog(e);
    })
    .send(() => { });
};

export const getHiredEmployerDetailsList = (formData, setLoading) => (dispatch) => {
  api()
    .root(JOB_SEEKER_API_ROOT)
    .post(JOBSEEKER_HIRED_JOB_LIST)
    .data(formData)
    .success((res) => {
      devConsoleLog(res);
      successToast({ title: 'Success', msg: res.message });
      dispatch(getHiredEmployerDetailsAct(res.result))
      setLoading(false)
      //  <Redirect to={JOBSEEKER_DASHBOARD} />
    })
    .error((e) => {
      const { message = '' } = e ?? {};
      devConsoleLog(e);
    })
    .send(() => { });
};

export const getHiredEmployerDetails = (formData, setLoading) => (dispatch) => {
  api()
    .root(JOB_SEEKER_API_ROOT)
    .post(JOBSEEKER_HIRED_JOB_DETAIL)
    .data(formData)
    .success((res) => {
      devConsoleLog(res);
      successToast({ title: 'Success', msg: res.message });
      dispatch(getHiredEmployerDetailsAct(res))
      setLoading(false)
      //  <Redirect to={JOBSEEKER_DASHBOARD} />
    })
    .error((e) => {
      const { message = '' } = e ?? {};
      devConsoleLog(e);
    })
    .send(() => { });
};
export const getJobSeekerReqiuredCheck = (formData) => (dispatch) => {
  api()
    .root(JOB_SEEKER_API_ROOT)
    .post(JOBSEEKER_PROFILE_REQUIRED_CHECK)
    .data(formData)
    .success((res) => {
      devConsoleLog(res);
      // successToast({ title: 'Success', msg: res.message });      
      // if(res.result === "FullyInserted") {
      //   return;
      // }
      dispatch(getRequiredFieldsList(res))

      //  <Redirect to={JOBSEEKER_DASHBOARD} />
    })
    .error((e) => {
      const { message = '' } = e ?? {};
      devConsoleLog(e);
    })
    .send(() => { });
};
export const getEventDetails = (formData) => (dispatch) => {
  api()
    .root(LOGIN_API_ROOT)
    .post(AUTH_GET_EVENT_DETAILS)
    .data(formData)
    .success(res => { dispatch(getEventDetail(res?.result)) })
    .error()
    .send(() => { });
};
export const deleteDeclainedJobs = (formData, openModal ,setFlagTwo) => (dispatch) => {
  api()
    .root(JOB_SEEKER_API_ROOT)
    .post(JOBSEEKER_DELETE_DECLAINED_JOBS)
    .data(formData)
    .success((res) => {
      devConsoleLog(res);
      setFlagTwo(false)
      // successToast({ title: 'Success', msg: res.message });      
      // if(res.result === "FullyInserted") {
      //   return;
      // }
      dispatch(getRequiredFieldsList(res))
      openModal(true)
      //  <Redirect to={JOBSEEKER_DASHBOARD} />
    })
    .error((e) => {
      const { message = '' } = e ?? {};
      devConsoleLog(e);
    })
    .send(() => { });
};
export const updateJobseekerEmail = (formData,setButtonLoad,setShowOtpDiv) => (dispatch) => {
  api()
    .root(JOB_SEEKER_API_ROOT)
    .post(JOBSEEKER_UPDATE_EMAIL)
    .data(formData)
    .success((res) => {
      setButtonLoad(false)
      setShowOtpDiv(true)
      successToast({ title: 'Success', msg: res.message });      
     

    })
    .error((e) => {
      const { message = '' } = e ?? {};
      devConsoleLog(e);
    })
    .send(() => {
      setButtonLoad(false)
     });
};
export const verifyJobseekerEmail = (formData,closeModal,handleCloseModal) => (dispatch) => {
  api()
    .root(JOB_SEEKER_API_ROOT)
    .post(JOBSEEKER_VERIFY_OTP)
    .data(formData)
    .success((res) => {

      successToast({ title: 'Success', msg: res.message });      
      closeModal();
      handleCloseModal()
      localStorage.setItem('user_email',res.result);
    

    })
    .error((e) => {
      const { message = '' , result = '' } = e ?? {};
      devConsoleLog(e);
      errorToast({ title: 'Error', msg: result });
    })
    .send(() => {
     
     });
};

export const listSignInRequest = (formData, callBack=null) => (dispatch) => {
  api()
    .root(JOB_SEEKER_API_ROOT)
    .post(JOBSEEKER_SIGNIN_LIST)
    .data(formData)
    .success((res) => {
      callBack?.(res)
      let a = res.result.filter((e)=>e.protected_status === "waiting_for_sign")
      dispatch(getProtectedWaitingForSign(a))
    })
    .error((e) => {
      const { message = '' } = e ?? {};
      devConsoleLog(e);
    })
    .send();
};


export const contractDetails = (formData, callBack=null) => (dispatch) => {
  api()
    .root(JOB_SEEKER_API_ROOT)
    .post(JOBSEEKER_CONTRACT_DETAILS)
    .data(formData)
    .success((res) => {
      callBack?.(res)
    })
    .error((e) => {
      const { message = '' } = e ?? {};
      devConsoleLog(e);
    })
    .send();
};

export const sendAgreeContract = (formData, callBack=null,setLoading=null) => (dispatch) => {
  setLoading?.(true)
  api()
    .root(JOB_SEEKER_API_ROOT)
    .post(JOBSEEKER_CONTRACT_AGREE)
    .data(formData)
    .success((res) => {
      successToast({
        title:'Success',
        msg:'Signed document send successfully'
      })
      callBack?.(res)
    })
    .error((e) => {
      const { message = '' } = e ?? {};
      devConsoleLog(e);
    })
    .send(()=>{
      setLoading?.(false)
    });
};
export const getTransformText = (formData, callBack=null) => (dispatch) => {
  
  api()
    .root(JOB_SEEKER_API_ROOT)
    .post(JOBSEEKER_GET_TRANSFORM_TEXT)
    .data(formData)
    .success((res) => {
      
      callBack?.(res.result)
    })
    .error((e) => {
      const { message = '' } = e ?? {};
      devConsoleLog(e);
    })
    .send(()=>{
     
    });
};