import { useSession, useSocket } from '../../../helpers/hooks.js';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  chatMessageStore,
  chatProfileToggle,
  setCallingData,
  setCurrentChat,
  setSocketNotification,
  socketSelector,
} from '../../../redux/slicers/socketSlicer.js';
import PaymentBonus from '../../employer/paymentBonus';
import { CHAT, EMPLOYER } from '../../../config/constant/routePathConstant.js';
import { timeRandomName } from '../../../helpers/jsHelper.js';
import React, { useEffect, useState, useMemo } from 'react';
import { blockChatApi, clearMsgApi, deleteChatApi, muteChatApi } from '../../../api/chat/chatApi';
import ModalView from '../../common/modal';
import { Button, ListGroup, Modal } from 'react-bootstrap';
import { ImageLoader, successToast, getUserData, warningToast } from '../../../helpers/projectHelper';
import { Input, TextArea, DecimalInput, Select } from '../../common/input';
import { Link, MemoryRouter, Switch, Route } from 'react-router-dom';
import { changeDefaultApi, empAddPayment, getCardApi } from '../../../api/employer/employer';
import { hireEmployeeSelector } from '../../../redux/slicers/employer/hireEmployeeSlice';
import ChatPayment from '../../employer/chatPayment'
const reportData = [
  { value: 'harassment', text: 'Harassment' },
  { value: 'hatespeech', text: 'Hate Speech' },
  { value: 'inappropriate', text: 'Sharing Inappropriate things' },

];
const ChatHeader = ({ videoCall }) => {
  const { getStatus = null, sendCalling, sendNotification, notification } = useSocket();
  const [showDiv, setShowDiv] = useState(false);
  const { user_id = '' } = getUserData();
  const [show, setShow] = useState()
  const [open, setOpen] = useState();
  const [showPaymentModal, setOpenPaymentModal] = useState(false);
  const [openModal, setOpenModal] = useState();
  const [addCardModal, setAddCardModal] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { cardData = [] } = useSelector(hireEmployeeSelector);
  const { user_id: from_id = null, full_name: name = '', user_type = '' } = useSession();
  const { currentChat = {}, typing: { chat_id: typing_chat_id, typing }, userList = {}, } = useSelector(socketSelector);
  const { to_details: { full_name = '', _id = '', blocked = [], muted = [], user_img_id = null }, chat_id, hired = false } = currentChat;
  const isBlockedByMine = blocked.indexOf(from_id) > -1;
  const isMutedByMine = muted.indexOf(from_id) > -1;
  const chatBlocked = blocked.length > 0;
  const [flag, setFlag] = useState(false);
  const [formObj, setFormObj] = useState();
  const [loading, setLoading] = useState(false);
  let formData = { ...formObj };
  const handleFormChange = ({ name, value }) => {
    if (name) {
      formData[name] = value;
      setFormObj(formData)
    }
  };
  if (chatBlocked) {
    videoCall = false;
  }

  const PAYMENT_TYPE = [
    { text: 'Salary', value: 'Salary' },
    { text: 'Bonus', value: 'Bonus' },
  ]
  const handleClick = () => {
    dispatch(chatProfileToggle());
  };

  const handleClear = () => {
    if (!window.confirm('Are you sure want clear this chat?')) {
      return;
    }
    dispatch(clearMsgApi(chat_id));
  };
  const handleShow = () => {
    setShow(true)
  }
  const handleBlocked = () => {
    if (!isBlockedByMine) {
      if (!window.confirm('Are you sure want block this chat?')) {
        return;
      }
    }
    dispatch(blockChatApi(chat_id, !isBlockedByMine, () => {
      sendNotification({ type: 'block', chat_id }, _id);
    }));
  };

  const handleMuteStatus = () => {
    if (!isMutedByMine) {
      if (!window.confirm('Are you sure want mute this chat?')) {
        return;
      }
    }
    dispatch(muteChatApi(chat_id, !isMutedByMine));
  };
  const handleDelete = () => {
    if (!window.confirm('Are you sure want delete this chat?')) {
      return;
    }
    dispatch(deleteChatApi(chat_id, () => {
      dispatch(setCurrentChat({}));
      history.push({ pathname: CHAT, state: {} });
    }));
  };

  const handleWrong = () => {
    setOpen(true);
  };
  const handleCloseModal = () => {
    setAddCardModal(false);
  }
  const handleClickVideo = () => {
    const meetPath = `${CHAT}/video/${chat_id}/${from_id}/${_id}`;
    dispatch(setSocketNotification({}));
    dispatch(setCallingData({}));
    dispatch(chatMessageStore({
      _id: timeRandomName('messge'),
      content: 'Video Call',
      chat_id,
      msg_type: 'calling',
      msg_time: Date.now(),
      from_id,
    }));
    sendCalling({ url: meetPath, content: 'Call Started', chat_id, from_id, msg_type: 'calling', name }, _id);
    history.push(meetPath);
  };
  const handleReport = () => () => {
    successToast({ title: 'Success', msg: 'Reported successfully' });
    setOpen(false);
  };


  useEffect(() => {
    let st = setInterval(() => {
      getStatus?.(_id);
    }, 5000)
    return () => {
      clearTimeout(st)
    }
  }, [_id, getStatus]);

  const handleCall = () => {
    const data = {
      employer_id: user_id
    }
    dispatch(getCardApi(data, setFlag))
  }
  const openPaymentModal = () => {
    setOpenPaymentModal(true);
    handleCall();
  }

  const handleClose = () => {

  }
  const addZero = (val) => {
    let data = val
    return ('0' + data).slice(-2)
  }
  const handleDefault = (ele) => {
    if (window.confirm('Are you sure want to change this card to default?')) {
      const obj = {
        "employer_id": user_id,
        "card_id": ele?.id,
        "customer_id": ele?.customer
      }
      dispatch(changeDefaultApi(obj, handleCall))
    }
  }
  const handleAddPayment = () => {
    setLoading(true)
    const { amount = '', amount_type = '', description = '' } = formData;
    if (!amount || !amount_type) {
      return warningToast({ title: "Invalid", msg: "Please enter all the details" })
    }
    let tax = Number(formObj?.amount) * 0.03;
    const bonusObj = {
      user_obj: {

        "user_id": _id,
        "employer_id": user_id,
        "amount_type": formObj?.amount_type,
        "description": formObj?.description,
        "billing_date": new Date(),
        "amount": Number(formObj?.amount)

      }
    }
    dispatch(empAddPayment(bonusObj, setLoading))
  }
  const handleViewProfileClick = () => {
    if(user_type === 'employer'){
      const profile_id = currentChat?.to_details?._id
      if(profile_id){
        const win = window.open(`${EMPLOYER}/applicantprofile/${profile_id}`, "_blank");
        win.focus();
      }
      return
    }
    handleClick()
  }
  const FlowDivone = () => {
    return (
      <>
        <Modal.Body style={{ fontSize: '20px', }}>


          <div className="row">
            <div className="col-md-6">
              <label style={{ fontWeight: 'bold' }}>Enter Amount</label>
              <div className="currency-input">
                <div>$</div>
                <DecimalInput name="amount" type="number" className="currency-usd" onChange={handleFormChange} />

                <span>USD</span>
              </div>
            </div>
            <div className="col-md-6">
              <Select onChange={handleFormChange} label={'Reason'} name={'amount_type'} options={PAYMENT_TYPE} />
            </div>
          </div>

          <span style={{ fontSize: "13px" }}>Note: Once you make payment it will process right away to your default card.</span>
        </Modal.Body>
        <Modal.Footer>

          {' '}
          <Link to="/choose-payment">
            <Button variant="primary">
              Next
            </Button>
          </Link>
          <Button variant="secondary" onClick={() => setOpenPaymentModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </>
    )
  }
  const FlowDivtwo = () => {
    return (
      <>
        <Modal.Body >
          <div className="d-flex align-items-center">
            <Link to='/'><span className="flex text-white bg-info iconButton p-3 fas fa-2x fa-arrow-left" /></Link>
            <h6 className="ml-1">Go Back</h6>
          </div>
          {cardData?.length > 0 ?
            <div className="row p-2">

              {cardData?.map((ele, i) => {
                return (


                  <div className="list-card mb-1" key={i} style={{ fontSize: '10px', width: "100%" }}>
                    <div className="card-div flexwrap">

                      <input type="radio" className={ele?.default ? "card-default mb-0" : ""} checked={ele?.default} onClick={() => handleDefault(ele)} />



                      <span className="ml-5">{ele?.brand}</span>
                      <span>**** **** **** {ele?.last4}</span>
                      <span>{ele?.cardType}</span>
                      <span>Exp Month - {addZero(ele?.exp_month)}</span>
                      <span>Exp Year  - {ele?.exp_year}</span>

                    </div>

                  </div>
                )
              })}
            </div> : <div className="py-5 font-weight-bolder text-center text-gray">No Payment Options</div>

          }
          <button type="button" className="btn btn-primary" onClick={() => setAddCardModal(true)}>ADD CARD</button>
          <div className="bonus-footer">
            <h3>Total Amount:</h3>
            <span>$   {formObj?.amount ? formObj?.amount : '0'}</span>
          </div>
          <span style={{ fontSize: "13px" }}>Note: Once you make payment it will process right away to your default card.</span>
        </Modal.Body>
        <Modal.Footer>

          {' '}
          <Link to="/choose-payment">
            <Button variant="primary" onClick={() => handleAddPayment()}>
              Make Payment
            </Button>
          </Link>
          <Button variant="secondary" onClick={() => setOpenPaymentModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </>
    )
  }

  // const Modalmemo = useMemo(() => {
  //   return (
  //     <Modal
  //     show={showPaymentModal}
  //     onHide={handleClose}
  //     aria-labelledby="example-modal-sizes-title-lg"
  //     size="lg"

  //   >
  //     <Modal.Header closeButton>
  //       <Modal.Title id="example-modal-sizes-title-lg" className="bonus-modal"><h1>Pay Staff</h1>
  //         <h6>Pay staff conveniently with 0% processing fee</h6>
  //         <h6>We'll take care of that for you.</h6>
  //         </Modal.Title>
  //     </Modal.Header>
  //       {!showDiv ? <FlowDivone/> : <FlowDivtwo />} 
  //       <div className="bonus-footer">
  //       <h3>Total Amount:</h3>
  //       <span>$   {formData?.amount ?  formData?.amount : '0'}</span>
  //     </div>



  //   </Modal>
  //   )
  // },[showPaymentModal])
  return <div className='chat-header'>
    <div className='messagecard1-leftrowdetails prevent-hover'>
      <div className='flex overflow-hidden' onClick={handleClick}>
        <div className='messagecard1-leftrowdetails-img'>
          <img src={ImageLoader(user_img_id)} alt='' />
        </div>
        <div className='messagecard1-leftrowdetails-text flex align-items-start flex-column justify-content-center'>
          <h4 className='title text-capitalize'>{full_name}</h4>
          <p className='mt-2  font-weight-bold'>
            {typing && (typing_chat_id === chat_id) ?
              <span className={'text-primary'}>Typing...</span> :
              userList[_id] && <span className={'text-green'}><i className='fa fa-check-circle mr-1' />online</span>}
          </p>
        </div>
      </div>
      <div className='flex mr-3' style={{ alignItems: 'center' }}>
        {user_type === 'employer' &&
          <>
            {hired ? <ChatPayment id={_id} name={"Pay Staff"} /> : <button className="hire-staff-button mr-2" onClick={() => setOpenModal(true)}>Hire Staff</button>}
          </>}
        {/* {user_type === 'employer' && hired &&} */}
        {videoCall && chat_id &&
          <span onClick={handleClickVideo}><i className='iconButton fas fa-video' /></span>}

        <div className='dropdown w-auto'>
          <span className='ml-2 btn dropdown-toggle prevent-after p-0' data-toggle='dropdown'><i
            className='iconButton fas fa-ellipsis-v' /></span>
          <div className='dropdown-menu  dropdown-menu-right'>
            <span className='dropdown-item pointer  py-2' onClick={handleViewProfileClick}><i
              className={'fas fa-user fa-sm mr-2 text-dark'} />View Profile</span>
            {!hired &&
              <>
                <span className='dropdown-item pointer  py-2' onClick={handleDelete}><i
                  className={'fas fa-trash fa-sm mr-2 text-dark'} />Delete Chat</span>
                <span className='dropdown-item pointer  py-2' onClick={handleBlocked}><i
                  className={'fas fa-ban fa-sm mr-2 text-dark'} />{isBlockedByMine ? 'Unblock' : 'Block'}</span>
              </>}
            <span className='dropdown-item pointer  py-2' onClick={handleClear}><i
              className={'fas fa-broom fa-sm mr-2 text-dark'} />Clear Conversation</span>
            <span className='dropdown-item pointer  py-2' onClick={handleMuteStatus}><i
              className={'fa fa-bell-slash fa-sm mr-2 text-dark'} />{isMutedByMine ? 'Unmute' : 'Mute'} Conversation</span>
            <span className='dropdown-item pointer  py-2' onClick={handleWrong}><i
              className={'fas fa-info-circle fa-sm mr-2 text-dark'} />Something's wrong</span>
          </div>
        </div>
        <ModalView open={open} footer={false} onClose={() => setOpen(false)} title={'Let us know what\'s going on'}
          content={
            <>
              <ListGroup>
                {reportData.map(a => <ListGroup.Item as={'span'} key={a.value} action className={'pointer'} onClick={handleReport(a.value)}>{a.text}</ListGroup.Item>)}
                <ListGroup.Item span={'a'}>
                  <p className={"mb-0 pointer"} onClick={handleShow}>Other</p>
                  {show && <> <TextArea />
                    <Input type={"file"} className={"h-auto"} />
                    <Button size={'sm'} className="pull-right mt-2" onClick={handleReport("m")}>Save</Button></>}
                </ListGroup.Item>
              </ListGroup>
            </>
          } />

        <ModalView
          open={openModal} footer={false} onClose={() => setOpenModal(false)}
          content={
            <>
              <div>
                <p style={{ color: '#232266', textAlign: 'center', fontSize: "18px", fontWeight: "bold" }}>Do you want to hire this jobseeker?</p>
                <div>
                  <p style={{ textAlign: 'center', fontSize: "18px" }}>To hire this staff,<br></br>Please click the <b>Send Offer</b> button.</p>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-around', flexDirection: 'column', alignItems: 'center', height: '5rem' }}>
                  <Link to={`${EMPLOYER}/send-job-offer/${_id}`}>
                    <button className="hire-staff-button">Send Offer</button> </Link>
                  <button className="cancel-button" onClick={() => setOpenModal(false)}>Cancel</button>

                </div>
              </div>
            </>

          }
        />

      </div>
    </div>
    {/* {addCardModal &&  <PaymentBonus show={addCardModal}  close={handleCloseModal} call={handleCall} />} */}
  </div>;
};

export default ChatHeader;
