import { checkJson, devConsoleLog, dummyQueryUser, errorToast, getLocal, getUserData, navigate, successToast, storeLocal } from '../../helpers/projectHelper.js';
import {
   CHANGE_PASSWORD,
   EMPLOYEE_LIST,
   EMPLOYEE_SAVE_CARD,
   EMPLOYEE_UNSAVE_CARD,
   EMPLOYER_ADD_PROFILE,
   EMPLOYER_ALL_APPLICANTS_LIST,
   EMPLOYER_API_ROOT,
   EMPLOYER_APPROVED_INVOICE,
   EMPLOYER_CARD_VERIFICATION,
   EMPLOYER_CATEGORY_LIST,
   EMPLOYER_CLOSE_JOB,
   EMPLOYER_CONTRACT_UPDATE,
   EMPLOYER_DELETE_JOB,
   EMPLOYER_EDIT_PROFILE,
   EMPLOYER_EMPLOYEE_DAY,
   EMPLOYER_EMPLOYEE_DAY_GROUP,
   EMPLOYER_EMPLOYEE_DETAILS,
   EMPLOYER_EMPLOYEE_MANUAL_WEEKLY_REPORT,
   EMPLOYER_EMPLOYEE_FILTER_WEAKLY_IMAGES,
   EMPLOYER_EMPLOYEE_FILTER_WEAKLY_IMAGES_DATE,
   EMPLOYER_EMPLOYEE_FILTER_WEAKLY_IMAGES_DATE_TIMES,
   EMPLOYER_EMPLOYEE_FILTER_WEAKLY_TABLE,
   EMPLOYER_EMPLOYEE_MANUAL_TODAY_TRACKED_TIMES,
   EMPLOYER_EMPLOYEE_MANUAL_TRACKED_TIMES,
   EMPLOYER_EMPLOYEE_MANUAL_WEEKLY_TRACKED_TIMES,
   EMPLOYER_EMPLOYEE_SAVE_IMAGES,
   EMPLOYER_EMPLOYEE_SAVE_TIMES,
   EMPLOYER_EMPLOYEE_TODAY_TRACKED_TIMES,
   EMPLOYER_EMPLOYEE_TRACKED_TIMES,
   EMPLOYER_EMPLOYEE_WEAKLY_TABLE,
   EMPLOYER_EMPLOYEE_WEAKLY_TRACKED_TIMES,
   EMPLOYER_GET_ALL_NOTIFICATION_COUNT,
   EMPLOYER_GET_HIRED_JOB,
   EMPLOYER_GET_JOB_OFFER,
   EMPLOYER_HIRE_STAFF_API,
   EMPLOYER_HIRED_INDIVIDIUAL_LIST,
   EMPLOYER_HIRED_STAFF_LIST,
   EMPLOYER_INDIVIDAL_JOB,
   EMPLOYER_INDIVIDUAL_OFFER_LIST,
   EMPLOYER_INVOICE_LIST,
   EMPLOYER_JOB_APPLICANTS_LIST,
   EMPLOYER_JOB_COUNT,
   EMPLOYER_JOB_FILTER,
   EMPLOYER_JOB_VIEWS_COUNT,
   EMPLOYER_JOBTITLE_LIST,
   EMPLOYER_LIST_COUNT,
   EMPLOYER_LIST_PROFILE,
   EMPLOYER_NEW_APPLICANT_COUNT,
   EMPLOYER_NOTIFICATION_LIST,
   EMPLOYER_NOTIFICATION_UPDATE,
   EMPLOYER_NOTIFY_JOB_OFFER_COUNT,
   EMPLOYER_OFFER_LIST,
   EMPLOYER_OFFER_UPDATE,
   EMPLOYER_POST_A_JOB_API,
   EMPLOYER_SEND_JOBOFFER,
   EMPLOYER_SKILL_LIST,
   EMPLOYER_TOTAL_APPLICANTS_LIST,
   EMPLOYER_TRANSACTION_LIST,
   EMPLOYER_UPDATE_JOB,
   GET_BOOKMARK_JOBSEEKER,
   GET_BOOKMARK_JOBSEEKER_LIST,
   GET_POSTED_JOB_LIST,
   JOBSEEKER_FILE_UPLOAD,
   JOBSEEKER_INDIVIDUAL_LIST,
   LOGIN_API_ROOT,
   EMPLOYER_INVOICE_ACTION,
   LOCAL_HOST_BACKEND,
   EMPLOYER_INDIVIUAL_INVOICE,
   LOCAL_HOST_BACKEND_EMPLOYER,
   EMPLOYER_INVOICE_UPDATE,
   EMPLOYER_PAYMENT_DISPUTE_TICKET,
   EMPLOYER_CONFIRM_JOBOFFER,
   EMPLOYER_ADD_BONUS,
   EMPLOYER_BONUS_TRANSACTION_LIST,
   EMPLOYER_RELEASE_DISPUTE,
   EMPLOYER_TERMINATE_API,
   EMPLOYER_ADD_CARD,
   EMPLOYER_DELETE_cARD_DETAILS,
   EMPLOYER_GET_CARD_DETAILS,
   WORK_LOG_API_ROOT,
   EMPLOYER_CHANGE_DEFAULT_CARD,
   JOB_SEEKER_API_ROOT,
   EMPLOYER_EMPLOYEE_DETAILS_EMPLOYER,
   EMPLOYER_ACTIVATE_JOB,
   EMPLOYER_ADD_PAYMENT,
   EMPLOYER_RECEIPT,
   EMPLOYER_UPDATE_EMAIL,
   EMPLOYER_VERIFY_OTP,
   EMPLOYER_SUBSCRIBE_PLAN,
   EMPLOYER_SUBSCRIPTION_UPDATE,
   EMPLOYER_UPDATE_JOB_APPLICANT,
   EMPLOYER_DELETE_JOB_APPICANT,
   EMPLOYER_ORDER_SUCCESS,
   EMPLOYER_SIGNIN_REQUEST,
   EMPLOYER_PROTECTED_PAY,
   EMPLOYER_PROTECTED_CANCEL,
   EMPLOYER_CONTRACT_DETAILS,
   EMPLOYER_SIGNIN_LIST,
   EMPLOYER_GET_TALENTS,
   EMPLOYER_GET_TRANSFORMTEXT,
   EMPLOYER_UPDATE_AGREEMENT,
   EMPLOYER_USER_MODAL_ALERT,
   EMPLOYER_DELETE_REQUEST
} from '../../config/constant/apiConstant.js';
import api from '../../helpers/axiosHelper.js';
import {
   postJobCategoryData,
   postJobFormData,
   postJobLoading,
   postJobSkillsData,
   postJobTitleData,
} from '../../redux/slicers/employer/postJobSlice.js';
import {
   addressProofAct,
   empApplicantCountData,
   employeeDatas,
   employeeManualTodayTrackedTImes,
   employeeManualTrackedTImes,
   employeeManualWeeklyTrackedTImes,
   employeeTodayTrackedTImes,
   employeeTrackedDays,
   employeeTrackedDaysGroups,
   employeeTrackedImageTables,
   employeeTrackedTimes,
   employeeWeaklyTrackedTImes,
   employerDashCardData,

   imageCounts,
   totalTImeCounts,
   weaklyReports,
   trackingLoaders,
} from '../../redux/slicers/employer/empDashboardSlice.js';
import {
   allApplicantsData,
   bookmarkedEmpList,
   bookmarkEmployeeCheck,
   employeeListData,
   getBookmarkedEmpCount,
   getEmpIndividualOfferList,
   getEmpInvoiceList,
   getEmpProfileData,
   getHiredStaff,
   getIndividualInvoiceList,
   getIndividualProjectList,
   getJobOfferData,
   getOfferCount,
   getPostedJobsCount,
   getProjectDetails,
   getStaffCount,
   getTotalAppCount,
   getTransactionList,
   hiredEpmIndividualDetail,
   individualEmpData,
   individualJobsData,
   jobApplicantsData,
   jobViewData,
   postedJobsData,
   selfieFileAct,
   viewedApplicantData,
   govFileAct,
   getBonusTransactionList,
   setLoader,
   getSendOfferData,
   setLoaderMessage,
   getCardData,
   setPaymentError
} from '../../redux/slicers/employer/hireEmployeeSlice.js';
import {
   EMPLOYER,
   EMPLOYER_HIRED_EMPLOYER_LIST,
   EMPLOYER_HIRED_STAFF,
   EMPLOYER_POSTED_JOB_LIST,
   EMPLOYER_PROFILE,
   EMPLOYER_TRANSACTIONS,
   EMPLOYER_TRANSACTION_HISTORY,
   EMPLOYER_VIEW_JOBOFFER,
   EMPLOYER_VIEW_PROFILE,
} from '../../config/constant/routePathConstant.js';
import { employerSettingsData } from '../../redux/slicers/employer/empDashboardSlice';
import { setAlert } from '../../components/task/actions/alert.js';
import axios from 'axios';
import { EMPLOYER_SUBSCRIPTION_REFRESH, EMPLOYER_CONVERSION } from './../../config/constant/apiConstant';
import { setSubscriptionDetails } from '../../redux/slicers/sessionSlice.js';
import { USER_DATA } from '../../config/constant/projectConstant.js';


export const getEmpDashDataApi = () => (dispatch) => {
   const { user_id: employer_id } = getUserData();
   const formData = { query: { employer_id } };
   api().root(EMPLOYER_API_ROOT).post(EMPLOYER_JOB_COUNT).data(formData).success(({ result = 0 }) => {
      dispatch(employerDashCardData({ job_count: result }))
   }).send();
};

export const getEmployerSkillsApi = () => (dispatch) => {
   api().root(EMPLOYER_API_ROOT).post(EMPLOYER_SKILL_LIST).data({ 'query': {}, 'limit': 170, 'skip': 0 }).success(({ result }) => {
      dispatch(postJobSkillsData(result?.data?.data));
   }).send();
};

export const getEmployerCategoryApi = () => (dispatch) => {
   return api().root(EMPLOYER_API_ROOT).post(EMPLOYER_CATEGORY_LIST).data({ 'query': {}, 'limit': 100, 'skip': 0 }).success(({ result }) => {
      dispatch(postJobCategoryData(result?.data?.data));
   }).send();
}

export const getEmployeeApi = (formData, setLoading) => (dispatch) => {
   api().root(EMPLOYER_API_ROOT).post(EMPLOYEE_LIST).data(formData).success(({ result }) => {
      dispatch(employeeListData(result?.data))
      dispatch(getStaffCount(result?.data?.recordsTotal))
      setLoading(false);
   }).send();
}

export const saveEmployeeApi = (formData) => (dispatch) => {
   api().root(EMPLOYER_API_ROOT).post(EMPLOYEE_SAVE_CARD).data(formData).success(({ result }) => {
      successToast({ title: 'Success', msg: 'You have added this jobseeker to your bookmark'});
      // dispatch(employeeListData(result?.data?.data))
      const { user_id = "" } = getUserData();
      const formValue = {

         employer_id: user_id,
         limit: 10,
         skip: 0
      }
      dispatch(getEmployeeApi(formValue));

   }).send(() => {
      api().root(EMPLOYER_API_ROOT).post(GET_BOOKMARK_JOBSEEKER).data(formData).success(({ result }) => {
         successToast({ title: 'Success', msg: result.message });
         dispatch(bookmarkEmployeeCheck(result))
      }).send()
   });
}

export const unSaveEmployeeApi = (formData) => (dispatch) => {
   api().root(EMPLOYER_API_ROOT).post(EMPLOYEE_UNSAVE_CARD).data(formData).success(({ result }) => {
      // dispatch(employeeListData(result?.data?.data))
   }).send(() => {
      api().root(EMPLOYER_API_ROOT).post(GET_BOOKMARK_JOBSEEKER).data(formData).success(({ result }) => {
         // successToast({ title: 'Success', msg: result.message });
         dispatch(bookmarkEmployeeCheck(result))
      }).send()
   })
}

export const getEmployeeListApi = ({ data, user_id }) => (dispatch) => {
   api().root(EMPLOYER_API_ROOT).post(JOBSEEKER_INDIVIDUAL_LIST).data(data).success(({ result }) => {

      dispatch(individualEmpData(result))
   }).send((a, b, c) => {
      const form = {
         employer_id: user_id,
         applicant_id: data.user_id
      }
      api().root(EMPLOYER_API_ROOT).post(GET_BOOKMARK_JOBSEEKER).data(form).success(({ result }) => {

         dispatch(bookmarkEmployeeCheck(result))
      }).send()
   });
}
export const bookmarkEmpListApi = (formData, setLoading) => (dispatch) => {

   api().root(EMPLOYER_API_ROOT).post(GET_BOOKMARK_JOBSEEKER_LIST).data(formData).success(a => {
      dispatch(bookmarkedEmpList(a?.result?.data?.data))
      dispatch(getBookmarkedEmpCount(a?.result?.data?.recordsTotal))
      setLoading(false)
   }).send();
};

export const getEmpJobApplicantApi = (formData) => (dispatch) => {
   api().root(EMPLOYER_API_ROOT).post(EMPLOYER_JOB_APPLICANTS_LIST).data(formData).success(a => {
      dispatch(bookmarkedEmpList(a?.result?.data))
   }).send();
}

export const getPostedJobList = (formData) => (dispatch) => {
   api().root(EMPLOYER_API_ROOT).post(GET_POSTED_JOB_LIST).data(formData).success(a => {
      dispatch(postedJobsData(a?.result?.data?.data))

   }).send();
}
export const postJobApi = (formData,setLoading) => (dispatch) => {
   dispatch(postJobLoading(true))
   return api().root(EMPLOYER_API_ROOT).post(EMPLOYER_POST_A_JOB_API).data(formData).success(({ result, message }) => {
      const data = {};
      dispatch(postJobFormData(data))
       successToast({ title: 'Success', msg: message })
       navigate(EMPLOYER_POSTED_JOB_LIST);
       setTimeout(() => {
         window.location.reload(true);
       },1000)
       setLoading(false)

   }).error(e => {
      const { message: msg = '' } = e;
      
      errorToast({ title: 'Failure', msg: msg});
   }).send(() => {
      dispatch(postJobLoading(false))
      setLoading(false)
   });


}
export const editJobApi = (formData,setLoading) => (dispatch) => {
   // dispatch(postJobLoading(true))
   return api().root(EMPLOYER_API_ROOT).post(EMPLOYER_UPDATE_JOB).data(formData).success(({ result, message }) => {
      successToast({ title: 'Success', msg: message })
      navigate(EMPLOYER_POSTED_JOB_LIST);
      setLoading(false)
      setTimeout(() => {
         window.location.reload(true);
      },1000)
      const data = {}
      dispatch(postJobFormData(data))
   }).error(e => {
      const { message: msg = '' } = e;
      errorToast({ title: 'Failure', msg: msg});
   }).send(() => { setLoading(false)
   });


}

export const deleteJobApi = (formData) => (dispatch) => {
   api().root(EMPLOYER_API_ROOT).post(EMPLOYER_DELETE_JOB).data(formData).success(a => {
      // dispatch(bookmarkedEmpList(a?.result?.data))
      console.log(a);
   }).send();
}
export const closeJobApi = (formData) => (dispatch) => {
   api().root(EMPLOYER_API_ROOT).post(EMPLOYER_CLOSE_JOB).data(formData).success(a => {
      const { user_id = null } = getUserData();
      const apiData = {

         employer_id: user_id,
         limit: 10,
         skip: 0
      }

      dispatch(postJobFilterApi(apiData)) 
      successToast({ title: 'Success', msg: a.message });
   }).send();
}
export const activateJobApi = (formData) => (dispatch) => {
   api().root(EMPLOYER_API_ROOT).post(EMPLOYER_ACTIVATE_JOB).data(formData).success(a => {
      const { user_id = null } = getUserData();
      const apiData = {

         employer_id: user_id,
         limit: 10,
         skip: 0
      }

      dispatch(postJobFilterApi(apiData));
      successToast({ title: 'Success', msg: a.message });
   }).send();
}

export const getJobApplicantsApi = (formData, setLoading) => (dispatch) => {
   api().root(EMPLOYER_API_ROOT).post(EMPLOYER_JOB_APPLICANTS_LIST).data(formData).success(a => {
      dispatch(jobApplicantsData(a?.result?.data));
 
      setLoading(false)
   }).send();
}

export const getIndividualJobApi = (formData) => (dispatch) => {
   const data = {
      job_id: formData
   }
   api().root(EMPLOYER_API_ROOT).post(EMPLOYER_INDIVIDAL_JOB).data(data).success(a => {
      let newObjData = a.result;
      let oldObjData = a.result;
      if (newObjData?.salary_type === 'range') {
         oldObjData['salary_amount'] = oldObjData?.salary_range_to
      }
      if (newObjData?.work_hours_type === 'range') {
         oldObjData['work_hours'] = oldObjData?.work_hours_to
      }

      dispatch(postJobFormData(newObjData))
      dispatch(individualJobsData(newObjData))
      dispatch(getSendOfferData(oldObjData))


      console.log(a);
   }).send();
}



export const getApplicationCountApi = (formData) => (dispatch) => {
   const data = {
      job_id: formData
   }
   api().root(EMPLOYER_API_ROOT).post(EMPLOYER_JOB_VIEWS_COUNT).data(data).success(a => {
      dispatch(jobViewData(a))
   }).send();
}


export const postJobFilterApi = (formData, setLoading) => (dispatch) => {
   api().root(EMPLOYER_API_ROOT).post(EMPLOYER_JOB_FILTER).data(formData).success(a => {
      dispatch(postedJobsData(a?.result?.data?.data))
      dispatch(getPostedJobsCount(a?.result?.data?.recordsTotal))
      setLoading(false)
     
   }).send();
}


export const viewedApplicantProfileApi = (formData) => (dispatch) => {
   api().root(EMPLOYER_API_ROOT).post(EMPLOYER_TOTAL_APPLICANTS_LIST).data(formData).success(a => {
      dispatch(viewedApplicantData(a?.result?.data))
   }).send();
}

export const getAllCountApi = (formData) => (dispatch) => {
   const data = {
      employer_id: formData
   }
   api().root(EMPLOYER_API_ROOT).post(EMPLOYER_LIST_COUNT).data(data).success(a => {
      dispatch(getTotalAppCount(a?.result))
   }).send();
}
export const getAllApplicantsApi = (formData, setLoading) => (dispatch) => {

   api().root(EMPLOYER_API_ROOT).post(EMPLOYER_ALL_APPLICANTS_LIST).data(formData).success(a => {
      dispatch(allApplicantsData(a?.result?.data))
      setLoading(false)
   
   }).send();
}
export const getUpdateApplicantsApi = (formData,handleCall) => (dispatch) => {

   api().root(EMPLOYER_API_ROOT).post(EMPLOYER_UPDATE_JOB_APPLICANT).data(formData).success(a => {
      handleCall()
      successToast({ title: 'Success', msg: a.message })
   
   }).send();
}
export const deleteApplicantsApi = (formData,handleCall,setShowModal) => (dispatch) => {

   api().root(EMPLOYER_API_ROOT).post(EMPLOYER_DELETE_JOB_APPICANT).data(formData).success(a => {
      setShowModal(false)
      handleCall()
      successToast({ title: 'Success', msg: a.message })
 
   }).send();
}
export const empSendJobOffer = (formData, setLoading,sendMsg,id) => (dispatch) => {

   api().root(EMPLOYER_API_ROOT).post(EMPLOYER_SEND_JOBOFFER).data(formData).success(a => {
      successToast({ title: 'Success', msg: a.message })
      sendMsg({
         content: 'https://app.virtualstaff.ph/jobseeker/viewjoboffer',
         msg_type: "job_offer"
   
       },id)
      navigate(EMPLOYER_VIEW_JOBOFFER)
      setLoading(false);
   }).error((err) => {
      setLoading(false)
      errorToast({ title: 'Failure', msg: err.message });
   }).send();
}


export const addEmpProfile = (formData) => (dispatch) => {

   api().root(EMPLOYER_API_ROOT).post(EMPLOYER_ADD_PROFILE).data(formData).success(a => {
      successToast({ title: 'Success', msg: a.message })
   }).send();
}
export const updateEmpProfile = (formData, val) => (dispatch) => {
   const { user_id = '' } = getUserData()
   const datas = {
      employer_id: user_id,
   };
   api().root(EMPLOYER_API_ROOT).post(EMPLOYER_EDIT_PROFILE).data(formData).success(a => {
      successToast({ title: 'Success', msg: val === "true" ? "Account delete request has been sent to admin" : a.message })
      dispatch(getEmpProfile(datas));
      navigate(EMPLOYER_VIEW_PROFILE)
      console.log(a);
   }).send(() => {
      dispatch(setLoader(false));
      dispatch(setLoaderMessage(''));
   });
}
export const deleteEmpProfile = (formData, val) => (dispatch) => {
   const { user_id = '' } = getUserData()
   const datas = {
      employer_id: user_id,
   };
   api().root(EMPLOYER_API_ROOT).post(EMPLOYER_DELETE_REQUEST).data(formData).success(a => {
      successToast({ title: 'Success', msg: val === "true" ? "Account delete request has been sent to admin" : a.message })
      dispatch(getEmpProfile(datas));
      navigate(EMPLOYER_VIEW_PROFILE)
      console.log(a);
   }).send(() => {
      dispatch(setLoader(false));
      dispatch(setLoaderMessage(''));
   });
}
export const getEmpProfile = (formData) => (dispatch) => {
   api().root(EMPLOYER_API_ROOT).post(EMPLOYER_LIST_PROFILE).data(formData).success(a => {
      //  successToast({ title: 'Success', msg: a.message })
      dispatch(getEmpProfileData(a?.result));
      let { subscription_settings ={} } = a.result;
      let data = localStorage.getItem('userData')
      let res = JSON.parse(data);
     
   }).send();
}
export const getRefreshData = (formData) => (dispatch) => {
   api().root(EMPLOYER_API_ROOT).post(EMPLOYER_SUBSCRIPTION_REFRESH).data(formData).success(a => {
      dispatch(setSubscriptionDetails(a.result.plan_details));
      let data  = getLocal(USER_DATA);
      data.plan_details  = a.result.plan_details;
      storeLocal(data,USER_DATA);
   }).send();
}
export const getJobOfferList = (formData, setLoading) => (dispatch) => {

   api().root(EMPLOYER_API_ROOT).post(EMPLOYER_OFFER_LIST).data(formData).success(a => {
      //  successToast({ title: 'Success', msg: a.message })
      dispatch(getJobOfferData(a?.result?.data?.data));
      dispatch(getOfferCount(a?.result?.data?.recordsTotal))
      setLoading(false);
   }).send();
}

export const empChangePassword = (formData) => (dispatch) => {
   api().root(LOGIN_API_ROOT).post(CHANGE_PASSWORD).data(formData).success(a => {
      successToast({ title: 'Success', msg: a.message })
      //  dispatch(getJobOfferData(a?.result?.data?.data));
      console.log(a);
   }).send();
}

export const empDeleteJob = (formData) => (dispatch) => {
   api().root(EMPLOYER_API_ROOT).post(EMPLOYER_DELETE_JOB).data(formData).success(a => {
      successToast({ title: 'Success', msg: a.message })
      const { user_id = null } = getUserData();
      const apiData = {

         employer_id: user_id,
         limit: 10,
         skip: 0
      }

      dispatch(postJobFilterApi(apiData))
     
   }).send();
}

export const empNotificationApi = (formData) => (dispatch) => {
   const data = {
      employer_id: formData
   }
   api().root(EMPLOYER_API_ROOT).post(EMPLOYER_NOTIFICATION_LIST).data(data).success(a => {
      dispatch(employerSettingsData(a?.result?.notification_settings));
   }).send();
};

export const updateNotificationApi = (formData) => (dispatch) => {

   api().root(EMPLOYER_API_ROOT).post(EMPLOYER_NOTIFICATION_UPDATE).data(formData).success(a => {
      dispatch(employerSettingsData(a?.result?.notification_settings));
      const { user_id = '' } = getUserData()
      //  const data = {
      //    employer_id : user_id
      // }
      dispatch(empNotificationApi(user_id));
      console.log(a);
   }).send();
};
export const getJobOfferCountApi = (formData) => (dispatch) => {
   api().root(EMPLOYER_API_ROOT).post(EMPLOYER_NOTIFY_JOB_OFFER_COUNT).data(formData).success(a => {
      // dispatch(empOfferCountData(a.result))
   }).send();
};
export const getApplicantCountApi = (formData) => (dispatch) => {
   api().root(EMPLOYER_API_ROOT).post(EMPLOYER_NEW_APPLICANT_COUNT).data(formData).success(a => {
      // dispatch(empApplicantCountData(a.result))
   }).send();
}
export const getNotifyCountApi = (formData) => (dispatch) => {
   api().root(EMPLOYER_API_ROOT).post(EMPLOYER_GET_ALL_NOTIFICATION_COUNT).data(formData).success(a => {
      dispatch(empApplicantCountData(a.result))
   }).send();
}

export const projectDetailsApi = (formData) => (dispatch) => {
   api().root(EMPLOYER_API_ROOT).post(EMPLOYER_GET_JOB_OFFER).data(formData).success(a => {
      dispatch(getProjectDetails(a.result))
   }).send()
}
export const getEmployeeDetails = (formData) => (dispatch) => {
   api().root(JOB_SEEKER_API_ROOT).post(EMPLOYER_EMPLOYEE_DETAILS).data(formData).success(a => {
      dispatch(employeeDatas(a.result))
   }).send()
}

export const getEmpEmployeeDetails = (formData) => (dispatch) => {
   api().root(EMPLOYER_API_ROOT).post(EMPLOYER_EMPLOYEE_DETAILS_EMPLOYER).data(formData).success(a => {
      dispatch(employeeDatas(a.result))
   }).send()
}


export const getEmployeeTrackedTimes = (formData) => (dispatch) => {
   api().root(WORK_LOG_API_ROOT).post(EMPLOYER_EMPLOYEE_TRACKED_TIMES).data(formData).success(a => {
      dispatch(employeeTrackedTimes(a.result))
   }).send()
}

export const getEmployeeTrackedWeaklyTimes = (formData) => (dispatch) => {
   api().root(WORK_LOG_API_ROOT).post(EMPLOYER_EMPLOYEE_WEAKLY_TRACKED_TIMES).data(formData).success(a => {
      dispatch(employeeWeaklyTrackedTImes(a.result))
   }).send()
}

export const getEmployeeTrackedTodayTimes = (formData) => (dispatch) => {
   api().root(WORK_LOG_API_ROOT).post(EMPLOYER_EMPLOYEE_TODAY_TRACKED_TIMES).data(formData).success(a => {
      dispatch(employeeTodayTrackedTImes(a.result))
   }).send()
}

export const getEmployeeManualTrackedTimes = (formData) => (dispatch) => {

   api().root(WORK_LOG_API_ROOT).post(EMPLOYER_EMPLOYEE_MANUAL_TRACKED_TIMES).data(formData).success(a => {
      dispatch(employeeManualTrackedTImes(a.result))
   }).send()
}

export const getEmployeeManualWeeklyTrackedTimes = (formData) => (dispatch) => {

   api().root(WORK_LOG_API_ROOT).post(EMPLOYER_EMPLOYEE_MANUAL_WEEKLY_TRACKED_TIMES).data(formData).success(a => {
      dispatch(employeeManualWeeklyTrackedTImes(a.result))
   }).send()
}

export const getEmployeeManualTodayTrackedTimes = (formData) => (dispatch) => {

   api().root(WORK_LOG_API_ROOT).post(EMPLOYER_EMPLOYEE_MANUAL_TODAY_TRACKED_TIMES).data(formData).success(a => {
      dispatch(employeeManualTodayTrackedTImes(a.result))
   }).send()
}

export const saveEmployeeTimes =
   (formData, callback = null) =>
      (dispatch) => {
         api()
            .root(WORK_LOG_API_ROOT)
            .post(EMPLOYER_EMPLOYEE_SAVE_TIMES)
            .data(formData)
            .success((a) => {
               successToast({ title: 'Success', msg: a.message });
               //   dispatch(employeeDatas(a.result))
               callback?.();
            })
            .send();
      };

export const saveEmployeeTrackedImages = (formData) => (dispatch) => {
   api().root(WORK_LOG_API_ROOT).post(EMPLOYER_EMPLOYEE_SAVE_IMAGES).data(formData).success(a => {
      //   dispatch(employeeDatas(a.result))
   }).send()
}



export const employeeWithDates = (formData) => (dispatch) => {
   dispatch(trackingLoaders(true))
   api().root(WORK_LOG_API_ROOT).post(EMPLOYER_EMPLOYEE_WEAKLY_TABLE).data(formData).success(a => {
      dispatch(trackingLoaders(false))
      dispatch(employeeTrackedTimes(a.result))
      dispatch(totalTImeCounts(a.sumCount))
   }).send()
}

export const employeeWithFilteredDates = (formData) => (dispatch) => {
   
   api().root(WORK_LOG_API_ROOT).post(EMPLOYER_EMPLOYEE_FILTER_WEAKLY_TABLE).data(formData).success(a => {
      // dispatch(trackingLoaders(false))
      dispatch(employeeTrackedTimes(a.result))
      dispatch(totalTImeCounts(a.sumCount))
   }).error((err) => {
      dispatch(trackingLoaders(false))
      errorToast({ title: 'Failure', msg: err.message });
   }).send()
   dispatch(trackingLoaders(false))
}

export const employeeWithDay = (formData) => (dispatch) => {
   dispatch(trackingLoaders(true))
   api().root(WORK_LOG_API_ROOT).post(EMPLOYER_EMPLOYEE_DAY).data(formData).success(a => {
      dispatch(trackingLoaders(false))
      dispatch(employeeTrackedDays(a.result))
      dispatch(totalTImeCounts(a.sumCount))
   }).send()
}


export const employeeWithDayGroup = (formData) => (dispatch) => {
   dispatch(trackingLoaders(true))
   api().root(WORK_LOG_API_ROOT).post(EMPLOYER_EMPLOYEE_DAY_GROUP).data(formData).success(a => {
      dispatch(trackingLoaders(false))
      dispatch(employeeTrackedDaysGroups(a.result))
   }).send()
}


export const employeeWithImages = (formData) => (dispatch) => {

   api().root(WORK_LOG_API_ROOT).post(EMPLOYER_EMPLOYEE_FILTER_WEAKLY_IMAGES).data(formData).success(a => {
      dispatch(employeeTrackedImageTables(a.result))
   }).send()
}

export const employeeWithFilteredImages = (formData) => (dispatch) => {

   dispatch(trackingLoaders(true))
   api().root(WORK_LOG_API_ROOT).post(EMPLOYER_EMPLOYEE_FILTER_WEAKLY_IMAGES_DATE).data(formData).success(a => {
      dispatch(trackingLoaders(false))
      dispatch(employeeTrackedImageTables(a.result))
      dispatch(imageCounts(a.trackerImageCount))
   }).send()
}

export const employeeWithFilteredTimesImages = (formData) => (dispatch) => {
   dispatch(trackingLoaders(true));
   api()
      .root(WORK_LOG_API_ROOT)
      .post(EMPLOYER_EMPLOYEE_FILTER_WEAKLY_IMAGES_DATE_TIMES)
      .data(formData)
      .success((a) => {
         dispatch(trackingLoaders(false));
         dispatch(employeeTrackedImageTables(a.result));
      })
      .send();
};


export const employeeWithWeaklyReport = (formData) => (dispatch) => {
   api()
      .root(WORK_LOG_API_ROOT)
      .post(EMPLOYER_EMPLOYEE_MANUAL_WEEKLY_REPORT)
      .data(formData)
      .success((a) => {
         dispatch(weaklyReports(a.result));
      })
      .send();
};


export const scheduleInterview = (formData, setShowModal) => (dispatch) => {
   api()
      .root(LOGIN_API_ROOT)
      .post('/auth/event')
      .data(formData)
      .success((a) => {
         successToast({ title: 'Success', msg: a.message });
         setShowModal(false);

      })
      .send();
};
export const empHireStaffApi = (formData, setLoader) => (dispatch) => {
   api().root(EMPLOYER_API_ROOT).post(EMPLOYER_HIRE_STAFF_API).data(formData).success(a => {
      navigate(EMPLOYER_HIRED_EMPLOYER_LIST)
      successToast({ title: 'Success', msg: a.message })
      setLoader(false)
   }).error((err) => {
      errorToast({ title: 'Failure', msg: err.message });
   }).send(() => {
      setLoader(false)
   })
}
export const empHiredStaffApi = (formData, setLoading) => (dispatch) => {
   api().root(EMPLOYER_API_ROOT).post(EMPLOYER_HIRED_STAFF_LIST).data(formData).success(a => {
      dispatch(getHiredStaff(a.result.data))
      setLoading(false)
   }).error((err) => {
      //   console.log('err',err);
      // errorToast({title:'Failure', msg:err.message});
   }).send()
}

export const empTransactionApi = (formData, setLoading) => (dispatch) => {
   api().root(EMPLOYER_API_ROOT).post(EMPLOYER_TRANSACTION_LIST).data(formData).success(a => {

      dispatch(getTransactionList(a.result.data));
      setLoading(false)

   }).error((err) => {
      // errorToast({title:'Failure', msg:err.message});

   }).send()
}
export const empInvoiceApi = (formData, setLoading) => (dispatch) => {
   api().root(EMPLOYER_API_ROOT).post(EMPLOYER_INVOICE_LIST).data(formData).success(a => {
      dispatch(getEmpInvoiceList(a.result.data))
      setLoading(false)

   }).error((err) => {
      errorToast({ title: 'Failure', msg: err.message });
      setLoading(false)

   }).send()
}

export const empAccpetInvoiceApi = (formData, setLoading) => (dispatch) => {
   api().root(EMPLOYER_API_ROOT).post(EMPLOYER_APPROVED_INVOICE).data(formData).success(a => {
      // dispatch(getEmpInvoiceList(a.result.data.data))
      successToast({ title: 'Success', msg: "Approved Successful" })
      setLoading(false)
   }).error((err) => {
      errorToast({ title: 'Failure', msg: err.message });

   }).send()
}
export const empJobTitleData = (formData) => (dispatch) => {
   api().root(EMPLOYER_API_ROOT).post(EMPLOYER_JOBTITLE_LIST).data(formData).success(result => {
      dispatch(postJobTitleData(result?.result?.data?.data))

   }).error((err) => {
      // errorToast({title:'Failure', msg:err.message});

   }).send()
}


export const empEditJobOfferApi = (formData) => (dispatch) => {
   api().root(EMPLOYER_API_ROOT).post(EMPLOYER_INDIVIDUAL_OFFER_LIST).data(formData).success(result => {
      dispatch(getEmpIndividualOfferList(result?.result))
      // dispatch(individualJobsData(result?.result))
   }).error((err) => {
      // errorToast({title:'Failure', msg:err.message});

   }).send()
}
export const empOfferUpdateApi = (formData, setLoading) => (dispatch) => {
   api().root(EMPLOYER_API_ROOT).post(EMPLOYER_OFFER_UPDATE).data(formData).success(result => {
      navigate(EMPLOYER_VIEW_JOBOFFER)
   }).error((err) => {
      errorToast({ title: 'Failure', msg: err.message });
      setLoading(false)
   }).send()
}

export const empHiredIndividualApi = (formData, setLoading) => (dispatch) => {
   api().root(EMPLOYER_API_ROOT).post(EMPLOYER_HIRED_INDIVIDIUAL_LIST).data(formData).success(r => {
      dispatch(hiredEpmIndividualDetail(r.result))
      setLoading(false)

   }).error((err) => {
      errorToast({ title: 'Failure', msg: err.message });
      setLoading(false)
   }).send()
}
export const govFileUploadApi = (formData,progress) => (dispatch) => {
   api().root(LOGIN_API_ROOT).post(JOBSEEKER_FILE_UPLOAD).data(formData).onUploadProgress(progress).success(res => {
      dispatch(govFileAct(res.file_id));
      successToast({ title: 'Success', msg: res.message });
   })
      .error((e) => {
         const { message = '' } = e ?? {};
         devConsoleLog(e);
         //  errorToast({ title: 'Failed', msg: message });
      })
      .upload();
};

export const selfieUploadApi = (formData,progress) => (dispatch) => {
   api().root(LOGIN_API_ROOT).post(JOBSEEKER_FILE_UPLOAD).data(formData).onUploadProgress(progress).success(res => {
      dispatch(selfieFileAct(res?.file_id));
      successToast({ title: 'Success', msg: res.message });
   })

      .upload();
};
export const addressProofApi = (formData) => (dispatch) => {
   api().root(LOGIN_API_ROOT).post(JOBSEEKER_FILE_UPLOAD).data(formData).success(res => {
      dispatch(addressProofAct(res?.file_id));

   })

      .upload(() => { });
};

export const cardVerificationApi = (formData, setLoading) => (dispatch) => {
   const { user_id = '' } = getUserData();
   api().root(EMPLOYER_API_ROOT).post(EMPLOYER_CARD_VERIFICATION).data(formData).success(res => {
      const datas = {
         employer_id: user_id,
      }
      successToast({ title: 'Success', msg: res.message });

      dispatch(getEmpProfile(datas));

   })
      .error((e) => {
         const { message = '' } = e ?? {};
         devConsoleLog(e);

         errorToast({ title: 'Failed', msg: message });
      })
      .send(() => {
         setLoading(false)
      });
};


export const getEmpHiredJobApi = (formData) => (dispatch) => {
   api().root(EMPLOYER_API_ROOT).post(EMPLOYER_GET_HIRED_JOB).data(formData).success(r => {
      dispatch(getIndividualProjectList(r.result))


   }).error((err) => {
      errorToast({ title: 'Failure', msg: err.message });

   }).send()
}
export const empContactUpdateApi = (formData,handleCall) => (dispatch) => {
   api().root(EMPLOYER_API_ROOT).post(EMPLOYER_CONTRACT_UPDATE).data(formData).success(r => {
      // dispatch(getIndividualProjectList(r.result))
      const data = {
         hired_job_id: formData?._id
      }
      dispatch(getEmpHiredJobApi(data))
      successToast({ title: "Success", msg: r.message })
      handleCall()
   }).error((err) => {
      errorToast({ title: 'Failure', msg: err.message });

   }).send()
}

export const empInvoiceActionApi = (formData) => (dispatch) => {
   api().root(EMPLOYER_API_ROOT).post(EMPLOYER_INVOICE_ACTION).data(formData).success(r => {
      successToast({ title: "Success", msg: r.message })

   }).error((err) => {
      errorToast({ title: 'Failure', msg: err.message });

   }).send()
}
export const empInvoiceApproveApi = (formData, setLoading) => (dispatch) => {
   api().root(EMPLOYER_API_ROOT).post(EMPLOYER_INVOICE_ACTION).data(formData).success(r => {
      successToast({ title: "Success", msg: r.message })
      navigate(EMPLOYER_TRANSACTIONS)

   }).error((err) => {
      dispatch(setLoader(false))
      errorToast({ title: 'Failure', msg: err.message });

   }).send(() => {
      dispatch(setLoader(false))
      dispatch(setLoaderMessage(""))
   })
}
export const empReleaseDisputeApi = (formData) => (dispatch) => {
   api().root(EMPLOYER_API_ROOT).post(EMPLOYER_RELEASE_DISPUTE).data(formData).success(r => {
      successToast({ title: "Success", msg: r.message })
      navigate(EMPLOYER_TRANSACTIONS)
      dispatch(setLoader(false))
   }).error((err) => {
      dispatch(setLoader(false))
      console.log(err);

      errorToast({ title: 'Failure', msg: err.message });

   }).send()
}
export const empIndividualInvoiceApi = (formData, setLoading) => (dispatch) => {
   api().root(EMPLOYER_API_ROOT).post(EMPLOYER_INDIVIUAL_INVOICE).data(formData).success(r => {
      dispatch(getIndividualInvoiceList(r.result))
   }).error((err) => {
      errorToast({ title: 'Failure', msg: err.message });

   }).send(() => { setLoading(false) })
}

export const empInvoiceUpdateApi = (formData, callerFun) => (dispatch) => {

   const data = {
      invoice_id: formData?.payment_invoice_id
   }
   api().root(EMPLOYER_API_ROOT).post(EMPLOYER_INVOICE_UPDATE).data(formData).success(r => {
      successToast({ msg: "Success", title: r.message })


   }).error((err) => {
      errorToast({ title: 'Failure', msg: err.message });

   }).send(() => {
      callerFun()
      dispatch(setLoader(false))
      dispatch(setLoaderMessage(""));
   })
};


export const empInvoiceDisputeApi = (formData, callerFun) => (dispatch) => {
   const data = {
      invoice_id: formData?.invoice_id
   }
   api().root(EMPLOYER_API_ROOT).post(EMPLOYER_PAYMENT_DISPUTE_TICKET).data(formData).success(r => {
      successToast({ msg: "Success", title: r.message })

   }).error((err) => {
      errorToast({ title: 'Failure', msg: err.message });

   }).send(() => {
      callerFun()
      dispatch(setLoader(false))
      dispatch(setLoaderMessage(""))
   })
};

export const empConfirmJobOfferApi = (formData, setShow, setLoader, setCurrentPage, handleFun) => (dispatch) => {
   api().root(EMPLOYER_API_ROOT).post(EMPLOYER_CONFIRM_JOBOFFER).data(formData).success(r => {
      successToast({ title: 'Success', msg: r.message })
      setShow(false)
      setLoader(false)
      setCurrentPage(1)
      handleFun()
   }).error((err) => {
      errorToast({ title: 'Failure', msg: err.message });

   }).send(() => {
      setShow(false)
      setLoader(false)
      setCurrentPage(1)
      handleFun()
   })
};

export const fileUploadApi = (formData) => (dispatch) => {
   api()
      .root(EMPLOYER_API_ROOT)
      .post('/employer/file/upload')
      .data(formData)
      .success((res) => {

      })
      .error((e) => {
         const { message = '' } = e ?? {};
         devConsoleLog(e);
         errorToast({ title: 'Failed', msg: message });
      })
      .upload();
};

export const empAddBonus = (formData, setLoading) => (dispatch) => {

   api().root(EMPLOYER_API_ROOT).post(EMPLOYER_ADD_BONUS).data(formData).success(r => {
      successToast({ msg: "Success", title: r.message })
      setLoading(false);
      navigate(EMPLOYER_TRANSACTIONS)
   }).error((err) => {
      errorToast({ title: 'Failure', msg: err.message });
      setLoading(false);
   }).send(() => setLoading(false))
};
export const empAddPayment = (formData, setLoading,handleSendNotification) => (dispatch) => {

   api().root(EMPLOYER_API_ROOT).post(EMPLOYER_ADD_PAYMENT).data(formData).success(r => {
      successToast({ msg: "Success", title: r.message })
      setLoading(false);
      handleSendNotification()
      navigate(EMPLOYER_TRANSACTIONS)
      localStorage.removeItem('payments')
   }).error((err) => {
      errorToast({ title: 'Failure', msg: err.message });
      setLoading(false);
   }).send(() => setLoading(false))
};

export const empBonusTransactionList = (formData, setLoading) => (dispatch) => {

   api().root(EMPLOYER_API_ROOT).post(EMPLOYER_BONUS_TRANSACTION_LIST).data(formData).success(r => {
      dispatch(getBonusTransactionList(r?.result))
   }).error((err) => {
      // errorToast({title:'Failure', msg:err.message});

   }).send()
};

export const empTerminationApi = (formData, setLoading ,setFlagTwo ,handleCall) => (dispatch) => {

   api().root(EMPLOYER_API_ROOT).post(EMPLOYER_TERMINATE_API).data(formData).success(r => {
      const {result ,message} = r;
         successToast({ msg: "Success", title:message })  
         handleCall();
      

   }).error((err) => {
      errorToast({ title: 'Failure', msg: err.message });

   }).send(() => {
      setLoader(true)
      setLoaderMessage("")
      setLoading(false);
      setFlagTwo(false)

   })
};

export const addCardApi = (formData, setLoading, close, call) => (dispatch) => {
   api().root(EMPLOYER_API_ROOT).post(EMPLOYER_ADD_CARD).data(formData).success(r => {
      successToast({ title: 'Success', msg: "Card added successfully" })
      close()
      call()
      dispatch(setPaymentError(""))
   }).error((err) => {
      dispatch(setPaymentError(err.result))
   })
      .serverErr500((err) => { errorToast({ title: 'Failure', msg: err.message }) })
      .send(() => {
         setLoading(false);
         //  close()
      })
}
export const deleteCardApi = (formData, handleCall) => (dispatch) => {
   api().root(EMPLOYER_API_ROOT).post(EMPLOYER_DELETE_cARD_DETAILS).data(formData).success(r => {
      successToast({ title: 'Success', msg: "Card deleted successfully" })
      handleCall()
   }).error((err) => {
      errorToast({ title: 'Failure', msg: err.message });

   }).send(() => {

   })
}
export const getCardApi = (formData, setFlag) => (dispatch) => {
   api().root(EMPLOYER_API_ROOT).post(EMPLOYER_GET_CARD_DETAILS).data(formData).success(r => {
      dispatch(getCardData(r.result))

   }).error((err) => {
      // errorToast({ title: 'Failure', msg: err.message });

   }).send(() => {
      setFlag(false)
   })
}
export const changeDefaultApi = (formData, callerFun) => (dispatch) => {
   api().root(EMPLOYER_API_ROOT).post(EMPLOYER_CHANGE_DEFAULT_CARD).data(formData).success(r => {
      successToast({ title: 'Success', msg: r.message })
      callerFun()
   }).error((err) => {
      errorToast({ title: 'Failure', msg: err.message });

   }).send(() => {

   })
}



export const generateReceipt = (id,callback,loading) => (dispatch) => {
   const token = getLocal();
   axios({
      method: 'post',
      url: EMPLOYER_API_ROOT+EMPLOYER_RECEIPT,
      data: {
         id
      },
      responseType:'blob',
      headers:{
         'Content-Type': 'application/json',
         Accept: 'application/json',
         Authorization: token,
      }
   }).then((r)=>{
      callback(r.data)
   }).catch(e=>{
      loading(null)
      errorToast({ title: 'No Record Found', msg:'Please refresh and try again'});
   })
}
export const updateEmployerEmail = (formData,setButtonLoad,setShowOtpDiv) => (dispatch) => {
   api()
     .root(EMPLOYER_API_ROOT)
     .post(EMPLOYER_UPDATE_EMAIL)
     .data(formData)
     .success((res) => {
       setButtonLoad(false)
       setShowOtpDiv(true)
       successToast({ title: 'Success', msg: res.message });      
      
 
     })
     .error((e) => {
       const { message = '' } = e ?? {};
       devConsoleLog(e);
     })
     .send(() => {
       setButtonLoad(false)
      });
 };
 export const verifyEmployerEmail = (formData,handleCloseModal,handleCall) => (dispatch) => {
   api()
     .root(EMPLOYER_API_ROOT)
     .post(EMPLOYER_VERIFY_OTP)
     .data(formData)
     .success((res) => {
 
       successToast({ title: 'Success', msg: res.message });      
       handleCall()
       handleCloseModal()
       localStorage.setItem('user_email',res.result);
     
 
     })
     .error((e) => {
       const { message = '' , result = '' } = e ?? {};
       devConsoleLog(e);
       errorToast({ title: 'Error', msg: result });
     })
     .send(() => {
      
      });
 };
 export const empSubsribePlan = (formData,setLoading,history,successCallBack=null) => (dispatch) => {
   api()
     .root(EMPLOYER_API_ROOT)
     .post(EMPLOYER_SUBSCRIBE_PLAN)
     .data(formData)
     .success(({result}) => {
      const { url }    = result; 
      if(url){
         if(successCallBack){
            successCallBack(url)
         }else{
         window.open(url);
      }
      } 
     })
     .error((e) => {
       const { message = '' , result = '' } = e ?? {};
       devConsoleLog(e);
       errorToast({ title: 'Error', msg: result });
     })
     .send(() => {
      setLoading(false)
      });
 };
 export const empUpdateSubsribePlan = (formData,setLoading,successCallBack=null) => (dispatch) => {
   api()
     .root(EMPLOYER_API_ROOT)
     .post(EMPLOYER_SUBSCRIPTION_UPDATE)
     .data(formData)
     .success(({result}) => {
         setLoading(false);
      if(result){
         if(successCallBack){
            successCallBack(result.url)
         }else{
            window.open(result.url);
         }
      } 
     })
     .error((e) => {
       const { message = '' , result = '' } = e ?? {};
       devConsoleLog(e);
       errorToast({ title: 'Error', msg: result });
     })
     .send(() => {
      setLoading(false)
      });
 };

 export const fbConvertionApi = (formData) => (dispatch) => {
   api()
     .root(EMPLOYER_API_ROOT)
     .post(EMPLOYER_CONVERSION)
     .data(formData)
     .success(({result}) => {
        console.log(result)
     })
     .error((e) => {
       console.log(e)
     })
     .send();
 };
 export const empOrderSuccess= (formData=null,callback=null) => (dispatch) => {
   console.log(formData);
   api()
     .root(EMPLOYER_API_ROOT)
     .post(EMPLOYER_ORDER_SUCCESS)
     .data(formData)
     .success(({result}) => {
       callback(result)
    
 
     })
     .error((e) => {
       const { message = '' , result = '' } = e ?? {};
       devConsoleLog(e);
       errorToast({ title: 'Error', msg: result });
     })
     .send(() => {
      
      });
 };

 
 export const employerProtectedRequest= (formData=null,callback=null) => (dispatch) => {
   api()
     .root(EMPLOYER_API_ROOT)
     .post(EMPLOYER_SIGNIN_REQUEST)
     .data(formData)
     .success(({result}) => {
      successToast({
         title:'Success',
         msg:'Request send successfully'
      })
       console.log(result);
       callback?.(result)
     })
     .error((e) => {
       const { message = '' , result = '' } = e ?? {};
       devConsoleLog(e);
       errorToast({ title: 'Error', msg: result });
     })
     .send();
 };

 
 export const employerProtectPay= (formData=null,callback=null) => (dispatch) => {
   api()
     .root(EMPLOYER_API_ROOT)
     .post(EMPLOYER_PROTECTED_PAY)
     .data(formData)
     .success(({result}) => {
      successToast({
         title:'Success',
         msg:'Request initiated'
      })
       window.open(result.url,'_blank')
       console.log(result);
       callback?.(result)
     })
     .error((e) => {
       const { message = '' , result = '' } = e ?? {};
       devConsoleLog(e);
       errorToast({ title: 'Error', msg: result });
     })
     .send();
 };


 
 export const employerProtectionCancel= (formData=null,callback=null) => (dispatch) => {
   api()
     .root(EMPLOYER_API_ROOT)
     .post(EMPLOYER_PROTECTED_CANCEL)
     .data(formData)
     .success(({result}) => {
      successToast({
         title:'Success',
         msg:'Protection cancelled'
      })
       callback?.(result)
     })
     .error((e) => {
       const { message = '' , result = '' } = e ?? {};
       devConsoleLog(e);
       errorToast({ title: 'Error', msg: result });
     })
     .send();
 };
 export const employerContractDetails = (formData, callBack=null) => (dispatch) => {
   api()
     .root(EMPLOYER_API_ROOT)
     .post(EMPLOYER_CONTRACT_DETAILS)
     .data(formData)
     .success((res) => {
       callBack?.(res)
     })
     .error((e) => {
       const { message = '' } = e ?? {};
       devConsoleLog(e);
     })
     .send();
 };

 export const EmplistSignInRequest = (formData, callBack=null) => (dispatch) => {
   api()
     .root(EMPLOYER_API_ROOT)
     .post(EMPLOYER_SIGNIN_LIST)
     .data(formData)
     .success((res) => {
       callBack?.(res)
     })
     .error((e) => {
       const { message = '' } = e ?? {};
       devConsoleLog(e);
     })
     .send();
 };
 export const EmpGetTalents = (formData, callBack=null,setAiLoader=null) => (dispatch) => {
   api()
     .root(EMPLOYER_API_ROOT)
     .post(EMPLOYER_GET_TALENTS)
     .data(formData)
     .success((res) => {
       callBack?.(res?.result)
     })
     .error((e) => {
       const { message = '' } = e ?? {};
       devConsoleLog(e);
     })
     .send(() => {
      setAiLoader(false)
     });
 };
 export const EmpGetTransformText = (formData, callBack=null,setLoading) => (dispatch) => {
   api()
     .root(EMPLOYER_API_ROOT)
     .post(EMPLOYER_GET_TRANSFORMTEXT)
     .data(formData)
     .success((res) => {
       callBack?.(res?.result)
     })
     .error((e) => {
       const { message = '' } = e ?? {};
       devConsoleLog(e);
     })
     .send(() => {
      // setLoading(false)
     });
 };
 export const EmpUpdateAgreementDetails = (formData, callBack=null) => (dispatch) => {
   api()
     .root(EMPLOYER_API_ROOT)
     .post(EMPLOYER_UPDATE_AGREEMENT)
     .data(formData)
     .success((res) => {
       callBack?.(res)
     })
     .error((e) => {
       const { message = '' } = e ?? {};
       devConsoleLog(e);
     })
     .send();
 };
 export const EmpUserModalUpdate = (formData, callBack=null) => (dispatch) => {
   api()
     .root(EMPLOYER_API_ROOT)
     .post(EMPLOYER_USER_MODAL_ALERT)
     .data(formData)
     .success((res) => {
       callBack?.(false)
     })
     .error((e) => {
       const { message = '' } = e ?? {};
       devConsoleLog(e);
     })
     .send();
 };