import React, { useState, useEffect } from 'react';
import { FullSectionLoader } from '../common/loader';
import { Link } from 'react-router-dom';
import { EMPLOYER } from '../../config/constant/routePathConstant';
import { empHiredStaffApi, EmplistSignInRequest } from '../../api/employer/employer';
import { getUserData } from '../../helpers/projectHelper';
import { useDispatch } from 'react-redux';
import InfoIcon from "../../assets/images/i-icon.png";
export default function SignedContract() {
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState(false);

  const { user_id = '' } = getUserData();
  const [signInList, setSignInList] = useState([]);
  const dispatch = useDispatch();
  const handleCall = () => {
    setLoading(true);
    dispatch(
      EmplistSignInRequest({ user_id }, ({ result }) => {
        setSignInList(result);
        setLoading(false);
      }),
    );
  };
  useEffect(() => {
    handleCall();
  }, []);
  return (
    <div className="employer-posted-job-list-page">
      <section className="my-jobs-section">
        <h3 className="my-jobs-head mb-3">Signed Contracts</h3>
        <p className="employer_signed_para mb-5">
          Here’s a list of your staff who have signed <br></br>Philippines compliant contractor
          agreements
        </p>
        <div className="applicant-profile-education-table contract">
          <table className="tableresponsive signed_contract">
            <thead>
              <tr>
                  <th>Staff Name</th>
                  <th>Job Position</th>
                  
                  <th>Salary</th>
                  <th>Contract</th>
                 
                  <th 
                  ><div style={{ display: "flex", position: "relative",justifyContent:"center" }}>
                  <a   >Compliant Agreement</a>
                  <div
                          className="tooltips swing ml-1"
                          data-title="Utilize our tailor-made contracts, meticulously reviewed by legal professionals, to ensure full compliance with Philippine labor laws, providing you and your contractors with clarity and peace of mind."
                        >
                           <img className="i" src={InfoIcon} />
                        </div>
                 </div>
                 </th>
              </tr>
            </thead>
            {loading && <FullSectionLoader />}
            {!loading && (
              <tbody>
                {signInList &&
                  signInList?.map((a) => {
                    return (
                      <tr key={a._id} style={{ cursor: 'pointer' }}>
                        <td>{a?.user_id?.user_full_nam}</td>
                        <td>{a?.job_id?.job_name}</td>
                        <td>${a?.hired_job_id?.salary_amount}/hr</td>
                        <td style={{ color: '#004C70', textDecoration: 'underline' }}>
                          <Link target="_blank" to={`${EMPLOYER}/contract-agreement/${a._id}`}>
                            View
                          </Link>
                        </td>
                        <td
                          style={{
                            color: a.protected_status == 'protected' ? '#49B247' : '#A0A0A0',
                          }}
                        >
                          {a.protected_status == 'protected'
                            ? 'Signed'
                            : a.protected_status === 'waiting_for_pay'
                            ? 'waiting for employer'
                            : 'Pending'}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            )}
          </table>
        </div>
      </section>
    </div>
  );
}
