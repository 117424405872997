import { useCallback, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { socketSelector } from '../redux/slicers/socketSlicer.js';
import { getLocal, getUserData } from './projectHelper.js';
import { SocketContext } from './context.js';

export const useSocket = () => {
   const { typing = null, receivedMsg, receivedChat, connection,notification={},lastMessageId } = useSelector(socketSelector);
   const socketEvents = useContext(SocketContext);
   return Object.assign({}, socketEvents, { typing, receivedMsg, receivedChat,lastMessageId, connection,notification });
};



export const useSession = () => {
   return { ...getUserData(), token: getLocal() };
};

export const useChatMessageList = (chat_id) => {
   const { chatData = {} } = useSelector(socketSelector);
   const { user_id } = useSession();
   return { data: chatData?.[chat_id] ?? [], user_id };
};

export const useDebouncedEffect = (effect, delay , deps) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const callback = useCallback(effect, deps);

    useEffect(() => {
        const handler = setTimeout(() => {
            callback();
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [callback, delay]);
}