import React, { useEffect } from 'react';
import { RouterBuilder } from '../common/router';
import { LazyLoader } from '../common/loader.js';
import Header from './common/header';
import SideBar from './common/sideBar/index';
import '../../assets/css/admin.scss';
import '../../assets/css/login.scss';
import { useRouteMatch } from 'react-router-dom';
import {  ADMIN_LOGIN, SUPER_ADMIN } from '../../config/constant/routePathConstant';
import { OtherSessionsChecker } from '../common/container/sessionChecker';
import SUPER_ADMIN_ROUTES from '../../config/routes/adminRoutes';
import { disableLiveChat, enableLiveChat } from '../common/basic';

const SuperAdminContainer = () => {
  const isLogin = useRouteMatch(ADMIN_LOGIN);
  useEffect(() => {
    disableLiveChat()
    return ()=>{
      enableLiveChat()
    }
  }, []);
  return (
    <div className='v-staff-admin-container'>
      <OtherSessionsChecker sessionFailPath={ADMIN_LOGIN} currentRoute={SUPER_ADMIN} >{""} </OtherSessionsChecker>
      {!isLogin && <><Header /><SideBar /></>}
      <LazyLoader>
        <RouterBuilder data={SUPER_ADMIN_ROUTES} />
      </LazyLoader>
    </div>
  );
};

export default SuperAdminContainer;
