import {
  ADD_BOARD,
  ADD_CARD,
  ADD_CARD_MEMBER,
  ADD_CHECKLIST_ITEM,
  ADD_LIST,
  ADD_MEMBER,
  ARCHIVE_CARD,
  ARCHIVE_LIST,
  BOARD_ERROR,
  BOARD_STATUS,
  BOARD_STATUS_ERROR,
  CARD_ID,
  CLEAR_BOARD,
  COMPLETE_CHECKLIST_ITEM,
  DELETE_CARD,
  DELETE_CHECKLIST_ITEM,
  EDIT_CARD,
  EDIT_CHECKLIST_ITEM,
  ERROR_BAORDS,
  GET_ACTIVITY,
  GET_BOARD,
  GET_BOARDS,
  GET_CARD,
  GET_LIST,
  HIDE_LOADER,
  MOVE_CARD,
  MOVE_LIST,
  POST_COMMENT,
  POST_COMMENT_ERROR,
  RENAME_BOARD,
  RENAME_LIST,
  SHOW_LOADER,
  USER_ID,
} from '../actions/types';

const initialState = {
  boards: [],
  board: null,
  dashboardLoading: true,
  error: {},
  comments: [],
  commentsError: null,
  emails: [],
  boardstatus: null,
  boardError: null,
  loading: false,
  user_id: null,
  cardsids: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CLEAR_BOARD:
      return {
        ...state,
        board: null,
      };
    case GET_BOARDS:
      return {
        ...state,
        boards: payload,
        dashboardLoading: false,
      };
    case ERROR_BAORDS:
      return {
        ...state,
        dashboardLoading: false,
      };
    case RENAME_BOARD:
    case GET_BOARD:
      return {
        ...state,
        board: { ...state.board, ...payload },
      };
    case ADD_BOARD:
      return {
        ...state,
        boards: [payload, ...state.boards],
      };
    case BOARD_ERROR:
      return {
        ...state,
        error: payload,
      };
    case GET_LIST:
      let ListObjectsArray = [...state.board.listObjects, payload];
      return {
        ...state,
        board: {
          ...state.board,
          // listObjects: [...state.board.listObjects, payload],
          listObjects: ListObjectsArray,
        },
      };
    case ADD_LIST:
      let listArray = [...state.board.lists, payload._id];
      return {
        ...state,
        board: {
          ...state.board,
          // lists: [...state.board.lists, payload._id],
          lists: listArray,
        },
      };
    case ARCHIVE_LIST:
    case RENAME_LIST:
      return {
        ...state,
        board: {
          ...state.board,
          listObjects: state.board.listObjects.map((list) =>
            list._id === payload._id ? payload : list,
          ),
        },
      };
    case GET_CARD:
      console.log('GET_BOARD', payload);
      let cardObjectsArray = [...state.board.cardObjects, payload];

      return {
        ...state,
        board: {
          ...state.board,
          // cardObjects: [...state.board.cardObjects, payload],
          cardObjects: cardObjectsArray,
        },
      };
    case ADD_CARD:
      return {
        ...state,
        board: {
          ...state.board,
          listObjects: state.board.listObjects.map((list) =>
            list._id === payload.listId
              ? { ...list, cards: [...list.cards, payload.cardId] }
              : list,
          ),
        },
      };
    case ADD_CHECKLIST_ITEM:
    case EDIT_CHECKLIST_ITEM:
    case COMPLETE_CHECKLIST_ITEM:
    case DELETE_CHECKLIST_ITEM:
    case ARCHIVE_CARD:
    case ADD_CARD_MEMBER:
    case EDIT_CARD:
      return {
        ...state,
        board: {
          ...state.board,
          cardObjects: state.board.cardObjects.map((card) =>
            card._id === payload._id ? payload : card,
          ),
        },
      };
    case MOVE_CARD:
      return {
        ...state,
        board: {
          ...state.board,
          listObjects: state.board.listObjects.map((list) =>
            list._id === payload.from._id
              ? payload.from
              : list._id === payload.to._id
              ? payload.to
              : list,
          ),
          cardObjects: state.board.cardObjects.filter(
            (card) => card._id !== payload.cardId || payload.to._id === payload.from._id,
          ),
        },
      };
    case DELETE_CARD:
      return {
        ...state,
        board: {
          ...state.board,
          cardObjects: state.board.cardObjects.filter((card) => card._id !== payload),
          listObjects: state.board.listObjects.map((list) =>
            list.cards.includes(payload)
              ? {
                  ...list,
                  cards: list.cards.filter((card) => card !== payload),
                }
              : list,
          ),
        },
      };
    case GET_ACTIVITY:
      return {
        ...state,
        board: {
          ...state.board,
          activity: payload,
        },
      };
    case ADD_MEMBER:
      return {
        ...state,
        board: {
          ...state.board,
          members: payload.members,
          emails: payload.emails,
        },
      };
    case MOVE_LIST:
      return {
        ...state,
        board: {
          ...state.board,
          lists: payload,
        },
      };
    case POST_COMMENT:
      return {
        ...state,
        comments: payload,
      };
    case POST_COMMENT_ERROR:
      return {
        ...state,
        commentsError: payload,
      };
    case BOARD_STATUS_ERROR:
      return {
        ...state,
        boardError: payload,
      };
    case BOARD_STATUS:
      return {
        ...state,
        boardstatus: payload,
      };
    case SHOW_LOADER:
      return {
        ...state,
        loading: true,
      };
    case HIDE_LOADER:
      return {
        ...state,
        loading: false,
        dashboardLoading: false,
      };
    case USER_ID:
      return {
        ...state,
        user_id: payload,
      };
    case CARD_ID:
      return {
        ...state,
        cardsids: payload,
      };
    default:
      return state;
  }
}
