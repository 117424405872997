import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ChatCenter, ChatEmployerInitialMsg, ChatLeftMsg, ChatRightMsg } from '../chatMessage.js';
import { useChatMessageList, useSession } from '../../../helpers/hooks.js';
import { useDispatch, useSelector } from 'react-redux';
import { currentChatSelector, setLastMessageId } from '../../../redux/slicers/socketSlicer.js';
import ChatHeader from './chatHeader.js';
import ChatFooter from './chatFooter.js';
import { chatViewApi, blockChatApi } from '../../../api/chat/chatApi';
import { useSocket } from '../../../helpers/hooks.js';
import { Button } from 'react-bootstrap';
import { EMPLOYER_INTRO_MSG } from '../../../config/constant/projectConstant.js';
import moment from 'moment/moment.js';

const ChatContent = ({
  videoCall = true,
  msgLarger = false,
  updateChatLimit,
  preventApiRenders,
}) => {
  const ref = useRef();
  const [scrolledVertical, setScrolledVertical] = useState(false);
  const [scrollBottom, setScrollBottom] = useState(false);
  const [introMsg, setIntroMsg] = useState(true);
  const { user_id, user_type = '' } = useSession();
  const dispatch = useDispatch();
  const { chat_id: nav_chat_id } = useParams();
  const {
    chat_id = null,
    temp_chat_id = null,
    to_details: { blocked = [] },
  } = useSelector(currentChatSelector);
  const isBlockedByMine = blocked.indexOf(user_id) > -1;
  const chatBlocked = blocked.length > 0;
  let final_chat_id = chat_id || temp_chat_id;
  final_chat_id = final_chat_id ?? nav_chat_id;
  const { data } = useChatMessageList(final_chat_id);
  const { sendNotification, receivedMsg, lastMessageId = null } = useSocket();
  const [to_id, setToId] = useState(data[0]);
  const [scrollEnable, setScrollEnable] = useState(false);

  const handleBlocked = () => {
    if (!isBlockedByMine) {
      if (!window.confirm('Are you sure want block this chat?')) {
        return;
      }
    }
    dispatch(
      blockChatApi(chat_id, !isBlockedByMine, () => {
        sendNotification({ type: 'block', chat_id }, to_id._id);
      }),
    );
  };

  useEffect(() => {
    if (ref?.current) {
      setTimeout(() => {
        if (ref.current?.scrollTop !== undefined && !scrolledVertical) {
          ref.current.scrollTop = ref.current?.scrollHeight ?? 0;
          setScrollEnable(true);
        }
      }, 500);
    }
  }, [data, nav_chat_id, chat_id]);

  useEffect(() => {
    if (lastMessageId && ref.current?.scrollTop !== undefined) {
      ref.current.scrollTop =
        document.getElementById(lastMessageId).offsetTop - ref.current.offsetTop;
      dispatch(setLastMessageId(null));
    }
  }, [data]);

  useEffect(() => {
    return () => {
      setScrolledVertical(false);
      setScrollEnable(false);
    };
  }, []);

  useEffect(() => {
    setScrollBottom(true);
    dispatch(chatViewApi(chat_id));
  }, [chat_id,nav_chat_id]);

  useEffect(() => {
    if (scrollBottom) {
      setScrollBottom(false);
      ref.current.scrollTop = ref.current?.scrollHeight;
    }
  }, [scrollBottom]);

  useEffect(() => {
    ref.current.scrollTop = ref.current?.scrollHeight;
    dispatch(chatViewApi(chat_id));
  }, [receivedMsg]);

  const onScroll = async () => {
    if (!scrollEnable) {
      return;
    }
    const scrollTop = ref.current?.scrollTop;
    if (scrollTop == 0 && !preventApiRenders) {
      setScrolledVertical(true);
      const lastMsg = data[0];
      dispatch(setLastMessageId(lastMsg?._id));
      await updateChatLimit();
    }
  };

  let preDate = null;
  const messageMemo = useMemo(
    () =>
      data &&
      data.map((a, i) => {
        const { from_id, _id } = a;
        const check = moment(a.msg_time).format('DD-MM-YYYY') === moment(preDate).format('DD-MM-YYYY')
        if (!check) {
          preDate = a.msg_time;
        }
        return (
          <Fragment key={_id}>
            {!check && ChatCenter(a, data?.[i - 1])}
            {from_id !== user_id && <ChatLeftMsg {...a} />}
            {from_id === user_id && <ChatRightMsg {...a} />}
          </Fragment>
        );
      }),
    [data],
  );

  const handleIntroClose = () => {
    setIntroMsg(false);
  };

  return (
    <div className="messagecard2 patient-details-left-card">
      <ChatHeader videoCall={videoCall} />
      <div
        ref={ref}
        onScroll={onScroll}
        className={`messagerowsection ${msgLarger ? 'message-large-width' : ''} ${
          user_type === 'employer' && chat_id === null && introMsg && 'justify-content-end'
        }`}
      >
        {messageMemo}
        {user_type === 'employer' && chat_id === null && introMsg && (
          <ChatEmployerInitialMsg onClose={handleIntroClose} content={EMPLOYER_INTRO_MSG} />
        )}
      </div>
      {chatBlocked ? (
        <>
          <div className="flex pt-2 bg-light-gray flex-column justify-content-center align-items-center">
            {isBlockedByMine && (
              <div className="d-flex w-100 justify-content-around">
                <div className="text-center">
                  <p style={{ marginBottom: '8px', color: '#004c70' }}>
                    You've blocked messages and calls from this account
                  </p>
                  <p style={{ marginBottom: '8px', color: '#004c70' }}>
                    You can't message or call them in this chat, and you won't receive their message
                    or calls.
                  </p>
                </div>
                <div>
                  <Button
                    style={{ marginBottom: '8px' }}
                    size={'sm'}
                    className="pull-right mt-2"
                    onClick={handleBlocked}
                  >
                    Unblock
                  </Button>
                </div>
              </div>
            )}
            {!isBlockedByMine && <h5 className={'text-center'}>This conversion has blocked</h5>}
          </div>
        </>
      ) : (
        <ChatFooter setScrollBottom={setScrollBottom} />
      )}
    </div>
  );
};

export default ChatContent;
