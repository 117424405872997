import {
  LOGIN_API_ROOT,
  ADMIN_API_ROOT,
  JOB_SEEKER_API_ROOT,
  EMPLOYER_API_ROOT,
  AUTH_TICKET_CREATE_API,
  AUTH_TICKET_EMPLOYER_GET_API,
  AUTH_TICKET_JOB_SEEKER_GET_API,
  AUTH_TICKET_DELETE_API,
  ADMIN_TICKET_FILTER_API,
  ADMIN_CUST_SUP_LIST_API,
  ADMIN_TICKET_ASSIGN_API,
  AUTH_TICKET_UPDATE_API,
  AUTH_TICKET_COMMENT_API
} from '../../config/constant/apiConstant';
import api from '../../helpers/axiosHelper';
import { getUserData, successToast } from '../../helpers/projectHelper';
import {
  authTicketCreateAct,
  authTicketFilterGetAct,
  authTicketDeleteAct,
  adminTicketFilterAct,
  adminCusSupListAct,
  adminTicketAssignAct,
  authTicketUpdateAct,
  adminTicketCommentAct,
} from '../../redux/slicers/ticketingSystemSlice';

const userData = localStorage.getItem('userData');
const userInfo = JSON.parse(userData);
const user_type = userInfo?.user_type;

export const authTicketCreateApi = (formData, setLoading, getTicketList,setOpenModal) => (dispatch) => {
  // const formData={}
  api()
    .root(LOGIN_API_ROOT)
    .post(AUTH_TICKET_CREATE_API)
    .data(formData)
    .success(({ result = null }) => {
      getTicketList()
      successToast({ title: 'Success', msg: result.message });
      setLoading(false);
    })
    .send();
};

export const authTicketJobSeekerGetApi = (formData, setLoading, setOpenModal) => (dispatch) => {
  api()
    .root(JOB_SEEKER_API_ROOT)
    .post(AUTH_TICKET_JOB_SEEKER_GET_API)
    .data(formData)
    .success(({ result = null }) => {
      dispatch(authTicketFilterGetAct(result));
      setLoading(false);
    })
    .send();
};

export const authTicketEmployerGetApi = (formData, setLoading, setOpenModal) => (dispatch) => {
  api()
    .root(EMPLOYER_API_ROOT)
    .post(AUTH_TICKET_EMPLOYER_GET_API)
    .data(formData)
    .success(({ result = null }) => {
      dispatch(authTicketFilterGetAct(result));
      setLoading(false);
    })
    .send();
};

export const authTicketDeleteApi = (formData, setLoading, getTicketList, setOpenModal) => (dispatch) => {
  api()
    .root(LOGIN_API_ROOT)
    .post(AUTH_TICKET_DELETE_API)
    .data(formData)
    .success(({ result = null }) => {
      getTicketList()
      successToast({ title: 'Success', msg: result.message });
      setLoading(false);
    })
    .send();
};

export const authTicketUpdateApi = (formData, setLoading, getTicketList, setOpenModal) => (dispatch) => {
  const data = {
    query: {},
    limit: 8,
    skip: 0,
  };
  api()
    .root(LOGIN_API_ROOT)
    .post(AUTH_TICKET_UPDATE_API)
    .data(formData)
    .success(({ result = null }) => {
      getTicketList()
      successToast({ title: 'Success', msg: result.message });
      setLoading(false);
    })
    .send();
};

export const authTicketCommentApi = (formData, setLoading, getTicketList) => (dispatch) => {
  console.log(formData);
  api()
    .root(LOGIN_API_ROOT)
    .post(AUTH_TICKET_COMMENT_API)
    .data(formData)
    .success(({ result = null }) => {
      getTicketList()
      setLoading(false);
    })
    .send();
};

export const adminTicketFilterApi = (formData, setLoading, setOpenModal) => (dispatch) => {
  api()
    .root(ADMIN_API_ROOT)
    .post(ADMIN_TICKET_FILTER_API)
    .data(formData)
    .success(({ result = 0 }) => {
      dispatch(adminTicketFilterAct(result));
      setLoading(false);
    })
    .send();
};

export const adminCusSupListApi = (formData, setLoading, setOpenModal) => (dispatch) => {
  api()
    .root(ADMIN_API_ROOT)
    .post(ADMIN_CUST_SUP_LIST_API)
    .data(formData)
    .success(({ result = 0 }) => {
      dispatch(adminCusSupListAct(result));
      setLoading(false);
    })
    .send();
};

export const adminTicketAssignApi = (formData, setLoading,getTicketList, setOpenModal) => (dispatch) => {
  api()
    .root(ADMIN_API_ROOT)
    .post(ADMIN_TICKET_ASSIGN_API)
    .data(formData)
    .success(({ result = null }) => {
      getTicketList()
      successToast({
        title: 'Success',
        msg: result.message,
      });
      setLoading(false);
    })
    .send();
};




