import { createSlice } from '@reduxjs/toolkit';
import { objectFind, objectFindUpdate } from '../../helpers/jsHelper.js';
import moment from 'moment';

const socketSlice = createSlice({
  name: 'socket',
  initialState: {
    attachLoading: {},
    callingData: {},
    chatProfile: false,
    connection: false,
    notification: {},
    attachFileId: {},
    chatList: [],
    userList: {},
    receivedMsg: {},
    receivedChat: {},
    currentChat: {},
    lastMessageId: null,
    activityLoading: false,
    sidebarLoading: false,
    chatData: {},
    typing: {
      chat_id: '',
      from_id: '',
    },
    unreadCount: '',
    calling: false,
    appNotification: {
      count: '',
      json: []
    },
    preventApiRenders: false,
    hired: false
  },
  reducers: {
    socketConnection: (state, { payload }) => {
      state.connection = payload;
    },
    socketNewMessage: (state, { payload }) => {
      state.receivedMsg = payload;
    },
    setCurrentChat: (state, { payload }) => {
      state.currentChat = payload;
    },
    setChatListInitial: (state, { payload }) => {
      state.chatList = payload;
    },
    setChatListAfter: (state, { payload }) => {
      state.chatList = [...state.chatList, ...payload];
    },
    socketNewChat: (state, { payload }) => {
      state.receivedChat = payload;
    },
    socketTyping: (state, { payload }) => {
      state.typing = payload;
    },
    socketCalling: (state, { payload }) => {
      state.calling = payload;
    },
    chatMessageLoad: (state, { payload }) => {
      const { chat_id, data } = payload;
      if (chat_id) {
        state.chatData[chat_id] = state.chatData[chat_id] || [];
        state.chatData[chat_id] = [...data, ...state.chatData[chat_id]]
        state.chatList = objectFindUpdate(state.chatList, '_id', chat_id);
        state.preventApiRenders = data?.length > 0 ? false : true;
      }
    },
    chatListUpdate: (state, { payload }) => {
      const { chat_id, data } = payload;
      state.chatList = objectFindUpdate([...state.chatList], '_id', chat_id, data);
    },
    chatListAdd: (state, { payload }) => {
      state.chatList = [payload, ...state.chatList];
    },
    chatMessageInitialLoad: (state, { payload }) => {
      const { chat_id, data } = payload;
      if(chat_id){
        state.chatData[chat_id] = data
      }
    },
    removeChatMsg: (state, { payload }) => {
      if (payload) {
        state.chatData[payload] = [];
        state.chatList = objectFindUpdate(state.chatList, '_id', payload, { recent_msg: '' });
      }
    },
    clearLastMsg: (state) => {
      state.receivedMsg = {};
    },
    removeChat: (state, { payload }) => {
      if (payload) {
        state.chatList = state.chatList.filter(a => a._id.toString() !== payload);
      }
    },
    chatMessageStore: (state, { payload }) => {
      const { chat_id, content = '' } = payload;
      if(chat_id){
        state.chatData[chat_id] = state.chatData[chat_id] || [];
        state.chatData[chat_id].push(payload);
        const preData = objectFind(state.chatList, '_id', chat_id);
        const { msg_count = 0 } = preData;
        const newCount = state.currentChat.chat_id === chat_id ? 0 : msg_count + 1;
        state.chatList = objectFindUpdate(state.chatList, '_id', chat_id, { recent_msg: content,last_msg_time:moment().toISOString(), msg_count: newCount });
        state.receivedMsg = null;
      }
    },
    chatProfileToggle: (state) => {
      state.chatProfile = !state.chatProfile;
    },
    setCallingData: (state, { payload }) => {
      state.callingData = payload;
    },
    setUserStatus: (state, { payload }) => {
      const { user_id = null, status = false } = payload;
      if (user_id) {
        state.userList[user_id] = status;
      }
    },
    setSocketNotification: (state, { payload }) => {
      state.notification = payload;
    },
    setActivityLoading: (state, { payload }) => {
      state.activityLoading = payload;
    },
    setUnreadCount: (state, { payload }) => {
      state.unreadCount = payload;
    },
    setAttachLoading: (state, { payload }) => {
      const { _id = '', status = false } = payload;
      if (_id) {
        state.attachLoading[_id] = status;
      }
    },
    removeCount: (state, { payload }) => {
      state.chatList = objectFindUpdate([...state.chatList], '_id', payload, { msg_count: 0 });
    },
    setAttachFileId: (state, { payload }) => {
      const { _id = '', fileId = null } = payload;
      if (_id && fileId) {
        state.attachFileId[_id] = fileId;
      }
    },
    getNotificationData: (state, { payload }) => {
      state.appNotification['count'] = payload?.result?.length;
      state.appNotification['json'] = payload;
    },
    setHiredStaff: (state, { payload }) => {
      state.hired = payload
    },
    setLastMessageId: (state, { payload }) => {
      state.lastMessageId = payload
    },
    setSidebarLoading: (state, { payload }) => {
      state.sidebarLoading = payload
    }
  },
});

export const {
  setHiredStaff,
  chatProfileToggle,
  socketConnection,
  setChatListInitial,
  setChatListAfter,
  socketCalling,
  setCurrentChat,
  socketTyping,
  socketRinging,
  chatMessageStore,
  socketNewChat,
  socketNewMessage,
  chatMessageLoad,
  clearLastMsg,
  chatMessageInitialLoad,
  setUserStatus,
  setCallingData,
  setSocketNotification,
  setActivityLoading,
  chatListUpdate,
  removeChatMsg,
  removeChat,
  chatListAdd,
  setAttachLoading,
  setAttachFileId,
  removeCount,
  setUnreadCount,
  getNotificationData,
  setLastMessageId,
  setSidebarLoading
} = socketSlice.actions;

export const socketSelector = state => state.socket;
export const unreadCountSelector = state => state.socket.unreadCount;
export const currentChatSelector = state => state.socket.currentChat;
export const attachSelector = state => state.socket.attachLoading;
const socketReducer = socketSlice.reducer;
export default socketReducer;
