import {
   ADMIN_ACTIVITY_LOG_LIST_API,
   ADMIN_ADD_CATEGORY_API,
   ADMIN_ADD_JOB_TITLE_API,
   ADMIN_ADD_SKILL_API,
   ADMIN_ADD_STAFF_API,
   ADMIN_API_ROOT,
   ADMIN_BONUS_FILTER_LIST_API,
   ADMIN_CATEGORY_API,
   ADMIN_CHAT_FILTER,
   ADMIN_CHAT_LIST_BY_EMAIL,
   ADMIN_DASHBOARD_EMPLOER_LIST_API,
   ADMIN_DASHBOARD_GET_EMPLOYER_DETAIL,
   ADMIN_DASHBOARD_LIST_API,
   ADMIN_DELETE_CATEGORY_API,
   ADMIN_DELETE_JOB_TITLE_API,
   ADMIN_DELETE_SKILL_API,
   ADMIN_DELETE_STAFF_API,
   ADMIN_EMPLOYER_BLOCK_API,
   ADMIN_EMPLOYER_PASSWORD_API,
   ADMIN_EMPLOYER_UNBLOCK_API,
   ADMIN_EMPLOYER_VERIFY,
   ADMIN_EMPLOYER_VERIFY_LIST,
   ADMIN_EMPOLYER_ACCEPTED_LIST,
   ADMIN_EMPOLYER_REJECTED_LIST_API,
   ADMIN_GET_JOB_INFO,
   ADMIN_GET_JOB_SEEKER_DETAIL_API,
   ADMIN_JOB_DELETE_API,
   ADMIN_JOB_LIST_API,
   ADMIN_JOB_SEEKER_ACCEPTED_LIST_API,
   ADMIN_JOB_SEEKER_LIST_API,
   ADMIN_JOB_SEEKER_PASSWORD_API,
   ADMIN_JOB_SEEKER_REJECTED_LIST_API,
   ADMIN_JOB_SEEKER_VERIFY,
   ADMIN_JOB_SEEKER_VERIFY_API,
   ADMIN_JOB_SEEKER_VERIFY_LIST_API,
   ADMIN_JOB_TITLE_API,
   ADMIN_LOGIN_API,
   ADMIN_NOTIFICATION_LIST_API,
   ADMIN_NOTIFICATION_UPDATE_API,
   ADMIN_PAYMENT_HISTORY_FILTER_LIST_API,
   ADMIN_RELEASE_DISPUTE,
   ADMIN_SKILL_API,
   ADMIN_STAFF_ACTIVATION_API,
   ADMIN_STAFF_LIST_API,
   ADMIN_UPDATE_CATEGORY_API,
   ADMIN_UPDATE_JOB_TITLE_API,
   ADMIN_UPDATE_SKILL_API,
   ADMIN_UPDATE_STAFF_API,
   TASK_MANAGEMENT_API,
   TASK_MANAGEMENT_ROOT_API,
   ADMIN_UPDATE_JOB_INFO,
   ADMIN_UPDATE_EMPLOYER_INFO,
   ADMIN_UPDATE_JOBSEEKER_INFO,
   ADMIN_DELETE_EMPLOYER_INFO,
   ADMIN_DELETE_JOBSEEKER_INFO,
   ADMIN_UNVERIFIED_JOB_LIST_API,
   ADMIN_VERIFY_JOB_API,
   ADMIN_DELETE_EMPLOYER_LIST_API,
   ADMIN_DELETE_JOBSEEKER_LIST_API,
   ADMIN_DASHBOARD_GET_DELETE_EMPLOYER_DETAIL,
   ADMIN_DASHBOARD_GET_DELETE_JOB_SEEKER_DETAIL,
   ADMIN_ADD_ANNOUNCEMENT,
   ADMIN_GET_ANNOUNCEMENT,
   ADMIN_UPDATE_ANNOUNCEMENT,
   ADMIN_EMPLOYER_PASSWORD_API_LINK,
   ADMIN_JOBSEEKER_PASSWORD_API_LINK,
   ADMIN_JOBSEEKER_VERIFY,
   ADMIN_DELETE_ANNOUNCEMENT,
   ADMIN_GET_ANNOUNCEMENT_INFO,
   ADMIN_GET_ACCOUNT_DETAILS,
   ADMIN_UPDATE_ACCOUNT_DETAILS,
   ADMIN_GET_FREEUSERS_LIST,
   ADMIN_UPDATE_EXPIRYDATE,
   ADMIN_GET_SUBSCRIBERS_LIST,
   ADMIN_UPDATE_BASIC_PLAN,
   ADMIN_SUBSCRIPTION_CANCEL,
   EMPLOYER_API_ROOT,
   ADMIN_DASHBOARD_GET_EMPLOYER_LOG,
   ADMIN_DASHBOARD_CHAT_TOTAL,
   ADMIN_GET_REFERRAL,
   ADMIN_GET_AFFILIATE,
   ADMIN_PROTECTION_LIST,
   ADMIN_INDIVIDUAL_PROTECTION_LIST,
   ADMIN_CONTRACT_DETAILS,
   ADMIN_USERS_CONFIG_LIST,
   ADMIN_AGREED_USERS_LIST,
   ADMIN_DELETE_USERS_LIST,
   ADMIN_DELETE_USER,
   ADMIN_GET_REPORTED_JOB,
   ADMIN_UPDATE_REPORTED_JOB, ADMIN_DELETE_JOBSEEKER_STRIPE,
   ADMIN_INDIVIDUAL_JOB_LIST,
   ADMIN_INDIVIDUAL_OFFER_LIST,
   ADMIN_INDIVIDUAL_HIRED_LIST,
   ADMIN_INDIVIDUAL_STAFF_OFFER_LIST,
   ADMIN_INDIVIDUAL_STAFF_HIRED_LIST,
   ADMIN_INDIVIDUAL_PAYMENT_LIST,
   ADMIN_USER_AGREEMENT_LIST,
   ADMIN_USER_AGREEMENT,
} from '../../config/constant/apiConstant';
import { ROLE_NAV, USER_DATA } from '../../config/constant/projectConstant';
import {
   SUPER_ADMIN_JOB_TITLE,
   ADMIN_LOGIN,
   SUPER_ADMIN_MANAGE_STAFF,
   SUPER_ADMIN_CATEGORY,
   SUPER_ADMIN_SKILL,
} from '../../config/constant/routePathConstant';
import api from '../../helpers/axiosHelper';
import {
   devConsoleLog,
   errorToast,
   navigate,
   storeLocal,
   successToast,
} from '../../helpers/projectHelper';
import {
   activiyListAct,
   adminJobInfoAct,
   adminBonusListAct,
   adminCategoryAct,
   adminEmployerAcceptedListAct,
   adminEmployerRejectedListAct,
   adminEmpVerifyAct,
   adminJobSeekerAcceptedListAct,
   adminJobSeekerAct,
   adminJobSeekerDataAct,
   adminJobSeekerRejectedListAct,
   adminJobSeekerSingleDataAct,
   adminJobSeekerVerifyAct,
   adminJobTitleAct,
   adminPaymentHistoryAct,
   adminSkillAct,
   adminStaffListAct,
   chatListAct,
   chatListEmpAct,
   chatListJobAct,
   dashboardDataAct,
   dashboardEmpListAct,
   employerDetailAct,
   jobListAct,
   notificationAct,
   jobUnverifiedListAct,
   deleteEmployerListAct,
   deleteJobseekerListAct,
   deleteEmployerDetailAct,
   deleteJobseekerDetailAct,
   setAnnouncement,
   jobSeekerVerifyListAct,
   eventInfoAct,
   setProfileDetails,
   setProfileDetailsLog,
   setChatTotalLog,
   setReferral,
   setAffiliate
} from '../../redux/slicers/adminSlice/adminDashboardSlice';
import { activationLoading, sessionFail, sessionSuccess } from '../../redux/slicers/sessionSlice';

export const adminEmployerProfVeriListApi = (formData, setLoading) => (dispatch) => {
   api().root(ADMIN_API_ROOT).post(ADMIN_EMPLOYER_VERIFY_LIST).data(formData).success(({ result = 0 }) => {
      dispatch(adminEmpVerifyAct(result))
      setLoading(false)
   }).error((e) => {
      const { message: msg = '' } = e;
      setLoading(false);
      errorToast({ title: 'Fetch Failed', msg })
   })
      .send(() => { setLoading(false) });
};
export const adminEmployerProfVerify = (formData, getData = null, setLoading, setOpenModal, setLoadingBtn) => (dispatch) => {
   api().root(ADMIN_API_ROOT).post(ADMIN_EMPLOYER_VERIFY).data(formData).success(({ result = 0 }) => {
      successToast({ title: "Success", message: "Successfully Submitted" })
      setLoading(false)
      getData()
      setOpenModal(false)
      setLoadingBtn(false)
   })
      .error((e) => {
         const { message: msg = '' } = e;
         setLoading(false);
         errorToast({ title: 'Fetch Failed', msg })
      })
      .send(() => {
         setLoading(false)
      });
};
export const adminJobseekerProfVerify = (formData, getData = null, setLoading, setOpenModal, setLoadingBtn) => (dispatch) => {
   api().root(ADMIN_API_ROOT).post(ADMIN_JOBSEEKER_VERIFY).data(formData).success(({ result = 0 }) => {
      successToast({ title: "Success", message: "Successfully Submitted" })
      setLoading(false)
      getData()
      setOpenModal(false)
      setLoadingBtn(false)
   })
      .error((e) => {
         const { message: msg = '' } = e;
         setLoading(false);
         errorToast({ title: 'Fetch Failed', msg })
      })
      .send(() => {
         setLoading(false)
      });
};
export const dashboardListApi = (formData, setLoading) => (dispatch) => {
   api().root(ADMIN_API_ROOT).post(ADMIN_DASHBOARD_LIST_API).data(formData).success(({ result = 0 }) => {
      dispatch(dashboardDataAct(result))
      setLoading(false)
   })
      .error((e) => {
         const { message: msg = '' } = e;
         setLoading(false);
         errorToast({ title: 'Fetch Failed', msg })
      })
      .send(() => {
         setLoading(false)

      });
};
export const dashboardEmpListApi = (formData, setLoading, setPage, page) => (dispatch) => {
   api().root(ADMIN_API_ROOT).post(ADMIN_DASHBOARD_EMPLOER_LIST_API).data(formData)
      .success(({ result = 0 }) => {
         dispatch(dashboardEmpListAct(result))
         setPage(page)
         setLoading(false)
      })
      .error((e) => {
         const { message: msg = '' } = e;
         setLoading(false);
      })
      .send(() => {
         setLoading(false)

      });
};
export const empListApi = (formData, setLoading) => (dispatch) => {
   api().root(ADMIN_API_ROOT).post(ADMIN_DASHBOARD_EMPLOER_LIST_API).data(formData).success(({ result = 0 }) => {
      dispatch(dashboardEmpListAct(result))
      setLoading(false)
   })
      .error((e) => {
         const { message: msg = '' } = e;
         setLoading(false);
         errorToast({ title: 'Fetch Failed', msg })
      })
      .send(() => {
         setLoading(false)

      });
};
export const getEmployerDetail = (formData) => (dispatch) => {
   api().root(ADMIN_API_ROOT).post(ADMIN_DASHBOARD_GET_EMPLOYER_DETAIL).data(formData).success(({ result = 0 }) => {

      dispatch(employerDetailAct(result))

      //   setLoading(false)
   })
      .error((e) => {
         const { message: msg = '' } = e;
         //   setLoading(false);    
         errorToast({ title: 'Fetch Failed', msg })
      })
      .send(() => {
         //    setLoading(false)

      });
};

export const getEmployerDetailLog = (formData) => (dispatch) => {
   api().root(ADMIN_API_ROOT).post(ADMIN_DASHBOARD_GET_EMPLOYER_LOG).data(formData).success(({ result = {} }) => {
      dispatch(setProfileDetailsLog(result))
   }).error((e) => {
         const { message: msg = '' } = e;
          errorToast({ title: 'Fetch Failed For active log', msg })
      })
      .send();
};

export const getChatTotal = (formData) => (dispatch) => {
   api().root(TASK_MANAGEMENT_ROOT_API).post(ADMIN_DASHBOARD_CHAT_TOTAL).data(formData).success(({ result = {} }) => {
      console.log(result);
      dispatch(setChatTotalLog(result))
   }).error((e) => {
         const { message: msg = '' } = e;
         console.log(e);
      })
      .send();
};

export const getDeleteEmployerDetail = (formData, setLoading) => (dispatch) => {
   api().root(ADMIN_API_ROOT).post(ADMIN_DASHBOARD_GET_DELETE_EMPLOYER_DETAIL).data(formData).success(({ result = 0 }) => {
      dispatch(deleteEmployerDetailAct(result))
      setLoading(false)
   })
      .error((e) => {
         const { message: msg = '' } = e;
         setLoading(false)
         errorToast({ title: 'Fetch Failed', msg })
      })
      .send(() => {
         setLoading(false)
      });
};
export const getDeleteJobseekerDetail = (formData, setLoading) => (dispatch) => {
   api().root(ADMIN_API_ROOT).post(ADMIN_DASHBOARD_GET_DELETE_JOB_SEEKER_DETAIL).data(formData).success(({ result = 0 }) => {
      dispatch(deleteJobseekerDetailAct(result))
      setLoading(false)
   })
      .error((e) => {
         const { message: msg = '' } = e;
         setLoading(false)
         errorToast({ title: 'Fetch Failed', msg })
      })
      .send(() => {
         setLoading(false)
      });
};
export const employerBlock = (formData, getData,) => () => {
   api().root(ADMIN_API_ROOT).post(ADMIN_EMPLOYER_BLOCK_API).data(formData).success(({ result = 0 }) => {
      successToast({ title: "Success", msg: "Successfully  Blocked" })
      getData()
      // setOpenModal(false)
      // setLoading(false)
   })
      .error((e) => {
         const { message: msg = '' } = e;

         errorToast({ title: 'Block failed', msg })
      })
      .send();
};

export const employerUnBlock = (formData, getData) => () => {
   api().root(ADMIN_API_ROOT).post(ADMIN_EMPLOYER_UNBLOCK_API).data(formData).success(({ result = 0 }) => {
      successToast({ title: "Success", msg: "Successfully  Un-Blocked" })
      getData()
      // setOpenModal(false)
      // setLoading(false)
   })
      .error((e) => {
         const { message: msg = '' } = e;

         // errorToast({ title: 'Unblock Failed', msg })
      })
      .send(() => {


      });
};
export const changeEmployerPwd = (formData, setLoadingBtn, setOpen, setPassword, setConfirmPwd) => (dispatch) => {
   api().root(ADMIN_API_ROOT).post(ADMIN_EMPLOYER_PASSWORD_API).data(formData).success(({ result = 0 }) => {
      successToast({ title: "Success", msg: "Change Password Successfull" })
      setOpen(false)
      setLoadingBtn(false)
      setPassword('');
      setConfirmPwd('');
   })
      .error((e) => {
         const { message: msg = '' } = e;
         setLoadingBtn(false)
         errorToast({ title: 'Failed', msg })
      })
      .send(() => {
         setLoadingBtn(false)
      });
};
export const empPwdLink = (formData, setLoadBtn, setOpen) => (dispatch) => {
   api().root(ADMIN_API_ROOT).post(ADMIN_EMPLOYER_PASSWORD_API_LINK).data(formData).success(({ result = 0 }) => {
      successToast({ title: "Success", msg: "Change Password Link sent to email successful" })
      setLoadBtn(false)
      setOpen(false)
   })
      .error((e) => {
         const { message: msg = '' } = e;
         errorToast({ title: 'Failed', msg })
      })
      .send();
};
export const jsPwdLink = (formData, setLoadBtn, setOpen) => (dispatch) => {
   api().root(ADMIN_API_ROOT).post(ADMIN_JOBSEEKER_PASSWORD_API_LINK).data(formData).success(({ result = 0 }) => {
      successToast({ title: "Success", msg: "Change Password Link sent to email successful" })
      setLoadBtn(false)
      setOpen(false)
   })
      .error((e) => {
         const { message: msg = '' } = e;
         errorToast({ title: 'Failed', msg })
      })
      .send();
};

export const adminJobListApi = (formData, setLoading) => (dispatch) => {
   api().root(ADMIN_API_ROOT).post(ADMIN_JOB_LIST_API).data(formData).success((a) => {
      dispatch(jobListAct(a.result))
      setLoading(false);
   })
      .error((e) => {
         const { message: msg = '' } = e;
         // setLoading(false)     
         // errorToast({ title: 'Job List', msg })
      })
      .send(() => {
         setLoading(false)
      });
};

export const deleteEmployerListApi = (formData, setLoading, setPage, page) => (dispatch) => {
   api().root(ADMIN_API_ROOT).post(ADMIN_DELETE_EMPLOYER_LIST_API).data(formData).success((a) => {
      setPage(page);
      dispatch(deleteEmployerListAct(a.result));
      setLoading(false);
   })
      .error((e) => {
         const { message: msg = '' } = e;
         setLoading(false)
      })
      .send(() => {
         setLoading(false)
      });
};

export const deletejobseekerListApi = (formData, setLoading, setPage, page) => (dispatch) => {
   api().root(ADMIN_API_ROOT).post(ADMIN_DELETE_JOBSEEKER_LIST_API).data(formData).success((a) => {
      setPage(page);
      dispatch(deleteJobseekerListAct(a.result));
      setLoading(false);
   })
      .error((e) => {
         const { message: msg = '' } = e;
         setLoading(false)
      })
      .send(() => {
         setLoading(false)
      });
};

export const adminJobDeleteApi = (formData,callBack = null) => (dispatch) => {
   api().root(ADMIN_API_ROOT).post(ADMIN_JOB_DELETE_API).data(formData).success((a) => {
      // setLoadingBtn(false)  
      // setOpen(false);
      callBack?.()
      successToast({ title: "Success", msg: "Successfully Deleted" })
   })
      .error((e) => {
         const { message: msg = '' } = e;
         // setLoadingBtn(false)
         errorToast({ title: 'Deletion Failed', msg })
      })
      .send(() => {
         // setLoadingBtn(false)    
      });
};
export const adminJobSeekerListApi = (formData, setLoading, setPage, page) => (dispatch) => {
   api().root(ADMIN_API_ROOT).post(ADMIN_JOB_SEEKER_LIST_API).data(formData).success((a) => {
      dispatch(adminJobSeekerAct(a?.result))
      setPage(page);
      setLoading(false);
   })
      .error((e) => {
         console.log(e);
         const { message: msg = '' } = e;
         setLoading(false)
         // errorToast({ title: 'Fetching Data Failed', msg })
      })
      .send(() => {
         setLoading(false)
      });
};

export const changeJobSeekerPwd = (formData, setOpen, setLoadingBtn, setPassword, setConfirmPwd) => () => {
   api().root(ADMIN_API_ROOT).post(ADMIN_JOB_SEEKER_PASSWORD_API).data(formData).success(() => {
      successToast({ title: "Success", msg: "Password Changed Successfully" })
      setOpen(false);
      setLoadingBtn(false);
      setPassword('');
      setConfirmPwd('');
   })
      .error((e) => {
         const { message: msg = '' } = e;
         setLoadingBtn(false)
         errorToast({ title: 'Failed', msg })
      })
      .send(() => {
         setLoadingBtn(false)
      });
};
export const getJobSeekerDetails = (formData, setOpen) => (dispatch) => {
   api().root(ADMIN_API_ROOT).post(ADMIN_GET_JOB_SEEKER_DETAIL_API).data(formData).success((a) => {
      dispatch(adminJobSeekerDataAct(a.result))
      setOpen(false)
      // setLoading(false)
   })
      // .error((e) => {
      //    const { message: msg = '' } = e;       
      //    setLoadingBtn(false)
      //    errorToast({ title: 'Failed', msg })
      //  })
      .send(() => {
         // setLoadingBtn(false)    
      });
};


export const getCategoryList = (formData, setLoading, setPage, page) => (dispatch) => {
   api().root(ADMIN_API_ROOT).post(ADMIN_CATEGORY_API).data(formData).success((a) => {
      setPage(page);
      dispatch(adminCategoryAct(a.result));
      setLoading(false);
   })
      .error((e) => {
         const { message: msg = '' } = e;
         setLoading(false)
         errorToast({ title: 'Failed', msg })
      })
      .send(() => {
         setLoading(false)
      });
};
export const deleteCategory = (formData, setOpenDelete, setLoading, getCategoryListFunc) => () => {
   api().root(ADMIN_API_ROOT).post(ADMIN_DELETE_CATEGORY_API).data(formData).success((a) => {
      successToast({ title: "Deleted", msg: "Successfully Deleted Category" });
      getCategoryListFunc();
      setLoading(false);
      setOpenDelete(false);
   })
      .error((e) => {
         const { message: msg = '' } = e;
         setLoading(false)
         errorToast({ title: 'Failed', msg })
      })
      .send(() => {
         setLoading(false)
      });
};

export const addCategoryApi = (formData, setBtnLoading, getCategoryListFunc, setOpenAdd) => () => {
   api().root(ADMIN_API_ROOT).post(ADMIN_ADD_CATEGORY_API).data(formData).success((a) => {
      successToast({ title: "Added", msg: "Successfully Category Added" });
      setBtnLoading(false);
      getCategoryListFunc();
      setOpenAdd(false);
      navigate(SUPER_ADMIN_CATEGORY);
   })
      .error(e => {
         errorToast({ title: "Failed", msg: e.message });
         setBtnLoading(false);

      })
      .send(() => {
         setBtnLoading(false)
      });
};
export const updateCategoryApi = (formData, setBtnLoading, setOpen, getCategoryListFunc) => (dispatch) => {
   api().root(ADMIN_API_ROOT).post(ADMIN_UPDATE_CATEGORY_API).data(formData).success((a) => {
      successToast({ title: "Category Updated", msg: "Successfully Category Updated" });
      getCategoryListFunc();
      setBtnLoading(false);
      setOpen(false);
   })
      .error(e => {
         errorToast({ title: "Failed", msg: e.message });
         setBtnLoading(false);

      })
      .send(() => {
         setBtnLoading(false)
      });
};

export const getSkillsList = (formData, setLoading, setPage, page) => (dispatch) => {
   api().root(ADMIN_API_ROOT).post(ADMIN_SKILL_API).data(formData).success((a) => {
      setPage(page);
      dispatch(adminSkillAct(a.result));
      setLoading(false);
   })
      .error(e => {
         errorToast({ title: "Failed", msg: e.message });
         setLoading(false);

      })
      .send(() => {
         setLoading(false)
      });
};

export const deleteSkill = (formData, getSkillListFunc, setLoadingBtn, setOpenDelete) => () => {
   api().root(ADMIN_API_ROOT).post(ADMIN_DELETE_SKILL_API).data(formData).success((a) => {
      successToast({ title: "Deleted", msg: "Successfully Deleted Skill" });
      getSkillListFunc();
      setLoadingBtn(false);
      setOpenDelete(false);
   })
      .error(e => {
         errorToast({ title: "Failed", msg: e.message });
         setLoadingBtn(false);

      })
      .send(() => {
         setLoadingBtn(false)
      });
};

export const addSkillApi = (formData, setLoading) => () => {
   api().root(ADMIN_API_ROOT).post(ADMIN_ADD_SKILL_API).data(formData).success((a) => {
      successToast({ title: "Added", msg: "Successfully Skill Added" });
      setLoading(false);
      navigate(SUPER_ADMIN_SKILL)
   })
      .error(e => {
         errorToast({ title: "Failed", msg: e.message });
         setLoading(false);

      })
      .send(() => {
         setLoading(false)
      });
};

export const updateSkillApi = (formData, setBtnLoading, setOpen, getData) => (dispatch) => {
   api().root(ADMIN_API_ROOT).post(ADMIN_UPDATE_SKILL_API).data(formData).success((a) => {
      successToast({ title: "Updated", msg: "Successfully Skill Updated" });
      getData();
      setBtnLoading(false);
      setOpen(false);
   })
      .error(e => {
         errorToast({ title: "Failed", msg: e.message });
         setBtnLoading(false);

      })
      .send(() => {
         setBtnLoading(false)
      });
};


export const getPaymentHistoryFilterList = (formData, setLoading, setPage, page) => (dispatch) => {
   api().root(ADMIN_API_ROOT).post(ADMIN_PAYMENT_HISTORY_FILTER_LIST_API).data(formData).success((a) => {
      dispatch(adminPaymentHistoryAct(a.result));
      setLoading(false);
      setPage(page);
   })
      .error(e => {
         errorToast({ title: "Failed", msg: e.message });
         setLoading(false);

      })
      .send(() => {
         setLoading(false)
      });
};

export const getJobTitleList = (formData, setLoading, setPage, page) => (dispatch) => {
   api().root(ADMIN_API_ROOT).post(ADMIN_JOB_TITLE_API).data(formData).success((a) => {
      setPage(page);
      dispatch(adminJobTitleAct(a.result));
      setLoading(false);
   })
      .error(e => {
         errorToast({ title: "Failed", msg: e.message });
         setLoading(false);

      })
      .send(() => {
         setLoading(false)
      });
};

export const deleteJobTitle = (formData, setOpenDelete, getJobTitleListFunc, setLoading) => (dispatch) => {
   api().root(ADMIN_API_ROOT).post(ADMIN_DELETE_JOB_TITLE_API).data(formData).success((a) => {
      successToast({ title: "Deleted", msg: "Successfully Job Title Deleted" });
      getJobTitleListFunc();
      setLoading(false);
      setOpenDelete(false);
   })
      .error(e => {
         errorToast({ title: "Failed", msg: e.message });
         setLoading(false);

      })
      .send(() => {
         setLoading(false)
      });

};


export const updateJobTitleApi = (formData, setBtnLoading, setOpen, getJobTitleListFunc) => (dispatch) => {
   api().root(ADMIN_API_ROOT).post(ADMIN_UPDATE_JOB_TITLE_API).data(formData).success((a) => {
      successToast({ title: "Updated", msg: "Successfully Job Title Updated" });
      getJobTitleListFunc();
      setBtnLoading(false);
      setOpen(false);
   })
      .error(e => {
         errorToast({ title: "Failed", msg: e.message });
         setBtnLoading(false);

      })
      .send(() => {
         setBtnLoading(false)
      });
};

export const addJobTitleApi = (formData, setBtnLoading) => (dispatch) => {
   api().root(ADMIN_API_ROOT).post(ADMIN_ADD_JOB_TITLE_API).data(formData).success((a) => {
      successToast({ title: "Added", msg: "Successfully Job title Added" });
      setBtnLoading(false);
      navigate(SUPER_ADMIN_JOB_TITLE)
   })
      .error(e => {
         errorToast({ title: "Failed", msg: e.message });
         setBtnLoading(false);

      })
      .send(() => {
         setBtnLoading(false)
      });
};

export const accountActivation = (formData, history) => (dispatch) => {
   dispatch(activationLoading(true));
   api().root(ADMIN_API_ROOT).post(ADMIN_STAFF_ACTIVATION_API).data(formData).success(({ message }) => {
      successToast({ title: 'Success', msg: message });
      history.push(ADMIN_LOGIN);
   })
      .error((e) => {
         const { message = '' } = e ?? {};
         devConsoleLog(e);
         errorToast({ title: 'Failed', msg: message });
      })
      .send(() => {
         dispatch(activationLoading(false));
      });
};

export const getStaffListApi = (formData, setLoading, setPage, page) => (dispatch) => {

   api().root(ADMIN_API_ROOT).post(ADMIN_STAFF_LIST_API).data(formData).success((a) => {
      dispatch(adminStaffListAct(a.result));
      setPage(page);
      setLoading(false);
   })
      .error(e => {
         errorToast({ title: "Failed", msg: e.message });
         setLoading(false);

      })
      .send(() => {
         setLoading(false)
      });
};

export const addStaffApi = (formData, setBtnLoading) => () => {
   api().root(ADMIN_API_ROOT).post(ADMIN_ADD_STAFF_API).data(formData).success((a) => {
      successToast({ title: "Success", msg: a.message });
      setBtnLoading(false);
      navigate(SUPER_ADMIN_MANAGE_STAFF)
   })
      .error(a => {
         errorToast({ title: "Failed", msg: a.message });
         setBtnLoading(false);

      })
      .send(() => {
         setBtnLoading(false)
      });
};

export const updateStaffApi = (formData, getData, setOpen) => () => {
   api().root(ADMIN_API_ROOT).post(ADMIN_UPDATE_STAFF_API).data(formData).success((a) => {
      successToast({ title: "Success", msg: a.message });
      getData();
      setOpen(false);
   })
      .error(e => {
         errorToast({ title: "Failed", msg: e.message });
      })
      .send();
};

export const deleteStaffApi = (formData, setLoading, getData) => () => {
   api().root(ADMIN_API_ROOT).post(ADMIN_DELETE_STAFF_API).data(formData).success((a) => {
      successToast({ title: "Deleted", msg: "Successfully Deleted Staff" });
      getData();
      setLoading(false);
   })
      .error(e => {
         errorToast({ title: "Failed", msg: e.message });
         setLoading(false);

      })
      .send(() => {
         setLoading(false)
      });
};

export const getJobSeekerVerifyList = (formData, setLoading) => (dispatch) => {
   api().root(ADMIN_API_ROOT).post(ADMIN_JOB_SEEKER_VERIFY_LIST_API).data(formData).success((a) => {
      dispatch(adminJobSeekerVerifyAct(a.result));
      setLoading(false);
   })
      .error(e => {
         errorToast({ title: "Failed", msg: e.message });
         // setLoading(false);

      })
      .send(() => {
         // setLoading(false)
      });
};
export const getJobSeekerVerifyData = (formData, setLoading) => (dispatch) => {
   api().root(ADMIN_API_ROOT).post(ADMIN_JOB_SEEKER_VERIFY_API).data(formData).success((a) => {
      dispatch(adminJobSeekerSingleDataAct(a.result));
      setLoading(false);
   })
      .error(e => {
         errorToast({ title: "Failed", msg: e.message });
         setLoading(false);

      })
      .send(() => {
         setLoading(false)
      });
};
export const loginAdmin = (formData,
   history, setFormData, setLoading) => (dispatch) => {
      api().root(ADMIN_API_ROOT).post(ADMIN_LOGIN_API).data(formData)
         .success((a) => {
            setFormData({})
            setLoading(false);

            const { message, result = {} } = a;
            const { access_token = null } = result;
            successToast({ title: 'Success', msg: message });
            storeLocal(access_token);
            storeLocal(result, USER_DATA);
            dispatch(sessionSuccess(result));
            const path = ROLE_NAV?.[result?.user_type] ?? '';
            if (path) {
               history.push(path);
            }
         })
         .error((e) => {
            setLoading(false);
            const { message: msg = '' } = e;
            dispatch(sessionFail());
            errorToast({ title: 'Login Failed', msg });
         })
         .send(() => {
            setLoading(false);
         });
   };

export const adminJobSeekerProfileVerify = (formData, setLoading, setOpenModal, getJobSeekerList, setLoadingBtn) => (dispatch) => {
   api().root(ADMIN_API_ROOT).post(ADMIN_JOB_SEEKER_VERIFY).data(formData).success(({ result = 0 }) => {
      if (formData.status === "accept") {
         successToast({ title: "Success", msg: "Accepted Successfully" })
      }
      if (formData.status === "reject") {
         successToast({ title: "Success", msg: "Rejected Successfully" })
      }
      setLoadingBtn(false);
      setOpenModal(false)
      setLoading(false)
      getJobSeekerList();
   })
      .error(e => {
         errorToast({ title: "Failed", msg: e.message });
         setLoading(false);
         setLoadingBtn(false);

      })
      .send(() => {
         setLoading(false)
         setLoadingBtn(false);
      });
};
export const adminAcitvityLogList = (formData, setLoading, setDataLoading = null, setPage, page) => (dispatch) => {
   api().root(ADMIN_API_ROOT).post(ADMIN_ACTIVITY_LOG_LIST_API).data(formData).success(({ result = "" }) => {
      setPage(page);
      dispatch(activiyListAct(result.data))
      setLoading(false)
      setDataLoading(true)

   })
      .error(e => {
         errorToast({ title: "Failed", msg: e.message });
         setLoading(false);
         setDataLoading(true)


      })
      .send(() => {
         setLoading(false)
         setDataLoading(true)

      });
};

export const adminNotificationListApi = (formData, setLoading) => (dispatch) => {
   api().root(ADMIN_API_ROOT).post(ADMIN_NOTIFICATION_LIST_API).data(formData).success(({ result = "" }) => {
      dispatch(notificationAct(result))
      setLoading(false)
   })
      .error(e => {
         errorToast({ title: "Failed", msg: e.message });
         setLoading(false);


      })
      .send(() => {
         setLoading(false)

      });
};
export const adminNotificationUpdateApi = (formData, notificationDataList) => () => {
   api().root(ADMIN_API_ROOT).post(ADMIN_NOTIFICATION_UPDATE_API).data(formData).success(() => {

      notificationDataList();

   })
      .error(e => {
         errorToast({ title: "Failed", msg: e.message });
      })
      .send();
};
export const adminChatByEmployer = (formData) => (dispatch) => {
   api().root(TASK_MANAGEMENT_API).post(ADMIN_CHAT_LIST_BY_EMAIL).data(formData).success(({ result = "" }) => {
      dispatch(chatListEmpAct(result))

      // setLoading(false)
   })
      .error(e => {
         errorToast({ title: "Failed", msg: e.message });
      })
      .send();
};
export const adminChatByJobSeeker = (formData) => (dispatch) => {
   api().root(TASK_MANAGEMENT_API).post(ADMIN_CHAT_LIST_BY_EMAIL).data(formData).success(({ result = "" }) => {
      dispatch(chatListJobAct(result))

   })
      .error(e => {
         errorToast({ title: "Failed", msg: e.message });
      })
      .send();
};
export const adminChatFilter = (formData) => (dispatch) => {
   api().root(TASK_MANAGEMENT_ROOT_API).post(ADMIN_CHAT_FILTER).data(formData).success(({ result = "" }) => {
      dispatch(chatListAct(result))

      // setLoading(false)
   })
      .error(e => {
         errorToast({ title: "Failed", msg: e.message });
      })
      .send();
};
export const adminEmployerAcceptedApi = (formData, setLoading) => (dispatch) => {
   api().root(ADMIN_API_ROOT).post(ADMIN_EMPOLYER_ACCEPTED_LIST).data(formData).success(({ result = "" }) => {
      dispatch(adminEmployerAcceptedListAct(result))
      setLoading(false)
   })
      .error(e => {
         setLoading(false)
         errorToast({ title: "Failed", msg: e.message });
      })
      .send(() => {
         setLoading(false)
      });
};
export const adminEmployerRejectedApi = (formData, setLoading) => (dispatch) => {
   api().root(ADMIN_API_ROOT).post(ADMIN_EMPOLYER_REJECTED_LIST_API).data(formData).success(({ result = "" }) => {
      dispatch(adminEmployerRejectedListAct(result))
      setLoading(false)
   })
      .error(e => {
         setLoading(false)
         errorToast({ title: "Failed", msg: e.message });
      })
      .send(() => {
         setLoading(false)
      });
};
export const adminJobSeekerAcceptedApi = (formData, setLoading, setPage, page) => (dispatch) => {
   api().root(ADMIN_API_ROOT).post(ADMIN_JOB_SEEKER_ACCEPTED_LIST_API).data(formData).success(({ result = "" }) => {
      setPage(page);
      dispatch(adminJobSeekerAcceptedListAct(result))
      setLoading(false)
   })
      .error(e => {
         setLoading(false)
         errorToast({ title: "Failed", msg: e.message });
      })
      .send(() => {
         setLoading(false)
      });
};
export const adminJobSeekerRejectedApi = (formData, setLoading) => (dispatch) => {
   api().root(ADMIN_API_ROOT).post(ADMIN_JOB_SEEKER_REJECTED_LIST_API).data(formData).success(({ result = "" }) => {
      dispatch(adminJobSeekerRejectedListAct(result))
      setLoading(false)
   })
      .error(e => {
         setLoading(false)
         errorToast({ title: "Failed", msg: e.message });
      })
      .send(() => {
         setLoading(false)
      });
};

export const getBonusFilterList = (formData, setLoading) => (dispatch) => {
   api().root(ADMIN_API_ROOT).post(ADMIN_BONUS_FILTER_LIST_API).data(formData).success((a) => {
      dispatch(adminBonusListAct(a.result));
      setLoading(false);
   })
      .error(e => {
         errorToast({ title: "Failed", msg: e.message });
         setLoading(false);

      })
      .send(() => {
         setLoading(false)
      });
};

export const getJobInfo = (formData, setOpen) => (dispatch) => {
   api().root(ADMIN_API_ROOT).post(ADMIN_GET_JOB_INFO).data(formData).success((a) => {
      dispatch(adminJobInfoAct(a.result))
      setOpen(false)
   })
      .send(() => {
      });
};

export const updateJobInfo = (formData, getData, closingModal) => (dispatch) => {
   api()
      .root(ADMIN_API_ROOT)
      .post(ADMIN_UPDATE_JOB_INFO)
      .data(formData)
      .success(({ result }) => {
         successToast({ title: 'Success', message: "Job updated successfully" });
         getData();
         closingModal();
      })
      .send();
};

export const updateJobSeekerInfo = (formData, getData) => (dispatch) => {
   api()
      .root(ADMIN_API_ROOT)
      .post(ADMIN_UPDATE_JOBSEEKER_INFO)
      .data(formData)
      .success(({ result }) => {
         successToast({ title: 'Success', message: result.message });
         getData();
      })
      .send();
};

export const updateEmployerInfo = (formData, getData) => (dispatch) => {
   api()
      .root(ADMIN_API_ROOT)
      .post(ADMIN_UPDATE_EMPLOYER_INFO)
      .data(formData)
      .success(({ result }) => {
         successToast({ title: 'Success', message: result.message });
         getData();
      })
      .send();
};
export const deleteJobseekerInfo = (formData, getData) => (dispatch) => {
   api()
      .root(ADMIN_API_ROOT)
      .post(ADMIN_DELETE_JOBSEEKER_INFO)
      .data(formData)
      .success(({ result }) => {
         successToast({ title: 'Success', message: result.message });
         getData();
      })
      .send();
};

export const deleteJobSeekerStripe = (formData, getData,loading) => (dispatch) => {
   loading(true)
   api()
     .root(ADMIN_API_ROOT)
     .post(ADMIN_DELETE_JOBSEEKER_STRIPE)
     .data(formData)
     .success(({ result }) => {
        successToast({ title: 'Success', message: result.message });
        getData();
     })
     .send(()=>{
        loading(false)
     });
};
export const deleteEmployerInfo = (formData, getData) => (dispatch) => {
   api()
      .root(ADMIN_API_ROOT)
      .post(ADMIN_DELETE_EMPLOYER_INFO)
      .data(formData)
      .success(({ result }) => {
         successToast({ title: 'Success', message: result.message });
         getData();
      })
      .send();
};
export const verifyJobApi = (formData, getData, checked, setLoad) => (dispatch) => {
   api()
      .root(ADMIN_API_ROOT)
      .post(ADMIN_VERIFY_JOB_API)
      .data(formData)
      .success(({ result }) => {
         successToast({ title: 'Success', message: checked ? "Job verified successfully" : "Job rejected successfully" });
         getData();
         setLoad(false)
      })
      .send();
};
export const releaseDisputeApi = (formData, setLoading) => (dispatch) => {
   api().root(ADMIN_API_ROOT).post(ADMIN_RELEASE_DISPUTE).data(formData).success((a) => {
      successToast({ title: "Success", msg: a.message });
      setLoading(false);
   })
      .error(e => {
         errorToast({ title: "Failed", msg: e.message });
         setLoading(false);

      })
      .send(() => {
         setLoading(false)
      });
};

export const adminUnverifiedJobListApi = (formData, setLoading, setPage, page) => (dispatch) => {
   api().root(ADMIN_API_ROOT).post(ADMIN_UNVERIFIED_JOB_LIST_API).data(formData).success((a) => {
      dispatch(jobUnverifiedListAct(a.result));
      setPage(page)
      setLoading(false);
   })
      .error((e) => {
         const { message: msg = '' } = e;
         // setLoading(false)     
         // errorToast({ title: 'Job List', msg })
      })
      .send(() => {
         // setLoading(false)    
      });
};
export const adminAddEventApi = (formData) => (dispatch) => {
   api().root(ADMIN_API_ROOT).post(ADMIN_ADD_ANNOUNCEMENT).data(formData).success((a) => {
      successToast({ title: "Success", msg: a.message });
      // navigate('/super_admin/announcement-and-promos')
   })
      .error((e) => {
         const { message: msg = '' } = e;
      })
      .send(() => {
      });
};
export const adminGetEventApi = (formData) => (dispatch) => {
   api().root(ADMIN_API_ROOT).post(ADMIN_GET_ANNOUNCEMENT).data(formData).success((a) => {
      dispatch(setAnnouncement(a.result))
   })
      .error((e) => {
         const { message: msg = '' } = e;
         // setLoading(false)     
         // errorToast({ title: 'Job List', msg })
      })
      .send(() => {

      });
};
export const adminGetReferralApi = (formData) => (dispatch) => {
   api().root(ADMIN_API_ROOT).post(ADMIN_GET_REFERRAL).data(formData).success((a) => {
      dispatch(setReferral(a.result))
   })
      .error((e) => {
         const { message: msg = '' } = e;
         // setLoading(false)     
         // errorToast({ title: 'Job List', msg })
      })
      .send(() => {

      });
};
export const adminGetaffiliateApi = (formData) => (dispatch) => {
   api().root(ADMIN_API_ROOT).post(ADMIN_GET_AFFILIATE).data(formData).success((a) => {
      dispatch(setAffiliate(a.result))
   })
      .error((e) => {
         const { message: msg = '' } = e;
         // setLoading(false)     
         // errorToast({ title: 'Job List', msg })
      })
      .send(() => {

      });
};
export const adminUpadateEventApi = (formData) => (dispatch) => {
   api().root(ADMIN_API_ROOT).post(ADMIN_UPDATE_ANNOUNCEMENT).data(formData).success((a) => {
      successToast({ title: "Success", msg: a.message });
      navigate('/super_admin/announcement-and-promos')
   })
      .error((e) => {
         const { message: msg = '' } = e;
      })
      .send(() => {
      });
};

export const adminDeleteEventApi = (formData) => (dispatch) => {
   api().root(ADMIN_API_ROOT).post(ADMIN_DELETE_ANNOUNCEMENT).data(formData).success((a) => {
      successToast({ title: "Success", msg: a.message });
      navigate('/super_admin/announcement-and-promos')
   })
      .error((e) => {
         const { message: msg = '' } = e;
      })
      .send(() => {
      });
};

export const adminGetEventInfoApi = (formData) => (dispatch) => {
   api().root(ADMIN_API_ROOT).post(ADMIN_GET_ANNOUNCEMENT_INFO).data(formData).success((a) => {

      dispatch(eventInfoAct(a.result))
   })
      .error((e) => {
         const { message: msg = '' } = e;
      })
      .send(() => {
      });
};

export const adminJobSeekerVerfifyListApi = (formData, setLoading, setPage, page) => (dispatch) => {
   api().root(ADMIN_API_ROOT).post(ADMIN_JOB_SEEKER_VERIFY_LIST_API).data(formData).success((a) => {
      dispatch(jobSeekerVerifyListAct(a?.result))
      setPage(page);
      setLoading(false);
   })
      .error((e) => {
         console.log(e);
         const { message: msg = '' } = e;
         setLoading(false)
         // errorToast({ title: 'Fetching Data Failed', msg })
      })
      .send(() => {
         setLoading(false)
      });
};




export const adminJobUnverifiedListApi = (formData, setLoading, setPage, page) => (dispatch) => {
   api().root(ADMIN_API_ROOT).post(ADMIN_UNVERIFIED_JOB_LIST_API).data(formData).success((a) => {
      dispatch(jobUnverifiedListAct(a.result));
      setPage(page)
      setLoading(false);
   })
      .error((e) => {
         const { message: msg = '' } = e;
         // setLoading(false)     
         // errorToast({ title: 'Job List', msg })
      })
      .send(() => {
         // setLoading(false)    
      });
};
//account verifier

export const accountJobListApi = (formData, setLoading) => (dispatch) => {
   api().root(ADMIN_API_ROOT).post(ADMIN_UNVERIFIED_JOB_LIST_API).data(formData).success((a) => {
      dispatch(jobUnverifiedListAct(a.result))
      setLoading(false);
   })
      .error((e) => {
         const { message: msg = '' } = e;
         setLoading(false)
         // errorToast({ title: 'Job List', msg })
      })
      .send(() => {
         setLoading(false)
      });
};

export const accountEmpListApi = (formData, setLoading) => (dispatch) => {
   api().root(ADMIN_API_ROOT).post(ADMIN_EMPLOYER_VERIFY_LIST).data(formData).success(({ result = 0 }) => {
      dispatch(adminEmpVerifyAct(result))
      setLoading(false)
   })
      .error((e) => {
         const { message: msg = '' } = e;
         setLoading(false);
         errorToast({ title: 'Fetch Failed', msg })
      })
      .send(() => {
         setLoading(false)

      });
};

export const accountJobSeekerListApi = (formData, setLoading) => (dispatch) => {
   api().root(ADMIN_API_ROOT).post(ADMIN_JOB_SEEKER_VERIFY_LIST_API).data(formData).success((a) => {
      dispatch(jobSeekerVerifyListAct(a?.result))
      setLoading(false);
   })
      .error((e) => {
         const { message: msg = '' } = e;
         setLoading(false)
         errorToast({ title: 'Fetching Data Failed', msg })
      })
      .send(() => {
         setLoading(false)
      });
};
export const accountUnverifiedJobListApi = (formData, setLoading, setPage, page) => (dispatch) => {
   api().root(ADMIN_API_ROOT).post(ADMIN_UNVERIFIED_JOB_LIST_API).data(formData).success((a) => {
      dispatch(jobUnverifiedListAct(a.result));
      setPage(page)
      setLoading(false);
   })
      .error((e) => {
         const { message: msg = '' } = e;
         // setLoading(false)     
         // errorToast({ title: 'Job List', msg })
      })
      .send(() => {
         // setLoading(false)    
      });
};
export const adminGetProfileApi = (formData) => (dispatch) => {
   api().root(ADMIN_API_ROOT).post(ADMIN_GET_ACCOUNT_DETAILS).data(formData).success((a) => {
      dispatch(setProfileDetails(a?.result))
       
   })
      .error((e) => {
         const { message: msg = '' } = e;
      
      })
      .send(() => {
     
      });
};
export const adminUpdatetProfileApi = (formData) => (dispatch) => {
   api().root(ADMIN_API_ROOT).post(ADMIN_UPDATE_ACCOUNT_DETAILS).data(formData).success((a) => {
      dispatch(adminGetProfileApi())
      successToast({ title: "Success", message: a.message})
   })
      .error((e) => {
         const { message: msg = '' } = e;
         
      })
      .send(() => {
     
      });
};
export const adminGetEmpUsersApi = (formData,setUsers) => (dispatch) => {
   api().root(ADMIN_API_ROOT).post(ADMIN_GET_FREEUSERS_LIST).data(formData).success((a) => {
      setUsers(a?.result?.data)
    
   })
      .error((e) => {
         const { message: msg = '' } = e;
         
      })
      .send(() => {
     
      });
};
export const adminGetSubscribersApi = (formData,setLoading,setUsers) => (dispatch) => {
   api().root(ADMIN_API_ROOT).post(ADMIN_GET_SUBSCRIBERS_LIST).data(formData).success((a) => {
      console.log(a.result)
      setUsers(a?.result?.data)
    
   })
      .error((e) => {
         const { message: msg = '' } = e;
         
      })
      .send(() => {
         setLoading(false)
      });
};
export const adminUpdateExpiryDateApi = (formData,setOpen,getData) => (dispatch) => {
   api().root(ADMIN_API_ROOT).post(ADMIN_UPDATE_EXPIRYDATE).data(formData).success((a) => {
      setOpen(false)
      getData()
      successToast({ title: "Success", message: a.message})
   })
      .error((e) => {
         const { message: msg = '' } = e;
         
      })
      .send(() => {
     
      });
};
export const adminUpdateBasicPlanApi = (formData,setLoadBtn, setOpen, getData) => (dispatch) => {
   api().root(ADMIN_API_ROOT).post(ADMIN_UPDATE_BASIC_PLAN).data(formData).success((a) => {
      setLoadBtn(false)
      setOpen(false)
      getData()
      successToast({ title: "Success", message: a.message})
   })
      .error((e) => {
         const { message: msg = '' } = e;
         
      })
      .send(() => {
         setLoadBtn(false)
      });
};

export const adminSubscriptionCancel = (formData,setLoadBtn, setOpen, getData) => (dispatch) => {
   api().root(EMPLOYER_API_ROOT).post(ADMIN_SUBSCRIPTION_CANCEL).data(formData).success((a) => {
      setLoadBtn(false)
      setOpen(false)
      setTimeout(()=>{
         getData()
      },3000)
      successToast({ title: "Success", message: a.message})
   })
      .error((e) => {
         const { message: msg = '' } = e;
         
      })
      .send(() => {
         setLoadBtn(false)
      });
};

export const adminGetProtectedApi = (formData,setList) => (dispatch) => {
   api().root(ADMIN_API_ROOT).post(ADMIN_PROTECTION_LIST).data(formData).success((a) => {
      
      setList(a?.result)
    
   })
      .error((e) => {
         const { message: msg = '' } = e;
         
      })
      .send(() => {
        
      })
}
export const adminIndividualProtectedApi = (formData,setList) => (dispatch) => {
   api().root(ADMIN_API_ROOT).post(ADMIN_INDIVIDUAL_PROTECTION_LIST).data(formData).success((a) => {
      
      setList(a?.result)
    
   })
      .error((e) => {
         const { message: msg = '' } = e;
         
      })
      .send(() => {
        
      })
}
export const adminContractDetailsApi = (formData,setList) => (dispatch) => {
   api().root(ADMIN_API_ROOT).post(ADMIN_CONTRACT_DETAILS).data(formData).success((a) => {
      
      setList(a?.result)
    
   })
      .error((e) => {
         const { message: msg = '' } = e;
         
      })
      .send(() => {
        
      })
}

export const admingetUsersConfig = (formData,setLoading,setData) => (dispatch) => {
   api().root(ADMIN_API_ROOT).post(ADMIN_USERS_CONFIG_LIST).data(formData).success((a) => {
      
      setData(a?.result.data)
    
   })
      .error((e) => {
         const { message: msg = '' } = e;
         
      })
      .send(() => {
         setLoading(false)
      })
}
export const admingetAgreedUsersList= (formData,setLoading,setData) => (dispatch) => {
   api().root(ADMIN_API_ROOT).post(ADMIN_AGREED_USERS_LIST).data(formData).success((a) => {
      
      setData(a?.result.data)
    
   })
      .error((e) => {
         const { message: msg = '' } = e;
         
      })
      .send(() => {
         setLoading(false)
      })
}
export const admingetDeleteUsersList= (formData,setList) => (dispatch) => {
   api().root(ADMIN_API_ROOT).post(ADMIN_DELETE_USERS_LIST).data(formData).success((a) => {
       
        setList(a?.result.data)
    
   })
      .error((e) => {
         const { message: msg = '' } = e;
         
      })
      .send(() => {
        
      })
}

export const adminDeleteEmployer= (formData,setOpen,handleCall) => (dispatch) => {
   api().root(ADMIN_API_ROOT).post(ADMIN_DELETE_USER).data(formData).success((a) => {
       
        setOpen(false)
        handleCall()
        successToast({ title: "Success", message: "Account deleted successfully"})
   })
      .error((e) => {
         const { message: msg = '' } = e;
         
      })
      .send(() => {
        
      })
}
export const adminGetReportedJobs = (reqObj,callBack,loading=null,limit,skip,) => (dispatch) => {
   loading?.(true)

   api().root(ADMIN_API_ROOT).post(`${ADMIN_GET_REPORTED_JOB}?limit=${limit}&skip=${skip}`).data(reqObj).success((a) => {
      callBack?.(a)
      })
      .error((e) => {
         const { message: msg = '' } = e;
      })
      .send(() => {
         loading?.(false)
      })
}
export const adminUpdateReportedJobs = (formData,callBack) => (dispatch) => {
   api().root(ADMIN_API_ROOT).post(ADMIN_UPDATE_REPORTED_JOB).data(formData).success((a) => {
      callBack?.(a)
      })
      .error((e) => {
         const { message: msg = '' } = e;
         
      })
      .send(() => {
        
      })
}

export const getAllApplicantsApi = (formData,callBack, setLoading , ) =>  {
   api().root(ADMIN_API_ROOT).post(ADMIN_INDIVIDUAL_JOB_LIST).data(formData).success((a) => {
      callBack(a.result)
      setLoading(false);
   })
      .error((e) => {
         const { message: msg = '' } = e;

      })
      .send(() => {
         setLoading(false)
      });
};

export const getJobOfferList = (formData, callBack = null, loading = null) => {
   loading?.(true)
   api().root(ADMIN_API_ROOT).post(ADMIN_INDIVIDUAL_OFFER_LIST).data(formData).success((result = {}) => {
      callBack?.(result)
   }).send(() => {
      loading?.(false)
   });
}

export const empHiredStaffApi = (formData, callback, loading) => {
   loading?.(true)
   api().root(ADMIN_API_ROOT).post(ADMIN_INDIVIDUAL_HIRED_LIST).data(formData).success(({ result = {} }) => {
      callback?.(result)
   }).error(devConsoleLog).send(() => {
      loading?.(false)
   })
}

export const viewJobOfferApi = (formData,callBack,loading) => {
   loading?.(true)
   api().root(ADMIN_API_ROOT).post(ADMIN_INDIVIDUAL_STAFF_OFFER_LIST).data(formData).success(res => {
     callBack(res)
   }).send(()=>{
       loading?.(false)
   })
 }

 export const viewHiredApi = (formData,callBack,loading) => {
   loading?.(true)
   api().root(ADMIN_API_ROOT).post(ADMIN_INDIVIDUAL_STAFF_HIRED_LIST).data(formData).success(res => {
     callBack(res)
   }).send(()=>{
       loading?.(false)
   })
 }

 
 export const getPayments = (formData,callBack,loading) => {
   loading?.(true)
   api().root(ADMIN_API_ROOT).post(ADMIN_INDIVIDUAL_PAYMENT_LIST).data(formData).success(res => {
     callBack(res)
   }).send(()=>{
       loading?.(false)
   })
 }


 export const getUserAgreementList = (formData, callBack = null, loading = null) => {
   loading?.(true)
   api().root(ADMIN_API_ROOT).post(ADMIN_USER_AGREEMENT_LIST).data(formData).success((result = {}) => {
      callBack?.(result)
   }).send(() => {
      loading?.(false)
   });
}


export const getUserAgreement = (formData, callBack = null, loading = null) => {
   loading?.(true)
   api().root(ADMIN_API_ROOT).post(ADMIN_USER_AGREEMENT).data(formData).success((result = {}) => {
      callBack?.(result)
   }).send(() => {
      loading?.(false)
   });
}