import { WEBSITE_RELOGIN ,WEBSITE_ROOT} from '../constant/routePathConstant';
import ReLogin from '../../components/website/relogin';
import RedirectPage from '../../components/website/redirectPage';

const SPECIAL_ROUTES = [
  { component: ReLogin, path: WEBSITE_RELOGIN },
  //  {component: RedirectPage, path: '/'} 
];

export default SPECIAL_ROUTES;
