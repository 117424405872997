import React from 'react';
import TrackerWatch from './trackerWatch';

const TrackerHome = () => {
    return(
        <div>
            <TrackerWatch />
        </div>
    )
}

export default TrackerHome;
