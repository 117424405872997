import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  createBoardModal: {
    width: 400,
    '& textarea.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputMultiline.MuiOutlinedInput-inputMultiline':
      {
        color: '#33444d !important',
      },
  },
  cardModal: {
    width: 950,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 400,
    },
    "& .se-component":{
      maxWidth:'700px'
    }
  },
  cardTitle: {
    width: '100%',
    color: '#33444d',
    '& textarea.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputMultiline.MuiOutlinedInput-inputMultiline':
      {
        color: '#33444d !important',
      },
  },
  button: {
    width: 180,
    marginTop: 10,
  },
  membersTitle: {
    margin: '20px 0 10px',
    fontSize: '27px',
    fontWeight: 500,
  },
  labelTitle: {
    margin: '20px 0 10px',
    fontSize: '27px',
    fontWeight: 500,
  },
  colorPicker: {
    minWidth:'max-content',
  },
  noLabel: {
    // width: 300,
  },
 
  moveCardTitle: {
    marginTop: 20,
  },
  moveCard: {
    display: 'flex',
    flexDirection: 'column',
  },
  moveCardSelect: {
    marginTop: 10,
    marginRight: 20,
    width: 200,
  },
  header: {
    marginTop: 10,
    marginBottom: 10,
  },
  checklistItem: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    margin: '2px 0 5px',
  },
  checklistFormLabel: {
    width: '100%',
  },
  itemButtons: {
    display: 'flex',
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  itemButton: {
    height: 40,
  },
  checklistBottom: {
    marginTop: 5,
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    [theme.breakpoints.up('md')]: {
      top: '5%',
      maxHeight: '90vh',
    },
    [theme.breakpoints.down('sm')]: {
      height: '100%',
    },
    overflowY: 'auto',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  modalTop: {
    display: 'flex',
    justifyContent: 'space-between',
    '& textarea.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputMultiline.MuiOutlinedInput-inputMultiline':
      {
        color: '#33444d !important',
      },
    '& .MuiIconButton-root': {
      padding: '30px',
      height: '35px',
      width: '10px',
    },
  },
  modalSection: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    height: 'auto',
  },
  modalSectionMembers: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    height: 'auto',
  },
  modalBottomRight: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    marginTop: 20,
  },
  archiveButton: {
    marginBottom: 5,
  },
  scrollmenu: {
    backgroundColor: '#ffffff',
    overflow: 'auto',
    whiteSpace: 'nowrap',
    border: '1px solid #e5e5e5',
  },

  an: {
    display: 'inline-block',
    color: 'white',
    textAlign: 'center',
    padding: '14px',
    textDecoration: 'none',
    '&:hover': {
      backgroundColor: '#e5e5e5',
    },
  },
  priorityBtns: {
    height: '25px',

    backgroundColor: 'red',
    color: '#ffffff',
    borderRadius: '3px',
    fontWeight: 700,
    padding: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    marginBottom: '5px',
    
  },
  priorityText:{
    fontSize:'15px',
    fontWeight:700
  },
  labelsPriority:{
    minWidth:"300px"
  },
  saveLabel:{
    display:'flex',
    justifyContent: 'center'
    // width:'200px',

 },
 starts_date:{
  //  padding:'0px',
   '& .MuiOutlinedInput-inputAdornedEnd':{
    //  padding:'0px'
   }
 },
 datefeild:{
   '& .MuiFormControl-marginNormal':{
     marginTop:'0px',
     marginBottom:'0px'
   },
   "& .MuiInputLabel-outlined.MuiInputLabel-shrink":{
     background:'#ffffff',
     paddingRight:'5px'
   }
 }
}));

export default useStyles;
