import ring_tone from '../../assets/mp3/ring.mp3';
import FBimage from '../../assets/images/homepage/footer.jpg';

//color
export const WHITE = '#fff'
export const THEME_DARK_BLUE = '#004c70'


export const RING_TONE = ring_tone

export const FOOTER_FB_IMAGE = FBimage