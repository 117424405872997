import { lazy } from 'react';
import {

  ACCOUNT_VERIFY,
  ACCOUNT_VERIFY_DASHBOARD,
  ACCOUNT_VERIFY_EMPLOYER_LIST,
  ACCOUNT_VERIFY_JOB_SEEKER_LIST,
  ACCOUNT_VERIFY_LOGIN,  
  ACCOUNT_VERIFY_MANAGE_EMPLOYER,
  ACCOUNT_VERIFY_MANAGE_JOBS,
  ACCOUNT_VERIFY_MANAGE_JOB_SEEKER,
  ACCOUNT_VERIFY_MANAGE_JOBS_INFO
} from '../constant/routePathConstant';
import { RedirectToCustomerSupportDashboard } from '../../components/accountVerify/common/redirect';

const Dashboard = lazy(() => import('../../components/accountVerify/userDashboard'));
const AccountVerifierLogin = lazy(() => import('../../components/accountVerify/login'));
const ManageJobs = lazy(() => import('../../components/accountVerify/manageJobs'));
const ManageEmployer = lazy(() => import('../../components/accountVerify/manageEmployer'));
const ManageEmployerList = lazy(() =>
  import('../../components/accountVerify/manageEmployerList'),
);

const ManageJobSeekerList = lazy(() =>
  import('../../components/accountVerify/manageJobSeekerList'),
);

const ManageJobSeeker = lazy(() => import('../../components/accountVerify/manageJobSeeker'));

const ManageJobInfo = lazy(() => import('../../components/accountVerify/manageJobInfo'));

const ACCOUNT_VERIFY_ROUTES = [
  { component: AccountVerifierLogin, path: ACCOUNT_VERIFY_LOGIN },
  { component: RedirectToCustomerSupportDashboard, path: ACCOUNT_VERIFY },
  { component: Dashboard, path: ACCOUNT_VERIFY_DASHBOARD },
  { component: ManageJobs, path: ACCOUNT_VERIFY_MANAGE_JOBS },
  { component: ManageEmployer, path: ACCOUNT_VERIFY_MANAGE_EMPLOYER },
  { component: ManageEmployerList, path: ACCOUNT_VERIFY_EMPLOYER_LIST },

  { component: ManageJobSeekerList, path: ACCOUNT_VERIFY_JOB_SEEKER_LIST },
  { component: ManageJobSeeker, path: ACCOUNT_VERIFY_MANAGE_JOB_SEEKER },

  { component: ManageJobInfo, path: ACCOUNT_VERIFY_MANAGE_JOBS_INFO },
];

export default ACCOUNT_VERIFY_ROUTES;
