
import React, { useEffect ,useState} from 'react';


import ChatContent from '../chatContent/chatContent.js';
import JitsiComponent from './jitsi.js';
import { useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  chatListAdd,
  chatMessageStore,
  setCurrentChat,
  setSocketNotification,
  socketSelector,
} from '../../../redux/slicers/socketSlicer.js';
import { CHAT, CHAT_INDIVIDUAL } from '../../../config/constant/routePathConstant.js';
import { getChatDetailsApi, getChatMessageListApi } from '../../../api/chat/chatApi.js';
import { useSession, useSocket } from '../../../helpers/hooks.js';
import { eventCompletedApi } from '../../../api/jobSeeker/jobSeeker';
import { objectFindCheck, strReduce } from '../../../helpers/jsHelper.js';
import { disableLiveChat } from '../../common/basic.js';
import { enableLiveChat } from './../../common/basic';

const VideoCall = () => {
  const history = useHistory();
  const { sendNotification, notification,receivedMsg } = useSocket();
  const { currentChat = {},preventApiRenders= false,chatList=[] } = useSelector(socketSelector);
  const { from_id, to_id, chat_id, type = '' } = useParams();
  const directChat = useRouteMatch(CHAT_INDIVIDUAL);
  const { state = {} } = useLocation();
  const dispatch = useDispatch();
  const { full_name, user_id } = useSession();
  const [meettype,setMeetType] = useState(false);
  useEffect(() => {
    const url = new URL(window.location.href);
    const params = url.searchParams;
    const type = new URLSearchParams(params).get('type')
    if(type  === 'join_meet') {
        setMeetType(true)
    }
  },[])

  const [skipLimits, setSkipLimits] = useState(0);


  useEffect(() => {
    if (chat_id && directChat) {
       updateChatLimit(true)
    }
  }, [chat_id]);

  useEffect(() => {
    if (state?.to_details) {
      dispatch(setCurrentChat(state));
    } else {
      dispatch(getChatDetailsApi(chat_id));
    }
  }, []);

  useEffect(() => {
    disableLiveChat()
    return ()=>{
      enableLiveChat()
    }
  }, []);

  useEffect(() => {
    if (
      notification?.chat_id === chat_id &&
      notification.type === 'calling' &&
      notification?.status === false
    ) {
      dispatch(setSocketNotification({}));
      handleHang();
    }
  }, [notification]);

  const handleHang = () => {
    history.push(CHAT + '/' + chat_id);
    let new_to_id = user_id === from_id ? to_id : from_id;
    sendNotification({ type: 'calling', status: false, chat_id }, new_to_id);
    if(meettype) {
      dispatch(eventCompletedApi({ from_id, to_id, interview_completed: true }));
    }
  };
  const updateChatLimit = (replace=false) =>{
    if (chat_id && directChat) {
      let limit = 30;
      let skip = skipLimits; 
      dispatch(getChatMessageListApi(chat_id, replace,limit,skip));
      setSkipLimits(skipLimits+30)
    }
  }
  useEffect(() => {
    if (receivedMsg) {
      if (receivedMsg.chat_id) {
        if (!objectFindCheck(chatList, '_id', receivedMsg.chat_id.toString())) {
          dispatch(getChatDetailsApi(receivedMsg.chat_id, (a) => {
            dispatch(chatListAdd({...a.data,last_msg_time:receivedMsg?.msg_time, recent_msg: strReduce(receivedMsg.content)}))
          }));
        }
      }
      dispatch(chatMessageStore(receivedMsg));
    }
  }, [receivedMsg, dispatch]);

  return (
    <div className="messagewholecard">
      <div className="dashboard-parent">
        <div className="chat-container flex">
          <div className="w-75 flex-lg-fill">
            <JitsiComponent
              from_user={full_name}
              from_id={from_id}
              to_id={to_id}
              onHangUp={handleHang}
            />
          </div>
          <div className="w-25 video-chat-content">
            {currentChat?.to_details && <ChatContent  updateChatLimit={updateChatLimit} preventApiRenders={preventApiRenders} videoCall={false} msgLarger />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoCall;
