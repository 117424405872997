import React,{useEffect}from 'react'
import { useHistory } from 'react-router';
import { CHAT } from '../../config/constant/routePathConstant';

const RedirectPage  = ()  => {
    const history = useHistory()
    useEffect(() => {
        // const url = new URL(window.location.href)
        // const params = url.searchParams;
        // const from_id = params.get('from_id');
        // const to_id = params.get('to_id');
        // const type =params.get('type');
        // const chat_id = params.get('chat_id')
        // if(type === 'join_meet'){
        //    history.push(`${CHAT}/video/${chat_id}/${from_id}/${to_id}`);
        //    return
        //   }
        // alert('hello')
    }, [])
    return (
        <div>
            
        </div>
    )
}

export default RedirectPage
