import api from '../helpers/axiosHelper.js';
import {
  ACCOUNT_ACTIVATION_API,
  CHANGE_PASSWORD_API,
  FORGOT_PASSWORD_API,
  LOGIN_API,
  LOGIN_API_ROOT,
  NEW_PASSWORD_API,
  PASSWORD_ACTIVATION_API,
  REGISTER_API,
  RESET_PASSWORD_API,
  GOOGLE_REGISTER_API,
  GOOGLE_LOGIN_API
} from '../config/constant/apiConstant.js';
import {
  devConsoleLog,
  errorToast,
  getUserData,
  navigate,
  redirectRoute,
  sessionDestroy,
  storeLocal,
  successToast,
} from '../helpers/projectHelper.js';

import {
  activationLoading,
  forgetLoading,
  loginLoading,
  resetPasswordLoading,
  sessionFail,
  sessionSuccess,
  signUpLoading,
} from '../redux/slicers/sessionSlice.js';
import { ROLE_NAV, USER_DATA } from '../config/constant/projectConstant.js';
import {
  CHAT,
  JOBSEEKER_DASHBOARD,
  LOGIN,
  RESET_PASSWORD,
  THANKYOU_JOBSEEKER,
  THANKYOU_EMPLOYER,
  EMPLOYER_DASHBOARD,
  WEBSITE_THANKYOU_EMPLOYER,
  WEBSITE_THANKYOU_JOBSEEKER
} from '../config/constant/routePathConstant.js';
import { jobSeekerEventScheduled } from './jobSeeker/deshboard.js';

export const loginApi = (formData, history) => (dispatch) => {
  dispatch(loginLoading(true));
  api()
    .root(LOGIN_API_ROOT)
    .post(LOGIN_API)
    .data(formData)
    .success((a) => {
      const { message, result = {} } = a;
      const { access_token = null } = result;
      successToast({ title: 'Success', msg: message });
      storeLocal(access_token);
      storeLocal(result, USER_DATA);
      storeLocal(true,'alertModal')
      dispatch(sessionSuccess(result));
      storeLocal(result?.isStripeOnboarded || 0,'isStripeOnboarded');
      const path = ROLE_NAV?.[result?.user_type] ?? '';
      if (result.type === 'OldUser') {
        history.push(`${RESET_PASSWORD}?user_email=${result?.user_email}`);
      }

      const url = new URL(window.location.href);
      const params = url.searchParams;

      if (params) {
        redirectRoute(params);
      }
      if (path) {
        window.location.href = path
        return
      }
    })
    .error((e) => {
      const { message: msg = '' } = e;
      devConsoleLog(e);
      dispatch(sessionFail());
      errorToast({ title: msg, msg : '' });
    })
    .send(() => {
      dispatch(loginLoading(false));
    });
};

export const forgetPasswordApi = (formData) => (dispatch) => {
  dispatch(forgetLoading(true));
  api()
    .root(LOGIN_API_ROOT)
    .post(FORGOT_PASSWORD_API)
    .data(formData)
    .success(({ message }) => {
      successToast({ title: 'Success', msg: message });
    })
    .error((e) => {
      const { message = '' } = e ?? {};
      devConsoleLog(e);
      errorToast({ title: 'Failed', msg: message });
    })
    .send(() => {
      dispatch(forgetLoading(false));
    });
};

export const resetPasswordApi = (formData, history) => (dispatch) => {
  dispatch(resetPasswordLoading(true));
  api()
    .root(LOGIN_API_ROOT)
    .post(RESET_PASSWORD_API)
    .data(formData)
    .success(({ message }) => {
      successToast({ title: 'Success', msg: message });
      history.push(LOGIN);
    })
    .error((e) => {
      const { message = '' } = e ?? {};
      devConsoleLog(e);
      errorToast({ title: 'Failed', msg: message });
    })
    .send(() => {
      dispatch(resetPasswordLoading(false));
    });
};
export const newPasswordApi = (formData, history) => (dispatch) => {
  dispatch(resetPasswordLoading(true));
  api()
    .root(LOGIN_API_ROOT)
    .post(NEW_PASSWORD_API)
    .data(formData)
    .success(({ message }) => {
      successToast({ title: 'Success', msg: message });
      // history.push(LOGIN);
    })
    .error((e) => {
      const { message = '' } = e ?? {};
      devConsoleLog(e);
      errorToast({ title: 'Failed', msg: message });
    })
    .send(() => {
      dispatch(resetPasswordLoading(false));
    });
};
export const changePasswordApi = (formData) => (dispatch) => {
  // dispatch(resetPasswordLoading(true));
  api()
    .root(LOGIN_API_ROOT)
    .post(CHANGE_PASSWORD_API)
    .data(formData)
    .success(({ message }) => {
      successToast({ title: 'Success', msg: message });
    })
    .error((e) => {
      const { message = '' } = e ?? {};
      devConsoleLog(e);
      errorToast({ title: 'Failed', msg: message });
    })
    .send(() => {});
};
export const registerApi = (formData, history, captcha, role) => (dispatch) => {
  dispatch(signUpLoading(true));
  api()
    .root(LOGIN_API_ROOT)
    .post(REGISTER_API)
    .data(formData)
    .success(({ message }) => {
      successToast({ title: 'Success', msg: message });
      // setOpenModal(true);
      // history.push(LOGIN);
      if(role === 'employer'){
        window.location.href = WEBSITE_THANKYOU_EMPLOYER
      }else{
        window.location.href = `${WEBSITE_THANKYOU_JOBSEEKER}?email=${formData.user_email}&name=${formData.full_name}`
      }
    })
    .error((e) => {
      const { message = '' } = e ?? {};
      devConsoleLog(e);
      errorToast({ title: 'Failed', msg: message });
    })
    .send(() => {
      dispatch(signUpLoading(false));
      captcha.reset();
    });
};
export const googleRegisterApi = (formData, history) => (dispatch) => {
  dispatch(signUpLoading(true));
  api()
    .root(LOGIN_API_ROOT)
    .post(GOOGLE_REGISTER_API)
    .data(formData)
    .success((a) => {
      const { message, result = {} } = a;
      const { access_token = null } = result;
      successToast({ title: 'Success', msg: message });
      storeLocal(access_token);
      storeLocal(result, USER_DATA);

      if(formData.role === 'employer'){
        window.location.href = `${WEBSITE_THANKYOU_EMPLOYER}?redirect=${EMPLOYER_DASHBOARD}`
      }else if(formData.role == 'job_seeker'){
        window.location.href = `${WEBSITE_THANKYOU_JOBSEEKER}?redirect=${JOBSEEKER_DASHBOARD}`
      }
      
      if (result.type === 'OldUser') {
        history.push(`${RESET_PASSWORD}?user_email=${result?.user_email}`);
      }
    })
    .error((e) => {
      const { message = '' } = e ?? {};
      devConsoleLog(e);
      errorToast({ title: 'Failed', msg: message });
    })
    .send(() => {
      dispatch(signUpLoading(false));
     
    });
};
export const googleLoginApi = (formData, history) => (dispatch) => {
  dispatch(signUpLoading(true));
  api()
    .root(LOGIN_API_ROOT)
    .post(GOOGLE_LOGIN_API)
    .data(formData)
    .success((a) => {
      const { message, result = {} } = a;
      const { access_token = null } = result;
      successToast({ title: 'Success', msg: message });
      storeLocal(access_token);
      storeLocal(result, USER_DATA);
      const path = ROLE_NAV?.[result?.user_type] ?? '';
      if (result.type === 'OldUser') {
        history.push(`${RESET_PASSWORD}?user_email=${result?.user_email}`);
      }
      if (path) {
        window.location.href = path
      }
    })
    .error((e) => {
      const { message = '' } = e ?? {};
      devConsoleLog(e);
      errorToast({ title: 'Failed', msg: message });
    })
    .send(() => {
      dispatch(signUpLoading(false));
     
    });
};
export const activationApi = (formData, history) => (dispatch) => {
  dispatch(activationLoading(true));
  api()
    .root(LOGIN_API_ROOT)
    .post(ACCOUNT_ACTIVATION_API)
    .data(formData)
    .success(({ result,message }) => {
      successToast({ title: 'Success', msg: message });
      if(result){
        const { access_token = null } = result;
        storeLocal(access_token);
        storeLocal(result, USER_DATA);
        storeLocal(true,'alertModal')
        dispatch(sessionSuccess(result));
        const path = ROLE_NAV?.[result?.user_type] ?? '';
        if (result.type === 'OldUser') {
          history.push(`${RESET_PASSWORD}?user_email=${result?.user_email}`);
        }
  
        const url = new URL(window.location.href);
        const params = url.searchParams;
  
        if (params) {
          redirectRoute(params);
        }
        if (path) {
          history.push(path);
        }
      }
      history.push(LOGIN);
    })
    .error((e) => {
      const { message = '' } = e ?? {};
      devConsoleLog(e);
      errorToast({ title: 'Failed', msg: message });
    })
    .send(() => {
      dispatch(activationLoading(false));
    });
};
export const passwordActivationApi = (formData, history) => (dispatch) => {
  dispatch(activationLoading(true));
  api()
    .root(LOGIN_API_ROOT)
    .post(PASSWORD_ACTIVATION_API)
    .data(formData)
    .success(({ message }) => {
      successToast({ title: 'Success', msg: message });
      history.push(LOGIN);
    })
    .error((e) => {
      const { message = '' } = e ?? {};
      devConsoleLog(e);
      errorToast({ title: 'Failed', msg: message });
    })
    .send(() => {
      dispatch(activationLoading(false));
    });
};
// export const changePasswordApi = (formData) => (dispatch) => {
//    // dispatch(resetPasswordLoading(true));
//    api()
//      .root(LOGIN_API_ROOT)
//      .post(CHANGE_PASSWORD)
//      .data(formData)
//      .success(({ message }) => {
//        successToast({ title: 'Success', msg: message });
//      })
//      .error((e) => {
//        const { message = '' } = e ?? {};
//        devConsoleLog(e);
//        errorToast({ title: 'Failed', msg: message });
//      })
//      .send(() => {});
//  };
export const scheduleInterviewApi = (formData) => (dispatch) => {
  const { user_id } = getUserData();
  const participantId = {
    participant_id: user_id,
  };
  api()
    .root(LOGIN_API_ROOT)
    .post('/auth/event-attend')
    .data(formData)
    .success(({ message }) => {
      successToast({ title: 'Success', msg: message });

      navigate(JOBSEEKER_DASHBOARD);
    })
    .error((e) => {
      const { message = '' } = e ?? {};
      devConsoleLog(e);
      errorToast({ title: 'Failed', msg: message });
    })
    .send(() => {
      dispatch(
        jobSeekerEventScheduled({
          participant_id: participantId.participant_id,
          participant_attend: 'all',
        }),
      );
    });
};

export const logoutApi = () => () => {
  sessionDestroy();
};
