import { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useHistory, useRouteMatch } from 'react-router-dom';
import Mytimer from '../../common/counter';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { getUnreadCount } from '../../../api/chat/chatApi';
import { EmpUserModalUpdate, getNotifyCountApi } from '../../../api/employer/employer.js';
import { getNotificationApi, updateNotificationApi } from '../../../api/notification.js';
import { EMPLOYER_HOW_IT_WORKS } from '../../../config/constant/apiConstant.js';
import { LOGO_IMG } from '../../../config/constant/imageConstant.js';
import {
  CHAT,
  EMPLOYER_DASHBOARD,
  EMPLOYER_POST_A_JOB,
  EMPLOYER_VIEW_ALL_APPLICANTS,
  EMPLOYER_VIEW_INVOICE,
  EMPLOYER_VIEW_JOBOFFER,
  EMPLOYER_HIRED_EMPLOYER_LIST,
  EMPLOYER_SUBSCRIPTION_PRICING_PAGE,
  WEBSITE_HOW_IT_WORKS_EMPLOYERS,
} from '../../../config/constant/routePathConstant.js';
import { useSession, useSocket } from '../../../helpers/hooks.js';
import { countFixer, infoToast, navigate, sessionDestroy } from '../../../helpers/projectHelper.js';
import { employerDashboardSelector } from '../../../redux/slicers/employer/empDashboardSlice.js';
import {
  setSocketNotification,
  socketSelector,
  unreadCountSelector,
} from '../../../redux/slicers/socketSlicer';
import { subscriptionSelector } from '../../../redux/slicers/sessionSlice';
import { NewUserAlertPopup, SubscriptionAlertPopup, TrailAlert } from './subscription';
import Banner from '../../../assets/images/bfbanner.png';
import MobileBanner from '../../../assets/images/mobilebfbanner.png';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import EmployerSideBar from './sideBar';
import MenuIcon from '@mui/icons-material/Menu';
import PostJobIcon from "../../../assets/images/post-A-job-employer/add.png"
const Header = () => {
  const [drawer,setDrawer] = useState(false);
  const match = useRouteMatch(EMPLOYER_SUBSCRIPTION_PRICING_PAGE)
  const [flag, setFlag] = useState(false);
  const [show, setShow] = useState();
  const [alert, setAlert] = useState(false);
  const [mbMenu, setMbMenu] = useState(false)
  const location = useLocation();
  const { pathname } = location;
  const unreadCount = useSelector(unreadCountSelector);
  const dropdownref = useRef(null);
  const dispatch = useDispatch();
  const { user_id = '' } = useSession();
  const { is_subscription_active = null, subscription_plan  , user_modal_alert = false} = useSelector(subscriptionSelector);
  const { notification = {}, disconnect = null } = useSocket();
  const { appNotification = {} } = useSelector(socketSelector);
  const { count = '', json = [] } = appNotification;
  const search = useLocation().search;
  const name = new URLSearchParams(search).get('q');
  const history = useHistory();
  const time = new Date(2022, 10, 2);
  const [popup, setPopup] = useState(false);
  time.setSeconds(time.getSeconds());
  var a = moment([2022, 11, 2]);
  var b = moment([2022, 10, 26]);
  var c = a.diff(b, 'days'); // 1

  const [windowDimenion, detectHW] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  });
  
  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    });
  };
  const toggleDrawer = (a) => {
    setDrawer(a)
  }
  useEffect(() => {
      const popup_flag  = localStorage.getItem('popup_flag');
     
      if(popup_flag === false || popup_flag === null) {
        if(user_modal_alert){
          setAlert(true)
        }
        
      }
  },[user_modal_alert])
  
  useEffect(() => {
    window.addEventListener('resize', detectSize);

    return () => {
      window.removeEventListener('resize', detectSize);
    };
  }, [windowDimenion]);
  useEffect(() => {
    const data = {
      employer_id: user_id,
    };
    const obj = {
      userid: user_id,
    };
    dispatch(getUnreadCount());
    dispatch(getNotifyCountApi(data));
    dispatch(getNotificationApi(obj));
  }, []);

  useEffect(() => {
    if (is_subscription_active === false) {
      if (name === 'basic') {
        setShow(true);
      }
    }
  }, [name]);

  useEffect(() => {
    if (notification.type) {
      if (notification?.msg) {
        infoToast({ title: 'notification', msg: notification.msg });
      }
      dispatch(setSocketNotification({}));
    }
  }, [notification]);
  useEffect(() => {
    const pageClickEvent = (e) => {
      // If the active element exists and is clicked outside of
      if (dropdownref.current !== null && !dropdownref.current.contains(e.target)) {
        setFlag(!flag);
      }
    };

    // If the item is active (ie open) then listen for clicks
    if (flag) {
      window.addEventListener('click', pageClickEvent);
    }

    return () => {
      window.removeEventListener('click', pageClickEvent);
    };
  }, [flag, dropdownref]);
  const getLink = (val) => {
    switch (val) {
      case 'InvoiceModule':
        return EMPLOYER_VIEW_INVOICE;
      case 'ApplyJob':
        return EMPLOYER_VIEW_ALL_APPLICANTS;
      case 'AcceptJobOffer':
        return EMPLOYER_VIEW_JOBOFFER;
      case 'DeclineJobOffer':
        return EMPLOYER_VIEW_JOBOFFER;
      case 'payment_auto_debit_alert':
        return EMPLOYER_VIEW_INVOICE;
      case 'JobResignation':
        return EMPLOYER_HIRED_EMPLOYER_LIST;
    }
  };
  const handleUnread = (val) => {
    dispatch(updateNotificationApi(val));
  };
  const handleLogout = () => {
    sessionDestroy();
    disconnect?.();
  };
  const handleShow = (a) => {
    setShow(a);
  };
  const handleChat = () => {
    if (!is_subscription_active) {
      setShow(true);
    } else {
      navigate(CHAT);
    }
  };
  const width = window.innerWidth;
  const breakpoint = 767;
  useEffect(() => {
    {
      width > breakpoint ? setMbMenu(false) : setMbMenu(true);
    }
  }, []);
  const handleMenu = () => {
    setDrawer(!drawer)
  };

  const handleTrigger = () => {
    let data = { user_id }
    dispatch(EmpUserModalUpdate(data,setAlert))
  }
  const handleClose = () => {
    localStorage.setItem('popup_flag' , true)
    setAlert(false)
  }
  return (
    <header className="employer_header">
      <TrailAlert />
      <SwipeableDrawer
        anchor="left"
        open={drawer}
        onClose={() => toggleDrawer(false)}
        onOpen={() => toggleDrawer(true)}
      >
           <EmployerSideBar />
      </SwipeableDrawer>
      <div className="container-fluid">
        <nav className="navbar navbar-expand-md">
          <Link className="navbar-brand" to={EMPLOYER_DASHBOARD}>
            <img className="img-fluid" src={LOGO_IMG} alt="logo" />
          </Link>
          <div
          className="toggle-menu d-flex justify-content-end"
          style={{ zIndex: '999',top: "15px", right: '6px', lineHeight: '12px' }}
          onClick={handleMenu}
        >
          <MenuIcon />
        </div>
          <div className="collapse navbar-collapse" id="collapsibleNavbar">
            <ul className="employer-nav nav navbar-nav navbar-right">
           
              <Link
                to={EMPLOYER_POST_A_JOB}
                className={
                  'font-weight-bold text-white  mr-2 button-theme d-flex align-items-center post-job'
                }
              >
                {/* <i
                  class="iconButton rounded-pill  white-bg bg-theme-hover p-3 fa fa-plus pr-1"
                  style={{ height: '14px', width: '30px' }}
                ></i> */}
                <div className='icon-div'><img src={PostJobIcon}/></div>
                <span className="pl-1 pr-1" style={{ color:"#004C70" }}>
                  Post a job 
                </span>
              </Link>
              <a
                href={EMPLOYER_HOW_IT_WORKS}
                target="_blank"
                className={' font-weight-bold p-2 px-3 mr-2 bg-theme'}
                style={{color: "#004C70",background:"none"}}
              >
                How It Works
              </a>
            
                  <li className="nav-item">
                <a href={EMPLOYER_DASHBOARD}>
                  <i class="iconButton text-white bg-theme bg-theme-hover p-3 fa fa-home pr-1"></i>
                </a>
                </li>
            
              {' '}{' '}
              <li className="nav-item">
                <Link onClick={() => handleChat()}>
                  <i
                    className={`iconButton text-white  bg-theme-hover p-3 fa fa-commenting ${
                      is_subscription_active ? 'bg-theme' : 'bg-lighter'
                    }`}
                  />
                  {unreadCount ? (
                    <div className="notification-header-num">{countFixer(unreadCount)}</div>
                  ) : (
                    ''
                  )}
                </Link>
              </li>
              {/* } */}
              <li className="nav-item nav-notifications">
                <a>
                  {' '}
                  <i
                    className="fas fa-bell  text-white bg-theme bg-theme-hover iconButton"
                    onClick={() => {
                      setFlag(true);
                    }}
                  />
                </a>
                {count ? <div className="notification-header-num">{countFixer(count)}</div> : ''}

                <div
                  className="nav-notification-options "
                  ref={dropdownref}
                  style={{
                    display: flag ? 'block' : 'none',
                    width: '29rem',
                    left: '-27rem',
                    top: '42px',
                    height: '48vh',
                    padding: '73%',
                  }}
                >
                  {count > 0 ? (
                    <>
                      {json?.result
                        ?.filter((e) => {
                          if (e.notificationObj.type != 'calling') {
                            return e;
                          }
                        })
                        .map((ele, i) => {
                          return (
                            <Link
                              key={'header-menu' + i}
                              to={getLink(ele?.notificationObj?.type)}
                              className="convers dropdownoptions d-flex justify-content-between align-items-center "
                              style={{ borderBottom: '1px solid grey' }}
                            >
                              <div
                                className="dropdownoptions"
                                onClick={() => {
                                  handleUnread(ele?._id);
                                  setTimeout(() => {
                                    setFlag(false);
                                  }, 100);
                                }}
                              >
                                <div
                                  className="pb-3 d-flex flex-column justify-content-start align-items-start"
                                  style={{ width: '80%' }}
                                >
                                  <li
                                    className="pb-0 mb-0"
                                    style={{ fontSize: '14px', listStyle: 'square' }}
                                  >
                                    <strong>{ele?.notificationObj?.title}</strong>
                                  </li>
                                  <small style={{ fontSize: '16px' }}>
                                    {ele?.notificationObj?.msg}
                                  </small>
                                </div>
                                <div style={{ color: 'grey' }}>
                                  {moment(ele?.created_time).format('hh:mm A')}
                                </div>
                              </div>
                            </Link>
                          );
                        })
                        .reverse()}
                    </>
                  ) : (
                    <div style={{ textAlign: 'center', padding: '3%', fontSize: '20px' }}>
                      No Notifications
                    </div>
                  )}
                </div>
              </li>
              <li className="nav-item" onClick={handleLogout}>
                <a>
                  <i className="iconButton p-3 fas bg-theme-hover text-white bg-theme fa-sign-out-alt" />
                </a>
              </li>
            </ul>
          </div>
          
        </nav>
        {mbMenu ? <div className="mb-1" id="">
            <ul className="employer-nav nav mobile_nav">
              <Link
                to={EMPLOYER_POST_A_JOB}
                className={
                  'font-weight-bold text-white px-3 mr-2 button-theme d-flex align-items-center post-job'
                }
              >
                <i
                  class="iconButton rounded-pill  white-bg bg-theme-hover fa fa-plus"
                  style={{ height: '14px', width: '30px' }}
                ></i>
                <span className="pl-1" style={{ fontSize: '13px', color: '#004C70' }}>
                  Post a job (It's free)
                </span>
              </Link>
              {/* <a
                href={EMPLOYER_HOW_IT_WORKS}
                className={' font-weight-bold p-2 px-3 mr-2 bg-theme'}
                style={{ color: '#004C70', background: 'none' }}
              >
                How It Works
              </a> */}
              {is_subscription_active && (
                <a href={EMPLOYER_DASHBOARD}>
                  <i class="iconButton text-white bg-theme bg-theme-hover fa fa-home"></i>
                </a>
              )}
              | {/* {is_subscription_active && */}
              <li className="nav-item">
                <Link onClick={() => handleChat()}>
                  <i
                    className={`iconButton text-white  bg-theme-hover fa fa-commenting ${
                      is_subscription_active ? 'bg-theme' : 'bg-lighter'
                    }`}
                  />
                  </Link>
                  {unreadCount ? (
                    <div className="notification-header-num">{countFixer(unreadCount)}</div>
                  ) : (
                    ''
                  )}
                
              </li>
              {/* } */}
              <li className="nav-item nav-notifications">
                <a>
                  {' '}
                  <i
                    className="fas fa-bell  text-white bg-theme bg-theme-hover iconButton"
                    onClick={() => {
                      setFlag(true);
                    }}
                  />
                </a>
                {count ? <div className="notification-header-num">{countFixer(count)}</div> : ''}

                <div
                  className="nav-notification-options "
                  ref={dropdownref}
                  style={{
                    display: flag ? 'block' : 'none',
                    width: '29rem',
                    left: '-27rem',
                    top: '42px',
                    height: '48vh',
                    padding: '73%',
                  }}
                >
                  {count > 0 ? (
                    <>
                      {json?.result
                        ?.filter((e) => {
                          if (e.notificationObj.type != 'calling') {
                            return e;
                          }
                        })
                        .map((ele, i) => {
                          return (
                            <Link
                              key={'header-menu' + i}
                              to={getLink(ele?.notificationObj?.type)}
                              className="convers dropdownoptions d-flex justify-content-between align-items-center "
                              style={{ borderBottom: '1px solid grey' }}
                            >
                              <div
                                className="dropdownoptions"
                                onClick={() => {
                                  handleUnread(ele?._id);
                                  setTimeout(() => {
                                    setFlag(false);
                                  }, 100);
                                }}
                              >
                                <div
                                  className="pb-3 d-flex flex-column justify-content-start align-items-start"
                                  style={{ width: '80%' }}
                                >
                                  <li
                                    className="pb-0 mb-0"
                                    style={{ fontSize: '14px', listStyle: 'square' }}
                                  >
                                    <strong>{ele?.notificationObj?.title}</strong>
                                  </li>
                                  <small style={{ fontSize: '16px' }}>
                                    {ele?.notificationObj?.msg}
                                  </small>
                                </div>
                                <div style={{ color: 'grey' }}>
                                  {moment(ele?.created_time).format('hh:mm A')}
                                </div>
                              </div>
                            </Link>
                          );
                        })
                        .reverse()}
                    </>
                  ) : (
                    <div style={{ textAlign: 'center', padding: '3%', fontSize: '20px' }}>
                      No Notifications
                    </div>
                  )}
                </div>
              </li>
              <li className="nav-item" onClick={handleLogout}>
                <a>
                  <i className="iconButton fas bg-theme-hover text-white bg-theme fa-sign-out-alt" />
                </a>
              </li>
            </ul>
          </div>:<></>}
        <SubscriptionAlertPopup show={show} setShow={handleShow} />
        <NewUserAlertPopup show={alert} setShow={handleShow} close={handleClose} trigger={handleTrigger}  />
         {/* {!match && <>{(!is_subscription_active || subscription_plan === 'month') && (
          <a href="/employer/subscription-pricing">
            <div className={`halloween-popup ${popup ? 'hide' : ''}`}>
              <div className="main-section d-flex">
                <div>
                  {' '}
                  <img src={windowDimenion?.winWidth > 500 ? Banner : MobileBanner} />
                </div>
                <div
                  className="first-div"
                  onClick={(e) => {
                    e.preventDefault();

                    setPopup(true);
                  }}
                >
                  {' '}
                  <span className="close-btn">X</span>{' '}
                </div>
                <div className='counter-sec'>
             <p style={{fontSize:'15px'}}>OFFER ENDS TODAY!</p>
             <Mytimer expiryTimestamp={time} flag={true}/>
             <div className="faq-profile-button">
                 <a className="button" href='/employer/promo'>Save now!</a>
                         
              </div>
          </div> 
              </div>
            </div>
          </a>
        )}</>} */}
      </div>
    </header>
  );
};

export default Header;
