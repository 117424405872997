import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { getBoard, moveCards, moveList } from '../../actions/board';
import { Box, CircularProgress } from '@material-ui/core';
import BoardTitle from '../board/BoardTitle';
import BoardDrawer from '../board/BoardDrawer';
import List from '../list/List';
import CreateList from '../board/CreateList';
import Members from '../board/Members';
import { getUserData } from '../../../../helpers/projectHelper';
import HomeIcon from '@mui/icons-material/Home';

const Board = ({ match }) => {
  let history = useHistory();
  // const { user_id } = getUserData();
  const isAuthenticated = localStorage.getItem('token');
  const board = useSelector((state) => state.board.board);
  // const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(getBoard(match.params.id));
    let data = {
      board_id: match.params.board_id,
    };
    dispatch(getBoard(data));
  }, [dispatch, match.params.id]);

  useEffect(() => {
    if (board?.title) document.title = board.title + ' | Task Management';
  }, [board?.title]);

  if (!isAuthenticated) {
    history.push('/login');
    window.location.reload();
    // return <Redirect to="/" />;
  }

  const onDragEnd = (result) => {
    const { source, destination, draggableId, type } = result;
    if (!destination) {
      return;
    }
    if (type === 'card') {
      let data = {
        id: draggableId,
        fromId: source.droppableId,
        toId: destination.droppableId,
        toIndex: destination.index,
        boardId: match.params.board_id,
        user_id: getUserData().user_id,
      };
      dispatch(moveCards(data));
    } else {
      let datas = {
        id: draggableId,
        toIndex: destination.index,
        boardId: match.params.board_id,
      };
      dispatch(moveList(datas));

      // dispatch(moveList(draggableId, { toIndex: destination.index }));
    }
  };

  return !board ? (
    <Fragment>
      <Box className="board-loading">
        <CircularProgress />
      </Box>
    </Fragment>
  ) : (
    // <div
    //   className="board-and-navbar"
    //   style={{
    //     backgroundImage:
    //       'url(' +
    //       (board.backgroundURL
    //         ? board.backgroundURL
    //         : 'https://images.unsplash.com/photo-1598197748967-b4674cb3c266?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2689&q=80') +
    //       ')',
    //   }}
    // >
    <div
    className="board-and-navbar"
    style={{
      background:"#d3ebeb"
      // backgroundImage:
      //   'url(' +
      //   (board.backgroundURL
      //     ? board.backgroundURL
      //     : 'https://softwareplant.com/wp-content/uploads/2019/05/trello-portfolio-management.png') +
      //   ')',
    }}
  >
      <section className="board">
        <div className="board-top">
          <div className="board-top-left">
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: '#ffffff',
                textDecoration: 'none',
              }}
            >
              <Link
                key={board._id}
                to={`/task-management/employer/dashboard`}
                className="board-card"
                style={{ color: '#ffffff' }}
              >
                <HomeIcon fontSize="large"/>
              </Link>
            </div>
            <BoardTitle board={board} />
            <Members />
          </div>
          <BoardDrawer />
        </div>-
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="all-lists" direction="horizontal" type="list">
            {(provided) => (
              <div className="lists" ref={provided.innerRef} {...provided.droppableProps}>
                {board.lists.map((listId, index) => (
                  <List key={listId} listId={listId} index={index} />
                ))}
                {provided.placeholder}
                <CreateList />
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </section>
    </div>
  );
};

export default Board;
