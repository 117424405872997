import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { getBoards, delBoards } from '../../actions/board';
import CreateBoard from '../other/CreateBoard';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getUserData } from '../../../../helpers/projectHelper';
import { TASK_MANAGEMENT } from '../../../../config/constant/routePathConstant';
import Chip from '@mui/material/Chip';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@mui/material/TextField';
import _ from 'lodash';
import usePagination from './usePagination';
import Pagination from '@material-ui/lab/Pagination';
import { alpha } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import DeleteIcon from '@mui/icons-material/Delete';
// import IconButton from '@material-ui/core/IconButton';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    padding: theme.spacing(0.5),
  },
  roots: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
  },
  chip: {
    margin: theme.spacing(0.5),
    backgroundColor: '#e5e5e5',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
    pointerEvents: 'none',
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

const StyledChip = withStyles((theme) => ({
  root: {
    maxWidth: 100,
    backgroundColor: '#e5e5e5',
  },
  label: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))(Chip);

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    fontSize: 16,
    width: 'auto',
    padding: '10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}))(InputBase);

const Dashboard = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  let history = useHistory();
  const isAuthenticated = localStorage.getItem('token');
  const boards = useSelector((state) => state.board.boards);
  const loading = useSelector((state) => state.board.dashboardLoading);
  const [boardDatas, setBoardDatas] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [boardId, setBoardId] = useState();

  useEffect(() => {
    setBoardDatas(boards);
  }, [boards]);

  const searchUserOptions = async (inputValue) => {
    const re = new RegExp(_.escapeRegExp(inputValue), 'i');
    const results = boards?.filter((object) => {
      if (re.test(object.title)) {
        console.log('object', object.title);
        // setuserid(object.title);
        return true;
      }
    });
    console.log('idss', results[0]?.user);

    await setBoardDatas(results);
    return results;
  };

  let [page, setPage] = useState(1);
  const PER_PAGE = 6;

  const count = boardDatas !== undefined ? Math.ceil(boardDatas.length / PER_PAGE) : 0;
  const _DATA = usePagination(boardDatas, PER_PAGE);

  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
    window.scrollTo({
      // top: 580,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    let data = {
      user_id: getUserData().user_id,
    };
    dispatch(getBoards(data));
  }, [dispatch]);

  useEffect(() => {
    document.title = 'Your Boards | Task Management';
  }, []);

  if (!isAuthenticated) {
    // return <Redirect to="/" />;
    history.push('/login');
    window.location.reload();
  }

  const deleteModal = (e) => {
    setOpen(true);
    setBoardId(e);
    // dispatch(delBoards({ user_id: getUserData().user_id, board_id: e }))
  };
  const handleClose = () => {
    setOpen(false);
  };

  const deleteboards = () => {
    // setBoardId(e);
    dispatch(delBoards({ user_id: getUserData().user_id, board_id: boardId }));
    setOpen(false);
  };
  return (
    <div className="dashboard-and-navbar task-job-dash">
      <section className="dashboard" >
        <p styled={{ fontSize: '17px' }}>
          A free tool that you can use if you’d like to. VS Task Management is optional and not
          compulsory to use.
        </p>
        <br />
        <h1 className='name-text' tyle={{ fontWeight: 700, fontSize: '25px !important' }}>
          Welcome {getUserData().full_name && getUserData().full_name} -{' '}
          {getUserData().user_type && getUserData().user_type == 'job_seeker'
            ? 'staff member'
            : getUserData().user_type}
        </h1>
        <br />
        <Paper component="form" className={classes.roots}>
          <InputBase
            className={classes.input}
            placeholder="Search boards here"
            inputProps={{ 'aria-label': 'search Boards here' }}
            onChange={(e) => searchUserOptions(e.target.value)}
          />
          <IconButton type="submit" className={classes.iconButton} aria-label="search">
            <SearchIcon />
          </IconButton>
        </Paper>
        <h2 style={{ fontWeight: 600, fontSize: '25px' }}>Your Boards</h2>
        {loading && <CircularProgress className="dashboard-loading" />}
        <div className="boards" style={{ height: 'max-content' }}>
          {boards &&
            boards !== null &&
            // boardDatas?.map((board) =>
            _DATA.currentData()?.map((board) =>
              board !== null ? (
                // <Link
                //   key={board?._id}
                //   to={`${TASK_MANAGEMENT}/${getUserData().user_type}/board/${board?._id}`}
                //   className="board-card"
                // >
                <div key={board?._id} className="board-card">
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      fontSize: '20px',
                      fontWeight: 600,
                    }}
                  >
                    <Link
                      key={board?._id}
                      to={`${TASK_MANAGEMENT}/${getUserData().user_type}/board/${board?._id}`}
                      style={{ color: '#ffffff', textDecoration: 'underline' }}
                      className="board-title-card"
                    >
                      {board?.title}
                    </Link>
                    <IconButton
                      style={{ cursor: 'pointer' }}
                      onClick={() => deleteModal(board._id)}
                    >
                      <DeleteIcon style={{ color: '#ffffff' }} />
                    </IconButton>
                  </div>
                  {board?.lists.map((e) => (
                    <div style={{ margin: '4px 4px' }}>
                      {e.archived == false ? (
                        <Tooltip title={`${e.title} - ${e.cards.length}`}>
                          <StyledChip
                            size="small"
                            style={{ backgroundColor: '#e5e5e5' }}
                            key={`${e.title} - ${e.cards.length}`}
                            label={`${e.title} - ${e.cards.length}`}
                            className={classes.chip}
                          />
                        </Tooltip>
                      ) : (
                        ' '
                      )}
                    </div>
                  ))}
                </div>
              ) : (
                ' '
              ),
            )}
          {getUserData().user_type && getUserData().user_type == 'job_seeker' ? (
            ''
          ) : (
            <CreateBoard />
          )}
        </div>
      </section>
      {boardDatas && boardDatas.length ? (
        <p
          style={{
            width: '100%',
            margin: '10px 0px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Pagination
            count={count}
            size="large"
            page={page}
            color="primary"
            onChange={handleChange}
          />
        </p>
      ) : !loading ? (
        <p
          style={{
            display: 'flex',
            color: '#7a7a79',
            minHeight: '400px',
            justifyContent: 'center',
            margin: '0 auto',
            width: '50%',
            flexWrap: 'wrap',
            textAlign: 'center',
          }}
        >
          {getUserData().user_type == 'job_seeker' ? (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div>
                <AssignmentLateIcon style={{ height: '50px', fontSize: '50px' }} />
              </div>
              <br />
              <div>
                When you get hired by an employer. Some employers might assign you tasks via
                VirtualStaff.ph task management. However, this is not compulsory, and we would
                notify you if your employer assigns you tasks here
              </div>
            </div>
          ) : (
            `No Boards Available`
          )}
        </p>
      ) : (
        ' '
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography id="modal-modal-title" component="h5">
            Are you sure you want to delete this board?
          </Typography>
          <br/>
          <div>
            <Button
              variant="contained"
              color="primary"
              style={{ paddding: '20px', backgroundColor:'#336699'   }}
              onClick={deleteboards}
            >
              Delete
            </Button>
            &nbsp;
            <Button variant="contained"   style={{ paddding: '20px', backgroundColor:'#336699'   }} color="primary" onClick={handleClose}>
              Cancel
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default Dashboard;
