import { TITLE_PREFIX } from '../config/constant/projectConstant.js';
import moment from 'moment';
import draftToHtml from 'draftjs-to-html';
import { checkJson } from './projectHelper.js';
export const getFullDayFormat = (a) => moment(a).format('MMM DD,YYYY')
export const setTitle = (title) => {
   if (title) {
      document.title = title + ' - ' + TITLE_PREFIX;
   }
};
export const arrayRemoveIndex = (arr, ind) => arr.filter((_, index) => index !== ind);
export const arrayRemove = (arr, key, val) => arr.filter(a => a[key] !== val);

export const checkValueExists = (a={})=> Object.entries(a).some(([k,v])=>!!v)

export const reload = () => window.location.reload();

export const parseFloatNaN = a => isNaN(a) || a === '' ? 0 : parseFloat(a);

export const spaceReduce = (a) => a ? a.split(' ').join('_') : '';
export const spaceReverse = (a) => a ? a.split('_').join(' ') : '';

export const objectFind = (a, key, select) => {
   const res = a.find(i => i[key] === select);
   if (res) {
      return res;
   }
   return {};
};
export const ObjectFilter = (a,key,arr) => {
  const res  = a.filter(i => i[key] === arr.map(e => e[key]))
  return res;
}

export const objectGetValue = (a, key, select, value) => {
   const res = a.find(i => i[key] === select);
   if (res) {
      return res?.[value] ?? null;
   }
   return null;
};

export const objectFindCheck = (a, key, select) => a.findIndex(i => i[key] === select) > -1;

export const objectFindUpdate = (a = null, key, select, res) => {
   if (!a) {
      return null;
   }
   let arr = [...a];
   let ind = arr.findIndex(i => i[key] === select);
   arr[ind] = { ...arr[ind], ...res };
   return [...arr];
};

export const arrayUpsert = (data = [], key, value, obj) => {
   let newData = data;
   if (objectFindCheck(data, key, value)) {
      newData = objectFindUpdate(data, key, value, obj);
   } else {
      newData.unshift(obj);
   }
   return newData;
};
export const lookupArray = array => Object.assign({}, ...array.map(i => ({ [i.value]: i.text })));
export const arrayMapKeyValue = (arr,key,value) => arr.map(i => ({[key]:i[value]}));
export const optionArray = (array, val, text) => array.map(i => ({ value: i[val], text: i[text], ...i }));
export const optionAutoComplete = (array = [], label) => array.map(i => ({label:i[label] ?? null})).filter(i => i.label)
export const arrayAutoComplete = (array = []) => array.map(i => ({label:i}));

export const arrayToOption = (arr = []) => arr.flatMap((val, ind) => (val ? { text: val, value: ind } : []));

export const timeRender = (a = null) => a ? moment(a).format('DD-MMM-YYYY hh:mm A') : null;

export const capitalize = a => a ? a.toString().split('')[0].toUpperCase() + a.slice(1) : '';

export const milliToAge = (a) => Math.round(moment.duration(a).asYears());
export const dateRender = (a) => moment(a).format('DD-MM-YYYY');

export const milliToYear = (a) => moment(a).format('YYYY');
export const createdTime = (a) => moment(a).format('MMM DD, YYYY ');
export const milliToDate = (a) => {
  if(a === undefined || a === null) return null;
  return moment(a).format('YYYY-MM-DD')
  }
export const utcConvert = (a) => {
  if(a === undefined || a === null) return null;
  return moment(a).utc().format('YYYY-MM-DD')
  }

export const milliToDateSlashFormat = (a) => moment(a).format('YYYY/MM/DD');

export const updateToArrayLoop = (a = [], obj) => a.map(i => Object.assign(i, obj));
export const freezeClone = (a = {}) => JSON.parse(JSON.stringify(a));
export const sum = (a, key) => (a.reduce((acc, b) => {
   if (b[key]) {
      acc = acc + parseFloatNaN(b[key]);
   }
   return acc;
}, 0)).toFixed(2);

export const href = link => {
   window.open(link);
};
export const hashOpen = (link = null) => {
   window.open(`#${link}`);
};

export const scrollToBottom = (elem) => {
   if (elem?.scrollHeight) {
      elem.scrollTop = elem.scrollHeight;
   }
};

export const duplicateRemove = (arr, key) => {
   return arr.map(a => a[key]).filter((a, ind, ar) => ind === ar.indexOf(a)).reduce((acc, curr) => (acc.push({ [key]: curr }), acc), []);
};

export const NumGen = (length) => Array.from(Array(length), (_, ind) => ind);

export const timeRandomName = (a = 'upload') => {
   const suf = moment().format('DD_MM_YYYY_HH_mm') + Math.round(Math.random() * 100000000).toString();
   return a + '_' + suf;
};

export const strReduce =(str,length=30)=>{
   return str.substr(0,length)
}

export const  monthToMilliseconds= (a) =>{
   const monthToMilli = moment.duration(moment.duration(a,'m').asMilliseconds());
return monthToMilli._milliseconds}


export const  yearToMilliseconds= (a) => parseInt(moment(a).format('x'))
  
export const ageCalculate = (a) => {
   const data = milliToDate(a)
  return moment().diff(data, 'years');
}

export const retrieveImageFromClipboardAsBlob = (pasteEvent, callback)=>{
   if(pasteEvent.clipboardData === false){
      if(typeof(callback) == "function"){
         callback(undefined);
      }
   };

   var items = pasteEvent.clipboardData.items;

   if(items === undefined){
      if(typeof(callback) == "function"){
         callback(undefined);
      }
   };

   for (var i = 0; i < items.length; i++) {
      // Skip content if not image
      if (items[i].type.indexOf("image") === -1) continue;
      // Retrieve image on clipboard as blob
      var blob = items[i].getAsFile();

      if(typeof(callback) == "function"){
         callback(blob);
      }
   }
}


export const milliToDat = (a) => moment(a).format('MMMM DD, YYYY');

export const milliToMonthAndYear = (a) => moment(a).format('MMMM, YYYY');

export const timeRenderStrToDate = (a = null) => a ? moment(a,"x").format('DD-MMM-YYYY') : null;
export const timeRenderStrToTime = (a = null) => a ? moment(a,"x").format('hh:mm A') : null;

export const dateToMilli = (a) => {
   const ts = moment(a).unix()
   const s = moment.unix(ts)
   return s;
}

export const hourString = (a) => {
   if(a.toString().includes(".")) {
      const s = a.toString().split(".")
   return s[0]+" Hour"+" "+s[1]+" Min"
   } else {
      return a+" Hour";
   }
   
}
export const timeActivityRender = (a = null) => a ? moment(a).format('MMM DD, YYYY hh:mm A') : null;

export const convertIdTypeString = (str) => {
 
    if(str === "national_id"){
      return "National ID"
    }
    if(str === "driver_license"){
      return "Driving License"
    }
    if(str === "driver_lisense"){
      return "Driving License"
    }
    if(str === "passport"){
      return "Passport"
    }
    
  }

   export const convertAcessLevel = (str) => {
      if(str === "super_admin"){
        return "Super Admin";
      }
      if(str === "admin"){
        return "Account Verifier";
      }
      if(str === "customer_support"){
        return "Customer Support";
      }
      if(str === "account_verifier"){
        return "Account Verifier";
      }
      if(str === "accountant"){
        return "Account Verifier";
      }
      if(str === "marketing"){
        return "Marketing";
      }
      if(str === "employer"){
        return "Employer";
      }
   }

   export const convertNotification = (str) => {
      if (str === 'job_post_applicants_update') {
        return 'Job Post Applicants update';
      }
      if (str === 'new_message_applicant_alert') {
        return 'New Message Applicant Alert';
      }
      if (str === 'new_message_workers_alert') {
        return 'New Message Workers Alert';
      }
      if (str === 'weekly_newsletter') {
        return 'Weekly News Letters';
      }
      if (str === 'Announcements_or_Promos') {
        return 'Announcement or Promos';
      }
      if (str === 'job_offer_update') {
        return 'Job Offer Update';
      }
      if (str === 'payment_invoice') {
        return 'Payment Invoice';
      }
      if (str === 'weekly_invoice_alert') {
        return 'Weekly Invoice Alert';
      }
      if (str === 'auto_payment_alert') {
        return 'Auto Payment Alert';
      }
      if (str === 'weekly_invoice_rem_alert') {
        return 'Weekly Invoice Reminder Alert';
      }
      if (str === 'support_ticket_creation') {
        return 'Support Ticket Creation';
      }
      if (str === 'support_ticket_update') {
        return 'Support Ticket Update';
      }
      if (str === 'job_offer') {
        return 'Job Offer';
      }
      if (str === 'new_msg_emp_alert') {
        return 'New Message Employer Alert';
      }
      if (str === 'weekly_work_log_final_rem') {
        return ' Weekly Work Log Finalization Reminder  ';
      }
      if (str === 'support_ticket_confirmation') {
        return 'Support Ticket Confirmation';
      }
      if (str === 'salary_status') {
        return 'Salary Status';
      }
      if (str === 'support_ticket_update') {
        return 'Support Ticket Update';
      }
      if (str === 'new_job_alert') {
        return 'New job Alert';
      }
    };
  
    export const disableNotification = [
      'job_offer',
      'new_msg_emp_alert',
      'weekly_work_log_final_rem',
      'salary_status',
      'support_ticket_confirmation',
      'support_ticket_update',
      'job_offer_update',
      'auto_payment_alert',
      'weekly_invoice_alert',
      'payment_invoice',
      'weekly_invoice_rem_alert',
      'support_ticket_creation',
      'support_ticket_update',
      'new_job_alert',
    ]; 

    export const duplicateRemoveArrayObj = (ar,key) =>{
      const res = [];
      ar?.forEach((a,i)=>{
        const ind = ar.findIndex(k=> k[key] === a[key])      
        if(ind === i){
            if(Object.keys(a).length > 0){
                res.push(a)
              }
          
        }
      })
      return res
    } 

   export const toFixedConvert = (a = "0") => {
      return Number(a).toFixed(2);
   }

   export const convertToHTML=(data)=> draftToHtml(data).toString()

export  const parseHTML = (html)=> {
  const str = new DOMParser().parseFromString(html, "text/html");
  return str.body.textContent
}
export  const parseGetHTML = (html)=> {
  const str = new DOMParser().parseFromString(html, "text/html");
  return str.body.innerHTML
}
export function decodeHTMLEntities(text) {
  if(!text){
     return ''
  }
  var textArea = document.createElement('textarea');
  textArea.innerHTML = text;
  return textArea.value;
}

export const sliceString=(data)=> {
  if(data.length > 100){
    return <span>{data.slice(0,250)} <span style={{color: 'blue'}}>see more...</span></span>
  }
  return data
}

export const converGender = (a) => {
  if(a === "Male"){
    return "male"
  }
  return;
}
export const convertJobType = (a) => {
  if(a === null || a === undefined ) {
    return null;
  }
  if(a === "full_time"){
    return "Full Time"
  }
  if(a === "part_time") {
    return "Part Time"
  }
}
export const perfectNumber  = (a = '') => {
      return a ?  isNaN(Number(a)) ? 0 : Number(a) : 0
}
export const htmlDecode = (input) => {
  var e = document.createElement('div');
  e.innerHTML = input;
 
  return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue;
}
export const HtmlToString  = ({input=null}) => {
  let data =  <div dangerouslySetInnerHTML={{__html :htmlDecode(input)} }/>
  let check = data?.props?.dangerouslySetInnerHTML?.__html
  
  return   check !== null ?  <div className="default-css" dangerouslySetInnerHTML={{__html :htmlDecode(input)} }/> :  <div className="format-desc default-css" dangerouslySetInnerHTML={{__html :input} }/> 
}
export const HtmlShortString =({input=null}) => {
  return  <div dangerouslySetInnerHTML={{__html :input} }/>
}
export const timeInCalc = () => {
  let res = ['00:00'];
  let startTime = moment('00:00', 'HH:mm');
  for (let i = 0; i < 12; ) {
    let d = startTime.add(15, 'minutes');
    i = d.format('HH');
    res.push(d.format('HH:mm'));
  }
  return res;
};
export const HtmlConverter = ({input=null}) => {
  let data =  <div dangerouslySetInnerHTML={{__html :htmlDecode(input)} }/>
  let check = data?.props?.dangerouslySetInnerHTML?.__html
 
  return check.includes('color') ?  <div className="default-css"  dangerouslySetInnerHTML={{__html :htmlDecode(input.slice(0,1000).concat("...See More"))} }/> :  <div className="format-desc default-css" dangerouslySetInnerHTML={{__html :htmlDecode(input.slice(0,100).concat('...See More'))} }/> 
}
