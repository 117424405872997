import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import CreateChecklistItem from './CreateChecklistItem';
import ChecklistItem from './ChecklistItem';
import { FormControl, FormGroup } from '@material-ui/core';
import useStyles from '../../utils/modalStyles';
import { getUserData } from '../../../../helpers/projectHelper';

const Checklist = ({ card }) => {
  // const { user, isAuthenticated } = useSelector((state) => state.auth);
  const { full_name, user_type,user_id } = getUserData();
  const isAuthenticated = localStorage.getItem('token');

  const classes = useStyles();

  return (
    <Fragment>
      <h3 className={classes.header} style={{fontSize:"25px",fontWeight:600}}>Checklist</h3>
      <FormControl component="fieldset">
        <FormGroup>
          {card.checklist.length>0?card.checklist.map((item) => (
            <ChecklistItem key={item._id} item={item} card={card} />
          )):<p style={{color:"#e5e5e5",fontWeight:600,margin:"10px 0px"}}>No CheckList found !</p>}
        </FormGroup>
      </FormControl>
      {user_type && user_type == "job_seeker" ? (
        ""
      ) : (
        <CreateChecklistItem cardId={card._id} />
      )}
    </Fragment>
  );
};

Checklist.propTypes = {
  card: PropTypes.object.isRequired,
};

export default Checklist;
