import { createSlice } from '@reduxjs/toolkit';

const adminDashboardSlice = createSlice({
  name: 'adminDashboard',
  initialState: {
    dashboardData: {},
    employerVerifyList: [],
    dashboardEmpListData: [],
    employerDetailData: {},
    jobListData: [],
    jobseekerList: [],
    jobSeekerData: [],
    categoryDataList: [],
    skillDataList: [],
    jobTitleDataList: [],
    paymentHistoryDataList: [],
    staffDataList: [],
    activationLoading: false,
    jobSeekerVerifySingleData: {},
    acitvityListData: [],
    notificationData: {},
    chatListEmp: [],
    chatListJob: [],
    chatListData: [],
    acceptedEmpList: [],
    rejectedEmpList: [],
    acceptedJobSeekerList: [],
    rejectedJobSeekerList: [],
    bonusListData: [],
    jobInfo: {},
    jobSeekerInfo: {},
    unVerifiedJobListData: [],
    deleteEmployerList: [],
    deleteJobseekerList: [],
    deleteEmployerData: {},
    deleteJobseekerData: {},
    announcementList: [],
    jobSeekerVerifyList: [],
    eventInfo: {},
    profileDetails: {},
    referral:[],
    affiliate:[]
  },
  reducers: {
    setAnnouncement: (state, { payload }) => {
      state.announcementList = payload;
    },
    setReferral : (state,{payload})=>{
      state.referral=payload
    },
    setAffiliate : (state,{payload})=>{
      state.affiliate=payload
    },
    adminEmpVerifyAct: (state, { payload }) => {
      state.employerVerifyList = payload;
    },
    dashboardDataAct: (state, { payload }) => {
      state.dashboardData = payload;
    },
    dashboardEmpListAct: (state, { payload }) => {
      state.dashboardEmpListData = payload;
    },
    employerDetailAct: (state, { payload }) => {
      state.employerDetailData = payload;
    },
    jobListAct: (state, { payload }) => {
      state.jobListData = payload;
    },
    adminJobSeekerAct: (state, { payload }) => {
      state.jobseekerList = payload;
    },
    adminJobSeekerDataAct: (state, { payload }) => {
      state.jobSeekerData = payload;
    },
    adminCategoryAct: (state, { payload }) => {
      state.categoryDataList = payload;
    },
    adminSkillAct: (state, { payload }) => {
      state.skillDataList = payload;
    },
    adminJobTitleAct: (state, { payload }) => {
      state.jobTitleDataList = payload;
    },
    adminPaymentHistoryAct: (state, { payload }) => {
      state.paymentHistoryDataList = payload;
    },
    adminStaffListAct: (state, { payload }) => {
      state.staffDataList = payload;
    },
    adminJobSeekerVerifyAct: (state, { payload }) => {
      state.jobSeekerVerifyList = payload;
    },
    activationLoading: (state, { payload }) => {
      state.activationLoading = payload;
    },
    adminJobSeekerSingleDataAct: (state, { payload }) => {
      state.jobSeekerVerifySingleData = payload;
    },
    activiyListAct: (state, { payload }) => {
      state.acitvityListData = payload;
    },
    notificationAct: (state, { payload }) => {
      state.notificationData = payload;
    },
    chatListEmpAct: (state, { payload }) => {
      state.chatListEmp = payload;
    },
    chatListJobAct: (state, { payload }) => {
      state.chatListJob = payload;
    },
    chatListAct: (state, { payload }) => {
      state.chatListData = payload;
    },
    adminEmployerAcceptedListAct: (state, { payload }) => {
      state.acceptedEmpList = payload;
    },
    adminEmployerRejectedListAct: (state, { payload }) => {
      state.rejectedEmpList = payload;
    },
    adminJobSeekerAcceptedListAct: (state, { payload }) => {

      state.acceptedJobSeekerList = payload;
    },
    adminJobSeekerRejectedListAct: (state, { payload }) => {
      state.rejectedJobSeekerList = payload;
    },
    adminBonusListAct: (state, { payload }) => {
      state.bonusListData = payload;
    },
    adminJobInfoAct: (state, { payload }) => {
      state.jobInfo = payload;
    },
    adminJobSeekerInfoAct: (state, { payload }) => {
      state.jobSeekerInfo = payload;
    },
    jobUnverifiedListAct: (state, { payload }) => {
      state.unVerifiedJobListData = payload;
    },
    deleteEmployerListAct: (state, { payload }) => {
      state.deleteEmployerList = payload;
    },
    deleteJobseekerListAct: (state, { payload }) => {
      state.deleteJobseekerList = payload;
    },
    deleteEmployerDetailAct: (state, { payload }) => {
      state.deleteEmployerData = payload;
    },
    deleteJobseekerDetailAct: (state, { payload }) => {
      state.deleteJobseekerData = payload;
    },
    jobSeekerVerifyListAct: (state, { payload }) => {
      state.jobSeekerVerifyList = payload;
    },
    eventInfoAct: (state, { payload }) => {
      state.eventInfo = payload;
    },
    setProfileDetails:(state,{ payload}) => {
      state.profileDetails =payload;
    },
    setProfileDetailsLog:(state,{ payload}) => {
      console.log('payload',payload);
      state.employerDetailData['log'] =payload;
    },
    setChatTotalLog:(state,{ payload}) => {
      state.employerDetailData['log'] = state.employerDetailData?.['log'] || [];
      state.employerDetailData['log']['chat_total'] = payload
    }
  },
});

export const {setChatTotalLog,setProfileDetails,setProfileDetailsLog, eventInfoAct, setAnnouncement, adminEmpVerifyAct, dashboardDataAct, dashboardEmpListAct, employerDetailAct, deleteEmployerDetailAct, deleteJobseekerDetailAct, jobListAct, adminJobSeekerAct, adminJobSeekerDataAct, adminCategoryAct, adminSkillAct, adminPaymentHistoryAct, adminJobTitleAct, adminStaffListAct, adminJobSeekerVerifyAct, adminJobSeekerSingleDataAct, activiyListAct, notificationAct, chatListEmpAct, chatListJobAct, chatListAct, adminEmployerAcceptedListAct, adminEmployerRejectedListAct, adminJobSeekerAcceptedListAct,
  adminJobSeekerRejectedListAct, adminBonusListAct, adminJobInfoAct, adminJobSeekerInfoAct, jobUnverifiedListAct, deleteEmployerListAct, deleteJobseekerListAct, jobSeekerVerifyListAct,setReferral,setAffiliate } = adminDashboardSlice.actions;

export const adminDashboardSelector = (state) => state.adminDashboard;
const adminDashboardReducer = adminDashboardSlice.reducer;
export default adminDashboardReducer;