import { createSlice } from '@reduxjs/toolkit';
import { JOBSEEKER_MENU } from '../../config/constant/menuConstant';
import { menuIdUpdate } from '../../helpers/projectHelper';
import { objectFindUpdate } from '../../helpers/jsHelper';

const jobSeekerSlice = createSlice({
  name: 'job_seeker',
  initialState: {
    bonusTransactionList :[],
    bookmarked: '',
    jobList: '',
    findJobCategoryList: '',
    skillList: [],
    skillSearchList:[],
    searchedJobs: '',
    formData: [],
    audioData: '',
    educationlist: '',
    categoryList: [],
    skillsList:[],
    selectedCategory: '',
    experiencelist: '',
    skillsrate: [],
    filedata: [],
    profileList: {},
    profilePic: '',
    getProfPic: '',
    isApplied: '',
    isSaved: '',
    viewJob: '',
    appliedJobsList: '',
    savedJobsList: '',
    offerList:[],
    offerStatus:"",
    settingsList:'',
    getStart:false,
    bankDetailList: "",
    notificationData:{},
    editBankData:"",
    profileLanding: false,
    jobSeekerMenu:menuIdUpdate?.(JOBSEEKER_MENU),
    govFiledata: [],
    scheduledListData : [],
    paymentHistoryList : [],
    paymentRequestList : [],
    paymentHour : {},
    hiredEmployerList : [],
    hiredEmployerDetails:{},
    invoiceListData: [],
    transactionList:[],
    seflieID:'',
    individualInvoiceData:[],
    govermentId:"",
    profileCheck:{},
    pro:false,
    requiredFields: [],
    processLoader:false,
    processData:"",
    eventDetails:{},
    protectedPendingSign:[]
  },
  reducers: {
    bookmarkedSuccess: (state, { payload }) => {
      state.bookmarked = payload;
    },
    jobListSuccess: (state, { payload }) => {
      state.jobList = payload;
    },
    searchJobSuccess: (state, { payload }) => {
      state.searchedJobs = payload;
    },
    categoryListSuccess: (state, { payload }) => {
      state.findJobCategoryList = payload;
    },
    skillListSuccess: (state, { payload }) => {
      const { data } = payload;
      state.skillList = data.data;
    },
    educationList: (state, { payload }) => {
      state.educationlist = payload;
    },
    profileData: (state, { payload }) => {
      state.formData = { ...state.formData, ...payload };
    },
    categoryData: (state, { payload }) => {
      state.categoryList = payload;
    },
    skillsData: (state, { payload }) => {
      state.skillsList = payload;
    },
    selectedCategoryList: (state, { payload }) => {
      state.selectedCategory = payload;
    },
    experienceList: (state, { payload }) => {
      state.experiencelist = payload;
    },
    fileData: (state, { payload }) => {
      state.filedata.unshift(payload);
    },
    audioDataSuccess: (state, { payload }) => {
      state.audioData = payload;
    },
    skillsArray: (state, { payload }) => {
      state.skillsrate.push(payload);
    },
    profileListSuccess: (state, { payload }) => {
      state.profileList = payload;
    },
    profilepicSuccess: (state, { payload }) => {
      state.profilePic = payload;
    },
    getProfilePic: (state, { payload }) => {
      state.getProfPic = payload;
    },
    appliedJobsListCheck: (state, { payload }) => {
      state.isApplied = payload;
    },
    saveJobsListCheck: (state, { payload }) => {
      state.isSaved = payload;
    },
    viewJobList: (state, { payload }) => {
      state.viewJob = payload;
    },
    appliedJobListArray: (state, { payload }) => {
      state.appliedJobsList = payload;
    },
    savedJobListArray: (state, { payload }) => {
      state.savedJobsList = payload;
    },
    getJobOfferList:(state,{payload}) => {
      state.offerList = payload
   },
    getJobOfferStatus:(state,{payload}) => {
      state.offerStatus = payload
   },
   getNotiftyData:(state,{payload}) => {
     state.settingsList = payload
   },
    getStartFunc: (state, { payload }) => {
      state.getStart = payload;
    },
    getBankDetailList: (state, { payload }) => {
      state.bankDetailList = payload;
    },
    getSettingsData: (state,{ payload}) => {
      state.notificationData = payload;
    },
    editBankData: (state, { payload }) => {
      state.editBankData = payload;
    },
    profileLandingfun: (state, { payload }) => {
      state.profileLanding = payload;
    },
    setSkillSearchList: (state, { payload }) => {
      state.skillSearchList = payload;
    },
    resetJobSeekerMenu: (state, { payload }) => {
      state.jobSeekerMenu = menuIdUpdate(JOBSEEKER_MENU);
    },
    setJobSeekerMenu: (state, { payload }) => {
      const {name,subName='',extra={}} = payload;
      state.jobSeekerMenu.forEach((a) => {
        a.active = a.active && a.id === name ? false : a.id === name
        Object.entries(extra).forEach(([key, value]) => {
          a[key] = value
        })
        if (a.subMenu){
          a.subMenu.forEach((i) =>{
            i.active = i.id === subName;
          })
        }
      })
    },
    setBadgeCount: (state, { payload }) => {
      const {name,badge=0} = payload;
      state.jobSeekerMenu = objectFindUpdate([...state.jobSeekerMenu],'id',name,{badge})
    },
    govFileData: (state, { payload }) => {
      state.govFiledata.push(payload);
    },
    getScheduledListData : (state, { payload }) => {
      state.scheduledListData = payload;
    },
    getPaymentHistory : (state, { payload }) => {
      state.paymentHistoryList = payload;
    },
    getPaymentRequestList : (state, { payload }) => {
      state.paymentRequestList = payload
    },
    getPaymentHour : (state, { payload }) => {
      state.paymentHour = payload
    },
    gethiredEmployerList : (state, { payload }) => {
      state.hiredEmployerList = payload;
    },
    getHiredEmployerDetailsAct: (state, { payload }) => {
      state.hiredEmployerDetails = payload;
    },
    invoiceListAct: (state, { payload }) => {
      state.invoiceListData = payload;
    },
    getTransactionList:(state,{payload}) => {
      state.transactionList = payload;
    },
    selfieIdAct:(state,{payload}) => {
      state.seflieID = payload;
    },
    individualInvoiceList:(state,{payload}) => {
      state.individualInvoiceData = payload;
    },
    getBonus:(state,{payload}) => {
      state.bonusTransactionList = payload;
    },
    govIdAct:(state,{payload}) => {
      state.govermentId = payload;
    },
    getProfileCheck:(state,{payload}) => {
      state.profileCheck = payload;
    },
    proList:(state,{payload}) => {
      state.pro = payload;
    },
    getRequiredFieldsList:(state,{payload}) => {
      state.requiredFields = payload;
    },
    setProcessLoader:(state,{payload}) => {
      state.processLoader = payload;
    },
    setPaymentMessage: (state, { payload }) => {
      state.paymentMessage = payload;
    },
    getEventDetail: (state, {payload}) => {
      console.log(payload);
      state.eventDetails = payload
    },
    getProtectedWaitingForSign :(state, {payload}) => {
      state.protectedPendingSign = payload
    }
  },
  
});

export const {
  setProcessLoader,
  setPaymentMessage,
  getProfileCheck,
  bookmarkedSuccess,
  jobListSuccess,
  searchJobSuccess,
  categoryListSuccess,
  skillListSuccess,
  educationList,
  profileData,
  categoryData,
  skillsData,
  selectedCategoryList,
  experienceList,
  fileData,
  skillsArray,
  profileListSuccess,
  profilepicSuccess,
  getProfilePic,
  appliedJobsListCheck,
  saveJobsListCheck,
  viewJobList,
  appliedJobListArray,
  savedJobListArray,
  audioDataSuccess,
  getJobOfferList,
  getJobOfferStatus,
  getNotiftyData,
  getStartFunc,
  getBankDetailList,
  getSettingsData,
  editBankData,
  profileLandingfun,
  setSkillSearchList,
  setJobSeekerMenu,
  resetJobSeekerMenu,
  govFileData,
  getScheduledListData,
  getPaymentHistory,
  getPaymentRequestList,
  getPaymentHour,
  setBadgeCount,
  gethiredEmployerList,
  getHiredEmployerDetailsAct,
  invoiceListAct,
  getTransactionList,
  selfieIdAct,
  individualInvoiceList, getBonus,proList, getRequiredFieldsList,
  getEventDetail,
  govIdAct,
  getProtectedWaitingForSign
} = jobSeekerSlice.actions;

export const jobSeekerSelector = (state) => state.jobSeeker;
export const jobSeekerMenu = (state) => state.jobSeeker.jobSeekerMenu;
const jobSeekerReducer = jobSeekerSlice.reducer;
export default jobSeekerReducer;
