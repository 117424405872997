import {useState} from 'react'
import { Modal } from 'react-bootstrap';
import CancelIcon from '@material-ui/icons/Cancel';
import moment from 'moment';
import CloseIcon from '@material-ui/icons/Close';
import { useDispatch, useSelector } from 'react-redux';
import { subscriptionSelector } from '../../../redux/slicers/sessionSlice';
import { Link, useHistory } from 'react-router-dom';
import { EMPLOYER_HOW_IT_WORKS, EMPLOYER_SUBSCRIPTION_PRICING_PAGE } from '../../../config/constant/routePathConstant';
import { FREE_TRAIL } from '../../../config/constant/projectConstant';
import { useEffect } from 'react';
import { employerDashboardSelector , setTrialLoader} from '../../../redux/slicers/employer/empDashboardSlice';

export const SubscriptionAlertPopup = (props) => {
  const history = useHistory();
 
  const arr = [
    { key: 'Post jobs', value: 'Unlimited' },
    { key: 'Job approval time', value: 'Instant' },
    { key: 'Message applicants', value: 'Unlimited' },
    { key: 'Customer support', value: '24/7' },
    { key: 'Send job offer', value: 'Unlimited' },
    { key: 'Time tracking', value: 'Free to Use!' },
    { key: 'Bookmark workers', value: 'Unlimited' },
    { key: 'Payment processing fee', value: ' 0%' },
    { key: 'Hire staff', value: 'Unlimited' },
  ];
  const handleClick = ()=>{
    props.setShow(false)
    history.push(EMPLOYER_SUBSCRIPTION_PRICING_PAGE)

  }

  return (
    <Modal
      show={props.show}
      onHide={() => props.setShow(false)}
      aria-labelledby="example-modal-sizes-title-sm"
      style={{  borderRadius: '35px'}}
      className="emp-sub-modal"
    >
      <Modal.Body style={{ fontSize: '20px', cursor: 'pointer' , borderRadius: '25px'}} className="emp-pricing-modal emp_pricing_modal">
        <div className="d-flex justify-content-end mt-1 mr-1 closepos x-bar-res">
          <CancelIcon
            data-dismiss="modal"
            aria-label="Close"
            style={{ color: 'grey', cursor: 'pointer' }}
            onClick={()=>props.setShow(false)}
          />
        </div>
        <div className="modal_content pb-0">
          <div className='modal_left_area'>
          <h2 >Upgrade and get full access to</h2>
          <h2 >the Phillipines' safest</h2>
          <h2 >Outsourcing Platform.</h2>
          </div>
          <div className='modal_right_area'>
            <img src="../../images/Rocket.png"/>
          </div>
         
        </div>
        <div className="mt-2 benefits_div">
          <div className="modal-head">
            <h1>ENJOY
            THESE
            BENEFITS</h1>
          </div>
          <div style={{ borderRight: '4px solid yellow' }}>
            <div></div>
          </div>
          <div className='modal_content_flex'>
            {arr.map((a, i) => (
              <div key={a.key} className="d-flex card-row">
                <div style={{ width: '100%',display:"flex" }}>
                  <span><img src="../../images/Green-tic.png"/> {a?.key}:&nbsp;</span>{a?.value && <p className="">{a?.value}</p>}
                </div>
              </div>
            ))}
          </div>
          <div className="d-flex justify-content-center">
          <button onClick={handleClick}
            className={'rounded-pill'}
          >
            View Pricing
          </button>
          {/* <p onClick={()=>props.setShow(false)}>No thanks</p> */}
        </div>
        </div>
       
      </Modal.Body>
    </Modal>
  );
};

export const NewUserAlertPopup = (props) => {
  const history = useHistory();
 
  const handlePush = () => {
    props.trigger()
    history.push(EMPLOYER_HOW_IT_WORKS)
  }
 

  return (
    <Modal
      show={props.show}
      onHide={() => props.setShow(false)}
      aria-labelledby="example-modal-sizes-title-sm"
      style={{  borderRadius: '35px'}}
      className="emp-sub-modal"
    >
      <Modal.Body style={{ fontSize: '20px', cursor: 'pointer' , borderRadius: '25px' , background:"#004C70"}} className="user_alert_modal">
        <div className="d-flex justify-content-end mt-1 mr-1  x-bar-res">
          <CancelIcon
            data-dismiss="modal"
            aria-label="Close"
            style={{ color: 'grey', cursor: 'pointer' }}
            onClick={()=>props.close()}
          />
        </div>
         <div className="user_alert_modal_header p-4">
            <div className="row">
                <div className="col-md-8">
                  <div style={{color:"white"}} className="header_section">
                     <h1 className="text-bold">Not sure how everything works?</h1>
                     <p>Click below to see how to use our platform and make the most out of VirtualStaff.ph</p>
                     <div className='footer_section'>
                         <button className="read_btn" onClick={()=>handlePush()}>Read Here</button>
                         <button className="normal_btn mt-2" onClick={() => props.trigger()}>Don’t Show Again</button>
                     </div>
                  </div>
                </div>
                <div className="col-md-4">
                <img src="../../images/user-alert.png"/>
                </div>
            </div>
         </div>
       
      </Modal.Body>
    </Modal>
  );
};

export const TrailAlert =(props) => {
    const {
        plan_expiry = null,
        plan_days_left = null,
        is_trail=false,
        is_subscription_cancel=false
      } = useSelector(subscriptionSelector);
      const { trialLoader = false } = useSelector(employerDashboardSelector)
     let firstLine = '';
     let secondLine = '';
     let buttonText = ''; 
     const dispatch = useDispatch()
     const [show,setShow] = useState(false)
  
    const expiryString = plan_days_left  == '0' ? 'Today' : (plan_days_left  == '1' ? 'Tomorrow':  plan_days_left )
    if(is_trail){
        firstLine = `Free trial expires in ${expiryString} days `
        secondLine = `Click here to upgrade now and not lose access`
        buttonText = 'Upgrade Plan'
        if(plan_days_left === 1){
          firstLine = `Free trial expires tomorrow`
        }
        if(plan_days_left < 0){
          firstLine = `Free trial has expired`
          secondLine = `Click here to  subscribe any plan`
        }
        if(plan_days_left === 0){
          firstLine = `Free trial expires today`
          secondLine = `Click here to subscribe any plan`
        }
    }else if(plan_days_left <= FREE_TRAIL && !is_subscription_cancel){
        firstLine = `Your plan expires in ${expiryString} days`
        secondLine = `Click here to upgrade now and not lose access`
        buttonText = 'Upgrade Plan'
        if(plan_days_left === 1){
          firstLine = `Your plan expires tomorrow`
        }
        if(plan_days_left < 0){
          firstLine = `Your plan has expired`
          secondLine = `Click here to  upgrade the plan`
        }
    }

    useEffect(()=>{
      if((is_trail && plan_days_left <= FREE_TRAIL)){
         console.log("came")
         dispatch(setTrialLoader(true))
      }
    },[plan_days_left,is_trail])

    return (
      trialLoader ? <div div className='p-3 ' style={{ backgroundColor: "rgb(236 240 243)" }}>
                        <div className='d-flex align-items-center learn-wrapper green'>
                            <div className='close-icon tabresponsiveclose' style={{ width: "2%" }}>
                                <CloseIcon
                                    style={{
                                        color: "black",
                                        fontSize: "15px",
                                        border: "1px solid white",
                                    }}
                                    onClick={() => setShow(false)}
                                />
                            </div>
                            <div style={{ width: "94%",color:"black" }} className='d-flex justify-content-between learn-content tabresponsivefriendly'>
                                <div>
                                    <p className='m-0 fontsize-11' style={{  fontSize: "13px", lineHeight: "6px"}}>{firstLine}.</p>
                                    <a className='m-0 fontsize-11 text-underline' style={{ fontSize: "13px" }} href={EMPLOYER_SUBSCRIPTION_PRICING_PAGE}>{secondLine}.</a>
                                    {/* <p className='m-0 fontsize-11' style={{ color: "White", fontSize: "13px" }}>{thirdLine}</p> */}
                                </div>
                                <div className='d-flex align-items-center learn-btn'>
                                    <a href={EMPLOYER_SUBSCRIPTION_PRICING_PAGE}>
                                        <button style={{
                                         
                                            color: "white",
                                            fontSize: "14px",
                                            borderRadius: "6px",
                                            backgroundColor: "red",
                                            fontWeight: "bold",
                                            padding:"5%",
                                            width: "7rem",
                                            border:"none"
                                        }}>{buttonText}</button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div> : null
    )
}