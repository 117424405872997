import {
  ACCOUNT_VERIFY,
  CHAT,
  CUSTOMER_SUPPORT,
  EMPLOYER,
  JOB_SEEKER,
  SUPER_ADMIN,
  TASK_MANAGEMENT,
  TICKETING_SYSTEM,
  TIME_SHEET,
  WEBSITE,
  MARKETING,
} from '../constant/routePathConstant.js';
import WebsiteContainer from '../../components/website';
import JobSeekerContainer from '../../components/jobSeeker';
import EmployerContainer from '../../components/employer';
import AdminContainer from '../../components/admin';
import ChatContainer from '../../components/chat';
import TimeSheetContainer from '../../components/timeSheet';
import TaskManagementContainer from '../../components/task';
import TicketingSystemContainer from '../../components/ticketingsystem/ticketingSystem';
import CustomerSupport from '../../components/customerSupport';
import AccountVerifier from '../../components/accountVerify';
import AdContainer from '../../components/ad';
import SPECIAL_ROUTES from './specialRoutes';
import Marketing from '../../components/Marketing'

export const MAIN_ROUTES = [
  ...SPECIAL_ROUTES,
  { component: Marketing, subRoutes: '*', path: MARKETING },
  { component: AccountVerifier, subRoutes: '*', path: ACCOUNT_VERIFY },
  { component: TicketingSystemContainer, subRoutes: '*', path: TICKETING_SYSTEM },
  { component: JobSeekerContainer, subRoutes: '*', path: JOB_SEEKER },
  { component: EmployerContainer, subRoutes: '*', path: EMPLOYER },
  { component: AdminContainer, subRoutes: '*', path: SUPER_ADMIN },
  { component: ChatContainer, subRoutes: '*', path: CHAT },
  { component: TimeSheetContainer, subRoutes: '*', path: TIME_SHEET },
  { component: TaskManagementContainer, subRoutes: '*', path: TASK_MANAGEMENT },
  { component: CustomerSupport, subRoutes: '*', path: CUSTOMER_SUPPORT },
  { component: AdContainer, subRoutes: '*', path: ACCOUNT_VERIFY },
  { component: WebsiteContainer, exact: false, path: WEBSITE }
];
