import { STRIPE_KEY } from './../config/constant/projectConstant';

export const expertiseLevelConverter = (a) => {
	if(a === "intermediate") {
		return "Intermediate"
	}
	else if (a === "expert") {
		return "Expert"
	}
	else {
		return "Entry Level"
	}
	
}

export const yearArray = () => {
  let max = 2040
  let min = max - 60
  let years = []

  for (let i = max; i >= min; i--) {
    years.push({value:i, text: i})
  }
  return years;
}
const Stripe = window.Stripe
export const stripe = typeof Stripe !== "undefined" ? Stripe(STRIPE_KEY) : null 
// pk_live_51IYlMjEs4YSpqgfJHYfPFAoabhOwwyfG71TQXgJKXzHzjG7QkIon0yPiitjYx2KxMfS4PDqe2uqQG57vAa7ToLBU005dga9zvC	
//pk_test_51IYlMjEs4YSpqgfJ8t53RqQ3F3y7ZB6HIWx7OyImzQtgVpSuNAWmvHL4ijMRrKQwTpHu5t07mkym2ZBxoB5AHkSc00YQfOl8l0

export const planCheck = (a) => {
 return	a === 'Basic' ?  true : false
}