import React from 'react';
import { RouterBuilder } from '../common/router';
import { LazyLoader } from '../common/loader.js';
import Header from './common/header';
import SideBar from './common/sideBar';
import '../../assets/css/admin.scss';
import '../../assets/css/login.scss';
import { useRouteMatch } from 'react-router-dom';
import { ACCOUNT_VERIFY,ACCOUNT_VERIFY_LOGIN } from '../../config/constant/routePathConstant';
import { OtherSessionsChecker } from '../common/container/sessionChecker';
import { ACCOUNT_VERIFY_ROUTE } from '../../config/routes/adRoutes';

const AdContainer = () => {
  const isLogin = useRouteMatch(ACCOUNT_VERIFY_LOGIN);
  return (
    <div className='v-staff-admin-container'>
      <OtherSessionsChecker sessionFailPath={ACCOUNT_VERIFY_LOGIN} currentRoute={ACCOUNT_VERIFY} >{""} </OtherSessionsChecker>
      {!isLogin && <><Header /><SideBar /></>}
      <LazyLoader>
        <RouterBuilder data={ACCOUNT_VERIFY_ROUTE} />
      </LazyLoader>
    </div>
  );
};

export default AdContainer;
