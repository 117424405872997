import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { archiveList } from '../../actions/board';
import { getUserData } from '../../../../helpers/projectHelper';
import { useParams } from 'react-router-dom';
import List from '@material-ui/core/List';
import Button from '@material-ui/core/Button';
import ListItem from '@material-ui/core/ListItem';
import Chip from '@mui/material/Chip';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import {useSession} from "../../../../helpers/hooks";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    padding: theme.spacing(0.5),
  },
  chip: {
    margin: theme.spacing(0.5),
    backgroundColor: '#e5e5e5',
  },
}));

const StyledChip = withStyles((theme) => ({
  root: {
    maxWidth: 150,
    backgroundColor: '#e5e5e5',
  },
  label: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))(Chip);

const ArchivedLists = () => {
  const {user_type} = useSession()
  const classes = useStyles();
  const listObjects = useSelector((state) => state.board.board.listObjects);
  const dispatch = useDispatch();
  const { board_id } = useParams();
  const { full_name, user_id } = getUserData();

  const onSubmit = async (listId) => {
    let data = {
      id: listId,
      user_id: user_id,
      boardId: board_id,
      archive: false,
    };
    dispatch(archiveList(data));
  };

  return (
    <div>
      <List>
        {listObjects
          .filter((list) => list.archived)
          .map((list, index) => (
            <ListItem key={index}>
              <Tooltip title={`${list.title}`}>
                <StyledChip
                  size="small"
                  style={{ backgroundColor: '#e5e5e5' }}
                  key={`${list.title}`}
                  label={`${list.title}`}
                  className={classes.chip}
                />
              </Tooltip>
              {user_type !== 'job_seeker'?
              <Button onClick={() => onSubmit(list._id)}>Restore</Button>
          :" "}  </ListItem>
          ))}
      </List>
    </div>
  );
};

export default ArchivedLists;
