import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { archiveCard, deleteCard } from '../../actions/board';
import { getUserData } from '../../../../helpers/projectHelper';
import { useSession } from '../../../../helpers/hooks';
import { Button, Card, CardContent, List, ListItem } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Chip from '@mui/material/Chip';
import getInitials from '../../utils/getInitials';
import Avatar from '@material-ui/core/Avatar';
import { getBoards, delBoards, delBoardsMembers } from '../../actions/board';

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    height: '100%',
    '& .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-elevation24.MuiPaper-rounded':
      {
        width: '500px',
      },
    '& .MuiDialogActions-root': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '150px',
      flexWrap: 'wrap',
    },
  },
}));

const StyledChip = withStyles((theme) => ({
  root: {
    maxWidth: 250,
    backgroundColor: '#e5e5e5',
    // backgroundColor:`${'#'+Math.floor(Math.random()*16777215).toString(16)}`,
    height: '20px',
  },
  label: {
    // whiteSpace: 'nowrap',
    // overflow: 'hidden',
    // textOverflow: 'ellipsis',
    fontSize: '14px',
    padding: '10px',
  },
}))(Chip);

const ArchivedCards = () => {
  const classes = useStyles();

  const { full_name, user_type, user_id } = useSession();
  const isAuthenticated = localStorage.getItem('token');
  const boardMembers = useSelector((state) => state.board.board.members);
  const [boardMemberss, setBoardMembers] = useState([]);

  console.log("boardMembers",boardMembers)
  const dispatch = useDispatch();
  const { board_id } = useParams();

  useEffect(() => {
    setBoardMembers(boardMembers);
  }, [boardMembers]);

  const onDelete = async (e) => {
    dispatch(delBoardsMembers({ user_id: e, board_id: board_id }));
  };

  return (
    <div>
      <List>
        {boardMembers.map((member, index) => (
          <ListItem key={index} className="archived-card">
            <div style={{ display: 'flex' }}>
              {member.profile_id ? (
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <StyledChip
                    size="small"
                    style={{ backgroundColor: '#e5e5e5' }}
                    key={`${member.name}`}
                    label={`${member.name}`}
                    className={classes.chip}
                    avatar={`https://authapi.virtualstaff.ph/api/v1/file/download/${member.profile_id}`}
                  />
                </div>
              ) : (
                <div style={{ display: 'flex' }}>
                  <StyledChip
                    size="small"
                    style={{ backgroundColor: '#e5e5e5' }}
                    key={`${member.name}`}
                    label={`${member.name}`}
                    className={classes.chip}
                    avatar={<Avatar>{getInitials(member.name)}</Avatar>}
                  />
                </div>
              )}
            </div>
            &nbsp; &nbsp;
            <div>
              <Button color="secondary" onClick={() => onDelete(member.user)}>
                Remove staff member
              </Button>
            </div>
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default ArchivedCards;
