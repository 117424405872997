import { Component } from 'react';
import {JITSI_SERVER_ROOT} from "../../../config/constant/apiConstant";

class JitsiComponent extends Component {
  domain = JITSI_SERVER_ROOT;
  api = {};

  constructor(props) {
    super(props);
    this.state = {
      room: this.props.from_id,
      user: {
        name: this.props.to_id,
      },
      isAudioMuted: false,
      isVideoMuted: false,
      password: 'colan@1234',
      start: false,
    };
  }

  startMeet = () => {
    const options = {
      roomName: this.state.room,
      width: '100%',
      height: 750,
      // jwt: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzZXNzaW9uSWQiOiJKcmE5RF9SSlRSenhyNFpFaWk4akY0SndXNUFReVppSSIsImZ1bGxfbmFtZSI6InNoYW50aGkiLCJ1c2VyX2VtYWlsIjoic2hhbnRoaUB5b3BtYWlsLmNvbSIsInVzZXJfaWQiOiI2MGYxMjEzMDgzZGU5MDM2NTg3ZjM1NzIiLCJ1c2VyX3R5cGUiOiJlbXBsb3llciIsImlhdCI6MTYyODg0MDM2MSwiZXhwIjoxNjI4ODY5MTYxfQ.yeC4KmegH5p98GKZX3lpeodRyAnswWGB9ZoX5Z9WE5Y',
      configOverwrite: {
        prejoinPageEnabled: false,
        startWithVideoMuted: true,
      },
      startWithVideoMuted: true,
      // disableInviteFunctionForPrejoin:false,
      interfaceConfigOverwrite: {
        // overwrite interface properties
        // disableInviteFunctionForPrejoin:true,
        SHOW_JITSI_WATERMARK: false,
        SHOW_POWERED_BY: false,
        SHOW_BRAND_WATERMARK: false,
        SHOW_WATERMARK_FOR_GUESTS: false,
        SHOW_PROMOTIONAL_CLOSE_PAGE: false,
        TOOLBAR_BUTTONS: [
          'microphone',
          'camera',
          'closedcaptions',
          'fullscreen',
          'fodeviceselection',
          'hangup',
          'etherpad',
          'raisehand',
          'filmstrip',
          'videobackgroundblur',
          'e2ee',
          //   "Participants",
          //   "invite",
        ],
        //   "chats","invite",
      },
      parentNode: document.querySelector('#jitsi-iframe'),
      userInfo: {
        displayName: this.props.from_user,
      },
      moderator: true,
    };
    this.api = new window.JitsiMeetExternalAPI(this.domain, options);

    this.api.addEventListener('readyToClose', this.handleClose);
  };

  handleClose = () => {
    this.props.onHangUp();
  };

  handleParticipantLeft = async (participant) => {
    // //console.log("handleParticipantLeft", participant); // { id: "2baa184e" }
    const data = await this.getParticipants();
  };

  handleParticipantJoined = async (participant) => {
    // //console.log("handleParticipantJoined", participant); // { id: "2baa184e", displayName: "Shanu Verma", formattedDisplayName: "Shanu Verma" }
    const data = await this.getParticipants();
  };

  handleVideoConferenceJoined = async (participant) => {
    // //console.log("handleVideoConferenceJoined", participant); // { roomName: "bwb-bfqi-vmh", id: "8c35a951", displayName: "Akash Verma", formattedDisplayName: "Akash Verma (me)"}
    const data = await this.getParticipants();
  };

  handleVideoConferenceLeft = () => {
    // //console.log("handleVideoConferenceLeft");
    // return this.props.history.push('/thank-you');
    this.setState({ start: false });
    // this.api.dispose();
  };

  handleMuteStatus = (audio) => {
    // //console.log("handleMuteStatus", audio); // { muted: true }
  };

  handleVideoStatus = (video) => {
    // //console.log("handleVideoStatus", video); // { muted: true }
  };

  getParticipants() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(this.api.getParticipantsInfo()); // get all participants
      }, 500);
    });
  }

  // custom events
  executeCommand(command) {
    this.api.executeCommand(command);
    if (command == 'hangup') {
      this.setState({ start: false });
      this.api.dispose();

      return this.props.history.push('/thank-you');

    }

    if (command == 'toggleAudio') {
      this.setState({ isAudioMuted: !this.state.isAudioMuted });
    }

    if (command == 'toggleVideo') {
      this.setState({ isVideoMuted: !this.state.isVideoMuted });
    }
  }

  async componentDidMount() {
    await new Promise((resolve) => {
      const script = document.createElement('script');
      script.src =`https://${JITSI_SERVER_ROOT}/external_api.js`;
      script.async = true;
      script.onload = resolve;
      document.body.appendChild(script);
    });
    // //console.log("meetid", this.props.match.params.meetid);
    await this.setState({
      room: this.props.from_id,
      user: {
        name: this.props.to_id,
        moderator: true,
      },
    });
    await this.startMeet();
  }
  handleToggle = () => {
    this.setState({ start: true, room: this.state.room });
  };
  handleOnChange = (e) => {
    this.setState({ ...this.state, room: e.target.value });
  };
  handleStartMeeting = () => {
    this.setState({ start: true });
    this.startMeet();
  };

  render() {
    // const { isAudioMuted, isVideoMuted } = this.state;
    return <div id="jitsi-iframe" />;
  }
}

export default JitsiComponent;
