import { createSlice } from '@reduxjs/toolkit';

const customerSopportSlice = createSlice({
  name: 'customerSupport',
  initialState: {
    ticketListData:[],
    customerSupportTicketStatusUpdate:{}
  },
  reducers: {
    csTicketListAct: (state, {payload}) => {
      state.ticketListData = payload;
    },
    customerSupportTicketStatusUpdateAct: (state, { payload }) => {
      state.customerSupportTicketStatusUpdate = payload;
    },
  },
});

export const { csTicketListAct,customerSupportTicketStatusUpdateAct } = customerSopportSlice.actions;

export const customerSupportSelector = (state) => state.customerSupport;
const customerSupportReducer = customerSopportSlice.reducer;
export default customerSupportReducer;