import { lazy } from 'react';
import {
   ACCCOUNT_EXPERTISE,
   ACCOUNT_ABOUT_ME,
   ACCOUNT_CATEGORY,
   ACCOUNT_EDUCATION,
   ACCOUNT_EMPLOYMENT,
   ACCOUNT_EXPERTISELEVEL,
   ACCOUNT_GETSTARTED,
   ACCOUNT_LANGUAGE,
   ACCOUNT_LOCATION,
   ACCOUNT_PHOTO,
   ACCOUNT_PREVIEW,
   ACCOUNT_RATE,
   ACCOUNT_VERFICATION,
   JOB_SEEKER,
   JOBSEEKER_ACCOUNTSECTION,
   JOBSEEKER_APPLIEDJOBS,
   JOBSEEKER_DASHBOARD,
   JOBSEEKER_FINDJOBS,
   JOBSEEKER_HIRED_EMPLOYER_JOB_DETAILS_ROUTE,
   JOBSEEKER_INVOICE,
   JOBSEEKER_JOB_DESCRIPTION,
   JOBSEEKER_JOBOFFER_LIST,
   JOBSEEKER_MESSAGE,
   JOBSEEKER_MY_EMPLOYER,
   JOBSEEKER_NOTIFICATION_SETTING,
   JOBSEEKER_PROFILE_LANDING,
   JOBSEEKER_SAVEDJOBS,
   JOBSEEKER_SCHEDULE_INTERVIEW,
   JOBSEEKER_SEE_PUBLIC_VIEW,
   JOBSEEKER_TRANSACTION,
   JOBSEEKER_USER_PROFILE_PREVIEW,
   JOBSEEKER_VIEW_ALL_SCHEDULED_EVENTS,
   JOBSEEKER_VIEW_JOB_DESCRIPTION,
   JOBSEEKER_VIEW_SCHEDULED_EVENT,
   TRACKER,
   JOBSEEKER_INVOICE_ACTION,
   JOBSEEKER_UNVERIFIED,
   ACCOUNT_PROFILEVISIBILITY,
   JOBSEEKER_EARNINGS_SUMMARY,
   JOBSEEKER_UNSUBSCRIBE_NOTIFICATION,
   INSTALL_TRACKER,
   JOBSEEKER_HOW_IT_WORKS,
   ACCOUNT_STRIPE_ONBOARD,
   STRIPE_ONBOARD_EXISTING_USER,
   JOBSEEKER_BANK_VERIFY,
   NDA_PAGE,
   NDA_AGREEMENT,
   NDA_AGREEMENT_DETAILS,
   JOBSEEKER_CONTRACT_DOWNLOAD
} from '../constant/routePathConstant';

import { RedirectToDashboard, RedirectToGetStarted } from '../../components/jobSeeker/common/redirect.js';

const DashboardComponent = lazy(() => import('../../components/jobSeeker/dashboard/dashboard.js'));
const FindJobsComponent = lazy(() => import('../../components/jobSeeker/findjobs/findjobs.js'));
const TransactionComponent = lazy(() => import('../../components/jobSeeker/profilesection/transaction.js'));
const ProfilePage = lazy(() => import('../../components/jobSeeker/profilesection'));
const JobDescription = lazy(() => import('../../components/jobSeeker/jobdescription/jobdescription.js'));
const GetStarted = lazy(() => import('../../components/jobSeeker/profilesection/GetStarted'));
const Category = lazy(() => import('../../components/jobSeeker/profilesection/Category'));
const Eduction = lazy(() => import('../../components/jobSeeker/profilesection/Education'));
const Expertise = lazy(() => import('../../components/jobSeeker/profilesection/Expertise'));
const Location = lazy(() => import('../../components/jobSeeker/profilesection/location'));
const StripeOnboard = lazy(() => import('../../components/jobSeeker/profilesection/StripeOnboard'));
const Verify = lazy(() => import('../../components/jobSeeker/profilesection/verification'));
const ProfilePhoto = lazy(() => import('../../components/jobSeeker/profilesection/profilephoto'));
const ProfileVisibility = lazy(() => import('../../components/jobSeeker/profilesection/ProfileVisibility'))
const Rate = lazy(() => import('../../components/jobSeeker/profilesection/rate'));
const Resume = lazy(() => import('../../components/jobSeeker/profilesection/resume'));
const Employment = lazy(() => import('../../components/jobSeeker/profilesection/employment'));
const Expertiselevel = lazy(() => import('../../components/jobSeeker/profilesection/Expertise_level'));
const Language = lazy(() => import('../../components/jobSeeker/profilesection/languages'));
const Preview = lazy(() => import('../../components/jobSeeker/profilesection/preview'));
const UserPreview = lazy(() => import('../../components/jobSeeker/profilesection/user_preview'));
const AppliedJobs = lazy(() => import('../../components/jobSeeker/appliedJobs/appliedJobs.js'));
const SavedJobs = lazy(() => import('../../components/jobSeeker/savedJobs/savedJobs.js'));
const Settings = lazy(() => import('../../components/jobSeeker/settings-notification/settings.js'));
const Invoice = lazy(() => import('../../components/jobSeeker/invoice/invoice.js'));
const StripeOnboardExistingUser = lazy(() => import('../../components/jobSeeker/stripeOnboarding/stripeOnboardingExistingUser.js'));
const Message = lazy(() => import('../../components/jobSeeker/message/message'));
const ViewJobOffer = lazy(() => import('../../components/jobSeeker/joboffer/jobOffer.js'));
const ViewJobDescription = lazy(() => import('../../components/jobSeeker/viewJobDescription/viewJobDescription.js'));
const SeePublicView = lazy(() => import('../../components/jobSeeker/profilesection/see_public_view'));
const ProfileLanding = lazy(() => import('../../components/jobSeeker/profilesection/profileLanding'));

const ViewAllScheduledEvent = lazy(() => import('../../components/jobSeeker/viewAllScheduledEvents'));
const MyEmploer = lazy(() => import('../../components/jobSeeker/myemployer/myemployer'));
const HiredEmployerDetails = lazy(() => import('../../components/jobSeeker/hiredEmployerDetails/hiredEmployerDetails'));
const HiredEmployerDetailsList = lazy(() => import('../../components/jobSeeker/hiredEmployerDetailsList/hiredEmployerDetailsList.js'));
const InvoiceAction = lazy(() => import('../../components/jobSeeker/invoice/invoiceAction'));
const scheduleInterview = lazy(() => import('../../components/jobSeeker/viewAllScheduledEvents'));
// const Settings = lazy(() => import('../../components/jobSeeker/settings-notification/settings.js'));
// const Invoice = lazy(() => import('../../components/jobSeeker/invoice/invoice.js'));
// const Message = lazy(() => import('../../components/jobSeeker/message/message'));
const UpdataProfile = lazy(() => import('../../components/jobSeeker/updataProfile/index'));
const MyTracker = lazy(() => import('../../components/jobSeeker/tracker/tracker'))
const IncompleteProfile = lazy(() => import('../../components/jobSeeker/unverified'))
const SheduledInterviews = lazy(() => import('../../components/jobSeeker/scheduleInterview'))
const EarningsSummary = lazy(() => import('../../components/jobSeeker/earningsSummary'))
const UnSubscribeNotification = lazy(() => import('../../components/jobSeeker/unSubsNotify'))
const InstallTracker = lazy(() => import('../../components/timeSheet/trackerInstall'))
const HowItWorks = lazy(() => import('../../components/jobSeeker/howitworks'))
const VerifyBank = lazy(() => import('../../components/jobSeeker/bankVerify'))
const NDA = lazy(() => import('../../components/jobSeeker/NDA'))
const ContractAgreement = lazy(() => import('../../components/jobSeeker/ContractAgreement'))
const ContractDownload = lazy(() => import('../../components/employer/contractDownload'))

export const JOBSEEKER_ROUTES = [
   { component: RedirectToDashboard, path: JOB_SEEKER },
   { component: DashboardComponent, path: JOBSEEKER_DASHBOARD },
   { component: FindJobsComponent, path: JOBSEEKER_FINDJOBS },
   { component: JobDescription, path: JOBSEEKER_JOB_DESCRIPTION },
   { component: TransactionComponent, path: JOBSEEKER_TRANSACTION },
   { component: UserPreview, path: JOBSEEKER_USER_PROFILE_PREVIEW },
   { component: AppliedJobs, path: JOBSEEKER_APPLIEDJOBS },
   { component: SavedJobs, path: JOBSEEKER_SAVEDJOBS },
   { component: SeePublicView, path: JOBSEEKER_SEE_PUBLIC_VIEW },
   { component: ProfilePage, subRoutes: '*', path: JOBSEEKER_ACCOUNTSECTION },
   { component: Settings, path: JOBSEEKER_NOTIFICATION_SETTING },
   { component: Invoice, path: JOBSEEKER_INVOICE },
   { component: StripeOnboardExistingUser, path: STRIPE_ONBOARD_EXISTING_USER },
   { component: Message, path: JOBSEEKER_MESSAGE },
   { component: ViewJobOffer, path: JOBSEEKER_JOBOFFER_LIST },
   { component: ViewJobDescription, path: JOBSEEKER_VIEW_JOB_DESCRIPTION },
   { component: HowItWorks, path: JOBSEEKER_HOW_IT_WORKS },
   { component: ViewAllScheduledEvent, path: JOBSEEKER_VIEW_ALL_SCHEDULED_EVENTS },
   { component: MyEmploer, path: JOBSEEKER_MY_EMPLOYER },
   { component: MyTracker, path: TRACKER },
   { component: ViewJobDescription, path: JOBSEEKER_VIEW_JOB_DESCRIPTION },
   { component: scheduleInterview, path: JOBSEEKER_VIEW_SCHEDULED_EVENT },
   { component: HiredEmployerDetails, path: JOBSEEKER_HIRED_EMPLOYER_JOB_DETAILS_ROUTE },
   { component: HiredEmployerDetailsList, path: JOBSEEKER_HIRED_EMPLOYER_JOB_DETAILS_ROUTE },
   { component: InvoiceAction, path: JOBSEEKER_INVOICE_ACTION },
   { component: UpdataProfile, path: "/jobseeker/update-profile" },
   { component: IncompleteProfile, path: JOBSEEKER_UNVERIFIED },
   { component: SheduledInterviews, path: JOBSEEKER_SCHEDULE_INTERVIEW },
   { component: EarningsSummary, path: JOBSEEKER_EARNINGS_SUMMARY },
   { component: UnSubscribeNotification, path: JOBSEEKER_UNSUBSCRIBE_NOTIFICATION },
   { component: InstallTracker, path: INSTALL_TRACKER },
   {component: VerifyBank, path : JOBSEEKER_BANK_VERIFY},
   {component: NDA, path : NDA_PAGE},
   {component: ContractAgreement, path : NDA_AGREEMENT_DETAILS},
   {component:ContractDownload,path:JOBSEEKER_CONTRACT_DOWNLOAD},


];

export const PROFILE_ROUTES = [
   { component: RedirectToGetStarted, path: JOBSEEKER_ACCOUNTSECTION },
   { component: GetStarted, path: ACCOUNT_GETSTARTED },
   { component: Category, path: ACCOUNT_CATEGORY },
   { component: Expertise, path: ACCCOUNT_EXPERTISE },
   { component: Expertiselevel, path: ACCOUNT_EXPERTISELEVEL },
   { component: Eduction, path: ACCOUNT_EDUCATION },
   { component: Employment, path: ACCOUNT_EMPLOYMENT },
   { component: Language, path: ACCOUNT_LANGUAGE },
   { component: Rate, path: ACCOUNT_RATE },
   { component: Resume, path: ACCOUNT_ABOUT_ME },
   { component: ProfilePhoto, path: ACCOUNT_PHOTO },
   { component: Location, path: ACCOUNT_LOCATION },
   { component: ProfileVisibility, path: ACCOUNT_PROFILEVISIBILITY },
   { component: Verify, path: ACCOUNT_VERFICATION },
   { component: StripeOnboard, path: ACCOUNT_STRIPE_ONBOARD },
   { component: Preview, path: ACCOUNT_PREVIEW },
   { component: ProfileLanding, path: JOBSEEKER_PROFILE_LANDING },
];
// export const ACCOUNT_ROUTES = [
//   {component:GetStarted},

// ]
